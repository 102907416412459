<template>
  <CustomModalContainer :open="open" :set-open="setOpen">
    <div class="w-100 disclaimer-container p-3">
      <h4 class="note-text mb-0">
        <b class="text-warning warning-icon mr-3 mb-0">&#x26A0;</b>
        <span class="mb-0">Note</span>
      </h4>
      <p class="mb-0 body-title">
        {{ message }}
      </p>
    </div>
  </CustomModalContainer>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-md-3">
        <Button />
      </div>
      <div class="col-md-9">
        <div
          class="card border border-white shadow bg-body rounded-4 min-vh-100"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-md-3" style="border-right: 1px inset">
                <ul class="progress-barr">
                  <li :class="{ active: step >= 1 }">
                    Total Revenue of Freezone Person
                  </li>
                  <li :class="{ active: step >= 2 }">
                    Total Expense of Freezone Person
                  </li>
                  <li :class="{ active: step >= 3 }">
                    Income From Non Qualifying Activities
                  </li>
                </ul>
              </div>
              <div class="col-md-8">
                <Revenue :set-alert="setOpen" v-show="step === 1" />
                <Expense :set-alert="setOpen" v-show="step === 2" />
                <div v-show="step === 3">
                  <Total :set-alert="setOpen" />
                  <RevenueQFZP :set-alert="setOpen" />
                </div>
                <AllTotal :set-alert="setOpen" v-show="step === 4" />
                <div class="d-flex justify-content-between mt-3">
                  <a
                    class="btn btn-outline-dark"
                    v-if="step > 1"
                    @click="prevStep"
                    >Back</a
                  >
                  <a
                    class="btn btn-primary w-1 ml-auto"
                    v-if="step < 4"
                    @click="nextStep"
                    >Next Step</a
                  >
                </div>

                <!--   <div class="d-flex flex-row-reverse mt-1 justify-content-between">
                                    <a class="btn btn-outline-dark" v-if="step > 1" @click="prevStep">Back</a>
                                    <a class="btn btn-primary w-1" v-if="step < 4" @click="nextStep" >Next Step</a>
                                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
</template>
<script>
import { CustomModalContainer } from "../common";
import Button from "./Button.vue";
import Revenue from "./QFZCalculator/Revenue.vue";
import Expense from "./QFZCalculator/Expense.vue";
import Total from "./QFZCalculator/Total.vue";
import RevenueQFZP from "./QFZCalculator/RevenueQFZP.vue";
import AllTotal from "./QFZCalculator/AllTotal.vue";

export default {
  components: {
    CustomModalContainer,
    Button,
    Revenue,
    Expense,
    Total,
    RevenueQFZP,
    AllTotal,
  },
  data: () => {
    return {
      step: 1,
      open: false,
      message: "",
    };
  },

  methods: {
    nextStep() {
      this.step++;
    },
    prevStep() {
      this.step--;
    },
    setOpen(value, message = "") {
      if (message) {
        this.message = message;
      }
      this.open = value;
    },
  },
};
</script>

<style>
.progress-barr {
  list-style: none;
  margin-top: 30px;
  font-size: 13px;
  font-weight: 600;
  counter-reset: container 0;
}
.progress-barr li {
  position: relative;
  margin-left: 40px;
  margin-top: 50px;
  counter-increment: container 1;
  color: #4f6581;
}
.progress-barr li::before {
  content: counter(container);
  line-height: 25px;
  text-align: center;
  position: absolute;
  height: 25px;
  width: 25px;
  border: 1px solid #4f6581;
  border-radius: 50%;
  left: -40px;
  top: -5px;
  z-index: 2;
  background-color: #2a9ed8;
}

.progress-barr li::after {
  content: "";
  position: absolute;
  height: 90px;
  width: 2px;
  background-color: #4f6581;
  z-index: 1;
  left: -27px;
  top: -70px;
}

.progress-barr li.active::after {
  background-color: #fff;
}

.progress-barr li:first-child:after {
  display: none;
}

.progress-barr li.active::before {
  color: #fff;
  border: 1px solid #fff;
}
.progress-barr li.active {
  color: #2a9ed8;
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
}

.btn {
  width: 150px;
  padding: 12px !important;
}

.clickable-text {
  width: 20%;
}
@media (min-width: 768px) and (max-width: 1024px){
            .col-md-3 {
                width: auto !important;
            }
            .col-md-9{width: 100% !important;}
            .col-md-8{width: 100% !important;}
        }
</style>

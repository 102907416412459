<template>
  <div class="chart">
    <Bar v-if="chartData" :data="chartData" :options="options" />
  </div>
</template>

<script setup>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from "chart.js";
import { onMounted, ref, watch } from "vue";
import { Bar } from "vue-chartjs";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const props = defineProps({
  taxableIncomeChart: {
    type: Object,
    default: null
  },
  labels: {
    type: String,
    default: ""
  }
});
const chartData = ref(null);
const options = ref({
  responsive: true
});

watch(
  () => props.taxableIncomeChart,
  (newValue) => {
    let labels = [];
    let dataNumber = [];
    for (let x in newValue) {
      labels.push(x);
      dataNumber.push(newValue[x]);
    }
    chartData.value = {
      labels: labels,
      datasets: [
        {
          label: "Income",
          data: dataNumber,
          backgroundColor: "#00a7a7"
        }
      ]
    };
  },
  { deep: true }
);
onMounted(() => {
  let labels = [];
  let dataNumber = [];
  for (let x in props.taxableIncomeChart) {
    labels.push(x);
    dataNumber.push(props.taxableIncomeChart[x]);
  }
  chartData.value = {
    labels: labels,
    datasets: [
      {
        label: props.labels || "Income",
        data: dataNumber,
        backgroundColor: "#00a7a7"
      }
    ]
  };
});
</script>

<style scoped>
.chart {
  height: 250px;
}
</style>

<template>
  <div class="w-100">
    <div class="header d-flex w-100 align-items-center justify-content-between">
      <p class="header-title mb-0">Derivative Financial Instruments</p>
      <p class="header-title mb-0">
        {{
          NumberFormatter(totalNonCurrentAsset.derivative_financial_instruments)
        }}
      </p>
    </div>
    <form
      v-for="(item, index) in data"
      :id="item.model"
      :key="item.id"
      :class="`w-100 table-body ${!index || !(index % 2) ? 'white' : 'gray'} ${
        item.sub ? 'sub' : ''
      } d-flex align-items-center justify-content-between`"
      @submit="handleSubmit"
    >
      <p class="mb-0 body-title">
        {{ item.name }}
      </p>
      <p
        v-if="!item.showInput"
        :class="`body-title ${isHeader(item.model) ? 'bold' : ''} mb-0`"
        @click="openCloseInput(item.model, true)"
      >
        {{ NumberFormatter(NonCurrentAssetData[item.model]) }}
      </p>

      <input
        :id="'input-' + item.model"
        v-model="NonCurrentAssetData[item.model]"
        class="clickable-text"
        :hidden="!item.showInput"
        type="number"
        @focus="setOriginalValue(item.model, NonCurrentAssetData[item.model])"
        @keydown.tab="(e) => handleTab(e, item.model)"
        @blur="handleBlur(item.model, false)"
      />
    </form>
  </div>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { generate } from "randomized-string";
import { storeToRefs } from "pinia";
import { useFinancialDataStore } from "../../../../../store/financial.store";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";
import { useRoute, useRouter } from "vue-router";
import { autoSaveNonCurrentAssets } from "./helper";

const props = defineProps({
  view: {
    type: Boolean,
    default: false,
  },
  setSavingStatus: {
    type: Function,
    default: () => {},
  },
});
const route = useRoute();
const financialDataStore = useFinancialDataStore();
const { NonCurrentAssetData, totalNonCurrentAsset } =
  storeToRefs(financialDataStore); //get initialvalue from store
const router = useRouter();
const data = ref([
  {
    id: generate(),
    name: "1. Derivatives held for hedging",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "derivative_financial_instrumentsderivatives_held_for_hedging",
  },
  {
    id: generate(),
    name: "1.1 Cash-Flow Hedges",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "derivative_financial_instrumentscash_fl_hdg",
  },
  {
    id: generate(),
    name: "  a) Interest Rate Swaps",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "derivative_financial_instrumentscash_fl_hdg_interest_rate_swaps",
  },
  {
    id: generate(),
    name: "  b) Currency Forwards",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "derivative_financial_instrumentscash_fl_hdg_currency_forwds",
  },
  {
    id: generate(),
    name: "1.2 Fair Value Hedge",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "derivative_financial_instrumentsfair_vl_hdg",
  },
  {
    id: generate(),
    name: "  a) Currency Forwards",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "derivative_financial_instrumentsfair_vl_hdg_crncy_forwards",
  },
  {
    id: generate(),
    name: "2. Derivatives not held for hedging",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "derivative_financial_instderivatives_not_held_for_hedging",
  },
  {
    id: generate(),
    name: "  a) Currency Forwards",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "derivative_financial_instderivatives_nt_hld_for_hdg_crncy_forwds",
  },
]);
const getValue = (key) => {
  return Number(NonCurrentAssetData.value[key] || 0);
};

//2. Cash-Flow Hedges
NonCurrentAssetData.value.derivative_financial_instrumentscash_fl_hdg =
  computed(
    () =>
      getValue(
        "derivative_financial_instrumentscash_fl_hdg_interest_rate_swaps"
      ) +
      getValue("derivative_financial_instrumentscash_fl_hdg_currency_forwds")
  );
//3. Fair Value Hedge
NonCurrentAssetData.value.derivative_financial_instrumentsfair_vl_hdg =
  computed(() =>
    getValue("derivative_financial_instrumentsfair_vl_hdg_crncy_forwards")
  );
// 1. Derivatives held for hedging
NonCurrentAssetData.value.derivative_financial_instrumentsderivatives_held_for_hedging =
  computed(
    () =>
      getValue("derivative_financial_instrumentscash_fl_hdg") +
      getValue("derivative_financial_instrumentsfair_vl_hdg")
  );
// 4. Derivatives not held for hedging
NonCurrentAssetData.value.derivative_financial_instderivatives_not_held_for_hedging =
  computed(() =>
    getValue("derivative_financial_instderivatives_nt_hld_for_hdg_crncy_forwds")
  );

//Derivative Financial Instruments
totalNonCurrentAsset.value.derivative_financial_instruments = computed(
  () =>
    getValue("derivative_financial_instrumentsderivatives_held_for_hedging") +
    getValue("derivative_financial_instderivatives_not_held_for_hedging")
);

// watch(
//   NonCurrentAssetData,
//   async (newValue) => {
//     //2. Cash-Flow Hedges
//     NonCurrentAssetData.value.derivative_financial_instrumentscash_fl_hdg =
//       (await getValue(
//         "derivative_financial_instrumentscash_fl_hdg_interest_rate_swaps",
//         newValue
//       )) +
//       getValue(
//         "derivative_financial_instrumentscash_fl_hdg_currency_forwds",
//         newValue
//       );
//     //3. Fair Value Hedge
//     NonCurrentAssetData.value.derivative_financial_instrumentsfair_vl_hdg =
//       await getValue(
//         "derivative_financial_instrumentsfair_vl_hdg_crncy_forwards",
//         newValue
//       );
//     // 1. Derivatives held for hedging
//     NonCurrentAssetData.value.derivative_financial_instrumentsderivatives_held_for_hedging =
//       (await getValue(
//         "derivative_financial_instrumentscash_fl_hdg",
//         newValue
//       )) + getValue("derivative_financial_instrumentsfair_vl_hdg", newValue);
//     // 4. Derivatives not held for hedging
//     NonCurrentAssetData.value.derivative_financial_instderivatives_not_held_for_hedging =
//       await getValue(
//         "derivative_financial_instderivatives_nt_hld_for_hdg_crncy_forwds",
//         newValue
//       );
//     //Derivative Financial Instruments
//     totalNonCurrentAsset.value.derivative_financial_instruments =
//       getValue(
//         "derivative_financial_instrumentsderivatives_held_for_hedging",
//         newValue
//       ) +
//       getValue(
//         "derivative_financial_instderivatives_not_held_for_hedging",
//         newValue
//       );
//   },
//   { deep: true }
// );
const isHeader = (key) => {
  const keys = [
    "derivative_financial_instrumentsderivatives_held_for_hedging",
    "derivative_financial_instrumentscash_fl_hdg",
    "derivative_financial_instrumentsfair_vl_hdg",
    "derivative_financial_instderivatives_not_held_for_hedging",
  ];
  return !!keys.find((item) => item === key);
};
const openCloseInput = async (model, value) => {
  if (props.view || route.query.business === "master") {
    return;
  } else if (isHeader(model)) {
    return;
  }
  data.value = data.value.map((item) => {
    if (item.model === model) {
      return { ...item, showInput: value };
    }
    return item;
  });
  if (value) {
    const input = await document.getElementById(`input-${model}`);
    input.focus();
  }
};
const autoSave = async (model) => {
  const previousValue = data.value.find((item) => item.model === model).value;
  const currentValue = NonCurrentAssetData.value[model];
  await autoSaveNonCurrentAssets(
    previousValue,
    currentValue,
    props.setSavingStatus,
    route,
    router
  );
};
const handleSubmit = async (e) => {
  e.preventDefault();
  let current_model = e.target.id;
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
const handleTab = async (e, current_model) => {
  e.preventDefault();
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
const handleBlur = async (model, openClose) => {
  await openCloseInput(model, openClose);
  await autoSave(model);
};
const setOriginalValue = (key, value) => {
  const index = data.value.findIndex((item) => item.model === key);
  data.value[index].value = value;
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}

.width50 {
  width: 50% !important;
}
</style>

<template>
  <div id="Marquee" class="marquee mb-0">
    <p
      v-for="item in Texts"
      :id="`marquee-text-${item.id}`"
      :key="item.id"
      class="text-white text-center"
    >
      {{ item.text }}
    </p>
  </div>
</template>

<script setup>
import { onMounted, ref, onUnmounted, watch } from "vue";
import { generate } from "randomized-string";

const props = defineProps({
  texts: {
    type: Array,
    default: []
  }
});
const Texts = ref([]);
const counter = ref(0);
const marquee = ref(null);
const scrollToText = async (id) => {
  const Marquee = await document.getElementById(`Marquee`);
  const text = await document.getElementById(`marquee-text-${id}`);
  if (Marquee && text) {
    await Marquee.scrollTo({
      top: text.offsetTop,
      left: text.offsetLeft,
      behavior: "smooth"
    });
  }
};
onMounted(() => {
  Texts.value = props.texts.map((text) => ({
    id: generate(),
    text
  }));
  marquee.value = setInterval(() => {
    counter.value += 1;
    if (Texts.value[counter.value]) {
      scrollToText(Texts.value[counter.value]?.id);
    } else {
      counter.value = 0;
      scrollToText(Texts.value[0]?.id);
    }
  }, 10 * 1000);
});
watch(
  () => props.texts,
  (newValue) => {
    Texts.value = newValue.map((text) => ({
      id: generate(),
      text
    }));
  },
  { deep: true }
);
onUnmounted(async () => {
  await clearInterval(marquee.value);
});
</script>

<style scoped>
.marquee {
  position: sticky;
  width: 100%;
  bottom: 0;
  background: #0f75bc;
  height: 25px;
  overflow: scroll;
  margin-top: 20px;
  z-index: 2;
}
.marquee::-webkit-scrollbar {
  display: none;
}
.marquee {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>

<template>
  <div class="wrapper w-100">
    <div
      class="title-container pb-1 flex-wrap px-2 w-100 d-flex align-items-center justify-content-between"
    >
      <div class="d-flex align-items-center">
        <ThumbNail :title="companyName" />
        <div class="">
          <p class="mb-1 ml-3 username">{{ companyName }}</p>
        </div>
      </div>
    </div>
    <div class="pl-2 vh pr-5 mt-5">
      <div class="d-flex align-items-start justify-content-between">
        <p class="mb-0 computation">Computation</p>
        <select v-model="financial_year" class="p-2 select">
          <option
            v-for="(year, index) in financialYear.data"
            :key="year.id"
            class="option"
            :value="year.id"
            :selected="!index"
          >
            {{ year.name }}
          </option>
        </select>
      </div>
      <div class="d-flex align-items-center mt-4">
        <button class="btn-pdf mr-2" @click="downloadFile('PDF')">PDF</button>
        <button class="btn-pdf" @click="downloadFile('excel')">Excel</button>
      </div>
      <div class="d-flex align-items-start justify-content-between mt-4">
        <p class="mb-0 computation">Balance Sheet</p>
        <select v-model="financial_year" class="p-2 select">
          <option
            v-for="(year, index) in financialYear.data"
            :key="year.id"
            class="option"
            :value="year.id"
            :selected="!index"
          >
            {{ year.name }}
          </option>
        </select>
      </div>
      <div class="d-flex align-items-center mt-4">
        <button class="btn-pdf mr-2" @click="downloadBalanceSheet('PDF')">
          PDF
        </button>
        <button class="btn-pdf" @click="downloadBalanceSheet('Excel')">
          Excel
        </button>
      </div>

      <div class="d-flex align-items-start justify-content-between mt-4">
        <p class="mb-0 computation">Profit & Loss</p>
        <select v-model="financial_year" class="p-2 select">
          <option
            v-for="(year, index) in financialYear.data"
            :key="year.id"
            class="option"
            :value="year.id"
            :selected="!index"
          >
            {{ year.name }}
          </option>
        </select>
      </div>
      <div class="d-flex align-items-center mt-4">
        <button class="btn-pdf mr-2" @click="downloadProfitLoss('PDF')">
          PDF
        </button>
        <button class="btn-pdf" @click="downloadProfitLoss('Excel')">
          Excel
        </button>
      </div>
    </div>
    <QFZPButtonStatus
      v-if="license_authority_type == 2 && qualified_for_freezone === 'Y'"
    />
    <ButtonStatus v-else />
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useProfitAndLossStore } from "../../../store/ProfitAdLoss";
import { onMounted, ref, computed } from "vue";
import { useEntityStore } from "../../../store/entity";
import { useRoute } from "vue-router";
import { useComputeIncomeTaxStore } from "../../../store/incometax.store";
import { useBusinessStore } from "../../../store/buisness.store";
import { useFinancialDataStore } from "../../../store/financial.store";
import ButtonStatus from "./ButtonStatus.vue";
import { ThumbNail } from "../../common";
import { useIFRSStore } from "../../../store/NonIFRS/IFRS.store";
import QFZPButtonStatus from "./QFZPButtonStatus.vue";

const ifrsStore = useIFRSStore();
const profitAndLossStore = useProfitAndLossStore();
const financialDataStore = useFinancialDataStore();
const businessStore = useBusinessStore();
const { financial_year, financial_period } = storeToRefs(profitAndLossStore);
const { data, qualified_for_freezone, license_authority_type } =
  storeToRefs(ifrsStore);

const route = useRoute();
const entityStore = useEntityStore();
const { financialPeriod: financialYear, entity } = storeToRefs(entityStore);
const companyName = computed(() => {
  if (!entity.value.data?.entity) {
    return "";
  }
  const Entity = entity.value.data?.entity[0];
  if (Entity?.entity_type == 2) {
    return `${Entity?.entity_first_name} ${Entity?.entity_last_name}`;
  }
  return Entity?.entity_name;
});
const computeIncomeTaxStore = useComputeIncomeTaxStore();
const { current_business } = storeToRefs(businessStore);
onMounted(() => {
  const Financial_period = entity.value.data?.entity[0]?.financial_period?.id;
  financial_period.value = Financial_period
    ? Financial_period
    : financial_period.value;
});
const downloadFile = async (type) => {
  let qfzp =
    license_authority_type.value == 2 &&
    data.value === "IFRS" &&
    qualified_for_freezone.value === "Y";
  await computeIncomeTaxStore.downloadCOI(
    route.params.id,
    financial_year.value,
    financial_period.value,
    current_business.value,
    type,
    qfzp
  );
};
const downloadBalanceSheet = async (type = "Excel") => {
  await financialDataStore.saveBalanceSheetFile(
    route.params.id,
    financial_year.value,
    financial_period.value,
    current_business.value,
    type
  );
};
const downloadProfitLoss = async (type = "Excel") => {
  await profitAndLossStore.saveProfitAndLossFile(
    route.params.id,
    financial_year.value,
    financial_period.value,
    current_business.value,
    type
  );
};
</script>

<style scoped>
.wrapper {
  background: #deeded;
  min-height: 113vh;
  position: relative;
}

.title-container {
  min-height: 74px;
  border-bottom: 1px solid rgba(116, 113, 113, 0.3);
}
.username {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px; /* identical to box height */
  letter-spacing: 0.055em; /* FontBlack */
  color: #382628;
}
.computation {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px; /*
identical to box height */
  letter-spacing: 0.055em; /* FontBlack */
  color: #382628;
}
.select {
  width: 163px;
  height: 40px;
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
}
.btn-pdf {
  width: 51px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
@supports (-moz-appearance: none) {
  .wrapper {
    min-height: 90vh !important;
  }
}
</style>

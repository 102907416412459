<template>
  <v-dialog width="auto" v-model="toggle" content-class="my-custom-dialog">
    <div class="card-container">
      <div class="d-flex w-100 justify-content-end">
        <img
          src="@/assets/svg/close-circle.svg"
          class="cursor-pointer"
          alt=""
          @click="closeModal()"
        />
      </div>
      <h4 class="bold title">Upload {{ name }} details</h4>
      <div class="w-100 d-flex mt-4 flex-wrap align-items-center">
        <button
          class="btn-download px-3 mr-3 mt-2 d-flex align-items-center"
          @click="downloadSample()"
        >
          <img src="@/assets/svg/download.svg" class="mr-1" alt="" />
          <p class="mb-0 btn-text">Download Sample</p>
        </button>
        <button
          class="btn-download mt-2 px-3 d-flex align-items-center"
          @click="fileOpen(true)"
        >
          <img src="@/assets/svg/upload.svg" class="mr-1" alt="" />
          <p class="mb-0 btn-text">Upload File</p>
        </button>
      </div>
      <UploadFile
        v-if="showFileUpload"
        :callBack="props.callBack"
        :name="props.name"
        :type="props.type"
      />
    </div>
  </v-dialog>
</template>

<script setup>
import { ref, defineProps, watch } from "vue";
import { downloadURI } from "../../../../helpers/download";
import UploadFile from "./UploadFile.vue";
import { downloadEntitySample } from "../../../../constants/entity-upload.api";
const props = defineProps({
  open: { type: Boolean, required: true },
  type: { type: String, required: true },
  setOpen: { type: Function, required: true },
  name: { type: String, default: "" },
  callBack: { type: Function, default: () => {} }
});
const showFileUpload = ref(false);
const toggle = ref(props.open);

const fileOpen = (value) => {
  showFileUpload.value = value;
};

watch(
  () => props.open,
  () => {
    toggle.value = props.open;
  }
);
watch(
  toggle,
  (newValue) => {
    props.setOpen(newValue);
  },
  { deep: true }
);

const closeModal = () => {
  toggle.value = false;
  props.setOpen(false);
};
const downloadSample = async () => {
  const link = downloadEntitySample[props.type];
  await downloadURI(link);
};
</script>

<style scoped>
.card-container {
  max-width: 596px;
  background: #fafbff;
  border-radius: 5px;
  padding: 33px 27px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.card-container::-webkit-scrollbar {
  display: none;
}
.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.055em;
  color: #000000;
}
.btn-download {
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  gap: 13px;
}
.btn-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
</style>

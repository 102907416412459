<template>
  <div class="w-100">
    <CustomModalContainer :open="open.value" :set-open="setOpen">
      <div class="w-100 disclaimer-container p-3">
        <h4 class="note-text mb-0">
          <b class="text-warning warning-icon mr-3 mb-0">&#x26A0;</b>
          <span class="mb-0">Note</span>
        </h4>
        <p class="mb-0 body-title">
          {{ open.message }}
        </p>
      </div>
    </CustomModalContainer>
    <div v-if="showCalculator" class="wrapper">
      <Revenue
        :set-alert="setOpen"
        :disabled="disabled"
        :compare-value="true"
        :set-complete="setCompleted"
      />
      <Expense :set-alert="setOpen" :disabled="disabled" />
      <Total :set-alert="setOpen" :disabled="disabled" />
      <RevenueQFZP :set-alert="setOpen" :disabled="disabled" />
      <div
        class="total-container d-flex align-items-center justify-content-between"
      >
        <p
          v-if="options.qualified_for_freezone === 'Y'"
          class="mb-0 body-title w-50 bold text-success"
        >
          * As company qualifies De-minimis criteria and other requirements of
          QFZP, company is eligible for QFZP Exemption
        </p>
        <p v-else class="mb-0 body-title w-50 bold text-danger">
          * As company disqualifies De-minimis criteria and other requirements
          of QFZP, company is not eligible for QFZP Exemption
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import Expense from "../../calculator/QFZCalculator/Expense.vue";
import Revenue from "../../calculator/QFZCalculator/Revenue.vue";
import RevenueQFZP from "../../calculator/QFZCalculator/RevenueQFZP.vue";
import Total from "../../calculator/QFZCalculator/Total.vue";
import { useQFZCalculatorStore } from "../../../store/QFZcalculator";
import { storeToRefs } from "pinia";
import { useGeneralInfoStore } from "../../../store/generalInformation";
import { CustomModalContainer } from "../../common";

const props = defineProps({
  showCalculator: {
    type: [Boolean, String, Number],
    default: false,
  },
  setInput: {
    type: Function,
    default: () => {},
  },
  setValidity: {
    type: Function,
    default: () => {},
  },
  disabled: {
    type: [Boolean, String, Number],
    default: false,
  },
  setCompleted: {
    type: Function,
    default: () => {},
  },
});

const QFZCalculator = useQFZCalculatorStore();
const {
  totalRevenueNonQualifyingActivities,
  totalRevenue,
  options: calculatorOptions,
} = storeToRefs(QFZCalculator);
const generalInfoStore = useGeneralInfoStore();
const { options } = storeToRefs(generalInfoStore);
const open = ref({
  value: false,
  message: "",
});
const setOpen = (value, message = "") => {
  if (message) {
    open.value.message = message;
  }
  open.value.value = value;
};
const setValidity = async (
  totalRevenueNonQualifying,
  totalRevenue,
  calculatorOptionLineTwo
) => {
  const Percentage = (totalRevenueNonQualifying / totalRevenue) * 100 || 0;
  if (calculatorOptionLineTwo) {
    options.value.qualified_for_freezone = "N";
    return;
  }
  if (totalRevenueNonQualifying > 5 * 10 ** 6) {
    options.value.qualified_for_freezone = "N";
    return;
  }
  if (Percentage <= 5) {
    options.value.qualified_for_freezone = await "Y";
  } else {
    options.value.qualified_for_freezone = await "N";
  }
};
watch(
  [
    totalRevenueNonQualifyingActivities,
    totalRevenue,
    () =>
      calculatorOptions.value
        .the_company_has_selected_to_be_subject_to_corporate_tax,
  ],
  async ([
    newValTotalRevenueNQA,
    newValTotalRevenue,
    calculatorOptionLineTwo,
  ]) => {
    await setValidity(
      newValTotalRevenueNQA,
      newValTotalRevenue,
      calculatorOptionLineTwo
    );
  },
  { deep: true }
);
onMounted(async () => {
  await setValidity(
    Number(totalRevenueNonQualifyingActivities.value || 0),
    Number(totalRevenue.value || 0),
    calculatorOptions.value
      .the_company_has_selected_to_be_subject_to_corporate_tax
  );
});
</script>

<style lang="scss" scoped>
.save-button {
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.disclaimer-container {
  background: #deeded;
}
.note-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.warning-icon {
  font-size: 20px;
}
.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}
.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  border-bottom: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 26px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
</style>

<template>
  <LoaderContainer
    :loading="filingPlanner.loading || filingType.loading || entities.loading"
    :error="filingPlanner.error || filingType.error || entities.error"
  >
    <VDatePicker
      class="mt-4 calendar w-100"
      :attributes="attrs"
      @dayclick="handleDatePick"
    />
    <FilingModal
      :selected-date="selectedDate.date"
      :day="selectedDate.day"
      :open="modal.allModal"
      :set-open="setModal"
    />
    <EntityFilingModal
      :selected-date="selectedDate.date"
      :day="selectedDate.day"
      :open="modal.entityModal"
      :set-open="setModal"
      :entity-name="entityName"
      :entity="entity"
    />
  </LoaderContainer>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import LoaderContainer from "../../common/LoaderContainer.vue";
import { useFilingPlannerStore } from "../../../store/filingPlanner";
import FilingModal from "./FilingModal.vue";
import { storeToRefs } from "pinia";
import { useEntityStore } from "../../../store/entity";
import { getEntityItem } from "../../../store/entityActions/get";
import { getNumberOfDaysBetweenDates, dateNow } from "../../../helpers/date";
import EntityFilingModal from "./EntityFilingModal.vue";
const props = defineProps({
  entity: {
    type: String,
    default: "all",
  },
  entityName: {
    type: String,
    default: "all",
  },
});
const fillingPlannerStore = useFilingPlannerStore();
const { filingPlanner, filingType } = storeToRefs(fillingPlannerStore);
const entityStore = useEntityStore();
const { entities } = storeToRefs(entityStore);

const attrs = ref([]);
const modal = ref({
  allModal: false,
  entityModal: false,
});
const setModal = (value, type = "allModal") => {
  modal.value[type] = value;
};
const selectedDate = ref({
  date: null,
  day: null,
});
const handleDatePick = (dayAttribute) => {
  selectedDate.value.date = dayAttribute.id;
  selectedDate.value.day = dayAttribute.ariaLabel;
  if (props.entity === "all") return setModal(true);
  setModal(true, "entityModal");
};
const setData = (data) => {
  attrs.value = [
    {
      highlight: {
        color: "gray",
        fillMode: "light",
      },
      dates: Date.now(),
    },
    ...data.map((item) => ({
      key: item?.entity_id,
      highlight: {
        color:
          getNumberOfDaysBetweenDates(dateNow(), item?.filing_date) >= 0
            ? "green"
            : "red",
        fillMode: "solid",
      },

      dates: item?.filing_date,
    })),
  ];
};
watch(
  () => filingPlanner.value.data,
  (newValue) => {
    setData(newValue);
  },
  { deep: true }
);
onMounted(async () => {
  await fillingPlannerStore.getFilingTypes();
  await fillingPlannerStore.getFilingPlanner();
  if (!entities.value.data.length) {
    await getEntityItem("entities", "/entity");
  }
  setData(filingPlanner.value.data);
});
watch(
  () => props.entity,
  (newValue) => {
    if (newValue === "all") {
      return setData(filingPlanner.value.data);
    }
    const data = filingPlanner.value.data.filter(
      (item) => item.entity_id == newValue
    );
    setData(data);
  },
  { deep: true }
);
</script>

<style scoped></style>

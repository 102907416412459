<template>
  <div class="w-100">
    <div class="d-flex w-100 align-items-center justify-content-between">
      <h4 class="article flex-2 mb-0">Adjustment Required</h4>
      <div class="d-flex flex-1 justify-content-end">
        <v-switch
          v-model="data.article_36_adjustment_required"
          color="#0F75BC"
          inset
          hide-details
          :disabled="!!status"
        ></v-switch>
      </div>
    </div>
    <div
      v-if="data.article_36_adjustment_required"
      class="d-flex justify-content-between mt-4"
    >
      <h4 class="title bold">Separate Annexure</h4>
      <button v-if="!status" class="save-button mr-3" @click="addNewAnnexure">
        <img src="@/assets/svg/plus-square-light.svg" alt="" />
        Add New
      </button>
    </div>
    <div
      v-if="data.article_36_adjustment_required"
      class="w-100 table-responsive mt-4"
    >
      <table class="table table-bordered table-striped mt-2">
        <thead>
          <th class="table-header">Name of Connected Person</th>
          <th class="table-header">Connection with Person</th>
          <th class="table-header">Nature</th>
          <th class="table-header">Type of Transaction</th>
          <th class="table-header">Transaction Value</th>
          <th class="table-header">Market Price</th>
          <th class="table-header">Variation/Disallowance</th>
          <th class="table-header">Basis for Market Value</th>
          <th class="table-header">Adjusted in TI required</th>
          <th class="table-header px-3">Delete</th>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data.article_36_annexure" :key="item.id">
            <td class="align-middle table-rows pl-2">
              <input
                v-model="
                  data.article_36_annexure[index].name_of_connected_person
                "
                type="text"
                class="table-input"
                :disabled="!!status"
              />
            </td>
            <td class="table-rows align-middle">
              <input
                v-model="data.article_36_annexure[index].connection_with_person"
                type="text"
                class="table-input"
                :disabled="!!status"
              />
            </td>
            <td class="table-rows align-middle">
              <select
                v-model="data.article_36_annexure[index].nature"
                class="table-input w-100 p-2"
                :disabled="!!status"
              >
                <option value="expense">Expense</option>
                <option value="income">Income</option>
              </select>
            </td>
            <td class="table-rows align-middle">
              <input
                v-model="data.article_36_annexure[index].type_of_transaction"
                type="text"
                class="table-input"
                :disabled="!!status"
              />
            </td>
            <td class="table-row align-middle">
              <input
                v-model="data.article_36_annexure[index].transaction_value"
                type="number"
                class="table-input"
                :disabled="!!status"
                @keypress="validateAmount"
              />
            </td>
            <td class="table-row align-middle">
              <input
                v-model="data.article_36_annexure[index].market_price"
                type="number"
                class="table-input"
                :disabled="!!status"
                @keypress="validateAmount"
              />
            </td>
            <td class="table-rows align-middle">
              <p class="title mb-0 ml-3">{{ getVariation(index) }}</p>
            </td>

            <td class="table-row align-middle">
              <input
                v-model="data.article_36_annexure[index].basis_of_market_value"
                type="text"
                class="table-input"
                :disabled="!!status"
                @keypress="onlyAcceptAlphaNumeric"
              />
            </td>
            <td class="table-rows align-middle">
              <div
                class="d-flex pl-4 w-100 h-100 align-items-center justify-content-center"
              >
                <v-switch
                  v-model="
                    data.article_36_annexure[index].adjusted_in_ti_required
                  "
                  color="#0F75BC"
                  inset
                  hide-details
                  :disabled="!!status"
                ></v-switch>
              </div>
            </td>
            <td class="table-rows align-middle">
              <div
                class="w-100 d-flex align-items-center justify-content-between"
              >
                <button
                  v-if="!status"
                  class="btn btn-outline-info p-2 w-100"
                  @click="deleteItem(data.article_36_annexure[index]?.id)"
                >
                  <img src="@/assets/svg/trash.svg" alt="" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="data.article_36_adjustment_required"
      class="w-100 d-flex my-2 align-items-center justify-content-end"
    >
      <p class="title bold">
        Total Variation:
        {{ NumberFormatter(chapter10Store.totalVariationOffArticle36) }}
      </p>
    </div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-title
          collapse-icon="mdi-minus"
          expand-icon="mdi-plus"
        >
          <div
            class="px-3 py-3 w-100 d-flex align-items-center justify-content-between"
          >
            <h6 class="article-title mb-0">
              {{ article.name || "" }}
            </h6>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div v-html="article?.description"></div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script setup>
import { useChapter10Store } from "../../../../store/chapter10";
import { storeToRefs } from "pinia";
import { generate } from "randomized-string";
import { NumberFormatter } from "../../../../helpers/numberFormatter";
import { computed, ref, watch } from "vue";
import { useStatusStore } from "../../../../store/statusStore";
import { onlyAcceptAlphaNumeric } from "../../../../helpers/validation";

const chapter10Store = useChapter10Store();

const { data } = storeToRefs(chapter10Store);
const statusStore = useStatusStore();
const { status } = storeToRefs(statusStore);
const addNewAnnexure = () => {
  data.value.article_36_annexure.push({
    id: generate(),
    name_of_connected_person: "",
    connection_with_person: "",
    nature: "",
    type_of_transaction: "",
    transaction_value: "",
    market_price: "",
    variation: "",
    basis_of_market_value: "",
    adjusted_in_ti_required: "",
  });
};
const article = computed(
  () =>
    data.value.articles.find((item) => item.name.includes("Article 36")) || {
      name: "",
      article: "",
    }
);

const deleteItem = (id) => {
  if (confirm("Are you sure you want to delete this item")) {
    data.value.article_36_annexure = data.value.article_36_annexure.filter(
      (annexure) => annexure?.id != id
    );
  }
};
const getVariation = (index) => {
  const nature = data.value.article_36_annexure[index]?.nature;
  let transactionValue = Number(
    data.value.article_36_annexure[index]?.transaction_value || ""
  );
  let marketPrice = Number(
    data.value.article_36_annexure[index]?.market_price || ""
  );
  if (nature === "expense") {
    data.value.article_36_annexure[index].variation =
      transactionValue - marketPrice;
  } else if (nature === "income") {
    data.value.article_36_annexure[index].variation =
      marketPrice - transactionValue;
  }

  return data.value.article_36_annexure[index].variation;
};
</script>

<style scoped>
.table-header {
  min-height: 64px;
  width: 10%;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding: 5px;
  vertical-align: middle;
  border: 0.5px solid #ffffff;
}
.table-rows {
  background: #ffffff;
  border: 0.5px solid rgba(116, 113, 113, 0.5);
  padding: 0 !important;
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.text-note {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.table-input {
  padding: 10px;
  width: 100%;
}
.table-input:focus {
  outline: 0;
}
.table-input::placeholder {
  text-align: left;
}
.outline-none {
  outline: none !important;
}
.article {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
.flex-2 {
  flex: 6;
}
.flex-1 {
  flex: 1;
}
</style>

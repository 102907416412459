export const shareHolderData = [
  {
    model: "share_capital",
    name: "(a) Share Capital",
    sub: true,
  },
  {
    model: "reserves_and_surplus",
    name: "(b) Reserves and Surplus",
    sub: true,
  },
  {
    model: "share_application_money_pending_allotment",
    name: "Share application money pending allotment",
    sub: false,
  },
];

export const nonCurrentLiabilites = [
  {
    model: "long_term_borrowings",
    name: "(a) Long-term borrowings",
    sub: true,
  },
  {
    model: "deffered_tax_liabilities_net",
    name: "(b) Deferred tax liabilities (Net)",
    sub: true,
  },
  {
    model: "other_long_term_liabilities",
    name: "(c) Other Long term liabilities",
    sub: true,
  },
  {
    model: "long_term_provisions",
    name: "(d) Long term provisions",
    sub: true,
  },
];

export const currentLiabilites = [
  {
    model: "short_term_borrowings",
    name: "(a) Short-term borrowings",
    sub: true,
  },
  {
    model: "trade_payables",
    name: "(b) Trade payables",
    sub: true,
  },
  {
    model: "other_current_liabilities",
    name: "(c) Other current liabilities",
    sub: true,
  },
  {
    model: "short_term_provisions",
    name: "(d) Short-term provisions",
    sub: true,
  },
];

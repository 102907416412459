<template>
  <SideNavContainer title="Add Entity">
    <EntityChecker>
      <AddEntityTab active="bankingDetails" />
      <StatusChecker>
        <form @submit="handleSubmit">
          <div class="row wrapper">
            <div class="col-lg-4 col-md-6 mt-4">
              <CustomTextInput
                v-model="bank_name"
                label="Name of Bank"
                placeholder="Name of Bank"
                @keypress="onlyAcceptAlphaNumeric"
              />
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <CustomTextInput
                v-model="branch_name"
                label="Name of Branch"
                placeholder="Name of Branch"
                @keypress="onlyAcceptAlphaNumeric"
              />
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <CustomTextInput
                v-model="swift_code"
                placeholder="Swift Code"
                label="SWIFT Code"
                :error-message="error.swift_code"
              />
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <CustomTextInput
                v-model="branch_address"
                placeholder="Branch Address"
                label="Branch Address"
              />
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <CustomSelectInput
                v-model="account_type"
                label="Type of Account"
                placeholder="Account Type"
                :options="['Current', 'Savings']"
              />
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <CustomTextInput
                v-model="account_holder_name"
                label="Name of Account"
                placeholder="Name of Account"
                @keypress="onlyAcceptAlphabets"
              />
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <CustomTextInput
                v-model="account_no"
                label="Account Number "
                placeholder="Account Number"
                :error-message="error.account_no"
                @keypress="onlyAcceptDigits"
              />
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <CustomTextInput
                v-model="iban_no"
                type="text"
                label="IBAN Number"
                placeholder="IBAN Number"
                :error-message="error.iban_no"
                @keypress="onlyAcceptAlphaNumeric"
              />
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <h4 class="label mb-0">
                Is primary account <span class="text-danger">*</span>
              </h4>

              <div
                :class="`radio-container ${
                  error.primary_account ? 'error' : ''
                } w-100 d-flex align-items-center pl-1`"
              >
                <input
                  v-model="primary_account"
                  type="radio"
                  value="Y"
                  class=""
                />
                <p class="mb-0 yes">Yes</p>
                <input
                  v-model="primary_account"
                  type="radio"
                  name=""
                  value="N"
                  class=""
                />
                <p class="mb-0 yes">No</p>
              </div>
              <p v-if="error.primary_account" class="text-danger">
                {{ error.primary_account }}
              </p>
            </div>
            <p class="my-2 text-info">{{ message }}</p>
            <div class="d-flex align-items-center mt-10 mb-3">
              <button class="save-next mr-3" type="submit">Save & Next</button>
              <button
                class="cancel"
                type="button"
                @click="$router.push(`/entity/master-data/${$route.params.id}`)"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </StatusChecker>
    </EntityChecker>
  </SideNavContainer>
</template>

<script>
import { SideNavContainer } from "../../../../layout";
import { AddEntityTab } from "../../../../common";
import CustomSelectInput from "@/components/common/CustomSelectInput.vue";
import CustomTextInput from "../../../../common/CustomTextInput.vue";
import { addEntity } from "@/store/entityActions/post";
import EntityChecker from "../../EntityChecker.vue";
import { useEntityStore } from "../../../../../store/entity";
import { storeToRefs } from "pinia";
import { getEntityItem } from "@/store/entityActions/get";
import { updateEntity } from "../../../../../store/entityActions/put";
import { useGeneralInfoStore } from "../../../../../store/generalInformation";
import {
  IBANumberValidator,
  onlyAcceptAlphabets,
  onlyAcceptDigits,
  onlyAcceptAlphaNumeric,
} from "../../../../../helpers/validation";
import StatusChecker from "../../StatusChecker.vue";

export default {
  name: "BankingDetails",
  components: {
    SideNavContainer,
    AddEntityTab,
    CustomSelectInput,
    CustomTextInput,
    EntityChecker,
    StatusChecker,
  },
  setup() {
    const entityStore = useEntityStore();
    const { BankDetails } = storeToRefs(entityStore);
    const generalInfoStore = useGeneralInfoStore();
    return {
      BankDetails,
      generalInfoStore,
      IBANumberValidator,
      onlyAcceptAlphabets,
      onlyAcceptDigits,
      onlyAcceptAlphaNumeric,
    };
  },
  data() {
    return {
      bank_name: "",
      branch_name: "",
      swift_code: "",
      branch_address: "",
      account_type: "",
      account_holder_name: "",
      account_no: "",
      iban_no: "",
      primary_account: "",
      message: "",
      loading: true,
      error: {
        swift_code: false,
        iban_no: false,
        account_no: false,
        primary_account: false,
      },
    };
  },
  watch: {
    swift_code() {
      this.error.swift_code = false;
    },
    iban_no() {
      this.error.iban_no = false;
    },
    account_no() {
      this.error.account_no = false;
    },
    primary_account() {
      this.error.primary_account = false;
    },
  },
  async created() {
    const id = this.$route.params.sub_id;
    if (id) {
      await this.getSubId();
    }
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      const financial_year = localStorage.getItem("financial_year");
      if (!this.primary_account) {
        this.error.primary_account = "Field is required";
        return;
      }
      const readyData = {
        entity_id: this.$route.params.id,
        bank_name: this.bank_name,
        branch_name: this.branch_name,
        branch_address: this.branch_address,
        account_no: this.account_no,
        account_holder_name: this.account_holder_name,
        swift_code: this.swift_code,
        account_type: this.account_type,
        iban_no: this.iban_no,
        primary_account: this.primary_account,
      };
      if (
        (String(this.swift_code).length < 8 ||
          String(this.swift_code).length > 11) &&
        this.swift_code
      ) {
        this.error.swift_code =
          "Swift code should be between 8 to 11 characters";
        return;
      }
      // if (!String(this.iban_no).length > 28 && this.iban_no) {
      // this.error.iban_no =
      //"IBAN number should not be greater than 28 characters";
      // return;
      // }
      if (String(this.iban_no).length > 40 && this.iban_no) {
        this.error.iban_no = "IBAN number should be maximum 40 characters";
        return;
      }
      if (String(this.account_no).length > 20 && this.account_no) {
        this.error.account_no =
          "Account number should be maximum 20 characters";
        return;
      }
      if (this.edit) {
        await updateEntity(
          "/entityBankDetails",
          { ...readyData, bank_id: this.$route.params.sub_id },
          this.setMessage,
          async () => {
            await this.generalInfoStore.getGeneralInformation(
              this.$route.params.id,
              financial_year
            );
            setTimeout(() => {
              this.$router.push(
                "/entity/banking-details/" + this.$route.params.id
              );
            }, 2000);
          }
        );
        return;
      }
      await addEntity(
        "/entityBankDetails",
        readyData,
        this.setMessage,
        async ({ entity_id: entityId }) => {
          await this.generalInfoStore.getGeneralInformation(
            this.$route.params.id,
            financial_year
          );
          setTimeout(() => {
            this.$router.push("/entity/banking-details/" + entityId);
          }, 2000);
        }
      );
    },
    setMessage(message) {
      this.message = message;
    },
    async getSubId() {
      const id = this.$route.params.sub_id;
      await getEntityItem(
        "BankDetails",
        "/entityBankDetails",
        this.$route.params.id
      );
      const bank = this.BankDetails?.data?.find(
        (e) => Number(e.id) === Number(id)
      );
      if (bank) {
        this.edit = true;
        this.bank_name = bank?.bank_name;
        this.branch_name = bank?.branch_name;
        this.account_no = bank?.account_no;
        this.account_holder_name = bank?.account_holder_name;
        this.swift_code = bank?.swift_code;
        this.branch_address = bank?.branch_address;
        this.account_type = bank?.account_type;
        this.iban_no = bank?.iban_no;
        this.account_type = bank.account_type ? bank.account_type : "";
        this.primary_account = bank?.primary_account || "";
      }
    },
  },
};
</script>

<style scoped>
.save-next {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #fafbff;
  width: 196px;
  background: #0f75bc;
  height: 55px;
}
.cancel {
  background: #ffffff;
  border: 1px solid #747171;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #747171;
  height: 55px;
  width: 196px;
}
.radio-container {
  margin-top: 7px;
  height: 56px;
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  gap: 11px;
}
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
  margin-bottom: 18px;
}
.radio-container.error {
  border: 1px solid #ff0000;
}
</style>

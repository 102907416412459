<template>
  <div class="w-100">
    <div class="header d-flex align-items-center w-100 justify-content-between">
      <p class="header-title mb-0">
        Other Operating Revenue (Specify nature & amount)
      </p>
      <p class="header-title mb-0">
        {{ NumberFormatter(otherOperatingRevenue($route)) }}
      </p>
    </div>
    <form
      v-for="(item, index) in data"
      :id="item.model"
      :key="item.model"
      :class="`w-100 table-body ${!index || !(index % 2) ? 'white' : 'gray'} ${
        item.sub ? 'sub' : ''
      } d-flex align-items-center justify-content-between`"
      @submit="handleSubmit"
    >
      <p
        v-if="item.model !== 'revenue_other_operating_revenue_dividend_income'"
        class="mb-0 body-title"
      >
        {{ item.name }}
      </p>
      <p
        v-if="
          !item.showInput &&
          item.model !== 'revenue_other_operating_revenue_dividend_income'
        "
        :class="`body-title ${isHeader(item.model) ? 'bold' : ''} mb-0`"
        @click="openCloseInput(item.model, true)"
      >
        {{ NumberFormatter(other_operating_revenue[item.model]) }}
      </p>
      <input
        v-if="item.model !== 'revenue_other_operating_revenue_dividend_income'"
        :id="`input-${item.model}`"
        v-model="other_operating_revenue[item.model]"
        :hidden="
          !item.showInput ||
          item.model === 'revenue_other_operating_revenue_dividend_income'
        "
        type="number"
        class="clickable-text"
        @focus="
          setOriginalValue(item.model, other_operating_revenue[item.model])
        "
        @blur="handleBlur(item.model, false)"
      />
      <DividenIncome
        v-if="item.model === 'revenue_other_operating_revenue_dividend_income'"
        :set-saving-status="setSavingStatus"
      />
    </form>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useProfitAndLossStore } from "../../../../../store/ProfitAdLoss";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";
import { useRoute, useRouter } from "vue-router";
import { autoSaveIncome } from "./helper";
import DividenIncome from "./DividenIncome.vue";

const props = defineProps({
  view: {
    type: Boolean,
    default: false,
  },
  setSavingStatus: {
    type: Function,
    default: () => {},
  },
});

const route = useRoute();
const router = useRouter();
const data = ref([
  {
    model: "revenue_other_operating_revenue_interest_income",
    name: "1. Interest Income",
    showInput: false,
  },
  {
    model: "interest_earned",
    name: "1.1 Interest Earned",
    sub: true,
    showInput: false,
  },
  {
    model: "interest_on_leased_assets",
    name: "1.2  Interest on Leased Assets",
    sub: true,
    showInput: false,
  },
  {
    model: "others",
    name: "1.3 Others",
    sub: true,
    showInput: false,
  },
  {
    model: "revenue_other_operating_revenue_rent",
    name: "2. Rent",
    showInput: false,
  },
  {
    model: "revenue_other_operating_revenue_commission",
    name: "3. Commission",
    showInput: false,
  },
  {
    model: "revenue_other_operating_revenue_dividend_income",
    name: "4. Dividend Income",
    showInput: false,
  },
  {
    model: "revenue_other_operating_revenue_foreign_exchange_gain_loss",
    name: "5. Foreign Exchange Gain /Loss",
    showInput: false,
  },
  {
    model: "revenue_other_operating_revenue_income_from_participating_intrst",
    name: "6. Income from a Participating Interest",
    showInput: false,
  },
  {
    model: "revenue_other_operating_revenue_income_of_foreign_permanent_estb",
    name: "7. Income of a Foreign Permanent Establishment",
    showInput: false,
  },
  {
    model: "revenue_other_operating_revenue_any_other_income",
    name: "8. Any Other Income",
    showInput: false,
  },
]);
const profileAndLossStore = useProfitAndLossStore();
const { other_operating_revenue, otherOperatingRevenue } =
  storeToRefs(profileAndLossStore);

const isHeader = (key) => {
  return key === "revenue_other_operating_revenue_interest_income";
};
const getValue = (model) => {
  return Number(other_operating_revenue.value[model]) || 0;
};
// 1. Interest Income
other_operating_revenue.value.revenue_other_operating_revenue_interest_income =
  computed(() => {
    const keys = ["interest_earned", "interest_on_leased_assets", "others"];
    return keys.reduce((a, b) => a + getValue(b), 0);
  });
const openCloseInput = async (model, value) => {
  if (props.view || route.query.business === "master" || isHeader(model)) {
    return;
  }
  const newValues = data.value.map((item) => {
    if (item.model === model) {
      return { ...item, showInput: value };
    }
    return item;
  });
  data.value = newValues;
  if (value) {
    const input = await document.getElementById(`input-${model}`);
    input.focus();
  }
};

const autoSave = async (model) => {
  const previousValue = data.value.find((item) => item.model === model).value;
  const currentValue = other_operating_revenue.value[model];
  await autoSaveIncome(
    previousValue,
    currentValue,
    props.setSavingStatus,
    route,
    router
  );
};
const handleBlur = async (model, openClose) => {
  await openCloseInput(model, openClose);
  await autoSave(model);
};
const setOriginalValue = (key, value) => {
  const index = data.value.findIndex((item) => item.model === key);
  data.value[index].value = value;
};
const handleSubmit = async (e) => {
  e.preventDefault();
  let current_model = e.target.id;
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  await openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}

.sub-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}

.total-container {
  height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}

.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}

.clickable-text {
  text-align: right !important;
  border: none;
  border-bottom: 1px solid#000000;
  padding-bottom: 1px;
  padding-left: 4px;
  height: 100%;
}

.clickable-text:focus {
  outline: 0;
  border-bottom: 1px solid#0f75bc;
}
</style>

import { generate } from "randomized-string";

export const chapter10Data = {
  transaction_under_article_35_as_per_alp: false,
  article_35_adjustment_required: false,
  article_35_annexure: [
    {
      id: generate(),
      name_of_entity: "",
      jurisdiction_of_entity: "",
      type_of_transaction: "",
      transaction_value: 0,
      arm_length_price: 0,
      variation: 0,
      method_apply: "",
      adjusted_in_ti_required: "",
      calculation_of_alp: ""
    }
  ],
  article_35_total_variation: null,
  transaction_uder_article_36_as_per_market_value: false,
  article_36_adjustment_required: false,
  article_36_annexure: [
    {
      id: generate(),
      name_of_connected_person: "",
      connection_with_person: "",
      type_of_transaction: "",
      transaction_value: 0,
      market_price: 0,
      variation: 0,
      basis_of_market_value: 0,
      adjusted_in_ti_required: "",
      calculation_of_alp: ""
    }
  ],
  article_36_total_variation: null
};

<template>
  <SideNavContainer title="Add Entity">
    <SubscriptionChecker>
      <EntityChecker>
        <AddEntityTab active="entityDetails" />
        <Progress :active="3" />
        <LoaderContainer :loading="loading">
          <StatusChecker>
            <form @submit="handleSubmit">
              <div class="row wrapper px-2">
                <div class="col-lg-4 col-md-6 mt-4">
                  <h4 class="label mb-0">
                    Are you Registered Tax Group?
                    <span class="text-danger">*</span>
                  </h4>
                  <div
                    class="radio-container w-100 d-flex align-items-center pl-1"
                  >
                    <input
                      v-model="reg_tax_group"
                      :error-message="error.reg_tax_group"
                      type="radio"
                      value="Y"
                      class=""
                    />
                    <p class="mb-0 yes">Yes</p>
                    <input
                      v-model="reg_tax_group"
                      :error-message="error.reg_tax_group"
                      type="radio"
                      name=""
                      value="N"
                      class=""
                    />
                    <p class="mb-0 yes">No</p>
                  </div>
                  <p v-if="error.reg_tax_group" class="text-danger">
                    {{ error.reg_tax_group }}
                  </p>
                </div>
                <div class="col-lg-4 col-md-6 mt-4">
                  <CustomSelectInput
                    v-model="financial_period"
                    :options="financialYear.data"
                    item-title="name"
                    item-value="id"
                    label="Financial period *"
                    placeholder="select period"
                    :error-message="error.financial_period"
                  />
                </div>
                <div class="col-lg-4 col-md-6 mt-4">
                  <CustomTextInput
                    v-model="var_trn_details"
                    label="VAT TRN Details"
                    placeholder="VAT TRN Details"
                    type="text"
                    :error-message="error.var_trn_details"
                  />
                </div>
                <div class="col-lg-4 col-md-6 mt-4">
                  <CustomTextInput
                    v-model="corp_tax_trn_details"
                    label="Corporate Tax TRN Details"
                    placeholder="Corporate Tax TRN Details"
                    type="text"
                    :error-message="error.corp_tax_trn_details"
                  />
                </div>
                <div class="col-lg-4 col-md-6 mt-4">
                  <CustomFileUploadInput
                    v-model="vat_certificate"
                    :file-name="vat_certificate"
                    supported-file-text="*Supported file type - .png , .jpeg, .pdf. * Supported file size - 0 - 2 MB"
                    accept="image/png, image/jpeg, application/pdf"
                    :link="
                      vat_certificate ? `${imagePath}/${vat_certificate}` : ''
                    "
                    :error-message="error.vat_certificate"
                    label="VAT Certificate"
                    :input-props="{
                      onchange: (e) =>
                        handleFileChange(e, 'registration_certification'),
                      accept: 'image/png, image/jpeg, application/pdf',
                    }"
                  />
                </div>
                <div class="col-lg-4 col-md-6 mt-4">
                  <CustomFileUploadInput
                    v-model="corporate_tax"
                    label="Corporate tax"
                    :file-name="corporate_tax"
                    supported-file-text="*Supported file type - .png , .jpeg , .pdf. * Supported file size - 0 - 2 MB"
                    accept="image/png, image/jpeg, application/pdf"
                    :link="corporate_tax ? `${imagePath}/${corporate_tax}` : ''"
                    :error-message="error.corporate_tax"
                    :input-props="{
                      onchange: (e) =>
                        handleFileChange(e, 'registration_certification'),
                      accept: 'image/png, image/jpeg, application/pdf',
                    }"
                  />
                </div>
                <div class="col-lg-4 col-md-6 mt-4">
                  <CustomDateInput
                    v-model="issue_date"
                    label="Certificate Issue Date"
                    :max="dateNow()"
                    placeholder="License Number"
                  />
                </div>
                <div v-if="entity_type == 2" class="col-lg-4 col-md-6 mt-4">
                  <CustomSelectInput
                    v-model="fy_period_under_vat"
                    :options="financialYear.data"
                    item-title="name"
                    item-value="id"
                    label="FY Period Under VAT"
                    placeholder="FY Period Under VAT"
                  />
                </div>
              </div>
              <p class="my-2 text-info">{{ message }}</p>
              <div class="d-flex align-items-center mt-10">
                <button class="save-next mr-3" type="submit">
                  Save & Next
                </button>
                <button
                  class="cancel"
                  type="button"
                  @click="
                    $router.push('/entity/add-entity/entity-details/entity')
                  "
                >
                  Cancel
                </button>
              </div>
            </form>
          </StatusChecker>
        </LoaderContainer>
      </EntityChecker>
    </SubscriptionChecker>
  </SideNavContainer>
</template>

<script>
import { SideNavContainer } from "../../../../layout";
import { AddEntityTab, CustomDateInput } from "../../../../common";
import Progress from "./Progress.vue";
import CustomTextInput from "../../../../common/CustomTextInput.vue";
import SubscriptionChecker from "../../../../subscription/SubscriptionChecker.vue";
import { addEntity } from "../../../../../store/entityActions/post";
import EntityChecker from "../../EntityChecker.vue";
import {
  getEntityItem,
  getEntityItems,
} from "../../../../../store/entityActions/get";
import { useEntityStore } from "../../../../../store/entity";
import { storeToRefs } from "pinia";
import CustomSelectInput from "@/components/common/CustomSelectInput.vue";
import { dateNow } from "../../../../../helpers/date";
import LoaderContainer from "../../../../common/LoaderContainer.vue";
import { checkFileSize } from "../../../../../helpers/file";
import CustomFileUploadInput from "../../../../common/CustomFileUploadInput.vue";
import StatusChecker from "../../StatusChecker.vue";

export default {
  name: "AddEntityTaxDetails",
  components: {
    SideNavContainer,
    AddEntityTab,
    CustomTextInput,
    SubscriptionChecker,
    EntityChecker,
    CustomSelectInput,
    CustomDateInput,
    LoaderContainer,
    Progress,
    CustomFileUploadInput,
    StatusChecker,
  },
  setup() {
    const entityStore = useEntityStore();
    const { entity, financialYear } = storeToRefs(entityStore);
    return { entityStore, entity, financialYear, dateNow };
  },
  data() {
    return {
      entity_id: this.$route.params,
      message: "",
      edit: false,
      loading: true,
      imagePath: "",
      vc: "",
      ct: "",
      reg_tax_group: "",
      entity_type: "",
      var_trn_details: "",
      financial_period: "",
      corp_tax_trn_details: "",
      vat_certificate: "",
      corporate_tax: "",
      issue_date: "",
      fy_period_under_vat: "",
      error: {
        var_trn_details: false,
        corp_tax_trn_details: false,
        financial_period: false,
        vat_certificate: false,
        corporate_tax: false,
        reg_tax_group: "",
      },
    };
  },
  watch: {
    reg_tax_group() {
      this.error.reg_tax_group = false;
    },
    var_trn_details() {
      this.error.var_trn_details = false;
    },
    corp_tax_trn_details() {
      this.error.corp_tax_trn_details = false;
    },
    financial_period() {
      this.error.financial_period = false;
    },
  },
  async created() {
    this.loading = true;
    const id = this.$route.params.sub_id;
    await getEntityItem("entity", "/entity", this.$route.params.id);
    const Entity = this.entity?.data?.entity?.find(
      (e) => Number(e.id) === Number(this.$route.params.id)
    );
    this.entity_type = Entity?.entity_type;
    if (!this.financialYear.data.length) {
      await getEntityItems("financialYear", "/financialYear");
    }
    if (id) {
      this.getSubId();
    }
    this.loading = false;
  },
  methods: {
    handleFileChange(e, model) {
      this.error[model] = "";
    },
    async handleSubmit(e) {
      e.preventDefault();

      const formData = new FormData();

      formData.append("reg_tax_group", this.reg_tax_group);
      formData.append("var_trn_details", this.var_trn_details);
      formData.append("financial_period", this.financial_period);
      formData.append("corp_tax_trn_details", this.corp_tax_trn_details);
      formData.append("issue_date", this.issue_date);
      formData.append("fy_period_under_vat", this.fy_period_under_vat);

      formData.append("entity_id", this.$route.params.id);
      if (typeof this.corporate_tax !== "string" && this.corporate_tax) {
        formData.append("corporate_tax", this.corporate_tax);
      }
      if (typeof this.vat_certificate !== "string" && this.vat_certificate) {
        formData.append("vat_certificate", this.vat_certificate);
      }
      if (typeof this.corporate_tax === "object") {
        if (!checkFileSize(this.corporate_tax)) {
          this.error.corporate_tax = "File size should be less than 2MB";
          return;
        }
      }
      if (typeof this.vat_certificate === "object") {
        if (!checkFileSize(this.vat_certificate)) {
          this.error.vat_certificate = "File size should be less than 2MB";
          return;
        }
      }
      if (!this.reg_tax_group) {
        this.error.reg_tax_group = "Field is required!";
        return;
      }
      if (!this.financial_period) {
        this.error.financial_period = "Financial period is required!";
        return;
      }

      if (this.var_trn_details && String(this.var_trn_details).length !== 15) {
        this.error.var_trn_details =
          "VAT TRN Details should be equal 15 characters";
        return;
      }
      if (
        this.corp_tax_trn_details &&
        String(this.corp_tax_trn_details).length !== 15
      ) {
        this.error.corp_tax_trn_details =
          "Corporate Tax TRN Details should be equal 15 characters";
        return;
      }
      if (this.edit) {
        const id = Number(this.$route.params.sub_id);
        formData.append("entity_tax_id", id);
        formData.append("_method", "PUT");
        await addEntity(
          "/entityTax",
          formData,
          this.setMessage,
          async () => {
            await getEntityItem("entity", "/entity", this.$route.params.id);

            const Entity = this.entity?.data?.business?.find(
              (e) => Number(e.entity_id) === Number(this.$route.params.id)
            );
            const id = Entity?.id;
            if (id) {
              this.$router.push(
                `/entity/add-entity/entity-details/business/${this.$route.params.id}/${id}`
              );
            } else {
              this.$router.push(
                `/entity/add-entity/entity-details/business/${this.$route.params.id}`
              );
            }
          },
          true
        );
        return;
      }

      addEntity(
        "/entityTax",
        formData,
        this.setMessage,
        async ({ entity_id: entityId }) => {
          await getEntityItem("entity", "/entity", entityId);
          this.$router.push(
            "/entity/add-entity/entity-details/business/" + entityId
          );
        },
        true
      );
    },
    async getSubId() {
      const id = this.$route.params.sub_id;
      await getEntityItem("entity", "/entity", this.$route.params.id);
      const Entity = this.entity?.data?.tax?.find(
        (e) => Number(e.id) === Number(id)
      );

      if (Entity) {
        this.edit = true;
        this.reg_tax_group = Entity?.reg_tax_group || "";
        this.var_trn_details = Entity?.var_trn_details || "";
        this.corp_tax_trn_details = Entity?.corp_tax_trn_details || "";
        this.financial_period = Number(Entity?.financial_period?.id) || "";
        this.issue_date = Entity?.issue_date || "";
        this.corporate_tax = Entity?.corporate_tax || "";
        this.imagePath = Entity?.imagepath || "";
        this.vat_certificate = Entity?.vat_certificate || "";
        this.fy_period_under_vat =
          Number(Entity?.fy_period_under_vat?.id) || "";
      }
    },
    setMessage(message) {
      this.message = message;
    },
  },
};
</script>

<style scoped>
.save-next {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #fafbff;
  width: 196px;
  background: #0f75bc;
  height: 55px;
}
.cancel {
  background: #ffffff;
  border: 1px solid #747171;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #747171;
  height: 55px;
  width: 196px;
}
.radio-container {
  margin-top: 7px;
  height: 56px;
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  gap: 11px;
}
.yes {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
  margin-bottom: 18px;
}
</style>

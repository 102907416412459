<template>
  <LoaderContainer :loading="loading">
    <CustomTable :Data="Entity" :Headers="header" />
  </LoaderContainer>
</template>

<script setup>
import CustomTable from "../../../common/CustomTable.vue";
import { onMounted, ref } from "vue";
import { useEntityStore } from "../../../../store/entity";
import { storeToRefs } from "pinia";
import {
  getEntityItem,
  getEntityAttribute,
} from "../../../../store/entityActions/get";
import { useCountryStore } from "../../../../store/country";
import { dateMDY } from "../../../../helpers/date";
import { useRoute } from "vue-router";
import {
  naturalPersonHeaders,
  legalPersonHeader,
  partnerShipHeaders,
} from "./entity-details.data";
import { LoaderContainer } from "../../../common";
const header = ref([]);
const entityStore = useEntityStore();
const { entity } = storeToRefs(entityStore);
const route = useRoute();
const countryStore = useCountryStore();
const { countries, states } = storeToRefs(countryStore);
const Entity = ref([]);
const loading = ref(true);
const getEntity = async () => {
  const id = route.params.id;
  if (entity.value.data?.entity) {
    const check = entity.value.data?.entity?.find(
      (e) => Number(e.id) === Number(id)
    );
    if (!check) {
      await getEntityItem("entity", "/entity", id);
    }
  } else {
    await getEntityItem("entity", "/entity", id);
  }
};
onMounted(async () => {
  await getEntity();
  const id = route.params.id;
  const findEntity = entity.value.data?.entity?.find(
    (e) => Number(e.id) === Number(id)
  );

  const typeName = await getEntityAttribute(
    "type",
    "/entityType",
    findEntity?.entity_type
  );

  const subTypeName = await getEntityAttribute(
    "subType",
    `/entityType/${findEntity?.entity_type}`,
    findEntity?.entity_sub_type,
    "name",
    true
  );

  const placeOfIncorporation = await countryStore.getCountryName(
    findEntity?.place_of_incorporation
  );
  if (findEntity?.entity_type == 2) {
    header.value = naturalPersonHeaders.map((item) => item.label);
  } else if (findEntity?.entity_type == 1) {
    header.value = legalPersonHeader.map((item) => item.label);
  } else if (findEntity?.entity_type == 3) {
    header.value = partnerShipHeaders.map((item) => item.label);
  }
  await countryStore.getStateList(findEntity?.place_of_establishment?.id);
  Entity.value = [findEntity].map((e) => {
    let data = {
      id: e?.id,
      type: typeName,
      sub_type: subTypeName,
      entity_first_name: e?.entity_first_name,
      entity_last_name: e?.entity_last_name,
      name: e?.entity_name,
      DOI: dateMDY(e?.date_of_incorporation),
      DOCOB: dateMDY(e?.date_of_commencement_of_business),
      trade_name: e?.trade_name,
      place_of_incorporation: placeOfIncorporation,
      qualifying_benefit_entity:
        findEntity?.qualifying_benefit_entity === "Y" ? "Yes" : "No",
      decree_law_number: findEntity?.decree_law_number,
      decree_law_number_file: {
        type: "image",
        url: findEntity?.decree_law_number_file
          ? `${findEntity?.decreelaw_imagepath}/${findEntity?.decree_law_number_file}`
          : null,
      },
    };
    if (findEntity?.entity_type == 2) {
      //natural person
      let entityDetailsData = {};
      naturalPersonHeaders.forEach((item) => {
        entityDetailsData[item.key] = data[item.key];
      });
      return { id: e?.id, ...entityDetailsData };
    } else if (findEntity?.entity_type == 1) {
      //legal person
      let entityDetailsData = {};
      legalPersonHeader.forEach((item) => {
        if (item.key === "decree_law_number") {
          return (entityDetailsData[item.key] =
            data.qualifying_benefit_entity === "Yes" ? data[item.key] : "");
        } else if (item.key === "decree_law_number_file") {
          return (entityDetailsData[item.key] =
            data.qualifying_benefit_entity === "Yes" ? data[item.key] : "");
        }
        entityDetailsData[item.key] = data[item.key];
      });
      return { id: e?.id, ...entityDetailsData };
    } else if (findEntity?.entity_type == 3) {
      let entityDetailsData = {};
      partnerShipHeaders.forEach((item) => {
        entityDetailsData[item.key] = data[item.key];
      });
      return { id: e?.id, ...entityDetailsData };
    }
  });
  loading.value = false;
});
</script>

<style lang="scss" scoped></style>

<template>
  <div class="w-100">
    <div class="header d-flex align-items-center w-100 justify-content-between">
      <p class="header-title mb-0">Continuing Operations</p>
    </div>
    <div
      class="table-body d-flex align-items-center w-100 justify-content-between"
    >
      <p class="sub-title mb-0">Revenue</p>
      <p class="total-amount mb-0">
        {{ NumberFormatter(Revenue($route)) }}
      </p>
    </div>

    <form
      v-for="(item, index) in data"
      :id="item.model"
      :key="item.model"
      :class="`w-100 table-body ${!index || !(index % 2) ? 'gray' : 'white'} ${
        item.sub ? 'sub' : ''
      } d-flex align-items-center justify-content-between`"
    >
      <p class="mb-0 body-title">{{ item.name }}</p>
      <p
        v-if="!item.showInput"
        class="body-title mb-0"
        @click="openCloseInput(item.model, true)"
      >
        {{ NumberFormatter(sales[item.model]) }}
      </p>
      <input
        :id="`input-${item.model}`"
        v-model="sales[item.model]"
        :hidden="!item.showInput"
        type="number"
        class="clickable-text"
        @focus="setOriginalValue(item.model, sales[item.model])"
        @blur="handleBlur(item.model, false)"
        @keydown.tab.enter="(e) => handleSubmit(e, item.model)"
      />
    </form>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useProfitAndLossStore } from "../../../../../store/ProfitAdLoss";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";
import { useRoute, useRouter } from "vue-router";
import { autoSaveIncome } from "./helper";

const props = defineProps({
  view: {
    type: Boolean,
    default: false,
  },
  setSavingStatus: {
    type: Function,
    default: () => {},
  },
});

const route = useRoute();
const router = useRouter();
const data = ref([
  {
    name: "1. Sale of Goods",
    sub: false,
    model: "revenue_sale_of_good",
    showInput: false,
  },
  {
    model: "revenue_sale_of_service",
    name: "2. Sale of Services",
    sub: false,
    showInput: false,
    amount: "0000.00",
  },
]);
const profileAndLossStore = useProfitAndLossStore();
const { sales, Revenue } = storeToRefs(profileAndLossStore);
const openCloseInput = async (model, value) => {
  if (props.view || route.query.business === "master") {
    return;
  }
  const newValues = data.value.map((item) => {
    if (item.model === model) {
      return { ...item, showInput: value };
    }
    return item;
  });
  data.value = newValues;
  if (value) {
    const input = await document.getElementById(`input-${model}`);
    input.focus();
  }
};

const autoSave = async (model) => {
  const previousValue = data.value.find((item) => item.model === model).value;
  const currentValue = sales.value[model];
  await autoSaveIncome(
    previousValue,
    currentValue,
    props.setSavingStatus,
    route,
    router
  );
};
const handleBlur = async (model, openClose) => {
  await openCloseInput(model, openClose);
  await autoSave(model);
};
const setOriginalValue = (key, value) => {
  const index = data.value.findIndex((item) => item.model === key);
  data.value[index].value = value;
};
const handleSubmit = async (e, model) => {
  e.preventDefault();
  let current_model = model;
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  await openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}

.sub-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}

.total-container {
  height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}

.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}

.clickable-text {
  text-align: right !important;
  border: none;
  border-bottom: 1px solid#000000;
  padding-bottom: 1px;
  padding-left: 4px;
  height: 100%;
}

.clickable-text:focus {
  outline: 0;
  border-bottom: 1px solid#0f75bc;
}
</style>

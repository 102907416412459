<template>
  <v-otp-input
    ref="otpInput"
    input-classes="otp-input"
    separator=""
    :num-inputs="4"
    :should-auto-focus="true"
    :is-input-num="true"
    :conditionalClass="['one', 'two', 'three', 'four']"
    :placeholder="['', '', '', '']"
    @on-change="handleOnChange"
    @on-complete="handleOnComplete"
  />
</template>

<script>
import VOtpInput from "vue3-otp-input";
import { ref, defineComponent } from "vue";

export default defineComponent({
  name: "OTP",
  components: {
    VOtpInput,
  },
  props: {
    HandleComplete: Function,
    HandleOnChange: Function,
  },
  setup(props) {
    const otpInput = ref(null);

    const handleOnComplete = (value) => {
      props?.HandleComplete(value);
    };

    const handleOnChange = (value) => {
      props?.HandleOnChange(value);
    };

    const clearInput = () => {
      otpInput.value.clearInput();
    };

    return { handleOnComplete, handleOnChange, clearInput, otpInput };
  },
});
</script>

<style>
.otp-input {
  width: 62px !important;
  height: 62px !important;
  padding: 5px !important;
  background: #ffffff;
  border: 1px solid #e1e8f0;
  border-radius: 7px;
  text-align: center;
  margin-right: 11px;
}
/* Background colour of an input field with value */
.otp-input.is-complete {
  background-color: #e4e4e4;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
</style>

<template>
  <div class="w-100">
    <div class="header d-flex align-items-center w-100 justify-content-between">
      <p class="header-title mb-0">Other reserves</p>
      <p class="header-title mb-0">
        {{ NumberFormatter(totalShareHolderEquity.other_reserves) }}
      </p>
    </div>
    <form
      v-for="(item, index) in data"
      :id="item.model"
      :key="item.id"
      :class="`w-100 table-body ${!index || !(index % 2) ? 'white' : 'gray'} ${
        item.sub ? 'sub' : ''
      } d-flex align-items-center justify-content-between`"
      @submit="handleSubmit"
    >
      <p class="mb-0 body-title">
        {{ item.name }}
      </p>
      <p
        v-if="!item.showInput"
        :class="`body-title ${isHeader(item.model) ? 'bold' : ''} mb-0`"
        @click="openCloseInput(item.model, true)"
      >
        {{ NumberFormatter(shareHolderLiability[item.model]) }}
      </p>

      <input
        :id="'input-' + item.model"
        v-model="shareHolderLiability[item.model]"
        :hidden="!item.showInput"
        type="number"
        class="clickable-text"
        @blur="openCloseInput(item.model, false)"
        @keydown.tab="(e) => handleTab(e, item.model)"
      />
    </form>
  </div>
</template>

<script setup>
import { generate } from "randomized-string";
import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useLiabilityDataStore } from "../../../../../store/liabities.store";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";
import { useRoute } from "vue-router";
const props = defineProps({
  view: {
    type: Boolean,
    default: false,
  },
});
const route = useRoute();
const liabilityDataStore = useLiabilityDataStore();
const { shareHolderLiability, totalShareHolderEquity } =
  storeToRefs(liabilityDataStore); //get initialvalue from store

const data = ref([
  {
    id: generate(),
    name: "1.Share option reserve",
    sub: false,
    amount: "0000.00",
    model: "othr_rsrvs_shr_optn_rsrv",
  },
  {
    id: generate(),
    name: "1.1 Beginning of the financial year",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "othr_rsrvs_shr_optn_rsrv_begining_of_the_financial_year",
  },
  {
    id: generate(),
    name: "1.2  Employee share option scheme",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "othr_rsrvs_shr_optn_rsrv_employee_share_option_scheme",
  },
  {
    id: generate(),
    name: "2.Capital reserve",
    sub: false,
    amount: "0000.00",
    model: "othr_rsrvs_cptl_rsrv",
  },
  {
    id: generate(),
    name: "2.1 Beginning of the financial year",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "othr_rsrvs_cptl_rsrv_begining_of_the_financial_year",
  },
  {
    id: generate(),
    name: "2.2 Gain on re-issue",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "othr_rsrvs_cptl_rsrv_gain_on_reissue",
  },
  {
    id: generate(),
    name: "2.3    Excess tax on employee share option scheme",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "othr_rsrvs_cptl_rsrv_excess_tax_on_emply_shr_optn_scheme",
  },
  {
    id: generate(),
    name: "3.Fair value reserve",
    sub: false,
    amount: "0000.00",
    model: "othr_rsrvs_fair_value_reserve",
  },
  {
    id: generate(),
    name: "3.1     Beginning of the financial year",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "othr_rsrvs_fair_value_reserve_begining_of_the_fncal_yr",
  },
  {
    id: generate(),
    name: "3.2 Fair value gains/(losses)",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "othr_rsrvs_fair_value_reserve_fair_value_gain_losses",
  },
  {
    id: generate(),
    name: "4.Hedging reserve",
    sub: false,
    amount: "0000.00",
    model: "othr_rsrvs_hedging_reserve",
  },
  {
    id: generate(),
    name: "4.1 Beginning of the financial year",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "othr_rsrvs_hedging_reserve_begining_of_the_financial_year",
  },
  {
    id: generate(),
    name: "4.2  Fair value gains/(losses)",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "othr_rsrvs_hedging_reserve_fair_value_gain_losses",
  },
  {
    id: generate(),
    name: "4.3 Tax on reclassification adjustments",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "othr_rsrvs_hedging_reserve_tax_on_rclassfctn_adjustments",
  },
  {
    id: generate(),
    name: "5.Currency translation reserve",
    sub: false,
    amount: "0000.00",
    model: "othr_rsrvs_currency_translation_reserve",
  },
  {
    id: generate(),
    name: "5.1   Beginning of the financial year",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "othr_rsrvs_currency_translation_reserve_bgng_of_the_fncal_yr",
  },
  {
    id: generate(),
    name: "5.2  Net currency translation differences",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "othr_rsrvs_currency_translation_reserve_net_crncy_tnsln_dffrnc",
  },
  {
    id: generate(),
    name: "6.Equity component of convertible bonds",
    sub: false,
    amount: "0000.00",
    model: "othr_rsrvs_equity_component_of_convertible_bonds",
  },
  {
    id: generate(),
    name: "7.Asset revaluation reserve",
    sub: false,
    amount: "0000.00",
    model: "othr_rsrvs_assets_revaluation_reserve",
  },
  {
    id: generate(),
    name: "7.1   Beginning of the financial year",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "othr_rsrvs_assets_revaluation_reserve_begining_of_the_fncal_yr",
  },
  {
    id: generate(),
    name: "7.2   Revaluation gains",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "othr_rsrvs_assets_revaluation_reserve_revaluation_gains",
  },
  {
    id: generate(),
    name: "7.3   Tax on revaluation gains",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "othr_rsrvs_assets_revaluation_reserve_tax_on_revaluation_gains",
  },
]);

const getValue = (key) => {
  return Number(shareHolderLiability.value[key]) || 0;
};
const isHeader = (key) => {
  const keys = [
    "othr_rsrvs_shr_optn_rsrv", //1
    "othr_rsrvs_cptl_rsrv", // 2
    "othr_rsrvs_fair_value_reserve", // 3
    "othr_rsrvs_hedging_reserve", //4
    "othr_rsrvs_currency_translation_reserve", // 5
    "othr_rsrvs_assets_revaluation_reserve", //7
  ];
  return !!keys.find((k) => k === key);
};
// 1.Share option reserve
shareHolderLiability.value.othr_rsrvs_shr_optn_rsrv = computed(() => {
  const keys = [
    "othr_rsrvs_shr_optn_rsrv_begining_of_the_financial_year",
    "othr_rsrvs_shr_optn_rsrv_employee_share_option_scheme",
  ];
  return keys.reduce((a, b) => a + getValue(b), 0);
});
// 2.Capital reserve
shareHolderLiability.value.othr_rsrvs_cptl_rsrv = computed(() => {
  const keys = [
    "othr_rsrvs_cptl_rsrv_begining_of_the_financial_year",
    "othr_rsrvs_cptl_rsrv_excess_tax_on_emply_shr_optn_scheme",
    "othr_rsrvs_cptl_rsrv_gain_on_reissue",
  ];
  return keys.reduce((a, b) => a + getValue(b), 0);
});

//3.Fair value reserve
shareHolderLiability.value.othr_rsrvs_fair_value_reserve = computed(() => {
  const keys = [
    "othr_rsrvs_fair_value_reserve_begining_of_the_fncal_yr",
    "othr_rsrvs_fair_value_reserve_fair_value_gain_losses",
  ];
  return keys.reduce((a, b) => a + getValue(b), 0);
});

// 4.Hedging reserve
shareHolderLiability.value.othr_rsrvs_hedging_reserve = computed(() => {
  const keys = [
    "othr_rsrvs_hedging_reserve_begining_of_the_financial_year",
    "othr_rsrvs_hedging_reserve_fair_value_gain_losses",
    "othr_rsrvs_hedging_reserve_tax_on_rclassfctn_adjustments",
  ];
  return keys.reduce((a, b) => a + getValue(b), 0);
});

// 5.Currency translation reserve
shareHolderLiability.value.othr_rsrvs_currency_translation_reserve = computed(
  () => {
    const keys = [
      "othr_rsrvs_currency_translation_reserve_bgng_of_the_fncal_yr",
      "othr_rsrvs_currency_translation_reserve_net_crncy_tnsln_dffrnc",
    ];
    return keys.reduce((a, b) => a + getValue(b), 0);
  }
);

// 7.Asset revaluation reserve
shareHolderLiability.value.othr_rsrvs_assets_revaluation_reserve = computed(
  () => {
    const keys = [
      "othr_rsrvs_assets_revaluation_reserve_begining_of_the_fncal_yr",
      "othr_rsrvs_assets_revaluation_reserve_revaluation_gains",
      "othr_rsrvs_assets_revaluation_reserve_tax_on_revaluation_gains",
    ];
    return keys.reduce((a, b) => {
      if (
        b === "othr_rsrvs_assets_revaluation_reserve_tax_on_revaluation_gains"
      ) {
        return a - getValue(b);
      }
      return a + getValue(b);
    }, 0);
  }
);

totalShareHolderEquity.value.other_reserves = computed(() => {
  let sum = data.value.reduce((a, b) => {
    if (isHeader(b.model)) {
      return a + getValue(b.model);
    }
    return a;
  }, 0);
  return sum + getValue("othr_rsrvs_equity_component_of_convertible_bonds");
});

const openCloseInput = async (model, value) => {
  if (props.view || route.query.business === "master" || isHeader(model)) {
    return;
  }
  data.value = data.value.map((item) => {
    if (item.model === model) {
      return { ...item, showInput: value };
    }
    return item;
  });
  if (value) {
    const input = await document.getElementById(`input-${model}`);
    input.focus();
  }
};
const handleSubmit = async (e) => {
  e.preventDefault();
  let current_model = e.target.id;
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
const handleTab = async (e, current_model) => {
  e.preventDefault();
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}
</style>

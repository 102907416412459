<template>
  <LoaderContainer
    :loading="false"
    :error="error.type === 'not-decided' ? false : error.message"
  >
    <TaxTitleAndDate title="Computation of Income Tax" />
    <COIBreadCrumb :entity-name="entityName" />
    <QFZPCOI
      v-if="license_authority_type == 2 && qualified_for_freezone === 'Y'"
    />
    <CorporateTaxReturn v-else />
  </LoaderContainer>
</template>

<script setup>
import { LoaderContainer } from "../../common";
import { storeToRefs } from "pinia";
import { useIFRSStore } from "../../../store/NonIFRS/IFRS.store";
import QFZPCOI from "./QFZPCOI.vue";
import CorporateTaxReturn from "./CorporateTaxReturn.vue";
import TaxTitleAndDate from "../common/TaxTitleAndDate.vue";
import { COIBreadCrumb } from "../../BreadCrumbs";
import { computed } from "vue";
import { useEntityStore } from "../../../store/entity";

const ifrsStore = useIFRSStore();
const { error, data, qualified_for_freezone, license_authority_type } =
  storeToRefs(ifrsStore);
const entityStore = useEntityStore();
const { entity } = storeToRefs(entityStore);
const entityName = computed(() => {
  if (!entity.value.data?.entity) {
    return "";
  }
  const Entity = entity.value.data?.entity[0];
  if (Entity?.entity_type == 2) {
    return `${Entity?.entity_first_name} ${Entity?.entity_last_name}`;
  }
  return Entity?.entity_name;
});
</script>

<style scoped></style>

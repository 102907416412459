<template>
  <div
    v-if="!Number(profile.data?.parent_id)"
    class="add-new d-flex align-items-center"
    @click="$router.push('/entity/add-entity/entity-details/entity')"
  >
    <img src="@/assets/svg/add-new.svg" alt="" class="mb-0 mr-2" />
    Add New
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useProfileStore } from "../../../store/profile";

const profileStore = useProfileStore();
const { profile } = storeToRefs(profileStore);
</script>

<style scoped>
.add-new {
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

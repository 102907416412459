<template>
  <LoaderContainer :loading="transactions.loading" :error="transactions.error">
    <div class="row mt-4">
      <div class="col-lg-6 col-md-6 mx-auto">
        <FilterDate :set-date="handleDate" />
      </div>
      <div class="col-lg-3 col-md-6 mx-auto"></div>
      <div class="col-lg-3 col-md-6 mx-auto">
        <SearchTransaction
          :search-text="searchText"
          :handle-search="handleSearch"
        />
      </div>
      <div class="col-lg-12 mt-3">
        <TransactionList :data="data" />
      </div>
    </div>
  </LoaderContainer>
</template>

<script setup>
import { onMounted, ref } from "vue";
import LoaderContainer from "../../components/common/LoaderContainer.vue";
import { usePaymentStore } from "../../store/payment.store";
import { storeToRefs } from "pinia";
import { searchObject } from "../../helpers/searchObject";
import SearchTransaction from "./SearchTransaction.vue";
import TransactionList from "./List.vue";
import FilterDate from "./FilterDate.vue";
import { isDateBetweenDates } from "../../helpers/date";
const paymentStore = usePaymentStore();
const { transactions } = storeToRefs(paymentStore);
const searchText = ref("");
const data = ref([]);
const date = ref({
  startDate: "",
  finalDate: ""
});
const handleDate = (value) => {
  if (!value) {
    date.value.startDate = "";
    date.value.finalDate = "";
    filterSearch();
    return;
  }
  if (!value.length) {
    date.value.startDate = "";
    date.value.finalDate = "";
    filterSearch();
    return;
  }
  const [startDate, finalDate] = value;
  date.value.startDate = startDate;
  date.value.finalDate = finalDate;
  filterSearch();
};
const handleSearch = (value) => {
  searchText.value = value || "";
  filterSearch();
};
const filterSearch = () => {
  let dateFilter = transactions.value.data;
  if (date.value.finalDate && date.value.startDate) {
    dateFilter = transactions.value.data.filter((item) => {
      return isDateBetweenDates(
        new Date(item?.created_at),
        date.value.startDate,
        date.value.finalDate
      );
    });
  }
  data.value = searchObject(
    searchText.value,
    ["status_text", "order_id", "txn_id"],
    dateFilter,
    "id",
    true
  );
};
onMounted(async () => {
  await paymentStore.getTransactionList();
  data.value = transactions.value.data;
});
</script>

<style scoped></style>

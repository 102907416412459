<template>
  <div v-if="!!data[props.article]" class="w-100">
    <h4 class="my-4 title">Checklist for determining eligibility for relief</h4>
    <div
      class="w-100 content d-flex align-items-center justify-content-between"
    >
      <p class="article flex-2 mb-0">
        1.The Taxable Persons are juridical persons that are Resident Persons,
        or Non-Resident Persons that have a Permanent Establishment in the
        State.
      </p>
      <div class="d-flex flex-1 text-right justify-content-end">
        <v-switch
          v-model="data[props.article].the_taxable_person_are_judicial_person"
          color="#0F75BC"
          inset
          :disabled="!!status"
          hide-details
        />
      </div>
    </div>
    <div
      class="w-100 content d-flex align-items-center justify-content-between"
    >
      <p class="article flex-2 mb-0">
        2.Either Taxable Person has a direct or indirect ownership interest of
        at least 75% (seventy-five percent) in the other Taxable Person, or a
        third Person has a direct or indirect ownership interest of at least 75%
        (seventy-five percent) in each of the Taxable Persons.
      </p>
      <div class="d-flex flex-1 justify-content-end">
        <v-switch
          v-model="
            data[props.article]
              .either_taxable_person_has_a_direct_or_indirect_ownership
          "
          color="#0F75BC"
          :disabled="!!status"
          inset
          hide-details
        />
      </div>
    </div>
    <div
      class="w-100 content d-flex align-items-center justify-content-between"
    >
      <p class="article flex-2 mb-0">
        3.None of the Persons are an Exempt Person.
      </p>
      <div class="d-flex flex-1 justify-content-end">
        <v-switch
          v-model="data[props.article].none_of_the_person_are_an_exempt_person"
          color="#0F75BC"
          inset
          :disabled="!!status"
          hide-details
        ></v-switch>
      </div>
    </div>
    <div
      class="w-100 content d-flex align-items-center justify-content-between"
    >
      <p class="article flex-2 mb-0">
        4.None of the Persons are a Qualifying Free Zone Person.
      </p>
      <div class="d-flex flex-1 justify-content-end">
        <v-switch
          v-model="
            data[props.article]
              .none_of_the_person_are_a_qualifying_freezone_person
          "
          color="#0F75BC"
          :disabled="!!status"
          inset
          hide-details
        ></v-switch>
      </div>
    </div>
    <div
      class="w-100 content d-flex align-items-center justify-content-between"
    >
      <p class="article flex-2 mb-0">
        5.The Financial Year of each of the Taxable Persons ends on the same
        date.
      </p>
      <div class="d-flex flex-1 justify-content-end">
        <v-switch
          v-model="
            data[props.article]
              .the_financial_year_of_the_each_of_the_taxable_person_ends_on_same_date
          "
          color="#0F75BC"
          :disabled="!!status"
          inset
          hide-details
        ></v-switch>
      </div>
    </div>
    <div
      class="w-100 content d-flex align-items-center justify-content-between"
    >
      <p class="article flex-2">
        6.Both Taxable Persons prepare their financial statements using the same
        accounting standards.
      </p>
      <div class="d-flex flex-1 justify-content-end">
        <v-switch
          v-model="
            data[props.article]
              .both_taxable_persons_prepare_their_financial_statements_using_the_same_accounting_standards
          "
          color="#0F75BC"
          :disabled="!!status"
          inset
          hide-details
        />
      </div>
    </div>
    <div class="w-100">
      <p class="article">
        Note-Exception to be triggered in case result of checklist does not
        matches input from the user in Row "28"
      </p>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useChapter8Store } from "../../../../store/chapter8";
import { useStatusStore } from "../../../../store/statusStore";

const props = defineProps({
  article: String
});
const chapter8Store = useChapter8Store();
const { data } = storeToRefs(chapter8Store);
const statusStore = useStatusStore();
const { status } = storeToRefs(statusStore);
</script>

<style scoped>
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.btn-add {
  width: 124.33px;
  height: 47px;
  background: #0f75bc;
  border-radius: 5px;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.header {
  padding: 6px 9px;
  min-height: 47px;
  background: #0f75bc;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
.custom-input {
  height: 44px;
  padding: 11px 13px;
  margin-top: 10px;
  border: 1px solid rgba(116, 113, 113, 0.3);
}
.custom-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
  text-align: start;
}
.article {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
.content {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ede4e4;
  gap: 60px;
}
.flex-2 {
  flex: 10;
}
.flex-1 {
  flex: 1;
}
</style>

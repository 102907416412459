import { defineStore } from "pinia";
import { getErrorMessage, getWarningMessage } from "../../helpers/errorHandler";
import {
  nonIFRSBalanceSheet,
  nonIFRSDataStructure,
  getValueForCalc,
  nonIFRSBSGroupData,
} from "../../helpers/non-ifrs.balance-sheet";
import fetchWrapper from "../../helpers/fetchWrapper";
import {
  currentAssetData,
  nonCurrentAssetData,
} from "../../components/entity/FinancialData/NON-IFRS/BalanceSheet/Assets/data";
import {
  nonCurrentLiabilites,
  currentLiabilites,
} from "../../components/entity/FinancialData/NON-IFRS/BalanceSheet/Liabilities/data";
import { Toast } from "../../helpers/toast";
import fetchFileWrapper from "../../helpers/fileApi";
export const useNoneIFRSBalanceSheet = defineStore("non-ifrs-balancesheet", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      loading: true,
      error: false,
      data: nonIFRSBalanceSheet,
      groupData: nonIFRSBSGroupData,
    };
  },
  getters: {
    fixedAssets(state) {
      const keys = [
        "tangible_assets",
        "intangible_assets",
        "capital_work_in_progress",
        "intangible_assets_under_development",
      ];
      let sum = keys.reduce((a, b) => a + getValueForCalc(state, b), 0);
      return sum;
    },
    totalNonCurrentAssets(state) {
      return nonCurrentAssetData.reduce(
        (a, b) => a + getValueForCalc(state, b.model),
        0
      );
    },
    totalCurrentAssets(state) {
      return currentAssetData.reduce(
        (a, b) => a + getValueForCalc(state, b.model),
        0
      );
    },
    totalAssets() {
      return this.totalCurrentAssets + this.totalNonCurrentAssets;
    },
    shareholderFunds(state) {
      return (
        getValueForCalc(state, "share_capital") +
        getValueForCalc(state, "reserves_and_surplus")
      );
    },
    totalNonCurrentLiabilities(state) {
      return nonCurrentLiabilites.reduce(
        (a, b) => a + getValueForCalc(state, b.model),
        0
      );
    },
    totalCurrentLiabilities(state) {
      return currentLiabilites.reduce(
        (a, b) => a + getValueForCalc(state, b.model),
        0
      );
    },
    totalLiabilities() {
      return this.totalCurrentLiabilities + this.totalNonCurrentLiabilities;
    },
    totalShareHolderEquityLiabilities(state) {
      return (
        this.totalLiabilities +
        getValueForCalc(state, "share_application_money_pending_allotment") +
        this.shareholderFunds
      );
    },
  },
  actions: {
    async getNoneIFRSBalanceSheet(
      entity_id,
      financial_year,
      financial_period,
      current_business
    ) {
      try {
        if (entity_id && financial_year && financial_period) {
          this.loading = true;
          const res = await fetchWrapper.get(
            `/non_ifrs_balancesheet?entity_id=${entity_id}&financial_period=${financial_period}&financial_year=${financial_year}${
              current_business === "master"
                ? ""
                : "&entity_business_id=" + current_business
            }`
          );
          if (res.data.result == 1) {
            const response = await res?.data?.data;
            if (current_business !== "master") {
              let balancesheet = {};
              Object.keys(response).forEach((key) => {
                if (!response[key]) {
                  balancesheet[key] = nonIFRSDataStructure;
                } else {
                  balancesheet[key] = response[key];
                  if (typeof response[key] === "object") {
                    if (!response[key]?.last_input) {
                      balancesheet[key]["last_input"] = "input";
                    } else if (!response[key]?.value) {
                      balancesheet[key]["value"] = "";
                    }
                  }
                }
              });
              this.data = await balancesheet;
            } else {
              let balanceSheet = {};
              for (let x in this.groupData) {
                balanceSheet[x] = response[x];
              }
              this.groupData = await balanceSheet;
            }
            this.error = false;
            this.loading = false;
          } else {
            this.data = await nonIFRSBalanceSheet;
            this.error = getWarningMessage(res);
            this.loading = false;
          }
        }
      } catch (err) {
        console.log(err);
        this.error = getErrorMessage(err);
        this.loading = false;
      }
    },
    async saveNonIFRSBalanceSheet(
      entity_id,
      financial_year,
      financial_period,
      current_business,
      callBack = () => {},
      toast = new Toast("Saving please wait...")
    ) {
      try {
        if (current_business === "master") {
          toast.makeErrorToast("You can't save in master");
          return;
        }
        if (financial_year && financial_period && entity_id) {
          const payload = {
            entity_id,
            financial_year,
            financial_period,
            ...this.data,
            fixed_assets: this.fixedAssets,
            total_non_current_assets: this.totalNonCurrentAssets,
            total_current_assets: this.totalCurrentAssets,
            total_assets: this.totalAssets,
            shareholders_funds: this.shareholderFunds,
            total_non_current_liabilities: this.totalNonCurrentLiabilities,
            total_current_liabilities: this.totalCurrentLiabilities,
            total_liabilities: this.totalLiabilities,
            total_shareholders_equity_and_liabilities:
              this.totalShareHolderEquityLiabilities,
            entity_business_id: current_business,
          };
          const res = await fetchWrapper.post(
            `/non_ifrs_balancesheet`,
            payload
          );
          if (res.data?.result === 1) {
            await toast.makeSuccessToast(res.data?.message);
            callBack(res?.data, toast);
          } else {
            toast.makeErrorToast(getWarningMessage(res));
          }
        }
      } catch (err) {
        console.log(err?.message);
        toast.makeErrorToast(getErrorMessage(err));
      }
    },
    async uploadBalanceSheetFile(
      route,
      File,
      callBack = () => {},
      toast = new Toast("Uploading file....")
    ) {
      try {
        const entity_id = route.params.id;
        const { financial_period, financial_year, business } = route.query;
        if (File && financial_year && financial_period) {
          let data = new FormData();
          data.append("file", File);
          data.append("entity_id", entity_id);
          data.append("entity_business_id", business || 0);
          data.append("financial_year", financial_year);
          data.append("financial_period", financial_period);
          const res = await fetchFileWrapper.post(
            "/import_non_ifrs_balancesheet",
            data
          );
          if (res.data.result == 1) {
            toast.makeSuccessToast(res.data.message);
            callBack(res.data.entity_business_id);
            return;
          } else toast.makeWarningToast(getWarningMessage(res));
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(getErrorMessage(err));
      }
    },
    async autoSaveBalanceSheet(route, setSavingStatus, callBack = () => {}) {
      try {
        const entity_id = route.params.id;
        const { financial_year, financial_period, business } = route.query;
        const payload = {
          entity_id,
          financial_year,
          financial_period,
          ...this.data,
          fixed_assets: this.fixedAssets,
          total_non_current_assets: this.totalNonCurrentAssets,
          total_current_assets: this.totalCurrentAssets,
          total_assets: this.totalAssets,
          shareholders_funds: this.shareholderFunds,
          total_non_current_liabilities: this.totalNonCurrentLiabilities,
          total_current_liabilities: this.totalCurrentLiabilities,
          total_liabilities: this.totalLiabilities,
          total_shareholders_equity_and_liabilities:
            this.totalShareHolderEquityLiabilities,
          entity_business_id: business,
        };

        const res = await fetchWrapper.post(`/non_ifrs_balancesheet`, payload);
        if (res.data.result == 1) {
          setSavingStatus("loading", false);
          setSavingStatus("error", false);
          setSavingStatus("success", true);
          callBack(res.data.entity_business_id);
        } else {
          setSavingStatus("loading", false);
          setSavingStatus("error", getWarningMessage(res));
          setSavingStatus("success", false);
          setTimeout(() => {
            setSavingStatus("loading", false);
            setSavingStatus("error", false);
            setSavingStatus("success", false);
          }, 3000);
        }
      } catch (err) {
        console.log(err);
        setSavingStatus("loading", false);
        setSavingStatus("error", getErrorMessage(err));
        setSavingStatus("success", false);
        setTimeout(() => {
          setSavingStatus("loading", false);
          setSavingStatus("error", false);
          setSavingStatus("success", false);
        }, 3000);
      }
    },
  },
});

<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="414" height="600">
      <v-card>
        <div class="w-100 wrapper">
          <div class="w-100 d-flex align-items-center justify-content-end">
            <img
              src="@/assets/svg/close.svg"
              class="mt-2 mr-2"
              @click="close"
              alt
            />
          </div>
          <div
            class="w-100 mt-3 d-flex flex-column justify-content-evenly align-items-center"
          >
            <h3 class="verification">Verification</h3>
            <OTP
              :HandleComplete="onOtpComplete"
              :HandleOnChange="onOtpChange"
            />
            <p class="text-info">{{ message }}</p>
            <vue-countdown
              v-if="counting"
              @end="onCountdownEnd"
              :time="30 * 1000"
              v-slot="{ seconds }"
            >
              <p class="time mb-0 mt-3 mb-3">
                you can resend after 00:{{ seconds }} seconds
              </p>
            </vue-countdown>
            <p
              v-if="!counting"
              class="btn btn-outline-primary p-2 mt-5"
              @click="sendOtp()"
            >
              Resend
            </p>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useProfileStore } from "@/store/profile";
import OTP from "../common/OTP.vue";
import { ref, watch } from "vue";

export default {
  name: "VerifyLogin",
  props: {
    OTP_ID: String,
    Dialog: Boolean,
    mobile_no: String,
    setDialog: Function,
    mobileCode: String,
  },
  components: { OTP },
  setup(props) {
    const otp_id = ref(props.OTP_ID);
    watch(
      () => props.OTP_ID,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          otp_id.value = newValue;
        }
      }
    );
    return { otp_id };
  },
  data() {
    return {
      dialog: false,
      message: "OTP Sent. Please check your phone",
      counting: false,
    };
  },
  watch: {
    Dialog() {
      this.dialog = this.Dialog;
    },
    dialog(newValue) {
      this.setDialog(newValue);
    },
  },
  methods: {
    onOtpChange(value) {
      //console.log(value);
    },
    close() {
      this.dialog = false;
    },
    async onOtpComplete(otp) {
      const profileStore = useProfileStore();
      await profileStore.verifyOtp(
        { otp_id: this.otp_id, otp },
        this.setMessage,
        async () => {
          await profileStore.signInWithOTp(
            this.mobile_no,
            this.mobileCode,
            this.setMessage,
            async ({ token }) => {
              await sessionStorage.setItem("Token", token);
              setTimeout(() => {
                location.href = `/`;
              }, 2000);
            }
          );

          setTimeout(() => {
            this.dialog = false;
          }, 2000);
        }
      );
    },
    async sendOtp() {
      const profileStore = useProfileStore();
      await profileStore.getLoginOtp(
        this.mobile_no,
        this.mobileCode,
        this.setMessage,
        (otpId) => {
          this.otp_id = otpId;
          this.startCountdown();
        }
      );
    },
    setMessage(message) {
      this.message = message;
    },
    startCountdown() {
      this.counting = true;
    },
    onCountdownEnd() {
      this.counting = false;
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="w-100 mt-10">
    <h4 class="upload-title">
      Upload
      {{ props.name }}
      Details
    </h4>
    <div class="d-flex mt-3 w-100 align-items-center">
      <div
        class="file-name-container p-3 d-flex align-items-center justify-content-between"
      >
        <p class="file-name mr-4 mb-0">{{ fileName }}</p>
        <input type="file" @change="setFile" id="upload-file" hidden />
      </div>
      <button
        for="upload"
        class="choose-file d-flex justify-content-center align-items-center"
        @click="selectFile"
      >
        Choose file
      </button>
    </div>
    <p :class="`text-info mt-4 text-${type}`">{{ message }}</p>
    <div class="w-100 mt-4">
      <button class="btn-submit" @click="uploadFile">Submit</button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { uploadEntityLinks } from "../../../../constants/entity-upload.api";
import fetchFileWrapper from "../../../../helpers/fileApi";

const props = defineProps({
  type: { type: String, required: true },
  name: { type: String, default: "" },
  callBack: { type: Function, default: () => {} }
});

const File = ref(null);
const fileName = ref("");
const message = ref("");
const type = ref("info");
const route = useRoute();
const setFile = (e) => {
  File.value = e.target.files[0];
  fileName.value = File.value?.name;
};
const selectFile = () => {
  document.getElementById("upload-file").click();
};
const setMessage = (mess, Type) => {
  message.value = mess;
  type.value = Type;
};
const uploadFile = async () => {
  try {
    setMessage("preparing file to upload please wait....", "info");
    const link = uploadEntityLinks[props.type];
    const data = new FormData();
    data.append("entity_id", route.params.id);
    data.append("file", File.value);
    setMessage("Uploading....", "info");
    const res = await fetchFileWrapper.post(link, data);
    if (res.data.result === 1) {
      setMessage("Uploaded successfully", "success");
      props?.callBack(res.data);
    } else {
      setMessage(
        res.data?.error ? res.data?.error?.join(",") : res.data?.message,
        "warning"
      );
    }
  } catch (err) {
    console.log(err);
    setMessage(err?.message, "danger");
  }
};
</script>

<style scoped>
.upload-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.file-name-container {
  min-width: 50%;
  height: 44px;
  background: #b0e1eb;
  overflow: hidden;
}
.file-name {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #747171;
}
.choose-file {
  width: 121px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
  cursor: pointer;
}
.btn-submit {
  width: 82px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; /*
  identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
</style>

<template>
  <div class="row g-3">
    <div
      v-for="item in Data"
      :key="item.id"
      :to="`${item.link}`"
      class="col-lg-6 mt-30 mx-auto"
    >
      <router-link
        v-if="item.id !== 'portfolio_of_clients'"
        :to="`${item.link}`"
        :class="`w-100 status-card p-2 d-flex justify-content-between ${
          item?.link ? 'cursor-pointer' : 'disabled'
        }`"
      >
        <div class="d-flex flex-column min-h justify-content-between">
          <img :src="item.img" alt="" class="item-img" />
          <div class="d-flex align-items-center">
            <img :src="item.icon ? item.icon : DeskLight" alt="" />
            <p class="status-desc mb-0 ml-2">{{ item.status }}</p>
          </div>
        </div>
        <div class="d-flex flex-column align-items-end">
          <div class="d-flex flex-column align-items-end pb-2">
            <h4 class="number-desc">{{ item.numberDesc }}</h4>
            <h3 class="number mb-0">{{ item.count }}</h3>
          </div>
        </div>
      </router-link>
      <div v-else class="w-100 status-card p-2 d-flex justify-content-between">
        <div class="d-flex flex-column min-h justify-content-between">
          <img :src="item.img" alt="" class="item-img" />
          <div class="d-flex align-items-center">
            <img :src="item.icon ? item.icon : DeskLight" alt="" />
            <p class="status-desc mb-0 ml-2">{{ item.status }}</p>
          </div>
        </div>
        <div class="d-flex flex-column align-items-end">
          <router-link
            :to="item.upperLink"
            class="d-flex flex-column align-items-end pb-2 cursor-pointer freezone"
          >
            <h4 class="number-desc">{{ item.numberDesc }}</h4>
            <h3 class="number mb-0">{{ item.count }}</h3>
          </router-link>
          <router-link
            :to="item.lowerLink"
            class="d-flex flex-column cursor-pointer align-items-end pt-2"
          >
            <h4 class="number-desc">{{ item.subItemDesc }}</h4>
            <h3 class="number mb-0">{{ item.subItemCount }}</h3>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { generate } from "randomized-string";
import { onMounted, ref, watch } from "vue";
import BriefCaseBlue from "@/assets/svg/BriefCase-blue.svg";
import refresh from "@/assets/svg/Refresh.svg";
import Network from "@/assets/svg/Network.svg";
import piechart from "@/assets/svg/piechart.svg";
import layer from "@/assets/svg/Layer.svg";
import DeskLight from "@/assets/svg/Desk_light.svg";
import { useEntityStore } from "../../../store/entity";
import { storeToRefs } from "pinia";
import { getEntityItems } from "../../../store/entityActions/get";
import { useConsultantDashboardStore } from "../../../store/consultantDashboard";

const entityStore = useEntityStore();
const { entities } = storeToRefs(entityStore);

const consultantDashboard = useConsultantDashboardStore();
const { data } = storeToRefs(consultantDashboard);
const Data = ref([
  {
    id: "no_of_pending_returns",
    img: Network,
    numberDesc: "No. of Pending Returns",
    count: 3,
    status: "2 Days Ago",
    link: `/entity?file_return_status=Pending`
  },
  {
    id: "no_of_returns_filed",
    img: piechart,
    numberDesc: "No. of Returns Filed",
    count: 2,
    status: "Last 24 Hours",
    link: `/entity?file_return_status=Filed`
  },
  {
    id: "no_of_clients",
    img: BriefCaseBlue,
    icon: refresh,
    numberDesc: "No. of Clients",
    count: entities.value.data.length,
    status: "Just Update",
    link: "/entity"
  },
  {
    id: "portfolio_of_clients",
    img: layer,
    numberDesc: "Mainland clients",
    count: 5,
    status: "Last 24 Hours",
    subItemDesc: "Freezone clients",
    subItemCount: 0,
    upperLink: `/entity?license_authority_type=Mainland`,
    lowerLink: `/entity?license_authority_type=Freezone(FZ)`
  }
]);
onMounted(async () => {
  await getEntityItems("entities", "/entity");
  Data.value = Data.value.map((item) => ({
    ...item,
    count:
      item.id === "portfolio_of_clients"
        ? data.value.portfolio_of_clients.mainland_client
        : data.value[item.id],
    subItemCount:
      item.id === "portfolio_of_clients"
        ? data.value.portfolio_of_clients.freezone_client
        : 0
  }));
});
watch(
  data,
  (newValue) => {
    Data.value = Data.value.map((item) => ({
      ...item,
      count:
        item.id === "portfolio_of_clients"
          ? newValue.portfolio_of_clients.mainland_client
          : data.value[item.id],
      subItemCount:
        item.id === "portfolio_of_clients"
          ? newValue.portfolio_of_clients.freezone_client
          : 0
    }));
  },
  { deep: true }
);
</script>

<style scoped>
.status-card {
  height: 155px;
  background: #deeded;
  border: 1px solid rgba(116, 113, 113, 0.3);
  box-shadow: 0px 8px 8px rgba(187, 187, 187, 0.25);
  border-radius: 4px;
}
.status-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.055em;
  color: #382628;
}
.number-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.055em;
  color: #382628;
}
.number {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.055em;
  color: #000000;
}
.item-img {
  width: 41px;
  height: 41px;
  margin-top: -25px;
}
.min-h {
  min-height: 80px;
}
.mt-30 {
  margin-top: 30px;
}
.disabled {
  pointer-events: none;
}
.freezone {
  border-bottom: 1px solid #747171;
}
</style>

import { checkPopUpInProfitLoss } from "../../../../../helpers/non-ifrs.profit-loss";

export const nonIFRSIncomeData = [
  {
    model: "sale_of_products",
    name: "Sale of Products",
    havePopup: checkPopUpInProfitLoss("sale_of_products"),
    sub: false,
  },
  {
    model: "sale_of_services",
    name: "Sale of Services",
    havePopup: checkPopUpInProfitLoss("sale_of_services"),
    sub: false,
  },
  {
    model: "other_operating_revenues",
    name: "Other operating revenues",
    havePopup: checkPopUpInProfitLoss("other_operating_revenues"),
    sub: false,
  },
];

export const nonIFRSOtherIncomeData = [
  {
    model: "interest_income",
    name: "Interest Income",
    havePopup: checkPopUpInProfitLoss("interest_income"),
    sub: false,
  },
  {
    model: "dividend_income",
    name: "Dividend Income",
    havePopup: checkPopUpInProfitLoss("dividend_income"),
    sub: false,
  },
  {
    model: "net_gain_loss_on_sale_of_investment",
    name: "Net Gain/Loss on sale of Investment",
    havePopup: checkPopUpInProfitLoss("net_gain_loss_on_sale_of_investment"),
    sub: false,
  },
  {
    model: "other_non_operating_income",
    name: "Other Non Operating Income",
    havePopup: checkPopUpInProfitLoss("other_non_operating_income"),
    sub: false,
  },
  {
    model: "net_gain_loss_on_foriegn_currency_transaction",
    name: "Net Gain/Loss on Foreign Currency Transaction",
    havePopup: checkPopUpInProfitLoss(
      "net_gain_loss_on_foriegn_currency_transaction"
    ),
    sub: false,
  },
];

export const nonIFRSDividenIncomeData = {
  dividends_and_other_profit_distributions_received: [
    {
      model: "dividends_and_other_profit_distributions_received",
      name: "1.) Dividends and other profit distributions received from, a) Resident Juridical person & b) Participating Interest in a foreign juridical person",
      havePopup: false,
      sub: false,
    },
  ],
  other_income: [
    {
      model: "any_other_income_participating_interest_specified_article_23",
      name: "2.i Any other income from a Participating Interest as specified in Article-23 ",
      havePopup: false,
      sub: true,
    },
    {
      model:
        "income_foreign_permanent_establishment_that_meet_condition_article_24",
      name: "2.ii Income of a Foreign Permanent Establishment that meet condition of Article 24",
      havePopup: false,
      sub: true,
    },
  ],
};

export const nonIFRSExpensesData = [
  {
    model: "cost_of_material_consumed",
    name: "Cost of Materials Consumed ",
    havePopup: false,
    sub: false,
  },
  {
    model: "purchase_of_stock_in_trade",
    name: "Purchase of Stock-in-Trade",
    havePopup: false,
    sub: false,
  },
  {
    model: "changes_in_inventories_of_finish_goods_wip_sit",
    name: "Changes in Inventories of Finished Goods, WIP, Stock-in-Trade",
    havePopup: false,
    sub: false,
  },
];

export const nonIfrsEmployeeBenefitsExpense = [
  {
    model: "salary_and_wages",
    name: "Salaries and Wages",
    havePopup: false,
    sub: false,
  },
  {
    model: "contribution_to_provident_and_other_funds",
    name: "Contribution to Provident and other Funds",
    havePopup: false,
    sub: false,
  },
  {
    model: "esop_espp",
    name: "ESOP/ESPP",
    havePopup: false,
    sub: false,
  },
  {
    model: "staff_welfare_expenses",
    name: "Staff Welfare expenses",
    havePopup: false,
    sub: false,
  },
];

export const nonIfrsOtheExpenseData = [
  {
    model: "consumption_of_stores_spares",
    name: "Consumption of Stores and Spares",
    havePopup: false,
    sub: false,
  },
  {
    model: "power_and_fuel",
    name: "Power and Fuel",
    havePopup: false,
    sub: false,
  },
  {
    model: "rent",
    name: "Rent",
    havePopup: false,
    sub: false,
  },
  {
    model: "entertainment_expenses",
    name: "Entertainment Expenses",
    havePopup: false,
    sub: false,
  },
  {
    model: "insurance",
    name: "Insurance",
    havePopup: false,
    sub: false,
  },
  {
    model: "rates_and_taxes_excluding_income_tax",
    name: "Rates and Taxes (excluding Income Tax)",
    havePopup: false,
    sub: false,
  },
  {
    model: "misc_expenses",
    name: "Misc. Exp.",
    havePopup: false,
    sub: false,
  },
  {
    model: "loss_of_foreign_currency_transactions",
    name: "Loss on Foreign Curreny Transactions",
    havePopup: false,
    sub: false,
  },
  {
    model: "payment_of_auditors",
    name: "Payment to Auditors",
    havePopup: false,
    sub: false,
  },
  {
    model: "net_loss_on_sale_of_investments",
    name: "Net Loss on sale of Investments",
    havePopup: false,
    sub: false,
  },
  {
    model: "prior_period_items",
    name: "Prior Period Items",
    havePopup: false,
    sub: false,
  },
  {
    model: "other_borrowing_cost_except_interest",
    name: "Other Borrowing Costs (Except Interest)",
    havePopup: false,
    sub: false,
  },
];

export const nonIFRSLessExpenseData = [
  {
    model: "corporat_tax",
    name: "1) Corporate Tax",
    havePopup: false,
    sub: true,
  },
  {
    model: "deferred_tax",
    name: "2) Deferred Tax",
    havePopup: false,
    sub: true,
  },
];

<template>
  <div
    :class="`d-flex table-body align-items-center justify-content-between ${customContainerClass}`"
  >
    <p :class="`mb-0 body-title ${customTitleClass}`">
      {{ title }}
    </p>
    <div v-if="!showInput" class="d-flex align-items-center">
      <p v-if="text" :class="`mb-0 bold w-100  body-title text`">
        <b>{{ text }}</b>
      </p>
      <p
        v-if="showAmount"
        :class="`mb-0  body-title ${customTextClass}`"
        v-bind="textProps"
      >
        {{ NumberFormatter(amount) }}
      </p>

      <v-tooltip
        v-if="showTooltip && showAmount"
        :text="`Computed value ${NumberFormatter(toolTipNumber)}`"
        location="bottom"
      >
        <template v-slot:activator="{ props }">
          <p class="bold ml-3 mb-0" v-bind="props">
            <b> ?</b>
          </p>
        </template>
      </v-tooltip>
    </div>
    <input
      v-model="value"
      type="number"
      class="clickable-text"
      v-bind="inputProps"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { NumberFormatter } from "../../helpers/numberFormatter";

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0
  },
  customContainerClass: {
    type: String,
    default: ""
  },
  customTextClass: {
    type: String,
    default: ""
  },
  customTitleClass: {
    type: String,
    default: ""
  },
  title: {
    type: String,
    default: ""
  },
  showTooltip: {
    type: Boolean,
    default: false
  },
  toolTipNumber: {
    type: Number,
    default: 0
  },
  showInput: {
    type: Boolean,
    default: false
  },
  inputProps: {
    type: Object,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: {}
  },
  amount: {
    type: Number,
    default: 0
  },
  textProps: {
    type: Object,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: {}
  },
  showAmount: {
    type: Boolean,
    default: true
  },
  text: {
    type: String,
    default: ""
  }
});
const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  }
});
</script>

<style scoped>
.white {
  background: #fafbff;
}
.gray {
  background: #deeded;
}
.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}
.body-title.disabled {
  color: #747171 !important;
}
.info-container {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-body {
  min-height: 80px;
  padding-right: 100px;
  padding-left: 26px;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 30px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.body-title.disabled {
  color: #747171 !important;
}
.total-amount.disabled {
  color: #747171 !important;
}
.text {
  text-align: end;
}
</style>

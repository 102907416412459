<template>
  <div class="w-100">
    <div
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 total-amount">TOTAL NON CURRENT ASSETS</p>
      <p class="mb-0 total-amount">
        {{ NumberFormatter(total_non_current_asset) }}
      </p>
    </div>
    <!-- <div class="total-container d-flex align-items-center justify-content-between">
      <p class="mb-0 total-amount">CURRENT ASSETS</p>
      <p class="mb-0 total-amount">0000.00</p>
    </div> -->
    <!-- <div class="total-container d-flex align-items-center justify-content-between">
      <p class="mb-0 total-amount"></p>
      <p class="mb-0 total-amount">0000.00</p>
    </div> -->
    <!-- <div class="grand-total d-flex align-items-center justify-content-between mb-10">
       <p class="mb-0 grand-total-amount">TOTAL ASSETS</p>
       <p class="mb-0 grand-total-amount">0000.00</p>
    </div> -->
  </div>
</template>

<script>
import { useFinancialDataStore } from "@/store/financial.store";
import { storeToRefs } from "pinia";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";

export default {
  name: "AssetTotal",
  setup() {
    const financialDataStore = useFinancialDataStore();
    const { financial_common_data } = storeToRefs(financialDataStore); //get initialvalue from store

    return {
      financialDataStore,
      financial_common_data,
      NumberFormatter
    };
  },
  data() {
    return {
      entity_id: this.$route.params.id
    };
  },
  computed: {
    total_non_current_asset: function () {
      return this.financialDataStore.total_non_current_assets;
    }
  }
};
</script>

<style scoped>
.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}
</style>

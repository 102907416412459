<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="w-100 table-responsive mt-4">
    <table class="table custom-table table-bordered table-striped">
      <thead v-if="!haveNoHeader">
        <tr>
          <th v-if="haveIndex" class="table-header">Sl No.</th>
          <th class="table-header" v-for="header in Headers" :key="header">
            {{ header }}
          </th>
          <th class="table-header" v-if="actions?.length">Action</th>
          <th class="table-header" v-if="hasYesNo">
            {{ ActionName ? ActionName : "Active" }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, index) in listOfItems" :key="data.id">
          <td
            v-if="haveIndex"
            :class="`table-rows ${
              pointer ? 'cursor-pointer' : ''
            } align-middle`"
          >
            <p class="mb-0 data-text">
              {{ indexStart ? indexStart + index : index + 1 }}
            </p>
          </td>
          <td
            :class="`table-rows align-middle ${
              pointer ? 'cursor-pointer' : ''
            }`"
            v-for="(item, key) in data"
            :key="key"
            @click="
              Data[index]?.onclick
                ? Data[index]?.onclick(Data[index]?.id)
                : () => {}
            "
          >
            <p class="mb-0 data-text" v-if="typeof item === 'string'">
              {{ item }}
            </p>

            <img
              v-if="
                typeof item === 'object' &&
                item?.url &&
                item?.url.substr(item?.url.lastIndexOf('.') + 1) != 'pdf'
              "
              :src="item?.url"
              class="data-img"
              alt=""
            />
            <a
              v-if="
                typeof item === 'object' &&
                item?.url &&
                item?.url.substr(item?.url.lastIndexOf('.') + 1) == 'pdf'
              "
              :href="item?.url"
              target="blank"
              ><img src="@/assets/images/pdf40.png"
            /></a>
          </td>
          <slot :data="data" :index="index" />

          <td v-if="actions?.length" class="table-rows align-middle">
            <div class="w-100 d-flex align-items-center">
              <img
                class="mr-2 cursor-pointer"
                v-for="action in actions"
                :key="action.id"
                :src="action.icon"
                @click="action.onClick(Data[index]?.id)"
                alt=""
              />
            </div>
          </td>
          <td v-if="hasYesNo" class="align-middle">
            <div class="d-flex align-items-center mt-1">
              <input type="checkbox" class="mr-3" name="" id="" />
              <p class="yes-text mb-0">Yes</p>
            </div>
            <div class="d-flex align-items-center mt-1">
              <input type="checkbox" class="mr-3" name="" id="" />
              <p class="yes-text mb-0">No</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="empty mb-4" v-if="!listOfItems.length">
      <p class="mb-0 data-text">No Data is registered</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomTable",
  props: {
    Headers: Array,
    haveIndex: Boolean,
    Data: Array,
    actions: Array,
    hasYesNo: Boolean,
    haveNoHeader: Boolean,
    indexStart: Number,
    pointer: Boolean,
    ActionName: String,
    yesNo: Boolean,
  },
  data() {
    return {
      listOfItems: [],
    };
  },
  watch: {
    Data(newValue) {
      this.listOfItems = newValue?.length ? this.extractData(newValue) : [];
    },
  },
  created() {
    this.listOfItems = this.Data?.length ? this.extractData(this.Data) : [];
  },
  methods: {
    extractData(data) {
      const list = data.map((item) => {
        const x = {};
        for (const i in item) {
          if (i !== "id" && i !== "onclick") {
            x[i] = item[i];
          }
        }
        return x;
      });
      return list;
    },
  },
};
</script>

<style scoped>
.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
}
.table-header {
  min-height: 39px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2.5px solid #fff;
}
.table-rows {
  background: #ffffff;
  border: 2.5px solid #fff;
}
.yes-text {
  font-style: normal;
  font-weight: 400;
  font-size: 11.5235px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: #000000;
}
.empty {
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.5px solid rgba(149, 146, 146, 0.3);
}
.cursor-pointer {
  cursor: pointer;
}
.data-img {
  width: 110px;
  height: 110px;
  object-fit: cover;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
tbody:last-child {
  border: 2.5px solid #fff;
  border-bottom: 3px solid rgba(149, 146, 146, 0.3);
}
</style>

<template>
  <CustomModalContainer
    :open="open"
    :set-open="setOpen"
    content-class="my-custom-dialog"
    :min-width="400"
  >
    <h4 class="bold title">
      {{ props.type === "balanceSheet" ? "Balance Sheet" : "Profit and Loss" }}
    </h4>
    <UploadFile :type="props.type" />
  </CustomModalContainer>
</template>

<script setup>
import { ref, defineProps, watch } from "vue";
import { downloadURI } from "../../../../helpers/download";
import UploadFile from "./UploadFile.vue";
import { CustomModalContainer } from "../../../common";

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "balanceSheet",
  },
  setOpen: {
    type: Function,
    default: () => {},
  },
});
</script>

<style scoped>
.card-container {
  max-width: 596px;
  background: #fafbff;
  border-radius: 5px;
  padding: 33px 27px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.card-container::-webkit-scrollbar {
  display: none;
}
.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.055em;
  color: #000000;
}
.btn-download {
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  gap: 13px;
}
.btn-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
</style>

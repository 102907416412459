<script setup>
import { storeToRefs } from "pinia";
import { useBusinessStore } from "../../../../store/buisness.store";
import TaxableLineItemHeader from "./TaxableLineItemHeader.vue";
import TaxableLineItemForm from "./TaxableLineItemForm.vue";
import { NumberFormatter } from "../../../../helpers/numberFormatter";
import { watch } from "vue";
import MasterDataTaxableLineitem from "./MasterDataTaxableLineitem.vue";

const { useChapter8Store } = require("../../../../store/chapter8");

const chapter8Store = useChapter8Store();
const { masterData } = storeToRefs(chapter8Store);
const businessStore = useBusinessStore();
const { business } = storeToRefs(businessStore);
const getBusinessName = (id) => {
  const Business = business.value.data.find((b) => b.id == id);
  if (!Business) {
    return "";
  }
  return Business?.name;
};
</script>
<template>
  <div class="w-100 mt-4">
    <div
      v-for="(item, index) in masterData"
      class="row border-bottom"
      :key="item.id"
    >
      <div class="col-lg-12 mt-3">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <h2 class="title">{{ getBusinessName(item?.entity_business_id) }}</h2>
        </div>
      </div>
      <div class="col-lg 12 mt-4">
        <h4 class="title">Article 26 Assets</h4>
        <TaxableLineItemHeader type="assets">
          <MasterDataTaxableLineitem
            v-for="(Item, index) in item?.article_26_taxable_lineitem?.assets"
            :item="Item"
          />
        </TaxableLineItemHeader>
      </div>
      <div class="col-lg-12">
        <div
          class="table-body py-3 pr-3 gray d-flex align-items-center w-100 justify-content-end"
        >
          <p class="sub-title bold mb-0 mr-10">Total Asset Taxable Amount</p>
          <p class="total-amount bold mb-0">
            {{
              NumberFormatter(
                item?.article_26_taxable_lineitem?.totalAssetsAmount
              )
            }}
          </p>
        </div>
      </div>
      <div class="col-lg 12 mt-4">
        <h4 class="title">Article 26 Liabilities</h4>
        <TaxableLineItemHeader type="liabilities">
          <MasterDataTaxableLineitem
            v-for="(Item, index) in item?.article_26_taxable_lineitem
              ?.liabilities"
            :item="Item"
          />
        </TaxableLineItemHeader>
      </div>
      <div class="col-lg-12">
        <div
          class="table-body py-3 gray pr-3 d-flex align-items-center w-100 justify-content-end"
        >
          <p class="sub-title bold mb-0 mr-10">
            Total Liabilities Taxable Amount
          </p>
          <p class="total-amount bold mb-0">
            {{
              NumberFormatter(
                item?.article_26_taxable_lineitem?.totalLiabilitiesAmount
              )
            }}
          </p>
        </div>
      </div>
      <div class="col-lg-12">
        <div
          class="table-body py-3 pr-3 border-bottom d-flex align-items-center w-100 justify-content-end"
        >
          <p class="sub-title bold mb-0 mr-10">Total Taxable Amount</p>
          <p class="total-amount bold mb-0">
            {{
              NumberFormatter(
                item?.article_26_taxable_lineitem?.totalTaxableAmount
              )
            }}
          </p>
        </div>
      </div>

      <div class="col-lg 12 mt-4">
        <h4 class="title">Article 27 Assets</h4>
        <TaxableLineItemHeader type="assets">
          <MasterDataTaxableLineitem
            v-for="Item in item?.article_27_taxable_lineitem?.assets"
            :item="Item"
            :key="Item?.id"
          />
        </TaxableLineItemHeader>
      </div>
      <div class="col-lg-12">
        <div
          class="table-body py-3 pr-3 gray d-flex align-items-center w-100 justify-content-end"
        >
          <p class="sub-title bold mb-0 mr-10">Total Asset Taxable Amount</p>
          <p class="total-amount bold mb-0">
            {{
              NumberFormatter(
                item?.article_27_taxable_lineitem?.totalAssetsAmount
              )
            }}
          </p>
        </div>
      </div>
      <div class="col-lg 12 mt-4">
        <h4 class="title">Article 27 Liabilities</h4>
        <TaxableLineItemHeader type="liabilities">
          <MasterDataTaxableLineitem
            v-for="Item in item?.article_27_taxable_lineitem?.liabilities"
            :item="Item"
            :key="Item?.id"
          />
        </TaxableLineItemHeader>
      </div>
      <div class="col-lg-12">
        <div
          class="table-body py-3 gray pr-3 d-flex align-items-center w-100 justify-content-end"
        >
          <p class="sub-title bold mb-0 mr-10">
            Total Liabilities Taxable Amount
          </p>
          <p class="total-amount bold mb-0">
            {{
              NumberFormatter(
                item?.article_27_taxable_lineitem?.totalLiabilitiesAmount
              )
            }}
          </p>
        </div>
      </div>
      <div class="col-lg-12">
        <div
          class="table-body py-3 pr-3 border-bottom d-flex align-items-center w-100 justify-content-end"
        >
          <p class="sub-title bold mb-0 mr-10">Total Taxable Amount</p>
          <p class="total-amount bold mb-0">
            {{
              NumberFormatter(
                item?.article_27_taxable_lineitem?.totalTaxableAmount
              )
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.btn-add {
  width: 124.33px;
  height: 47px;
  background: #0f75bc;
  border-radius: 5px;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.header {
  padding: 6px 9px;
  min-height: 47px;
  background: #0f75bc;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
.custom-input {
  height: 44px;
  padding: 11px 13px;
  margin-top: 10px;
  border: 1px solid rgba(116, 113, 113, 0.3);
}
.custom-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
  text-align: start;
}
.article {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
.article-desc {
  white-space: pre-wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px; /* or
185% */
  letter-spacing: 0.055em;
  color: #747171;
}
.gray {
  background: #deeded;
}
.article-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px; /*
identical to box height, or 185% */
  letter-spacing: 0.055em;
  color: #000000;
}
.border-bottom {
  border-bottom: 1px solid #ede4e4;
}
</style>

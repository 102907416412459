export const QFZCOIdata = {
  business_income: "",
  other_direct_taxable_income_qfzp: "",
  a_income_attr_domestic: "",
  a_i_revenue_attr_domestic: "",
  a_ii_expense_attr_domestic: "",
  b_income_attr_foreign: "",
  b_i_income_attr_foreign: "",
  b_ii_expense_attr_foreign: "",
  c_income_from_txn_nfzp_immovable_commercial_fz: "",
  c_i_revenue_from_txn_nfzp_immovable_commercial_fz: "",
  c_ii_expense_to_txn_nfzp_immovable_commercial_fz: "",
  d_revenue_from_txn_any_person_fz_not_commercial: "",
  d_i_revenue_from_txn_any_person_fz_not_commercial: "",
  d_ii_expense_to_txn_any_person_fz_not_commercial: "",
  e_income_from_txn_any_person_non_commercial_fz: "",
  e_i_revenue_from_txn_any_person_non_commercial_fz: "",
  e_ii_expense_to_txn_any_person_non_commercial_fz: "",
  f_income_from_non_qualifying_intl_prpty: "",
  f_i_revenue_from_non_qualifying_intl_prpty: "",
  f_ii_expense_to_and_earning_from_non_qualifying_intl_prpty: "",
  g_excess_income_from_qualifying_intl_prpty: "",
  unrealized_gain_loss_chapter_6: "",
  exempt_income_chapter_7: "",
  taxability_as_per_chapter_8: "",
  deduction_under_chapter_9: "",
  related_party_txn_under_chapter_10: "",
  total_net_qualifying_income_current_year: "",
  brought_frwd_carried_frwd_loss_chapter_11: "",
  total_income: "",
  taxable_refundable: ""
};

<template>
  <SideNavContainer v-slot="{ setSavingStatus }" side-nav-type="financialData">
    <EntityChecker :check-mandatory-fields="true">
      <IFRSCheckerContainer :have-loader="false" :check-status="true">
        <div
          class="title-container d-flex align-items-center justify-content-between"
        >
          <h3 class="title">
            Financial Data <br />
            <p class="subtitle mb-0">
              {{ name }}
            </p>
          </h3>
          <AssetsDateHandler />
        </div>
        <BalanceSheetProfitLossBreadCrumb
          :entity-name="name"
          type="balance-sheet"
          tab="assets"
        />
        <div class="row">
          <div class="col-lg-12">
            <AssetsBusiness :ifrs="data" />
          </div>
        </div>
        <div
          class="w-100 d-flex flex-wrap align-items-center justify-content-between mt-3 mb-3"
        >
          <div class="d-flex align-items-center">
            <button
              class="tab-button active mr-2 my-2"
              @click="changeTab('assets')"
            >
              <img src="@/assets/svg/briefcase.svg" alt="" class="mr-1 mb-1" />
              Assets
            </button>
            <button class="tab-button my-2" @click="changeTab('liabilities')">
              <img src="@/assets/svg/pie-chart.svg" alt="" class="mr-1 mb-1" />
              Liabilities
            </button>
          </div>
          <div class="message">
            <p :class="type == 'info' ? `text-info` : `text-warning`">
              {{ message }}
            </p>
          </div>
          <DeleteBusinessBtnContainer>
            <button class="save-button my-2 mr-3" @click="createBusiness">
              <img src="@/assets/svg/plus-square-light.svg" alt="" />
              Add New
            </button>
            <button
              v-if="$route.query.business !== 'master' && !status"
              :disabled="
                data === 'IFRS'
                  ? loading
                  : data === 'Non-IFRS'
                  ? nonIFRSLoading
                  : false
              "
              class="save-button my-2"
              @click="handleSave()"
            >
              Save Data
            </button>
          </DeleteBusinessBtnContainer>
        </div>

        <LoaderContainer :loading="financialTypeLoading || statusLoading">
          <IFRSAssets
            v-if="data === 'IFRS'"
            :set-saving-status="setSavingStatus"
          />
          <NonIfrsAsset
            v-if="data === 'Non-IFRS'"
            :set-saving-status="setSavingStatus"
          />
        </LoaderContainer>
      </IFRSCheckerContainer>
    </EntityChecker>
  </SideNavContainer>
</template>

<script setup>
import { SideNavContainer } from "../../../../layout";
import AssetsDateHandler from "../../DateHandler/BalanceSheet/AssetsDateHandler.vue";
import AssetsBusiness from "../../BusinessHandler/BalanceSheet/AssetsBusiness.vue";
import { computed, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { getEntityItem } from "../../../../../store/entityActions/get";
import { useRoute, useRouter } from "vue-router";
import { useEntityStore } from "../../../../../store/entity";
import { useBusinessStore } from "../../../../../store/buisness.store";
import { useFinancialDataStore } from "../../../../../store/financial.store";
import { BalanceSheetProfitLossBreadCrumb } from "../../../../BreadCrumbs";
import { useStatusStore } from "../../../../../store/statusStore";
import LoaderContainer from "../../../../common/LoaderContainer.vue";
import DeleteBusinessBtnContainer from "../../BusinessHandler/common/DeleteBusinessBtnContainer.vue";
import IFRSCheckerContainer from "../../IFRSCheckerContainer.vue";
import { useIFRSStore } from "../../../../../store/NonIFRS/IFRS.store";
import NonIfrsAsset from "../../NON-IFRS/BalanceSheet/Assets/Assets";
import { useNoneIFRSBalanceSheet } from "../../../../../store/NonIFRS/balancesheet.store";
import EntityChecker from "../../../AddEntity/EntityChecker.vue";
import IFRSAssets from "./IFRSAssets.vue";
defineProps({
  view: {
    type: Boolean,
    default: false,
  },
});
const router = useRouter();
const nonIfrsBalanceSheet = useNoneIFRSBalanceSheet();
const entityStore = useEntityStore();
const { entity } = storeToRefs(entityStore);
const financialDataStore = useFinancialDataStore();
const route = useRoute();
const businessStore = useBusinessStore();
const ifrsStore = useIFRSStore();
const { data, loading: financialTypeLoading } = storeToRefs(ifrsStore);
const statusStore = useStatusStore();
const { status, loading: statusLoading } = storeToRefs(statusStore);
const { loading: nonIFRSLoading } = storeToRefs(nonIfrsBalanceSheet);

const message = ref("");
const type = ref("");
const { loading } = storeToRefs(financialDataStore);
const name = computed(() => {
  if (!entity.value.data) return "";
  const Entity = entity.value.data?.entity[0];
  if (Entity?.entity_type == 2) {
    return `${Entity?.entity_first_name} ${Entity?.entity_last_name}`;
  }
  return Entity?.entity_name;
});
onMounted(async () => {
  if (!entity.value.data) {
    const id = route.params.id;
    await getEntityItem("entity", "/entity", id);
  }
  loading.value = true;
});
const changeTab = (tab, business_id) => {
  router.push(
    `/entity/financial-data/balance-sheet/${tab}/${
      route.params.id
    }?financial_period=${route.query.financial_period}&financial_year=${
      route.query.financial_year
    }${
      business_id
        ? "&business=" + business_id
        : route.query.business
        ? "&business=" + route.query.business
        : ""
    }`
  );
};
const handleSave = async () => {
  if (data.value === "IFRS") {
    await financialDataStore.save(
      route,
      (Type, Message) => {
        type.value = Type;
        message.value = Message;
      },
      (id) => {
        setTimeout(() => changeTab("liabilities", id), 2000);
      }
    );
  } else if (data.value === "Non-IFRS") {
    await nonIfrsBalanceSheet.saveNonIFRSBalanceSheet(
      route.params.id,
      route.query.financial_year,
      route.query.financial_period,
      route.query.business,
      ({ entity_business_id: id }) => {
        setTimeout(() => changeTab("liabilities", id), 2000);
      }
    );
  }
};
const createBusiness = async () => {
  await businessStore.addBusiness(
    {
      entity_id: route.params.id,
      financial_period: route.query.financial_period,
      financial_year: route.query.financial_year,
    },
    (mess) => {
      message.value = mess;
    },
    async ({ id }) => {
      await businessStore.getBusiness(
        route.params.id,
        route.query.financial_year,
        route.query.financial_period
      );
      const { pathname } = window.location;
      await router.push(
        `${pathname}?financial_year=${
          route.query.financial_year
        }&financial_period=${route.query.financial_period}${
          id ? "&business=" + id : ""
        }`
      );
    }
  );
};
</script>

<style scoped>
.tab-button {
  width: 140px;
  height: 44px;
  border-radius: 4px;
  background: #eff3f3;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}

.tab-button.active {
  background: linear-gradient(90deg, #101432 0%, #00a7a7 100%);
  color: #fafbff;
}

.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.title-container {
  padding: 25px 10px 16px 10px;
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.055em;
  color: #000000;
}

.subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
  font-style: normal;
}
.save-button:disabled {
  opacity: 0.5;
}
</style>

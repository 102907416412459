<template>
  <SideNavContainer
    v-slot="{ setSavingStatus }"
    side-nav-type="financialData"
    title="Adjustment as per Chapter-9"
    :subtitle="name"
    :TitleRightSide="FinancialYear"
  >
    <EntityChecker>
      <ChaptersBreadCrumb
        :entity-name="name"
        chapter="chapter-9"
        chapter-name="Chapter-9"
      />
      <Business Tab="chapter-9" />
      <LoaderContainer
        :loading="chapter9.loading || loading || statusLoading"
        :error="chapter9.error || statusError"
      >
        <div class="row mt-10">
          <div class="col-lg-12">
            <Chapter9Popup
              v-if="$route.query.current_business !== 'master'"
              :type="additionType"
              :model="model"
              :open="modal"
              :set-open="setModal"
            />
            <Chapter9Popup5 :open="modal5" :set-open="setModal5" />
            <Chapter9Popup7 :open="modal7" :set-open="setModal7" />
          </div>
          <Chapter9Addition :set-saving-status="setSavingStatus" />
          <div class="col-lg-6">
            <div
              class="header d-flex align-items-center w-100 justify-content-between"
            >
              <p class="header-title mb-0">Deletion from Taxable Income</p>
              <p class="header-title mb-0">Amount</p>
            </div>
            <div
              v-for="(item, key, index) in chapter9.deduction_description"
              :key="key"
              :class="`w-100 table-body ${
                !index || !(index % 2) ? 'gray' : 'white'
              }  d-flex align-items-center justify-content-between`"
            >
              <p class="mb-0 body-title">{{ item }}</p>
              <div class="d-flex align-items-center">
                <p
                  class="mb-0 body-title cursor-pointer mr-3"
                  :style="{ color: '#0000b3' }"
                  @click="setModal(true, key, 'deduction_item')"
                >
                  {{
                    $route.query.current_business === "master"
                      ? NumberFormatter(chapter9.deduction_item[key])
                      : NumberFormatter(chapter9.deduction_item[key]?.total)
                  }}
                </p>
              </div>
              <input
                :id="'input-' + key"
                v-model="chapter9.deduction_item[key]"
                :hidden="
                  !deduction_itemInput[key] ||
                  $route.query.current_business === 'master'
                "
                type="number"
                class="clickable-text"
                @blur="openCloseInput('deduction', key, false)"
              />
            </div>
            <EditableSheet
              v-if="$route.query.current_business !== 'master'"
              v-model="chapter9.additional_data.deduction.data"
              v-model:total="chapter9.additional_data.deduction.total"
            />
            <div
              v-if="$route.query.current_business === 'master'"
              class="w-100 table-body white d-flex align-items-center justify-content-between"
            >
              <p class="mb-0 body-title">Additional</p>
              <div class="d-flex align-items-center">
                <p class="mb-0 body-title mr-3 cursor-pointer">
                  {{ NumberFormatter(chapter9.additional_data.deduction) }}
                </p>
              </div>
            </div>
            <div
              class="total-container d-flex align-items-center justify-content-between"
            >
              <p class="mb-0 total-amount">Total deduction</p>
              <div class="d-flex align-items-center">
                <p
                  class="mb-0 total-amount mr-3"
                  :style="{ color: chapter9Color.total_diduction }"
                >
                  {{ NumberFormatter(chapter9TotalDeduction) }}
                </p>
                <v-tooltip
                  v-if="chapter9Color.total_diduction === '#e60000'"
                  :text="`Computed value ${NumberFormatter(
                    chapter9.total_diduction_computed || ''
                  )}`"
                  location="bottom"
                >
                  <template #activator="{ props }">
                    <p class="bold mb-0" v-bind="props">
                      <b> ?</b>
                    </p>
                  </template>
                </v-tooltip>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div
              class="grand-total d-flex align-items-center justify-content-between mb-10"
            >
              <p class="mb-0 grand-total-amount">Net Addition/(deduction)</p>
              <div class="d-flex align-items-center">
                <p class="mb-0 grand-total-amount mr-3">
                  {{ NumberFormatter(chapter9Total) }}
                </p>
                <v-tooltip
                  v-if="chapter9Color.total === '#e60000'"
                  :text="`Computed value ${NumberFormatter(
                    chapter9.total_computed || ''
                  )}`"
                  location="bottom"
                >
                  <template #activator="{ props }">
                    <p class="bold mb-0" v-bind="props">
                      <b> ?</b>
                    </p>
                  </template>
                </v-tooltip>
              </div>
            </div>
          </div>
          <div
            v-if="$route.query.current_business !== 'master'"
            class="w-100 mt-0 d-flex align-items-center justify-content-end"
          >
            <button class="btn-submit" @click="saveData()">Save</button>
          </div>
          <div class="col-lg-12 mt-10">
            <h5 class="reference">Reference for Clause Below-</h5>
          </div>
          <v-expansion-panels v-if="chapter9.articles.length">
            <v-expansion-panel
              v-for="(article, i) in chapter9.articles"
              :key="i"
            >
              <v-expansion-panel-title
                collapse-icon="mdi-minus"
                expand-icon="mdi-plus"
              >
                <div
                  class="article px-3 py-3 w-100 d-flex align-items-center justify-content-between"
                >
                  <h6 class="article-tile mb-0">{{ article.name }}</h6>
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text
                ><div v-html="article.description"></div
              ></v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </LoaderContainer>
    </EntityChecker>
  </SideNavContainer>
</template>

<script setup>
import SideNavContainer from "../../layout/SideNavContainer.vue";
import { useRoute } from "vue-router";
import { useComputeIncomeTaxStore } from "../../../store/incometax.store";
import LoaderContainer from "../../common/LoaderContainer.vue";
import { storeToRefs } from "pinia";
import { ref, watch, onMounted } from "vue";
import Business from "../Business/Business.vue";
import { NumberFormatter } from "../../../helpers/numberFormatter";
import Chapter9Popup from "./chapter9/Popup.vue";
import Chapter9Popup5 from "./chapter9/Popup5.vue";
import Chapter9Popup7 from "./chapter9/Popup7.vue";
import EntityChecker from "../../entity/AddEntity/EntityChecker.vue";
import { useEntityStore } from "../../../store/entity";
import { ChaptersBreadCrumb } from "../../BreadCrumbs";
import { useStatusStore } from "../../../store/statusStore";
import { useBusinessStore } from "../../../store/buisness.store";
import EditableSheet from "../../common/EditableSheet.vue";
import Chapter9Addition from "./chapter9/Additions.vue";
import FinancialYear from "../common/FinancialPeriodYear.vue";

const route = useRoute();
const name = ref("");
const entity_id = route.params.id;
const message = ref("");

const computeIncomeTaxStore = useComputeIncomeTaxStore();
const businessStore = useBusinessStore();
const entityStore = useEntityStore();
const { entity, type } = storeToRefs(entityStore);
const statusStore = useStatusStore();
const {
  status,
  loading: statusLoading,
  error: statusError,
} = storeToRefs(statusStore);
const {
  chapter9,
  chapter9Total,
  chapter9TotalAddition,
  chapter9TotalDeduction,
  chapter9DisallowancePerArticle,
  chapter9Color,
  computeIncomeTax,
  loading,
} = storeToRefs(computeIncomeTaxStore);
const { business } = storeToRefs(businessStore);
const modal = ref(false);
const modal5 = ref(false);
const modal7 = ref(false);
const addition_itemInput = ref({});
const deduction_itemInput = ref({});
const model = ref("expenses_debited_in_PL");
const additionType = ref("addition_item");
watch(chapter9, (newValue) => {
  if (!newValue.loading && !newValue.error && newValue.addition_description) {
    for (let x in newValue.addition_description) {
      addition_itemInput.value[x] = false;
    }
  }
  if (!newValue.loading && !newValue.error && newValue.deduction_description) {
    for (let x in newValue.deduction_description) {
      deduction_itemInput.value[x] = false;
    }
  }
});
const setModal = (value, key, type) => {
  if (route.query.current_business === "master" || status.value) {
    return;
  }
  if (key) {
    if (!chapter9.value.addition_item_status[key] && type === "addition_item") {
      return;
    }
    model.value = key;
  }
  if (type) {
    additionType.value = type;
  }
  modal.value = value;
};

const setModal5 = (value) => {
  if (status.value) {
    return;
  }
  if (
    !chapter9.value.addition_item_status[
      "interest_expense_disallowed_as_per_article_30"
    ]
  ) {
    return;
  }

  modal5.value = value;
};
const setModal7 = (value) => {
  if (route.query.current_business === "master" || status.value) {
    return;
  }
  modal7.value = value;
};
const setBusinessName = (newValue, current_business) => {
  const findBusiness = newValue?.data?.find((b) => b.id == current_business);
  name.value = findBusiness?.name ? findBusiness?.name : "";
};
watch(
  business,
  (newValue) => {
    if (newValue?.data.length)
      setBusinessName(newValue, route.query.current_business);
  },
  { deep: true }
);
watch(
  () => route.query,
  (newValue) => {
    setBusinessName(business.value, newValue.current_business);
  }
);
onMounted(async () => {
  await computeIncomeTaxStore.getchapter9(
    entity_id,
    route.query.financial_year,
    route.query.financial_period,
    route.query.current_business
  );
  await computeIncomeTaxStore.getComputeIncomeTax(
    entity_id,
    route.query.financial_year,
    route.query.financial_period,
    route.query.current_business
  );
  if (route.query.financial_year && route.query.financial_period) {
    await statusStore.getStatus(
      route.params.id,
      route.query.financial_year,
      route.query.financial_period
    );
  }
});
const openCloseInput = async (type, key, value) => {
  if (route.query.current_business === "master" || status.value) {
    return;
  }
  if (
    key === "expenses_related_to_exempt_income" &&
    !computeIncomeTax.value?.chapter_seven
  ) {
    return;
  }
  if (type === "addition") {
    if (!chapter9.value.addition_item_status[key]) {
      return;
    }
    addition_itemInput.value[key] = value;
  } else if (type === "deduction") {
    deduction_itemInput.value[key] = value;
  }
  if (value) {
    const input = await document.getElementById(`input-${key}`);
    input.focus();
  }
};

const changeColor = (key, type) => {
  if (type === "addition") {
    chapter9.value.addition_item_color[key] = "#e60000";
  } else if (type === "deduction") {
    chapter9.value.deduction_item_color[key] = "#e60000";
  }
};
const saveData = async () => {
  await computeIncomeTaxStore.saveChapter9(route, async () => {
    await computeIncomeTaxStore.getchapter9(
      entity_id,
      route.query.financial_year,
      route.query.financial_period,
      route.query.current_business
    );
  });
};
const handleClick = (key) => {
  if (key === "disallowance_as_per_article_33") {
    return;
  }
  if (
    (business.value.data.length > 1 &&
      route.query.current_business !== "master" &&
      key === "interest_expense_disallowed_as_per_article_30") ||
    (route.query.current_business === "master" &&
      key !== "interest_expense_disallowed_as_per_article_30")
  ) {
    return;
  }

  if (
    key === "expenses_debited_in_PL" || //1
    key === "expenses_wholly_partially_not_related_to_business_activity" || //2
    key === "expenses_related_to_exempt_income" || // 3
    key === "interest_disallowed_as_per_article_28" || //4
    key === "other_expenses_disallowed" || //4
    key === "interest_expense_disallowed_as_per_article_31" || //6
    key === "entertainment_expense_disallowed_as_per_article_32" //7
  ) {
    setModal(true, key, "addition_item");
  } else if (
    key === "interest_expense_disallowed_as_per_article_30" //5
  ) {
    setModal5(true);
  } else {
    openCloseInput("addition", key, true);
  }
};
const setAmount = (key) => {
  if (route.query.current_business === "master") {
    return chapter9.value.addition_item[key];
  }

  if (
    key === "expenses_wholly_partially_not_related_to_business_activity" || //2
    key === "expenses_debited_in_PL" || // 1
    key === "expenses_related_to_exempt_income" || // 3
    key === "interest_disallowed_as_per_article_28" || //4
    key === "other_expenses_disallowed" || // 4
    key === "interest_expense_disallowed_as_per_article_31" || //6
    key === "entertainment_expense_disallowed_as_per_article_32" //7
  ) {
    if (chapter9.value.addition_item[key]) {
      return chapter9.value.addition_item[key]?.total;
    } else return "";
  } else if (key === "disallowance_as_per_article_33") {
    //8
    return chapter9DisallowancePerArticle.value;
  }
  return chapter9.value.addition_item[key];
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: white;
}
.table-body {
  min-height: 80px;
  padding-right: 88px;
  padding-left: 26px;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 30px;
}
.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}
.total-container {
  height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}
.total-desc {
  background: #deeded;
}
.reference {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.article-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px; /*
identical to box height, or 185% */
  letter-spacing: 0.055em;
  color: #000000;
}

.white {
  background: #fafbff;
}
.gray {
  background: #deeded;
}
.btn-submit {
  width: 82px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
.body-title.disabled {
  color: #747171 !important;
}
.total-amount.disabled {
  color: #747171 !important;
}
.cursor-pointer {
  cursor: pointer;
}
</style>

<template>
  <div :class="`w-100 ${containerClass}`">
    <h4
      v-if="label && !label?.toString().includes('*')"
      :class="`label mb-0 ${disabled ? 'disabled' : ''}`"
    >
      {{ label }}
    </h4>
    <h4
      v-if="label && label?.toString().includes('*')"
      :class="`label mb-0 ${disabled ? 'disabled' : ''}`"
    >
      {{ label.toString().replace("*", "") }} <span class="text-danger">*</span>
    </h4>
    <div class="d-flex align-items-center">
      <input
        v-bind="$props"
        v-model="value"
        :type="
          type
            ? type === 'password'
              ? showpassword
                ? 'text'
                : 'password'
              : type
            : 'text'
        "
        :class="`custom-input w-100 ${
          addSuggestion ? 'border-' + color : ''
        } ${customClass} ${errorMessage ? 'error' : ''}  `"
        :placeholder="placeholder"
        :disabled="disabled"
        :pattern="pattern"
        :accept="accept"
        step="0.01"
        @change="handleChange"
      />
      <img
        v-if="type === 'password' && !showpassword"
        src="@/assets/svg/eye-off.svg"
        :class="`eye-off cursor-pointer ${disabled ? 'disabled' : ''}`"
        @click="showPassword"
      />
      <img
        v-else-if="type === 'password' && showPassword"
        src="@/assets/svg/eye.svg"
        :class="`eye-off eye cursor-pointer ${disabled ? 'disabled' : ''}`"
        @click="showPassword"
      />
      <p
        v-if="type === 'password' && addSuggestion"
        :class="`p-2 mb-0 suggestion text-${color}`"
        :style="{
          marginLeft:
            -(suggestion === 'Too short'
              ? 120
              : suggestion === 'Include digits'
              ? 140
              : suggestion === '✓ Strong password'
              ? 180
              : (suggestion.length || 11) * 9) + 'px',
        }"
      >
        {{ suggestion }}
      </p>
    </div>
    <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
    <p
      v-if="type === 'file' && supportedFileText"
      class="supported-file-text text-center"
    >
      {{ supportedFileText }}
    </p>
  </div>
</template>

<script>
import { checkPasswordStrength } from "../../helpers/password";

export default {
  name: "CustomTextInput",
  props: [
    "modelValue",
    "placeholder",
    "label",
    "type",
    "options",
    "errorMessage",
    "onChange",
    "disabled",
    "pattern",
    "accept",
    "customClass",
    "containerClass",
    "supportedFileText",
    "checkValidity",
    "addSuggestion",
  ],
  emits: ["update:modelValue"],
  data() {
    return {
      showpassword: false,
      suggestion: "",
      color: "",
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
        if (this.type === "password" && value && this.addSuggestion) {
          let { valid, suggestion, color } = checkPasswordStrength(value);
          this.suggestion = suggestion;
          this.color = color;
          if (this.checkValidity) {
            this.checkValidity(valid);
          }
        }
      },
    },
  },
  methods: {
    handleChange(e) {
      if (this.onChange) {
        this.onChange(e);
      }
    },
    showPassword() {
      this.showpassword = !this.showpassword;
    },
  },
};
</script>

<style scoped>
.custom-input {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  height: 56px;
  padding: 13px 10px;
  margin-top: 8px;
}
.custom-input.error {
  border: 1px solid red;
}
.custom-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
  text-align: start;
}
.custom-input:focus {
  outline: 0;
}
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
  margin-bottom: 18px;
}
.mt-31 {
  margin-top: -32px !important;
}
.eye-off {
  margin-left: -30px;
}
.eye {
  height: 30px;
  width: 25px;
}
.supported-file-text {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #959292;
}
.suggestion {
  font-size: 14px;
  margin-bottom: 0;
  z-index: 2;
  background: #deeded;
}
.custom-input:disabled {
  opacity: 0.5;
}
.label.disabled {
  opacity: 0.5;
}
.eye-off.disabled {
  opacity: 0.5;
}
.custom-input.border-danger {
  border-bottom: 1px solid --bs-danger;
}
.custom-input.border-warning {
  border-bottom: 1px solid --bs-warning;
}
.custom-input.border-success {
  border-bottom: 1px solid --bs-success;
}
</style>

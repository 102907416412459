<template>
  <div class="w-100">
    <form
      v-for="item in data"
      :id="item.model"
      :key="item.id"
      :class="`w-100 table-body  d-flex align-items-center justify-content-between`"
    >
      <div class="d-flex w-75">
        <p class="mb-0 body-title count">
          <b>
            {{ renameIndexExpense(item.model) }}
          </b>
        </p>
        <p class="mb-0 d-flex body-title item-name">
          {{ item.name }}
        </p>
      </div>
      <p
        v-if="!item.showInput"
        class="body-title mb-0"
        @click="openCloseInput(item.model, true)"
      >
        {{ NumberFormatter(expense[item.model]) }}
      </p>

      <input
        :id="'input-qfz-calc-' + item.model"
        v-model="expense[item.model]"
        :hidden="!item.showInput"
        type="text"
        class="clickable-text"
        @blur="openCloseInput(item.model, false, checkNonNegative)"
        @keydown.tab.enter.down.up="(e) => handleTab(e, item.model)"
        @keypress="(e) => handleChange(e, item.model)"
      />
    </form>
    <div
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 total-amount w-70">
        Total Net Expenses of Freezone Person
      </p>
      <p class="mb-0 total-amount">
        {{ NumberFormatter(totalExpense) }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

import { useQFZCalculatorStore } from "../../../store/QFZcalculator";
import { storeToRefs } from "pinia";
import { NumberFormatter } from "../../../helpers/numberFormatter";
import { insertChar } from "../../../helpers/stringUtils";
import { fzpCalcExpenseData } from "./data";
import { renameIndexExpense } from "./controller";

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  setAlert: {
    type: Function,
    default: () => {},
  },
});
const QFZCalculator = useQFZCalculatorStore();
const { expense, totalExpense } = storeToRefs(QFZCalculator);
const data = ref(fzpCalcExpenseData);
const handleChange = (e, model) => {
  let value = "";
  const index = e.target.selectionStart;
  const prevValue = e.target.value;
  const currentKey = String.fromCharCode(e.keyCode);
  value = insertChar(prevValue, currentKey, index);
  if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(value)) {
    e.preventDefault();
    return;
  }
  value = Number(value);
  if (value < 0) {
    e.preventDefault();
    e.target.blur();
    props.setAlert(true, "Can not accept negative values");
    expense.value[model] = "";
  }
  if (model !== "total_expenses_of_qfzp") {
    const totalExpenseOfQFZP = Number(expense.value.total_expenses_of_qfzp);
    const total = data.value.reduce((a, b) => {
      if (b.model === "total_expenses_of_qfzp") {
        return a + 0;
      } else if (b.model === model) {
        return a + value;
      }
      return a + Number(expense.value[b.model]);
    }, 0);
    if (value > totalExpenseOfQFZP) {
      e.preventDefault();
      e.target.blur();
      props.setAlert(
        true,
        "Value should not be greater than Total Expense of Freezone Person value"
      );
      return;
    }
    if (total > totalExpenseOfQFZP) {
      e.preventDefault();
      e.target.blur();
      props.setAlert(
        "Sum of values should not be greater than Total Expense of Freezone Person value"
      );
    }
  }
};
const checkNonNegative = (model) => {
  const value = Number(expense.value[model]);
  if (value < 0) {
    props.setAlert("Can not accept negative values");
    expense.value[model] = "";
  }
};
const openCloseInput = async (model, value, callBack = () => {}) => {
  if (props.disabled) {
    return;
  }
  data.value = data.value.map((item) => {
    if (item.model === model) {
      return { ...item, showInput: value };
    }
    return item;
  });
  if (value) {
    const input = await document.getElementById(`input-qfz-calc-${model}`);
    input.focus();
  }
  callBack(model);
};

const handleTab = async (e, current_model) => {
  e?.preventDefault();
  if (e.key === "ArrowUp") {
    return handleUpArrow(e, current_model);
  }
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
const handleUpArrow = async (e, current_model) => {
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) - 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}
.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  border-bottom: 1px solid #747171;
  /*background: #fafbff;*/
  padding-right: 100px;
  padding-left: 26px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}
.count {
  flex: 0.75;
}
.item-name {
  flex: 18;
}
.mb-00:hover {
  background-color: #2a9ed8;
  width: 100px;
  height: 30px;
  text-align: center;
  /*  padding-left: 20px;
  padding-right: 20px;
  margin-left: -200px;*/
}
.clickable-text:focus {
  background-color: #2a9ed8;
  width: 100px;
  height: 30px;
  margin-left: -200px;
}
@media screen and (max-width: 600px) {
  .count {
    flex: 2;
  }
}
b,
strong {
  margin-left: -16px;
}
.w-75 {
  margin-top: 25px;
}
</style>

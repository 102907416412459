<template>
  <BreadCrumb :links="breadCrumbLinks" />
</template>

<script setup>
import { ref, computed } from "vue";
import { generate } from "randomized-string";
import BreadCrumb from "../common/BreadCrumb.vue";
import { useRoute } from "vue-router";
import { useEntityStore } from "../../store/entity";
import { storeToRefs } from "pinia";

const route = useRoute();
const entityStore = useEntityStore();
const { entity } = storeToRefs(entityStore);
const breadCrumbLinks = computed(() => {
  const entityName = () => {
    if (!entity.value.data?.entity) {
      return "";
    }
    const Entity = entity.value.data?.entity[0];
    if (Entity?.entity_type == 2) {
      return `${Entity?.entity_first_name} ${Entity?.entity_last_name}`;
    }
    return Entity?.entity_name;
  };
  return [
    {
      id: generate(),
      name: "Home",
      link: "/",
      disabled: false,
      active: false,
    },
    {
      id: generate(),
      name: "Entity",
      link: "/entity",
      disabled: false,
      active: false,
    },
    {
      id: generate(),
      name: entityName(),
      link: "/entity/" + route.params.id,
      disabled: false,
      active: true,
    },
  ];
});
</script>

<style scoped></style>

import { useProfitAndLossStore } from "../../../../../store/ProfitAdLoss";
import { useBusinessStore } from "../../../../../store/buisness.store";

export const autoSaveIncome = async (
  previousValue,
  currentValue,
  setSavingStatus,
  route,
  router
) => {
  const profitLossStore = useProfitAndLossStore();
  const businessStore = useBusinessStore();

  if (previousValue != currentValue) {
    setSavingStatus("loading", true);
    await profitLossStore.autoSaveProfitAndLoss(
      route,
      async (business) => {
        if (!route.query?.business) {
          await businessStore.getBusiness(
            route.params.id,
            route.query.financial_year,
            route.query.financial_period
          );
          router.push({
            path: location.pathname,
            query: {
              ...route.query,
              business,
            },
          });
        }
        setTimeout(() => {
          setSavingStatus("loading", false);
          setSavingStatus("error", false);
          setSavingStatus("success", false);
        }, 3000);
      },
      setSavingStatus
    );
  }
};

import { defineStore } from "pinia";
import fetchWrapper from "../helpers/fetchWrapper";
import { Toast } from "../helpers/toast";

export const useChapter11Store = defineStore("chapter11", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      loading: false,
      error: false,
      savingMessage: {
        message: "",
        type: "info"
      },
      masterData: [],
      data: []
    };
  },
  actions: {
    async getChapter11(route) {
      try {
        this.loading = true;
        const entity_id = route.params.id;
        const { financial_year, financial_period, current_business } =
          route.query;
        const res = await fetchWrapper.get(
          `/chapter11?entity_id=${entity_id}&financial_year=${financial_year}&financial_period=${financial_period}${
            current_business !== "master"
              ? "&entity_business_id=" + current_business
              : ""
          }`
        );
        if (res.data?.result === 1) {
          if (route.query.current_business === "master") {
            this.masterData = res.data.data;
            this.error = false;
            this.loading = false;
            return;
          }
          this.data = res.data.data;
          this.error = false;
          this.loading = false;
        } else {
          this.error = res?.data.message;
          this.loading = false;
        }
      } catch (err) {
        console.log(err);
        this.error = err?.response?.data?.message || "";
        this.loading = false;
      }
    },
    async saveChapter11(route, data, callBack = () => {}) {
      let toast;
      try {
        const { financial_year, financial_period, current_business } =
          route.query;
        if (current_business === "master") {
          return;
        }
        toast = new Toast("Preparing data...");
        let payload = data;
        payload["entity_id"] = route.params.id;
        payload["financial_year"] = financial_year;
        payload["financial_period"] = financial_period;
        payload["entity_business_id"] = current_business;
        toast.makeInfoToast("saving...");
        const res = await fetchWrapper.post(`chapter11`, payload);
        if (res.data.result === 1) {
          toast.makeSuccessToast("Saved successfully");
          callBack();
        } else {
          toast.makeWarningToast(res.data.message);
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    }
  }
});

<template>
  <BreadCrumb :links="breadCrumbLinks" />
</template>

<script setup>
import { ref, watch, defineProps } from "vue";
import { generate } from "randomized-string";
import BreadCrumb from "../common/BreadCrumb.vue";
import { useRoute } from "vue-router";

const props = defineProps({
  entityName: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "",
  },
  tab: {
    type: String,
    default: "",
  },
});
const route = useRoute();
const name = ref(props.entityName);
const nameTheTab = () => {
  const tabLetter = props.tab.toString().split("");
  const firstLetter = tabLetter[0].toUpperCase();
  const letter = tabLetter.slice(1).join("");
  return firstLetter + letter;
};
const breadCrumbLinks = ref([
  {
    id: generate(),
    name: "Home",
    link: "/",
    disabled: false,
    active: false,
  },
  {
    id: generate(),
    name: "Entity",
    link: "/entity",
    disabled: false,
    active: false,
  },
  {
    id: "master-data",
    name: name,
    link: "/entity/master-data/" + route.params.id,
    disabled: false,
    active: false,
  },
  {
    id: "financial-data",
    name: "Financial Data",
    link: `/entity/financial-data/${route.params.id}?financial_year=${
      route.query.financial_year
    }&financial_period=${route.query.financial_period}${
      route.query.business ? "&business=" + route.query.business : ""
    }`,
    disabled: false,
    active: false,
  },
  {
    id: "profit-loss-balancesheet-view",
    name: props.type === "balance-sheet" ? "BalanceSheet" : "Profit and Loss",
    disabled: false,
    active: false,
    link: `/entity/financial-data/${props.type}/view/${
      route.params.id
    }?financial_year=${route.query.financial_year}&financial_period=${
      route.query.financial_period
    }${route.query.business ? "&business=" + route.query.business : ""}`,
  },
  {
    id: "profit-loss-balancesheet",
    name: nameTheTab(),
    link: `/entity/financial-data/${props.type}/${props.tab}/${
      route.params.id
    }?financial_year=${route.query.financial_year}&financial_period=${
      route.query.financial_period
    }${route.query.business ? "&business=" + route.query.business : ""}`,
    disabled: false,
    active: true,
  },
]);

watch(
  () => route.query,
  (newValue) => {
    breadCrumbLinks.value = breadCrumbLinks.value.map((item) => {
      if (item.id === "financial-data") {
        return {
          ...item,
          link: `/entity/financial-data/${route.params.id}?financial_period=${
            newValue.financial_period
          }&financial_year=${newValue.financial_year}${
            newValue.business ? "&business=" + newValue.business : ""
          }`,
        };
      } else if (item.id === "profit-loss-balancesheet-view") {
        return {
          ...item,
          link: `/entity/financial-data/${props.type}/view/${
            route.params.id
          }?financial_period=${newValue.financial_period}&financial_year=${
            newValue.financial_year
          }${newValue.business ? "&business=" + newValue.business : ""}`,
        };
      } else if (item.id === "profit-loss-balancesheet") {
        return {
          ...item,
          link: `/entity/financial-data/${props.type}/${props.tab}/${
            route.params.id
          }?financial_period=${newValue.financial_period}&financial_year=${
            newValue.financial_year
          }${newValue.business ? "&business=" + newValue.business : ""}`,
        };
      }
      return item;
    });
  },
  { deep: true }
);

watch(
  () => props.entityName,
  (newValue) => {
    breadCrumbLinks.value = breadCrumbLinks.value.map((item) => {
      if (item.id === "master-data") {
        return { ...item, name: newValue };
      }
      return item;
    });
  },
  { deep: true }
);
</script>

<style scoped></style>

/* eslint-disable no-prototype-builtins */
import { defineStore } from "pinia";
import fetchWrapper from "../helpers/fetchWrapper";
import { downloadURI } from "../helpers/download";
import fetchFileWrapper from "../helpers/fileApi";
import { Toast } from "../helpers/toast";
import { getWarningMessage, getErrorMessage } from "../helpers/errorHandler";

export const useProfitAndLossStore = defineStore("profitAndLoss", {
  state: () => {
    return {
      financial_year: null,
      financial_period: null,
      loading: false,
      error: true,
      sales: {
        revenue_sale_of_good: "",
        revenue_sale_of_service: "",
      },
      groupDatadividenIncome: "",
      dividenIncome: {
        any_other_income_participating_interest_specified_article_23: "",
        income_foreign_permanent_establishment_that_meet_condition_article_24:
          "",
        dividends_and_other_profit_distributions_received: "",
      },
      other_operating_revenue: {
        revenue_other_operating_revenue_interest_income: "",
        interest_earned: "",
        interest_on_leased_assets: "",
        others: "",
        revenue_other_operating_revenue_rent: "",
        revenue_other_operating_revenue_commission: "",
        revenue_other_operating_revenue_foreign_exchange_gain_loss: "",
        revenue_other_operating_revenue_income_from_participating_intrst: "",
        revenue_other_operating_revenue_income_of_foreign_permanent_estb: "",
        revenue_other_operating_revenue_any_other_income: "",
      },
      costOfSales: {
        cost_of_sale_change_in_inventory_opening_stock: "",
        cost_of_sale_change_in_inventory_purchase: "",
        cost_of_sale_change_in_inventory_closing_stock: "",
        cost_of_sale_power_and_fuel: "",
        cost_of_sale_jobwork_expenses: "",
        cost_of_sale_carriege_inward: "",
        cost_of_sale_labour_wages: "",
        cost_of_sale_other_direct_expneses: "",
      },
    };
  },
  getters: {
    DividenIncome(state) {
      return (route) => {
        let total = 0;
        for (let x in state.dividenIncome) {
          total += state.dividenIncome[x] ? Number(state.dividenIncome[x]) : 0;
        }
        return route?.query?.business === "master"
          ? Number(this.groupDatadividenIncome)
          : total;
      };
    },
    otherOperatingRevenue(state) {
      let total = 0;
      for (let x in state.other_operating_revenue) {
        if (x !== "revenue_other_operating_revenue_interest_income") {
          total += state.other_operating_revenue[x]
            ? Number(state.other_operating_revenue[x])
            : 0;
        }
      }
      return (route) => total + this.DividenIncome(route);
    },
    Revenue() {
      return (route) => {
        let totalSales =
          Number(this.sales.revenue_sale_of_good) +
          Number(this.sales.revenue_sale_of_service);
        return this.otherOperatingRevenue(route) + totalSales;
      };
    },
    changeInInventory(state) {
      let sum =
        Number(
          state.costOfSales.cost_of_sale_change_in_inventory_opening_stock
        ) + Number(state.costOfSales.cost_of_sale_change_in_inventory_purchase);
      return (
        sum -
        Number(state.costOfSales.cost_of_sale_change_in_inventory_closing_stock)
      );
    },
    CostOfSales(state) {
      let total = 0;
      for (let x in state.costOfSales) {
        if (
          x !== "cost_of_sale_change_in_inventory_opening_stock" &&
          x !== "cost_of_sale_change_in_inventory_purchase" &&
          x !== "cost_of_sale_change_in_inventory_closing_stock"
        )
          total += state.costOfSales[x] ? Number(state.costOfSales[x]) : 0;
      }
      return total + this.changeInInventory;
    },
    grossProfit() {
      return (route) => {
        let dif = this.Revenue(route) - this.CostOfSales;
        return dif; //+ this.otherOperatingRevenue(route);
      };
    },
  },
  actions: {
    async setToEmpty() {
      for (let x in this.sales) {
        this.sales[x] = "";
      }
      for (let y in this.costOfSales) {
        this.costOfSales[y] = "";
      }
      for (let a in this.dividenIncome) {
        this.dividenIncome[a] = "";
      }
      for (let b in this.other_operating_revenue) {
        if (b !== "revenue_other_operating_revenue_interest_income") {
          this.other_operating_revenue[b] = "";
        }
      }
    },
    async getPayload(route) {
      const id = route.params.id;
      const {
        financial_year,
        financial_period,
        business: entity_business_id,
      } = route.query;
      let payload = {
        ...this.sales,
        ...this.other_operating_revenue,
        ...this.costOfSales,
        revenue_other_operating_revenue_total_dividend_income:
          this.DividenIncome(route),
        revenue_other_operating_revenue_dividend_income: this.dividenIncome,
        revenue_other_operating_revenue: this.otherOperatingRevenue(route),
        revenue: this.Revenue(route),
        cost_of_sale: this.CostOfSales,
        cost_of_sale_change_in_inventory: this.changeInInventory,
        gross_profit: this.grossProfit(route),
        entity_id: Number(id),
        entity_business_id,
        financial_year: financial_year,
        financial_period: financial_period,
      };
      return payload;
    },
    async saveProfitAndLoss(route, callBack = () => {}) {
      const toast = new Toast("Saving....");
      try {
        //const res= await fetchWrapper()
        const payload = await this.getPayload(route);
        const res = await fetchWrapper.post(`/income`, payload);
        if (res?.data.result === 1) {
          toast.makeSuccessToast("Saved Successfully");
          callBack(res?.data?.entity_business_id);
        } else
          toast.makeErrorToast(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message
          );
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(
          "Something went wrong " +
            err?.message +
            ", " +
            err?.response?.data?.message || ""
        );
      }
    },
    async getIncomeRevenue(
      id,
      financial_year,
      financial_period,
      entity_business_id
    ) {
      if (financial_year && financial_period) {
        try {
          this.loading = true;
          const res = await fetchWrapper.get(
            `/income?entity_id=${id}&financial_period=${financial_period}&financial_year=${financial_year}${
              entity_business_id !== "master"
                ? "&entity_business_id=" + entity_business_id
                : ""
            }`
          );
          if (res?.data.result == 1) {
            let data = res?.data?.data;
            for (let x in data) {
              if (x !== "revenue_other_operating_revenue_dividend_income") {
                //other_operating_revenue,costOfSales
                let Sales = this.sales.hasOwnProperty(x);
                let otherOperating =
                  this.other_operating_revenue.hasOwnProperty(x);
                let cost = this.costOfSales.hasOwnProperty(x);
                if (cost) {
                  this.costOfSales[x] = data[x] || "";
                }
                if (otherOperating) {
                  if (x !== "revenue_other_operating_revenue_interest_income") {
                    this.other_operating_revenue[x] = data[x] || "";
                  }
                }
                if (Sales) {
                  this.sales[x] = data[x] || "";
                }
              } else {
                if (entity_business_id === "master") {
                  this.groupDatadividenIncome = data[x];
                } else this.dividenIncome = data[x];
              }
            }
          } else {
            await this.setToEmpty();
            this.error = getWarningMessage(res);
            this.loading = false;
            return;
          }
          this.loading = false;
          this.error = false;
        } catch (err) {
          console.log(err);
          this.error = err?.response?.data?.message || "";
          this.loading = false;
        }
      } else {
        await this.setToEmpty();
      }
    },
    async saveProfitAndLossFile(
      id,
      financial_year,
      financial_period,
      current_business,
      type = "Excel"
    ) {
      const toast = new Toast("Downloading....");
      try {
        const res = await fetchWrapper.get(
          `/profitlossExport?entity_id=${id}&financial_year=${financial_year}&financial_period=${financial_period}&type=${type}${
            current_business === "master"
              ? ""
              : "&entity_business_id=" + current_business
          }`
        );
        if (res.data?.result === 1) {
          const url = res.data?.url;
          await downloadURI(url, "profitAndLoss");
          toast.makeSuccessToast("Downloaded successfully");
        } else {
          toast.makeErrorToast(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message
          );
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    },
    async uploadProfitAndLossFile(
      entity_id,
      File,
      financial_year,
      financial_period,
      current_business,
      setMessage = () => {},
      callBack = () => {}
    ) {
      const toast = new Toast("Uploading file....");
      try {
        if (File && financial_year && financial_period) {
          let data = new FormData();
          data.append("file", File);
          data.append("entity_id", entity_id);
          data.append(
            "entity_business_id",
            current_business ? current_business : 0
          );
          data.append("financial_year", financial_year);
          data.append("financial_period", financial_period);
          const res = await fetchFileWrapper.post("/importProfitloss", data);
          if (res.data.result == 1) {
            toast.makeSuccessToast(res.data.message);
            callBack(res.data.entity_business_id);
            return;
          } else toast.makeWarningToast(getWarningMessage(res));
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    },
    async autoSaveProfitAndLoss(route, callBack = () => {}, setSavingStatus) {
      try {
        const payload = await this.getPayload(route);
        const res = await fetchWrapper.post(`/income`, payload);
        if (res?.data.result === 1) {
          setSavingStatus("loading", false);
          setSavingStatus("error", false);
          setSavingStatus("success", true);
          callBack(res?.data?.entity_business_id);
        } else {
          setSavingStatus("loading", false);
          setSavingStatus("error", getWarningMessage(res));
          setSavingStatus("success", false);
          setTimeout(() => {
            setSavingStatus("loading", false);
            setSavingStatus("error", false);
            setSavingStatus("success", false);
          }, 3000);
        }
      } catch (err) {
        console.log(err);
        setSavingStatus("loading", false);
        setSavingStatus("error", getErrorMessage(err));
        setSavingStatus("success", false);
        setTimeout(() => {
          setSavingStatus("loading", false);
          setSavingStatus("error", false);
          setSavingStatus("success", false);
        }, 3000);
      }
    },
  },
});

<template>
  <LoaderContainer :loading="OwnerDetails.loading">
    <ShowStatus :modal="editStatus" :set-modal="setStatus" />
    <CustomTable
      :Data="data"
      :have-index="true"
      :Headers="headers"
      :actions="actions"
    />
  </LoaderContainer>
</template>

<script>
import CustomTable from "@/components/common/CustomTable.vue";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useEntityStore } from "../../../../store/entity";
import { getEntityItem } from "../../../../store/entityActions/get";
import { useCountryStore } from "../../../../store/country";
import { generate } from "randomized-string";
import tableEdit from "../../../../assets/svg/table-edit.svg";
import tableDelete from "../../../../assets/svg/trash.svg";
import { useRouter, useRoute } from "vue-router";
import { deleteEntity } from "../../../../store/entityActions/delete";
import ShowStatus from "./ShowStatus.vue";
import { LoaderContainer } from "../../../common";

export default {
  name: "OwnerShipDetails",
  components: { CustomTable, LoaderContainer, ShowStatus },
  setup() {
    const countryStore = useCountryStore();
    const entityStore = useEntityStore();
    const { OwnerDetails, entity } = storeToRefs(entityStore);
    const router = useRouter();
    const route = useRoute();
    const editStatus = ref(false);
    const setStatus = (status) => {
      editStatus.value = status;
    };
    const actions = ref([
      {
        id: generate(),
        icon: tableEdit,
        onClick: (subId) => {
          if (entity.value?.data?.entity[0]?.file_return_status === "Filed") {
            setStatus(true);
            return;
          }
          router.push(
            `/entity/add-entity/ownership-details/${route.params.id}?owner_id=${subId}`
          );
        },
      },
      {
        id: generate(),
        icon: tableDelete,
        onClick: async (id) => {
          if (confirm("Are you sure you want to delete this manager detail?")) {
            await deleteEntity(
              id,
              "/entityOwnerDetails",
              (message) => {},
              () => location.reload()
            );
          }
        },
      },
    ]);
    const headers = ref([
      "Name",
      "Current resident of UAE",
      "Emirates ID Number",
      "Nationality",
      "Passport Number",
      "Mobile Number",
      "Gender",
      "Email",
      "Passport Issue Date",
      "Passport Expiry Date",
      "Address",
    ]);
    return {
      entityStore,
      OwnerDetails,
      countryStore,
      headers,
      actions,
      editStatus,
      setStatus,
    };
  },
  data() {
    return {
      data: [],
    };
  },
  async created() {
    const id = this.$route.params.id;
    await getEntityItem("OwnerDetails", "/entityOwnerDetails", id);
    const Data = [];
    for (let i = 0; i < this.OwnerDetails.data.length; i++) {
      let item = this.OwnerDetails.data[i];
      let nationality = await this.countryStore.getCountryName(
        item?.nationality
      );
      let Item = {
        id: item?.id,
        name_of_manager: item?.manager_name,
        resident_of_uae: item?.is_from_uae,
        emirates_id_number_of_manager: item?.emirates_id_number,
        nationality,
        passport: item?.passport_no,
        mobile: item?.mobile_no,
        gender: item?.gender,
        email: item?.email,
        issue_date: item?.passport_issue_date,
        expiry_date: item?.passport_expiry_date,
        address: item?.address,
      };
      Data.push(Item);
    }
    this.data = Data;
  },
};
</script>

<style scoped>
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

<template>
  <SideNavContainer title="Add Entity">
    <SubscriptionChecker>
      <EntitySubscriptionChecker :check="!$route.params?.id">
        <AddEntityTab active="entityDetails" :entity-type="`${entity_type}`" />
        <Progress />
        <LoaderContainer :loading="loading || entity_sub_type_loading">
          <StatusChecker>
            <form @submit="handleSubmit">
              <div class="row wrapper px-2">
                <div class="col-lg-4 col-md-6 mt-4">
                  <CustomSelectInput
                    v-model="entity_type"
                    item-title="name"
                    item-value="id"
                    :options="type.data"
                    label="Entity Type *"
                    placeholder="Select Entity Type"
                    :error-message="error.entity_type"
                  />
                </div>

                <div
                  v-if="
                    entity_type == 1 || entity_type == 2 || entity_type == 3
                  "
                  class="col-lg-4 col-md-6 mt-4"
                >
                  <h4 class="label">
                    Entity Sub-Type <span class="text-danger">*</span>
                  </h4>
                  <v-autocomplete
                    ref="Vueselect"
                    v-model="entity_sub_type"
                    v-model:search="entity_sub_type_search"
                    :items="subType.data"
                    item-title="name"
                    item-value="id"
                    bg-color="#DEEDED"
                    :color="error.entity_sub_type ? 'red' : ''"
                    label="Select Entity Sub-Type"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title @click="setSubType">
                          Create <strong>{{ entity_sub_type_search }}</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                  <p
                    v-if="error.entity_sub_type"
                    :style="{ 'margin-top': '-20px' }"
                    class="text-danger"
                  >
                    {{ error.entity_sub_type }}
                  </p>
                </div>
                <div
                  v-if="entity_type == 1 || entity_type == 3"
                  class="col-lg-4 col-md-6 mt-4"
                >
                  <CustomTextInput
                    v-model="entity_name"
                    :error-message="error.name"
                    placeholder="Entity Name"
                    label="Entity Name *"
                    @keypress="onlyAcceptAlphabetWithSymbol"
                  />
                </div>

                <div v-if="entity_type == 2" class="col-lg-4 col-md-6 mt-4">
                  <CustomTextInput
                    v-model="entity_first_name"
                    :error-message="error.entity_first_name"
                    placeholder="Entity First Name"
                    label="Entity First Name *"
                    @keypress="onlyAcceptAlphabets"
                  />
                </div>

                <div v-if="entity_type == 2" class="col-lg-4 col-md-6 mt-4">
                  <CustomTextInput
                    v-model="entity_last_name"
                    :error-message="error.entity_last_name"
                    placeholder="Entity Last Name"
                    label="Entity Last Name *"
                    @keypress="onlyAcceptAlphabets"
                  />
                </div>

                <div v-if="entity_type == 2" class="col-lg-4 col-md-6 mt-4">
                  <CustomDateInput
                    v-model="date_of_commencement_of_business"
                    label="Date of Commencement of Business *"
                    :max="dateNow()"
                    placeholder="Date of Commencement of Business"
                  />
                </div>

                <div
                  v-if="entity_type == 1 || entity_type == 3"
                  class="col-lg-4 col-md-6 mt-4"
                >
                  <CustomDateInput
                    v-model="date_of_incorporation"
                    label="Date of Incorporation *"
                    :max="dateNow()"
                    placeholder="Place of Incorporation"
                    :error-message="error.date_of_incorporation"
                  />
                </div>

                <div
                  v-if="
                    entity_type == 1 || entity_type == 2 || entity_type == 3
                  "
                  class="col-lg-4 col-md-6 mt-4"
                >
                  <CustomTextInput
                    v-model="trade_name"
                    placeholder="Trade Name *"
                    label="Trade Name *"
                    :error-message="error.trade_name"
                  />
                </div>
                <div
                  v-if="entity_type == 1 || entity_type == 3"
                  class="col-lg-4 col-md-6 mt-4"
                >
                  <h4 class="label">
                    Country of Incorporation <span class="text-danger">*</span>
                  </h4>
                  <v-autocomplete
                    v-model="place_of_incorporation"
                    label="Country of Incorporation"
                    :items="
                      entity_sub_type == 26
                        ? countries.data.filter(
                            (country) => country.name !== 'United Arab Emirates'
                          )
                        : countries.data
                    "
                    item-title="name"
                    item-value="id"
                    bg-color="#DEEDED"
                  >
                  </v-autocomplete>
                  <p v-if="error.place_of_incorporation" class="text-danger">
                    {{ error.place_of_incorporation }}
                  </p>
                </div>

                <div v-if="entity_type == 1" class="col-lg-4 col-md-6 mt-4">
                  <h4 class="label qualifying-benefit mb-0">
                    Are you a Qualifying Public Benefit Entity?
                    <span class="text-danger">*</span>
                  </h4>

                  <div
                    class="radio-container w-100 d-flex align-items-center pl-1"
                  >
                    <input
                      v-model="qualifying_benefit_entity"
                      :error-message="error.qualifying_benefit_entity"
                      type="radio"
                      value="Y"
                      class=""
                    />
                    <p class="mb-0 yes">Yes</p>
                    <input
                      v-model="qualifying_benefit_entity"
                      :error-message="error.qualifying_benefit_entity"
                      type="radio"
                      name=""
                      value="N"
                      class=""
                    />
                    <p class="mb-0 yes">No</p>
                  </div>
                  <p v-if="error.qualifying_benefit_entity" class="text-danger">
                    {{ error.qualifying_benefit_entity }}
                  </p>
                </div>

                <div
                  v-if="entity_type == 1 && qualifying_benefit_entity === 'Y'"
                  class="col-lg-4 col-md-6 mt-4"
                >
                  <CustomTextInput
                    v-model="decree_law_number"
                    placeholder="Decree Law Number"
                    label="Decree Law Number *"
                    :error-message="error.decree_law_number"
                    @keypress="onlyAcceptAlphaNumeric"
                  />
                </div>
                <div
                  v-if="entity_type == 1 && qualifying_benefit_entity === 'Y'"
                  class="col-lg-4 col-md-6 mt-4"
                >
                  <CustomFileUploadInput
                    v-model="decree_law_number_file"
                    label="Upload Decree Law"
                    :file-name="decree_law_number_file"
                    supported-file-text="*Supported file type - .png , .jpeg , .pdf. * Supported file size - 0 - 2 MB"
                    accept="image/png, image/jpeg, application/pdf"
                    :link="
                      decree_law_number_file
                        ? `${imagePath}/${decree_law_number_file}`
                        : ''
                    "
                    :error-message="error.decree_law_number_file"
                    :input-props="{
                      onchange: (e) =>
                        handleFileChange(e, 'registration_certification'),
                      accept: 'image/png, image/jpeg, application/pdf',
                    }"
                  />
                </div>

                <p class="my-2 text-info">{{ message }}</p>
                <div class="d-flex align-items-center mb-3">
                  <button class="save-next mr-3" type="submit">
                    Save & Next
                  </button>
                  <button
                    class="cancel"
                    type="button"
                    @click="$router.push('/entity')"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </StatusChecker>
        </LoaderContainer>
      </EntitySubscriptionChecker>
    </SubscriptionChecker>
  </SideNavContainer>
</template>

<script>
import { SideNavContainer } from "../../../../layout";
import {
  AddEntityTab,
  CustomDateInput,
  LoaderContainer,
} from "../../../../common";
import Progress from "./Progress.vue";
import CustomSelectInput from "@/components/common/CustomSelectInput.vue";
import CustomTextInput from "../../../../common/CustomTextInput.vue";
import SubscriptionChecker from "@/components/subscription/SubscriptionChecker.vue";
import { addEntity } from "../../../../../store/entityActions/post";
import { useEntityStore } from "../../../../../store/entity";
import { storeToRefs } from "pinia";
import { useCountryStore } from "../../../../../store/country";
import {
  getEntityItems,
  getEntityItem,
} from "../../../../../store/entityActions/get";
import Loader from "../../../../common/Loader.vue";
import { updateEntity } from "../../../../../store/entityActions/put";
import { dateNow, mdyToYMD } from "../../../../../helpers/date";
import EntitySubscriptionChecker from "../../../../subscription/EntitySubscriptionChecker.vue";
import { useProfileStore } from "../../../../../store/profile";
import StatusChecker from "../../StatusChecker.vue";
import {
  onlyAcceptAlphabets,
  onlyAcceptAlphaNumeric,
  onlyAcceptAlphabetWithSymbol,
} from "../../../../../helpers/validation";
import { checkFileSize } from "../../../../../helpers/file";
import CustomFileUploadInput from "../../../../common/CustomFileUploadInput.vue";
import { getExactValue } from "../../../../../helpers/stringUtils";
// import EntityChecker from '../../EntityChecker.vue'

export default {
  name: "EntityDetails",
  components: {
    SideNavContainer,
    AddEntityTab,
    Progress,
    CustomSelectInput,
    CustomTextInput,
    CustomDateInput,
    CustomFileUploadInput,
    SubscriptionChecker,
    Loader,
    // EntityChecker
    LoaderContainer,
    EntitySubscriptionChecker,
    StatusChecker,
  },

  setup() {
    const entityStore = useEntityStore();
    const {
      type,
      subType,
      entity,
      financialYear,
      businessActivity,
      licenseAuthorityType,
      licenseAuthority,
    } = storeToRefs(entityStore);
    const countryStore = useCountryStore();
    const profileStore = useProfileStore();
    const { countries, states } = storeToRefs(countryStore);

    return {
      type,
      entity,
      entityStore,
      subType,
      countries,
      countryStore,
      financialYear,
      businessActivity,
      states,
      licenseAuthorityType,
      licenseAuthority,
      dateNow,
      profileStore,
      onlyAcceptAlphabets,
      onlyAcceptAlphaNumeric,
      onlyAcceptAlphabetWithSymbol,
    };
  },
  data() {
    return {
      edit: false,
      loading: false,
      first_time: true,
      ft: true,
      message: "",
      entity_sub_type_loading: false,
      entity_type: "",
      entity_sub_type: "",
      entity_name: "",
      date_of_incorporation: "",
      trade_name: "",
      place_of_incorporation: "",
      qualifying_benefit_entity: "",
      decree_law_number: "",
      decree_law_number_file: "",
      entity_first_name: "",
      entity_last_name: "",
      date_of_commencement_of_business: "",
      imagePath: "",
      entityType: "1",
      error: {
        name: "",
        entity_first_name: "",
        entity_last_name: "",
        entity_type: "",
        entity_sub_type: "",
        decree_law_number_file: "",
      },
    };
  },
  watch: {
    // isShow(newVal, oldVal) {
    //   this.isShow = newVal;
    // },

    async entity_type(newValue, oldValue) {
      //this.isShow= newValue
      this.error.entity_type = "";
      this.entity_sub_type_loading = true;
      if (newValue !== oldValue) {
        await getEntityItem("subType", "/entityType", newValue);
        if (!this.subType.data.length) {
          this.entity_sub_type = "";
        } else {
          let check = this.subType.data.find(
            (item) => item?.id == this.entity_sub_type
          );
          if (!check) {
            this.entity_sub_type = "";
          }
        }
      }
      this.entity_sub_type_loading = false;
    },

    async place_of_incorporation(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.countryStore.getStateList(newValue);
        if (this.first_time) {
          this.first_time = false;
          return;
        }
        this.place_of_establishment = "";
        this.license_authority = "";
      }
    },
    async place_of_establishment(newValue) {
      await this.getLicenseAuthority(newValue, this.license_authority_type);
    },
    async license_authority_type(newValue) {
      await this.getLicenseAuthority(this.place_of_establishment, newValue);
    },
    async license_authority(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        await getEntityItem("businessActivity", "/businessActivity", newValue);
      }
    },
    entity_sub_type(newVal) {
      this.error.entity_sub_type = "";
      if (newVal == 26) {
        if (this.place_of_incorporation == 237) {
          this.place_of_incorporation = "";
        }
      }
    },
    entity_name(newValue) {
      this.error.name = "";
    },
    entity_first_name(newValue) {
      this.error.entity_first_name = "";
    },
    entity_last_name(newValue) {
      this.error.entity_last_name = "";
    },
    date_of_incorporation(newValue) {
      this.error.date_of_incorporation = "";
    },
    trade_name(newValue) {
      this.error.trade_name = "";
    },

    place_of_incorporation(newValue) {
      this.error.place_of_incorporation = "";
    },
    qualifying_benefit_entity(newValue) {
      this.error.qualifying_benefit_entity = "";
    },
    decree_law_number(newValue) {
      this.error.decree_law_number = "";
    },
    decree_law_number_file(newValue) {
      this.error.decree_law_number_file = "";
    },
  },
  async created() {
    this.loading = true;
    const id = this.$route.params.id;
    if (!this.type.data.length) {
      await getEntityItems("type", "/entityType");
    }
    if (!this.countries.data.length) {
      await this.countryStore.getCountryList();
    }
    if (!this.financialYear.data.length) {
      await getEntityItems("financialYear", "/financialYear");
    }
    if (!this.licenseAuthorityType.data.length) {
      await getEntityItems("licenseAuthorityType", "/license_authority_type");
    }
    if (id) {
      await this.getSubId();
    }
    this.loading = false;
  },

  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      if (!this.entity_type) {
        this.error.entity_type = "Entity type is required";
        return;
      }
      if (!this.entity_sub_type) {
        this.error.entity_sub_type = "Entity sub type is required";
        return;
      }
      if (
        !this.entity_name &&
        (this.entity_type == 1 || this.entity_type == 3)
      ) {
        this.error.name = "Entity name is required";
        return;
      }
      if (!this.entity_first_name && this.entity_type == 2) {
        this.error.entity_first_name = "Entity name is required";
        return;
      }
      if (!this.entity_last_name && this.entity_type == 2) {
        this.error.entity_last_name = "Entity name is required";
        return;
      }
      if (
        !this.date_of_incorporation &&
        (this.entity_type == 1 || this.entity_type == 3)
      ) {
        this.error.date_of_incorporation = "Date is required";
        return;
      }
      if (!this.trade_name) {
        this.error.trade_name = "Trade name is required";
        return;
      }

      if (
        !this.place_of_incorporation &&
        (this.entity_type == 1 || this.entity_type == 3)
      ) {
        this.error.place_of_incorporation = "Country is required";
        return;
      }
      if (!this.qualifying_benefit_entity && this.entity_type == 1) {
        this.error.qualifying_benefit_entity = "Field is required";
        return;
      }
      if (
        !this.decree_law_number &&
        this.entity_type == 1 &&
        this.qualifying_benefit_entity === "Y"
      ) {
        this.error.decree_law_number = "Decree Law Number is required";
        return;
      }

      // if (!this.entity_last_name) {
      //   this.error.name = "Entity last name is required";
      //   return;
      // }

      const id = Number(this.$route.params?.id);

      const data = new FormData();
      data.append("entity_name", this.entity_name);
      data.append("entity_first_name", this.entity_first_name);
      data.append("entity_last_name", this.entity_last_name);
      data.append(
        "date_of_commencement_of_business",
        this.date_of_commencement_of_business
      );
      data.append("entity_type", this.entity_type);
      data.append("entity_sub_type", this.entity_sub_type);
      data.append("trade_name", this.trade_name);
      data.append("place_of_incorporation", this.place_of_incorporation);
      data.append("qualifying_benefit_entity", this.qualifying_benefit_entity);
      data.append("decree_law_number", this.decree_law_number);
      data.append("date_of_incorporation", this.date_of_incorporation);
      data.append("place_of_establishment", this.place_of_establishment);
      data.append("financial_period", this.financial_period);
      data.append("license_authority", this.license_authority);
      data.append("license_authority_type", this.license_authority_type);
      data.append("business_activity", this.business_activity);
      if (
        typeof this.decree_law_number_file === "object" ||
        !this.decree_law_number_file
      ) {
        data.append(
          "decree_law_number_file",
          this.decree_law_number_file || ""
        );
      }

      if (typeof this.decree_law_number_file === "object") {
        if (!checkFileSize(this.decree_law_number_file)) {
          this.error.decree_law_number_file =
            "File size should be less than 2MB";
          return;
        }
      }

      if (this.edit) {
        data.append("_method", "PUT");
        data.append("entity_id", id);
        await addEntity("/entity", data, this.setMessage, async () => {
          await getEntityItem("entity", "/entity", id);
          setTimeout(() => {
            this.$router.push("/entity/entity-details/" + id);
          }, 2000);
        });

        return;
      }
      await addEntity(
        "/entity",
        data,
        this.setMessage,
        async ({ entity_id: entityId }) => {
          await getEntityItem("entity", "/entity", entityId);
          const tabs = JSON.parse(localStorage.getItem("tabs"));
          localStorage.setItem(
            "tabs",
            JSON.stringify({ ...tabs, entity_id: entityId })
          );
          setTimeout(() => {
            this.$router.push(
              "/entity/add-entity/entity-details/license/" + entityId
            );
          }, 2000);
        }
      );
    },
    setMessage(message) {
      this.message = message;
    },
    handleFileChange(e, model) {
      this.error[model] = "";
    },
    async getLicenseAuthority(placeofEtablishment, license_authority_type) {
      if (placeofEtablishment && license_authority_type) {
        await getEntityItem(
          "licenseAuthority",
          "/license_authority_type/" + license_authority_type,
          placeofEtablishment
        );
      } else {
        this.licenseAuthority.data = [];
        return;
      }
      if (!this.businessActivity.data.length) {
        this.license_authority = "";
        this.business_activity = "";
        return;
      }
      const find = this.licenseAuthority.data.find(
        (b) => b.id == this.license_authority
      );

      if (!find) {
        this.license_authority = "";
        this.business_activity = "";
      }
    },
    async getSubId() {
      const id = this.$route.params.id;
      await getEntityItem("entity", "/entity", id);
      const Entity = this.entity?.data?.entity?.find(
        (e) => Number(e.id) === Number(id)
      );
      if (Entity?.place_of_establishment && Entity?.place_of_incorporation) {
        await this.countryStore.getStateList(Entity?.place_of_incorporation);
      }
      if (Entity) {
        this.edit = true;
        this.entity_type = Entity?.entity_type;
        this.entity_sub_type = Entity?.entity_sub_type;
        this.entity_name = getExactValue(Entity?.entity_name);
        this.date_of_incorporation = mdyToYMD(Entity?.date_of_incorporation);
        this.trade_name = getExactValue(Entity?.trade_name);
        this.place_of_incorporation = getExactValue(
          Entity?.place_of_incorporation
        );
        this.qualifying_benefit_entity = Entity?.qualifying_benefit_entity;
        this.decree_law_number = Entity?.decree_law_number;
        this.decree_law_number_file = Entity?.decree_law_number_file;
        this.entity_first_name = getExactValue(Entity?.entity_first_name);

        this.entity_last_name = getExactValue(Entity?.entity_last_name);

        this.date_of_commencement_of_business =
          Entity?.date_of_commencement_of_business;
        this.imagePath = Entity?.decreelaw_imagepath;
      }
    },
    setSubType() {
      this.entity_sub_type = this.entity_sub_type_search;
      const searchEl = this.$refs.Vueselect;
      if (searchEl) {
        searchEl.blur();
      }
    },
  },
};
</script>

<style scoped>
.save-next {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #fafbff;
  width: 196px;
  background: #0f75bc;
  height: 55px;
}
.cancel {
  background: #ffffff;
  border: 1px solid #747171;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #747171;
  height: 55px;
  width: 196px;
}
.radio-container {
  margin-top: 7px;
  height: 56px;
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  gap: 11px;
}
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.radio-container .label {
  margin-bottom: 18px;
}
.label.qualifying-benefit {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>

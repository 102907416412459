<template>
  <div class="d-flex align-items-center justify-content-between my-2 px-4">
    <p class="price-desc mb-0 w-75">Entity count</p>

    <div class="w-25 d-flex align-items-center justify-content-end">
      <button class="btn-count mr-2 p-2" @click="handleCount('-')">
        <b>-</b>
      </button>
      <input
        type="text"
        class="custom-input mr-2"
        :value="count"
        @input="(e) => setCount(e.target.value)"
        @blur="handleBlur"
        @keypress="checkInputField"
      />
      <button class="btn-count p-2" @click="handleCount('+')">
        <b>+</b>
      </button>
    </div>
  </div>
</template>

<script setup>
import { insertChar } from "../../../helpers/stringUtils";

const props = defineProps({
  count: {
    type: String,
    default: ""
  },
  setCount: {
    type: Function,
    default: () => {}
  }
});
const handleCount = (type) => {
  if (type === "-") {
    if (Number(props.count) === 1) {
      return;
    }
    props.setCount(Number(props.count) - 1);
  } else if (type === "+") {
    props.setCount(Number(props.count) + 1);
  }
};
const checkInputField = (e) => {
  let value = "";
  const index = e.target.selectionStart;
  const prevValue = e.target.value;
  const currentKey = String.fromCharCode(e.keyCode);
  value = insertChar(prevValue, currentKey, index);
  if (!/^[1-9]\d*$/.test(value)) {
    e.preventDefault();
  }
};
const handleBlur = (e) => {
  const value = Number(e.target.value);
  if (!value) {
    props.setCount(1);
  }
};
</script>

<style scoped>
.price-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #382628;
}
.btn-count {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #382628;
  background: #eee;
  border-radius: 20px;
}
.custom-input {
  text-align: center !important;
  border: none;
  border-bottom: 1px solid#000000;
  padding-bottom: 1px;
  padding-left: 4px;
  height: 100%;
  width: 60px;
}
.custom-input:focus {
  outline: 0;
  border-bottom: 1px solid#0f75bc;
}
</style>

<template>
  <div class="w-100">
    <CustomModalContainer
      :open="open"
      :set-open="setOpen"
      :on-close="autoSave"
      :on-open="setOriginalValueOnOpen"
    >
      <CustomClickableInput
        v-for="(item, index) in dividendsAndOtherProfitData"
        :key="item.model"
        v-model="data.dividend_income[item.model]"
        :model="item.model"
        :name="item.name"
        :sub="item.sub"
        :bg-color="!index || !(index % 2) ? 'gray' : 'white'"
        :input-props="{
          onfocus: () =>
            setOriginalValues(item.model, data.dividend_income[item.model]),
        }"
      />
      <div
        class="w-100 table-body d-flex align-items-center justify-content-between"
      >
        <h5 class="mb-0 body-title">{{ "2) Other Income" }}</h5>
        <h5 class="body-title mb-0" @click="setOpen(true)">
          <b>{{ NumberFormatter(dividendOtherIncome) }}</b>
        </h5>
      </div>
      <CustomClickableInput
        v-for="(item, index) in dividenOtherIncome"
        :key="item.model"
        v-model="data.dividend_income[item.model]"
        :model="item.model"
        :name="item.name"
        :sub="item.sub"
        :bg-color="!index || !(index % 2) ? 'gray' : 'white'"
        :have-pop-up="false"
        :input-props="{
          onfocus: () =>
            setOriginalValues(item.model, data.dividend_income[item.model]),
        }"
      />
    </CustomModalContainer>

    <div
      class="w-100 table-body gray d-flex align-items-center justify-content-between"
    >
      <h5 class="mb-0 body-title">Dividend Income</h5>
      <h5 class="body-title mb-0 cursor-pointer" @click="setOpen(true)">
        <b>{{ NumberFormatter(dividendIncome) }}</b>
      </h5>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "vue";
import { CustomClickableInput, CustomModalContainer } from "../../../../common";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";
import { nonIFRSDividenIncomeData } from "./data";
import { useNoneIFRSProfitLoss } from "../../../../../store/NonIFRS/profitLoss.store";
import { storeToRefs } from "pinia";
import { autoSaveNoneIFRSPL } from "../common/autoSave";
import { useRoute, useRouter } from "vue-router";
import { useStatusStore } from "../../../../../store/statusStore";

const props = defineProps({
  setSavingStatus: {
    type: Function,
    default: () => {},
  },
});
const statusStore = useStatusStore();
const { status } = storeToRefs(statusStore);
const route = useRoute();
const router = useRouter();
const open = ref(false);
const setOpen = (value) => {
  if (status.value || route.query.business === "master") {
    return;
  }
  if (window.location.pathname.includes("view")) {
    return;
  }
  open.value = value;
};
const firstValues = reactive({
  dividends_and_other_profit_distributions_received: "",
  any_other_income_participating_interest_specified_article_23: "",
  income_foreign_permanent_establishment_that_meet_condition_article_24: "",
});
const dividendsAndOtherProfitData = ref(
  nonIFRSDividenIncomeData.dividends_and_other_profit_distributions_received
);
const dividenOtherIncome = ref(nonIFRSDividenIncomeData.other_income);
const nonIfrsPlStore = useNoneIFRSProfitLoss();
const { data, dividendOtherIncome, dividenIncome, groupData } =
  storeToRefs(nonIfrsPlStore);
const setOriginalValues = (key, value) => {
  firstValues[key] = value;
};
const setOriginalValueOnOpen = () => {
  for (let key in firstValues) {
    firstValues[key] = data.value.dividend_income[key];
  }
};
const autoSave = async () => {
  let save = false;
  for (let key in firstValues) {
    if (firstValues[key] != data.value.dividend_income[key]) {
      save = true;
      break;
    }
  }
  if (save) {
    await autoSaveNoneIFRSPL(props.setSavingStatus, route, router);
  }
};
const dividendIncome = computed(() => {
  if (route.query.business === "master") {
    return groupData.value.dividend_income;
  }
  return dividenIncome.value(route);
});
</script>

<style scoped>
.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}
.table-body.sub {
  padding-left: 46px !important;
}
.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}
.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}
.input-custom {
  border: none;
}
.input-custom:focus {
  outline: none;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
</style>

<template>
    <Loader v-if="loading" />
    <div class="w-100" v-else>
        <ShowStatus :modal="editStatus" :set-modal="setStatus" />

        <CustomTable
          :Data="data"
          :Headers="headers"
          :actions="actions"
          :have-index="true"
        />
      </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable.vue";
import { useEntityStore } from "@/store/entity";
import { storeToRefs } from "pinia";
import { generate } from "randomized-string";
import { ref } from "vue";
import tableEdit from "../../../../assets/svg/table-edit.svg";
import tableDelete from "../../../../assets/svg/trash.svg";
import { useRouter, useRoute } from "vue-router";
import { deleteEntity } from "../../../../store/entityActions/delete";
import { dateMDY } from "../../../../helpers/date";
import ShowStatus from "./ShowStatus.vue";
export default {
    name: "BusinessActivityDetails",
    components: { CustomTable, ShowStatus },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const headers = ref([
            "Industry",
            "Main Group",
            "Sub Group",
            "Activity",
            "Activity Code"
        ]);

        const editStatus = ref(false);
        const setStatus = (status) => {
            editStatus.value = status;
        };

        const actions = ref([
            {
                id: generate(),
                icon: tableEdit,
                onClick: (id) => {
                    if (entity.value?.data?.entity[0]?.file_return_status === "Filed") {
                        setStatus(true);
                        return;
                    }
                    router.push(
                        `/entity/add-entity/entity-details/business/${route.params.id}/${id}`
                    );
                },
            },
            {
                id: generate(),
                icon: tableDelete,
                onClick: async (id) => {
                    if (confirm("Are you sure you want to delete this business activity details ?")) {
                        await deleteEntity(
                            id,
                            "/entityBusinessActivity",
                            () => { },
                            () => {
                                location.reload();
                            }
                        );
                    }
                },
            },
        ]);
        const entityStore = useEntityStore();
        const { entity } = storeToRefs(entityStore);
        return {
            headers,
            actions,
            entity,
            //businessActivity,
            editStatus,
            setStatus,
        };
    },
    data() {
        return {
            data: [],
            loading: true,
        };
    },

    created() {
        this.data = this.entity?.data?.business?.map((e) => ({
            id: e?.id,
            industry: e?.industry?.name,
            main_group: e?.main_group?.main_group,
            sub_group: e?.sub_group?.sub_group,
            activity: e?.activity?.activity,
            activity_code: e?.activity_code
        }));
        this.loading = false;
    },
}
</script>
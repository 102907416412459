import axios from "axios";
import { getBaseUrl } from "./fetchWrapper";

const fetchFileWrapper = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + sessionStorage.getItem("Token")
  }
});
fetchFileWrapper.interceptors.request.use(function (config) {
  // Do something before request is sent
  const authKey = sessionStorage.getItem("Token");
  config.headers.Authorization = "Bearer " + authKey;
  return config;
});

export default fetchFileWrapper;

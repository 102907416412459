<template>
  <SideNavContainer side-nav-type="financialData">
    <EntityChecker :check-mandatory-fields="true">
      <IFRSCheckerContainer :have-loader="false" :check-status="true">
        <div
          class="title-container d-flex align-items-center justify-content-between"
        >
          <h3 class="title">
            Financial Data <br />
            <p class="subtitle mb-0">
              {{ name }}
            </p>
          </h3>
          <LiabilityDateHandler />
        </div>
        <BalanceSheetProfitLossBreadCrumb
          :entity-name="name"
          type="balance-sheet"
          tab="liabilities"
        />
        <div class="row">
          <div class="col-lg-12">
            <LiabilityBusiness :ifrs="data" />
          </div>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center justify-content-between mt-3 mb-3"
        >
          <div class="d-flex align-items-center">
            <button class="tab-button mr-2 my-2" @click="changeTab('assets')">
              <img src="@/assets/svg/briefcase.svg" alt="" class="mr-1 mb-1" />
              Assets
            </button>
            <button
              class="tab-button active my-2"
              @click="changeTab('liabilities')"
            >
              <img src="@/assets/svg/pie-chart.svg" alt="" class="mr-1 mb-1" />
              Liabilities
            </button>
          </div>
          <div class="message">
            <p :class="type == 'info' ? `text-info` : `text-warning`">
              {{ message }}
            </p>
          </div>
          <DeleteBusinessBtnContainer class="d-flex align-items-center">
            <button
              v-if="business.data?.length"
              class="save-button my-2 mr-3"
              @click="createBusiness"
            >
              <img src="@/assets/svg/plus-square-light.svg" alt="" />
              Add New
            </button>
            <button
              v-if="$route.query.business !== 'master' && !status"
              :disabled="
                data === 'IFRS'
                  ? loading
                  : data === 'Non-IFRS'
                  ? nonIFRSLoading
                  : false
              "
              class="save-button my-2"
              @click="handleSave()"
            >
              Save Data
            </button>
          </DeleteBusinessBtnContainer>
        </div>
        <LoaderContainer :loading="statusLoading || financialTypeLoading">
          <LoaderContainer v-if="data === 'IFRS'" :loading="liabilitiesLoading">
            <ShareHolderEquity :view="!!status" />
            <ShareCapital :view="!!status" />
            <TreasuryShares :view="!!status" />
            <OtherReserves :view="!!status" />
            <RetainedProfits :view="!!status" />
            <TotalShareHoldersEquity :view="!!status" />
            <Borrowings :view="!!status" />
            <Provisions :view="!!status" />
            <DeferredTax :view="!!status" />
            <TotalNonCurrentLiabilities :view="!!status" />
            <CurrentLiabilityTrade :view="!!status" />
            <TotalCurrentLiabilities :view="!!status" />
          </LoaderContainer>
          <NonIfrsLiabilities v-if="data === 'Non-IFRS'" />
        </LoaderContainer>
        <!-- <CurrentLiabilityBorrowings />
        <CurrentLiabilityProvision /> -->
      </IFRSCheckerContainer>
    </EntityChecker>
  </SideNavContainer>
</template>

<script setup>
import ShareHolderEquity from "./ShareHolderAmount.vue";
import ShareCapital from "./ShareCapital.vue";
import TreasuryShares from "./TreasuryShares.vue";
import OtherReserves from "./OtherReserves.vue";
import RetainedProfits from "./RetainedProfits.vue";
import TotalShareHoldersEquity from "./TotalShareHoldersEquity.vue";
import Borrowings from "./Borrowings.vue";
import Provisions from "./Provisions.vue";
import DeferredTax from "./DeferredTax.vue";
import TotalNonCurrentLiabilities from "./TotalNonCurrentLiabilities.vue";
import CurrentLiabilityTrade from "./Current/CurrentLiabilityTrade.vue";
import TotalCurrentLiabilities from "./Current/TotalCurrentLiabilities.vue";
import { SideNavContainer } from "../../../../layout";
import LiabilityDateHandler from "../../DateHandler/BalanceSheet/LiabilityDateHandler.vue";
import LiabilityBusiness from "../../BusinessHandler/BalanceSheet/LiabilityBusiness.vue";
import { useEntityStore } from "../../../../../store/entity";
import { onMounted, ref, watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { getEntityItem } from "../../../../../store/entityActions/get";
import { useRoute, useRouter } from "vue-router";
import { useBusinessStore } from "../../../../../store/buisness.store";
import { useLiabilityDataStore } from "../../../../../store/liabities.store";
import { BalanceSheetProfitLossBreadCrumb } from "../../../../BreadCrumbs";

import { useStatusStore } from "../../../../../store/statusStore";
import { useFinancialDataStore } from "../../../../../store/financial.store";
import { Toast } from "../../../../../helpers/toast";
import DeleteBusinessBtnContainer from "../../BusinessHandler/common/DeleteBusinessBtnContainer.vue";
import IFRSCheckerContainer from "../../IFRSCheckerContainer.vue";
import { useIFRSStore } from "../../../../../store/NonIFRS/IFRS.store";
import NonIfrsLiabilities from "../../NON-IFRS/BalanceSheet/Liabilities/Liabilities.vue";
import { useNoneIFRSBalanceSheet } from "../../../../../store/NonIFRS/balancesheet.store";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";
import { LoaderContainer } from "../../../../common";
import EntityChecker from "../../../AddEntity/EntityChecker.vue";

const router = useRouter();
const entityStore = useEntityStore();
const { entity } = storeToRefs(entityStore);
const route = useRoute();
const message = ref("");
const businessStore = useBusinessStore();
const statusStore = useStatusStore();
const ifrsStore = useIFRSStore();
const { data, loading: financialTypeLoading } = storeToRefs(ifrsStore);
const nonIfrsBalanceSheet = useNoneIFRSBalanceSheet();
const { totalAssets, totalShareHolderEquityLiabilities } =
  storeToRefs(nonIfrsBalanceSheet);

const {
  status,
  loading: statusLoading,
  error: statusError,
} = storeToRefs(statusStore);
const { business } = storeToRefs(businessStore);
const liabilityDataStore = useLiabilityDataStore();
const financialDataStore = useFinancialDataStore();
const { load: nonIFRSLoading } = storeToRefs(nonIfrsBalanceSheet);

const type = ref("");
const { totalNonCurrentAsset, totalCurrentAssets } =
  storeToRefs(financialDataStore);
const { totalShEquityAndLiabilities, loading: liabilitiesLoading } =
  storeToRefs(liabilityDataStore);
const name = computed(() => {
  if (!entity.value.data) return "";
  const Entity = entity.value.data?.entity[0];
  if (Entity?.entity_type == 2) {
    return `${Entity?.entity_first_name} ${Entity?.entity_last_name}`;
  }
  return Entity?.entity_name;
});
onMounted(async () => {
  if (!entity.value.data) {
    const id = route.params.id;
    await getEntityItem("entity", "/entity", id);
  }
  liabilitiesLoading.value = true;
});
const handleSave = async () => {
  if (data.value === "IFRS") {
    const toast = new Toast("Checking data please wait....");
    await financialDataStore.getAsset(
      route.params.id,
      route.query.financial_year,
      route.query.financial_period,
      route.query.business
    );
    setTimeout(async () => {
      const totalAsset =
        Number(totalNonCurrentAsset.value.total_non_current_assets) +
        Number(totalCurrentAssets.value.total_current_assets);
      const totalLiability = Number(totalShEquityAndLiabilities.value);

      if (totalAsset === totalLiability) {
        await liabilityDataStore.save(route, toast, (id) => {
          router.push(
            `/entity/financial-data/${route.params.id}?financial_period=${
              route.query.financial_period
            }&financial_year=${route.query.financial_year}${
              id
                ? "&business=" + id
                : route.query.business
                ? "&business=" + route.query.business
                : ""
            }`
          );
        });
      } else {
        toast.makeErrorToast(
          `Balance Sheet is not matched because  Asset value ${NumberFormatter(
            totalAsset
          )} and Liabilities value  ${NumberFormatter(
            totalLiability
          )} is not equal`
        );
      }
    }, 4000);
  } else if (data.value === "Non-IFRS") {
    const toast = new Toast("Checking data please wait....");
    setTimeout(async () => {
      if (totalAssets.value !== totalShareHolderEquityLiabilities.value) {
        toast.makeErrorToast(
          `Balance Sheet is not matched because  Asset value ${NumberFormatter(
            totalAssets.value
          )} and Liabilities value  ${NumberFormatter(
            totalShareHolderEquityLiabilities.value
          )} is not equal`
        );
        return;
      }
      await nonIfrsBalanceSheet.saveNonIFRSBalanceSheet(
        route.params.id,
        route.query.financial_year,
        route.query.financial_period,
        route.query.business,
        ({ entity_business_id: id }) => {
          router.push(
            `/entity/financial-data/${route.params.id}?financial_period=${
              route.query.financial_period
            }&financial_year=${route.query.financial_year}${
              id
                ? "&business=" + id
                : route.query.business
                ? "&business=" + route.query.business
                : ""
            }`
          );
        },
        toast
      );
    }, 2000);
  }
};
const createBusiness = async () => {
  await businessStore.addBusiness(
    {
      entity_id: route.params.id,
      financial_period: route.query.financial_period,
      financial_year: route.query.financial_year,
    },
    (mess) => {
      message.value = mess;
    },
    async ({ id }) => {
      await businessStore.getBusiness(
        route.params.id,
        route.query.financial_year,
        route.query.financial_period
      );
      const { pathname } = window.location;
      await router.push(
        `${pathname}?financial_year=${
          route.query.financial_year
        }&financial_period=${route.query.financial_period}${
          id ? "&business=" + id : ""
        }`
      );
    }
  );
};
const changeTab = (tab) => {
  router.push(
    `/entity/financial-data/balance-sheet/${tab}/${
      route.params.id
    }?financial_period=${route.query.financial_period}&financial_year=${
      route.query.financial_year
    }${route.query.business ? "&business=" + route.query.business : ""}`
  );
};
</script>

<style scoped>
.tab-button {
  min-width: 140px;
  min-height: 44px;
  border-radius: 4px;
  background: #eff3f3;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}

.tab-button.active {
  background: linear-gradient(90deg, #101432 0%, #00a7a7 100%);
  color: #fafbff;
}

.save-button {
  min-width: 110px;
  min-height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.save-button:disabled {
  opacity: 0.5;
}
.title-container {
  padding: 25px 10px 16px 10px;
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.055em;
  color: #000000;
}

.subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
  font-style: normal;
}
</style>

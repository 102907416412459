export const removeDuplicates = (originalArray, prop) => {
  var newArray = [];
  var lookupObject = {};

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
};

export const removeKeyFromObject = (object, keys) => {
  let newObject = {};
  Object.keys(object).forEach((key) => {
    let find = keys.find((item) => item === key);
    if (!find) {
      newObject[key] = object[key];
    }
  });
  return newObject;
};

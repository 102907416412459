<template>
  <div class="w-100 mt-10">
    <h4 class="upload-title">
      Upload
      {{ props.type === "balanceSheet" ? "Balance Sheet" : "Profit and Loss" }}
      File
    </h4>
    <div class="d-flex mt-3 w-100 align-items-center">
      <div
        class="file-name-container p-3 d-flex align-items-center justify-content-between"
      >
        <p class="file-name mr-4 mb-0">{{ fileName }}</p>
        <input type="file" @change="setFile" id="upload-file" hidden />
      </div>
      <button
        for="upload"
        class="choose-file d-flex justify-content-center align-items-center"
        @click="selectFile"
      >
        Choose file
      </button>
    </div>
    <p class="text-info mt-4">{{ message }}</p>
    <div class="w-100 mt-4">
      <button class="btn-submit" @click="uploadFile">Submit</button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useFinancialDataStore } from "../../../../store/financial.store";
import { useProfitAndLossStore } from "../../../../store/ProfitAdLoss";
import { useIFRSStore } from "../../../../store/NonIFRS/IFRS.store";
import { storeToRefs } from "pinia";
import { useNoneIFRSBalanceSheet } from "../../../../store/NonIFRS/balancesheet.store";
import { useNoneIFRSProfitLoss } from "../../../../store/NonIFRS/profitLoss.store";

const props = defineProps({ type: String });
const ifrsStore = useIFRSStore();
const { data, loading: financialTypeLoading } = storeToRefs(ifrsStore);
const File = ref(null);
const fileName = ref("");
const message = ref("");
const route = useRoute();
const router = useRouter();
const financialStore = useFinancialDataStore();
const profitLossStore = useProfitAndLossStore();
const noneIFRSBalanceSheet = useNoneIFRSBalanceSheet();
const noneIFRSProfitLoss = useNoneIFRSProfitLoss();
const setFile = (e) => {
  File.value = e.target.files[0];
  fileName.value = File.value?.name;
};
const selectFile = () => {
  document.getElementById("upload-file").click();
};

const uploadFile = async () => {
  if (data.value === "Non-IFRS") {
    if (props.type === "balanceSheet") {
      await noneIFRSBalanceSheet.uploadBalanceSheetFile(
        route,
        File.value,
        async (business) => {
          router.push(
            `/entity/financial-data/balance-sheet/view/${route.params.id}?financial_period=${route.query.financial_period}&financial_year=${route.query.financial_year}&business=${business}`
          );
        }
      );
    } else if (props.type === "profitAndLoss") {
      await noneIFRSProfitLoss.uploadProfitAndLossFile(
        route,
        File.value,
        async (business) => {
          router.push(
            `/entity/financial-data/profit-loss/view/${route.params.id}?financial_period=${route.query.financial_period}&financial_year=${route.query.financial_year}&business=${business}`
          );
        }
      );
    }
    return;
  }

  if (props.type === "balanceSheet") {
    await financialStore.uploadBalanceSheetFile(
      route.params.id,
      File.value,
      route.query.financial_year,
      route.query.financial_period,
      route.query.business,
      (mess) => {
        message.value = mess;
      },
      (business) => {
        router.push(
          `/entity/financial-data/balance-sheet/view/${route.params.id}?financial_period=${route.query.financial_period}&financial_year=${route.query.financial_year}&business=${business}`
        );
      }
    );
  } else if (props.type === "profitAndLoss") {
    await profitLossStore.uploadProfitAndLossFile(
      route.params.id,
      File.value,
      route.query.financial_year,
      route.query.financial_period,
      route.query.business,
      (mess) => {
        message.value = mess;
      },
      (business) => {
        router.push(
          `/entity/financial-data/profit-loss/view/${route.params.id}?financial_period=${route.query.financial_period}&financial_year=${route.query.financial_year}&business=${business}`
        );
      }
    );
  }
};
</script>

<style scoped>
.upload-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.file-name-container {
  min-width: 50%;
  height: 44px;
  background: #b0e1eb;
  overflow: hidden;
}
.file-name {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #747171;
}
.choose-file {
  width: 121px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
  cursor: pointer;
}
.btn-submit {
  width: 82px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
</style>

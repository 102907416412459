import { defineStore } from "pinia";
import fetchWrapper from "../helpers/fetchWrapper";
import { generate } from "randomized-string";
import { chapter10Data } from "../helpers/chapter-10-data";
import { Toast } from "../helpers/toast";

export const useChapter10Store = defineStore("chapter10", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      loading: false,
      error: false,
      savingMessage: {
        message: "",
        type: "info"
      },
      getters: {},
      masterData: [],
      data: chapter10Data
    };
  },
  getters: {
    totalVariationOffArticle35(state) {
      let totalVartiation = 0;
      state.data.article_35_annexure.forEach((item) => {
        totalVartiation = totalVartiation + item.variation;
      });
      return totalVartiation;
    },

    totalVariationOffArticle36(state) {
      let totalVartiation = 0;
      state.data.article_36_annexure.forEach((item) => {
        totalVartiation = totalVartiation + item.variation;
      });
      return totalVartiation;
    }
  },
  actions: {
    async getChapter10(route) {
      try {
        this.loading = true;
        const entity_id = route.params.id;
        const { financial_year, financial_period, current_business } =
          route.query;
        const res = await fetchWrapper.get(
          `/chapter10?entity_id=${entity_id}&financial_year=${financial_year}&financial_period=${financial_period}${
            current_business !== "master"
              ? "&entity_business_id=" + current_business
              : ""
          }`
        );
        if (res.data?.result === 1) {
          if (route.query.current_business === "master") {
            this.masterData = res.data.data;
            this.error = false;
            this.loading = false;
            return;
          } else {
            this.data = res.data.data;
            this.error = false;
            this.loading = false;
          }
        } else {
          this.error = res.data?.error
            ? res.data?.error?.join(",")
            : res.data?.message;
          this.loading = false;
        }
      } catch (err) {
        console.log(err);
        this.error = err?.response?.data?.message || "";
        this.loading = false;
      }
    },
    async saveChapter10(route, callBack = () => {}) {
      let toast;
      try {
        const { financial_year, financial_period, current_business } =
          route.query;
        if (current_business === "master") {
          return;
        }
        toast = new Toast("Preparing data...");
        let payload = {};
        for (let x in this.data) {
          if (x !== "articles") {
            payload[x] = this.data[x];
          }
        }
        const entity_id = route.params.id;
        payload["entity_id"] = entity_id;
        payload["financial_year"] = financial_year;
        payload["financial_period"] = financial_period;
        payload["entity_business_id"] = current_business;
        payload["article_35_total_variation"] = this.totalVariationOffArticle35;
        payload["article_36_total_variation"] = this.totalVariationOffArticle36;
        toast.makeInfoToast("Saving...");
        const res = await fetchWrapper.post(`chapter10`, payload);
        if (res.data.result === 1) {
          toast.makeSuccessToast("Saved successfully...");
          callBack();
        } else {
          toast.makeWarningToast(res.data.message);
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    },
    async setChapter10ToNull(route, callBack = () => {}) {
      let toast;
      try {
        const { financial_year, financial_period, current_business } =
          route.query;
        if (current_business === "master") {
          return;
        }
        toast = new Toast("Preparing data...");
        let payload = chapter10Data;
        const entity_id = route.params.id;

        payload["entity_id"] = entity_id;
        payload["financial_year"] = financial_year;
        payload["financial_period"] = financial_period;
        payload["entity_business_id"] = current_business;
        payload["article_35_total_variation"] = this.totalVariationOffArticle35;
        payload["article_36_total_variation"] = this.totalVariationOffArticle36;
        toast.makeInfoToast("Saving...");
        const res = await fetchWrapper.post(`chapter10`, payload);
        if (res.data.result === 1) {
          this.data = chapter10Data;
          toast.makeSuccessToast("Saved successfully");
          callBack();
        } else {
          toast.makeWarningToast(res.data.message);
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    }
  }
});

import { defineStore } from "pinia";
import fetchWrapper from "../helpers/fetchWrapper";
import { generate } from "randomized-string";
import { Toast } from "../helpers/toast";

export const useChapter13Store = defineStore("chapter13", {
  state: () => {
    return {
      loading: true,
      error: false,
      savingMessage: {
        message: "",
        type: "info"
      },
      masterData: [],
      data: {
        foreign_tax_credit: [
          {
            id: generate(),
            country_name: "",
            country_code: "",
            tax_identification_no_of_tax_payer: "",
            total_income: null,
            total_tax_credit_claimed: null
          }
        ],
        total_foreign_tax_credit: null,
        withholding_tax_credit: [
          {
            id: generate(),
            trn_code_of_deductor: "",
            nature_of_income: "",
            name_of_deductor: "",
            total_income: "",
            total_tax_deducted: "",
            percent_of_tax_deducted: null,
            date_of_payment: "",
            percent_of_tax_deduction: null
          }
        ],
        total_withholding_tax_credit: null
      }
    };
  },
  getters: {
    totalIncome(state) {
      if (!state.data.foreign_tax_credit.length) {
        return 0;
      }
      let total = state.data.foreign_tax_credit.reduce(
        (a, b) => a + Number(b?.total_tax_credit_claimed),
        0
      );
      return total;
    },
    totalWithholdingTaxCredit(state) {
      if (!state.data.withholding_tax_credit.length) {
        return 0;
      }
      let total = state.data.withholding_tax_credit.reduce(
        (a, b) => a + Number(b?.total_tax_deducted),
        0
      );
      return total;
    }
  },
  actions: {
    async getChapter13(route) {
      try {
        this.loading = true;
        const entity_id = route.params.id;

        const { financial_year, financial_period, current_business } =
          route.query;
        const res = await fetchWrapper.get(
          `/chapter13?entity_id=${entity_id}&financial_year=${financial_year}&financial_period=${financial_period}${
            current_business !== "master"
              ? "&entity_business_id=" + current_business
              : ""
          }`
        );
        const Data = res?.data?.data;
        if (res.data?.result === 1) {
          if (route.query.current_business === "master") {
            this.masterData = res.data?.data;
            this.error = false;
            this.loading = false;
            return;
          }
          if (!Data.foreign_tax_credit?.length) {
            this.data = {
              ...res.data.data,
              foreign_tax_credit: [
                {
                  id: generate(),
                  country_name: "",
                  country_code: "",
                  tax_identification_no_of_tax_payer: "",
                  total_income: null,
                  total_tax_credit_claimed: null
                }
              ]
            };
            if (!Data.withholding_tax_credit?.length) {
              this.data = {
                ...this.data,
                withholding_tax_credit: [
                  {
                    id: generate(),
                    trn_code_of_deductor: "",
                    nature_of_income: "",
                    name_of_deductor: "",
                    total_income: "",
                    total_tax_deducted: "",
                    percent_of_tax_deducted: null,
                    date_of_payment: "",
                    percent_of_tax_deduction: null
                  }
                ]
              };
              this.error = false;
              this.loading = false;
              return;
            }
            this.error = false;
            this.loading = false;
            return;
          }
          if (!Data.withholding_tax_credit?.length) {
            this.data = {
              ...res?.data.data,
              withholding_tax_credit: [
                {
                  id: generate(),
                  trn_code_of_deductor: "",
                  nature_of_income: "",
                  name_of_deductor: "",
                  total_income: "",
                  total_tax_deducted: "",
                  percent_of_tax_deducted: null,
                  date_of_payment: "",
                  percent_of_tax_deduction: null
                }
              ]
            };
            this.error = false;
            this.loading = false;
            return;
          }
          this.data = res.data?.data;
          this.error = false;
          this.loading = false;
        } else {
          this.error = res?.data.message;
          this.loading = false;
        }
      } catch (err) {
        console.log(err);
        this.error = err?.response?.data?.message || "";
        this.loading = false;
      }
    },
    async saveChapter13(route, callBack = () => {}) {
      let toast;
      try {
        toast = new Toast("Preparing data...");
        const entity_id = route.params.id;

        const { financial_year, financial_period, current_business } =
          route.query;
        if (current_business === "master") {
          return;
        }
        toast.makeInfoToast("Saving...");
        const res = await fetchWrapper.post(`chapter13`, {
          entity_id,
          entity_business_id: current_business,
          financial_year,
          financial_period,
          ...this.data,
          total_foreign_tax_credit: this.totalIncome,
          total_withholding_tax_credit: this.totalWithholdingTaxCredit
        });
        if (res.data.result === 1) {
          toast.makeSuccessToast("Saved successfully...");
          callBack();
        } else {
          toast.makeWarningToast(res.data.message);
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    }
  }
});

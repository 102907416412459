<template>
  <div class="w-100 d-flex align-items-center">
    <p class="date-text w-75 mb-0 mr-4">
      <b>Search between transaction dates</b>
    </p>
    <VueDatePicker
      v-model="date"
      :max-date="new Date()"
      range
      :enable-time-picker="false"
      :width="350"
      placeholder="Select Date (From - To)"
    />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
const props = defineProps({
  setDate: {
    type: Function,
    default: () => {},
  },
});
const date = ref([]);
watch(date, (newVal) => {
  props.setDate(newVal);
});
</script>

<style scoped>
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
</style>

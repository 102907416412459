<template>
  <AlertModal
    :open="open"
    message="Hello User! We've just rolled out an update!"
    sub-message=" Please Log In to explore Updates !"
    :on-confirm="logOut"
    :has-cancel="false"
    button-name="logout"
    :modal-props="{ persistent: true }"
  />
  <slot />
</template>

<script setup>
import { useVersionControllStore } from "../../store/version";
import { onMounted, ref, onUnmounted } from "vue";
import { AlertModal } from "../common";
import { generate } from "randomized-string";

const versionControllStore = useVersionControllStore();
const interval = ref("");
const open = ref(false);
const openCloseModal = (value) => {
  open.value = value;
};
const logOut = async () => {
  await localStorage.clear();
  await sessionStorage.clear();
  await caches.keys().then(function (names) {
    for (let name of names) {
      caches.delete(name);
    }
  });
  location.href = `/account/signin?r=${generate(24)}`;
};
onMounted(async () => {
  interval.value = setInterval(async () => {
    await versionControllStore.getCurrentVerion(openCloseModal);
  }, 2 * 60 * 1000);
  await versionControllStore.setCurrentVerion();
});
onUnmounted(() => {
  clearInterval(interval.value);
});
</script>

<style scoped></style>

/* eslint-disable camelcase */
import { defineStore } from "pinia";
import fetchWrapper from "../helpers/fetchWrapper";

export const useEntityDashboardStore = defineStore("entityDashboard", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      loading: true,
      error: false,
      data: {
        revenue_comparison: {
          2023: 0,
          2022: 3,
          comparison_percent: "10%"
        },
        revenue_profit: {
          current_year_revenue: 0,
          current_year_profit: 3
        },
        taxable_income_chart: {
          2018: 35,
          2019: 53,
          2020: -25,
          2021: 83,
          2022: 56,
          2023: 67
        },
        tax_paid_analysis: {
          2018: 35,
          2019: 53,
          2020: -25,
          2021: 83,
          2022: 56,
          2023: 67
        },
        np_ratio: {
          2018: 35,
          2019: 53,
          2020: -25,
          2021: 83,
          2022: 56,
          2023: 67
        },
        asset_liabilities_comparison: {
          mainland_client: 0,
          freezone_client: 0
        },
        marquee_text: []
      }
    };
  },
  actions: {
    async getDasboardItems(id) {
      try {
        this.loading = true;
        const res = await fetchWrapper.get(
          `/entityDashboard${id ? "/" + id : ""}`
        );
        if (res.data.result == 1) {
          this.data = {
            ...res.data?.data,
            entity_id: String(res.data?.data?.entity_id)
          };
          this.error = false;
          this.loading = false;
        } else {
          this.error = res.data?.error
            ? res.data?.error?.join(",")
            : res.data?.message;
          this.loading = false;
        }
      } catch (err) {
        console.log(err);
        this.error = err?.response?.data?.message || "";
        this.loading = false;
      }
    }
  }
});

<template>
  <SignInSignUpContainer>
    <LoginComponent />
  </SignInSignUpContainer>
</template>

<script>
import { SignInSignUpContainer, LoginComponent } from "@/components/account";

export default {
  name: "LoginApp",
  components: { SignInSignUpContainer, LoginComponent },
};
</script>

<style lang="scss" scoped></style>

import { removeDuplicates } from "./removeDuplicates";
import { matchString } from "./searchText";

/**
 * @param index- text data to search
 * @param area- where to search  example [name,age,...]
 * @param data - data to search
 * @param  to remove redudant object it is property string usually that uniquely identifies the object(_id,id)
 * @returns searched value of array object
 */
export const searchObject = (
  index,
  area,
  data,
  remove = "id",
  strict = false
) => {
  let result = [];
  area.forEach((a) => {
    data.forEach((d) => {
      for (let i in d) {
        if (i == a) {
          matchString(index, d[i], strict) ? result.push(d) : () => {};
        }
      }
    });
  });
  return removeDuplicates(result, remove);
};

/* eslint-disable no-prototype-builtins */
import { defineStore } from "pinia";
import fetchWrapper from "../helpers/fetchWrapper";
import { Toast } from "../helpers/toast";
import { removeKeyFromObject } from "../helpers/removeDuplicates";
import { basicDataStructures } from "../helpers/storeStructure";
import { getErrorMessage, getWarningMessage } from "../helpers/errorHandler";

export const useGeneralInfoStore = defineStore("generalInfo", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      loading: true,
      error: false,
      data: false,
      auditor_details: {
        date_of_furnishing: "",
        auditor_name: "",
        membership_number: "",
        proprietorship_name: "",
        firm_registration_number: "",
        member_eid: "",
        email: "",
        mobile: "",
        liable_to_furnish_report_under_tansfer_pricing_audit: "",
      },
      general_information: {
        entity_name: "",
        type: "",
        license_authority_type: "",
        date_of_ct_registration: "",
        financial_period: "",
        trn_number: "",
        office_address: "",
        building_no: "",
        street: null,
        area: "sd",
        emirates: "",
        po_box_no: null,
        bank_name: "",
        branch_name: "",
        swift_code: "",
        branch_address: "",
        account_type: "",
        account_holder_name: "",
        account_no: "",
        iban_no: null,
      },
      options: {
        method_of_accounting: "",
        liable_to_audit: "",
        liable_to_maintain_tp_documentation: "",
        company_registered_in_freezone: "",
        qualified_for_freezone: "",
        selected_non_qfzp: "",
        selected_non_qfzp_year: "",
        selected_non_qfzp_year_count: "",
        financial_type: "",
      },
      status: {
        method_of_accounting: "",
        liable_to_audit: "",
        liable_to_maintain_tp_documentation: "",
      },
      financialYear: basicDataStructures,
    };
  },
  actions: {
    async getGeneralInformation(entity_id, financial_year) {
      try {
        if (entity_id && financial_year) {
          this.loading = true;
          const res = await fetchWrapper.get(
            `/generalInformation?entity_id=${entity_id}&financial_year=${financial_year}`
          );
          if (res.data.result == 1) {
            this.data = true;
            this.auditor_details = res.data?.data?.auditor_details
              ? res.data?.data?.auditor_details
              : {
                  date_of_furnishing: "",
                  auditor_name: "",
                  membership_number: "",
                  proprietorship_name: "",
                  firm_registration_number: "",
                  member_eid: "",
                  email: "",
                  mobile: "",
                  liable_to_furnish_report_under_tansfer_pricing_audit: "",
                };
            this.status = res.data?.status;
            for (let x in res.data.data) {
              if (this.general_information.hasOwnProperty(x)) {
                if (x == "emirates") {
                  this.general_information[x] = res.data?.data[x]
                    ? Number(res.data?.data[x])
                    : "";
                } else this.general_information[x] = res.data?.data[x];
              } else if (this.options.hasOwnProperty(x)) {
                this.options[x] = res.data?.data[x];
              }
            }
            if (
              Number(this.options["selected_non_qfzp_year_count"]) &&
              Number(this.options["selected_non_qfzp_year_count"]) <= 5
            ) {
              this.options.qualified_for_freezone = "N";
            }

            this.error = false;
            this.loading = false;
          } else {
            this.error = res.data?.error
              ? res.data?.error?.join(",")
              : res.data?.message;
            this.loading = false;
          }
        }
      } catch (err) {
        console.log(err);
        this.error = err?.response?.data?.message || "";
        this.loading = false;
      }
    },
    async saveAuditorDetails(entity_id, financial_year, callBack = () => {}) {
      const toast = new Toast("Saving please wait...");
      try {
        const res = await fetchWrapper.post("/generalInformation", {
          entity_id,
          financial_year,
          liable_to_audit: "Y",
          auditor_details: {
            ...this.auditor_details,
          },
        });
        if (res.data.result == 1) {
          toast.makeSuccessToast(res.data?.message);
          callBack(res.data);
        } else {
          toast.makeErrorToast(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message
          );
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    },
    async saveOptions(
      entity_id,
      financial_year,
      setMessage = () => {},
      callBack = () => {},
      toast = new Toast("Saving please wait...")
    ) {
      try {
        const res = await fetchWrapper.post("/generalInformation", {
          ...removeKeyFromObject(this.options, [
            "selected_non_qfzp_year_count",
          ]),
          street: this.general_information.street,
          area: this.general_information.area,
          building_no: this.general_information.building_no,
          entity_id,
          financial_year,
        });
        if (res.data.result == 1) {
          toast.makeSuccessToast(res.data?.message);
          callBack(res.data);
        } else {
          toast.makeErrorToast(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message
          );
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    },
    async getPreviousFinacialYear(financial_period) {
      try {
        this.financialYear.loading = true;
        const res = await fetchWrapper.get(
          `/previousFinancialYear/${financial_period}`
        );
        if (res.data.result == 1) {
          this.financialYear.data = await res.data.data;
          this.financialYear.error = await false;
          this.financialYear.loading = false;
        } else {
          this.financialYear.data = [];
          this.financialYear.error = getWarningMessage(res);
          this.financialYear.loading = false;
        }
      } catch (err) {
        console.log(err);
        this.financialYear.error = getErrorMessage(err);
        this.financialYear.loading = false;
      }
    },
  },
});

<template>
  <SideNavContainer title="Add Entity">
    <EntityChecker>
      <AddEntityTab active="contactDetails" />
      <LoaderContainer :loading="loading">
        <StatusChecker>
          <form @submit="handleSubmit">
            <div class="row wrapper px-2">
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomSelectInput
                  v-model="place_of_establishment_in_uae"
                  placeholder="Place of Establishment in UAE"
                  :options="state.data"
                  item-title="name"
                  item-value="id"
                  :error-message="error.place_of_establishment_in_uae"
                  label="Place of Establishment in UAE *"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <h4 class="label">
                  Nationality <span class="text-danger">*</span>
                </h4>
                <v-autocomplete
                  v-model="nationality"
                  :items="countries.data"
                  label="Nationality"
                  :error-message="error.nationality"
                  item-title="name"
                  item-value="id"
                  bg-color="#DEEDED"
                >
                </v-autocomplete>
                <p v-if="error.nationality" class="text-danger">
                  {{ error.nationality }}
                </p>
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomSelectInput
                  v-model="id_doc_type"
                  :options="documentType.data"
                  item-title="name"
                  item-value="id"
                  :error-message="error.id_doc_type"
                  label="ID Document *"
                  placeholder="ID Document"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomFileUploadInput
                  v-model="id_doc_file"
                  label="Upload ID Document"
                  :file-name="id_doc_file"
                  supported-file-text="*Supported file type - .png , .jpeg, .pdf. * Supported file size - 0 - 2 MB"
                  accept="image/png, image/jpeg, application/pdf"
                  :link="id_doc_file ? `${imagePath}/${id_doc_file}` : ''"
                  :error-message="error.id_doc_file"
                  :input-props="{
                    onchange: (e) =>
                      handleFileChange(e, 'registration_certification'),
                    accept: 'image/png, image/jpeg, application/pdf',
                  }"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="id_doc_no"
                  type="text"
                  label="ID Document Number *"
                  placeholder="ID Document Number"
                  :error-message="error.id_doc_no"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <div class="w-100">
                  <h4 class="label">
                    Mobile Number with Country Code
                    <span class="text-danger">*</span>
                  </h4>
                  <input
                    v-model="mobile_no"
                    :error-message="error.mobile_no"
                    type="text"
                    :class="`custom-input ${
                      error?.mobile_no ? 'error' : ''
                    } w-100`"
                    placeholder="Mobile Number"
                    @keypress="checkMobileNumber($event)"
                  />
                  <p v-if="error.mobile_no" class="text-danger">
                    {{ error.mobile_no }}
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="phone_no"
                  label="Landline Number with Country Code"
                  placeholder="Landline Number"
                  :error-message="error.phone_no"
                  @keypress="checkMobileNumber($event, true)"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="email"
                  type="email"
                  label="Email Address *"
                  placeholder="Email Address *"
                  :error-message="error.email"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="registered_address"
                  label="Registered Address in Country of Incorporation *"
                  placeholder="Registered Address"
                  :error-message="error.registered_address"
                />
              </div>
              <div class="col-lg-12 mt-4">
                <h4 class="owner-list">Business Address in UAE</h4>
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="business_address"
                  label="Business Address *"
                  :error-message="error.business_address"
                  placeholder="Business Address"
                />
              </div>

              <div class="col-lg-4 col-md-6 mt-4">
                <CustomSelectInput
                  v-model="state_id"
                  placeholder="Emirate"
                  :options="state.data"
                  item-title="name"
                  item-value="id"
                  label="Emirate *"
                  :error-message="error.state_id"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="po_box_no"
                  label="PO BOX *"
                  :error-message="error.po_box_no"
                  placeholder="PO BOX"
                  @keypress="onlyAcceptDigits"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomRadioButton
                  v-model="is_registered"
                  :error-message="error.is_registered"
                  label="Is the primary contact *"
                  :options="[
                    { name: 'Yes', value: 'Y' },
                    { name: 'No', value: 'N' },
                  ]"
                />
                <p v-if="error.is_registered" class="text-danger">
                  {{ error.is_registered }}
                </p>
              </div>
              <p class="text-info">{{ message }}</p>
              <div class="d-flex align-items-center mt-10 mb-3">
                <button class="save-next mr-3" type="submit">
                  Save & Next
                </button>
                <button
                  class="cancel"
                  type="button"
                  @click="
                    $router.push(`/entity/master-data/${$route.params.id}`)
                  "
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </StatusChecker>
      </LoaderContainer>
    </EntityChecker>
  </SideNavContainer>
</template>

<script>
import { SideNavContainer } from "../../../../layout";
import {
  AddEntityTab,
  CustomRadioButton,
  CustomTextInput,
  LoaderContainer,
} from "../../../../common";
import CustomSelectInput from "@/components/common/CustomSelectInput.vue";
import { useCountryStore } from "../../../../../store/country";
import { storeToRefs } from "pinia";
import { useEntityStore } from "../../../../../store/entity";
import {
  getEntityItems,
  getEntityItem,
} from "../../../../../store/entityActions/get";
import { addEntity } from "../../../../../store/entityActions/post";
import EntityChecker from "../../EntityChecker.vue";
import Loader from "@/components/common/Loader.vue";
import { useGeneralInfoStore } from "../../../../../store/generalInformation";
import { checkFileSize } from "../../../../../helpers/file";
import CustomFileUploadInput from "../../../../common/CustomFileUploadInput.vue";
import StatusChecker from "../../StatusChecker.vue";
import { onlyAcceptAlphabets } from "../../../../../helpers/validation";
import { emailRegex } from "../../../../../constants/regex-format";

export default {
  name: "ContactDetailsForm",
  components: {
    SideNavContainer,
    AddEntityTab,
    CustomSelectInput,
    CustomTextInput,
    EntityChecker,
    Loader,
    CustomRadioButton,
    CustomFileUploadInput,
    StatusChecker,
    LoaderContainer,
  },
  setup() {
    const countryStore = useCountryStore();
    const entityStore = useEntityStore();
    const { documentType, contactDetails } = storeToRefs(entityStore);
    const { countries, states: state } = storeToRefs(countryStore);
    const generalInfoStore = useGeneralInfoStore();
    return {
      countries,
      countryStore,
      documentType,
      entityStore,
      state,
      contactDetails,
      generalInfoStore,
      onlyAcceptAlphabets,
    };
  },
  data() {
    return {
      place_of_establishment_in_uae: "",
      nationality: "",
      id_doc_type: "",
      id_doc_file: "",
      id_doc_no: "",
      mobile_no: "",
      phone_no: "",
      email: "",
      registered_address: "",
      business_address: "",
      state_id: "",
      po_box_no: "",
      is_registered: "",
      message: "",
      edit: false,
      loading: true,
      imagePath: "",
      error: {
        place_of_establishment_in_uae: "",
        nationality: "",
        id_doc_type: "",
        id_doc_no: "",
        mobile_no: "",
        email: "",
        registered_address: "",
        business_address: "",
        state_id: "",
        po_box_no: "",
        is_registered: "",
        id_doc_file: "",
      },
    };
  },
  watch: {
    place_of_establishment_in_uae() {
      this.error.place_of_establishment_in_uae = "";
    },
    mobile_no() {
      this.error.mobile_no = "";
    },
    email() {
      this.error.email = "";
    },
    phone_no() {
      this.error.phone_no = "";
    },

    nationality() {
      this.error.nationality = "";
    },
    id_doc_file() {
      this.error.id_doc_file = "";
    },
    id_doc_no() {
      this.error.id_doc_no = "";
    },
    registered_address() {
      this.error.registered_address = "";
    },
    business_address() {
      this.error.business_address = "";
    },
    state_id() {
      this.error.state_id = "";
    },
    po_box_no() {
      this.error.po_box_no = "";
    },
    id_doc_type() {
      this.error.id_doc_type = "";
    },
    is_registered() {
      this.error.is_registered = "";
    },
  },
  async created() {
    const id = this.$route.params.sub_id;
    if (!this.countries.data?.length) {
      await this.countryStore.getCountryList();
    }
    if (!this.documentType.data?.length) {
      await getEntityItems("documentType", "/documentType");
    }
    if (!this.state.data?.length) {
      const country = this.countries.data.find(
        (c) => c.name === "United Arab Emirates"
      );
      if (country) {
        await this.countryStore.getStateList(country?.id);
      }
    }
    if (id) {
      await this.getSubId();
    }
    this.loading = false;
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      if (!this.place_of_establishment_in_uae) {
        this.error.place_of_establishment_in_uae = " Field is required";
        return;
      }
      if (!this.nationality) {
        this.error.nationality = " Field is required";
        return;
      }
      if (!this.id_doc_type) {
        this.error.id_doc_type = " Field is required";
        return;
      }

      if (!this.id_doc_no) {
        this.error.id_doc_no = " Field is required";
        return;
      }
      if (!this.mobile_no) {
        this.error.mobile_no = " Field is required";
        return;
      }

      if (!this.email) {
        this.error.email = " Field is required";
        return;
      }

      if (!this.registered_address) {
        this.error.registered_address = " Field is required";
        return;
      }
      if (!this.business_address) {
        this.error.business_address = " Field is required";
        return;
      }
      if (!this.state_id) {
        this.error.state_id = " Field is required";
        return;
      }
      if (!this.po_box_no) {
        this.error.po_box_no = " Field is required";
        return;
      }
      if (!this.is_registered) {
        this.error.is_registered = " Field is required";
        return;
      }
      if (
        !(
          String(this.mobile_no).length <= 13 &&
          String(this.mobile_no).length >= 8
        ) &&
        !!this.mobile_no
      ) {
        this.error.mobile_no =
          "Invalid Mobile Number, Mobile number should be between 8 to 13 digits ";
        return;
      }
      if (
        !(
          String(this.phone_no).length <= 13 &&
          String(this.phone_no).length >= 8
        ) &&
        !!this.phone_no
      ) {
        this.error.phone_no =
          "Invalid Landline Number, Landline number should be between 8 to 13 digits ";
        return;
      }
      if (this.email && !emailRegex.test(this.email)) {
        this.error.email = "Invalid Email";
        return;
      }
      const formData = new FormData();
      const financial_year = localStorage.getItem("financial_year");
      formData.append("entity_id", Number(this.$route.params.id));
      formData.append(
        "place_of_establishment_in_uae",
        this.place_of_establishment_in_uae
      );
      formData.append(" nationality", this.nationality ? this.nationality : 0);
      formData.append("id_doc_type", this.id_doc_type);
      formData.append("id_doc_no", this.id_doc_no);
      formData.append("mobile_no", this.mobile_no); // mobile_no: '',
      formData.append("phone_no", this.phone_no); // phone_no: '',
      formData.append("email", this.email); // email: '',
      formData.append("registered_address", this.registered_address); // registered_address: '',
      formData.append("business_address", this.business_address); // business_address: '',
      formData.append("state_id", this.state_id); // : '',
      formData.append("po_box_no", this.po_box_no); //   : '',
      formData.append("is_registered", this.is_registered); //   : '',
      if (typeof this.id_doc_file === "object" || !this.id_doc_file) {
        formData.append("id_doc_file", this.id_doc_file || "");
      }
      if (typeof this.id_doc_file === "object") {
        if (!checkFileSize(this.id_doc_file)) {
          this.error.id_doc_file = "File size should be less than 2MB";
          return;
        }
      }

      if (this.edit) {
        const id = this.$route.params.sub_id;
        formData.append("entity_contact_details_id", id);
        formData.append("_method", "PUT");
        await addEntity(
          "/entityContactDetails",
          formData,
          this.setMessage,
          async () => {
            await getEntityItem(
              "contactDetails",
              "/entityContactDetails",
              this.$route.params.id
            );
            await this.generalInfoStore.getGeneralInformation(
              this.$route.params.id,
              financial_year
            );
            setTimeout(() => {
              this.$router.push(
                "/entity/contact-details/" + this.$route.params.id
              );
            }, 2000);
          },
          true
        );
        return;
      }

      await addEntity(
        "/entityContactDetails",
        formData,
        this.setMessage,
        async ({ entity_id: entityId }) => {
          await getEntityItem(
            "contactDetails",
            "/entityContactDetails",
            entityId
          );
          await this.generalInfoStore.getGeneralInformation(
            this.$route.params.id,
            financial_year
          );

          setTimeout(() => {
            this.$router.push("/entity/add-entity/banking-details/" + entityId);
          }, 2000);
        },
        true
      );
      // $router.push()
    },
    setMessage(message) {
      this.message = message;
    },
    handleFileChange() {
      this.error.id_doc_file = "";
    },
    async getSubId() {
      const id = this.$route.params.sub_id;
      await getEntityItem(
        "contactDetails",
        "/entityContactDetails",
        this.$route.params.id
      );
      const contactDetails = this.contactDetails?.data?.find(
        (e) => Number(e.id) === Number(id)
      );
      ///const p = this.state.data.filter( (e) => Number(e.id) === Number(contactDetails?.place_of_establishment_in_uae || ""));
      //const d = this.documentType.data.filter( (e) => Number(e.id) === Number(contactDetails?.id_doc_type || ""));
      //console.log(contactDetails);
      if (contactDetails) {
        this.edit = true;
        this.entity_type = contactDetails?.entity_type || "";
        this.place_of_establishment_in_uae =
          Number(contactDetails?.place_of_establishment_in_uae) || "";
        this.nationality = contactDetails?.nationality || "";
        this.id_doc_type = Number(contactDetails?.id_doc_type) || "";
        this.id_doc_file = contactDetails?.id_doc_file || "";
        this.id_doc_no = contactDetails?.id_doc_no || "";
        this.mobile_no = contactDetails?.mobile_no || "";
        this.phone_no = contactDetails?.phone_no || "";
        this.email = contactDetails?.email || "";
        this.registered_address = contactDetails?.registered_address || "";
        this.business_address = contactDetails?.business_address || "";
        this.state_id = contactDetails?.state_id?.id || "";
        this.po_box_no = contactDetails?.po_box_no || "";
        this.is_registered = contactDetails?.is_registered || "";
        this.imagePath = contactDetails?.imagepath || "";
      }
    },
    checkMobileNumber(e, containMinus = false) {
      let reg = containMinus ? /^[0-9 +-]+$/ : /^[0-9+]+$/;
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (reg.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
  },
};
</script>

<style scoped>
.save-next {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #fafbff;
  width: 196px;
  background: #0f75bc;
  height: 55px;
}
.cancel {
  background: #ffffff;
  border: 1px solid #747171;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #747171;
  height: 55px;
  width: 196px;
}
.owner-list {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.radio-container {
  height: 56px;
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  gap: 11px;
}
.yes {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.custom-input {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  height: 48px;
  padding: 13px 10px;
}
.custom-input.error {
  border: 1px solid red;
}
.custom-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
  text-align: start;
}
.custom-input:focus {
  outline: 0;
}
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

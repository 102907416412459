<template>
  <SideNavContainer title="Entity">
    <EntityChecker>
      <AddEntityTab active="contactDetails" />
      <UploadContainer
        name="contact"
        :open="open"
        type="contact_details"
        :set-open="setModal"
        :call-back="reload"
      />
      <div class="row">
        <div class="col-lg-12 mt-4">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <h4 class="title">Contact Details</h4>
            <div class="d-flex align-items-center">
              <div
                class="d-flex align-items-center gray-button mr-2"
                @click="
                  $router.push(
                    `/entity/add-entity/contact-details/${$route.params.id}`
                  )
                "
              >
                <img
                  src="@/assets/svg/plus-square-light.svg"
                  class="mr-2"
                  alt=""
                />
                <p class="edit-text mb-0">Add Contact</p>
              </div>
              <div
                class="mr-2 gray-button cursor-pointer edit-text"
                @click="() => setModal(true)"
              >
                &#128206; Upload Contact Details
              </div>
            </div>
          </div>
          <ContactDetails />
        </div>
      </div>
    </EntityChecker>
  </SideNavContainer>
</template>

<script setup>
import { SideNavContainer } from "@/components/layout";
import ContactDetails from "../../../MasterData/List/ContactDetails.vue";
import { AddEntityTab } from "@/components/common";
import EntityChecker from "../../EntityChecker.vue";
import UploadContainer from "../../uploadEntity/UploadContainer.vue";
import { ref } from "vue";
const open = ref(false);
const setModal = (value) => {
  open.value = value;
};
const reload = () => {
  window.location.reload();
};
</script>

<style scoped>
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.edit-text {
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #000000;
}
.gray-button {
  background-color: #deeded;
  padding: 10px;
}
</style>

<script setup>
import { ref, watch, onMounted } from "vue";
import SideNavContainer from "../../layout/SideNavContainer.vue";
import Article35 from "./chapter10/Article35.vue";
import Article36 from "./chapter10/Article36.vue";
import { useRoute } from "vue-router";
import { useBusinessStore } from "../../../store/buisness.store";
import { storeToRefs } from "pinia";
import Business from "../Business/Business.vue";
import { useChapter10Store } from "../../../store/chapter10";
import Chapter10MasterData from "./chapter10/Chapter10MasterData.vue";
import { ChaptersBreadCrumb } from "../../BreadCrumbs";
import LoaderContainer from "../../common/LoaderContainer.vue";
import { useStatusStore } from "../../../store/statusStore";
import { CustomRadioButton } from "../../common";

const name = ref("");
const route = useRoute();
const businessStore = useBusinessStore();
const { business } = storeToRefs(businessStore);
const chapter10Store = useChapter10Store();
const statusStore = useStatusStore();
const {
  status,
  loading: statusLoading,
  error: statusError,
} = storeToRefs(statusStore);
const { data, savingMessage, loading, error } = storeToRefs(chapter10Store);
const setBusinessName = (newValue, current_business) => {
  const findBusiness = newValue?.data?.find((b) => b.id == current_business);
  name.value = findBusiness?.name ? findBusiness?.name : "";
};
watch(
  business,
  (newValue) => {
    if (newValue?.data.length)
      setBusinessName(newValue, route.query.current_business);
  },
  { deep: true }
);
watch(
  () => route.query,
  (newValue) => {
    setBusinessName(business.value, newValue.current_business);
  }
);
onMounted(async () => {
  await chapter10Store.getChapter10(route);
  if (route.query.financial_year && route.query.financial_period) {
    await statusStore.getStatus(
      route.params.id,
      route.query.financial_year,
      route.query.financial_period
    );
  }
});

const closeOpenSwitch35 = async () => {
  if (data.value.transaction_under_article_35_as_per_alp) {
    if (confirm("ARE YOU SURE TO DISABLE THIS IT WILL DELETE ALL INPUT DATA")) {
      data.value.transaction_under_article_35_as_per_alp = "N";
      await chapter10Store.saveChapter10(route, async () => {
        await chapter10Store.getChapter10(route);
      });
    }
    return;
  }
};
const closeOpenSwitch36 = async () => {
  if (data.value.transaction_uder_article_36_as_per_market_value) {
    if (confirm("ARE YOU SURE TO DISABLE THIS IT WILL DELETE ALL INPUT DATA")) {
      data.value.transaction_uder_article_36_as_per_market_value = "N";
      await chapter10Store.saveChapter10(route, async () => {
        await chapter10Store.getChapter10(route);
      });
    }
    return;
  }
};
</script>

<template>
  <SideNavContainer
    sideNavType="financialData"
    title="Related Party Transactions under Chapter-10"
    :subtitle="name"
  >
    <ChaptersBreadCrumb
      :entityName="name"
      chapter="chapter-10"
      chapterName="Chapter-10"
    />
    <Business Tab="chapter-10" />
    <LoaderContainer
      :loading="loading || statusLoading"
      :error="error || statusError"
    >
      <Chapter10MasterData v-if="$route.query.current_business === 'master'" />
      <div v-else class="w-100">
        <div class="d-flex justify-content-end align-items-center">
          <p :class="`text-${savingMessage.type} mr-10 mb-0`">
            {{ savingMessage.message }}
          </p>
          <button
            class="save-button mr-3"
            @click="chapter10Store.saveChapter10($route)"
          >
            Save
          </button>
        </div>
        <div
          class="item-container mt-3 w-100 px-1 d-flex align-items-center justify-content-between"
        >
          <div class="flex-2">
            <h4 class="title 2">Article 35</h4>
            <p class="sub-title">
              Whether any transaction with any Related party as defined under
              Article-35 of Decree Law is not transacted as per Arm Length Price
              ?
            </p>
          </div>
          <div class="d-flex flex-1 justify-content-end">
            <CustomRadioButton
              v-model="data.transaction_under_article_35_as_per_alp"
              :options="[
                {
                  name: 'Yes',
                  value: 'Y',
                },
                {
                  name: 'No',
                  value: 'N',
                  input: {
                    onclick: (e) => {
                      e.preventDefault();
                      closeOpenSwitch35();
                    },
                  },
                },
              ]"
            />
          </div>
        </div>
        <Article35
          v-if="data.transaction_under_article_35_as_per_alp === 'Y'"
        />
        <hr class="hr w-100 mt-2" />
        <div
          class="item-container w-100 px-1 d-flex align-items-center justify-content-between"
        >
          <div class="flex-2">
            <h4 class="title">Article 36</h4>
            <p class="sub-title">
              Whether any transaction with any Related party as defined under
              Article-36 of Decree Law is not transacted as per Arm Length Price
              ?
            </p>
          </div>
          <div class="flex-1 d-flex justify-content-end">
            <CustomRadioButton
              v-model="data.transaction_uder_article_36_as_per_market_value"
              :options="[
                { name: 'Yes', value: 'Y' },
                {
                  name: 'No',
                  value: 'N',
                  input: {
                    onclick: (e) => {
                      e.preventDefault();
                      closeOpenSwitch36();
                    },
                  },
                },
              ]"
            />
          </div>
        </div>
        <Article36
          v-if="data.transaction_uder_article_36_as_per_market_value === 'Y'"
        />
      </div>
    </LoaderContainer>
  </SideNavContainer>
</template>

<style scoped>
.item-container {
  min-height: 105px;
}
.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
.hr {
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.flex-2 {
  flex: 4;
}
.flex-1 {
  flex: 1;
}
</style>

/* eslint-disable camelcase */

import { defineStore } from "pinia";
import { basicDataStructures } from "../helpers/storeStructure";
import fetchWrapper from "../helpers/fetchWrapper";
import { setNotificationMessage } from "../helpers/date";
import { Toast } from "../helpers/toast";

export const useFilingPlannerStore = defineStore("filingPlanner", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      filingType: {
        ...basicDataStructures
      },
      filingPlanner: {
        ...basicDataStructures
      },
      notification: {
        ...basicDataStructures
      }
    };
  },
  actions: {
    async getFilingTypes() {
      try {
        this.filingType.loading = true;
        const res = await fetchWrapper.get("/filingType");
        if (res.data.result == 1) {
          this.filingType.data = res?.data.data;
          this.filingType.error = false;
          this.filingType.loading = false;
        } else {
          this.filingType.error = res.data?.error
            ? res.data?.error?.join(",")
            : res.data?.message;
          this.filingType.loading = false;
        }
      } catch (err) {
        console.log(err?.message);
        this.filingType.error = err?.response?.data?.message || "";
        this.filingType.loading = false;
      }
    },
    async getFilingPlanner() {
      try {
        this.filingPlanner.loading = true;
        const res = await fetchWrapper.get("/filingPlanner");
        if (res.data.result == 1) {
          this.filingPlanner.data = res?.data.data;
          this.filingPlanner.error = false;
          this.filingPlanner.loading = false;
        } else {
          this.filingPlanner.error = res.data?.error
            ? res.data?.error?.join(",")
            : res.data?.message;
          this.filingPlanner.loading = false;
        }
      } catch (err) {
        console.log(err?.message);
        this.filingPlanner.error = err?.response?.data?.message || "";
        this.filingPlanner.loading = false;
      }
    },
    async getNotification() {
      try {
        this.notification.loading = true;
        const res = await fetchWrapper.get("/filingPlannerNotification");
        if (res.data.result == 1) {
          let notification = res.data.data.map((item) => ({
            ...item,
            alert_message: setNotificationMessage(item.alert_message)
          }));
          this.notification.data = notification;
          this.notification.error = false;
          this.notification.loading = false;
        } else {
          this.notification.error = res.data?.error
            ? res.data?.error?.join(",")
            : res.data?.message;
          this.notification.loading = false;
        }
      } catch (err) {
        console.log(err?.message);
        this.notification.error = err?.response?.data?.message || "";
        this.notification.loading = false;
      }
    },
    async saveFilePlaned(entities, filing_date, callBack = () => {}) {
      const toast = new Toast("Saving...");
      try {
        const payload = {
          entities,
          filing_date
        };
        const res = await fetchWrapper.post("/filingPlanner", payload);
        if (res.data.result === 1) {
          toast.makeSuccessToast("Filed successfully");
          callBack();
        } else {
          toast.makeWarningToast(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message
          );
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    }
  }
});

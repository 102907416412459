<template>
  <CustomModalContainer
    :open="modal"
    :set-open="setModal"
    title="Can not file because of the error in the following fields"
  >
    <div class="w-100 table-responsive mt-4 mb-0">
      <table class="table custom-table mb-0 table-bordered table-striped">
        <tbody>
          <tr v-for="(field, index) in missingFields" :key="index">
            <td class="table-rows">
              <p class="data-text mb-0 text-danger">{{ field }}</p>
            </td>
          </tr>
          <tr v-if="!missingFields.length" :key="index">
            <td class="table-rows">
              <p class="data-text mb-0">
                Some field are missing please contact customer support
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-lg-12 mb-5">
        <button
          class="save-button p-3 mt-4 mr-3"
          @click="
            $router.push(`/entity/general-information/${$route.params.id}`)
          "
        >
          Go to general Information
        </button>
      </div>
    </div>
  </CustomModalContainer>
  <div v-if="coiStatus" class="boxes w-100">
    <p
      v-if="message.text"
      :class="`mb-0 info-text text-center my-2 text-${message.type}`"
    >
      {{ message.text }}
    </p>
    <div
      class="w-100 p-3 box d-flex align-items-center justify-content-between"
    >
      <h4 class="mb-0 title bold">Status</h4>
      <h4
        :class="`mb-0 title p-2 text-white px-5 ${
          coiStatus?.status ? 'bg-warning' : 'bg-info'
        }`"
      >
        {{ coiStatus?.status ? "Filed" : "Pending" }}
      </h4>
    </div>
    <div
      class="w-100 box p-3 d-flex align-items-center justify-content-between"
    >
      <button
        :class="`btn-submit w-100 ${expirationDuration > 0 ? '' : 'disabled'}`"
        @click="updateStatus"
      >
        {{ coiStatus?.status ? "Revise" : "File" }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useComputeIncomeTaxStore } from "../../../store/incometax.store";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useProfitAndLossStore } from "../../../store/ProfitAdLoss";
import { useStatusStore } from "../../../store/statusStore";
import { useGeneralInfoStore } from "../../../store/generalInformation";
import { CustomModalContainer } from "../../common";
import { useEntityStore } from "../../../store/entity";
import { getEntityItem } from "../../../store/entityActions/get";
import { getDurationInDays } from "../../../helpers/date";
const message = ref({
  type: "info",
  text: "",
});
const route = useRoute();
const statusStore = useStatusStore();
const expirationDuration = ref(0);
const entityStore = useEntityStore();
const { entity } = storeToRefs(entityStore);

const modal = ref(false);
const setModal = (value) => {
  modal.value = value;
};
const missingFields = ref([]);

const computeIncomeTaxStore = useComputeIncomeTaxStore();
const generalInfoStore = useGeneralInfoStore();
const { status } = storeToRefs(generalInfoStore);
const { coiStatus } = storeToRefs(computeIncomeTaxStore);
const profitAndLossStore = useProfitAndLossStore();
const { financial_year, financial_period } = storeToRefs(profitAndLossStore);
const updateStatus = async () => {
  const payload = {
    entity_id: route.params.id,
    financial_year: financial_year.value,
    financial_period: financial_period.value,
    status: String(coiStatus?.value?.status ? 0 : 1),
  };

  await statusStore.checkStatus(
    {
      entity_id: route.params.id,
      financial_year: financial_year.value,
    },
    async (data, toast) => {
      if (data?.is_qualified) {
        await statusStore.updateStatus(payload, toast, async () => {
          await computeIncomeTaxStore.getComputeIncomeTax(route.params.id);
          await getEntityItem("entity", "/entity", route.params.id);
        });
      } else {
        await generalInfoStore.getGeneralInformation(
          route.params.id,
          financial_year.value
        );
        const missing = [];
        for (let x in status.value) {
          if (x === "method_of_accounting" && !status.value[x]) {
            missing.push("Method of Accounting");
          } else if (x === "liable_to_audit" && !status.value[x]) {
            missing.push("liable to Audit?");
          } else if (
            x === "liable_to_maintain_tp_documentation" &&
            !status.value[x]
          ) {
            missing.push("Company liable to maintain TP Documentation?");
          }
        }
        missingFields.value = missing;
        setModal(true);
        //router.push(`/entity/general-information/${route.params.id}`);
      }
    }
  );
};
onMounted(async () => {
  if (!entity.value.data) {
    await getEntityItem("entity", "/entity", route.params.id);
  }
  const Entity = entity.value.data?.entity[0];
  expirationDuration.value = getDurationInDays(Entity?.subscription_end_date);
});
</script>

<style scoped>
.boxes {
  position: absolute;
  bottom: 0;
  z-index: 2;
  background: #deeded;
}
.btn-submit {
  width: 82px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
.btn-submit.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px; /*
identical to box height */
  letter-spacing: 0.055em; /* FontBlack */
  color: #382628;
}
.box {
  border-top: 2px solid #ffff;
}
.info-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px; /*
identical to box height */
  letter-spacing: 0.055em;
}
.info-container {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #deeded;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}

.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
}
.table-header {
  min-height: 39px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2.5px solid #fff;
}
.table-header.amount {
  min-width: 300px !important;
}
.table-rows {
  background: #ffffff;
  border: 2.5px solid #fff;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input {
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input:focus {
  outline: none;
}
.save-button {
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.delete-button {
  width: 50px;
  height: 44px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.gray {
  background: #deeded;
}
</style>

import { generate } from "randomized-string";
import { toast } from "vue3-toastify";

export class Toast {
  constructor(message, id = generate(),otherProps={}) {
    this.tostId = id;
    toast.info(message, {
      toastId: this.tostId,
      type: toast.TYPE.INFO,
      autoClose: false,
      ...otherProps
    });
  }
  makeInfoToast(message,otherProps={}) {
    toast.update(this.tostId, {
      type: toast.TYPE.INFO,
      render: message,
      autoClose: false,
      ...otherProps
    });
  }
  makeErrorToast(message,otherProps={}) {
    toast.update(this.tostId, {
      type: toast.TYPE.ERROR,
      render: message,
      autoClose: 5 * 1000,
      ...otherProps
    });
  }
  makeSuccessToast(message,otherProps={}) {
    toast.update(this.tostId, {
      type: toast.TYPE.SUCCESS,
      render: message,
      autoClose: 5 * 1000,
      ...otherProps
    });
  }
  makeWarningToast(message,otherProps={}) {
    toast.update(this.tostId, {
      type: toast.TYPE.WARNING,
      render: message,
      autoClose: 5 * 1000,
      ...otherProps
    });
  }
  closeToast() {
    toast.remove(this.tostId);
  }
}

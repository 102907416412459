<template>
  <div class="row">
    <div class="col-lg-3"></div>
    <div class="col-lg-6">
      <div
        class="w-100 d-flex flex-column align-items-center justify-content-center check-wrapper"
      >
        <h1 class="check-mark text-danger">
          <b> &#x2715; </b>
        </h1>
        <h2 class="success-message text-danger mt-4">
          <b> canceled </b>
        </h2>
        <h2 class="unsuccess text-center mt-4">
          <b>
            Your payment was un successful because you cancelled the payment
          </b>
        </h2>
        <button
          class="btn btn-danger w-100 p-3"
          @click="$router.push(`/subscription`)"
        >
          Return
        </button>
      </div>
    </div>
    <div class="col-lg-3"></div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { usePaymentStore } from "../../../store/payment.store";

const paymentStore = usePaymentStore();

onMounted(async () => {
  await paymentStore.getOrderDetails();
});
</script>

<style scoped>
.check-wrapper {
  min-height: 80vh;
}
.check-mark {
  font-size: 100px;
}
.success-message {
  font-size: 20px;
}
.unsuccess {
  font-size: 18px;
}
.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
}
.table-header {
  min-height: 39px;
  background: rgb(76, 175, 80);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2.5px solid #fff;
}
.table-rows {
  background: #ffffff;
  border: 2.5px solid #fff;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.cont {
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap/dist/css/bootstrap.css";
import "vuetify/styles";
import "@/assets/styles/main.css";
import "@/assets/styles/main.scss";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import VOtpInput from "vue3-otp-input";
import { createPinia } from "pinia";
import CountryFlag from "vue-country-flag-next";
import VueCountdown from "@chenfengyuan/vue-countdown";
import VueSpinners from "vue-spinners";
import VCalendar from "v-calendar";
import "v-calendar/style.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "vue3-toastify/dist/index.css";
import Vue3Toasity from "vue3-toastify";
import "@mdi/font/css/materialdesignicons.css";

const pinia = createPinia();

const vuetify = createVuetify({
  components,
  directives
});

const app = createApp(App);
app.component("CountryFlag", CountryFlag);
app.component("VOtpInput", VOtpInput);
app.component("VueDatePicker", VueDatePicker);
app.component(VueCountdown.name, VueCountdown);
app.use(pinia);
app.use(router);
app.use(vuetify);
app.use(VueSpinners);
app.use(VCalendar, {});
app.use(Vue3Toasity);


app.mount("#app");

<template>
  <LoaderContainer :loading="loading">
    <div class="w-100 mt-4">
      <EntityListBreadCrumb>
        <div class="d-flex w-100 flex-wrap justify-content-between">
          <div class="d-flex align-items-center">
            <h4 class="entity-type mb-0 mr-3">Entity Type</h4>
            <select
              :value="searchArea['type']"
              class="p-2 select mt-1"
              @input="(e) => addQuery('type', e.target.value)"
            >
              <option selected value="all">All</option>
              <option
                v-for="item in type.data"
                :key="item.id"
                :value="item.name"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <input
            type="text"
            placeholder="Search Entity"
            class="w-25 search-entity mt-1"
            :value="searchArea['search']"
            @input="(e) => addQuery('search', e.target.value)"
          />
          <DateFilter
            :on-date-change="addQuery"
            :fy="searchArea['financial_year']"
            :fp="searchArea['financial_period']"
          />
        </div>
      </EntityListBreadCrumb>
    </div>
    <CustomModalContainer :open="editModal.modal" :set-open="setWarningModal">
      <div class="w-100 disclaimer-container p-3">
        <h4 class="note-text mb-0">
          <b class="text-warning warning-icon mr-3 mb-0">&#x26A0;</b>
          <span class="mb-0">Note</span>
        </h4>
        <p class="mb-0 body-title">
          This entity cannot be edited because it has already been filed.
        </p>
      </div>
    </CustomModalContainer>
    <div v-if="!entities.data.length" class="w-100">
      <ChoosePlan
        v-if="!Number(profile.data?.parent_id)"
        :open="open"
        :close-modal="closeModal"
      />
      <div
        v-if="!Number(profile.data?.parent_id)"
        class="wrapper h-100 w-100 d-flex align-items-center justify-content-center"
      >
        <button
          class="button btn-add-entity d-flex justify-content-center align-items-center"
          @click="openModal()"
        >
          <img src="@/assets/svg/plus-square-light.svg" alt />
          Add Entity
        </button>
      </div>
      <div
        v-if="Number(profile.data?.parent_id)"
        class="wrapper h-100 w-100 d-flex align-items-center justify-content-center"
      >
        <img class="no-data-img" src="@/assets/svg/no-data.svg" alt="" />
        <h4 class="no-data ml-4">No Entity is Assigned !</h4>
      </div>
    </div>
    <CustomTable
      v-else
      :have-index="true"
      :Headers="headers"
      :Data="data"
      :actions="actions"
      :pointer="true"
    />
    <AlertModal
      :open="deleteModal"
      message="Are you sure you want to delete this Entity?"
      sub-message="Data will be deleted permanently"
      :on-confirm="DeleteEntity"
      :status="status"
      :status-message="statusMessage"
      :on-cancel="onCancel"
    />
  </LoaderContainer>
</template>

<script setup>
import CustomTable from "@/components/common/CustomTable.vue";
import { computed, onMounted, ref, watch } from "vue";
import { generate } from "randomized-string";
import tableEye from "@/assets/svg/table-eye.svg";
import tableEdit from "@/assets/svg/table-edit.svg";
import tableDelete from "@/assets/svg/trash.svg";
import { useEntityStore } from "../../../store/entity";
import AlertModal from "../../common/AlertModal.vue";

import {
  getEntityItems,
  getEntityAttribute,
  getEntityItem,
} from "../../../store/entityActions/get";
import { useCountryStore } from "../../../store/country";
import { storeToRefs } from "pinia";
import ChoosePlan from "../AddEntity/ChoosePlan.vue";

import { useRoute, useRouter } from "vue-router";
import { deleteEntity } from "../../../store/entityActions/delete";
import LoaderContainer from "../../common/LoaderContainer.vue";
import { EntityListBreadCrumb } from "../../BreadCrumbs";
import { useProfileStore } from "../../../store/profile";
import DateFilter from "./DateFilter.vue";
import { useStatusStore } from "../../../store/statusStore";
import { CustomModalContainer } from "../../common";
import { removeKeyFromObject } from "../../../helpers/removeDuplicates";

const route = useRoute();
const entityStore = useEntityStore();
const countryStore = useCountryStore();
const statusStore = useStatusStore();
const {
  status: editStatus,
  loading: statusLoading,
  error: statusError,
} = storeToRefs(statusStore);
const editModal = ref({
  entity_id: "",
  modal: false,
});
const setWarningModal = (value, id) => {
  editModal.value.modal = value;
  if (id) {
    editModal.value.entity_id = id;
  }
};

const { entities, entity, type } = storeToRefs(entityStore);
const profileStore = useProfileStore();
const { profile } = storeToRefs(profileStore);
const statusMessage = ref("");
const data = ref([]);
const open = ref(false);
const loading = ref(true);
const name = ref("");
const originalData = ref([]);
const searchArea = ref({
  type: "all",
});
const status = ref("info");
const router = useRouter();
const toBeDeletedId = ref("");
const deleteModal = ref(false);
const onCancel = () => {
  deleteModal.value = false;
};
const pushToMasterData = async (id) => {
  await localStorage.removeItem("financial_year");
  let query = ``;
  if (route.query.financial_period) {
    query = `?financial_period=${route.query.financial_period}`;
    if (route.query.financial_year) {
      query += `&financial_year=${route.query.financial_year}`;
    }
  } else {
    if (route.query.financial_year) {
      query = `?financial_year=${route.query.financial_year}`;
    }
  }
  router.push(`/entity/master-data/${id}${query}`);
};
const DeleteEntity = async () => {
  let id = toBeDeletedId.value;
  if (id) {
    await deleteEntity(
      id,
      "/entity",
      (message, type) => {
        statusMessage.value = message;
        status.value = type;
      },
      async () => {
        await getEntityItem("entity", "/entity", id);
        await getEntityItems("entities", "/entity");
        deleteModal.value = false;
        location.reload();
      }
    );
  }
};
const risePopUpToDelete = (id) => {
  toBeDeletedId.value = id;
  deleteModal.value = true;
};

const actions = computed(() => {
  const actionData = [
    {
      id: generate(),
      icon: tableEye,
      onClick: (id) => pushToMasterData(id),
    },
    {
      id: generate(),
      icon: tableEdit,
      onClick: (id) => {
        const find = data.value.find((item) => item.id == id);
        if (find?.file_return_status === "Filed") {
          setWarningModal(true, id);
          return;
        }
        router.push(`/entity/add-entity/entity-details/entity/${id}`);
      },
    },
  ];
  return Number(profile.value.data.parent_id)
    ? actionData
    : [
        ...actionData,
        {
          id: generate(),
          icon: tableDelete,
          onClick: async (id) => await risePopUpToDelete(id),
        },
      ];
});

const headers = ref([
  "Entity Name",
  "Entity Type",
  "Country",
  "License Number",
  "License Authority",
  "License Authority Type",
  "File Return Status",
]);

watch(
  searchArea,
  () => {
    searchEntity();
  },
  { deep: true }
);
const addQuery = (key, value) => {
  if (!value) {
    removeQuery(key);
    return;
  }
  router.push({ query: { ...route.query, [key]: value } });
};
const removeQuery = async (key) => {
  let query = {};
  for (let x in searchArea.value) {
    if (x !== key) {
      query[x] = searchArea.value[x];
    }
  }
  searchArea.value = query;
  await router.push({ query: { ...query } });
};
watch(
  () => route.query,
  async (newValue, prevValue) => {
    let searchObject = searchArea.value;

    if (Object.keys(newValue).length) {
      if (
        prevValue.financial_period !== newValue.financial_period ||
        prevValue.financial_year !== newValue.financial_year
      ) {
        await setEntityQuery(newValue);
      }
      for (let key in newValue) {
        if (
          key === "search" ||
          key === "type" ||
          key === "file_return_status" ||
          key === "license_authority_type" ||
          key === "financial_period" ||
          key === "financial_year"
        ) {
          searchObject[key] = newValue[key];
        }
      }
      searchArea.value = searchObject;
    } else {
      searchArea.value = {
        type: "all",
      };
      loading.value = true;
      await getEntityItems("entities", `/entity`);
      await setEntity();
    }
  },
  { deep: true }
);
// watch(
//   data,
//   (newValue) => {
//     console.log("Data changed...", newValue);
//   },
//   { deep: true }
// );
const searchEntity = async () => {
  // console.log("Search area...", searchArea.value);
  // console.log("Data for searching...", data.value);
  // console.log("Original data for searching...", originalData.value);
  if (!Object.keys(searchArea.value).length) {
    data.value = originalData.value;
    return;
  }
  let counter = 0;

  for (let key in searchArea.value) {
    let Data = counter ? data.value : originalData.value;
    if (key === "search") {
      let index = String(searchArea.value[key]).toLowerCase();
      data.value = Data.filter((item) => {
        let entityName = String(item.entity_name).toLowerCase();
        let placeOfEstablishment = String(
          item.place_of_establishment
        ).toLowerCase();
        let licenseNumber = String(item.license_number).toLowerCase();
        let licenseAuthority = String(item.license_authority).toLowerCase();
        return (
          entityName.includes(index) ||
          placeOfEstablishment.includes(index) ||
          licenseNumber.includes(index) ||
          licenseAuthority.includes(index)
        );
      });
    } else if (key === "type") {
      data.value = Data.filter((item) => {
        if (searchArea.value[key] === "all") {
          return true;
        }

        return item.entity_type == searchArea.value[key];
      });
    } else if (
      key === "file_return_status" ||
      key === "license_authority_type"
    ) {
      data.value = Data.filter((item) => item[key] === searchArea.value[key]);
    }
    counter++;
  }
};
const setEntityQuery = async (newValue) => {
  loading.value = true;
  const dateQuery = newValue.financial_period
    ? newValue.financial_year
      ? `?financial_period=${newValue.financial_period}&financial_year=${newValue.financial_year}`
      : "?financial_period=" + newValue.financial_period
    : newValue.financial_year
    ? "?financial_year" + newValue.financial_year
    : "";
  if (dateQuery) {
    await getEntityItems("entities", `/entity${dateQuery}`);
  } else {
    await getEntityItems("entities", `/entity`);
  }
  await setEntity();
};
const setEntity = async () => {
  const Entities = [];
  entities.value.data?.forEach((Entity) => {
    let data = {};
    Entity.entity.forEach((e) => {
      data = e;
    });
    Entity.license.forEach((l) => {
      const licenseData = removeKeyFromObject(l, ["id"]);
      data = { ...data, ...licenseData };
    });
    Entities.push(data);
  });
  const Data = [];
  for (let i = 0; i < Entities.length; i++) {
    const entity = Entities[i];
    const entityType = await getEntityAttribute(
      "type",
      "/entityType",
      entity?.entity_type
    );
    const countryName = await countryStore.getCountryName(
      entity.place_of_incorporation
    );
    const item = {
      id: entity?.id,
      entity_name:
        entity?.entity_type == 2
          ? `${entity?.entity_first_name} ${entity?.entity_last_name}`
          : entity?.entity_name,
      entity_type: entityType,
      place_of_establishment: countryName,
      license_number: entity?.entity_license[0]?.license_number,
      license_authority: entity?.license_authority?.name,
      license_authority_type: entity?.license_authority_type?.name,
      file_return_status: entity?.file_return_status,
      onclick: (id) => pushToMasterData(id),
    };
    await Data.push(item);
  }
  data.value = Data;
  originalData.value = Data;
  // console.log("Changed to original value", data.value);
  loading.value = false;
};
onMounted(async () => {
  loading.value = true;
  if (!type.value.data?.length) {
    await getEntityItems("type", `/entityType`);
  }
  const dateQuery = route.query.financial_period
    ? route.query.financial_year
      ? `?financial_period=${route.query.financial_period}&financial_year=${route.query.financial_year}`
      : "?financial_period=" + route.query.financial_period
    : route.query.financial_year
    ? "?financial_year" + route.query.financial_year
    : "";
  await getEntityItems("entities", `/entity${dateQuery}`);
  await setEntity();
  if (entity.value.data) {
    const id = route.params.id;
    await getEntityItem("entity", "/entity", id);
  }
  name.value = entity.value.data
    ? entity.value.data?.entity
      ? entity.value.data?.entity[0].entity_name
      : ""
    : "";
  if (Object.keys(route.query).length) {
    for (let key in route.query) {
      searchArea.value[key] = route.query[key];
    }
  }
});

const closeModal = () => {
  open.value = false;
};
const openModal = () => {
  if (profile.value.data?.has_subscription === "Y") {
    router.push("/entity/add-entity/entity-details/entity");
  } else {
    open.value = true;
  }
};
</script>

<style scoped>
.wrapper {
  min-height: 80vh;
}
.btn-add-entity {
  background: #0f75bc;
  border-radius: 5px;
  width: 177.33px;
  height: 47px;
  gap: 6.6px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.search-entity {
  padding-bottom: 6px;
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.search-entity:focus {
  outline: 0;
}
.search-entity::placeholder {
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #414142;
}
.entity-type {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #414142;
}
.select {
  width: 163px;
  height: 40px;
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #414142;
}
.disclaimer-container {
  background: #deeded;
}
.note-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.warning-icon {
  font-size: 20px;
}
@media screen and (max-width: 600px) {
  .search-entity {
    width: 100% !important;
  }
}
.no-data-img {
  width: 200px;
  height: auto;
}
.no-data {
  color: #747171;
}
</style>

<template>
  <SideNavContainer
    side-nav-type="financialData"
    title="Other Adjustments"
    :subtitle="name"
    :TitleRightSide="FinancialYear"
  >
    <ChaptersBreadCrumb
      :entity-name="name"
      chapter="other-adjustments"
      chapter-name="Other Adjustments"
    />
    <Business Tab="other-adjustments" />
    <LoaderContainer
      :loading="loading || statusLoading"
      :error="error || statusError"
    >
      <div class="row">
        <div class="col-lg-12 mb-4">
          <div
            v-if="$route.query.current_business !== 'master'"
            class="w-100 d-flex align-items-center justify-content-end"
          >
            <button class="btn-submit" @click="saveData">Save</button>
          </div>
        </div>
        <Addition />
        <Deduction />
        <div class="col-lg-12">
          <div
            class="grand-total d-flex align-items-center justify-content-between mb-10"
          >
            <p class="mb-0 grand-total-amount">Net Addition/(deduction)</p>
            <div class="d-flex align-items-center">
              <p class="mb-0 grand-total-amount mr-3">
                {{ NumberFormatter(allTotal) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </LoaderContainer>
  </SideNavContainer>
</template>

<script setup>
import { SideNavContainer } from "../../layout";
import { useBusinessStore } from "../../../store/buisness.store";
import { storeToRefs } from "pinia";
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { ChaptersBreadCrumb } from "../../BreadCrumbs";
import Business from "../Business/Business.vue";
import { useOtherAdjustmentStore } from "../../../store/other-adjustment";
import { useStatusStore } from "../../../store/statusStore";
import { LoaderContainer } from "../../common";
import Addition from "./Addition.vue";
import Deduction from "./Deduction.vue";
import { NumberFormatter } from "../../../helpers/numberFormatter";
import FinancialYear from "../common/FinancialPeriodYear.vue";
const route = useRoute();
const businessStore = useBusinessStore();
const otherAdjustments = useOtherAdjustmentStore();
const { business } = storeToRefs(businessStore);
const { loading, error, total, masterData } = storeToRefs(otherAdjustments);
const statusStore = useStatusStore();
const {
  status,
  loading: statusLoading,
  error: statusError,
} = storeToRefs(statusStore);

const name = computed(() => {
  const findBusiness = business.value.data?.find(
    (b) => b.id == route.query.current_business
  );
  return findBusiness?.name ? findBusiness?.name : "";
});
const allTotal = computed(() => {
  if (route.query.current_business === "master") {
    return Number(masterData.value.total || 0);
  }
  return total.value;
});
onMounted(async () => {
  await otherAdjustments.getOtherAdjustments(route);
  if (route.query.financial_year && route.query.financial_period) {
    await statusStore.getStatus(
      route.params.id,
      route.query.financial_year,
      route.query.financial_period
    );
  }
});
const saveData = async () => {
  if (status.value || route.query.business === "master") {
    return;
  }
  await otherAdjustments.saveOtherAdjustment(route, () => {});
};
</script>

<style scoped>
.header {
  height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: white;
}
.table-body {
  min-height: 80px;
  padding-right: 100px;
  padding-left: 26px;
  gap: 30px;
}
.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
  overflow: hidden;
}
.total-container {
  height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}
.total-desc {
  background: #deeded;
}
.reference {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.article-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px; /*
identical to box height, or 185% */
  letter-spacing: 0.055em;
  color: #000000;
}
.article {
  border-top: 1px solid #747171;
  border-bottom: 1px solid #747171;
}
.btn-submit {
  width: 82px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
.article-desc {
  white-space: pre-wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px; /* or
185% */
  letter-spacing: 0.055em;
  color: #747171;
}
</style>

<template>
  <CustomModalContainer
    :open="open"
    :set-open="setOpen"
    persistent
    :show-close-icon="false"
  >
    <div class="modal-box">
      <div class="w-100 d-flex align-items-center justify-content-between">
        <h4 class="modal-title">Are financials prepared as per IFRS?</h4>
        <CustomRadioButton
          v-model="selectedOption"
          :options="[
            { name: 'Yes', value: 'IFRS' },
            { name: 'No', value: 'Non-IFRS' },
          ]"
        />
      </div>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <button v-if="selectedOption" class="save-button mt-4" @click="setFt">
          Set
        </button>
      </div>
    </div>
  </CustomModalContainer>
</template>

<script setup>
import { ref } from "vue";
import { CustomModalContainer, CustomRadioButton } from "../../common";

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  setOpen: {
    type: Function,
    default: () => {},
  },
  setFinancialType: {
    type: Function,
    default: () => {},
  },
});
const selectedOption = ref("");
const setFt = async () => {
  await props.setFinancialType(selectedOption.value);
  await props.setOpen(false);
};
</script>

<style lang="scss" scoped>
.error-icon {
  height: 80px;
  width: 120px;
}
.modal-box {
  width: 400px;
}
.modal-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #747171;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
</style>

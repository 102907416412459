<template>
  <LoaderContainer
    v-if="haveLoader"
    :loading="loading || (checkStatus ? statusLoading : false)"
    :error="error.type === 'not-decided' ? false : error.message"
  >
    <IfrsCheckerPopUp
      :open="open"
      :set-open="setOpen"
      :set-financial-type="setFinancialType"
    />
    <slot v-if="data === 'IFRS' || data === 'Non-IFRS'" />
  </LoaderContainer>
  <div v-else class="w-100">
    <IfrsCheckerPopUp
      :open="open"
      :set-open="setOpen"
      :set-financial-type="setFinancialType"
    />
    <slot />
  </div>
</template>

<script setup>
import { onUnmounted, ref, watch } from "vue";
import { useRoute, onBeforeRouteLeave, useRouter } from "vue-router";

import { AlertModal, LoaderContainer } from "../../common";

import { useIFRSStore } from "../../../store/NonIFRS/IFRS.store";
import { storeToRefs } from "pinia";
import IfrsCheckerPopUp from "./IfrsCheckerPopUp.vue";

import { useStatusStore } from "../../../store/statusStore";
const props = defineProps({
  checkStatus: {
    type: Boolean,
    default: false,
  },
  haveLoader: {
    type: Boolean,
    default: true,
  },
});
const route = useRoute();
const router = useRouter();
const statusStore = useStatusStore();
const time = ref(10);
const interval = ref("");
const ifrsStore = useIFRSStore();
const { loading, error, data } = storeToRefs(ifrsStore);
const alertModal = ref(false);
const { loading: statusLoading } = storeToRefs(statusStore);
const open = ref(false);
const setOpen = (value) => {
  open.value = value;
};
const setAlertModal = (value) => {
  alertModal.value = value;
};
const setTimer = (callBack) => {
  interval.value = setInterval(() => {
    if (time.value === 1) {
      time.value = time.value - 1;

      callBack();
    } else {
      time.value = time.value - 1;
    }
  }, 1000);
};
const goToGeneralInformationPage = async () => {
  setAlertModal(false);
  clearTimer();
  restartTimer();
  router.push(
    `/entity/general-information/${route.params.id}?financial_year=${route.query.financial_year}&financial_period=${route.query.financial_period}`
  );
};
const restartTimer = () => {
  time.value = 10;
};

const clearTimer = () => {
  clearInterval(interval.value);
};
const unWatchTime = watch(time, (newVal) => {
  if (newVal == 0) {
    clearTimer();
    restartTimer();
  }
});
const unWatchError = watch(
  () => error.value.type,
  (newVal) => {
    if (newVal === "not-decided") {
      setOpen(true);
    }
  },
  { deep: true }
);
const setFinancialType = async (value) => {
  await ifrsStore.saveFinancialType(
    route.params.id,
    route.query.financial_year,
    route.query.financial_period,
    value,
    async () => {
      await ifrsStore.getIFRSCheck(
        route.params.id,
        route.query.financial_year,
        route.query.financial_period,
        async () => {
          await statusStore.getStatus(
            route.params.id,
            route.query.financial_year,
            route.query.financial_period
          );
        }
      );
    }
  );
};
onBeforeRouteLeave(async () => {
  await unWatchError();
  await unWatchTime();
  return true;
});
onUnmounted(async () => {
  await unWatchError();
  await unWatchTime();
});
</script>

<style scoped>
.error-icon {
  height: 80px;
  width: 120px;
}
.modal-box {
  width: 550px;
}
.modal-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #747171;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
</style>

export function checkPasswordStrength(password) {
  // The password must be at least 8 characters long.
  if (password.length < 8) {
    return { suggestion: "Too short", color: "danger", valid: false };
  }

  // The password must contain at least one uppercase letter.
  if (!/[A-Z]/.test(password)) {
    return {
      suggestion: "Include uppercase letters",
      color: "warning",
      valid: false,
    };
  }

  // The password must contain at least one lowercase letter.
  if (!/[a-z]/.test(password)) {
    return {
      suggestion: "Include lowercase letters",
      color: "warning",
      valid: false,
    };
  }

  // The password must contain at least one digit.
  if (!/[0-9]/.test(password)) {
    return { suggestion: "Include digits", color: "warning", valid: false };
  }

  // The password must contain at least one special character.
  if (!/[^A-Za-z0-9]/.test(password)) {
    return {
      suggestion: "Include special characters",
      color: "warning",
      valid: false,
    };
  }
  // The password is strong.
  return { suggestion: "✓ Strong password", color: "success", valid: true };
}

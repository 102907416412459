<template>
  <LoaderContainer :loading="loading" :error="statusError">
    <div class="row">
      <div class="col-lg-12">
        <div class="d-flex align-items-center mb-2">
          <small class="mr-3 mb-0 bold text-info info-container">i</small>
          <small class="text-muted mr-1 mb-0">
            Double Click to enter more entries
          </small>
        </div>
        <div
          class="header d-flex align-items-center w-100 justify-content-between"
        >
          <p class="header-title mb-0">Particulars</p>
          <p class="header-title mb-0">Amount</p>
        </div>
        <NonCurrentAssets :set-saving-status="setSavingStatus" />
        <CurrentAssets :set-saving-status="setSavingStatus" />
      </div>
    </div>
  </LoaderContainer>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useNoneIFRSBalanceSheet } from "../../../../../../store/NonIFRS/balancesheet.store";
import NonCurrentAssets from "./NonCurrentAssets.vue";
import CurrentAssets from "./CurrentAssets.vue";

import { storeToRefs } from "pinia";
import { LoaderContainer } from "../../../../../common";
import { useStatusStore } from "../../../../../../store/statusStore";
import { generate } from "randomized-string";
defineProps({
  view: {
    type: Boolean,
    default: false,
  },
  setSavingStatus: {
    type: Function,
    default: () => {},
  },
});
const nonIFRSBsStore = useNoneIFRSBalanceSheet();

const statusStore = useStatusStore();
const toastId = ref(generate());
const { loading } = storeToRefs(nonIFRSBsStore);
const { error: statusError } = storeToRefs(statusStore);

onMounted(() => {
  loading.value = true;
});
</script>

<style lang="scss" scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.info-container {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #deeded;
}
</style>

<template>
  <div class="w-100">
    <AlertModal
      :open="deleteModal.modal"
      message="Are you sure you want to delete this user ?"
      sub-message="User  will be deleted permanently!!"
      :on-confirm="removeUser"
      :on-cancel="onCancel"
    />
    <AssignEntity
      :open="open"
      :set-open="setOpen"
      :user-id="selectedUser.user_id"
      :name="selectedUser.name"
      :entities="selectedUser.entities"
    />
    <CustomModalContainer
      :open="changePasswordUser.modal"
      :set-open="openModal"
      width="500"
    >
      <ChangePassword
        v-show="!!changePasswordUser.user_id"
        :sub-user-id="changePasswordUser.user_id"
        :set-open="openModal"
      />
    </CustomModalContainer>
    <div class="w-100 table-responsive mt-4 mb-0">
      <table class="table custom-table mb-0 table-bordered table-striped">
        <thead>
          <tr>
            <th class="table-header">Sl No.</th>
            <th class="table-header">User Name</th>
            <th class="table-header">Email</th>
            <th class="table-header">Mobile Number</th>
            <th class="table-header text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in data" :key="user">
            <td class="table-rows align-middle">
              <p class="data-text mb-0">{{ index + 1 }}</p>
            </td>
            <td class="table-rows align-middle">
              <p class="data-text mb-0">
                {{ `${user.first_name} ${user.last_name}` }}
              </p>
            </td>
            <td class="table-rows align-middle">
              <p class="data-text mb-0">
                {{ user.email }}
              </p>
            </td>
            <td class="table-rows align-middle">
              <p class="data-text mb-0">
                {{ user.mobile_no }}
              </p>
            </td>
            <td class="table-rows align-middle">
              <div
                class="w-100 d-flex flex-wrap align-items-center justify-content-end"
              >
                <button
                  class="assign-entity-button mt-2 p-2 data-text mr-2 mb-0"
                  @click="assignEntity(user)"
                >
                  Assign Entity
                </button>
                <button
                  class="assign-entity-button mt-2 p-2 data-text mr-2 mb-0"
                  @click="$router.push(`/users/update-user/${user.id}`)"
                >
                  Edit
                </button>
                <button
                  class="assign-entity-button mt-2 p-2 data-text mr-2 mb-0"
                  @click="openModal(true, user.id)"
                >
                  Change Password
                </button>
                <button
                  class="save-button mt-2 mr-2 px-4"
                  @click="setUser(user.id)"
                >
                  <img src="@/assets/svg/trash.svg" alt="" />
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="!data.length">
            <td class="align-middle" :colspan="5">
              <p class="data-text text-center mb-0">
                <b> No sub user is registered yet </b>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import AssignEntity from "./AssignEntity.vue";
import { AlertModal, CustomModalContainer } from "../common";
import ChangePassword from "./ChangePassword.vue";
import { useUsersStore } from "../../store/users.store";
import { useProfileStore } from "../../store/profile";

const props = defineProps({
  data: {
    type: Array,
    default: [],
  },
});
const changePasswordUser = ref({
  modal: false,
  user_id: "",
});
const profileStore = useProfileStore();
const deleteModal = ref({
  user_id: "",
  modal: false,
});

const onCancel = () => {
  deleteModal.value.user_id = "";
  deleteModal.value.modal = false;
};
const setUser = (id) => {
  deleteModal.value.user_id = id;
  deleteModal.value.modal = true;
};
const userStore = useUsersStore();
const openModal = (value, id) => {
  if (id) {
    changePasswordUser.value.user_id = id;
  }
  changePasswordUser.value.modal = value;
};
const open = ref(false);
const selectedUser = ref({
  user_id: "",
  name: "",
  entities: [],
});
const setOpen = (value) => {
  open.value = value;
};
const assignEntity = (user) => {
  selectedUser.value = {
    user_id: user?.id,
    name: `${user.first_name} ${user.last_name}`,
    entities: user?.entities ?? [],
  };
  setOpen(true);
};
const removeUser = async () => {
  if (deleteModal.value.user_id)
    await userStore.deleteUser(deleteModal.value.user_id, async () => {
      await userStore.getUsersList();
      await profileStore.getUserProfile();
    });
};
</script>
<style scoped>
.card-container {
  max-width: 600px;
  background: #fafbff;
  border-radius: 5px;
  padding: 33px 27px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.card-container::-webkit-scrollbar {
  display: none;
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /* identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
}
.table-header {
  min-height: 39px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2.5px solid #fff;
}
.table-rows {
  background: #ffffff;
  border: 2.5px solid #fff;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input {
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input:focus {
  outline: none;
}
.save-button {
  height: 41px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.delete-button {
  width: 50px;
  height: 44px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.gray {
  background: #deeded;
}
.assign-entity-button {
  width: 155px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
  height: 40px;
}
</style>

import axios from "axios";

export const getBaseUrl = () => {
  let host = window.location.origin;
  if (host === "https://app.snaptax.me") {
    return `https://prodadmin.snaptax.me/api/v1`;
  }
  if (host === "https://uat.snaptax.me") {
    return "https://uatadmin.snaptax.me/api/v1";
  }
  return `https://admin.snaptax.me/api/v1`;
};

const fetchWrapper = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    Authorization: "Bearer " + sessionStorage.getItem("Token")
  }
});

fetchWrapper.interceptors.request.use(function (config) {
  // Do something before request is sent
  const authKey = sessionStorage.getItem("Token");
  config.headers.Authorization = "Bearer " + authKey;
  return config;
});

export default fetchWrapper;

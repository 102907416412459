<template>
  <Loader v-if="loading" />
  <SideNavContainer title="Add Entity" v-else>
    <EntityChecker>
      <AddEntityTab active="branchDetails" />
      <StatusChecker>
        <LoaderContainer :loading="loading">
          <form @submit="handleSubmit">
            <div class="row wrapper px-2">
              <div class="col-lg-4 col-md-6 mt-4">
                <h4 class="label">
                  Do you have branches in the UAE?
                  <span class="text-danger">*</span>
                </h4>
                <div
                  class="radio-container w-100 d-flex align-items-center pl-1"
                >
                  <input
                    v-model="has_branch"
                    type="radio"
                    value="Y"
                    :error-message="error.has_branch"
                  />
                  <p class="mb-0 yes">Yes</p>
                  <input
                    v-model="has_branch"
                    type="radio"
                    value="N"
                    :error-message="error.has_branch"
                  />
                  <p class="mb-0 yes">No</p>
                </div>
              </div>
              <p v-if="error.has_branch" class="text-danger">
                {{ error.has_branch }}
              </p>
            </div>
            <div v-if="has_branch === 'Y'" class="row wrapper px-2">
              <div class="col-lg-4 col-md-6 mt-4">
                <h4 class="label">
                  Place of Establishment <span class="text-danger">*</span>
                </h4>
                <v-autocomplete
                  v-model="place_of_establishment"
                  label="Place of Establishment *"
                  :items="state.data"
                  item-title="name"
                  item-value="id"
                  bg-color="#DEEDED"
                  :error-message="error.place_of_establishment"
                >
                </v-autocomplete>
                <p v-if="error.place_of_establishment" class="text-danger">
                  {{ error.place_of_establishment }}
                </p>
              </div>

              <div class="col-lg-4 col-md-6 mt-4">
                <CustomSelectInput
                  v-model="license_authority_type"
                  label=" License Authority Type *"
                  :options="licenseAuthorityType.data"
                  item-title="name"
                  item-value="id"
                  placeholder="Select License Authority Type"
                  :error-message="error.license_authority_type"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomSelectInput
                  v-model="license_authority"
                  label="License Authority *"
                  :options="licenseAuthority.data"
                  item-title="name"
                  item-value="id"
                  placeholder="Select License Authority"
                  :other-props="{
                    loading: licenseAuthority.loading ? 'info' : false,
                  }"
                  :error-message="error.license_authority"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="license_number"
                  placeholder="License Number"
                  label="License Number *"
                  :error-message="error.license_number"
                  @keypress="onlyAcceptAlphaNumeric"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomDateInput
                  v-model="license_issue_date"
                  label="Issue Date *"
                  :error-message="error.license_issue_date"
                  :max="dateNow()"
                  placeholder="License Number"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomDateInput
                  v-model="license_expiry_date"
                  label="Expiry Date *"
                  :error-message="error.license_expiry_date"
                  :min="dateNow()"
                  placeholder="License Number"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomFileUploadInput
                  v-model="license_certification"
                  :file-name="license_certification"
                  label="License Certification"
                  supported-file-text="*Supported file type - .png , .jpeg, .pdf. * Supported file size - 0 - 2 MB"
                  accept="image/png, image/jpeg, application/pdf"
                  :link="
                    license_certification
                      ? `${license_imagepath}/${license_certification}`
                      : ''
                  "
                  :error-message="error.license_certification"
                  :input-props="{
                    onchange: (e) =>
                      handleFileChange(e, 'license_certification'),
                    accept: 'image/png, image/jpeg, application/pdf',
                  }"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-3">
                <CustomFileUploadInput
                  v-model="registration_certification"
                  label="Registration Certification"
                  :file-name="registration_certification"
                  supported-file-text="*Supported file type - .png , .jpeg, .pdf. * Supported file size - 0 - 2 MB"
                  accept="image/png, image/jpeg, application/pdf"
                  :link="
                    registration_certification
                      ? `${registration_imagepath}/${registration_certification}`
                      : ''
                  "
                  :error-message="error.registration_certification"
                  :input-props="{
                    onchange: (e) =>
                      handleFileChange(e, 'registration_certification'),
                    accept: 'image/png, image/jpeg, application/pdf',
                  }"
                />
              </div>
              <div class="col-lg-12 mt-4">
                <h4 class="mb-0 bold">Business Activity</h4>
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <h4 class="label">
                  Industry <span class="text-danger">*</span>
                </h4>
                <v-autocomplete
                  v-model="industry"
                  label="Industry"
                  :items="industries.data"
                  item-title="name"
                  item-value="id"
                  bg-color="#DEEDED"
                >
                </v-autocomplete>
                <p v-if="error.industry" class="text-danger">
                  {{ error.industry }}
                </p>
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <h4 class="label">
                  Main Group <span class="text-danger">*</span>
                </h4>
                <v-autocomplete
                  v-model="main_group"
                  label="Main Group"
                  :error-message="error.main_group"
                  :items="mainGroups.data"
                  item-title="main_group"
                  item-value="id"
                  bg-color="#DEEDED"
                  :loading="mainGroups.loading ? 'info' : false"
                >
                </v-autocomplete>
                <p v-if="error.main_group" class="text-danger">
                  {{ error.main_group }}
                </p>
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <h4 class="label">
                  Sub Group <span class="text-danger">*</span>
                </h4>
                <v-autocomplete
                  v-model="sub_group"
                  label="Sub Group"
                  :error-message="error.sub_group"
                  :items="subGroups.data"
                  item-title="sub_group"
                  item-value="id"
                  bg-color="#DEEDED"
                  :loading="subGroups.loading ? 'info' : false"
                >
                </v-autocomplete>
                <p v-if="error.sub_group" class="text-danger">
                  {{ error.sub_group }}
                </p>
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <h4 class="label">
                  Activity <span class="text-danger">*</span>
                </h4>
                <v-autocomplete
                  v-model="activity"
                  label="Activity"
                  :error-message="error.activity"
                  :items="activities.data"
                  item-title="activity"
                  item-value="id"
                  bg-color="#DEEDED"
                  :loading="activities.loading ? 'info' : false"
                >
                </v-autocomplete>
                <p v-if="error.activity" class="text-danger">
                  {{ error.activity }}
                </p>
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="activity_code"
                  placeholder="Activity Code"
                  label="Activity Code"
                  disabled="disabled"
                />
              </div>
            </div>
            <p class="my-2 text-info">{{ message }}</p>
            <div class="d-flex align-items-center mt-4 mb-4">
              <button class="save-next mr-3" type="submit">Save & Next</button>
              <button
                class="cancel"
                type="button"
                @click="$router.push('/entity')"
              >
                Cancel
              </button>
            </div>
          </form>
        </LoaderContainer>
      </StatusChecker>
    </EntityChecker>
  </SideNavContainer>
</template>

<script>
import { SideNavContainer } from "../../../../layout";
import {
  AddEntityTab,
  CustomDateInput,
  LoaderContainer,
} from "../../../../common";
import CustomSelectInput from "@/components/common/CustomSelectInput.vue";
import CustomTextInput from "../../../../common/CustomTextInput.vue";
import { useEntityStore } from "../../../../../store/entity";
import { storeToRefs } from "pinia";
import {
  getEntityItem,
  getEntityItems,
} from "../../../../../store/entityActions/get";
import { addEntity } from "@/store/entityActions/post";
import EntityChecker from "../../EntityChecker.vue";
import { useCountryStore } from "../../../../../store/country";

import { dateNow } from "../../../../../helpers/date";
import { checkFileSize } from "../../../../../helpers/file";
import {
  onlyAcceptAlphaNumeric,
  onlyAcceptAlphabets,
} from "../../../../../helpers/validation";
import StatusChecker from "../../StatusChecker.vue";
import CustomFileUploadInput from "../../../../common/CustomFileUploadInput.vue";

export default {
  name: "CheckOutEntity",
  components: {
    SideNavContainer,
    AddEntityTab,
    CustomSelectInput,
    CustomTextInput,
    CustomDateInput,
    EntityChecker,
    StatusChecker,
    CustomFileUploadInput,
    LoaderContainer,
  },
  setup() {
    const entityStore = useEntityStore();
    const countryStore = useCountryStore();
    const {
      businessActivity,
      entity,
      businessSubActivity,
      licenseAuthorityType,
      licenseAuthority,
      industries,
      mainGroups,
      subGroups,
      activities,
      entityBranch,
    } = storeToRefs(entityStore);
    const { countries, states: state } = storeToRefs(countryStore);
    return {
      countries,
      state,
      countryStore,
      businessActivity,
      entity,
      businessSubActivity,
      licenseAuthorityType,
      licenseAuthority,
      dateNow,
      onlyAcceptAlphaNumeric,
      onlyAcceptAlphabets,
      industries,
      mainGroups,
      subGroups,
      activities,
      entityBranch,
    };
  },
  data() {
    return {
      has_branch: "",
      place_of_establishment: "",
      license_authority_type: "",
      license_authority: "",
      license_number: "",
      license_issue_date: "",
      license_expiry_date: "",
      license_certification: "",
      registration_certification: "",
      industry: "",
      main_group: "",
      sub_group: "",
      activity: "",
      activity_code: "",
      //   industry: "",
      //  main_group: "",
      //sub_group: "1",
      //activity: "",
      //activity_code: "",
      imagePath: "",
      loading: true,
      first_time: {
        license_authority: "",
        industry: true,
        main_group: true,
        sub_group: true,
        activity: true,
      },
      error: {
        has_branch: "",
        place_of_establishment: "",
        license_authority_type: "",
        license_authority: "",
        license_number: "",
        license_issue_date: "",
        license_expiry_date: "",
        license_certification: "",
        registration_certification: "",
        industry: "",
        main_group: "",
        sub_group: "",
        activity: "",
      },
    };
  },

  watch: {
    async place_of_establishment(newValue) {
      await this.getLicenseAuthority(newValue, this.license_authority_type);
      this.error.place_of_establishment = "";
    },
    async license_authority_type(newValue) {
      await this.getLicenseAuthority(this.place_of_establishment, newValue);
      this.error.license_authority_type = "";
    },
    async license_authority(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        await getEntityItem("businessActivity", "/businessActivity", newValue);
        this.error.license_authority = "";
      }
    },
    async industry(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        await getEntityItem("mainGroups", "/mainGroups", newValue);
      } else if (!newValue) {
        this.mainGroups.data = [];
      }
      if (this.first_time.industry) {
        this.first_time.industry = false;
        return;
      }
      const find = this.mainGroups.data.find((b) => b.id == this.main_group);
      if (!find) {
        this.main_group = "";
      }
      this.error.industry = "";
    },
    async main_group(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        await getEntityItem("subGroups", "/subGroups", newValue);
      } else if (!newValue) {
        this.subGroups.data = [];
      }
      if (this.first_time.main_group) {
        this.first_time.main_group = false;
        return;
      }
      const find = this.subGroups.data.find((b) => b.id == this.sub_group);
      if (!find) {
        this.sub_group = "";
      }
      this.error.main_group = "";
    },

    async sub_group(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        await getEntityItem("activities", "/activities", newValue);
      } else if (!newValue) {
        this.activities.data = [];
      }
      if (this.first_time.sub_group) {
        this.first_time.sub_group = false;
        return;
      }
      const find = this.activities.data.find((b) => b.id == this.activity);
      if (!find) {
        this.activity = "";
      }
      this.error.sub_group = "";
    },
    async activity(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        const activities = this.activities.data.find((c) => c.id == newValue);
        if (activities) {
          this.activity_code = activities?.activity_code;
        }
      } else if (!newValue) {
        this.activity_code = "";
      }
      this.error.activity = "";
    },
    has_branch(newValue) {
      this.error.has_branch = "";
    },
    license_number(newValue) {
      this.error.license_number = "";
    },
    license_issue_date(newValue) {
      this.error.license_issue_date = "";
    },
    license_expiry_date(newValue) {
      this.error.license_expiry_date = "";
    },
  },
  async created() {
    const id = this.$route.params.sub_id;
    if (!this.entity.data) {
      await getEntityItem("entity", "/entity", this.$route.params.id);
    }
    if (!this.countries.data?.length) {
      await this.countryStore.getCountryList();
    }
    if (!this.state.data?.length) {
      const country = this.countries.data.find(
        (c) => c.name === "United Arab Emirates"
      );
      if (country) {
        await this.countryStore.getStateList(country?.id);
      }
    }
    if (!this.businessActivity.data.length) {
      await getEntityItems("businessActivity", "/businessActivity");
    }

    if (!this.licenseAuthorityType.data.length) {
      await getEntityItems("licenseAuthorityType", "/license_authority_type");
    }
    if (!this.industries.data.length) {
      await getEntityItems("industries", "/industries");
    }
    const entityDetails = this.entity.data?.entity?.find(
      (e) => Number(e.id) === Number(this.$route.params.id)
    );
    this.has_branch = entityDetails?.has_branch;
    this.licenseAuthority.loading = false;
    this.mainGroups.loading = false;
    this.subGroups.loading = false;
    this.activities.loading = false;
    if (id && entityDetails?.has_branch === "Y") {
      await this.getSubId();
    }
    this.loading = false;
  },

  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      if (!this.has_branch) {
        this.error.has_branch = " Field is required";
        return;
      }

      if (!this.place_of_establishment && this.has_branch == "Y") {
        this.error.place_of_establishment = " Field is required";
        return;
      }
      if (!this.license_authority_type && this.has_branch == "Y") {
        this.error.license_authority_type = " Field is required";
        return;
      }
      if (!this.license_authority && this.has_branch == "Y") {
        this.error.license_authority = " Field is required";
        return;
      }
      if (!this.license_number && this.has_branch == "Y") {
        this.error.license_number = " Field is required";
        return;
      }
      // if (
      //  String(this.license_number).length < 15 ||
      //   String(this.license_number).length > 20
      // ) {
      //   this.error.license_number =
      //    "License number should be between  15 to 20 characters";
      //  return;
      // }
      if (!this.license_issue_date && this.has_branch == "Y") {
        this.error.license_issue_date = " Field is required";
        return;
      }
      if (!this.license_expiry_date && this.has_branch == "Y") {
        this.error.license_expiry_date = " Field is required";
        return;
      }
      if (!this.industry && this.has_branch == "Y") {
        this.error.industry = " Field is required";
        return;
      }
      if (!this.main_group && this.has_branch == "Y") {
        this.error.main_group = " Field is required";
        return;
      }
      if (!this.sub_group && this.has_branch == "Y") {
        this.error.sub_group = " Field is required";
        return;
      }
      if (!this.activity && this.has_branch == "Y") {
        this.error.activity = " Field is required";
        return;
      }
      const id = this.$route.params.sub_id;
      const data = new FormData();

      data.append("place_of_establishment", this.place_of_establishment);
      data.append("license_authority_type", this.license_authority_type);
      data.append("license_authority", this.license_authority);
      data.append("license_number", this.license_number);
      data.append("license_issue_date", this.license_issue_date);
      data.append("license_expiry_date", this.license_expiry_date);
      data.append("industry", this.industry);
      data.append("main_group", this.main_group);
      data.append("sub_group", this.sub_group);
      data.append("activity", this.activity);
      data.append("activity_code", this.activity_code);
      data.append("has_branch", this.has_branch);
      if (
        typeof this.registration_certification === "object" &&
        this.registration_certification
      ) {
        data.append(
          "registration_certification",
          this.registration_certification
        );
      }
      if (
        typeof this.license_certification === "object" &&
        this.license_certification
      ) {
        data.append("license_certification", this.license_certification);
      }
      data.append("entity_license_id", id);
      if (
        typeof this.registration_certification === "object" &&
        this.has_branch == "Y"
      ) {
        if (!checkFileSize(this.registration_certification)) {
          this.error.registration_certification =
            "File size should be less than 2MB";
          return;
        }
      }
      if (
        typeof this.license_certification === "object" &&
        this.has_branch == "Y"
      ) {
        if (!checkFileSize(this.license_certification)) {
          this.error.license_certification =
            "File size should be less than 2MB";
          return;
        }
      }
      data.append("entity_id", this.$route.params.id);
      if (this.edit) {
        data.append("_method", "PUT");
        data.append("branch_id", this.$route.params.sub_id);

        await addEntity(
          "/entityBranch",
          data,
          this.setMessage,
          async () => {
            await getEntityItem("entity", "/entity", this.$route.params.id);
            this.$router.push(
              "/entity/branch-details/" + this.$route.params.id
            );
          },
          true
        );
        return;
      }

      addEntity(
        "/entityBranch",
        data,
        this.setMessage,
        async ({ entity_id: entityId }) => {
          await getEntityItem("entity", "/entity", entityId);
          this.$router.push("/entity/ownership-details/" + entityId);
        }
      );
    },
    setMessage(message) {
      this.message = message;
    },
    handleFileChange(e, model) {
      this.error[model] = "";
    },
    async getSubId() {
      const id = this.$route.params.sub_id;
      await getEntityItem(
        "entityBranch",
        "/entityBranch",
        this.$route.params.id
      );

      const Entity = this.entityBranch.data?.find(
        (e) => Number(e.id) === Number(id)
      );
      const entityDetails = this.entity.data?.entity?.find(
        (e) => Number(e.id) === Number(this.$route.params.id)
      );
      if (Entity) {
        this.edit = true;
        this.has_branch = Entity?.has_branch;
        this.place_of_establishment = Entity?.place_of_establishment?.id; //
        this.license_authority_type = Entity?.license_authority_type?.id; //
        this.license_authority = Entity?.license_authority?.id;
        this.license_number = Entity?.license_number;
        this.license_issue_date = Entity?.license_issue_date;
        this.license_expiry_date = Entity?.license_expiry_date;
        this.license_certification = Entity?.license_certification;

        this.registration_certification = Entity?.registration_certification;
        this.license_imagepath = Entity?.license_imagepath;
        this.registration_imagepath = Entity?.registration_imagepath;

        this.industry = Entity?.industry?.id;
        this.main_group = Entity?.main_group?.id;
        this.sub_group = Entity?.sub_group?.id;
        this.activity = Entity?.activity?.id;
        this.activity_code = Entity?.activity_code;
      }
    },
    async getLicenseAuthority(placeofEtablishment, license_authority_type) {
      if (placeofEtablishment && license_authority_type) {
        await getEntityItem(
          "licenseAuthority",
          "/license_authority_type/" + license_authority_type,
          placeofEtablishment
        );
      } else {
        this.licenseAuthority.data = [];
        return;
      }

      if (this.first_time.license_authority) {
        this.first_time.license_authority = false;
        return;
      }
      const find = this.licenseAuthority.data.find(
        (b) => b.id == this.license_authority
      );

      if (!find) {
        this.license_authority = "";
      }
    },
  },
};
</script>

<style scoped>
.save-next {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #fafbff;
  width: 196px;
  background: #0f75bc;
  height: 55px;
}
.cancel {
  background: #ffffff;
  border: 1px solid #747171;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #747171;
  height: 55px;
  width: 196px;
}
.owner-list {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.radio-container {
  height: 56px;
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  gap: 11px;
}
.yes {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.custom-input {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  height: 48px;
  padding: 13px 10px;
}
.custom-input.error {
  border: 1px solid red;
}
.custom-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
  text-align: start;
}
.custom-input:focus {
  outline: 0;
}
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

<template>
  <div class="d-flex flex-wrap justify-content-end align-items-center">
    <select
      v-model="financial_period"
      disabled
      class="date-select pl-2 mt-2 mr-3"
    >
      <option
        v-for="(year, index) in financialPeriod.data"
        :key="year.id"
        class="option"
        :value="year.id"
        :selected="!index"
      >
        {{ year.name }}
      </option>
    </select>
    <select
      id=""
      v-model="financial_year"
      name=""
      class="date-select pl-2 mt-2 mr-3"
      disabled
    >
      <option
        v-for="(year, index) in financialYear.data"
        :key="year.id"
        class="option"
        :value="year.id"
        :selected="!index"
      >
        {{ year.name }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { onMounted } from "vue";
import { useEntityStore } from "../../../store/entity";
import {
  getEntityItem,
  getEntityItems,
} from "../../../store/entityActions/get";
import { useProfitAndLossStore } from "../../../store/ProfitAdLoss";
import { watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const entityStore = useEntityStore();
const {
  financialYear: financialPeriod,
  financialPeriod: financialYear,
  entity,
} = storeToRefs(entityStore);
const profitAndLossStore = useProfitAndLossStore();
const { financial_year, financial_period } = storeToRefs(profitAndLossStore);

//entity.value.data?.entity[0]?.financial_period?.id
const setFinancialYear = async (fp) => {
  financial_period.value = "";
  financial_year.value = "";
  financial_period.value = fp;
  if (fp) {
    await getEntityItem(
      "financialPeriod",
      "/financialYear",
      financial_period.value
    );
    const fy = await localStorage.getItem("financial_year");
    if (fy) {
      const find = financialYear.value.data.find((f) => f.id == fy);
      financial_year.value = find ? fy : financialYear.value.data[0]?.id;
      await localStorage.setItem("financial_year", financial_year.value);
    } else {
      const find = financialYear.value.data.find(
        (f) => f.id == route.query.financial_year
      );
      financial_year.value = find
        ? route.query.financial_year
        : financialYear.value.data[0]?.id;
      await localStorage.setItem("financial_year", financial_year.value);
    }
  }
};

onMounted(async () => {
  if (!financialPeriod.value.data.length) {
    await getEntityItems("financialYear", "/financialYear");
  }
  let fp = entity.value.data?.entity[0]?.financial_period?.id;
  await setFinancialYear(fp);
});
watch(
  () => entity.value.data?.entity[0]?.financial_period?.id,
  async (newValue) => {
    await setFinancialYear(newValue);
  }
);
</script>

<style scoped>
.date-select {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  width: 140px;
  height: 40px;
  margin-bottom: 10px;
}
.date-select:focus {
  outline: none;
}
.option {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

import fetchWrapper from "@/helpers/fetchWrapper";
import fetchFileWrapper from "@/helpers/fileApi";
import { Toast } from "../../helpers/toast";

export const addEntity = async (
  endPoint,
  payload,
  setMessage,
  callBack = () => {},
  file = false
) => {
  let toast = new Toast("Saving....");
  try {
    let res;
    if (file) {
      res = await fetchFileWrapper.post(endPoint, payload);
    } else {
      res = await fetchWrapper.post(endPoint, payload);
    }
    if (res.data.result === 1) {
      toast.makeSuccessToast(res.data.message);
      callBack(res?.data);
    } else {
      toast.makeErrorToast(
        res.data?.error ? res.data?.error?.join(",") : res.data?.message
      );
    }
  } catch (err) {
    console.log(err);
    toast.makeErrorToast(err?.response?.data?.message || "");
  }
};

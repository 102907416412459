<template>
  <div class="w-100 h">
    <LoaderContainer
      v-if="Check"
      :loading="profile.loading"
      :error="profile.error"
    >
      <div class="w-100">
        <CustomModalContainer
          :open="open"
          :set-open="setOpen"
          content-class="my-custom-dialog"
          min-width="596px"
        >
          <div class="d-flex align-items-start justify-content-around">
            <img
              src="@/assets/svg/buy-subscription.svg"
              class="mr-3 mt-2"
              alt=""
            />
            <div class="">
              <p class="message-title mb-0">
                Your entity limit is up, Please add subscription per entity
              </p>
              <button
                v-if="!Number(profile.data?.parent_id)"
                class="choose-plan mt-4 text-center"
                @click="$router.push('/subscription/add-entity')"
              >
                Add plan
              </button>
            </div>
          </div>
        </CustomModalContainer>
        <slot v-if="!!remaining_entity_count" />
        <div
          v-else
          class="w-100 h d-flex align-items-center justify-content-center"
        >
          <p>
            Your entity limit is up, Please add subscription per entity
            <router-link
              v-if="!Number(profile.data?.parent_id)"
              to="/subscription/add-entity"
            >
              <b>Buy subscription now</b>
            </router-link>
          </p>
        </div>
      </div>
    </LoaderContainer>
    <slot v-else />
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useProfileStore } from "../../store/profile";
import { CustomModalContainer, LoaderContainer } from "../common";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
const props = defineProps({
  check: {
    type: Boolean,
    default: true,
  },
});
const Check = ref(props.check);
const profileStore = useProfileStore();
const route = useRoute();
const { profile, remaining_entity_count } = storeToRefs(profileStore);
const open = ref(false);
const setOpen = (value) => {
  open.value = value;
};
onMounted(() => {
  if (!profile.value.loading) {
    open.value = !remaining_entity_count.value;
  }
  if (props.check) {
    const sub_id = route.params?.sub_id;
    if (sub_id) {
      Check.value = false;
    }
  }
});
watch(
  () => props.check,
  (newVal) => {
    if (newVal) {
      const sub_id = route.params?.sub_id;
      if (sub_id) {
        Check.value = false;
      }
    }
  },
  { deep: true }
);
watch(
  () => profile.value.loading,
  (newVal) => {
    console.log(
      "on change",
      remaining_entity_count.value,
      profile.value.loading
    );
    if (!newVal) {
      open.value = !remaining_entity_count.value;
    }
  },
  { deep: true }
);
</script>

<style scoped>
.message-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.055em;
  color: #000000;
}
.message {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #747171;
}
.close-img {
  cursor: pointer;
}
.choose-plan {
  width: 151px;
  height: 40px;
  background: #0f75bc;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.h {
  min-height: 40vh;
}
</style>

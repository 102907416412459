<template>
  <SideNavContainer title="Choose Your Plan">
    <LoaderContainer
      :loading="profile.loading || subscriptions.loading"
      :error="profile.error || subscriptions.error"
    >
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div
            class="desc-container d-flex align-items-center justify-content-between"
          >
            <p class="mb-0 subscription-type">Subscription Type</p>
            <button class="business-owner">
              {{ profile.data.type }}
            </button>
          </div>
        </div>
      </div>
      <div class="row mt-4 px-2">
        <div
          v-for="item in subscriptions.data"
          :key="item.id"
          class="col-lg-6 mx-auto my-auto"
        >
          <div class="w-100 subscription mt-3">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <input
                  id=""
                  type="radio"
                  class="mr-2 mb-1"
                  @click="
                    $router.push(
                      `/subscription/checkout/${item.id}?redirect_tab=${$route.query.redirect_tab}`
                    )
                  "
                />
                <h4 class="subscription-title mb-0">
                  {{ item.name }}
                </h4>
              </div>
              <h4 class="price mb-0">AED {{ item.price }}</h4>
            </div>
            <ul class="py-3 subscription-detail">
              <li class="list-items">
                <p class="subscription-detail mb-0">
                  <u>
                    <b> Can support upto {{ item.entity_count }} entity </b>
                  </u>
                </p>
              </li>
              <li class="list-items">
                <p class="subscription-detail mb-0">
                  <u>
                    <b> Can support upto {{ item.user_count }} users </b>
                  </u>
                </p>
              </li>
              <li class="list-items">
                <p class="subscription-detail mb-0">
                  <u>
                    <b> Valid for {{ item.validity }} Filing </b>
                  </u>
                </p>
              </li>
              <li class="list-items">
                <p class="subscription-detail mb-0">
                  <u>
                    <b>
                      Price for additional entity is
                      {{ item.price_per_entity }} AED
                    </b>
                  </u>
                </p>
              </li>
              <li class="list-items">
                <div v-html="item.description"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </LoaderContainer>
  </SideNavContainer>
</template>

<script setup>
import { SideNavContainer } from "@/components/layout";
import { onMounted } from "vue";
import { useSubscriptionStore } from "../../../store/subscription";
import { useProfileStore } from "../../../store/profile";
import { storeToRefs } from "pinia";
import LoaderContainer from "../../common/LoaderContainer.vue";
const subscriptionStore = useSubscriptionStore();
const profileStore = useProfileStore();
const { profile } = storeToRefs(profileStore);
const { subscriptions } = storeToRefs(subscriptionStore);
onMounted(async () => {
  if (!subscriptions.value.data.length) {
    await subscriptionStore.getSubscriptions();
  }
});
</script>

<style scoped>
.subscription-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #747171;
}
.subscription-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.subscription-type {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.subscription {
  background: #deeded;
  box-shadow: 0px 8px 8px rgba(187, 187, 187, 0.25);
  border-radius: 11px;
  padding: 15px 10px 22px 22px;
}
.desc-container {
  padding: 22px 10px 18px 10px;
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.business-owner {
  width: 151px;
  height: 30px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.price {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #111827;
}
.list-items {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #382628;
}
</style>

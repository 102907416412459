<template>
  <div class="w-100">
    <CustomClickableInput
      v-for="item in modelData"
      :key="item.model"
      v-model="data[item.model]"
      :model="item.model"
      :name="item.name"
      :make-text-bold="!item.sub"
      :bg-color="item.sub ? 'white' : 'gray'"
      :sub="item.sub"
      :show-input="item.showInput"
      :disabled="item.disabled"
      :on-tab-and-enter="handleTabEnter"
      :amount-color="color[item.model]"
      :on-click="() => handleClick(item.model)"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { incomeAttributalCoi } from "./data";
import { CustomClickableInput } from "../../../common";
import { useQfzpCoiStore } from "../../../../store/QFZPCOI";
import { storeToRefs } from "pinia";
import { useProfitAndLossStore } from "../../../../store/ProfitAdLoss";
import { useRoute, useRouter } from "vue-router";

const modelData = ref(incomeAttributalCoi);
const route = useRoute();
const qfzpCoi = useQfzpCoiStore();
const { data, color } = storeToRefs(qfzpCoi);
const profitAndLossStore = useProfitAndLossStore();
const { financial_period, financial_year } = storeToRefs(profitAndLossStore);
const router = useRouter();
const handleTabEnter = async (model) => {
  //close current
  const newModel = modelData.value.map((item) => {
    if (item.model === model) {
      return { ...item, showInput: false };
    }
    return item;
  });
  modelData.value = await newModel;
  let next_model =
    modelData.value[modelData.value.map((e) => e.model).indexOf(model) + 1];

  if (next_model) {
    const nextModel = modelData.value.map((item) => {
      if (item.model === next_model.model) {
        return { ...item, showInput: true };
      }
      return item;
    });
    modelData.value = await nextModel;
  }
};
const handleClick = (key) => {
  if (
    key === "a_i_revenue_attr_domestic" ||
    key === "a_ii_expense_attr_domestic" ||
    key === "b_i_income_attr_foreign" ||
    key === "b_ii_expense_attr_foreign"
  ) {
    router.push(
      `/entity/general-information/fzp-calculator/${route.params.id}?financial_year=${financial_year.value}&financial_period=${financial_period.value}`
    );
  }
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}
.table-body.hover:hover {
  background: linear-gradient(90deg, #101432 0%, #00a7a7 100%);
  color: #fafbff !important;
}
.sub-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}

.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}

.total-container.h {
  min-height: 150px;
}

.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}

.clickable-text {
  text-align: right !important;
  border: none;
  border-bottom: 1px solid#000000;
  padding-bottom: 1px;
  padding-left: 4px;
  height: 100%;
}

.clickable-text:focus {
  outline: 0;
  border-bottom: 1px solid#0f75bc;
}

.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}

.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}

.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}

.total-desc {
  background: #deeded;
}
.p-10px {
  padding: 6px !important;
}
</style>

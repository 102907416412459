import { Layout, UserView, AddNewUserView } from "../views/users";
export default {
  path: "/users",
  component: Layout,
  children: [
    { path: "", component: UserView },
    { path: "add-user", component: AddNewUserView },
    { path: "update-user/:id", component: AddNewUserView }
  ]
};

import { insertChar } from "./stringUtils";

export const onlyAcceptDigits = (e, callBack = () => {}) => {
  let key = String.fromCharCode(e.keyCode);
  if (!/^[0-9]*$/.test(key)) {
    e.preventDefault();
  } else {
    callBack();
  }
};
export const onlyAcceptAlphabets = (e) => {
  let key = String.fromCharCode(e.keyCode);
  if (!/^[a-zA-Z ]+$/.test(key)) {
    e.preventDefault();
  }
};

export const onlyAcceptAlphaNumeric = (e) => {
  let key = String.fromCharCode(e.keyCode);
  if (!/^[a-zA-Z0-9 ]+$/.test(key)) {
    e.preventDefault();
  }
};

export const onlyAcceptAlphaNumericUpperCase = (e) => {
  let key = String.fromCharCode(e.keyCode);
  if (!/^[A-Z0-9]+$/.test(key)) {
    e.preventDefault();
  }
};
export const onlyAcceptAlphabetWithSymbol = (e) => {
  let key = String.fromCharCode(e.keyCode);
  console.log(!!Number(key));
  if (
    !/^[a-zA-Z\s~`!@#$%^&*()-_+={}[\]|\\:;"'<>,.?/]*$/.test(key) ||
    !!Number(key) ||
    key === "0"
  ) {
    e.preventDefault();
  }
};

export const IBANumberValidator = (e) => {
  let key = String.fromCharCode(e.keyCode);
  if (!/^[A-Z0-9 ]+$/.test(key)) {
    e.preventDefault();
  }
};
export const validateAmount = (event) => {
  if (event.key === "e" || event.key === "E") {
    event.preventDefault();
  }
};

export const onlyAcceptPhoneChar = (e) => {
  let key = String.fromCharCode(e.keyCode);
  if (!/^[\d()+\- ]+$/.test(key)) {
    e.preventDefault();
  }
};

export const checkAmount = (e, callBack = () => {}) => {
  let value = "";
  const index = e.target.selectionStart;
  const prevValue = e.target.value;
  const currentKey = String.fromCharCode(e.keyCode);
  value = insertChar(prevValue, currentKey, index);
  if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(value)) {
    e.preventDefault();
    return;
  }
  callBack(Number(value));
};

<template>
  <v-autocomplete
    v-model="selectedEntity"
    item-title="entity_name"
    item-value="id"
    :items="selectableData"
    density="compact"
    bg-color="#deeded"
    custom-class="mt-10px"
    :menu-props="{ 'content-class': 'dashboard-search' }"
    :style="{ width: '250px' }"
  />
</template>

<script setup>
import { storeToRefs } from "pinia";
import { onMounted, ref, watch } from "vue";
import { getEntityItems } from "../../../store/entityActions/get";
import { useEntityStore } from "../../../store/entity";
const props = defineProps({
  atEntitySelect: {
    type: Function,
    default: () => {},
  },
});
const selectedEntity = ref("all");
const entityStore = useEntityStore();
const { entities } = storeToRefs(entityStore);
const selectableData = ref([]);

onMounted(async () => {
  if (!entities.value.data.length) {
    await getEntityItems("entities", "/entity");
  }
  const Data = [{ id: "all", entity_name: "All" }];
  for (let i = 0; i < entities.value.data.length; i++) {
    const entity = entities.value.data[i]?.entity[0];
    const entity_name =
      entity?.entity_type == 2
        ? `${entity?.entity_first_name} ${entity?.entity_last_name}`
        : entity?.entity_name;
    const item = {
      id: String(entity?.id),
      entity_name,
    };

    Data.push(item);
  }
  selectableData.value = Data;
});
watch(
  selectedEntity,
  async (newValue) => {
    const Entity = selectableData.value.find((item) => item?.id == newValue);
    props.atEntitySelect(newValue, Entity?.entity_name);
  },
  { deep: true }
);
</script>

<style scoped>
.select {
  width: 163px;
  height: 40px;
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
}
.mt-10px {
  margin-top: 10px;
}
</style>

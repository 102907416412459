<template>
  <LoaderContainer :loading="loading" :error="error">
    <div class="w-100 vh">
      <div class="row g-2">
        <div class="col-lg-12">
          <TitleAndDateVue :show-entity="false" />
          <div class="row">
            <div class="col-lg-12 mt-4">
              <CalculatorContainer />
            </div>
            <div
              :class="`${
                profile.data?.has_subscription === 'N'
                  ? 'col-lg-12'
                  : 'col-lg-12'
              } mt-5`"
            >
              <StatusCards />
              <div
                class="d-flex flex-wrap align-items-center justify-content-between px-3"
              >
                <h4 class="title flex-2 bold mt-4">Filing Planner</h4>
                <div class="flex-1 d-flex align-items-center mt-4">
                  <h4 class="title mr-2 mb-2">Select Entity</h4>
                  <SelectEntity :at-entity-select="setEntity" />
                </div>
              </div>
              <FilingPlanerContainer
                :entity="entity.entity_id"
                :entity-name="entity.entity_name"
              />
              <FederalTax />
            </div>
          </div>
        </div>
        <div class="col-lg-3"></div>
      </div>
    </div>
    <Marquee :texts="data.marquee_text" />
  </LoaderContainer>
</template>

<script setup>
import TitleAndDateVue from "../common/TitleAndDate.vue";
import StatusCards from "./StatusCards.vue";
import FederalTax from "./FederalTax.vue";
import { CalculatorContainer } from "../../calculator";
import { useProfileStore } from "../../../store/profile";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import { useConsultantDashboardStore } from "../../../store/consultantDashboard";
import LoaderContainer from "../../common/LoaderContainer.vue";
import Marquee from "../../common/Marquee.vue";
import FilingPlanerContainer from "../FilingPlaner/FilingPlanerContainer.vue";
import SelectEntity from "../FilingPlaner/SelectEntity.vue";

const profileStore = useProfileStore();
const { profile } = storeToRefs(profileStore);
const consultantDashboard = useConsultantDashboardStore();
const { data, loading, error } = storeToRefs(consultantDashboard);
const entity = ref({
  entity_id: "all",
  entity_name: "",
});
const setEntity = (entity_id, entity_name) => {
  entity.value.entity_id = entity_id;
  entity.value.entity_name = entity_name;
};
onMounted(async () => {
  await consultantDashboard.getDashboardItems();
});
</script>

<style scoped>
.calendar {
  background: #deeded;
  border: 1.22901px solid rgba(116, 113, 113, 0.3);
  box-shadow: 0px 9.83206px 9.83206px rgba(187, 187, 187, 0.25);
  border-radius: 4.91603px;
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.vh {
  min-height: 125vh;
}
</style>

export const downloadEntitySample = {
  bank_details: "https://admin.snaptax.me/public/sample/BankDetails.xlsx",
  contact_details: "https://admin.snaptax.me/public/sample/ContactDetails.xlsx",
  ownership_details:
    "https://admin.snaptax.me/public/sample/OwnershipDetails.xlsx",
  branch_details: "https://admin.snaptax.me/public/sample/BranchDetails.xlsx"
};
export const uploadEntityLinks = {
  branch_details: "/importEntityBranch",
  contact_details: "/importContactDetails",
  ownership_details: "/importOwenership",
  bank_details: "/importBankDetails"
};

<template>
  <SideNavContainer title="Add Entity">
    <EntityChecker>
      <Loader :loading="loading">
        <AddEntityTab active="ownershipDetails" />
        <Progress :active="2" />
        <StatusChecker>
          <form @submit="handleSubmit">
            <div class="row">
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="name"
                  placeholder="Directors Name"
                  label="Directors name *"
                  :error-message="error.name"
                  @keypress="onlyAcceptAlphabets"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <h4 class="label">
                  Resident of UAE <span class="text-danger">*</span>
                </h4>
                <div
                  class="radio-container w-100 d-flex align-items-center pl-2"
                >
                  <input
                    type="radio"
                    value="Y"
                    id=""
                    :error-message="error.is_from_uae"
                    v-model="is_from_uae"
                    class=""
                  />
                  <p class="mb-0 yes">Yes</p>
                  <input
                    type="radio"
                    value="N"
                    name=""
                    v-model="is_from_uae"
                    id=""
                    :error-message="error.is_from_uae"
                    class=""
                  />
                  <p class="mb-0 yes">No</p>
                </div>
                <p v-if="error.is_from_uae" class="text-danger">
                  {{ error.is_from_uae }}
                </p>
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="uae_id"
                  label="Emirates ID Number *"
                  :error-message="error.uae_id"
                  @keypress="checkEmirateId($event, true)"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <h4 class="label">
                  Nationality <span class="text-danger">*</span>
                </h4>
                <v-autocomplete
                  v-model="director_nationality"
                  :items="countries.data"
                  label="Nationality"
                  :error-message="error.director_nationality"
                  item-title="name"
                  item-value="id"
                  bg-color="#DEEDED"
                >
                </v-autocomplete>
                <p v-if="error.director_nationality" class="text-danger">
                  {{ error.director_nationality }}
                </p>
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="director_passport_no"
                  label="Passport No. *"
                  placeholder="Passport No."
                  :error-message="error.director_passport_no"
                  @keypress="onlyAcceptAlphaNumeric"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="mobile_no"
                  :error-message="error.mobile_no"
                  placeholder="Mobile Number"
                  label="Mobile Number with Country Code *"
                  @keypress="checkMobileNumber"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomSelectInput
                  v-model="gender"
                  label="Gender"
                  placeholder="Select Gender"
                  :options="Gender"
                  item-title="name"
                  item-value="name"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="email"
                  placeholder="Email Address"
                  type="email"
                  label="Email"
                  :error-message="error.email"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomDateInput
                  v-model="director_passport_issue_date"
                  type="number"
                  label="Passport Issue Date *"
                  placeholder="Select Date"
                  :error-message="error.director_passport_issue_date"
                  :max="dateNow()"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomDateInput
                  v-model="director_passport_expiry_date"
                  type="number"
                  label="Passport Expiry Date *"
                  :error-message="error.director_passport_expiry_date"
                  placeholder="Select Date"
                  :min="dateNow()"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="address"
                  placeholder="Address"
                  label="Address"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomSelectInput
                  v-model="Designation"
                  label="Designation *"
                  placeholder="Designation"
                  :options="designation.data"
                  :error-message="error.Designation"
                  item-title="name"
                  item-value="id"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomDateInput
                  v-model="appointment_date"
                  type="number"
                  label="Appointment Date *"
                  :error-message="error.appointment_date"
                  placeholder="Select Date"
                  :max="dateNow()"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomDateInput
                  v-model="cessation_date"
                  type="number"
                  label="Cessation Date "
                  placeholders="Cessation Date"
                  :min="dateNow()"
                />
              </div>
              <p class="text-info my-2">{{ message }}</p>
              <div class="col-lg-12">
                <div class="w-100 d-flex align-items-center mt-10 mb-3">
                  <button class="save-next mr-3" type="submit">
                    Save & Next
                  </button>
                  <button
                    class="cancel"
                    type="button"
                    @click="
                      $router.push(`/entity/master-data/${$route.params.id}`)
                    "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </StatusChecker>
      </Loader>
    </EntityChecker>
  </SideNavContainer>
</template>

<script>
import CustomTextInput from "../../../../common/CustomTextInput.vue";
import CustomSelectInput from "@/components/common/CustomSelectInput.vue";
import { CustomDateInput } from "../../../../common";
import { useEntityStore } from "../../../../../store/entity";
import { getEntityItems, getEntityItem } from "@/store/entityActions/get";
import { useCountryStore } from "@/store/country";
import { addEntity } from "../../../../../store/entityActions/post";
import { updateEntity } from "@/store/entityActions/put";
import { storeToRefs } from "pinia";
import Loader from "@/components/common/LoaderContainer.vue";
import { SideNavContainer } from "../../../../layout";
import Progress from "./Progress.vue";
import EntityChecker from "../../EntityChecker.vue";
import { AddEntityTab } from "../../../../common";
import { dateNow } from "../../../../../helpers/date";
import { checkUaeId } from "../../../../../helpers/checkers";
import {
  onlyAcceptAlphaNumeric,
  onlyAcceptAlphabets,
} from "../../../../../helpers/validation";
import StatusChecker from "../../StatusChecker.vue";
import { emailRegex } from "../../../../../constants/regex-format";

export default {
  name: "DirectorDetails",
  components: {
    CustomTextInput,
    CustomSelectInput,
    CustomDateInput,
    Loader,
    SideNavContainer,
    Progress,
    EntityChecker,
    AddEntityTab,
    StatusChecker,
  },
  setup() {
    const entityStore = useEntityStore();
    const { designation, DirectorDetails, ShareholderDetails } =
      storeToRefs(entityStore);
    const countryStore = useCountryStore();
    const { countries } = storeToRefs(countryStore);
    return {
      entityStore,
      designation,
      countries,
      countryStore,
      DirectorDetails,
      ShareholderDetails,
      dateNow,
      onlyAcceptAlphaNumeric,
      onlyAcceptAlphabets,
    };
  },
  data() {
    return {
      owner_ship_id: "",
      name: "",
      mobile_no: "",
      email: "",
      gender: "",
      address: "",
      uae_id: "",
      appointment_date: "",
      cessation_date: "",
      director_nationality: "",
      Designation: "",
      director_passport_no: "",
      director_passport_issue_date: "",
      director_passport_expiry_date: "",
      is_from_uae: "",
      message: "",
      loading: true,
      edit: false,
      Gender: [
        { name: "Male", value: "M" },
        { name: "Female", value: "F" },
        { name: "Others", value: "Others" },
      ],
      error: {
        uae_id: false,
        director_passport_no: "",
        email: false,
        mobile_no: false,
        director_passport_issue_date: "",
        director_passport_expiry_date: "",
        name: "",
        is_from_uae: "",
        director_nationality: "",
        Designation: "",
        appointment_date: "",
      },
    };
  },
  watch: {
    uae_id() {
      this.error.uae_id = false;
    },
    name() {
      this.error.name = false;
    },
    is_from_uae() {
      this.error.is_from_uae = false;
    },
    director_nationality() {
      this.error.director_nationality = false;
    },
    director_passport_no() {
      this.error.director_passport_no = false;
    },

    mobile_no() {
      this.error.mobile_no = false;
    },
    email() {
      this.error.email = false;
    },
    director_passport_issue_date() {
      this.error.director_passport_issue_date = false;
    },
    director_passport_expiry_date() {
      this.error.director_passport_expiry_date = false;
    },
    Designation() {
      this.error.Designation = false;
    },
    appointment_date() {
      this.error.appointment_date = false;
    },
  },
  async created() {
    const id = this.$route.params.sub_id;
    let owner_id = this.$route.query.owner_id;
    if (!this.designation.data.length) {
      await getEntityItems("designation", "/designation");
    }
    if (!this.countries.data.length) {
      await this.countryStore.getCountryList();
    }
    if (id) {
      await this.getSubId();
    } else if (owner_id) {
      this.owner_ship_id = owner_id;
      this.$router.push(
        `/entity/add-entity/director-details/${this.$route.params.id}?owner_id=${owner_id}`
      );
    }
    this.loading = false;
  },
  methods: {
    checkEmirateId(e) {
      let reg = /^[0-9 -]+$/;
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (reg.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    checkMobileNumber(e, containMinus = false) {
      let reg = containMinus ? /^[0-9 +-]+$/ : /^[0-9+]+$/;
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (reg.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    async getSubId() {
      const id = this.$route.params.sub_id;
      await getEntityItem(
        "DirectorDetails",
        "/entityDirectorDetails",
        this.$route.params.id
      );
      const director = this.DirectorDetails?.data?.find(
        (e) => Number(e.id) === Number(id)
      );
      // console.log(director);
      if (director) {
        //await getEntityItem("ownershipDetails", "/entityOwnerDetails", id);
        this.edit = true;
        this.name = director.name;
        this.owner_ship_id = this.$route.query.owner_id
          ? this.$route.query.owner_id
          : director.owner_ship_id
          ? director.owner_ship_id
          : "";
        // this.owner_ship_id = this.$route.query.owner_id
        // ? this.$route.query.owner_id
        // : this.ownershipDetails?.data[0]?.id
        // ? this.ownershipDetails?.data[0]?.id
        // : ShareholderDetails?.owner_ship_id;
        this.mobile_no = director?.mobile_no;
        this.uae_id = director?.uae_id;
        this.email = director?.email;
        this.gender = director?.gender;
        this.address = director?.address;
        this.appointment_date = director?.appointment_date;
        this.cessation_date = director?.cessation_date;
        this.director_nationality = director?.nationality;
        this.Designation = director?.designation
          ? Number(director?.designation)
          : "";
        this.director_passport_no = director?.passport_no;
        this.director_passport_issue_date =
          director?.director_passport_issue_date;
        this.director_passport_expiry_date = director.passport_expiry_date;
        this.is_from_uae = director.is_from_uae;
        this.$router.push(
          `/entity/add-entity/director-details/${this.$route.params.id}/${id}?owner_id=${this.owner_ship_id}`
        );
      }
    },
    async handleSubmit(e) {
      e.preventDefault();
      const sub_id = this.$route.params.sub_id;
      const readyData = {
        entity_id: this.$route.params.id,
        owner_ship_id: this.owner_ship_id,
        name: this.name,
        mobile_no: this.mobile_no,
        uae_id: this.uae_id,
        email: this.email,
        gender: this.gender,
        address: this.address,
        appointment_date: this.appointment_date,
        cessation_date: this.cessation_date,
        nationality: this.director_nationality,
        designation: this.Designation,
        passport_no: this.director_passport_no,
        director_passport_issue_date: this.director_passport_issue_date,
        passport_expiry_date: this.director_passport_expiry_date,
        is_from_uae: this.is_from_uae,
      };
      if (!this.uae_id) {
        this.error.uae_id = "Field is required!";
        return;
      }
      if (!checkUaeId(this.uae_id) && this.uae_id) {
        this.error.uae_id = "Invalid uae id format";
        return;
      }
      if (String(this.director_passport_no).length > 12) {
        this.error.director_passport_no =
          "Passport number is should be less than 12 characters";
        return;
      }
      if (this.email && !emailRegex.test(this.email)) {
        this.error.email = "Invalid Email";
        return;
      }
      if (
        !(
          String(this.mobile_no).length <= 13 &&
          String(this.mobile_no).length >= 8
        ) &&
        !!this.mobile_no
      ) {
        this.error.mobile_no =
          "Invalid Mobile Number, Mobile number should be between 8 to 13 digits ";
        return;
      }
      if (!this.name) {
        this.error.name = "Field is required!";
        return;
      }
      if (!this.is_from_uae) {
        this.error.is_from_uae = "Field is required!";

        return;
      }

      if (!this.director_nationality) {
        this.error.director_nationality = "Field is required!";

        return;
      }
      if (!this.director_passport_no) {
        this.error.director_passport_no = "Field is required!";

        return;
      }
      if (!this.mobile_no) {
        this.error.mobile_no = "Field is required!";

        return;
      }
      if (!this.director_passport_issue_date) {
        this.error.director_passport_issue_date = "Field is required!";

        return;
      }
      if (!this.director_passport_expiry_date) {
        this.error.director_passport_expiry_date = "Field is required!";

        return;
      }
      if (!this.Designation) {
        this.error.Designation = "Field is required!";

        return;
      }
      if (!this.appointment_date) {
        this.error.appointment_date = "Field is required!";

        return;
      }
      if (sub_id) {
        await updateEntity(
          "/entityDirectorDetails",
          {
            entity_director_id: this.$route.params.sub_id,
            ...readyData,
          },
          this.setMessage,
          () => this.pushToShareHolders()
        );
      } else if (this.$route.query.owner_id) {
        await addEntity(
          "/entityDirectorDetails",
          readyData,
          this.setMessage,
          () => {
            setTimeout(() => {
              this.$router.push(
                `/entity/add-entity/shareholder-details/${this.$route.params.id}?owner_id=${this.$route.query.owner_id}`
              );
            }, 2000);
          }
        );
      }
    },
    setMessage(message) {
      this.message = message;
    },
    async pushToShareHolders() {
      await getEntityItem(
        "ShareholderDetails",
        "/entityShareholderDetails",
        this.$route.params.id
      );
      if (this.ShareholderDetails.data.length > 1) {
        this.$router.push(`/entity/ownership-details/${this.$route.params.id}`);
      } else if (this.ShareholderDetails.data.length === 1) {
        this.$router.push(
          `/entity/add-entity/shareholder-details/${this.$route.params.id}/${this.ShareholderDetails.data[0]?.id}`
        );
      } else if (this.$route.query.owner_id) {
        this.$router.push(
          `/entity/add-entity/shareholder-details/${this.$route.params.id}?owner_id=${this.$route.query.owner_id}`
        );
      }
    },
  },
};
</script>

<style scoped>
.save-next {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #fafbff;
  width: 196px;
  background: #0f75bc;
  height: 55px;
}
.cancel {
  background: #ffffff;
  border: 1px solid #747171;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #747171;
  height: 55px;
  width: 196px;
}
.custom-input {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  height: 48px;
  padding: 13px 10px;
}
.custom-input.error {
  border: 1px solid red;
}
.custom-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
  text-align: start;
}
.custom-input:focus {
  outline: 0;
}
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.radio-container {
  height: 56px;
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  gap: 11px;
}
</style>

<template>
  <div class="w-100">
    <div
      class="w-100 wrapper px-2 d-flex align-items-center justify-content-between"
    >
      <h3
        class="progress-counter active mb-0"
        @click="handleTab('ownership-details')"
      >
        1
      </h3>
      <h3
        :class="`${
          active >= 2
            ? 'progress-counter active'
            : active === 1 || !active
            ? 'progress-counter next'
            : 'progress-counter'
        }   mb-0`"
        @click="handleTab('director-details')"
      >
        2
      </h3>
      <h3
        :class="`${
          active >= 3
            ? 'progress-counter active'
            : active === 2
            ? 'progress-counter next'
            : 'progress-counter'
        }   mb-0`"
        @click="handleTab('shareholder-details')"
      >
        3
      </h3>
    </div>
    <hr />
    <div
      class="tab-title-container d-flex align-items-center justify-content-between"
    >
      <h3 class="tab-title cursor-pointer">Manager Details</h3>
      <h3 class="tab-title cursor-pointer ml-10">Director Details</h3>
      <h3 class="tab-title cursor-pointer">Shareholders Details</h3>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useEntityStore } from "../../../../../store/entity";
import { storeToRefs } from "pinia";
import { getEntityItem } from "../../../../../store/entityActions/get";
export default {
  name: "AddEntityProgress",
  props: { active: Number },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const entityStore = useEntityStore();
    const { entity, designation, DirectorDetails, ShareholderDetails } =
      storeToRefs(entityStore);
    const handleTab = async (tab) => {
      const owner_id = route.query.owner_id;
      if (tab === "ownership-details") {
        router.push(
          `/entity/add-entity/${tab}/${route.params.id}${
            owner_id ? "?owner_id=" + owner_id : ""
          }`
        );
      } else if (tab === "director-details") {
        await getEntityItem(
          "DirectorDetails",
          "/entityDirectorDetails",
          route.params.id
        );
        if (DirectorDetails.value.data.length > 1) {
          router.push(`/entity/ownership-details/${route.params.id}`);
        } else if (DirectorDetails.value.data.length === 1) {
          router.push(
            `/entity/add-entity/${tab}/${route.params.id}/${
              DirectorDetails.value.data[0]?.id
            }${owner_id ? "?owner_id=" + owner_id : ""}`
          );
        } else if (owner_id) {
          router.push(
            `/entity/add-entity/${tab}/${route.params.id}?owner_id=${owner_id}`
          );
        }
      } else if (tab === "shareholder-details") {
        await getEntityItem(
          "ShareholderDetails",
          "/entityShareholderDetails",
          route.params.id
        );
        if (ShareholderDetails.value.data.length > 1) {
          router.push(`/entity/ownership-details/${route.params.id}`);
        } else if (ShareholderDetails.value.data.length === 1) {
          router.push(
            `/entity/add-entity/${tab}/${route.params.id}/${
              ShareholderDetails.value.data[0]?.id
            }${owner_id ? "?owner_id=" + owner_id : ""}`
          );
        } else if (owner_id) {
          router.push(
            `/entity/add-entity/${tab}/${route.params.id}?owner_id=${owner_id}`
          );
        }
      }
    };
    return { handleTab };
  }
};
</script>

<style scoped>
.wrapper {
  height: 94px;
}
hr {
  margin-top: -45px;
  border: 1px solid #000000;
  margin-left: 30px;
  margin-right: 30px;
}
.progress-counter {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  background-color: #fafbff;
  border-radius: 42px;
  color: #747171;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  cursor: pointer;
}
.progress-counter.active {
  background: #00a7a7;
  color: #fafbff;
}
.progress-counter.next {
  background: #747171;
  color: #fafbff;
}
.tab-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #000000;
}
.tab-title-container {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
}
</style>

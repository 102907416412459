<template>
  <Loader v-if="loading" />
  <div v-else class="w-100">
    <ShowStatus :modal="editStatus" :set-modal="setStatus" />
    <CustomTable
      :Data="data"
      :Headers="headers"
      :haveIndex="true"
      :actions="actions"
    />
  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable.vue";
import { storeToRefs } from "pinia";
import { ref, onMounted } from "vue";
import { useEntityStore } from "../../../../store/entity";
import {
  getEntityItem,
  getEntityAttribute,
} from "../../../../store/entityActions/get";
import { useRoute, useRouter } from "vue-router";
import { useCountryStore } from "../../../../store/country";
import { Loader } from "@/components/common";
import { generate } from "randomized-string";
import tableEdit from "@/assets/svg/table-edit.svg";
import tableDelete from "../../../../assets/svg/trash.svg";
import { deleteEntity } from "../../../../store/entityActions/delete";
import { dateMDY } from "../../../../helpers/date";
import ShowStatus from "./ShowStatus.vue";

export default {
  name: "ListOfShareHolder",
  components: { CustomTable, Loader, ShowStatus },
  setup() {
    const headers = ref([
      "Type",
      "Name",
      "Resident of UAE",
      "Emirates ID Number",
      "Nationality",
      "Passport Number",
      "Mobile Number",
      "Gender",
      "Email",
      "Passport Issue Date",
      "Passport Expiry Date",
      "Share%",
      "Registered Date",
    ]);

    const data = ref([]);
    const loading = ref(true);
    const route = useRoute();
    const router = useRouter();
    const entityStore = useEntityStore();
    const countryStore = useCountryStore();
    const { ShareholderDetails, ownershipDetails, entity } =
      storeToRefs(entityStore);

    onMounted(async () => {
      await getEntityItem(
        "ShareholderDetails",
        "/entityShareholderDetails",
        route?.params.id
      );

      const Data = [];

      for (let i = 0; i < ShareholderDetails.value.data.length; i++) {
        const details = ShareholderDetails.value.data[i];
        const designationName = await getEntityAttribute(
          "designation",
          "/designation",
          details?.designation
        );
        const countryName = await countryStore.getCountryName(
          details?.nationality
        );
        const items = {
          id: details?.id,
          entity_type:
            details?.entity_type === 1
              ? "Legal Person"
              : details?.entity_type === 2
              ? "Natural Person"
              : "",
          name: details?.name,
          is_from_uae: details?.is_from_uae,
          uae_id: details?.uae_id,
          nationality: countryName,
          passport_no: details?.passport_no,
          mobile_no: details?.mobile_no,
          gender: details?.gender,
          email: details?.email,
          passport_issue_date: dateMDY(details?.passport_issue_date),
          passport_expiry_date: dateMDY(details?.passport_expiry_date),
          share_percent: details?.share_percent
            ? details?.share_percent.toString()
            : "",
          registration_date: dateMDY(details?.registration_date),
        };
        Data.push(items);
      }

      data.value = Data;
      loading.value = false;
    });
    const editStatus = ref(false);
    const setStatus = (status) => {
      editStatus.value = status;
    };
    const actions = ref([
      {
        id: generate(),
        icon: tableEdit,
        onClick: async (id) => {
          if (entity.value?.data?.entity[0]?.file_return_status === "Filed") {
            setStatus(true);
            return;
          }
          await getEntityItem(
            "ownershipDetails",
            "/entityOwnerDetails",
            route.params.id
          );
          let shareholder = ShareholderDetails.value.data.find(
            (director) => director.id == id
          );
          let owner_id = shareholder?.ownership_id;
          router.push(
            `/entity/add-entity/shareholder-details/${route.params.id}/${id}?owner_id=${owner_id}`
          );
        },
      },
      {
        id: generate(),
        icon: tableDelete,
        onClick: async (id) => {
          if (
            confirm("Are you sure you want to delete this shareholder detail?")
          ) {
            await deleteEntity(
              id,
              "/entityShareholderDetails",
              (message) => {},
              () => location.reload()
            );
          }
        },
      },
    ]);
    return { headers, data, loading, actions, editStatus, setStatus };
  },
};
</script>

<style scoped>
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

<template>
  <SideNavContainer
    :TitleRightSide="FinancialDataDateFilter"
    title="Financial Data"
    sideNavType="financialData"
    :subtitle="name"
  >
    <IFRSCheckerContainer :have-loader="false" :check-status="true">
      <ProfitLossBreadCrumb :ifrs="data" :entity-name="name" />
      <Business :view="true" />
      <div
        class="mt-3 mb-3 d-flex w-100 align-items-center justify-content-between"
      >
        <h4 class="balance-sheet-title">Profit & Loss View</h4>
        <button class="btn-download" @click="downloadFile">
          <img src="@/assets/svg/download.svg" class="mr-2" alt="" />
          Download
        </button>
      </div>
      <LoaderContainer
        v-if="data === 'IFRS'"
        :loading="IncomeLoading || expenditureLoading || financialTypeLoading"
        :error="businessError"
      >
        <div class="w-100">
          <h4 class="my-3 balance-sheet-type">Income</h4>
          <Sales :view="true" />
          <OtherOperatingRevenue :view="true" />
          <CostOfSales :view="true" />
          <h4 class="my-5 balance-sheet-type">Expenditure</h4>
          <PBTCalculations :view="true" />
          <DistributionExpenses :view="true" />
          <MarketingExpenses :view="true" />
          <OccupancyExpenses :view="true" />
          <AdministrationExpenses :view="true" />
          <OtherExpenses :view="true" />
          <Ebitda />
          <FinanceCosts />
          <ReclassifiedPL :view="true" />
          <LossesOrGains :view="true" />
          <TotalExpenditure :view="true" />
        </div>
      </LoaderContainer>
      <Plcontainer v-if="data === 'Non-IFRS'" />
    </IFRSCheckerContainer>
  </SideNavContainer>
</template>

<script setup>
import { SideNavContainer } from "@/components/layout";
import FinancialDataDateFilter from "../../DateHandler/ProfitAndLoss/ViewDatehandler.vue";
import CostOfSales from "../Income/CostOfSales.vue";
import PBTCalculations from "../Expenditure/PBTcalculations.vue";
import DistributionExpenses from "../Expenditure/DistributionExpenses.vue";
import MarketingExpenses from "../Expenditure/MarketingExpenses.vue";
import OccupancyExpenses from "../Expenditure/OccupancyExpenses.vue";
import AdministrationExpenses from "../Expenditure/AdministrationExpenses.vue";
import FinanceCosts from "../Expenditure/FinanceCosts.vue";
import ReclassifiedPL from "../Expenditure/ReclassifiedPL.vue";
import LossesOrGains from "../Expenditure/LossesOrGains.vue";
import TotalExpenditure from "../Expenditure/TotalExpenditure.vue";
import OtherExpenses from "../Expenditure/OtherExpenses.vue";
import { getEntityItem } from "../../../../../store/entityActions/get";
import { useEntityStore } from "../../../../../store/entity";
import { storeToRefs } from "pinia";
import Business from "../../BusinessHandler/ProfitAndLoss/ViewBusinessHandler.vue";
import { useProfitAndLossStore } from "../../../../../store/ProfitAdLoss";
import { useRoute } from "vue-router";
import { ProfitLossBreadCrumb } from "../../../../BreadCrumbs";
import { LoaderContainer } from "../../../../common";
import { computed, onMounted } from "vue";
import { useIFRSStore } from "../../../../../store/NonIFRS/IFRS.store";
import Plcontainer from "../../NON-IFRS/ProfitAndLoss/Container";
import IFRSCheckerContainer from "../../IFRSCheckerContainer.vue";
import { useExpenditureStore } from "../../../../../store/expenditure";
import { useBusinessStore } from "../../../../../store/buisness.store";
import Sales from "../Income/Sales.vue";
import OtherOperatingRevenue from "../Income/OtherOperatingRevenue.vue";
import Ebitda from "../Expenditure/Ebitda.vue";

const ifrStore = useIFRSStore();
const { data, loading: financialTypeLoading } = storeToRefs(ifrStore);
const entityStore = useEntityStore();
const businessStore = useBusinessStore();
const profitLossStore = useProfitAndLossStore();
const expenditureStore = useExpenditureStore();
const route = useRoute();
const { entity } = storeToRefs(entityStore);
const profitAndLossStore = useProfitAndLossStore();
const { loading: IncomeLoading } = storeToRefs(profitLossStore);
const { loading: expenditureLoading } = storeToRefs(expenditureStore);
const { error: businessError } = storeToRefs(businessStore);
const downloadFile = async () => {
  await profitAndLossStore.saveProfitAndLossFile(
    route.params.id,
    route.query.financial_year,
    route.query.financial_period,
    route.query.business,
    () => {}
  );
};
const name = computed(() => {
  if (!entity.value.data) return "";
  const Entity = entity.value.data?.entity[0];
  if (Entity?.entity_type == 2) {
    return `${Entity?.entity_first_name} ${Entity?.entity_last_name}`;
  }
  return Entity?.entity_name;
});
onMounted(async () => {
  if (!entity.value.data) {
    const id = route.params.id;
    await getEntityItem("entity", "/entity", id);
  }
  IncomeLoading.value = true;
  expenditureLoading.value = true;
});
</script>

<style scoped>
.balance-sheet-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.055em;
  color: #000000;
}
.btn-download {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 141px;
  height: 44px;
  background: #0f75bc;
  color: #fafbff;
  border-radius: 4px;
}
.balance-sheet-type {
  font-style: normal;
  font-weight: 400;
  font-size: 25.888px;
  line-height: 39px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

<template>
  <div class="col-lg-6">
    <Chapter7AdditionPopup
      :open="open"
      :set-open="setModal"
      :model="model"
      type="addition_item"
    />
    <div class="header d-flex align-items-center w-100 justify-content-between">
      <p class="header-title mb-0">Addition in Taxable Income</p>
      <p class="header-title mb-0">Amount</p>
    </div>
    <div
      v-for="(item, key, index) in chapter7.addition_description"
      :key="key"
      :class="`w-100 table-body ${
        !index || !(index % 2) ? 'white' : 'gray'
      } d-flex align-items-center justify-content-between`"
    >
      <p class="mb-0 body-title">
        {{ item }}
      </p>
      <div class="d-flex align-items-center">
        <p
          class="mb-0 body-title mr-3 cursor-pointer"
          @click="setModal(true, key)"
        >
          {{ NumberFormatter(getValue(key)) }}
        </p>
      </div>
    </div>
    <div
      v-if="$route.query.current_business === 'master'"
      class="w-100 table-body gray d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 body-title">Additional</p>
      <div class="d-flex align-items-center">
        <p class="mb-0 body-title mr-3 cursor-pointer">
          {{ NumberFormatter(chapter7.master_data.additional_data.addition) }}
        </p>
      </div>
    </div>
    <EditableSheet
      v-if="$route.query.current_business !== 'master'"
      v-model="chapter7.additional_data.addition.data"
      v-model:total="chapter7.additional_data.addition.total"
    />
    <div
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 total-amount">Total Addition</p>
      <div class="d-flex align-items-center">
        <p
          :class="`mb-0 total-amount mr-3 ${
            $route.query.current_business === 'master' ? '' : 'cursor-pointer'
          }`"
          @click="setOpen(true)"
        >
          {{ NumberFormatter(chapter7TotalAddition($route)) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { NumberFormatter } from "../../../../helpers/numberFormatter";
import { useComputeIncomeTaxStore } from "../../../../store/incometax.store";
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useStatusStore } from "../../../../store/statusStore";
import { EditableSheet } from "../../../common";
import Chapter7AdditionPopup from "./Chapter7Popup.vue";

const computeIncomeTaxStore = useComputeIncomeTaxStore();
const { chapter7TotalAddition, chapter7 } = storeToRefs(computeIncomeTaxStore);
const route = useRoute();
const open = ref(false);
const model = ref("");
const statusStore = useStatusStore();
const { status } = storeToRefs(statusStore);

const setOpen = (value) => {
  if (route.query.current_business === "master" || status.value) {
    return;
  }
  open.value = value;
};

const setModal = (value, key) => {
  if (route.query.current_business === "master" || status.value) {
    return;
  }
  if (!key) {
    open.value = value;
  } else {
    model.value = key;
    open.value = value;
  }
};
const getValue = (key) => {
  if (route.query.current_business === "master") {
    return Number(chapter7.value.addition_item[key] || "");
  }
  return Number(chapter7.value.addition_item[key].total || "");
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: white;
}
.table-body {
  min-height: 80px;
  padding-right: 88px;
  padding-left: 26px;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 30px;
}
.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}
.total-container {
  height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}
.total-desc {
  background: #deeded;
}
.reference {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.article-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px; /*
identical to box height, or 185% */
  letter-spacing: 0.055em;
  color: #000000;
}

.white {
  background: #fafbff;
}
.gray {
  background: #deeded;
}
.btn-submit {
  width: 82px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
.body-title.disabled {
  color: #747171 !important;
}
.total-amount.disabled {
  color: #747171 !important;
}
</style>

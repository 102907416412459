export const getMonthNumber = (monthName) => {
  if (monthName == "Jan" || monthName == "jan") {
    return "01";
  } else if (monthName == "Feb" || monthName == "feb") {
    return "02";
  } else if (monthName == "Mar" || monthName == "mar") {
    return "03";
  } else if (monthName == "Apr" || monthName == "apr") {
    return "04";
  } else if (monthName == "may" || monthName == "May") {
    return "05";
  } else if (monthName == "Jun" || monthName == "jun") {
    return "06";
  } else if (monthName == "Jul" || monthName == "Jul") {
    return "07";
  } else if (monthName == "Aug" || monthName == "aug") {
    return "08";
  } else if (monthName == "Sep" || monthName == "sep") {
    return "09";
  } else if (monthName == "Oct" || monthName == "oct") {
    return 10;
  } else if (monthName == "Nov" || monthName == "nov") {
    return 11;
  } else if (monthName == "Dec" || monthName == "dec") {
    return 12;
  }
};

export const formatBetweenDuration = (financialPeriod, financialYear) => {
  if (!financialPeriod && !financialYear) {
    return { initialDate: "", finalDate: "" };
  }
  const months = financialPeriod.split("-");
  const years = financialYear.split("-");
  const iDate = new Date(years[0], getMonthNumber(months[0]), "01");
  const fDate = new Date(years[1], getMonthNumber(months[1]), "01");
  const initialDate = `${iDate.getFullYear()}-${
    iDate.getMonth() < 10 ? "0" + iDate.getMonth() : iDate.getMonth()
  }-${iDate.getDate() < 10 ? "0" + iDate.getDate() : iDate.getDate()}`;
  const finalDate = `${fDate.getFullYear()}-${
    fDate.getMonth() < 10 ? "0" + fDate.getMonth() : fDate.getMonth()
  }-${fDate.getDate() < 10 ? "0" + fDate.getDate() : fDate.getDate()}`;
  return { initialDate, finalDate };
};

export const dateNow = () => {
  let today = new Date().toISOString().split("T")[0];
  return today;
};

export const dateMDY = (date) => {
  if (!date || date === "01/01/1970" || date === "1970-01-01") {
    return "";
  }
  let newDate = new Date(date);
  if (
    !(newDate.getMonth() + 1) ||
    !newDate.getDate() ||
    !newDate.getFullYear()
  ) {
    return "";
  }
  let month = newDate.getMonth() + 1;
  let day = newDate.getDate();
  let year = newDate.getFullYear();
  return `${month}/${day}/${year}`;
};

export const mdyToYMD = (date) => {
  if (!date) {
    return "";
  }
  let newDate = date.split("/");
  return `${newDate[2]}-${newDate[0]}-${newDate[1]}`;
};

export const getNumberOfDaysBetweenDates = (start_date, end_date) => {
  const startDate = new Date(start_date);
  const endDate = new Date(end_date);
  // Convert the start and end dates to milliseconds
  const startDateInMs = startDate.getTime();
  const endDateInMs = endDate.getTime();

  // Calculate the difference in milliseconds between the two dates
  const differenceInMs = endDateInMs - startDateInMs;

  // Divide the difference in milliseconds by the number of milliseconds in a day
  const numberOfDays = differenceInMs / (1000 * 60 * 60 * 24);

  // Round the number of days to the nearest integer
  return Math.round(numberOfDays);
};

export const setNotificationMessage = (message) => {
  const m = message.toString().split(" ");
  const date = m[m.length - 1];
  const utcDate = new Date(date).toUTCString().slice(0, 16);
  return `${m.slice(0, m.length - 1).join(" ")} ${utcDate}`;
};

export const getDurationInDays = (endDate, startDate) => {
  // Initialize two Date objects with the start and end dates.
  const start = startDate ? new Date(startDate) : new Date();
  const end = new Date(endDate);

  // Get the difference in milliseconds between the two dates.
  const differenceInMilliseconds = end.getTime() - start.getTime();

  // Divide the difference in milliseconds by the number of milliseconds in a day.
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  // Return the number of days.
  return Math.floor(differenceInDays);
};

export function isDateBetweenDates(date, startDate, endDate) {
  // Convert the dates to Date objects.
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);
  startDateObj.setHours(0);
  startDateObj.setMinutes(0);
  endDateObj.setHours(23);
  endDateObj.setMinutes(59);
  if (
    date.toUTCString().slice(0, 16) ==
      startDateObj.toUTCString().slice(0, 16) &&
    date.toUTCString().slice(0, 16) == endDateObj.toUTCString().slice(0, 16)
  ) {
    return true;
  }
  // Check if the date is greater than the start date and less than the end date.
  return date >= startDateObj && date <= endDateObj;
}

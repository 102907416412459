import { Layout, ProfileView, EditProfileView } from "@/views/profile";
import { ChangePassword } from "../components/Profile";
export default {
  path: "/profile",
  component: Layout,
  children: [
    { path: "", component: ProfileView },
    { path: "edit-profile", component: EditProfileView },
    { path: "change-password", component: ChangePassword }
  ]
};

<template>
  <div id="mySidenav" class="sidenav">
    <div class="w-100 d-flex flex-column justify-content-between">
      <div class="d-flex flex-column align-items-center upper-dashboard">
        <img src="@/assets/svg/Snaptax_logo_mini.svg" alt="" />
        <div
          class="dashboard-items w-100 d-flex flex-column align-items-center"
        >
          <router-link
            v-for="navitem in navigation"
            :key="navitem.id"
            :to="navitem.link"
            :class="`${
              navitem.link.includes(routerName)
                ? routerName === '/'
                  ? navitem.link === '/'
                    ? 'active'
                    : ''
                  : 'active'
                : ''
            } side-nav-item`"
          >
            <img
              :src="`${navitem.icon ? navitem.icon : UserIcon}`"
              class="text-center"
              alt=""
            />
            <p class="mb-0 side-nav-text text-center">
              {{ navitem.name }}
            </p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { onMounted, ref, watch } from "vue";
import UserIcon from "@/assets/svg/user_light.svg";
import {
  getFinancialRoute,
  mainNavigation,
  subUseNavigation,
} from "../../constants/navigation";
import { useProfileStore } from "../../store/profile";
import { storeToRefs } from "pinia";
const props = defineProps({ sideNavType: String });
const profileStore = useProfileStore();
const { profile } = storeToRefs(profileStore);
const route = useRoute();
const id = route?.params?.id;
const navigation = ref([]);
const financialRoutes =
  "/" + route.path.split("/")[1] + "/" + route.path.split("/")[2];
const normalRoutes = "/" + route.path.split("/")[1];
const routerName = ref(
  props?.sideNavType === "financialData" ? financialRoutes : normalRoutes
);
onMounted(() => {
  navigation.value =
    props?.sideNavType === "financialData"
      ? getFinancialRoute(id, route.query)
      : !Number(profile.value.data?.parent_id)
      ? mainNavigation
      : subUseNavigation;
});
watch(
  () => profile.value.data,
  (newValue) => {
    navigation.value =
      props?.sideNavType === "financialData"
        ? getFinancialRoute(id, route.query)
        : !Number(newValue?.parent_id)
        ? mainNavigation
        : subUseNavigation;
  },
  { deep: true }
);
watch(
  () => route.query,
  (newValue) => {
    navigation.value =
      props?.sideNavType === "financialData"
        ? getFinancialRoute(id, newValue)
        : !Number(profile.value.data?.parent_id)
        ? mainNavigation
        : subUseNavigation;
  },
  { deep: true }
);
</script>

<style scoped>
.sidenav {
  height: 125vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: linear-gradient(153.14deg, #101432 1.59%, #00a7a7 100%);
  overflow-x: hidden;
  transition: 0.5s;
  padding: 42px 15px 26px 25px;
  width: 150px;
  padding-top: 45px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.sidenav::-webkit-scrollbar {
  display: none;
}
.dashboard-items {
  gap: 45px;
  margin-top: 45px;
}
.side-nav-item:hover {
  background: #0f75bc;
  display: flex !important;
  flex-direction: column !important;
  align-items: "center";
  border-radius: 4px;
  color: #fafbff;
  padding: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.side-nav-item.active {
  display: flex !important;
  flex-direction: column !important;
  align-items: "center";
  background: #0f75bc;
  border-radius: 4px;
  color: #fafbff;
  padding: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.side-nav-text {
  display: none;
}
.side-nav-item:hover > .side-nav-text {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #b0e1eb;
}
.side-nav-item.active > .side-nav-text {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #b0e1eb;
}
</style>

<template>
    <div>
        <router-link to="/calculator/qfz-calculator" class="box mb-2">
            <div class="d-flex align-items-center">
                <img src="@/assets/images/calicon11.png" class="img" alt="" />
                <h6 class="title bold flex-3">Free Zone Person Calculator</h6>
            </div>
        </router-link>
        <router-link to="/calculator/ctax-calculator-business" class="box  mb-2">
            <div class=" d-flex align-items-center justify-content-start">
                <img src="@/assets/images/caliicon22.png" class="img" alt="" />
                <h6 class="title bold flex-3">Business Corporate Tax Calculator</h6>
            </div>
        </router-link>
        <router-link to="/calculator/ctax-calculator-individual" class="box  mb-2">
            <div class="d-flex align-items-center justify-content-start">
                <img src="@/assets/images/calicon32.png" class="img" alt="" />
                <h6 class="title bold flex-3">Individual Corporate Tax Calculator</h6>
            </div>
        </router-link>
    </div>
</template>

<script setup></script>

<style scoped>
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.055em;
  margin-bottom: 0px;
  
}
.box {
  /*padding-left: 27px;
  padding-top: 20px;
  padding-bottom: 20px;
   gap: 25px; */
  background: #fff;
  /*border: 1px solid rgba(116, 113, 113, 0.3);*/
  box-shadow: 8px 8px 8px 8px rgba(187, 187, 187, 1.25);
  border-radius: 57px;
  /* height: 100px;
  overflow: hidden; */
  display: block;
  padding: 15px 10px;
  color: #2a9ed8;
}

.router-link-active{
    background-color: #2a9ed8;
    color: #fff;
}

.flex-3 {
  flex: 3;
}

.img {
  height: auto;
  width: auto;
}
@media screen and (max-width:900px) {
  .box {
    width: 100%;
  }
}
</style>
<template>
  <form
    :id="model"
    :class="`w-100 table-body py-4  ${
      sub ? 'sub' : ''
    } ${bgColor} d-flex align-items-center justify-content-between`"
  >
    <h5 v-if="makeTextBold" class="mb-0 w-75 body-title bold">
      <b>{{ name }}</b>
    </h5>
    <p v-if="!makeTextBold" class="mb-0 w-75 body-title">
      {{ name }}
    </p>
    <s></s>
    <h5
      v-if="!open && makeTextBold"
      :class="`body-title   mb-0 bold`"
      :style="{ color: amountColor }"
      @click="openCloseInput(true)"
    >
      <b> {{ NumberFormatter(value) }}</b>
    </h5>
    <p
      v-if="!open && !makeTextBold"
      :class="`body-title ${disabled ? '' : 'cursor-pointer'} mb-0`"
      :style="{ color: amountColor }"
      @click="openCloseInput(true)"
    >
      {{ NumberFormatter(value) }}
    </p>
    <input
      :id="'input-' + model"
      v-model="value"
      :hidden="!open"
      type="number"
      class="clickable-text"
      v-bind="inputProps"
      @blur="handleBlur(false)"
      @keydown.tab.enter="(e) => handleTabEnter(e)"
      @keypress="validateAmount"
    />
  </form>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { NumberFormatter } from "../../helpers/numberFormatter";
import { validateAmount } from "../../helpers/validation";
import { useStatusStore } from "../../store/statusStore";

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: "",
  },
  model: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
  showInput: {
    type: Boolean,
    default: false,
  },
  sub: {
    type: Boolean,
    default: false,
  },
  bgColor: {
    type: String,
    default: "white",
  },
  onTabAndEnter: {
    type: Function,
    default: () => {},
  },
  makeTextBold: {
    type: Boolean,
    default: false,
  },
  inputProps: {
    type: Object,
    default: {},
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  onClick: {
    type: [Function, Boolean],
    default: false,
  },
  amountColor: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue"]);
const open = ref(props.showInput);
const route = useRoute();

const statusStore = useStatusStore();
const { status } = storeToRefs(statusStore);

const openCloseInput = async (value) => {
  if (props.onClick) {
    props.onClick();
    return;
  }
  if (props.disabled) {
    return;
  }
  if (status.value || route.query.business === "master") {
    return;
  }
  if (window.location.pathname.includes("view")) {
    return;
  }
  open.value = await value;
  const inputField = await document.getElementById(`input-${props.model}`);
  if (value && inputField) {
    inputField.hidden = await false;
    await inputField.focus();
  }
};
const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
const handleTabEnter = async (e) => {
  e.preventDefault();
  await openCloseInput(false);
  await props.onTabAndEnter(props.model, open.value);
};
const handleBlur = async (value) => {
  await openCloseInput(value);
  if (props.inputProps.onblur) {
    await props.inputProps.onblur(props.model);
  }
};
watch(
  () => props.showInput,
  async (newVal) => {
    await openCloseInput(newVal);
  },
  { deep: true }
);
</script>

<style scoped>
.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
  border-bottom: 1px solid rgba(116, 113, 113, 0.3);
}
.table-body.sub {
  padding-left: 46px !important;
}
.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}
.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}
@media screen and (max-width: 600px) {
  .table-body.sub {
    padding-left: 16px !important;
  }
}
</style>

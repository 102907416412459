<template>
  <div class="d-flex align-items-center">
    <CustomModalContainer :open="open" :set-open="setOpen">
      <div class="w-100 table-responsive mt-4 mb-0">
        <table class="table custom-table mb-0 table-bordered table-striped">
          <thead>
            <tr>
              <th class="table-header">Particulars</th>
              <th class="table-header amount">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="table-rows">
                <p class="data-text mb-0">Gross Total Income</p>
              </td>
              <td class="table-rows">
                <p class="data-text">
                  {{ NumberFormatter(taxableIncome) }}
                </p>
              </td>
            </tr>
            <tr>
              <td class="table-rows">
                <p class="data-text mb-0">Exemption</p>
              </td>
              <td class="table-rows">
                <p class="data-text">
                  {{ NumberFormatter(exemption) }}
                </p>
              </td>
            </tr>
            <tr>
              <td class="table-rows">
                <p class="data-text mb-0">Net Taxable Income</p>
              </td>
              <td class="table-rows">
                <p class="data-text">
                  {{ NumberFormatter(taxableIncome - exemption) }} , [{{
                    NumberFormatter(taxableIncome)
                  }}- {{ NumberFormatter(exemption) }}]
                </p>
              </td>
            </tr>
            <tr>
              <td class="table-rows">
                <p class="data-text mb-0">
                  Tax Liability @9% on Net Taxable Income
                </p>
              </td>
              <td class="table-rows">
                <p class="data-text">
                  {{ NumberFormatter((taxableIncome - exemption) * 0.09) }} ,
                  [{{ NumberFormatter(taxableIncome - exemption) }} * 9%]
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CustomModalContainer>

    <span class="total-amount text-white mb-0">
      {{ NumberFormatter(taxLiablity) }}
    </span>
    <span
      v-if="!!Number(taxLiablity)"
      class="ml-3 mb-0 bold text-info cursor-pointer info-container"
      @click="setOpen(true)"
      >i</span
    >
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { ref } from "vue";

import { CustomModalContainer } from "../../common";
import { NumberFormatter } from "../../../helpers/numberFormatter";
import { useCTaxCalculatorIndividualStore } from "../../../store/CTaxCalculatorIndividual";

const taxCalculator = useCTaxCalculatorIndividualStore();
const { exemption, taxLiablity, taxableIncome } = storeToRefs(taxCalculator);
const open = ref(false);
const setOpen = (value) => {
  open.value = value;
};
</script>

<style lang="scss" scoped>
.info-container {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #deeded;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  margin-left: 35px;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #0f75bc !important;
}

.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
}
.table-header {
  min-height: 39px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2.5px solid #fff;
}
.table-header.amount {
  min-width: 300px !important;
}
.table-rows {
  background: #ffffff;
  border: 2.5px solid #fff;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input {
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input:focus {
  outline: none;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.delete-button {
  width: 50px;
  height: 44px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #0f75bc !important;
}
.gray {
  background: #deeded;
}
</style>

<template>
  <v-dialog
    v-model="toggle"
    width="auto"
    content-class="my-custom-dialog"
    persistent
  >
    <div v-if="chapter7[props.type][props.model].data" class="card-container">
      <div class="d-flex w-100 justify-content-end">
        <img
          src="@/assets/svg/close-circle.svg"
          class="cursor-pointer"
          alt=""
          @click="closeModal()"
        />
      </div>
      <h4 class="bold title mt-4">
        {{
          chapter7[
            type === "deduction_item"
              ? "deduction_description"
              : "addition_description"
          ][model]
        }}
      </h4>

      <div class="w-100 d-flex align-items-center justify-content-end">
        <button class="save-button mr-3" @click="addNew">
          <img src="@/assets/svg/plus-square-light.svg" alt="" />
          Add New
        </button>
      </div>
      <div class="w-100 table-responsive mt-4 mb-0">
        <table class="table custom-table mb-0 table-bordered table-striped">
          <thead>
            <tr>
              <th class="table-header">Sl No.</th>
              <th class="table-header">Particulars</th>
              <th class="table-header">Amount</th>
              <th class="table-header">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in chapter7[props.type][props.model].data"
              :key="item.id"
            >
              <td class="table-rows">
                <p class="data-text mb-0">{{ index + 1 }}</p>
              </td>
              <td class="table-rows">
                <input
                  v-model="
                    chapter7[props.type][props.model].data[index].particular
                  "
                  type="text"
                  class="custom-input"
                />
              </td>
              <td class="table-rows">
                <input
                  v-model="chapter7[props.type][props.model].data[index].amount"
                  type="number"
                  class="custom-input"
                  step="0.01"
                  @input="changeColor"
                />
              </td>
              <button class="delete-button w-100" @click="deleteItem(item.id)">
                <img src="@/assets/svg/trash.svg" alt="" />
              </button>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="w-100 d-flex gray py-3 px-4 pr-4 align-items-center justify-content-between"
      >
        <div class=""></div>
        <p class="mb-0 bold total-amount">
          Total: {{ NumberFormatter(chapter7[props.type][props.model].total) }}
        </p>
      </div>
      <div class="w-100 d-flex align-items-center justify-content-end mt-3">
        <button class="save-button mr-3" @click="(e) => setOpen(false)">
          Set
        </button>
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import { ref, defineProps, watch, onMounted } from "vue";
import { useComputeIncomeTaxStore } from "../../../../store/incometax.store";
import { storeToRefs } from "pinia";
import { generate } from "randomized-string";
import { NumberFormatter } from "../../../../helpers/numberFormatter";

const props = defineProps({
  open: Boolean,
  setOpen: Function,
  model: {
    type: String,
    default: "any_other_income_from_a_participating",
  },
  type: {
    type: String,
    default: "deduction_item",
  },
});
const toggle = ref(props.open);
const computeIncomeTaxStore = useComputeIncomeTaxStore();
const { chapter7 } = storeToRefs(computeIncomeTaxStore);

const setData = (Type, Model) => {
  const data = chapter7.value[Type][Model]?.data;
  if (data?.length < 5) {
    const newData = [];
    for (let i = 0; i < 5; i++) {
      const item = data[i];
      if (!item) {
        newData.push({
          id: generate(),
          particular: "",
          amount: "",
        });
      } else newData.push(item);
    }
    chapter7.value[Type][Model].data = newData;
  }
};

onMounted(async () => {
  setData(props.type, props.model);
});

watch(
  () => props.open,
  () => {
    toggle.value = props.open;
  }
);
watch(
  () => props.type,
  (newValue) => {
    setData(newValue, props.model);
  },
  { deep: true }
);
watch(
  () => props.model,
  (newValue) => {
    setData(props.type, newValue);
  },
  { deep: true }
);

// change color when total changed
const changeColor = () => {
  chapter7.value[`${props.type}_color`][props.model] = "#e60000";
};
const closeModal = () => {
  toggle.value = false;
  props.setOpen(false);
};

const addNew = () => {
  chapter7.value[props.type][props.model].data.push({
    id: generate(),
    particular: "",
    amount: "",
  });
};
watch(toggle, (newValue) => props.setOpen(newValue));
const deleteItem = (id) => {
  if (confirm("Are you sure you want to delete this item?")) {
    const newData = chapter7.value[props.type][props.model].data.filter(
      (item) => item.id !== id
    );

    chapter7.value[props.type][props.model].data = newData;
  }
};
watch(
  () => chapter7.value[props.type][props.model]?.data,
  (newValue) => {
    if (newValue) {
      chapter7.value[props.type][props.model].total = newValue?.reduce(
        (a, b) => {
          return a + Number(b.amount);
        },
        0
      );
    }
  },
  { deep: true }
);
</script>

<style scoped>
.card-container {
  max-width: 600px;
  background: #fafbff;
  border-radius: 5px;
  padding: 33px 27px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.card-container::-webkit-scrollbar {
  display: none;
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /* identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
}
.table-header {
  min-height: 39px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2.5px solid #fff;
}
.table-rows {
  background: #ffffff;
  border: 2.5px solid #fff;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input {
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input:focus {
  outline: none;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.delete-button {
  width: 50px;
  height: 44px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.gray {
  background: #deeded;
}
</style>

<template>
  <SideNavContainer title="Add Entity">
    <SubscriptionChecker>
      <EntityChecker>
        <AddEntityTab active="entityDetails" />
        <Progress :active="2" />
        <StatusChecker>
          <form @submit="handleSubmit">
            <div class="row wrapper px-2">
              <div class="col-lg-4 col-md-6 mt-4">
                <h4 class="label">
                  Place of Establishment <span class="text-danger">*</span>
                </h4>
                <v-autocomplete
                  v-model="place_of_establishment"
                  :error-message="error.place_of_establishment"
                  label="Place of Establishment"
                  :items="state.data"
                  item-title="name"
                  item-value="id"
                  bg-color="#DEEDED"
                >
                </v-autocomplete>
                <p v-if="error.place_of_establishment" class="text-danger">
                  {{ error.place_of_establishment }}
                </p>
              </div>

              <div class="col-lg-4 col-md-6 mt-4">
                <CustomSelectInput
                  v-model="license_authority_type"
                  label=" License Authority Type *"
                  :options="licenseAuthorityType.data"
                  :error-message="error.license_authority_type"
                  item-title="name"
                  item-value="id"
                  placeholder="Select License Authority Type"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomSelectInput
                  v-model="license_authority"
                  label="License Authority *"
                  :error-message="error.license_authority"
                  :options="licenseAuthority.data"
                  item-title="name"
                  item-value="id"
                  placeholder="Select License Authority"
                  :other-props="{
                    loading: licenseAuthority.loading ? 'info' : false,
                  }"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="license_number"
                  placeholder="License Number"
                  label="License Number *"
                  :error-message="error.license_number"
                  @keypress="onlyAcceptAlphaNumeric"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomDateInput
                  v-model="license_issue_date"
                  label="Issue Date *"
                  :max="dateNow()"
                  placeholder="License Number"
                  :error-message="error.license_issue_date"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomDateInput
                  v-model="license_expiry_date"
                  label="Expiry Date *"
                  :min="dateNow()"
                  placeholder="License Number"
                  :error-message="error.license_expiry_date"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomFileUploadInput
                  v-model="license_certification"
                  :file-name="license_certification"
                  label="License Certification"
                  supported-file-text="*Supported file type - .png , .jpeg, .pdf. * Supported file size - 0 - 2 MB"
                  accept="image/png, image/jpeg, application/pdf"
                  :link="
                    license_certification
                      ? `${license_imagepath}/${license_certification}`
                      : ''
                  "
                  :error-message="error.license_certification"
                  :input-props="{
                    onchange: (e) =>
                      handleFileChange(e, 'license_certification'),
                    accept: 'image/png, image/jpeg, application/pdf',
                  }"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomFileUploadInput
                  v-model="registration_certification"
                  label="Certificate of Incorporation"
                  :file-name="registration_certification"
                  supported-file-text="*Supported file type - .png , .jpeg, .pdf. * Supported file size - 0 - 2 MB"
                  accept="image/png, image/jpeg, application/pdf"
                  :link="
                    registration_certification
                      ? `${registration_imagepath}/${registration_certification}`
                      : ''
                  "
                  :error-message="error.registration_certification"
                  :input-props="{
                    onchange: (e) =>
                      handleFileChange(e, 'registration_certification'),
                    accept: 'image/png, image/jpeg, application/pdf',
                  }"
                />
              </div>
            </div>
            <p class="my-2 text-info">{{ message }}</p>
            <div class="d-flex align-items-center mt-4 mb-4">
              <button class="save-next mr-3" type="submit">Save & Next</button>
              <button
                class="cancel"
                type="button"
                @click="$router.push('/entity')"
              >
                Cancel
              </button>
            </div>
          </form>
        </StatusChecker>
      </EntityChecker>
    </SubscriptionChecker>
  </SideNavContainer>
</template>

<script>
import { SideNavContainer } from "../../../../layout";
import {
  AddEntityTab,
  CustomDateInput,
  CustomSelectInput,
} from "../../../../common";
import Progress from "./Progress.vue";
import CustomTextInput from "../../../../common/CustomTextInput.vue";
import SubscriptionChecker from "@/components/subscription/SubscriptionChecker.vue";
import { addEntity } from "../../../../../store/entityActions/post";
import { getEntityItems, getEntityItem } from "@/store/entityActions/get";
import { storeToRefs } from "pinia";
import EntityChecker from "../../EntityChecker.vue";
import { useEntityStore } from "../../../../../store/entity";
import { dateNow } from "../../../../../helpers/date";
import { checkFileSize } from "../../../../../helpers/file";
import {
  onlyAcceptDigits,
  onlyAcceptAlphaNumeric,
} from "../../../../../helpers/validation";
import CustomFileUploadInput from "../../../../common/CustomFileUploadInput.vue";
import StatusChecker from "../../StatusChecker.vue";
import { useCountryStore } from "../../../../../store/country";

export default {
  name: "EntityLicense",
  components: {
    SideNavContainer,
    AddEntityTab,
    CustomDateInput,
    SubscriptionChecker,
    Progress,
    CustomSelectInput,
    CustomTextInput,
    EntityChecker,
    CustomFileUploadInput,
    StatusChecker,
  },
  setup() {
    const entityStore = useEntityStore();
    const {
      businessActivity,
      entity,
      businessSubActivity,
      licenseAuthorityType,
      licenseAuthority,
    } = storeToRefs(entityStore);

    const countryStore = useCountryStore();

    const { countries, states: state } = storeToRefs(countryStore);

    return {
      countries,
      state,
      countryStore,
      businessActivity,
      entity,
      businessSubActivity,
      licenseAuthorityType,
      licenseAuthority,
      dateNow,
      onlyAcceptDigits,
      onlyAcceptAlphaNumeric,
    };
  },
  data() {
    return {
      edit: false,
      message: "",
      license_imagepath: "",
      registration_imagepath: "",
      place_of_establishment: "",
      license_authority_type: "",
      license_authority: "",
      license_number: "",
      license_issue_date: "",
      license_expiry_date: "",
      license_certification: "",
      registration_certification: "",
      lc: "",
      rc: "",
      error: {
        license_number: false,
        license_certification: "",
        registration_certification: "",
        license_authority_type: false,
        place_of_establishment: false,
        license_authority: false,
      },
      firstTime: {
        license_authority_type: true,
        place_of_establishment: true,
      },
    };
  },
  watch: {
    license_number() {
      this.error.license_number = false;
    },

    async place_of_establishment(newValue) {
      await this.getLicenseAuthority(newValue, this.license_authority_type);
      this.error.place_of_establishment = "";
    },
    async license_authority_type(newValue) {
      await this.getLicenseAuthority(this.place_of_establishment, newValue);
      this.error.license_authority_type = "";
    },
    async license_authority(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        await getEntityItem("businessActivity", "/businessActivity", newValue);
      }
      this.error.license_authority = "";
    },

    license_issue_date() {
      this.error.license_issue_date = "";
    },
    license_expiry_date() {
      this.error.license_expiry_date = "";
    },
    license_license_certification() {
      this.error.license_certification = "";
    },
    license_registration_certification() {
      this.error.registration_certification = "";
    },
  },
  async created() {
    const id = this.$route.params.sub_id;
    if (!this.countries.data?.length) {
      await this.countryStore.getCountryList();
    }
    if (!this.state.data?.length) {
      const country = this.countries.data.find(
        (c) => c.name === "United Arab Emirates"
      );
      if (country) {
        await this.countryStore.getStateList(country?.id);
      }
    }
    if (!this.businessActivity.data.length) {
      await getEntityItems("businessActivity", "/businessActivity");
    }

    if (!this.licenseAuthorityType.data.length) {
      await getEntityItems("licenseAuthorityType", "/license_authority_type");
    }
    this.licenseAuthority.loading = false;
    if (id) {
      this.getSubId();
      return;
    }
    await getEntityItem("entity", "/entity", this.$route.params.id);
    const entity = this.entity?.data?.entity[0];
    const business_activity = entity?.business_activity;
    const place_of_incorporation = entity?.place_of_establishment?.id;
    await getEntityItem(
      "businessSubActivity",
      "/businessActivity/" + business_activity,
      place_of_incorporation
    );

    //this.license_authority_name = entity?.license_authority?.name;
  },

  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      if (!this.place_of_establishment) {
        this.error.place_of_establishment = "Field is required!";
        return;
      }
      if (!this.license_authority_type) {
        this.error.license_authority_type = "Field is required!";
        return;
      }
      if (!this.license_authority) {
        this.error.license_authority = "Field is required!";
        return;
      }

      if (!this.license_number) {
        this.error.license_number = "Filed is required";
        return;
      }
      if (!this.license_issue_date) {
        this.error.license_issue_date = "Field is required";
        return;
      }
      if (!this.license_expiry_date) {
        this.error.license_expiry_date = "Field is required";
        return;
      }

      const id = this.$route.params.sub_id;
      const data = new FormData();

      data.append("place_of_establishment", this.place_of_establishment);
      data.append("license_authority_type", this.license_authority_type);
      data.append("license_authority", this.license_authority);
      data.append("license_number", this.license_number);
      data.append("license_issue_date", this.license_issue_date);
      data.append("license_expiry_date", this.license_expiry_date);
      if (
        typeof this.registration_certification !== "string" &&
        this.registration_certification
      ) {
        data.append(
          "registration_certification",
          this.registration_certification
        );
      }
      if (
        typeof this.license_certification !== "string" &&
        this.license_certification
      ) {
        data.append("license_certification", this.license_certification);
      }
      data.append("entity_license_id", id);
      if (typeof this.registration_certification === "object") {
        if (!checkFileSize(this.registration_certification)) {
          this.error.registration_certification =
            "File size should be less than 2MB";
          return;
        }
      }
      if (typeof this.license_certification === "object") {
        if (!checkFileSize(this.license_certification)) {
          this.error.license_certification =
            "File size should be less than 2MB";
          return;
        }
      }
      if (this.edit) {
        data.append("_method", "PUT");
        await addEntity(
          "/entityLicense",
          data,
          this.setMessage,
          async () => {
            await getEntityItem("entity", "/entity", this.$route.params.id);
            const Entity = this.entity?.data?.tax?.find(
              (e) => Number(e.entity_id) === Number(this.$route.params.id)
            );
            const taxId = Entity?.id;
            if (taxId) {
              this.$router.push(
                `/entity/add-entity/entity-details/tax-details/${this.$route.params.id}/${taxId}`
              );
            } else {
              this.$router.push(
                `/entity/add-entity/entity-details/tax-details/${this.$route.params.id}`
              );
            }
          },
          true
        );
        return;
      }
      data.append("entity_id", this.$route.params.id);
      addEntity(
        "/entityLicense",
        data,
        this.setMessage,
        async ({ entity_id: entityId }) => {
          await getEntityItem("entity", "/entity", entityId);

          this.$router.push(
            "/entity/add-entity/entity-details/tax-details/" + entityId
          );
        }
      );
    },
    async getSubId() {
      const id = this.$route.params.sub_id;
      await getEntityItem("entity", "/entity", this.$route.params.id);
      const Entity = this.entity?.data?.license?.find(
        (e) => Number(e.id) === Number(id)
      );
      const entity = this.entity?.data?.entity[0];
      const business_activity = entity?.business_activity;
      const place_of_incorporation = entity?.place_of_establishment?.id;
      await getEntityItem(
        "businessSubActivity",
        "/businessActivity/" + business_activity,
        place_of_incorporation
      );

      if (Entity) {
        this.edit = true;
        this.place_of_establishment = Entity?.place_of_establishment?.id; //
        this.license_authority_type = Entity?.license_authority_type?.id; //
        this.license_authority = Entity?.license_authority?.id;
        this.license_number = Entity?.license_number;
        this.license_issue_date = Entity?.license_issue_date;
        this.license_expiry_date = Entity?.license_expiry_date;
        this.license_certification = Entity?.license_certification;

        this.registration_certification = Entity?.registration_certification;
        this.license_imagepath = Entity?.license_imagepath;
        this.registration_imagepath = Entity?.registration_imagepath;
      }
    },
    setMessage(message) {
      this.message = message;
    },
    handleFileChange(e, model) {
      this.error[model] = "";
    },
    async getLicenseAuthority(placeofEtablishment, license_authority_type) {
      if (placeofEtablishment && license_authority_type) {
        await getEntityItem(
          "licenseAuthority",
          "/license_authority_type/" + license_authority_type,
          placeofEtablishment
        );
      } else {
        this.licenseAuthority.data = [];
      }
      if (this.firstTime.license_authority_type) {
        this.firstTime.license_authority_type = false;
        return;
      }
      if (this.firstTime.place_of_establishment) {
        this.firstTime.place_of_establishment = false;
        return;
      }
      if (!this.licenseAuthority.data.length) {
        this.license_authority = "";
        return;
      }
      const find = this.licenseAuthority.data.find(
        (b) => b.id == this.license_authority
      );

      if (!find) {
        this.license_authority = "";
      }
    },
  },
};
</script>

<style scoped>
.save-next {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #fafbff;
  width: 196px;
  background: #0f75bc;
  height: 55px;
}
.cancel {
  background: #ffffff;
  border: 1px solid #747171;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #747171;
  height: 55px;
  width: 196px;
}
.custom-input {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  height: 56px;
  padding: 13px 10px;
  margin-top: 8px;
}
.custom-input.error {
  border: 1px solid red;
}
.custom-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
  text-align: start;
}
.custom-input:focus {
  outline: 0;
}
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
  margin-bottom: 8px;
}
</style>

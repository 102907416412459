<template>
  <SignInSignUpContainer>
    <LoginWithOtpVue />
  </SignInSignUpContainer>
</template>

<script>
import { SignInSignUpContainer } from "@/components/account";
import LoginWithOtpVue from "../../components/account/LoginWithOtp.vue";

export default {
  name: "LoginWithOtp",
  components: { SignInSignUpContainer, LoginWithOtpVue },
};
</script>

<style lang="scss" scoped></style>

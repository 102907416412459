<template>
  <CustomModalContainer
    :open="modal"
    :set-open="setModal"
    transition="dialog-top-transition"
    content-class="my-custom-dialog"
    persistent
    :show-close-icon="false"
  >
    <AlertModal
      :open="alertModal"
      message="You have selected a year that you cannot change later!"
      sub-message="Are you sure you want to continue?"
      :on-confirm="onConfirm"
      :on-cancel="handleModalCancel"
      :has-cancel="true"
      :button-name="`Confirm`"
      :modal-props="{ persistent: true }"
      :rise-an-event-on-cloase-modal="false"
    />
    <div
      class="w-100 wrapper p-3 d-flex align-items-center justify-content-between"
    >
      <CustomRadioButton
        v-model="options.selected_non_qfzp"
        label="Have you ever selected non qualified freezone person?"
        :options="[
          {
            name: 'Yes',
            value: 'Y',
          },
          {
            name: 'No',
            value: 'N',
          },
        ]"
      />
    </div>
    <div
      v-if="options.selected_non_qfzp === 'Y'"
      class="w-100 p-3 d-flex align-items-center justify-content-between"
    >
      <h4 class="choose-title w-50">In Which Financial Year?</h4>
      <div class="w-50">
        <v-skeleton-loader
          v-if="financialYear.loading"
          width="100%"
          type="button"
        ></v-skeleton-loader>
        <p v-else-if="financialYear.error" class="text-danger">
          {{ financialYear.error }}
        </p>
        <select
          v-else
          :value="options.selected_non_qfzp_year"
          class="date-select w-100"
          @input="handleConfirm"
        >
          <option
            v-for="item in financialYear.data"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>
    <h4
      v-if="options.selected_non_qfzp === 'N'"
      class="choose-title mt-4 mb-0 w-100 bold"
    >
      <b class="text-warning mb-0 f-20">&#x26A0;</b>
      After register ed as NON-FZP then this cannot be change upto 4 succeeding
      years.
    </h4>
    <div class="w-100 d-flex justify-content-end mt-3">
      <button
        :disabled="
          options.selected_non_qfzp === 'Y'
            ? !previousYearAccept
            : options.selected_non_qfzp !== 'N'
        "
        class="save-button"
        @click="() => setModal(false)"
      >
        Set
      </button>
    </div>
  </CustomModalContainer>
</template>

<script setup>
import {
  AlertModal,
  CustomModalContainer,
  CustomRadioButton,
} from "../../common";
import { useGeneralInfoStore } from "../../../store/generalInformation";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { onUnmounted, reactive, watch } from "vue";
import { ref } from "vue";

defineProps({
  modal: {
    type: Boolean,
    default: false,
  },
  setModal: {
    type: Function,
    default: () => {},
  },
});
const route = useRoute();
const generalInfoStore = useGeneralInfoStore();
const { options, financialYear } = storeToRefs(generalInfoStore);
const previousYearAccept = ref(false);
const alertModal = ref(false);
const selected_non_qfzp_year = ref("");
const handleConfirm = async (e) => {
  let value = e.target.value;
  if (value) {
    selected_non_qfzp_year.value = value;
    alertModal.value = await true;
  }
};
const handleModalCancel = async () => {
  options.value.selected_non_qfzp_year = await "";
  previousYearAccept.value = await false;
  alertModal.value = await false;
};
const onConfirm = async () => {
  options.value.selected_non_qfzp_year = selected_non_qfzp_year.value;
  previousYearAccept.value = await true;
  alertModal.value = await false;
};
const unWatch = watch(
  () => options.value.selected_non_qfzp,
  async (newVal) => {
    if (newVal === "Y") {
      if (!financialYear.value.data.length) {
        await generalInfoStore.getPreviousFinacialYear(
          route.query.financial_period
        );
      }
    }
  }
);

const unWatchRouteFinancialYear = watch(
  () => route.query.financial_year,
  async (newVal, oldVal) => {
    if (newVal !== oldVal) {
      options.selected_non_qfzp_year = "";
    }
  }
);
onUnmounted(() => {
  unWatch();
  unWatchRouteFinancialYear();
});
</script>

<style scoped>
.choose-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.choose-name {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.88px;
}
.wrapper {
  background: #deeded;
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.save-button:disabled {
  opacity: 0.5;
}
.f-20 {
  font-size: 30px;
}
.date-select {
  padding: 10px;
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  height: 40px;
}
.date-select:focus {
  outline: none;
}
.option {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

<template>
  <div class="w-100">
    <CustomModalContainer :open="modal" :set-open="setModal">
      <img :src="imageLink" class="img-modal" alt="" />
    </CustomModalContainer>
    <h4
      v-if="label && !label?.toString().includes('*')"
      :class="`label mb-0 ${disabled ? 'disabled' : ''}`"
    >
      {{ label }}
    </h4>
    <h4
      v-if="label && label?.toString().includes('*')"
      :class="`label mb-0 ${disabled ? 'disabled' : ''}`"
    >
      {{ label.toString().replace("*", "") }} <span class="text-danger">*</span>
    </h4>
    <div
      :class="`d-flex align-items-center  px-3 custom-input ${
        error ? 'error' : ''
      }`"
    >
      <input
        :id="Id"
        hidden
        type="file"
        :accept="accept"
        v-bind="inputProps"
        @change="setFile"
      />
      <button
        type="button"
        class="choose-file p-1 mr-4 d-flex justify-content-center align-items-center"
        @click="selectFile"
      >
        Upload file
      </button>
      <p
        :class="`mb-0 text-info mr-4 file-name ${error ? 'text-danger' : ''} `"
      >
        {{ nameFile }}
      </p>
      <!-- <img
        v-if="imageLink"
        :src="imageLink"
        alt=""
        class="custom-img cursor-pointer"
        @click="setModal(true)"
      /> -->
      <img
        v-if="
          imageLink &&
          imageLink.substr(imageLink.lastIndexOf('.') + 1) != 'pdf' &&
          fileType != 'pdf'
        "
        :src="imageLink"
        alt=""
        class="custom-img cursor-pointer"
        @click="setModal(true)"
      />

      <a
        v-if="
          imageLink &&
          (imageLink.substr(imageLink.lastIndexOf('.') + 1) == 'pdf' ||
            fileType == 'pdf')
        "
        :href="imageLink"
        target="_blank"
        ><img src="@/assets/images/pdf40.png"
      /></a>
      <h5
        v-if="nameFile !== 'No file chosen'"
        class="mb-0 bold remove-icon"
        @click="removeFile"
      >
        x
      </h5>
    </div>
    <p class="supported-file-text text-center mb-0">
      {{ supportedFileText }}
    </p>
    <p v-if="error" class="text-danger error-message">
      {{ error }}
    </p>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { generate } from "randomized-string";
import { CustomModalContainer } from ".";
import { checkFileSize } from "../../helpers/file";

const props = defineProps([
  "modelValue",
  "id",
  "fileName",
  "link",
  "errorMessage",
  "supportedFileText",
  "accept",
  "inputProps",
  "label",
  "maxFileSize",
]);
const emit = defineEmits(["update:modelValue"]);
const error = ref(props.errorMessage || "");
const modal = ref(false);
const setModal = (value) => {
  modal.value = value;
};
const nameFile = ref(props.fileName || "No file chosen");
const fileType = ref("");
const Id = ref(`file-upload-${props.id || generate()}`);
const imageLink = ref(props.link || "");
const checkFile = (file) => {
  if (props.accept) {
    return props.accept.toString().includes(file.type);
  }
  return true;
};
const setFile = async (event) => {
  let file = event.target.files[0];
  if (file) {
    if (!checkFileSize(file, Number(props.maxFileSize) || 2)) {
      error.value = "File size should be less than 2MB";
      return;
    }
    if (!checkFile(file)) {
      error.value = "File type is not supported";
      return;
    }
    error.value = "";
    await emit("update:modelValue", file);
    nameFile.value = file?.name.toString().slice(0, 20);
    imageLink.value = URL.createObjectURL(file);
    if (file.type == "application/pdf") {
      fileType.value = "pdf";
    } else {
      fileType.value = "";
    }
    if (props.inputProps.onchange) {
      props.inputProps.onchange(event);
    }
  }
  event.target.value = "";
};
const selectFile = () => {
  document.getElementById(Id.value).click();
};
const removeFile = async () => {
  await emit("update:modelValue", "");
  nameFile.value = "No file chosen";
  imageLink.value = "";
  fileType.value = "";
};
watch(
  () => props.fileName,
  (newVal) => {
    nameFile.value =
      newVal?.toString().slice(0, 20) ||
      nameFile?.value.toString().slice(0, 20);
  }
);
watch(
  () => props.link,
  (newVal) => {
    imageLink.value = newVal || imageLink.value;
  }
);
watch(
  () => props.errorMessage,
  (newVal) => {
    error.value = newVal;
  }
);
</script>

<style scoped>
.custom-input {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  height: 56px;
  padding: 13px 10px;
  margin-top: 8px;
}
.custom-input.error {
  border: 1px solid red;
}
.custom-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
  text-align: start;
}
.custom-input:focus {
  outline: 0;
}
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
  margin-bottom: 18px;
}
.mt-31 {
  margin-top: -32px !important;
}
.eye-off {
  margin-left: -30px;
}
.eye {
  height: 30px;
  width: 25px;
}
.supported-file-text {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #959292;
}
.suggestion {
  margin-left: -200px;
  font-size: 14px;
  margin-bottom: 0;
  z-index: 2;
  background: #deeded;
}
.custom-input:disabled {
  opacity: 0.5;
}
.label.disabled {
  opacity: 0.5;
}
.eye-off.disabled {
  opacity: 0.5;
}
.custom-input.border-danger {
  border-bottom: 1px solid --bs-danger;
}
.custom-input.border-warning {
  border-bottom: 1px solid --bs-warning;
}
.custom-input.border-success {
  border-bottom: 1px solid --bs-success;
}
.choose-file {
  width: 121px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
  cursor: pointer;
}
.custom-img {
  width: 45px;
  height: 45px;
  object-fit: cover;
}
.file-name {
  font-size: 14px;
}
.error-message {
  font-size: 14px;
}
.img-modal {
  width: 100%;
  height: auto;
  overflow: scroll;
  object-fit: cover;
}
.remove-icon {
  margin-left: auto;
  cursor: pointer;
}
</style>

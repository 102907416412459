/* eslint-disable no-prototype-builtins */
import fetchWrapper from "@/helpers/fetchWrapper";
import { defineStore, storeToRefs } from "pinia";
import { useProfitAndLossStore } from "./ProfitAdLoss";
import { downloadURI } from "../helpers/download";
import { useRoute } from "vue-router";
import { Toast } from "../helpers/toast";
import { chapter7DataStructure } from "../constants/chapters.data";
import { getErrorMessage, getWarningMessage } from "../helpers/errorHandler";

export const useComputeIncomeTaxStore = defineStore("ComputeIncomeTax", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      loading: true,
      error: false,
      computationTaxColor: {
        chapter_six: "#382628",
        chapter_seven: "#382628",
        chapter_eight: "#382628",
        chapter_nine: "#382628",
        chapter_ten: "#382628",
        total_income_current_year: "#382628",
        chapter_eleven: "#382628",
        loss_sett_off_in_current_year: "#382628",
        total_income: "#382628",
        tax_on_total_income: "#382628",
        chapter_thirteen: "#382628",
        wtc_ftc: "#382628",
        tax_payable_refundable: "#382628",
      },
      coiStatus: null,
      chapter6Color: {
        addition_item: "#382628",
        deduction_item: "#382628",
        total_addtion: "#382628",
        total_diduction: "#382628",
        total: "#382628",
      },
      chapter7Color: {
        addition_item: "#382628",
        deduction_item: "#382628",
        total_addtion: "#382628",
        total_diduction: "#382628",
        total: "#382628",
      },
      chapter9Color: {
        addition_item: "#382628",
        deduction_item: "#382628",
        total_addtion: "#382628",
        total_diduction: "#382628",
        total: "#382628",
      },
      computeIncomeTax: {
        business_income: 1000,
        chapter_eight: 0,
        chapter_eleven: 0,
        chapter_nine: 0,
        chapter_seven: 0,
        chapter_six: 0,
        chapter_ten: 0,
        chapter_thirteen: 0,
        loss_sett_off_in_current_year: 0,
        tax_on_total_income: 0,
        tax_payable_refundable: 0,
        total_income: 0,
        total_income_current_year: 0,
        wtc_ftc: 0,
      },
      chapter6: {
        loading: true,
        error: false,
        addition_item: null,
        addition_description: null,
        deduction_item: null,
        deduction_description: null,
        total_addtion: 0,
        total_diduction: 0,
        total: 0,
        article_20_text: "",
        addition_item_computed: {},
      },
      chapter7: {
        loading: true,
        error: false,
        ...chapter7DataStructure,
        master_data: {
          additional_data: {
            addition: "",
            deduction: "",
          },
        },
      },
      chapter9: {
        loading: true,
        error: false,
        addition_item: null,
        addition_description: null,
        deduction_item: null,
        deduction_description: null,
        total_addtion: 0,
        total_diduction: 0,
        total: 0,
        articles: [],
        item_5_info: [],
        item_7_info: "",
        addition_item_color: null,
        deduction_item_color: null,
        additional_data: {
          addition: {
            data: [],
            total: "",
          },
          deduction: {
            data: [],
            total: "",
          },
          total: "",
        },
      },
    };
  },

  getters: {
    chapter6TotalAddition(state) {
      if (!state.chapter6.addition_item) {
        return 0;
      }
      let sum = 0;
      for (let x in state.chapter6.addition_item) {
        let item = Number(state.chapter6.addition_item[x]);
        sum += item;
      }
      return sum;
    },
    chapter6TotalDeduction(state) {
      if (!state.chapter6.deduction_item) {
        return 0;
      }
      let sum = 0;
      for (let x in state.chapter6.deduction_item) {
        let item = Number(state.chapter6.deduction_item[x]);
        sum += item;
      }
      return sum;
    },
    chapter6Total() {
      return this.chapter6TotalAddition - this.chapter6TotalDeduction;
    },
    chapter7TotalAddition(state) {
      if (!state.chapter7.addition_item) {
        return 0;
      }

      return (route) => {
        let sum = 0;
        const current_business = route.query.current_business;
        for (let x in state.chapter7.addition_item) {
          //dividends_and_other_profit_distributions
          let item =
            current_business === "master"
              ? Number(state.chapter7.addition_item[x] || 0)
              : Number(state.chapter7.addition_item[x]?.total || 0);
          sum += item;
        }
        const additionalData =
          current_business === "master"
            ? Number(state.chapter7.master_data.additional_data.addition || 0)
            : Number(state.chapter7.additional_data.addition?.total || 0);
        return sum + additionalData;
      };
    },
    chapter7TotalDeduction(state) {
      if (!state.chapter7.deduction_item) {
        return 0;
      }
      return (route) => {
        let sum = 0;
        const current_business = route.query.current_business;
        for (let x in state.chapter7.deduction_item) {
          if (this.chapter7?.deduction_item_status[x]) {
            //dividends_and_other_profit_distributions
            if (x !== "any_other") {
              let item =
                current_business === "master"
                  ? Number(state.chapter7.deduction_item[x] || 0)
                  : Number(this.chapter7.deduction_item[x]?.total || 0);
              sum += item;
            }
          }
        }
        const additionalData =
          route.query.current_business === "master"
            ? Number(state.chapter7.master_data.additional_data.deduction || 0)
            : Number(state.chapter7.additional_data.deduction?.total || 0);
        return sum + additionalData;
      };
    },

    chapter7Total() {
      return (route) =>
        this.chapter7TotalAddition(route) - this.chapter7TotalDeduction(route);
    },
    chapter9TotalAddition(state) {
      const route = useRoute();
      if (!state.chapter9.addition_item) {
        return 0;
      }
      let sum = 0;
      if (route?.query?.current_business !== "master") {
        for (let x in state.chapter9.addition_item) {
          if (
            x !== "disallowance_as_per_article_33" &&
            this.chapter9.addition_item_status[x]
          ) {
            let item = Number(state.chapter9.addition_item[x]);
            if (
              x ===
                "expenses_wholly_partially_not_related_to_business_activity" ||
              x === "expenses_debited_in_PL" || // 1
              x === "expenses_related_to_exempt_income" || //3
              x === "interest_disallowed_as_per_article_28" || //4 new
              x === "other_expenses_disallowed" || // 4
              x === "interest_expense_disallowed_as_per_article_31" || //6
              x === "entertainment_expense_disallowed_as_per_article_32" // 7
            ) {
              item = Number(state.chapter9.addition_item[x]?.total);
            }
            sum += item;
          }
        }
      } else {
        for (let x in state.chapter9.addition_item) {
          if (
            x !== "disallowance_as_per_article_33" &&
            this.chapter9.addition_item_status[x]
          ) {
            let item = Number(state.chapter9.addition_item[x]);
            sum += item;
          }
        }
      }
      const sum2 =
        route?.query?.current_business === "master"
          ? Number(state.chapter9.additional_data.addition)
          : Number(state.chapter9.additional_data.addition.total);
      return sum + sum2;
    },
    chapter9TotalDeduction(state) {
      const route = useRoute();
      if (!state.chapter9.deduction_item) {
        return 0;
      }
      let sum = 0;
      if (route?.query?.current_business !== "master") {
        for (let x in state.chapter9.deduction_item) {
          let item = Number(state.chapter9.deduction_item[x]?.total);
          sum += item;
        }
      } else {
        for (let x in state.chapter9.deduction_item) {
          let item = Number(state.chapter9.deduction_item[x]);
          sum += item;
        }
      }
      const sum2 =
        route?.query?.current_business === "master"
          ? Number(state.chapter9.additional_data.deduction)
          : Number(state.chapter9.additional_data.deduction.total);
      return sum + sum2;
    },
    chapter9Total() {
      return this.chapter9TotalAddition - this.chapter9TotalDeduction;
    },
    chapter9DisallowancePerArticle(state) {
      if (!state.chapter9.addition_item) {
        return 0;
      }
      let sum = 0;
      for (let x in state.chapter9.addition_item) {
        if (
          x === "donations_grants_or_gifts_made_to_an_entity" ||
          x === "fines_and_penalties" ||
          x === "bribes_or_other_illicit_payments" ||
          x === "amounts_withdrawn_from_the_business" ||
          x === "corporate_tax" ||
          x === "recoverabel_input_value_added_tax" ||
          x ===
            "tax_on_income_imposed_on_the_taxable_person_outside_the_state" ||
          x === "other_expenditure_as_may_be_specified" ||
          x === "dividends_profit_distributions_or_benefits"
        ) {
          let item = Number(state.chapter9.addition_item[x]);
          sum += item;
        }
      }
      return sum;
    },
    totalIncomeCurrentYear(state) {
      let items = [
        "business_income",
        "chapter_six",
        "chapter_seven",
        "chapter_eight",
        "chapter_nine",
        "chapter_ten",
      ];
      let sum = items.reduce(
        (a, b) => a + Number(state.computeIncomeTax[b]),
        0
      );
      return sum;
    },
    coiTotalIncome(state) {
      return (
        this.totalIncomeCurrentYear -
        Number(state.computeIncomeTax.chapter_eleven)
      );
    },
    coiTotalIncomeNinePercent() {
      if (this.totalIncomeCurrentYear <= 0) {
        return 0;
      }
      return this.coiTotalIncome * 0.09;
    },
    taxPayableRefundable(state) {
      return (
        this.coiTotalIncomeNinePercent -
        Number(state.computeIncomeTax.chapter_thirteen)
      );
    },
  },
  actions: {
    async getComputeIncomeTax(entity_id, fy, fp) {
      try {
        this.loading = true;

        const profitAndLossStore = useProfitAndLossStore();
        const { financial_year, financial_period } =
          storeToRefs(profitAndLossStore);
        const Financial_year = fy || financial_year.value;
        const Financial_period = fp || financial_period.value;
        if (Financial_year && Financial_period) {
          const res = await fetchWrapper.get(
            `/computationOfIncometax?entity_id=${entity_id}&financial_period=${Financial_period}&financial_year=${Financial_year}`
          );
          if (res.data.result == 1) {
            let responseData = res.data?.data;
            let color = res.data?.color;
            this.computationTaxColor = color;
            this.computeIncomeTax = responseData;
            for (let x in responseData) {
              let computeIncomeTax = this.computeIncomeTax[x];
              if (computeIncomeTax || computeIncomeTax === 0) {
                this.computeIncomeTax[x] = responseData[x]
                  ? responseData[x]
                  : "";
              }
            }
            if (res.data?.status) {
              this.coiStatus = {};
              for (let x in res.data?.status) {
                this.coiStatus[x] = Number(res.data?.status[x]);
              }
            }
          } else {
            for (let x in this.computeIncomeTax) {
              this.computeIncomeTax[x] = "";
            }
          }
          this.loading = false;
          this.error = false;
        } else {
          for (let x in this.computeIncomeTax) {
            this.computeIncomeTax[x] = "";
          }
        }
      } catch (err) {
        console.log(err);
        this.error = err?.response?.data?.message || "";
        this.loading = false;
      }
    },
    async downloadCOI(
      entity_id,
      financial_year,
      financial_period,
      entity_business_id,
      type = "",
      qfzp = false
    ) {
      const toast = new Toast("Downloading...");
      try {
        let url = qfzp
          ? `/qfzpCOIExport?entity_id=${entity_id}&financial_period=${financial_period}&financial_year=${financial_year}&type=${type}`
          : `/COIExport?entity_id=${entity_id}&financial_period=${financial_period}&financial_year=${financial_year}${
              entity_business_id === "master"
                ? ""
                : "&entity_business_id=" + entity_business_id
            }${type ? "&type=" + type : ""}`;
        const getUrl = await fetchWrapper.get(url);
        if (getUrl.data.result == 1) {
          await downloadURI(getUrl.data.url);
          toast.makeSuccessToast("Downloaded successfully");
        } else {
          toast.makeErrorToast(
            "Unable to download file because of " + getUrl.data?.error
              ? getUrl.data?.error?.join(",")
              : getUrl.data?.message
          );
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(
          "Unable to download file because of " +
            err?.message +
            ", " +
            err?.response?.data?.message || ""
        );
      }
    },
    async getchapter6(
      entity_id,
      financial_year,
      financial_period,
      current_business
    ) {
      try {
        this.chapter6.loading = true;

        if (financial_year && financial_period && current_business) {
          const res = await fetchWrapper.get(
            `/chapter6?entity_id=${entity_id}&financial_period=${financial_period}&financial_year=${financial_year}${
              current_business !== "master"
                ? "&entity_business_id=" + current_business
                : ""
            }`
          );
          if (res.data.result == 1) {
            let responseData = res.data?.data;
            let color = res.data?.color;
            this.chapter6Color = color;
            this.chapter6 = { ...this.chapter6, ...responseData };
            for (let x in this.chapter6.addition_item) {
              this.chapter6.addition_item[x] = Number(
                this.chapter6.addition_item[x]
              )
                ? Number(this.chapter6.addition_item[x])
                : "";
            }
            for (let x in this.chapter6.deduction_item) {
              this.chapter6.deduction_item[x] = Number(
                this.chapter6.deduction_item[x]
              )
                ? Number(this.chapter6.deduction_item[x])
                : "";
            }
            this.chapter6.loading = false;
            this.chapter6.error = false;
          } else {
            this.chapter6.total_addtion = "";
            this.chapter6.total_diduction = "";
            this.chapter6.total = "";
            this.chapter6.loading = false;
            this.chapter6.error = res.data.message;
          }
        } else {
          this.chapter6.error =
            "Invalid business or financial year or financial period";
          this.chapter6.loading = false;
        }
      } catch (err) {
        console.log(err);
        this.chapter6.error = err?.response?.data?.message || "";
        this.chapter6.loading = false;
      }
    },
    async getchapter7(
      entity_id,
      financial_year,
      financial_period,
      current_business
    ) {
      try {
        this.chapter7.loading = true;

        if (financial_year && financial_period && current_business) {
          const res = await fetchWrapper.get(
            `/chapter7?entity_id=${entity_id}&financial_period=${financial_period}&financial_year=${financial_year}${
              current_business !== "master"
                ? "&entity_business_id=" + current_business
                : ""
            }`
          );
          if (res.data.result == 1) {
            let responseData = res.data?.data;
            let color = res.data?.color;
            this.chapter7Color = color;
            if (current_business == "master") {
              this.chapter7 = {
                ...this.chapter7,
                ...responseData,
                additional_data: chapter7DataStructure.additional_data,
                master_data: {
                  additional_data: responseData.additional_data || {
                    addition: "",
                    deduction: "",
                  },
                },
              };
            } else {
              this.chapter7 = {
                ...this.chapter7,
                ...responseData,
                additional_data:
                  responseData.additional_data ||
                  chapter7DataStructure.additional_data,
              };
              for (let x in this.chapter7.addition_item) {
                this.chapter7.addition_item[x] = {
                  data: this.chapter7.addition_item[x]?.data || [],
                  total: this.chapter7.addition_item[x]?.total || "",
                };
              }
              for (let x in this.chapter7.deduction_item) {
                if (x !== "any_other") {
                  this.chapter7.deduction_item[x] = {
                    data: this.chapter7.deduction_item[x]?.data || [],
                    total: this.chapter7.deduction_item[x]?.total || "",
                  };
                } else {
                  this.chapter7.previousValue[x] = Number(
                    this.chapter7.deduction_item[x]
                  );
                }
              }
            }

            if (!this.chapter7.deduction_item_color) {
              let newObject = {};
              for (let x in this.chapter7.deduction_item) {
                newObject[x] = "#0000b3";
              }
              this.chapter7.deduction_item_color = newObject;
            }
            this.chapter7.loading = false;
            this.chapter7.error = false;
          } else {
            this.chapter7.total_addtion = "";
            this.chapter7.total_diduction = "";
            this.chapter7.total = "";
            this.chapter7.loading = false;
            this.chapter7.error = res.data.message;
          }
        } else {
          this.chapter7.error =
            "Invalid business or financial year or financial period";
          this.chapter7.loading = false;
        }
      } catch (err) {
        console.log(err);
        this.chapter7.error = err?.response?.data?.message || "";
        this.chapter7.loading = false;
      }
    },
    async getchapter9(
      entity_id,
      financial_year,
      financial_period,
      current_business
    ) {
      try {
        this.chapter9.loading = true;

        if (financial_year && financial_period && current_business) {
          const res = await fetchWrapper.get(
            `/chapter9?entity_id=${entity_id}&financial_period=${financial_period}&financial_year=${financial_year}${
              current_business !== "master"
                ? "&entity_business_id=" + current_business
                : ""
            }`
          );
          if (res.data.result == 1) {
            let responseData = res.data?.data;
            let color = res.data?.color;
            this.chapter9Color = color;
            this.chapter9 = { ...this.chapter9, ...responseData };
            this.chapter9.loading = false;
            this.chapter9.error = false;
            if (current_business !== "master") {
              for (let x in this.chapter9.addition_item) {
                if (
                  x ===
                    "expenses_wholly_partially_not_related_to_business_activity" || //2
                  x === "expenses_debited_in_PL" || // 1
                  x === "expenses_related_to_exempt_income" || //3
                  x === "interest_disallowed_as_per_article_28" || //4 new
                  x === "other_expenses_disallowed" || // 4
                  x === "interest_expense_disallowed_as_per_article_31" || //6
                  x === "entertainment_expense_disallowed_as_per_article_32" // 7
                ) {
                  const data = this.chapter9.addition_item[x]?.data;
                  if (!data) {
                    this.chapter9.addition_item[x] = {
                      data: this.chapter9.addition_item[x]?.data || [],
                      total: this.chapter9.addition_item[x]?.total,
                    };
                  }
                } else {
                  this.chapter9.addition_item[x] = Number(
                    this.chapter9.addition_item[x]
                  )
                    ? Number(this.chapter9.addition_item[x])
                    : "";
                }
              }
              for (let x in this.chapter9.deduction_item) {
                if (x === "expense_allowed_as_per_article_28_3") {
                  const data = this.chapter9.deduction_item[x]?.data;
                  if (!data) {
                    this.chapter9.deduction_item[x] = {
                      data: this.chapter9.deduction_item[x]?.data || [],
                      total: this.chapter9.deduction_item[x]?.total || "",
                    };
                  }
                } else {
                  this.chapter9.deduction_item[x] = Number(
                    this.chapter9.deduction_item[x]
                  )
                    ? Number(this.chapter9.deduction_item[x])
                    : "";
                }
              }
            }
          } else {
            this.chapter9.total_addtion = "";
            this.chapter9.total_diduction = "";
            this.chapter9.total = "";
            this.chapter9.loading = false;
            this.chapter9.error = res.data.message;
          }
        } else {
          this.chapter9.error =
            "Invalid business or financial year or financial period";
          this.chapter9.loading = false;
        }
      } catch (err) {
        console.log(err);
        this.chapter9.error = err?.response?.data?.message || "";
        this.chapter9.loading = false;
      }
    },
    async saveChapter6(
      entity_id,
      financial_period,
      financial_year,
      entity_business_id,
      setMessage = () => {},
      callBack = () => {}
    ) {
      let toast = new Toast("Saving...");
      try {
        let payload =
          entity_business_id !== "master"
            ? {
                entity_id,
                financial_year,
                financial_period,
                entity_business_id,
                deduction_item: this.chapter6.deduction_item,
                addition_item: this.chapter6.addition_item,
                total_addtion: this.chapter6TotalAddition,
                total_diduction: this.chapter6TotalDeduction,
                total: this.chapter6Total,
              }
            : {
                entity_id,
                financial_year,
                financial_period,
                deduction_item: this.chapter6.deduction_item,
                addition_item: this.chapter6.addition_item,
                total_addtion: this.chapter6TotalAddition,
                total_diduction: this.chapter6TotalDeduction,
                total: this.chapter6Total,
              };
        const res = await fetchWrapper.post("/chapter6", payload);
        if (res.data.result === 1) {
          toast.makeSuccessToast(res.data.message);
          callBack();
        } else setMessage(res.data.message);
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    },
    async saveChapter7(
      entity_id,
      financial_period,
      financial_year,
      entity_business_id,
      setMessage = () => {},
      callBack = () => {}
    ) {
      let toast = new Toast("Saving...");
      try {
        const businessQuery = {
          query: { current_business: entity_business_id },
        };
        let payload = {
          entity_id,
          financial_year,
          financial_period,
          entity_business_id,
          deduction_item: this.chapter7.deduction_item,
          addition_item: this.chapter7.addition_item,
          total_addtion: this.chapter7TotalAddition(businessQuery),
          total_diduction: this.chapter7TotalDeduction(businessQuery),
          addition_description: this.chapter7.addition_description,
          total: this.chapter7Total(businessQuery),
          deduction_item_color: this.chapter7.deduction_item_color,
          addition_item_color: this.chapter7.addition_item_color,
          additional_data: {
            ...this.chapter7.additional_data,
            total:
              Number(this.chapter7.additional_data.addition.total || "") -
              Number(this.chapter7.additional_data.deduction.total || 0),
          },
        };
        const res = await fetchWrapper.post("/chapter7", payload);
        if (res.data.result === 1) {
          toast.makeSuccessToast(res.data.message);
          callBack();
        } else toast.makeSuccessToast(res.data.message);
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    },
    async getChapter9Payload(route) {
      const { financial_year, financial_period, current_business } =
        route.query;
      const entity_id = route.params.id;
      let payload = {
        deduction_item: this.chapter9.deduction_item,
        addition_item: {
          ...this.chapter9.addition_item,
          disallowance_as_per_article_33: this.chapter9DisallowancePerArticle,
        },
        total_addtion: this.chapter9TotalAddition,
        total_diduction: this.chapter9TotalDeduction,
        addition_item_color: this.chapter9.addition_item_color,
        deduction_item_color: this.chapter9.deduction_item_color,
        total: this.chapter9Total,
        additional_data: {
          ...this.chapter9.additional_data,
          total:
            Number(this.chapter9.additional_data.addition?.total || 0) -
            Number(this.chapter9.additional_data.deduction?.total || 0),
        },
        entity_id,
        financial_year,
        financial_period,
        entity_business_id: current_business,
      };
      return payload;
    },

    async saveChapter9(
      route,
      callBack = () => {},
      toast = new Toast("Saving...")
    ) {
      try {
        const { financial_year, financial_period, current_business } =
          route.query;
        const entity_id = route.params.id;
        if (
          financial_year &&
          financial_period &&
          current_business &&
          entity_id
        ) {
          const payload = await this.getChapter9Payload(route);
          const res = await fetchWrapper.post("/chapter9", payload);
          if (res.data.result === 1) {
            toast.makeSuccessToast(res.data.message);
            callBack(res.data.data);
          } else toast.makeErrorToast(getWarningMessage(res));
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    },
    async autoSaveChapter9(route, setSavingStatus, callBack = () => {}) {
      try {
        const { financial_year, financial_period, current_business } =
          route.query;
        const entity_id = route.params.id;
        if (
          financial_year &&
          financial_period &&
          current_business &&
          entity_id
        ) {
          const payload = await this.getChapter9Payload(route);
          const res = await fetchWrapper.post("/chapter9", payload);
          if (res.data.result === 1) {
            setSavingStatus("loading", false);
            setSavingStatus("error", false);
            setSavingStatus("success", true);
            callBack(res?.data);
          } else {
            setSavingStatus("loading", false);
            setSavingStatus("error", getWarningMessage(res));
            setSavingStatus("success", false);
            setTimeout(() => {
              setSavingStatus("loading", false);
              setSavingStatus("error", false);
              setSavingStatus("success", false);
            }, 3000);
          }
        }
      } catch (err) {
        console.log(err);
        setSavingStatus("loading", false);
        setSavingStatus("error", getErrorMessage(err));
        setSavingStatus("success", false);
        setTimeout(() => {
          setSavingStatus("loading", false);
          setSavingStatus("error", false);
          setSavingStatus("success", false);
        }, 3000);
      }
    },
  },
});

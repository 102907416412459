<template>
  <tr v-if="!!index || index === 0">
    <td class="table-rows">
      <input
        v-model="data[props.article][index].name_of_asset"
        type="text"
        class="custom-input w-100"
        :disabled="!!status"
        placeholder="Name"
      />
    </td>
    <td class="table-rows">
      <input
        v-model="data[props.article][index].name_of_transferee_entity"
        type="text"
        class="custom-input w-100"
        placeholder="Name"
        :disabled="!!status"
      />
    </td>
    <td class="table-rows">
      <input
        v-model="data[props.article][index].net_book_value_as_transfer_date"
        type="number"
        class="custom-input w-100"
        placeholder="Value"
        :disabled="!!status"
      />
    </td>
    <td class="table-rows">
      <input
        v-model="data[props.article][index].transfer_date"
        type="date"
        :min="initialDate"
        :max="finalDate"
        name="begin"
        placeholder="dd-mm-yyyy"
        class="custom-input w-100"
        :disabled="!!status"
      />
    </td>
    <td class="table-rows">
      <div
        class="w-100 button-container d-flex align-items-center justify-content-between"
      >
        <button class="save-button w-100" @click="deleteAssetLiabilities">
          <img src="@/assets/svg/trash.svg" alt="" />
        </button>
      </div>
    </td>
  </tr>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useChapter8Store } from "../../../../store/chapter8";
import { onMounted, ref } from "vue";
import { useEntityStore } from "../../../../store/entity";
import {
  getEntityItem,
  getEntityItems
} from "../../../../store/entityActions/get";
import { useRoute } from "vue-router";
import { formatBetweenDuration } from "../../../../helpers/date";
import { useStatusStore } from "../../../../store/statusStore";
const props = defineProps({
  id: String,
  article: String
});
const statusStore = useStatusStore();
const { status } = storeToRefs(statusStore);
const chapter8Store = useChapter8Store();
const entityStore = useEntityStore();
const { financialPeriod, financialYear } = storeToRefs(entityStore);
const route = useRoute();
const { data } = storeToRefs(chapter8Store);
const initialDate = ref("");
const finalDate = ref("");
const index = ref(null);
const deleteAssetLiabilities = () => {
  if (confirm("Are sure you want to delete this item")) {
    data.value[props.article] = data.value[props.article].filter(
      (value) => value?.id != data.value[props.article][index.value]?.id
    );
  }
};
onMounted(async () => {
  data.value[props.article].forEach((value, Index) => {
    if (value.id === props.id) {
      index.value = Index;
    }
  });
  await getEntityItems("financialYear", "/financialYear");
  await getEntityItem(
    "financialPeriod",
    "/financialYear",
    route.query.financial_period
  );

  const financial_year = financialPeriod.value.data.find(
    (period) => period?.id == route.query.financial_year
  );
  const financial_period = financialYear.value.data.find(
    (period) => period?.id == route.query.financial_period
  );
  const minMaxDate = formatBetweenDuration(
    financial_period?.name,
    financial_year?.name
  );
  initialDate.value = minMaxDate?.initialDate;
  finalDate.value = minMaxDate?.finalDate;
});
</script>

<style scoped>
.wrapper {
  overflow-x: auto;
}
.header {
  padding: 6px 9px;
  min-height: 60px;
  background: #0f75bc;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
.custom-input {
  height: 44px;
  padding: 11px 13px;
  margin-top: 10px;
  border: 1px solid rgba(116, 113, 113, 0.3);
}
.custom-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
  text-align: start;
}
.save-button {
  width: 110px;
  height: 44px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.button-container {
  border: 1px solid rgba(116, 113, 113, 0.3);
  margin-top: 10px;
}
.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
}
.table-header {
  min-height: 39px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2.5px solid #fff;
  width: 100px;
}
.table-header.sl-no {
  width: 10px !important;
}
.table-rows {
  background: #ffffff;
  border: 2.5px solid #fff;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
</style>

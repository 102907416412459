<template>
  <form @submit="handleSubmit">
    <div
      class="full-vh d-flex flex-column justify-content-center align-items-center gap-100"
    >
      <SendResetLink
        :open="forgetPasswordModal"
        :setOpen="setForgetPasswordModal"
      />
      <div
        class="wrapper d-flex flex-column justify-content-evenly align-items-center"
      >
        <h1 class="welcome">Welcome Back!</h1>
        <h2 class="log">Login to Your Account</h2>
        <AccountInput
          v-model="email"
          label="Email Address"
          type="email"
          placeholder="Enter your email"
          :error-message="error.email"
          :style="{
            'padding-bottom': '2px !important',
            'margin-top': '10px',
            'padding-left': '12px',
          }"
        />
        <AccountInput
          v-model="password"
          label="Password"
          type="password"
          placeholder="Enter password"
          :error-message="error.password"
          :style="{
            'padding-bottom': '2px !important',
            'margin-top': '10px',
            'padding-left': '12px',
          }"
        />
        <div class="w-100 d-flex align-items-center justify-content-center">
          <p
            class="forget-password cursor-pointer"
            @click="setForgetPasswordModal(true)"
          >
            Forgot password ?
          </p>
        </div>
        <p class="text-info">{{ message }}</p>
        <button class="btn-log-in" type="submit">Login</button>
        <p class="mb-0 or">OR</p>
        <button
          class="btn-log-otp"
          type="button"
          @click="$router.push('/account/login-otp')"
        >
          Login with OTP
        </button>
      </div>
      <p class="new-snaptax">
        New to SnapTax?
        <router-link to="/account/signup">
          <b class="create-account">Create an account</b>
        </router-link>
      </p>
    </div>
  </form>
</template>

<script>
import { AccountInput } from "../common";
import { useProfileStore } from "../../store/profile";
import { checkRequired } from "../../helpers/formErrorCheckers";
import SendResetLink from "./SendResetLink.vue";
export default {
  name: "LoginComponent",
  components: { AccountInput, SendResetLink },
  data() {
    return {
      email: "",
      password: "",
      message: "",
      error: {
        email: "",
        password: "",
      },
      forgetPasswordModal: false,
    };
  },
  watch: {
    email() {
      this.error.email = "";
    },
    password() {
      this.error.password = "";
    },
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();

      const profileStore = useProfileStore();
      const check = checkRequired({
        email: this.email,
        password: this.password,
      });
      if (check.length) {
        check.forEach((field) => {
          this.error[field] = `Please ${field} is required`;
        });
        return;
      }
      await profileStore.login(
        { email: this.email, password: this.password },
        this.setMessage,
        async ({ token }) => {
          await sessionStorage.setItem("Token", token);
          setTimeout(() => {
            location.href = `/`;
          }, 2000);
        }
      );
    },
    setMessage(message) {
      this.message = message;
    },
    setForgetPasswordModal(value) {
      this.forgetPasswordModal = value;
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;
  height: 500px;
  background: #fafbff;
  border-radius: 5px;
}
.welcome {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.full-vh {
  height: 125vh;
}
.log {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  letter-spacing: 0.055em;
  color: #000000;
}
.btn-log-in {
  width: 196px;
  height: 55px;
  background: #0f75bc;
  border-radius: 4px;
  padding: 15px 75px 16px;
  color: #fafbff;
}
.or {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #382628;
}
.btn-log-otp {
  width: 196px;
  height: 55px;
  background: #ffffff;
  border-radius: 4px;
  color: #747171;
  border: 1px solid #747171;
  border-radius: 4px;
}
.gap-100 {
  gap: 70px;
}
.new-snaptax {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #747171;
}
.create-account {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-decoration: none;
  color: #000000;
}

.forget-password {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  color: #747171;
}
/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>

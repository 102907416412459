<template>
  <div class="w-100">
    <h4
      v-if="label && !label?.toString().includes('*')"
      :class="`label mb-0  ${disabled ? 'disabled' : ''}`"
    >
      {{ label }}
    </h4>
    <h4
      v-if="label && label?.toString().includes('*')"
      :class="`label mb-0  ${disabled ? 'disabled' : ''}`"
    >
      {{ label.toString().replace("*", "") }} <span class="text-danger">*</span>
    </h4>
    <input
      type="date"
      v-model="value"
      :class="`custom-input ${errorMessage ? 'error' : ''}  w-100`"
      :placeholder="placeholder"
      :min="min"
      :max="max"
    />
    <p class="text-danger" v-if="errorMessage">{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  name: "CustomDateInput",
  props: [
    "modelValue",
    "placeholder",
    "label",
    "type",
    "options",
    "errorMessage",
    "min",
    "max",
  ],
  emits: ["update:modelValue"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style scoped>
.custom-input {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  height: 56px;
  padding: 13px 10px;
  margin-top: 7px;
}
.custom-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
  text-align: start;
}
.custom-input:focus {
  outline: 0;
}
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
  margin-bottom: 18px;
}
.custom-input.error {
  border: 1px solid red;
}
</style>

<template>
  <v-dialog v-model="toggle" width="auto" v-bind="modalProps">
    <div class="card-container">
      <div class="d-flex w-100 mt-3 justify-content-center">
        <img
          v-if="!icon"
          src="@/assets/svg/warning-men.svg"
          class="img"
          alt=""
        />
        <img v-else :src="icon" class="custom-img" alt="" />
      </div>
      <div class="d-flex flex-column align-items-center">
        <h4 class="message-text text-center my-3">
          {{ props.message }}
        </h4>
        <h6 v-if="subMessage" class="sub-message-text text-center mb-3">
          {{ subMessage }}
        </h6>
      </div>
      <p v-if="statusMessage" :class="`my-2 text-center text-${props.status}`">
        {{ statusMessage }}
      </p>
      <div class="d-flex justify-content-end mt-4 w-100 mb-5">
        <button
          v-if="hasCancel"
          class="btn btn-primary mr-4"
          @click="handleCancel"
        >
          {{ cancelButtonName }}
        </button>
        <button class="btn btn-danger" @click="handleConfirm">
          {{ buttonName }}
        </button>
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  message: {
    type: String,
    default: "",
  },
  subMessage: {
    type: String,
    default: "",
  },
  onCancel: {
    type: Function,
    default: () => {},
  },
  onConfirm: {
    type: Function,
    default: () => {},
  },
  status: {
    type: String,
    default: "info",
  },
  statusMessage: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: null,
  },
  buttonName: {
    type: String,
    default: "Confirm",
  },
  hasCancel: {
    type: Boolean,
    default: true,
  },
  modalProps: {
    type: Object,
    default: {},
  },
  cancelButtonName: {
    type: String,
    default: "Cancel",
  },
  riseAnEventOnCloaseModal: {
    type: Boolean,
    default: true,
  },
});
const toggle = ref(props.open);

watch(
  () => props.open,
  (newValue) => {
    toggle.value = newValue;
  },
  { deep: true }
);
const handleCancel = async () => {
  await props.onCancel();
  toggle.value = false;
};
const handleConfirm = async () => {
  await props.onConfirm();
  toggle.value = false;
};
watch(toggle, async (newVal) => {
  if (!newVal && props.riseAnEventOnCloaseModal) {
    await props.onCancel();
  }
});
</script>

<style scoped>
.img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.custom-img {
  width: 149px;
  height: 150px;
  object-fit: cover;
}
.btn-confirm {
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
.btn-cancel {
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.card-container {
  max-width: 596px;
  min-height: 119px;
  background: #fafbff;
  border-radius: 5px;
  padding-left: 25px;
  padding-right: 25px;
}
.message-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.sub-message-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
</style>

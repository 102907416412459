import {
  CTaxCalculatorBusiness,
  CTaxCalculatorIndividual,
  QFZCalculator
} from "../components/calculator";

export default {
  path: "/calculator",
  children: [
    { path: "qfz-calculator", component: QFZCalculator },
    { path: "ctax-calculator-business", component: CTaxCalculatorBusiness },
    { path: "ctax-calculator-individual", component: CTaxCalculatorIndividual }
  ]
};

<template>
  <v-dialog v-model="toggle" width="auto" persistent>
    <div class="card-container">
      <div class="d-flex w-100 justify-content-end">
        <img
          src="@/assets/svg/close-circle.svg"
          class="cursor-pointer"
          alt=""
          @click="closeModal()"
        />
      </div>
      <div class="row w-100">
        <div class="col-lg-6">
          <h4 class="title mt-4">
            <b class="bold">User Name : </b>
            <span class="text-dark">{{ props.name }}</span>
          </h4>
        </div>
        <div class="col-lg-6">
          <h4 class="bold title mt-4">
            No. of Entities Assigned :
            <span class="text-dark">{{ selectedEntities.length }}</span>
          </h4>
        </div>
      </div>
      <div class="row w-100">
        <div
          v-for="(item, index) in selectedEntities"
          :key="item.id"
          class="col-lg-4 mb-3"
        >
          <div
            class="entity-container w-100 p-2 d-flex align-items-center justify-content-between mr-2 px-3"
          >
            <div class="d-flex align-items-center justify-content-start">
              <p class="mb-0 data text mr-4">
                <b>{{ index + 1 }}.</b>
              </p>
              <p class="mb-0 data-text mr-4">{{ item.entity_name }}</p>
            </div>
            <p
              class="mb-0 data-text cursor-pointer"
              @click="removeEntity(item.id)"
            >
              <b>x</b>
            </p>
          </div>
        </div>
      </div>

      <p
        v-if="statusMessage.message"
        :class="`my-2 text-${statusMessage.type} text-center`"
      >
        {{ statusMessage.message }}
      </p>
      <div
        class="add-entity-container py-3 w-100 d-flex justify-content-between flex-wrap justify-content-evenly"
      >
        <div class="d-flex align-items-center w-50">
          <button
            v-if="!showEntity"
            @click="showEntitySelect(true)"
            class="btn-submit p-2"
          >
            Add Entity
          </button>

          <div v-else class="w-100 d-flex align-items-center">
            <p class="data-text mr-2"><b>Select Entity</b></p>
            <v-autocomplete
              v-model="selectedEntity"
              item-title="entity_name"
              item-value="id"
              :items="selectableData"
              density="compact"
              bg-color="#deeded"
              custom-class="mt-10px"
              @update:modelValue="onSelectEntity"
            />
          </div>
        </div>
        <div class="d-flex justify-content-end w-50">
          <button class="p-2 btn-submit" @click="saveAssignedEntity">
            Save
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useEntityStore } from "../../store/entity";
import { storeToRefs } from "pinia";
import { getEntityItems } from "../../store/entityActions/get";
import { useUsersStore } from "../../store/users.store";

const props = defineProps({
  open: Boolean,
  setOpen: {
    type: Function,
    default: () => {},
  },
  userId: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    default: "",
  },
  entities: {
    type: Array,
    default: [],
  },
});
const toggle = ref(props.open);

const usersStore = useUsersStore();
const allEntities = ref([]);
const selectedEntity = ref("");
const selectedEntities = ref([]);

const showEntity = ref(false);
const statusMessage = ref({
  message: "",
  type: "info",
});
const entityStore = useEntityStore();
const { entities } = storeToRefs(entityStore);
const selectableData = ref([]);
const closeModal = async () => {
  statusMessage.value.message = "";
  statusMessage.value.type = "";
  toggle.value = false;
  await usersStore.getUsersList();
  props.setOpen(false);
};
const removeEntity = (id) => {
  if (confirm("Are you sure to remove?")) {
    selectedEntities.value = selectedEntities.value.filter(
      (entity) => entity?.id != id
    );
    const find = allEntities.value.find((item) => item.id == id);
    if (find) {
      selectableData.value = [...selectableData.value, find];
    }
  }
};
const onSelectEntity = (id) => {
  const check = selectedEntities.value.find((entity) => entity?.id == id);
  if (check) {
    alert("Entity is added before");
    return;
  }
  const find = selectableData.value.find((entity) => entity?.id == id);
  selectedEntities.value = [...selectedEntities.value, find];
  selectableData.value = selectableData.value.filter((item) => item.id != id);
  showEntitySelect(false);
  selectedEntity.value = "";
};
const showEntitySelect = (value) => {
  showEntity.value = value;
};

const saveAssignedEntity = async () => {
  await usersStore.assignEntity(
    {
      user_id: props.userId,
      entities: selectedEntities.value.map((entity) => entity?.id),
    },
    (message, type) => {
      statusMessage.value.message = message;
      statusMessage.value.type = type;
    },
    async () => {
      setTimeout(async () => {
        closeModal();
      }, 2000);
    }
  );
};
watch(toggle, (newValue) => {
  statusMessage.value.message = "";
  statusMessage.value.type = "";
  props.setOpen(newValue);
});
watch(
  () => props.open,
  (newValue) => {
    statusMessage.value.message = "";
    statusMessage.value.type = "";
    toggle.value = newValue;
  },
  { deep: true }
);
const setData = () => {
  let Data = [];
  for (let i = 0; i < props.entities.length; i++) {
    const id = props.entities[i]?.id;
    const find = selectableData.value.find((entity) => entity.id == id);
    if (find) {
      Data.push(find);
    }
  }
  selectedEntities.value = Data;
  let allData = [];
  Data = [];
  for (let i = 0; i < entities.value.data.length; i++) {
    const entity = entities.value.data[i]?.entity[0];
    const find = selectedEntities.value.find((item) => item.id == entity?.id);
    const item = {
      id: String(entity?.id),
      entity_name:
        entity?.entity_type == 2
          ? `${entity?.entity_first_name} ${entity?.entity_last_name}`
          : entity?.entity_name,
    };
    allData.push(item);
    if (!find) {
      Data.push(item);
    }
  }
  allEntities.value = allData;
  selectableData.value = Data;
};
onMounted(async () => {
  if (!entities.value.data.length) {
    await getEntityItems("entities", "/entity");
  }
  setData();
});
watch(
  () => props.userId,
  () => {
    if (!props.entities.length) {
      selectedEntities.value = [];
    } else {
      setData();
    }
  },
  { deep: true }
);
</script>

<style scoped>
.card-container {
  min-width: 600px;
  max-width: 800px;
  background: #fafbff;
  border-radius: 5px;
  padding: 33px 27px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.card-container::-webkit-scrollbar {
  display: none;
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /* identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
}
.table-header {
  min-height: 39px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2.5px solid #fff;
}
.table-rows {
  background: #ffffff;
  border: 2.5px solid #fff;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input {
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input:focus {
  outline: none;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.delete-button {
  width: 50px;
  height: 44px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.gray {
  background: #deeded;
}
.entity-container {
  background: #deeded;
}
.add-entity-container {
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
  border-top: 1px solid rgba(116, 113, 113, 0.5);
}
.btn-submit {
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  background: #0f75bc;
  color: #fafbff;
}
.entities-container {
  min-height: 100px;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 3;
}
@media screen and (max-width: 600px) {
  .card-container {
    width: 100% !important;
    min-width: unset !important;
    max-width: unset !important;
  }
}
</style>

import { getErrorMessage, getWarningMessage } from "../helpers/errorHandler";

import { defineStore } from "pinia";
import fetchWrapper from "../helpers/fetchWrapper";
import { Toast } from "../helpers/toast";

export const useOtherAdjustmentStore = defineStore("other-adjustments", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      loading: true,
      error: false,
      addition: [],
      deduction: [],
      masterData: {
        addition: "",
        deduction: "",
        total: "",
        total_addtion: "",
        total_deduction: "",
      },
    };
  },
  getters: {
    totalAddition(state) {
      return state.addition.reduce(
        (acc, cur) => acc + Number(cur.amount || 0),
        0
      );
    },
    totalDeduction(state) {
      return state.deduction.reduce(
        (acc, cur) => acc + Number(cur.amount || 0),
        0
      );
    },
    total() {
      return this.totalAddition - this.totalDeduction;
    },
  },
  actions: {
    async getOtherAdjustments(route) {
      try {
        let { financial_period, financial_year, current_business } =
          route.query;
        let entity_id = route.params.id;
        if (
          !financial_period &&
          !financial_year &&
          !entity_id &&
          !current_business
        ) {
          return;
        }
        this.loading = true;
        const business =
          current_business === "master" || !current_business
            ? ""
            : `&entity_business_id=${current_business}`;

        const res = await fetchWrapper.get(
          `/chapter6?entity_id=${entity_id}&financial_year=${financial_year}&financial_period=${financial_period}${business}`
        );
        if (res.data.result == 1) {
          if (current_business === "master") {
            this.masterData = res.data.data;
          } else {
            this.addition = await res.data.data.addition;
            this.deduction = await res.data.data.deduction;
          }
          this.error = await false;
          this.loading = false;
        } else {
          this.error = await getWarningMessage(res);
          this.loading = false;
        }
      } catch (err) {
        console.log(err);
        this.error = getErrorMessage(err);
        this.loading = false;
      }
    },
    async saveOtherAdjustment(
      route,
      callBack = () => {},
      toast = new Toast("Saving Please Wait...")
    ) {
      try {
        let { financial_period, financial_year, current_business } =
          route.query;
        let entity_id = route.params.id;
        if (
          !financial_period &&
          !financial_year &&
          !entity_id &&
          !current_business
        ) {
          return;
        }
        const payload = {
          entity_id,
          financial_year,
          financial_period,
          entity_business_id: current_business,
          addition: this.addition,
          deduction: this.deduction,
          total_addition: this.totalAddition,
          total_deduction: this.totalDeduction,
          total: this.total,
        };
        const res = await fetchWrapper.post(`/chapter6`, payload);
        if (res.data.result == 1) {
          toast.makeSuccessToast(res.data.message);
          callBack(res.data, toast);
        } else {
          toast.makeWarningToast(getWarningMessage(res));
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(getErrorMessage(err));
      }
    },
  },
});

<template>
  <SideNavContainer
    :TitleRightSide="FinancialDataDateFilter"
    title="Financial Data"
    side-nav-type="financialData"
  >
    <EntityChecker :check-mandatory-fields="true">
      <IFRSCheckerContainer :have-loader="false" :check-status="true">
        <BalanceSheetViewBreadCrumb :entity-name="name" />
        <div class="row">
          <div class="col-lg-12">
            <Business :ifrs="data" :view="true" />
          </div>
        </div>
        <div
          class="mt-3 d-flex w-100 align-items-center justify-content-between"
        >
          <h4 class="balance-sheet-title">Balance Sheet View</h4>
          <button class="btn-download" @click="downloadFile">
            <img src="@/assets/svg/download.svg" class="mr-2" alt="" />
            Download
          </button>
        </div>
        <LoaderContainer :loading="financialTypeLoading" class="row">
          <LoaderContainer
            v-if="data === 'IFRS'"
            :loading="assetsLoading || liabilitiesLoading"
          >
            <h4 class="my-3 balance-sheet-type">Assets</h4>
            <ParticularsAssets />

            <FVTPLAssets :view="true" />
            <DerivativeFinacialInstruments :view="true" />
            <FVOCIAssets :view="true" />
            <AmortisedCostAssets :view="true" />
            <TradeAndReceivablesAssets :view="true" />
            <InvestmentProperties :view="true" />
            <PlantAndEquipment :view="true" />
            <IntangibleAssets :view="true" />
            <DeferredIncomeTax :view="true" />
            <InvestmentJointVenture :view="true" />
            <InvestmentsInAssociate :view="true" />
            <Total />
            <CurrentAssetParticulars :view="true" />
            <CashEquivalents :view="true" />
            <currentAssetDerivatve :view="true" />

            <currentAssetTrade :view="true" />

            <currentAssetInventories :view="true" />

            <NoncurrentAssetClassifiedSale :view="true" />
            <CurrentAssetTotal :view="true" />
            <h4 class="my-5 balance-sheet-type">Liabilities</h4>

            <ShareHolderEquity :view="true" />
            <ShareCapital :view="true" />
            <TreasuryShares :view="true" />
            <OtherReserves :view="true" />
            <RetainedProfits :view="true" />
            <TotalShareHoldersEquity :view="true" />
            <Borrowings :view="true" />
            <Provisions :view="true" />
            <DeferredTax :view="true" />
            <TotalNonCurrentLiabilities :view="true" />
            <CurrentLiabilityTrade :view="true" />
            <TotalCurrentLiabilities :view="true" />
          </LoaderContainer>
          <NonIFRSBsView v-if="data === 'Non-IFRS'" />
        </LoaderContainer>
      </IFRSCheckerContainer>
    </EntityChecker>
  </SideNavContainer>
</template>

<script setup>
import { SideNavContainer } from "@/components/layout";
import FinancialDataDateFilter from "../../DateHandler/BalanceSheet/ViewDateHandler.vue";

import ParticularsAssets from "../Assets/Particulars.vue";
import FVTPLAssets from "../Assets/FVTPL.vue";
import DerivativeFinacialInstruments from "../Assets/DerivativeInstruments.vue";
import FVOCIAssets from "../Assets/FVOCI.vue";
import AmortisedCostAssets from "../Assets/AmortisedCost.vue";
import TradeAndReceivablesAssets from "../Assets/TradeAndReceivables.vue";
import InvestmentProperties from "../Assets/InvestmentProperties.vue";
import PlantAndEquipment from "../Assets/PlantAndEquipment.vue";
import IntangibleAssets from "../Assets/IntangibleAssets.vue";
import DeferredIncomeTax from "../Assets/DeferredIncomeTax.vue";
import InvestmentJointVenture from "../Assets/InvestmentJointVenture.vue";
import InvestmentsInAssociate from "../Assets/InvestmentsInAssociates.vue";
import Total from "../Assets/Total.vue";
import CurrentAssetParticulars from "../Assets/Current/CurrentAssetParticulars.vue";
import CashEquivalents from "../Assets/Current/CashEquivalents.vue";
import currentAssetDerivatve from "../Assets/Current/currentAssetDerivatve.vue";

import currentAssetTrade from "../Assets/Current/currentAssetTrade.vue";

import currentAssetInventories from "../Assets/Current/currentAssetInventories.vue";

import NoncurrentAssetClassifiedSale from "../Assets/Current/NoncurrentAssetClassifiedSale.vue";
import CurrentAssetTotal from "../Assets/Current/CurrentAssetTotal.vue";

import ShareHolderEquity from "../Liabilities/ShareHolderAmount.vue";
import ShareCapital from "../Liabilities/ShareCapital.vue";
import TreasuryShares from "../Liabilities/TreasuryShares.vue";
import OtherReserves from "../Liabilities/OtherReserves.vue";
import RetainedProfits from "../Liabilities/RetainedProfits.vue";
import TotalShareHoldersEquity from "../Liabilities/TotalShareHoldersEquity.vue";
import Borrowings from "../Liabilities/Borrowings.vue";
import Provisions from "../Liabilities/Provisions.vue";
import DeferredTax from "../Liabilities/DeferredTax.vue";
import TotalNonCurrentLiabilities from "../Liabilities/TotalNonCurrentLiabilities.vue";
import CurrentLiabilityTrade from "../Liabilities/Current/CurrentLiabilityTrade.vue";
import TotalCurrentLiabilities from "../Liabilities/Current/TotalCurrentLiabilities.vue";
import { useLiabilityDataStore } from "@/store/liabities.store";
import { useRoute } from "vue-router";
import Business from "../../BusinessHandler/BalanceSheet/ViewBusiness.vue";
import { onMounted, ref, computed } from "vue";
import { useFinancialDataStore } from "../../../../../store/financial.store";
import { BalanceSheetViewBreadCrumb } from "../../../../BreadCrumbs";
import IFRSCheckerContainer from "../../IFRSCheckerContainer.vue";
import { useIFRSStore } from "../../../../../store/NonIFRS/IFRS.store";
import { storeToRefs } from "pinia";
import NonIFRSBsView from "../../NON-IFRS/BalanceSheet/view.vue";
import { LoaderContainer } from "../../../../common";
import EntityChecker from "../../../AddEntity/EntityChecker.vue";
import { useEntityStore } from "../../../../../store/entity";
const route = useRoute();
const message = ref("");
const financialDataStore = useFinancialDataStore();
const ifrsStore = useIFRSStore();
const entityStore = useEntityStore();
const { entity } = storeToRefs(entityStore);
const { data, loading: financialTypeLoading } = storeToRefs(ifrsStore);
const liablityStore = useLiabilityDataStore();
const assetStore = useFinancialDataStore();
const { loading: liabilitiesLoading } = storeToRefs(liablityStore);
const { loading: assetsLoading } = storeToRefs(assetStore);
const name = computed(() => {
  if (!entity.value.data) return "";
  const Entity = entity.value.data?.entity[0];
  if (Entity?.entity_type == 2) {
    return `${Entity?.entity_first_name} ${Entity?.entity_last_name}`;
  }
  return Entity?.entity_name;
});
const downloadFile = async () => {
  await financialDataStore.saveBalanceSheetFile(
    route.params.id,
    route.query.financial_year,
    route.query.financial_period,
    route.query.business,
    (mess) => {
      message.value = mess;
    }
  );
};
onMounted(() => {
  liabilitiesLoading.value = true;
  assetsLoading.value = true;
});
</script>

<style scoped>
.balance-sheet-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.055em;
  color: #000000;
}
.btn-download {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 141px;
  height: 44px;
  background: #0f75bc;
  color: #fafbff;
  border-radius: 4px;
}
.balance-sheet-type {
  font-style: normal;
  font-weight: 400;
  font-size: 25.888px;
  line-height: 39px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

<template>
  <div class="w-100 mt-4">
    <FormCheckList article="article_27_checklist" />
    <div v-if="showForm" class="w-100 mt-4">
      <div class="w-100 d-flex align-items-center justify-content-between">
        <h4 class="title bold">Name of Assets</h4>
        <div class="d-flex align-items-center">
          <button class="save-button mr-3" @click="saveData()">Save</button>
          <button
            v-if="!status"
            class="save-button mr-3"
            @click="addNewForm('article_27_assets')"
          >
            <img src="@/assets/svg/plus-square-light.svg" alt="" />
            Add New
          </button>
        </div>
      </div>
      <FormHeader article="assets">
        <Form
          v-for="item in data.article_27_assets"
          :id="item.id"
          :key="item.id"
          article="article_27_assets"
        />
      </FormHeader>
      <div class="w-100 mt-4 d-flex align-items-center justify-content-between">
        <h4 class="title bold">Name of Liability</h4>
        <div class="d-flex align-items-center">
          <button class="save-button mr-3" @click="saveData()">Save</button>
          <button
            v-if="!status"
            class="save-button mr-3"
            @click="addNewForm('article_27_liabilities')"
          >
            <img src="@/assets/svg/plus-square-light.svg" alt="" />
            Add New
          </button>
        </div>
      </div>
      <FormHeader article="liabilities">
        <Form
          v-for="item in data.article_27_liabilities"
          :id="item.id"
          :key="item.id"
          article="article_27_liabilities"
        />
      </FormHeader>
    </div>
    <TaxableCheckList />
    <div
      v-if="
        data.article_27_incident_occurs
          .the_shares_or_other_ownership_interests_in_the_taxable_person &&
        data.article_27_incident_occurs
          .there_is_a_subsequent_transfer_or_disposal_of_the_business
      "
      class="w-100 mt-4"
    >
      <div class="w-100 my-3 d-flex align-items-center justify-content-between">
        <h4 class="title bold">Assets</h4>
        <button
          v-if="!status"
          class="save-button mr-3"
          @click="addNewTaxableForm('assets')"
        >
          <img src="@/assets/svg/plus-square-light.svg" alt="" />
          Add New
        </button>
      </div>
      <TaxableLineItemHeader>
        <TaxableLineItemForm
          v-for="item in data.article_27_taxable_lineitem?.assets"
          :id="item.id"
          :key="item.id"
          article="article_27_taxable_lineitem"
        />
      </TaxableLineItemHeader>
      <div class="col-lg-12">
        <div
          class="table-body gray py-3 pr-3 d-flex align-items-center w-100 justify-content-end"
        >
          <p class="sub-title bold mb-0 mr-10">Total Asset Taxable Amount</p>
          <p class="total-amount bold mb-0">
            {{ NumberFormatter(article27AssetsTotalTaxable) }}
          </p>
        </div>
      </div>
      <div class="w-100 my-3 d-flex align-items-center justify-content-between">
        <h4 class="title bold">Liabilities</h4>
        <button
          v-if="!status"
          class="save-button mr-3"
          @click="addNewTaxableForm('liabilities')"
        >
          <img src="@/assets/svg/plus-square-light.svg" alt="" />
          Add New
        </button>
      </div>
      <TaxableLineItemHeader type="liabilities">
        <TaxableLineItemForm
          v-for="item in data.article_27_taxable_lineitem?.liabilities"
          :id="item.id"
          :key="item.id"
          type="liabilities"
          article="article_27_taxable_lineitem"
        />
      </TaxableLineItemHeader>
      <div class="col-lg-12">
        <div
          class="table-body gray py-3 pr-3 d-flex align-items-center w-100 justify-content-end"
        >
          <p class="sub-title bold mb-0 mr-10">
            Total Liabilities Taxable Amount
          </p>
          <p class="total-amount bold mb-0">
            {{ NumberFormatter(article27LiabilitiesTotalTaxable) }}
          </p>
        </div>
      </div>
      <div class="col-lg-12">
        <div
          class="table-body border-bottom py-3 pr-3 d-flex align-items-center w-100 justify-content-end"
        >
          <p class="sub-title bold mb-0 mr-10">Total Taxable Amount</p>
          <p class="total-amount bold mb-0">
            {{ NumberFormatter(article27TotalTaxableAmount) }}
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 my-4">
        <h4 class="title bold">Reference for Clause Below-</h4>
        <p class="article"></p>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-title
              collapse-icon="mdi-minus"
              expand-icon="mdi-plus"
            >
              <div
                class="px-3 py-3 w-100 d-flex align-items-center justify-content-between"
              >
                <h6 class="article-title mb-0">
                  {{ article27.name }}
                </h6>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div v-html="article27.description"></div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script setup>
import Form from "./Form.vue";
import ReferenceClause from "./ReferenceClause27.vue";
import { useChapter8Store } from "../../../../store/chapter8";
import { storeToRefs } from "pinia";
import FormCheckList from "./FormCheckList.vue";
import TaxableCheckList from "./TaxableCheckList27.vue";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { generate } from "randomized-string";
import TaxableLineItemForm from "./TaxableLineItemForm.vue";
import { NumberFormatter } from "../../../../helpers/numberFormatter";
import FormHeader from "./FormHeader.vue";
import TaxableLineItemHeader from "./TaxableLineItemHeader.vue";
import { useStatusStore } from "../../../../store/statusStore";
const statusStore = useStatusStore();
const { status } = storeToRefs(statusStore);
const chapter8Store = useChapter8Store();
const {
  data,
  article27AssetsTotalTaxable,
  article27LiabilitiesTotalTaxable,
  article27TotalTaxableAmount,
} = storeToRefs(chapter8Store);
const showForm = ref(false);
const route = useRoute();
const article27 = ref({
  name: "",
  description: "",
});
const addNewTaxableForm = (type) => {
  data.value.article_27_taxable_lineitem[type].push({
    id: generate(),
    date_of_initial_transfer: "",
    net_book_value: 0,
    market_value: "",
    subsequent_transfer_date: "",
    taxable_amount: 0,
  });
};
watch(
  data,
  async (newValue, oldValue) => {
    const values = [];
    for (let newValueX in newValue.article_27_checklist) {
      const newValueData = newValue.article_27_checklist[newValueX];
      values.push(newValueData);
    }
    showForm.value = values.reduce((a, b) => {
      return a && b;
    });
    article27.value = newValue.articles.find(
      (article) => article.name == "Article 27"
    );
  },
  { deep: true }
);
const addNewForm = (type) => {
  data.value[type].push({
    id: generate(),
    name_of_asset: "",
    sale_value: 0,
    sale_date: "",
    net_book_value_as_on_sale_date: 0,
    purchase_value: 0,
    purchase_date: "",
    net_book_value_as_on_purchase_date: 0,
  });
};
const saveData = async () => {
  await chapter8Store.saveChapter8(route);
};
onMounted(() => {
  const values = [];
  for (let x in data.value.article_27_checklist) {
    values.push(data.value.article_27_checklist[x]);
  }
  showForm.value = values.reduce((a, b) => {
    return a && b;
  });
  article27.value = data.value.articles?.find(
    (article) => article.name == "Article 27"
  );
});
</script>

<style scoped>
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.btn-add {
  width: 124.33px;
  height: 47px;
  background: #0f75bc;
  border-radius: 5px;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.header {
  padding: 6px 9px;
  height: 47px;
  background: #0f75bc;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
.custom-input {
  height: 44px;
  padding: 11px 13px;
  margin-top: 10px;
  border: 1px solid rgba(116, 113, 113, 0.3);
}
.custom-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
  text-align: start;
}
.article {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
.gray {
  background: #deeded;
}
.disabled {
  background-color: #eee;
}
.border-bottom {
  border: 1px solid #ede4e4;
}
</style>

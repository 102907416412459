<template>
  <BreadCrumb :links="breadCrumbLinks" />
</template>

<script setup>
import { ref } from "vue";
import { generate } from "randomized-string";
import BreadCrumb from "../common/BreadCrumb.vue";
import { useRoute } from "vue-router";

const route = useRoute();
const breadCrumbLinks = ref([
  {
    id: generate(),
    name: "Home",
    link: "/",
    disabled: false,
    active: false
  },
  {
    id: generate(),
    name: "Entity",
    link: "/entity",
    disabled: false,
    active: false
  },
  {
    id: generate(),
    name: "Master Data",
    link: "/entity/master-data/" + route.params.id,
    disabled: false,
    active: false
  },
  {
    id: generate(),
    name: "General information",
    link:
      "/entity/general-information/" +
      route.params.id +
      `${
        route.query.liableToAudit
          ? `?liableToAudit=${route.query.liableToAudit}${
              route.query.qfzp ? `&qfzp=${route.query.qfzp}` : ""
            }`
          : route.query.qfzp
          ? `?qfzp=${route.query.qfzp}`
          : ""
      }`,
    disabled: false,
    active: false
  },
  {
    id: generate(),
    name: "FZP Calculator",
    link:
      "/entity/general-information/fzp-calculator/" +
      route.params.id +
      `${
        route.query.liableToAudit
          ? `?liableToAudit=${route.query.liableToAudit}${
              route.query.qfzp ? `&qfzp=${route.query.qfzp}` : ""
            }`
          : route.query.qfzp
          ? `?qfzp=${route.query.qfzp}`
          : ""
      }`,
    disabled: false,
    active: true
  }
]);
</script>

<style lang="scss" scoped></style>

<template>
  <div class="d-flex flex-wrap justify-content-end align-items-center">
    <select
      v-model="financial_period"
      disabled
      class="date-select pl-2 mt-2 mr-3"
    >
      <option
        v-for="(year, index) in financialPeriod.data"
        :key="year.id"
        class="option"
        :value="year.id"
        :selected="!index"
      >
        {{ year.name }}
      </option>
    </select>
    <select v-model="financial_year" class="date-select pl-2 mt-2 mr-3">
      <option
        v-for="(year, index) in financialYear.data"
        :key="year.id"
        class="option"
        :value="year.id"
        :selected="!index"
      >
        {{ year.name }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { onMounted, onUnmounted, ref, watch } from "vue";

import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import { useEntityStore } from "../../../../../store/entity";
import {
  getEntityItems,
  getEntityItem,
} from "../../../../../store/entityActions/get";
const entityStore = useEntityStore();
const route = useRoute();
const router = useRouter();
const {
  financialYear: financialPeriod,
  financialPeriod: financialYear,
  entity,
} = storeToRefs(entityStore);
const financial_year = ref(
  route.query.financial_year ? route.query.financial_year : ""
);
const financial_period = ref(
  route.query.financial_period ? route.query.financial_period : 1
);
const business_id = ref(route.query.business ? route.query.business : "");
watch(
  () => route.query,
  (newValue, oldValue) => {
    if (newValue.business != oldValue.business)
      business_id.value = newValue.business;
  },
  { deep: true }
);

onMounted(async () => {
  if (!financialPeriod.value.data.length) {
    await getEntityItems("financialYear", "/financialYear");
  }

  const Financial_period = entity.value.data?.entity[0]?.financial_period?.id;
  financial_period.value = Financial_period
    ? Financial_period
    : financial_period.value;
  await getEntityItem(
    "financialPeriod",
    "/financialYear",
    financial_period.value
  );
  const fy = await localStorage.getItem("financial_year");
  if (fy) {
    financial_year.value = fy;
  } else {
    financial_year.value = financial_year.value
      ? financial_year.value
      : financialYear.value.data[0]?.id;
    localStorage.setItem("financial_year", financial_year.value);
  }
  router.push(
    `/entity/financial-data/profit-loss/view/${
      route.params.id
    }?financial_period=${financial_period.value}&financial_year=${
      financial_year.value
    }${route.query.business ? "&business=" + route.query.business : ""}`
  );
});

const unWatch = watch(financial_year, async (newValue) => {
  localStorage.setItem("financial_year", newValue);
  const { pathname } = window.location;
  const tab = pathname.split("/")[4];
  if (tab === "view") {
    router.push(
      `/entity/financial-data/profit-loss/view/${
        route.params.id
      }?financial_period=${financial_period.value}&financial_year=${newValue}${
        route.query.business ? "&business=" + route.query.business : ""
      }`
    );
  }
});
onBeforeRouteLeave(async () => {
  await unWatch();
  return true;
});
onUnmounted(async () => {
  await unWatch();
});
</script>

<style scoped>
.date-select {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  width: 163px;
  height: 40px;
}
.date-select:focus {
  outline: none;
}
.option {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

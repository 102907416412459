<template>
  <div class="w-100">
    <div class="header d-flex align-items-center w-100 justify-content-between">
      <p class="header-title mb-0">Continuing Operations</p>
      <p class="header-title mb-0">Amount</p>
    </div>
    <div
      class="table-body d-flex align-items-center w-100 justify-content-between"
    >
      <p class="sub-title mb-0">Indirect Expenses</p>
      <p class="total-amount mb-0">
        {{ NumberFormatter(totalIndirectExpenses) }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useExpenditureStore } from "../../../../../store/expenditure";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";

const expenditureStore = useExpenditureStore();
const { totalIndirectExpenses } = storeToRefs(expenditureStore);
</script>

<style scoped>
.header {
  height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.table-body {
  height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}
.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}
.white {
  background: #fafbff;
}
.gray {
  background: #deeded;
}
.table-body.sub {
  padding-left: 46px;
}
.sub-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
</style>

<template>
  <div
    class="full-vh d-flex flex-column justify-content-center align-items-center gap-100"
  >
    <LoaderContainer :loading="loading">
      <div
        class="wrapper d-flex flex-column justify-content-evenly align-items-center"
      >
        <h1 class="welcome">Welcome Back!</h1>
        <h2 class="log">Login to Your Account</h2>
        <div class="w-100">
          <p class="mb-3 label">Phone Number</p>
          <div class="d-flex align-items-center">
            <country-flag
              v-if="country_code"
              :country="country_code"
              class="mr-1 mt-10px"
              size="big"
            />

            <v-autocomplete
              v-model="mobile_code"
              item-title="calling_code"
              item-value="calling_code"
              :items="countries.data"
              custom-class=""
              density="compact"
              bg-color="#fafbff"
            />
            <input
              type="number"
              class="account-input w-75"
              :value="phone_number"
              placeholder="Enter your phone number"
              @input="updateValue"
            />
          </div>
          <p class="text-danger" v-if="error.phone_number">
            {{ error.phone_number }}
          </p>
        </div>
        <p class="text-info">{{ message }}</p>
        <button v-if="verify" class="mt-3 btn-log-otp" @click="sendLoginOTp()">
          Send otp
        </button>
        <VerifyLogin
          :OTP_ID="otp_id"
          :Dialog="dialog"
          :mobile_no="phone_number"
          :set-dialog="setDialog"
          :mobile-code="mobile_code"
        />
      </div>
      <p class="new-snaptax">
        New to SnapTax?
        <router-link to="/account/signup">
          <b class="create-account">Create an account</b>
        </router-link>
      </p>
    </LoaderContainer>
  </div>
</template>

<script>
import VerifyLogin from "./VerifyLogin.vue";
import { useProfileStore } from "../../store/profile";
import { useCountryStore } from "../../store/country";
import LoaderContainer from "../common/LoaderContainer.vue";

export default {
  name: "LoginComponent",
  components: { VerifyLogin, LoaderContainer },
  data() {
    return {
      phone_number: "",
      dialog: false,
      otp_id: "",
      verify: false,
      message: "",
      counting: false,
      country_code: "are",
      mobile_code: "+971",
      countries: [],
      loading: true,
      error: {
        phone_number: "",
      },
    };
  },
  watch: {
    email() {
      this.error.email = "";
    },
    password() {
      this.error.password = "";
    },
    async phone_number(newValue) {
      if (String(newValue).length === 10 || String(newValue).length === 9) {
        this.verify = true;
        this.error.phone_number = false;
      } else {
        this.verify = false;
        this.error.mobile_no = "Please give 10 characters";
      }
    },
    mobile_code(newValue) {
      if (!newValue) {
        return;
      }
      const findCountry = this.countries.data.find(
        (country) => country.calling_code === newValue
      );
      const code = findCountry ? findCountry?.code?.toLowerCase() : "";
      this.country_code = code === "uae" ? "are" : code;
      this.error.mobile_code = "";
    },
  },
  async created() {
    this.loading = true;
    const countryStore = useCountryStore();
    this.countries = countryStore.countries;
    if (!countryStore.countries.data.length) {
      await countryStore.getCountryList();
      this.countries = countryStore.countries;
    }
    this.loading = false;
  },
  methods: {
    setMessage(message) {
      this.message = message;
    },
    makeVarified() {
      this.verify = true;
    },
    startCountdown() {
      this.counting = true;
    },
    onCountdownEnd() {
      this.counting = false;
    },
    setDialog(dialog) {
      this.dialog = dialog;
    },
    updateValue(event) {
      const value = event.target.value;
      if (String(value).length <= 10) {
        this.phone_number = value;
      }
      this.$forceUpdate();
    },
    async sendLoginOTp() {
      const profileStore = useProfileStore();
      await profileStore.getLoginOtp(
        this.phone_number,
        this.mobile_code,
        this.setMessage,
        (otpId) => {
          this.dialog = true;
          this.otp_id = otpId;
        }
      );
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;
  height: 500px;
  background: #fafbff;
  border-radius: 5px;
}
.welcome {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.full-vh {
  height: 125vh;
}
.log {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  letter-spacing: 0.055em;
  color: #000000;
}
.btn-log-in {
  width: 196px;
  height: 55px;
  background: #0f75bc;
  border-radius: 4px;
  padding: 15px 75px 16px;
  color: #fafbff;
}
.or {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #382628;
}
.btn-log-otp {
  width: 196px;
  height: 55px;
  background: #ffffff;
  border-radius: 4px;
  color: #747171;
  border: 1px solid #747171;
  border-radius: 4px;
}
.gap-100 {
  gap: 70px;
}
.new-snaptax {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #747171;
}
.create-account {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-decoration: none;
  color: #000000;
}
.account-input {
  margin-top: -15px;
  border: none;
  border-bottom: 1px solid #747171;
  padding-bottom: 8px;
}
.account-input::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #747171;
  text-align: left !important;
}
.account-input:focus {
  outline: 0;
  border-bottom: 1px solid #0f75bc;
}
span {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.eye-off {
  margin-left: -30px;
  margin-bottom: 15px;
}
.label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
  z-index: 10;
}
.error {
  border-bottom: 1px solid red;
}
.mt-10px {
  margin-top: -10px !important;
}
.eye {
  height: 30px;
  width: 25px;
}
.mt-10px {
  margin-top: -10px;
}
/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>

<template>
  <div class="chart-container w-100 d-flex justify-content-center">
    <Doughnut
      v-if="chartData"
      width="100%"
      :data="chartData"
      :options="options"
    />
  </div>
</template>

<script setup>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { onMounted, ref, watch, defineProps } from "vue";
import { Doughnut } from "vue-chartjs";

ChartJS.register(ArcElement, Tooltip, Legend);

const props = defineProps({
  assetLiabilitiesComparison: {
    type: Object,
    default: null
  }
});
const chartData = ref(null);
const options = ref({
  responsive: true
});

watch(
  () => props.assetLiabilitiesComparison,
  (newValue) => {
    let labels = [];
    let dataNumber = [];
    for (let x in newValue) {
      labels.push(makeAppropriateLabel(x));
      dataNumber.push(newValue[x]);
    }
    chartData.value = {
      labels: labels,
      datasets: [
        {
          data: dataNumber,
          backgroundColor: ["#00a7a7", "#111827"]
        }
      ]
    };
  },
  { deep: true }
);
const makeAppropriateLabel = (word) => {
  const split = word.toString().split("_");
  const firstName = split[0];
  const firstLetter = firstName.charAt(0).toUpperCase();
  const fistNameLastLetters = firstName.slice(1);
  let newWord = firstLetter + fistNameLastLetters + " ";
  for (let i = 1; i < split.length; i++) {
    newWord += split[i] + " ";
  }
  return newWord;
};
onMounted(() => {
  let labels = [];
  let dataNumber = [];
  for (let x in props.assetLiabilitiesComparison) {
    labels.push(makeAppropriateLabel(x));
    dataNumber.push(props.assetLiabilitiesComparison[x]);
  }
  chartData.value = {
    labels: labels,
    datasets: [
      {
        data: dataNumber,
        backgroundColor: ["#00a7a7", "#111827"]
      }
    ]
  };
});
</script>

<style scoped>
.chart-container {
  height: 250px;
}
</style>

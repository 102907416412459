import BriefCase from "@/assets/svg/briefcase.svg";
import PaperIcon from "@/assets/svg/Paper_light.svg";
import HomeIcon from "@/assets/svg/Home_light.svg";
import FileIcon from "@/assets/svg/File-Icon.svg";

import { generate } from "randomized-string";

export const mainNavigation = [
  {
    id: generate(),
    link: "/",
    name: "Dashboard",
    icon: HomeIcon
  },
  {
    id: generate(),
    link: "/profile",
    name: "Profile"
  },
  {
    id: generate(),
    link: "/users",
    name: "Users"
  },
  {
    id: generate(),
    link: "/entity",
    name: "Entity"
  },
  {
    id: generate(),
    link: "/subscription",
    name: "Subscription",
    icon: PaperIcon
  }
];

export const subUseNavigation = [
  {
    id: generate(),
    link: "/",
    name: "Dashboard",
    icon: HomeIcon
  },
  {
    id: generate(),
    link: "/profile",
    name: "Profile"
  },
  {
    id: generate(),
    link: "/entity",
    name: "Entity"
  }
];

export const getFinancialRoute = (id, routeQuery) => {
  let query =
    routeQuery.financial_year && routeQuery.financial_period
      ? `?financial_period=${routeQuery.financial_period}&financial_year=${
          routeQuery.financial_year
        }${
          routeQuery.business
            ? "&business=" + routeQuery.business
            : routeQuery.current_business
            ? "&business=" + routeQuery.current_business
            : ""
        }`
      : "";
  let giQuery =
    routeQuery.financial_year && routeQuery.financial_period
      ? `?financial_year=${routeQuery.financial_year}&financial_period=${routeQuery.financial_period}`
      : "";
  return [
    {
      id: generate(),
      link: "/",
      name: "Dashboard",
      icon: HomeIcon
    },
    {
      id: generate(),
      link: "/entity/dashboard/" + id,
      name: "Entity dashboard"
    },
    {
      id: generate(),
      link: "/entity/master-data/" + id,
      name: "Entity Master Data"
    },
    {
      id: generate(),
      link: "/entity/financial-data/" + id + query,
      name: "Entity Financial Data",
      icon: BriefCase
    },
    {
      id: generate(),
      link: "/entity/general-information/" + id + giQuery,
      name: "General Information",
      icon: FileIcon
    },
    {
      id: generate(),
      link: "/entity/computation-tax/" + id,
      name: "Computation of Income tax",
      icon: BriefCase
    }
  ];
};

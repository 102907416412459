import { defineStore } from "pinia";
import { getErrorMessage, getWarningMessage } from "../../helpers/errorHandler";
import fetchWrapper from "../../helpers/fetchWrapper";
import { Toast } from "../../helpers/toast";

export const useIFRSStore = defineStore("ifrs", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically

      data: null,
      loading: true,
      error: {
        type: "",
        message: ""
      },
      financial_year: "",
      entity_id: "",
      financial_period: "",
      qualified_for_freezone: "",
      license_authority_type: ""
    };
  },

  actions: {
    async getIFRSCheck(
      entity_id,
      financial_year,
      financial_period,
      callBack = () => {}
    ) {
      if (financial_year && financial_period) {
        try {
          this.loading = true;
          const res = await fetchWrapper.get(
            `/financial_type?entity_id=${entity_id}&financial_year=${financial_year}&financial_period=${financial_period}`
          );
          if (res.data.result == 1) {
            const Data = res.data.financial_type;
            this.qualified_for_freezone = await res.data.qualified_for_freezone;
            this.license_authority_type = await res.data.license_authority_type;
            this.data = await Data;
            this.financial_year = await financial_year;
            this.entity_id = await entity_id;
            this.financial_period = await financial_period;
            this.error = await false;
            this.loading = await false;
            callBack({
              data: res.data.financial_type,
              qualified_for_freezone: res.data.qualified_for_freezone,
              license_authority_type: res.data.license_authority_type
            });
          } else {
            this.data = "";
            this.error = {
              type: "not-decided",
              message: "Please choose financial type, " + getWarningMessage(res)
            };

            this.financial_year = "";
            this.entity_id = "";
            this.financial_period = "";
            this.loading = false;
            callBack({
              data: ``,
              qualified_for_freezone: "",
              license_authority_type: ""
            });
          }
        } catch (err) {
          console.log(err);
          this.error = {
            type: "error",
            message: getErrorMessage(err)
          };
          this.loading = false;
          this.entity_id = "";
          this.financial_period = "";
          this.loading = false;
        }
      }
    },
    async saveFinancialType(
      entity_id,
      financial_year,
      financial_period,
      financial_type,
      callBack = () => {},
      toast = new Toast("Saving please wait...")
    ) {
      try {
        if (entity_id && financial_year && financial_period && financial_type) {
          const payload = {
            entity_id,
            financial_year,
            financial_period,
            financial_type
          };
          const res = await fetchWrapper.post(`/financial_type`, payload);
          if (res.data.result === 1) {
            await toast.makeSuccessToast(`Financial Data saved succesfully!`);
            callBack(res.data, toast);
          } else {
            toast.makeErrorToast(getWarningMessage(res));
          }
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(getErrorMessage(err));
      }
    }
  }
});

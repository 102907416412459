import { defineStore } from "pinia";
import { basicDataStructures } from "../helpers/storeStructure";
import fetchWrapper from "../helpers/fetchWrapper";

export const useVersionControllStore = defineStore("version-controll", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      version: {
        ...basicDataStructures,
        version: "",
      },
    };
  },
  actions: {
    async getCurrentVerion(openCloseModal) {
      try {
        const res = await fetchWrapper.get("/version");
        if (res.data?.result === 1) {
          const version = await localStorage.getItem("version");
          const newVersion = res.data?.version;
          if (version !== newVersion) {
            await localStorage.setItem("version", newVersion);
            const nonPrivateRoutes = [
              "/account/signup",
              "/account/signin",
              "/account/login-otp",
              "/account/forget-password",
            ];
            if (!nonPrivateRoutes.includes(window.location.pathname)) {
              openCloseModal(true);
            } else location.reload(true);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async setCurrentVerion() {
      try {
        const res = await fetchWrapper.get("/version");
        if (res.data?.result === 1) {
          const version = await localStorage.getItem("version");
          const newVersion = res.data?.version;
          if (version !== newVersion) {
            await localStorage.setItem("version", newVersion);
            //location.reload(true);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
});

<template>
  <div class="w-100">
    <div class="header d-flex align-items-center w-100 justify-content-between">
      <p class="header-title mb-0">Other (losses)/gains – net</p>
      <p class="header-title mb-0">
        {{ NumberFormatter(otherLossesGains) }}
      </p>
    </div>
    <form
      v-for="(item, index) in data"
      :id="item.model"
      :key="item.id"
      :class="`w-100 table-body ${!index || !(index % 2) ? 'white' : 'gray'} ${
        item.sub ? 'sub' : ''
      } d-flex align-items-center justify-content-between`"
    >
      <p class="mb-0 body-title">{{ item.name }}</p>
      <p
        v-if="!item.showInput"
        class="body-title mb-0"
        @click="openCloseInput(item.model, true)"
      >
        {{ NumberFormatter(OthersIncome[item.model]) }}
      </p>
      <input
        :id="'input-' + item.model"
        v-model="OthersIncome[item.model]"
        :hidden="!item.showInput"
        type="number"
        class="clickable-text"
        @blur="openCloseInput(item.model, false)"
        @keydown.enter.tab="(e) => handleSubmit(e, item.model)"
      />
    </form>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useExpenditureStore } from "../../../../../store/expenditure";
import { storeToRefs } from "pinia";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";
import { useRoute } from "vue-router";
import { otherLossesGainsData } from "./data";
const props = defineProps({
  view: {
    type: Boolean,
    default: false,
  },
});
const route = useRoute();
const data = ref(otherLossesGainsData);
const openCloseInput = async (model, value) => {
  if (props.view || route.query.business === "master") {
    return;
  }
  const newValues = data.value.map((item) => {
    if (item.model === model) {
      return { ...item, showInput: value };
    }
    return item;
  });
  data.value = newValues;
  if (value) {
    const input = await document.getElementById(`input-${model}`);
    input.focus();
  }
};
const expenditureStore = useExpenditureStore();
const { OthersIncome, otherLossesGains } = storeToRefs(expenditureStore);

const handleSubmit = async (e, current_model) => {
  e.preventDefault();
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}

.sub-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}

.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}

.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
</style>

<template>
  <div class="col-lg-6">
    <Chapter7Popup
      :open="open"
      :set-open="setModal"
      :model="model"
      type="deduction_item"
    />
    <div class="header d-flex align-items-center w-100 justify-content-between">
      <p class="header-title mb-0">Deduction from Taxable Income</p>
      <p class="header-title mb-0">Amount</p>
    </div>
    <div
      v-for="(item, key, index) in chapter7.deduction_description"
      :key="key"
      :class="`w-100 table-body ${
        !index || !(index % 2) ? 'white' : 'gray'
      } d-flex align-items-center justify-content-between`"
    >
      <p
        :class="`mb-0 body-title ${
          chapter7.deduction_item_status[key] ? '' : 'disabled'
        }`"
      >
        {{ item }}
      </p>
      <div v-if="key !== 'any_other'" class="d-flex align-items-center">
        <p
          v-if="chapter7.deduction_item_status[key]"
          :class="`mb-0 body-title mr-3 ${
            chapter7.deduction_item_status[key] ? 'cursor-pointer ' : 'disabled'
          }`"
          :style="{
            color: chapter7.deduction_item_color[key] || '',
          }"
          @click="setModal(true, key)"
        >
          {{ NumberFormatter(getValue(key)) }}
        </p>
        <v-tooltip
          v-if="
            chapter7.deduction_item_color[key] === '#e60000' &&
            chapter7.deduction_item_status[key]
          "
          :text="`Computed value ${NumberFormatter(
            chapter7.deduction_item_computed[key] || 0
          )}`"
          location="bottom"
        >
          <template #activator="{ props }">
            <p class="bold mb-0" v-bind="props">
              <b> ?</b>
            </p>
          </template>
        </v-tooltip>
      </div>
    </div>
    <EditableSheet
      v-if="$route.query.current_business !== 'master'"
      v-model="chapter7.additional_data.deduction.data"
      v-model:total="chapter7.additional_data.deduction.total"
    />
    <div
      v-if="$route.query.current_business === 'master'"
      class="w-100 table-body white d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 body-title">Additional</p>
      <div class="d-flex align-items-center">
        <p class="mb-0 body-title mr-3 cursor-pointer">
          {{ NumberFormatter(chapter7.master_data.additional_data.deduction) }}
        </p>
      </div>
    </div>
    <div
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 total-amount">Total deduction</p>
      <div class="d-flex align-items-center">
        <p
          class="mb-0 total-amount mr-3"
          :style="{ color: chapter7Color.total_diduction }"
        >
          {{ NumberFormatter(chapter7TotalDeduction($route) || 0) }}
        </p>
        <v-tooltip
          v-if="chapter7Color.total_diduction === '#e60000'"
          :text="`Computed value ${NumberFormatter(
            chapter7.total_diduction_computed || 0
          )}`"
          location="bottom"
        >
          <template #activator="{ props }">
            <p class="bold mb-0" v-bind="props">
              <b> ?</b>
            </p>
          </template>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import Chapter7Popup from "./Chapter7Popup.vue";
import { useRoute } from "vue-router";
import { ref } from "vue";
import { useStatusStore } from "../../../../store/statusStore";
import { EditableSheet } from "../../../common";
import { useComputeIncomeTaxStore } from "../../../../store/incometax.store";
import { NumberFormatter } from "../../../../helpers/numberFormatter";

const route = useRoute();
const computeIncomeTaxStore = useComputeIncomeTaxStore();
const statusStore = useStatusStore();
const { status } = storeToRefs(statusStore);
const { chapter7, chapter7TotalDeduction, chapter7Color } = storeToRefs(
  computeIncomeTaxStore
);

const open = ref(false);
const model = ref("");

const setModal = (value, key) => {
  if (route.query.current_business === "master" || status.value) {
    return;
  }
  if (!key) {
    open.value = value;
  } else if (chapter7.value.deduction_item_status[key]) {
    if (key) {
      model.value = key;
    }
    open.value = value;
  }
};
const getValue = (key) => {
  if (route.query.current_business === "master") {
    return Number(chapter7.value.deduction_item[key] || "");
  }
  return Number(chapter7.value.deduction_item[key].total || "");
};
</script>

<style lang="scss" scoped>
.header {
  height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: white;
}
.table-body {
  min-height: 80px;
  padding-right: 88px;
  padding-left: 26px;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 30px;
}
.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}
.total-container {
  height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}
.total-desc {
  background: #deeded;
}
.reference {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.article-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px; /*
identical to box height, or 185% */
  letter-spacing: 0.055em;
  color: #000000;
}

.white {
  background: #fafbff;
}
.gray {
  background: #deeded;
}
.btn-submit {
  width: 82px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
.body-title.disabled {
  color: #747171 !important;
}
.total-amount.disabled {
  color: #747171 !important;
}
</style>

<template>
  <a
    href="https://snaptax.me/law"
    class="wrapper w-100 mt-3 px-3 d-flex align-items-center justify-content-between"
    target="_blank"
  >
    <h3 class="desc">Ready Reckoner for Federal Tax Law (Free Benefit)</h3>
    <img src="@/assets/svg/Arrow-right.svg" alt="" />
  </a>
</template>

<script>
export default {
  name: "FederalTax",
};
</script>

<style scoped>
.wrapper {
  height: 151px;
  background: #deeded;
  border: 1px solid rgba(116, 113, 113, 0.2);
  box-shadow: 4px 4px 10px 2px rgba(187, 187, 187, 0.25);
  border-radius: 5px;
}
.desc {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #382628;
  max-width: 285px;
}
</style>

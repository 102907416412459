<template>
  <SideNavContainer title="Freezone Person Calculator">
    <LoaderContainer :loading="loading" :error="error">
      <!-- <CustomModalContainer :open="open.value" :set-open="setOpen">
        <div class="w-100 disclaimer-container p-3">
          <h4 class="note-text mb-0">
            <b class="text-warning warning-icon mr-3 mb-0">&#x26A0;</b>
            <span class="mb-0">Note</span>
          </h4>
          <p class="mb-0 body-title">
            {{ open.message }}
          </p>
        </div>
      </CustomModalContainer> -->
      <div class="wrapper pt-4">
        <StepCalculator />
        <!-- <div class="row">
          <div class="col-md-4">
            <Button />
          </div>
          <div class="col-md-8">
            <Revenue :set-alert="setOpen" />
            <Expense :set-alert="setOpen" />
            <Total :set-alert="setOpen" />
            <RevenueQFZP :set-alert="setOpen" />
            <AllTotal :set-alert="setOpen" />
          </div>
        </div> -->
        <div class="w-100 disclaimer-container p-3">
          <h4 class="total-amount">
            <b class="text-warning">&#x26A0;</b>
            Disclaimer
          </h4>
          <p class="mb-0 body-title">
            Our corporate tax calculator provides an estimate of tax liability
            based on our interpretation. It is not professional tax advice,
            subject to change, and may not be accurate or applicable. Consult a
            tax professional for personalized guidance and do not rely solely on
            the calculator's results.
          </p>
        </div>
        <Marquee :texts="data.marquee_text" />
      </div>
    </LoaderContainer>
  </SideNavContainer>
</template>

<script setup>
import { onMounted, ref } from "vue";
import Marquee from "../common/Marquee.vue";
import SideNavContainer from "../layout/SideNavContainer.vue";
import AllTotal from "./QFZCalculator/AllTotal.vue";
import Expense from "./QFZCalculator/Expense.vue";
import Revenue from "./QFZCalculator/Revenue.vue";
import RevenueQFZP from "./QFZCalculator/RevenueQFZP.vue";
import Total from "./QFZCalculator/Total.vue";
import Button from "./Button.vue";
import StepCalculator from "./StepCalculator.vue";
import { useDahboardStore } from "../../store/dashboard";
//import LoaderContainer from "../components/common/LoaderContainer.vue";
import { storeToRefs } from "pinia";
import { CustomModalContainer, LoaderContainer } from "../common";
const dashboardStore = useDahboardStore();
const { data, loading, error } = storeToRefs(dashboardStore);
const open = ref({
  value: false,
  message: "",
});
const setOpen = (value, message = "") => {
  if (message) {
    open.value.message = message;
  }
  open.value.value = value;
};
onMounted(async () => {
  await dashboardStore.getDasboardItems();
});
</script>

<style scoped>
.disclaimer-container {
  background: #deeded;
}
.note-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  text-transform: uppercase;
  color: #000000;
}
.warning-icon {
  font-size: 20px;
}
.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}
</style>

<template>
  <div class="w-100">
    <div
      class="w-100 table-body gray d-flex align-items-center justify-content-between"
    >
      <h5 class="mb-0 body-title bold">
        <b class="bold"> Direct Expenses </b>
      </h5>
      <h5 class="body-title mb-0 bold">
        <b>{{ NumberFormatter(directExpenses) }}</b>
      </h5>
    </div>
    <ClickableInputOrPopUp
      v-for="(item, index) in modelData"
      :key="item.model"
      :model-name="item.model"
      :name="item.name"
      :sub="item.sub"
      :show-input="item.showInput"
      :show-popup="item.showPopup"
      :on-tab-and-enter="handleTabEnter"
      :bg-color="!index || !(index % 2) ? 'white' : 'gray'"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { nonIFRSExpensesData } from "./data";
import { storeToRefs } from "pinia";
import { useNoneIFRSProfitLoss } from "../../../../../store/NonIFRS/profitLoss.store";
import ClickableInputOrPopUp from "./InputWithPop.vue";
import { HandleTabAndEnter } from "./helper";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";

const modelData = ref(nonIFRSExpensesData);
const nonIfrsPlStore = useNoneIFRSProfitLoss();
const { data, directExpenses } = storeToRefs(nonIfrsPlStore);
const handleTabEnter = async (model) => {
  HandleTabAndEnter(data, modelData, model);
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}
.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}
.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}
</style>

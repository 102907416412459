<!-- eslint-disable camelcase -->
<template>
  <SideNavContainer title="Add Entity">
    <EntityChecker>
      <AddEntityTab active="entityDetails" />
      <Progress :active="4" />
      <StatusChecker>
        <div class="row wrapper px-2 mt-10">
          <div class="col-lg-4 col-md-6">
            <h4 class="label">Industry</h4>
            <v-autocomplete
              v-model="industry"
              label="Industry"
              :items="businessActivity.data"
              item-title="name"
              item-value="id"
              bg-color="#DEEDED"
            >
            </v-autocomplete>
          </div>
          <div class="col-lg-4 col-md-6">
            <h4 class="label">Main</h4>
            <v-autocomplete
              v-model="main_group"
              label="Main Group"
              :items="businessActivity.data"
              item-title="name"
              item-value="id"
              bg-color="#DEEDED"
            >
            </v-autocomplete>
          </div>
          <div class="col-lg-4 col-md-6">
            <CustomTextInput
              v-model="sub_group"
              placeholder="Sub group"
              label="Sub group"
            />
          </div>
          <div class="col-lg-4 col-md-6">
            <h4 class="label">Activity</h4>
            <v-autocomplete
              v-model="activity"
              label="Activity"
              :items="businessActivity.data"
              item-title="name"
              item-value="id"
              bg-color="#DEEDED"
            >
            </v-autocomplete>
          </div>
          <div class="col-lg-4 col-md-6">
            <CustomTextInput
              v-model="activity_code"
              placeholder="Activity Code"
              label="Activity Code"
            />
          </div>

          <div class="col-lg-4 col-md-6">
            <h4 class="label">Whether entity has branch</h4>
            <div class="radio-container w-100 d-flex align-items-center pl-1">
              <input v-model="has_branch" type="radio" value="Y" />
              <p class="mb-0 yes">Yes</p>
              <input v-model="has_branch" type="radio" value="N" />
              <p class="mb-0 yes">No</p>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-10">
          <button class="save-next mr-3" @click="saveBranch">
            Save & Next
          </button>
          <button class="cancel" @click="$router.push(`/entity`)">
            Cancel
          </button>
        </div>
      </StatusChecker>
    </EntityChecker>
  </SideNavContainer>
</template>

<script>
import { SideNavContainer } from "../../../../layout";
import { AddEntityTab } from "../../../../common";
import Progress from "./Progress.vue";
import { addEntity } from "@/store/entityActions/post";
import EntityChecker from "../../EntityChecker.vue";
import { getEntityItem } from "@/store/entityActions/get";
import { useEntityStore } from "../../../../../store/entity";
import { storeToRefs } from "pinia";
import { updateEntity } from "../../../../../store/entityActions/put";
import StatusChecker from "../../StatusChecker.vue";
import CustomTextInput from "../../../../common/CustomTextInput.vue";

export default {
  name: "CheckBranches",
  components: {
    SideNavContainer,
    AddEntityTab,
    Progress,
    EntityChecker,
    StatusChecker,
    CustomTextInput,
  },
  setup() {
    const entityStore = useEntityStore();
    const { entity, businessActivity } = storeToRefs(entityStore);
    return { entity, businessActivity };
  },
  data() {
    return {
      industry: "",
      main_group: "",
      activity: "",
      activity_code: "",
      has_branch: "",
      message: "",
      edit: false,
    };
  },
  created() {
    const id = this.$route.params.sub_id;
    if (id) {
      this.getSubId();
    }
  },
  methods: {
    async saveBranch() {
      if (this.edit) {
        const id = Number(this.$route.params.sub_id);
        await updateEntity(
          "/entityBranch",
          { branch_id: id, has_branch: this.has_branch },
          this.setMessage,
          async ({ branch_id: branchId }) => {
            await getEntityItem("entity", "/entity", this.$route.params.id);
            setTimeout(() => {
              if (this.has_branch === "Y") {
                this.$router.push(
                  `/entity/add-entity/branch-details/${this.$route.params.id}/${id}`
                );
              } else {
                this.$router.push(
                  "/entity/entity-details/" + this.$route.params.id
                );
              }
            }, 2000);
          }
        );
        return;
      }
      await addEntity(
        "/entityBranch",
        {
          entity_id: this.$route.params.id,
          has_branch: "Y",
        },
        this.setMessage,
        async ({ entity_id: entityId, branch_id: branchId }) => {
          await getEntityItem("entity", "/entity", entityId);
          if (this.has_branch === "Y") {
            this.$router.push(
              `/entity/add-entity/branch-details/${entityId}/${branchId}`
            );
          } else {
            this.$router.push(
              "/entity/entity-details/" + this.$route.params.id
            );
          }
        }
      );
    },
    async getSubId() {
      const id = this.$route.params.id;
      await getEntityItem("entity", "/entity", id);
      const Entity = this.entity?.data?.entity?.find(
        (e) => Number(e.id) === Number(id)
      );
      if (Entity) {
        this.edit = true;
        this.has_branch = Entity?.has_branch;
      }
    },
    setMessage(message) {
      this.message = message;
    },
  },
};
</script>

<style scoped>
.save-next {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #fafbff;
  width: 196px;
  background: #0f75bc;
  height: 55px;
}
.cancel {
  background: #ffffff;
  border: 1px solid #747171;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #747171;
  height: 55px;
  width: 196px;
}
.radio-container {
  height: 56px;
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  gap: 11px;
}
.yes {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

import {
  AddEntityCheckout,
  CheckOutMakePayment,
  ChooseSubscriptionType,
  AddEntityForSubscription,
} from "../components/subscription";
import { Layout, SubscriptionView } from "../views/subscription";

export default {
  path: "/subscription",
  component: Layout,
  children: [
    { path: "", component: SubscriptionView },
    { path: "choose-subscription-type", component: ChooseSubscriptionType },
    { path: "checkout/:plan_type", component: AddEntityCheckout },
    { path: "make-payment/:plan_type", component: CheckOutMakePayment },
    { path: "add-entity", component: AddEntityForSubscription },
  ],
};

<template>
  <form @submit="handleSubmit">
    <div class="row w-100">
      <div v-for="(item, key) in data" :key="key" class="col-lg-4 mt-4">
        <CustomTextInput
          v-if="
            key !== 'mobile_no'
              ? key === 'password' || key === 'confirm_password'
                ? $route.params.id
                  ? false
                  : true
                : true
              : false
          "
          v-model="data[key]"
          v-bind="inputProps[key]"
          :error-message="error[key]"
          :disabled="
            key === 'confirm_password' ? !checkPasswordValidity : false
          "
          :add-suggestion="key === 'password'"
          :check-validity="key === 'password' ? changeValidity : () => {}"
          @input="setEmptyError(key)"
        />
        <div v-if="key === 'mobile_no'" class="w-100">
          <h4 class="label">Mobile Number</h4>
          <div class="d-flex mt-4 align-items-center">
            <country-flag
              v-if="mobile.country_code"
              :country="mobile.country_code"
              class="mr-1"
              size="big"
            />
            <v-autocomplete
              v-model="mobile.mobile_code"
              :items="countries.data"
              item-title="calling_code"
              item-value="calling_code"
              bg-color="#DEEDED"
            >
            </v-autocomplete>
            <input
              v-model="data.mobile_no"
              type="number"
              class="custom-input mt-31 w-60"
              autocomplete="off"
              @keypress="onlyAcceptDigits"
            />
          </div>
          <p v-if="error.mobile_no" class="text-danger">
            {{ error.mobile_no }}
          </p>
        </div>
      </div>

      <div class="col-lg-12">
        <button
          :class="`btn-log-in mt-2 ${
            $route.params.id
              ? ''
              : data.password != data.confirm_password
              ? 'disabled'
              : !data.password || !data.confirm_password
              ? 'disabled'
              : ''
          }`"
          type="submit"
          :disabled="
            $route.params.id
              ? false
              : data.password != data.confirm_password ||
                !data.password ||
                !data.confirm_password
          "
        >
          {{ !!$route.params.id ? "Update" : "Add" }}
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useCountryStore } from "../../store/country";
import { storeToRefs } from "pinia";
import { checkRequired } from "../../helpers/formErrorCheckers";
import { onlyAcceptDigits } from "../../helpers/validation";
import { useUsersStore } from "../../store/users.store";
import { useRouter, useRoute } from "vue-router";
import { addNewUserInputProps } from "./data";
import CustomTextInput from "../common/CustomTextInput.vue";
import { useProfileStore } from "../../store/profile";
import { emailRegex } from "../../constants/regex-format";

const countryStore = useCountryStore();
const { countries } = storeToRefs(countryStore);
const usersStore = useUsersStore();
const { users } = storeToRefs(usersStore);
const isEdit = ref(false);
const profileStore = useProfileStore();
const route = useRoute();
const router = useRouter();
const data = ref({
  first_name: "",
  last_name: "",
  email: "",
  mobile_no: "",
  password: "",
  confirm_password: "",
});
const inputProps = ref(addNewUserInputProps);
const mobile = ref({
  mobile_code: "+971",
  country_code: "are",
});
const error = ref({
  first_name: "",
  last_name: "",
  email: "",
  mobile_no: "",
  mobile_code: "",
});
const checkPasswordValidity = ref(false);
const changeValidity = (value) => {
  checkPasswordValidity.value = value;
};
const setEmptyError = (type) => {
  if (type === "confirm_password" && data.value[type]) {
    if (data.value.password !== data.value.confirm_password) {
      error.value[type] = "Password not matched";
      return;
    } else if (data.value.password === data.value.confirm_password) {
      error.value[type] = "";
      return;
    }
  }
  error.value[type] = "";
};
watch(
  () => data.value.mobile_no,
  (newValue) => {
    if (newValue) {
      if (String(newValue).length <= 13) {
        error.value.mobile_no = "";
      } else {
        error.value.mobile_no = "Please give maximum 13 digits";
      }
    }
  }
);

watch(
  () => mobile.value.mobile_code,
  (newValue) => {
    if (!newValue) {
      return;
    }
    const findCountry = countries.value.data.find(
      (country) => country.calling_code === newValue
    );
    const code = findCountry ? findCountry?.code?.toLowerCase() : "";
    mobile.value.country_code = code === "uae" ? "are" : code;
    error.value.mobile_code = "";
  }
);
const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const check = checkRequired({
      first_name: data.value.first_name,
      last_name: data.value.last_name,
      email: data.value.email,
      mobile_code: mobile.value.mobile_code,
      mobile_no: data.value.mobile_no,
      password: isEdit.value ? "dsg" : data.value.password,
      confirm_password: isEdit.value ? "dsg" : data.value.confirm_password,
    });
    if (check.length) {
      check.forEach((field) => {
        error.value[field] = "Please enter this field";
      });
      return;
    } else if (data.value.password !== data.value.confirm_password) {
      error.value.confirm_password = "Password confirmation error";
      return;
    } else if (!emailRegex.test(data.value.email)) {
      error.value.email = "Invalid Email";
      return;
    } else if (error.value.mobile_no) {
      return;
    }
    if (isEdit.value) {
      await usersStore.editSubUSer(
        {
          id: route.params.id,
          first_name: data.value.first_name,
          last_name: data.value.last_name,
          email: data.value.email,
          mobile_code: mobile.value.mobile_code,
          mobile_no: data.value.mobile_no,
        },
        async () => {
          setTimeout(async () => {
            await usersStore.getUsersList();
            router.push(`/users`);
          }, 2000);
        }
      );
      return;
    }
    await usersStore.addSubUSer(
      { ...data.value, mobile_code: mobile.value.mobile_code },
      async () => {
        setTimeout(async () => {
          await usersStore.getUsersList();
          await profileStore.getUserProfile();
          router.push(`/users`);
        }, 2000);
      }
    );
  } catch (err) {
    console.log(err);
  }
};
onMounted(async () => {
  if (!countries.value.data.length) {
    await countryStore.getCountryList();
  }
  isEdit.value = !!route.params.id;
  if (isEdit.value) {
    if (!users.value.data.length) {
      await usersStore.getUsersList();
    }
    const User = users.value.data.find((user) => user?.id == route.params.id);
    if (User) {
      data.value = {
        first_name: User.first_name,
        last_name: User.last_name,
        email: User.email,
        mobile_no: User.mobile_no,
      };
      mobile.value = {
        mobile_code: User.mobile_code,
        country_code:
          User.country_code?.toLowerCase() === "uae"
            ? "are"
            : User.country_code?.toLowerCase(),
      };
    }
  }
});
</script>

<style scoped>
.btn-log-in {
  height: 55px;
  background: #0f75bc;
  border-radius: 4px;
  padding: 15px 75px 16px;
  color: #fafbff;
}
.btn-log-in.disabled {
  opacity: 0.5;
}
.mobile-number {
  border: none;
  padding-bottom: 6px;
  border-bottom: 1px solid #747171;
  padding-right: 81px;
  margin-top: -12px;
  background: #fafbff;
}

.mobile-number:focus {
  outline: 0;
  border-bottom: 1px solid #000;
}
.mt-10px {
  margin-top: 10px;
}
.custom-input {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  height: 56px;
  padding: 13px 10px;
  margin-top: 8px;
}
.mt-31 {
  margin-top: -22px !important;
}
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
  margin-bottom: -9px;
}
.w-60 {
  width: 60%;
}
</style>

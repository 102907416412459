<template>
  <SideNavContainer title="Individual corporate tax calculator">
  <LoaderContainer :loading="loading" :error="error">
    <CustomModalContainer :open="open.value" :set-open="setOpen">
      <div class="w-100 disclaimer-container p-3">
        <h4 class="note-text mb-0">
          <b class="text-warning warning-icon mr-3 mb-0">&#x26A0;</b>
          <span class="mb-0">Note</span>
        </h4>
        <p class="mb-0 body-title">
          {{ open.message }}
        </p>
      </div>
    </CustomModalContainer>
    <div class="container-fluid wrapper">
      <div class="row align-items-center">
        <div class="col-md-3">
          <Button />
        </div>
        <div class="col-md-9">
          <div
            class="card card border border-white shadow bg-body rounded-4 my-4"
          >
            <div class="card-body">
              <div class="w-100 table-responsive mt-4 mb-4">
                <table class="table custom-table">
                  <thead>
                    <tr>
                      <th class="table-header w-50">Particulars</th>
                      <div class="head">
                        <th class="table-header w-25">Amount in AED</th>
                      </div>
                      <th class="table-header w-25">Calculated Fields</th>
                    </tr>
                  </thead>
                  <tbody>
                    <Reciepts />
                    <Expense :set-alert="setOpen" />
                    <Total />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 disclaimer-container p-3">
      <h4 class="total-amount">
        <b class="text-warning">&#x26A0;</b>
        Disclaimer
      </h4>
      <p class="mb-0 body-title">
        Our corporate tax calculator provides an estimate of tax liability based
        on our interpretation of current law in force. It is not professional
        tax advice, subject to change, and may not be accurate or applicable.
        Consult a tax professional for personalized guidance and do not rely
        solely on the calculator's results.
      </p>
    </div>
    <Marquee :texts="data.marquee_text" />
  </LoaderContainer>
   </SideNavContainer>
</template>
33
<script setup>
import { storeToRefs } from "pinia";
import { useDahboardStore } from "../../store/dashboard";
import Marquee from "../common/Marquee.vue";
import SideNavContainer from "../layout/SideNavContainer.vue";
import Expense from "./CTaxCalculatorIndividual/Expense.vue";
import Reciepts from "./CTaxCalculatorIndividual/Reciepts.vue";
import Total from "./CTaxCalculatorIndividual/Total.vue";
import { onMounted, ref } from "vue";
import { CustomModalContainer, LoaderContainer } from "../common";
import Button from "./Button.vue";
const dashboardStore = useDahboardStore();
const { data, loading, error } = storeToRefs(dashboardStore);
const open = ref({
  value: false,
  message: ""
});
const setOpen = (value, message = "") => {
  if (message) {
    open.value.message = message;
  }
  open.value.value = value;
};
onMounted(async () => {
  await dashboardStore.getDasboardItems();
});
</script>
<style scoped>
.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border-bottom-width: unset !important;
}
.table-header {
  min-height: 39px;
  /* background: #0f75bc; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.055em;
  /* color: #ffffff; */
  padding-top: 10px;
  padding-bottom: 10px;
  color: #2a9ed8;
}
.table-rows {
  background: #ffffff;
  padding-top: 14px;
  padding-bottom: 14px;
}
.table-rows.border {
  border-bottom: 1px solid #747171 !important;
  border-top: 1px solid #747171 !important;
}
.yes-text {
  font-style: normal;
  font-weight: 400;
  font-size: 11.5235px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: #000000;
}
.empty {
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.5px solid rgba(149, 146, 146, 0.3);
}
.cursor-pointer {
  cursor: pointer;
}
.data-img {
  width: 110px;
  height: 110px;
  object-fit: cover;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
tbody:last-child {
  border: 2.5px solid #fff;
  border-bottom: 5px solid rgb(0, 0, 0);
}
.custom-input {
  text-align: left !important;
  border: none;
  border-bottom: 1px solid#000000;
  padding-bottom: 1px;
  padding-left: 4px;
  height: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input:focus {
  outline: 0;
  border-bottom: 1px solid#0f75bc;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  margin-left: 35px;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
/* .table-total {
  background: #0f75bc;
} */
.total-text {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #0f75bc;
}
.disclaimer-container {
  background: #deeded;
}
.head {
  text-align: center;
  margin-left: -205px;
  margin-bottom: -10px;
}
.note-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.warning-icon {
  font-size: 20px;
}
.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}
.wrapper {
  min-height: 106vh !important;
}
</style>

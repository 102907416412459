<template>
  <LoaderContainer :loading="profile.loading" :error="profile.error">
    <div class="w-100">
      <ChoosePlan :open="open" :close-modal="closeModal" />
      <slot v-if="profile.data?.has_subscription === 'Y'" />
      <div
        v-else
        class="w-100 h d-flex align-items-center justify-content-center"
      >
        <p>
          You need to have subscription to continue.
          <router-link
            v-if="!Number(profile.data?.parent_id)"
            to="/subscription/choose-subscription-type"
          >
            <b>Buy subscription now</b>
          </router-link>
        </p>
      </div>
    </div>
  </LoaderContainer>
</template>

<script setup>
import ChoosePlan from "../entity/AddEntity/ChoosePlan.vue";
import { LoaderContainer } from "../common";
import { useProfileStore } from "../../store/profile";
import { storeToRefs } from "pinia";
import { onMounted, ref, watch } from "vue";

const profileStore = useProfileStore();
const { profile } = storeToRefs(profileStore);

const showEntity = ref(false);
const open = ref(false);
const closeModal = () => {
  open.value = false;
};

onMounted(() => {
  if (profile.value.data?.has_subscription !== "Y") {
    open.value = true;
    showEntity.value = false;
  } else {
    showEntity.value = true;
    open.value = false;
  }
});
watch(
  () => profile.value.data,
  (newVal) => {
    if (newVal?.has_subscription !== "Y") {
      open.value = true;
      showEntity.value = false;
    } else {
      showEntity.value = true;
      open.value = false;
    }
  },
  { deep: true }
);
</script>

<style scoped>
.h {
  min-height: 40vh;
}
</style>

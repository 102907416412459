<template>
  <v-dialog
    transition="dialog-top-transition"
    width="auto"
    v-model="openMessage"
    content-class="my-custom-dialog"
  >
    <div
      class="card-container d-flex align-items-center justify-content-evenly"
    >
      <img src="@/assets/svg/check-square.svg" class="mr-3 mt-2" alt="" />
      <div class="">
        <p class="message-title mb-0">{{ title }}</p>
        <p class="message">{{ message }}</p>
      </div>
      <img
        src="@/assets/svg/close-circle.svg"
        class="close-img"
        alt=""
        @click="closeModal()"
      />
    </div>
  </v-dialog>
</template>

<script>
import { ref } from "vue";
export default {
  name: "ModalSuccessMessage",
  props: {
    open: Boolean,
    message: String,
    title: String,
    closeModal: Function
  },
  setup(props) {
    const openMessage = ref(!!props.open);
    return { openMessage };
  },
  watch: {
    open() {
      this.openMessage = this.open;
    }
  }
};
</script>

<style scoped>
.card-container {
  max-width: 596px;
  min-height: 119px;
  background: #fafbff;
  border-radius: 5px;
  padding-left: 25px;
  padding-right: 25px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.card-container::-webkit-scrollbar {
  display: none;
}
.message-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.055em;
  color: #000000;
}
.message {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #747171;
}
.close-img {
  cursor: pointer;
}
</style>

<template>
  <div class="w-100">
    <div class="header d-flex align-items-center w-100 justify-content-between">
      <p class="header-title mb-0">Administration Expenses</p>
      <p class="header-title mb-0">
        {{ NumberFormatter(totalExpenditure.indrct_expns_admnstn_expns) }}
      </p>
    </div>
    <form
      v-for="(item, index) in data"
      :id="item.model"
      :key="item.id"
      :class="`w-100 table-body ${!index || !(index % 2) ? 'white' : 'gray'} ${
        item.sub ? 'sub' : ''
      } d-flex align-items-center justify-content-between`"
    >
      <p class="mb-0 body-title">{{ item.name }}</p>
      <p
        v-if="
          !item.showInput &&
          item.model !== 'indrct_expns_admnstn_expns_insrnce_expns'
        "
        class="body-title mb-0"
        @click="openCloseInput(item.model, true)"
      >
        {{ NumberFormatter(indirect_expenses[item.model]) }}
      </p>
      <p
        v-if="
          !item.showInput &&
          item.model === 'indrct_expns_admnstn_expns_insrnce_expns'
        "
        class="total-amount mb-0"
      >
        {{ NumberFormatter(totalExpenditure[item.model]) }}
      </p>
      <input
        :id="'input-' + item.model"
        v-model="indirect_expenses[item.model]"
        :hidden="!item.showInput"
        type="number"
        class="clickable-text"
        @blur="openCloseInput(item.model, false)"
        @keydown.tab.enter="(e) => handleTabEnter(e, item.model)"
      />
    </form>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useExpenditureStore } from "../../../../../store/expenditure";
import { storeToRefs } from "pinia";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";
import { useRoute } from "vue-router";
const props = defineProps({
  view: {
    type: Boolean,
    default: false,
  },
});
const route = useRoute();
const data = ref([
  {
    model: "indrct_expns_admnstn_expns_salary_expenses",
    name: "1. Salary Expenses",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "indrct_expns_admnstn_expns_lgl_and_prfsnl_charge",
    name: "2. Legal and Professional Charges",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "indrct_expns_admnstn_expns_utilities",
    name: "3. Utilities",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "indrct_expns_admnstn_expns_bonus",
    name: "4. Bonus",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "indrct_expns_admnstn_expns_insrnce_expns",
    name: "5. Insurance Expenses",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "indrct_expns_admnstn_expns_insrnce_expns_mdcl_insrnc",
    name: "5.1 Medical Insurance",
    sub: true,
    amount: "0000.00",
  },
  {
    model: "indrct_expns_admnstn_expns_insrnce_expns_life_insrnc",
    name: "5.2 Life Insurance",
    sub: true,
    amount: "0000.00",
  },
  {
    model: "indrct_expns_admnstn_expns_insrnce_expns_keyman_insrnc",
    name: "5.3 Keyman's Insurance",
    sub: true,
    amount: "0000.00",
  },
  {
    model: "indrct_expns_admnstn_expns_insrnce_expns_other_insrnc",
    name: "5.4 Other Insurance",
    sub: true,
    amount: "0000.00",
  },
  {
    model: "indrct_expns_admnstn_expns_commission_expenses",
    name: "6. Commission Expenses",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "indrct_expns_admnstn_expns_audit_fees",
    name: "7. Audit Fees",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "indrct_expns_admnstn_expns_accounting_charge",
    name: "8.Accounting Charges",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "indrct_expns_admnstn_expns_staff_walefare_expenses",
    name: "9. Staff Welfare Expenses",
    sub: false,
    amount: "0000.00",
  },
]);
const openCloseInput = async (model, value) => {
  if (props.view || route.query.business === "master") {
    return;
  }
  const newValues = data.value.map((item) => {
    if (item.model === model) {
      return { ...item, showInput: value };
    }
    return item;
  });
  data.value = newValues;
  if (value) {
    const input = await document.getElementById(`input-${model}`);
    input.focus();
  }
};
const expenditureStore = useExpenditureStore();
const { indirect_expenses, totalExpenditure } = storeToRefs(expenditureStore);
totalExpenditure.value.indrct_expns_admnstn_expns = data.value.reduce(
  (a, b) => {
    if (b.model === "indrct_expns_admnstn_expns_insrnce_expns") {
      //5
      return a;
    }
    return a + Number(indirect_expenses.value[b.model]);
  },
  0
);
totalExpenditure.value.indrct_expns_admnstn_expns_insrnce_expns =
  data.value.reduce((a, b) => {
    if (
      b.model === "indrct_expns_admnstn_expns_insrnce_expns_mdcl_insrnc" || //5.1
      b.model === "indrct_expns_admnstn_expns_insrnce_expns_life_insrnc" || // 5.2
      b.model === "indrct_expns_admnstn_expns_insrnce_expns_keyman_insrnc" || //5.3
      b.model === "indrct_expns_admnstn_expns_insrnce_expns_other_insrnc" // 5.4
    ) {
      return a + Number(indirect_expenses.value[b.model]);
    } else return a;
  }, 0);
watch(
  indirect_expenses,
  (newValue) => {
    totalExpenditure.value.indrct_expns_admnstn_expns = data.value.reduce(
      (a, b) => {
        if (b.model === "indrct_expns_admnstn_expns_insrnce_expns") {
          return a;
        } else {
          return a + Number(newValue[b.model]);
        }
      },
      0
    );
    totalExpenditure.value.indrct_expns_admnstn_expns_insrnce_expns =
      data.value.reduce((a, b) => {
        if (
          b.model === "indrct_expns_admnstn_expns_insrnce_expns_mdcl_insrnc" || //5.1
          b.model === "indrct_expns_admnstn_expns_insrnce_expns_life_insrnc" || // 5.2
          b.model ===
            "indrct_expns_admnstn_expns_insrnce_expns_keyman_insrnc" || //5.3
          b.model === "indrct_expns_admnstn_expns_insrnce_expns_other_insrnc" // 5.4
        ) {
          return a + Number(newValue[b.model]);
        } else return a;
      }, 0);
  },
  { deep: true }
);
const handleTabEnter = async (e, current_model) => {
  e.preventDefault();
  let next_model = await data.value[
    data.value.map((e) => e.model).indexOf(current_model) + 1
  ];
  if (current_model == "indrct_expns_admnstn_expns_bonus") {
    next_model = data.value.find(
      (item) =>
        item.model === "indrct_expns_admnstn_expns_insrnce_expns_mdcl_insrnc"
    );
  }
  await openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
    const nextInput = document.getElementById(`input-${next_model.model}`);
    nextInput.focus();
  }
};
</script>

<style scoped>
.header {
  height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}

.sub-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}

.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}

.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
</style>

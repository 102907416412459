<template>
  <SideNavContainer
    v-slot="{ setSavingStatus }"
    side-nav-type="financialData"
    :subtitle="name"
  >
    <EntityChecker :check-mandatory-fields="true">
      <IFRSCheckerContainer :have-loader="false" :check-status="true">
        <div
          class="title-container d-flex align-items-center justify-content-between"
        >
          <h3 class="title">
            Financial Data <br />
            <p class="subtitle mb-0">{{ name }}</p>
          </h3>
          <IncomeDateHandler v-if="tab === 'income'" />
          <ExpenditureDateHandler v-else-if="tab === 'expenditure'" />
          <ViewDateHandler v-else-if="tab === 'view'" />
        </div>
        <BalanceSheetProfitLossBreadCrumb
          :entity-name="name"
          type="profit-loss"
          :tab="tab"
        />
        <div class="row">
          <div class="col-lg-12">
            <ExpenditureBusinessHandler v-if="tab === 'expenditure'" />
            <IncomeBusinessHandler v-else-if="tab === 'income'" />
            <ViewBusinessHandler v-else-if="tab === 'view'" />
          </div>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center justify-content-between mt-3 mb-3"
        >
          <div class="d-flex align-items-start">
            <button
              :class="`tab-button mr-2 ${
                tab === 'income' ? 'active' : ''
              } my-2`"
              @click="changeTab('income')"
            >
              <img src="@/assets/svg/briefcase.svg" alt="" class="mr-1 mb-1" />
              Income
            </button>
            <button
              :class="`tab-button ${
                tab === 'expenditure' ? 'active' : ''
              } my-2`"
              @click="changeTab(`expenditure`)"
            >
              <img src="@/assets/svg/pie-chart.svg" alt="" class="mr-1 mb-1" />
              Expenditure
            </button>
          </div>
          <p :class="`text-${type}`">
            {{ message }}
          </p>
          <DeleteBusinessBtnContainer>
            <button class="save-button mr-3 my-2" @click="createBusiness">
              <img src="@/assets/svg/plus-square-light.svg" alt="" />
              Add New
            </button>
            <button
              v-if="$route.query.business !== 'master' && !status"
              class="save-button my-2"
              :disabled="
                data === 'IFRS'
                  ? tab === 'income'
                    ? incomeLoading
                    : tab === 'expenditure'
                    ? expenditureLoading
                    : tab === 'view'
                    ? incomeLoading || expenditureLoading
                    : true
                  : data === 'Non-IFRS'
                  ? nonIFRSLoading
                  : true
              "
              @click="saveData"
            >
              Save Data
            </button>
          </DeleteBusinessBtnContainer>
        </div>
        <slot :set-saving-status="setSavingStatus" />
      </IFRSCheckerContainer>
    </EntityChecker>
  </SideNavContainer>
</template>

<script setup>
import { SideNavContainer } from "@/components/layout";
import { useProfitAndLossStore } from "../../../../store/ProfitAdLoss";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { useExpenditureStore } from "../../../../store/expenditure";
import { getEntityItem } from "../../../../store/entityActions/get";
import { computed, onMounted, ref, watch } from "vue";
import { useEntityStore } from "../../../../store/entity";
import { useBusinessStore } from "../../../../store/buisness.store";
import EntityChecker from "../../AddEntity/EntityChecker.vue";
import ExpenditureBusinessHandler from "../BusinessHandler/ProfitAndLoss/ExpenditureBusinessHandler.vue";
import IncomeBusinessHandler from "../BusinessHandler/ProfitAndLoss/IncomeBusinessHandler.vue";
import ViewBusinessHandler from "../BusinessHandler/ProfitAndLoss/ViewBusinessHandler.vue";
import IncomeDateHandler from "../DateHandler/ProfitAndLoss/IncomeDateHandler.vue";
import ExpenditureDateHandler from "../DateHandler/ProfitAndLoss/ExpenditureDateHandler.vue";
import ViewDateHandler from "../DateHandler/ProfitAndLoss/ViewDatehandler.vue";
import { BalanceSheetProfitLossBreadCrumb } from "../../../BreadCrumbs";
import { useStatusStore } from "../../../../store/statusStore";
import DeleteBusinessBtnContainer from "../BusinessHandler/common/DeleteBusinessBtnContainer.vue";
import { useIFRSStore } from "../../../../store/NonIFRS/IFRS.store";
import { useNoneIFRSProfitLoss } from "../../../../store/NonIFRS/profitLoss.store";
import IFRSCheckerContainer from "../IFRSCheckerContainer.vue";

const props = defineProps({
  tab: {
    type: String,
    required: true,
  },
});
const router = useRouter();
const profitLossStore = useProfitAndLossStore();
const expenditureStore = useExpenditureStore();
const entityStore = useEntityStore();
const businesStore = useBusinessStore();

const { entity } = storeToRefs(entityStore);
const statusStore = useStatusStore();
const ifrStore = useIFRSStore();
const nonIfrsPlStore = useNoneIFRSProfitLoss();
const { data } = storeToRefs(ifrStore);
const { status } = storeToRefs(statusStore);

const message = ref("");
const type = ref("info");
const setMessage = (mess) => {
  message.value = mess;
};
const { loading: incomeLoading } = storeToRefs(profitLossStore);
const { loading: expenditureLoading } = storeToRefs(expenditureStore);
const { loading: nonIFRSLoading } = storeToRefs(nonIfrsPlStore);

const route = useRoute();
const name = computed(() => {
  if (!entity.value.data) return "";
  const Entity = entity.value.data?.entity[0];
  if (Entity?.entity_type == 2) {
    return `${Entity?.entity_first_name} ${Entity?.entity_last_name}`;
  }
  return Entity?.entity_name;
});
const saveData = async () => {
  if (data.value == "IFRS") {
    if (props?.tab === "expenditure") {
      await expenditureStore.saveExpenditure(route, (id) => {
        router.push(
          `/entity/financial-data/${route.params.id}?financial_period=${
            route.query.financial_period
          }&financial_year=${route.query.financial_year}${
            id
              ? "&business=" + id
              : route.query.business
              ? "&business=" + route.query.business
              : ""
          }`
        );
      });
      return;
    }

    await profitLossStore.saveProfitAndLoss(route, (id) => {
      router.push(
        `/entity/financial-data/profit-loss/expenditure/${
          route.params.id
        }?financial_period=${route.query.financial_period}&financial_year=${
          route.query.financial_year
        }${
          id
            ? "&business=" + id
            : route.query.business
            ? "&business=" + route.query.business
            : ""
        }`
      );
    });
  } else if (data.value === "Non-IFRS") {
    await nonIfrsPlStore.saveNonIFRSProfitLoss(
      route.params.id,
      route.query.financial_year,
      route.query.financial_period,
      route.query.business,
      ({ entity_business_id: id }) => {
        if (props.tab === "expenditure") {
          router.push(
            `/entity/financial-data/${route.params.id}?financial_period=${
              route.query.financial_period
            }&financial_year=${route.query.financial_year}${
              id
                ? "&business=" + id
                : route.query.business
                ? "&business=" + route.query.business
                : ""
            }`
          );
        } else if (props.tab === "income") {
          router.push(
            `/entity/financial-data/profit-loss/expenditure/${
              route.params.id
            }?financial_period=${route.query.financial_period}&financial_year=${
              route.query.financial_year
            }${
              id
                ? "&business=" + id
                : route.query.business
                ? "&business=" + route.query.business
                : ""
            }`
          );
        }
      }
    );
  }
};

const createBusiness = async () => {
  await businesStore.addBusiness(
    {
      entity_id: route.params.id,
      financial_period: route.query.financial_period,
      financial_year: route.query.financial_year,
    },
    setMessage,
    async ({ id }) => {
      await businesStore.getBusiness(
        route.params.id,
        route.query.financial_year,
        route.query.financial_period
      );
      const { pathname } = window.location;
      await router.push(
        `${pathname}?financial_year=${
          route.query.financial_year
        }&financial_period=${route.query.financial_period}${
          id ? "&business=" + id : ""
        }`
      );
    }
  );
};
const changeTab = (tab) => {
  router.push(
    `/entity/financial-data/profit-loss/${tab}/${
      route.params.id
    }?financial_period=${route.query.financial_period}&financial_year=${
      route.query.financial_year
    }${route.query.business ? "&business=" + route.query.business : ""}`
  );
};
</script>

<style scoped>
.tab-button {
  min-width: 155px;
  min-height: 44px;
  border-radius: 4px;
  background: #eff3f3;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}

.tab-button.active {
  background: linear-gradient(90deg, #101432 0%, #00a7a7 100%);
  color: #fafbff;
}

.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.save-button:disabled {
  opacity: 0.5;
}
.title-container {
  padding: 25px 10px 16px 10px;
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.055em;
  color: #000000;
}

.subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
  font-style: normal;
}
</style>

<template>
  <div class="d-flex flex-wrap justify-content-end align-items-center">
    <ImportBusiness
      :open="importBusiness.modal"
      :set-open="openBusinessModal"
      :import-business="ImportBusines"
      :on-close="onCancel"
    />
    <select
      v-model="financial_period"
      disabled
      class="date-select pl-2 mt-2 mr-3"
    >
      <option
        v-for="(year, index) in financialPeriod.data"
        :key="year.id"
        class="option"
        :value="year.id"
        :selected="!index"
      >
        {{ year.name }}
      </option>
    </select>
    <select class="date-select pl-2 mt-2 mr-3" @input="changeFinancialYear">
      <option
        v-for="(year, index) in financialYear.data"
        :key="year.id"
        class="option"
        :value="year.id"
        :selected="financial_year == year.id"
      >
        {{ year.name }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { onMounted, onUnmounted, ref, watch } from "vue";

import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import { useEntityStore } from "../../../../../store/entity";
import {
  getEntityItems,
  getEntityItem,
} from "../../../../../store/entityActions/get";
import { useBusinessStore } from "../../../../../store/buisness.store";
import ImportBusiness from "../../BusinessHandler/common/ImportBusiness.vue";
import { useIFRSStore } from "../../../../../store/NonIFRS/IFRS.store";
import IfrsCheckerPopUp from "../../IfrsCheckerPopUp.vue";
const ifrsCheckerModal = ref(false);
const ifrsStore = useIFRSStore();
const entityStore = useEntityStore();
const route = useRoute();
const router = useRouter();
const {
  financialYear: financialPeriod,
  financialPeriod: financialYear,
  entity,
} = storeToRefs(entityStore);
const businessStore = useBusinessStore();
const { business } = storeToRefs(businessStore);
const financial_year = ref(
  route.query.financial_year ? route.query.financial_year : ""
);
const financial_period = ref(
  route.query.financial_period ? route.query.financial_period : 1
);
const business_id = ref(route.query.business ? route.query.business : "");
watch(
  () => route.query,
  (newValue, oldValue) => {
    if (newValue.business != oldValue.business)
      business_id.value = newValue.business;
  },
  { deep: true }
);
const setIfrsCheckerModal = (value) => {
  ifrsCheckerModal.value = value;
};
onMounted(async () => {
  if (!financialPeriod.value.data.length) {
    await getEntityItems("financialYear", "/financialYear");
  }
  const Financial_period = entity.value.data?.entity[0]?.financial_period?.id;
  financial_period.value = Financial_period
    ? Financial_period
    : financial_period.value;
  await getEntityItem(
    "financialPeriod",
    "/financialYear",
    financial_period.value
  );
  const fy = await localStorage.getItem("financial_year");
  if (fy) {
    financial_year.value = fy;
  } else {
    financial_year.value = financial_year.value
      ? financial_year.value
      : financialYear.value.data[0]?.id;
    localStorage.setItem("financial_year", financial_year.value);
  }
  router.push(
    `/entity/financial-data/balance-sheet/liabilities/${
      route.params.id
    }?financial_period=${financial_period.value}&financial_year=${
      financial_year.value
    }${route.query.business ? "&business=" + route.query.business : ""}`
  );
});

const importBusiness = ref({
  modal: false,
  previousYear: "",
  currentYear: "",
});
const openBusinessModal = (value, currentYear, previousYear) => {
  importBusiness.value.modal = value;
  if (previousYear) {
    importBusiness.value.previousYear = previousYear;
  }
  if (currentYear) {
    importBusiness.value.currentYear = currentYear;
  }
};
const getIndexofFinancialYear = (id) => {
  return financialYear.value.data.findIndex((f) => f.id == id);
};
const getPreviousFinancialYear = (id) => {
  const index = getIndexofFinancialYear(id);
  if (index > 0) {
    return financialYear.value.data[index - 1].id;
  } else if (index === -1) {
    return "";
  }
  return financialYear.value.data[0].id;
};
const ImportBusines = async () => {
  await businessStore.importBusiness(
    {
      entity_id: route.params.id,
      financial_year: importBusiness.value.currentYear,
      financial_period: financial_period.value,
    },
    async () => {
      financial_year.value = importBusiness.value.currentYear;
      openBusinessModal(false);
    }
  );
};
const onCancel = async () => {
  financial_year.value = importBusiness.value.currentYear;
  importBusiness.value.modal = false;
};
const changeFinancialYear = async (e) => {
  try {
    let value = Number(e.target.value);
    let index = getIndexofFinancialYear(value);
    if (!index || index === -1) {
      financial_year.value = value;
      return;
    }
    let oldBusiness = await businessStore.getOldBusiness(
      route.params.id,
      getPreviousFinancialYear(value),
      financial_period.value
    );
    await businessStore.getBusiness(
      route.params.id,
      value,
      financial_period.value
    );
    if (!business.value?.data?.length && oldBusiness.length) {
      openBusinessModal(true, value, getPreviousFinancialYear(value));
      return;
    }

    financial_year.value = value;
  } catch (err) {
    console.log("Error...", err?.message);
  }
};
const unWatch = watch(financial_year, async (newValue) => {
  localStorage.setItem("financial_year", newValue);
  const { pathname } = window.location;
  const tab = pathname.split("/")[4];
  if (tab === "liabilities") {
    router.push(
      `/entity/financial-data/balance-sheet/liabilities/${
        route.params.id
      }?financial_period=${financial_period.value}&financial_year=${newValue}${
        route.query.business ? "&business=" + route.query.business : ""
      }`
    );
  }
});
onBeforeRouteLeave(async () => {
  await unWatch();
  return true;
});
onUnmounted(async () => {
  await unWatch();
});
</script>

<style scoped>
.date-select {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  width: 163px;
  height: 40px;
}
.date-select:focus {
  outline: none;
}
.option {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

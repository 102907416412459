export const renameIndexInRevenue = (model) => {
  switch (model) {
    case "total_revenue_of_QFZP":
      return "A.";
    case "revenue_attributable_for_domestic":
      return "i.";
    case "revenue_attributable_for_foreign":
      return "ii.";
    case "revenue_commercial_property_located":
      return "iii.";
    case "revenue_derived_transactions_with_person_related_fz_ncp":
      return "iv.";
    case "revenue_derived_transactions_with_person_related_ncp_fz":
      return "v.";
    case "revenue_derived_non_qualifying_intellectual_property":
      return "vi.";
    case "excess_income_derived_from_qualifying_intellectual_property":
      return "vii.";
    default:
      return "";
  }
};

export const renameIndexExpense = (model) => {
  switch (model) {
    case "total_expenses_of_qfzp":
      return "B.";
    case "expenses_attributable_for_domestic":
      return "i.";
    case "expenses_attributable_for_foreign_PE":
      return "ii.";
    case "expenses_commercial_property_located_in_FZP":
      return "iii.";
    case "expenses_from_non_commercial_property_located_in_FZP":
      return "iv.";
    case "expenses_in_transactions_person_related_ncp_fz":
      return "v.";
    case "expenses_related_earning_income_NQIP":
      return "vi.";
    case "revenue_income_derived_qualifying_intellectual_property":
      return "vii.";
    case "excess_income_derived_from_qualifying_intellectual_property":
      return "viii.";
    default:
      return "";
  }
};

<template>
  <div class="row mt-4">
    <div class="col-lg-12">
      <AlertModal
        :open="beforeLeave.modal"
        message="You have updated information in this page!"
        sub-message="Please save before leaving"
        :on-confirm="() => saveOption(handleOnLeave)"
        :on-cancel="() => changeAlertModal(false)"
        button-name="Save Now"
      />
      <AlertModal
        :open="unSavedModal"
        message="You have not selected Whether Company is Qualified Free Zone Person!"
        sub-message="please select the appropriate option."
        :on-confirm="() => openCloseUnSavedModal(false)"
        :on-cancel="() => openCloseUnSavedModal(false)"
        :has-cancel="false"
        button-name="OK"
      />
      <AlertModal
        :open="auditorDetailsModal"
        message="You've chosen 'Yes' to indicate that the company qualifies as a Free Zone Person. However, Whether Liable to Audit should be Yes"
        sub-message="Please select the appropriate answer!"
        :on-confirm="() => openCloseauditorDetailsModal(false)"
        :on-cancel="() => openCloseauditorDetailsModal(false)"
        :has-cancel="false"
        button-name="OK"
      />
    </div>
    <div class="col-lg-12">
      <div
        class="w-100 wrapper d-flex p-3 align-items-center justify-content-between"
      >
        <h4 class="choose-title w-50 bold mb-0">Financial Data Type</h4>
        <ChooseRadioButton
          v-model="financial_type"
          :options="[
            {
              name: 'IFRS',
              value: 'IFRS',
              input: {
                disabled: !!statusStoreStatus,
              },
            },
            {
              name: 'Non IFRS',
              value: 'Non-IFRS',
              input: {
                disabled: !!statusStoreStatus,
              },
            },
          ]"
        />
      </div>
    </div>
    <div class="col-lg-12">
      <div
        class="w-100 wrapper d-flex p-3 align-items-center justify-content-between"
      >
        <h4
          :class="`choose-title w-50 bold mb-0  ${
            status.method_of_accounting ? '' : 'text-danger'
          }`"
        >
          Method of Accounting?
        </h4>
        <ChooseRadioButton
          v-model="options.method_of_accounting"
          :options="[
            {
              name: 'Cash',
              value: 'cash',
              text: status.method_of_accounting ? '' : 'text-danger',
              input: {
                disabled: !!statusStoreStatus,
              },
            },
            {
              name: 'Accrual',
              value: 'accrual',
              text: status.method_of_accounting ? '' : 'text-danger',
              input: {
                disabled: !!statusStoreStatus,
              },
            },
          ]"
        />
      </div>
    </div>
    <div class="col-lg-12">
      <div
        class="w-100 wrapper p-3 d-flex align-items-center justify-content-between"
      >
        <h4
          :class="`choose-title w-50 bold ${
            status.liable_to_audit ? '' : 'text-danger'
          }`"
        >
          Whether Liable to Audit?
        </h4>
        <ChooseRadioButton
          v-model="options.liable_to_audit"
          :options="[
            {
              name: 'Yes',
              value: 'Y',
              text: status.liable_to_audit ? '' : 'text-danger',
              onClick: () => changeTab('auditor_details'),
              input: {
                disabled: !!statusStoreStatus,
              },
            },
            {
              name: 'No',
              value: 'N',
              text: status.liable_to_audit ? '' : 'text-danger',
              input: {
                disabled: !!statusStoreStatus,
              },
            },
          ]"
        />
      </div>
    </div>
    <div class="col-lg-12">
      <div
        class="w-100 wrapper p-3 d-flex align-items-center justify-content-between"
      >
        <h4
          :class="`choose-title w-50 bold ${
            status.liable_to_maintain_tp_documentation ? '' : 'text-danger'
          }`"
        >
          Whether Company Liable to Maintain TP Documentation?
        </h4>
        <ChooseRadioButton
          v-model="options.liable_to_maintain_tp_documentation"
          :options="[
            {
              name: 'Yes',
              value: 'Y',
              text: status.liable_to_maintain_tp_documentation
                ? ''
                : 'text-danger',
              input: {
                disabled: !!statusStoreStatus,
              },
            },
            {
              name: 'No',
              value: 'N',
              text: status.liable_to_maintain_tp_documentation
                ? ''
                : 'text-danger',
              input: {
                disabled: !!statusStoreStatus,
              },
            },
          ]"
        />
      </div>
    </div>

    <div class="col-lg-12">
      <div
        class="w-100 wrapper p-3 d-flex align-items-center justify-content-between"
      >
        <h4 class="choose-title w-50 bold">
          Whether Company is Registered in Freezone?
        </h4>
        <ChooseRadioButton
          v-model="options.company_registered_in_freezone"
          :options="[
            {
              name: 'Yes',
              value: 'Y',
              input: {
                disabled: true,
              },
            },
            {
              name: 'No',
              value: 'N',
              input: {
                disabled: true,
              },
            },
          ]"
        />
      </div>
    </div>

    <div class="col-lg-12 mb-4">
      <div
        v-if="options.company_registered_in_freezone === 'Y'"
        class="w-100 wrapper p-3 d-flex align-items-center justify-content-between"
      >
        <h4 class="choose-title w-50 bold">
          Whether Company is Qualified Free Zone Person ?
        </h4>
        <ChooseRadioButton
          v-model="options.qualified_for_freezone"
          :options="[
            {
              name: 'Yes',
              value: 'Y',
              onClick: () => changeTab('calculator'),
              input: {
                disabled: qfzpDisabled,
              },
            },
            {
              name: 'No',
              value: 'N',
              onClick: () => setModal(true),
              input: {
                disabled: qfzpDisabled,
              },
            },
          ]"
        >
          <button
            :disabled="qfzpDisabled"
            class="save-button p-2 mr-3"
            @click="changeTab('calculator')"
          >
            Check/Fill Data
          </button>
        </ChooseRadioButton>
      </div>
    </div>
    <NonQFZP :modal="modal" :set-modal="setModal" />
    <div class="col-lg-12 mb-5">
      <button
        class="save-button px-5 py-2 mr-3"
        @click="() => saveOption(() => {})"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script setup>
import ChooseRadioButton from "../common/ChooseRadioButton.vue";
import { useGeneralInfoStore } from "../../../store/generalInformation";
import { storeToRefs } from "pinia";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import { onMounted, onUnmounted, ref, watch, computed } from "vue";
import { AlertModal } from "../../common";
import { useStatusStore } from "../../../store/statusStore";
import NonQFZP from "./NonQFZP.vue";
import { changeObjectToQueryString } from "../../../helpers/stringUtils";
import { useIFRSStore } from "../../../store/NonIFRS/IFRS.store";
import { Toast } from "../../../helpers/toast";
const generalInfoStore = useGeneralInfoStore();
const { options, status } = storeToRefs(generalInfoStore);
const statusStore = useStatusStore();
const { status: statusStoreStatus } = storeToRefs(statusStore);
const route = useRoute();
const ifrsStore = useIFRSStore();
const { license_authority_type } = storeToRefs(ifrsStore);
const router = useRouter();
const auditorDetailsModal = ref(false);
const statusMessage = ref({
  message: "",
  type: "info",
});

const beforeLeave = ref({
  changes: false,
  to: "",
  modal: false,
});
const unSavedModal = ref(false);
const financial_type = ref("");
const qfzpDisabled = computed(
  () =>
    !!statusStoreStatus.value ||
    (Number(options.value.selected_non_qfzp_year_count) &&
      Number(options.value.selected_non_qfzp_year_count) > 1 &&
      Number(options.value.selected_non_qfzp_year_count) <= 5)
);
const openCloseUnSavedModal = (value) => {
  unSavedModal.value = value;
};
const changeAlertModal = (value) => {
  beforeLeave.value.modal = value;
};
const setStatusMessage = (message, type) => {
  statusMessage.value.message = message;
  statusMessage.value.type = type;
};
const modal = ref(false);
const setModal = (value) => {
  if (!value) {
    const beforeChange = beforeLeave.value.changes;
    beforeLeave.value.changes = true;
    router.push({
      path: `/entity/general-information/${route.params.id}`,
      params: { id: route.params.id },
      query: { ...route.query, open_non_qfzp: undefined },
    });
    beforeLeave.value.changes = beforeChange;
  }
  modal.value = value;
};
const openCloseauditorDetailsModal = (value) => {
  auditorDetailsModal.value = value;
};
const saveOption = async (callBack = () => {}) => {
  if (
    !options.value.qualified_for_freezone &&
    license_authority_type.value == 2
  ) {
    openCloseUnSavedModal(true);
    return;
  } else if (
    options.value.qualified_for_freezone === "Y" &&
    options.value.liable_to_audit !== "Y"
  ) {
    openCloseauditorDetailsModal(true);
    return;
  }
  let toast = new Toast("Saving please wait...");
  if (options.value.financial_type !== financial_type.value) {
    toast.makeInfoToast("Saving financial type...");
    await ifrsStore.saveFinancialType(
      route.params.id,
      route.query.financial_year,
      route.query.financial_period,
      financial_type.value,
      async () => {
        await ifrsStore.getIFRSCheck(
          route.params.id,
          route.query.financial_year,
          route.query.financial_period
        );
        toast.makeInfoToast("Saving general information...");
        await generalInfoStore.saveOptions(
          route.params.id,
          route.query.financial_year,
          setStatusMessage,
          async () => {
            await generalInfoStore.getGeneralInformation(
              route.params.id,
              route.query.financial_year
            );
            beforeLeave.value.changes = false;
            beforeLeave.value.modal = false;
            await callBack();
          },
          toast
        );
      },
      toast
    );
    return;
  }
  toast.makeInfoToast("Saving general information...");
  await generalInfoStore.saveOptions(
    route.params.id,
    route.query.financial_year,
    setStatusMessage,
    async () => {
      await generalInfoStore.getGeneralInformation(
        route.params.id,
        route.query.financial_year
      );
      beforeLeave.value.changes = false;
      beforeLeave.value.modal = false;
      await callBack();
    },
    toast
  );
};
const handleOnLeave = () => {
  const to = beforeLeave.value.to;
  beforeLeave.value.to = "";
  router.push(to);
};

const changeTab = async (to) => {
  const query = await changeObjectToQueryString({
    ...route.query,
    ...options.value,
  });
  const changes = beforeLeave.value.changes;
  beforeLeave.value.changes = false;
  if (to === "auditor_details") {
    await router.push(
      `/entity/general-information/auditor-details/${route.params.id}?${query}`
    );
  } else if (to === "calculator") {
    await router.push(
      `/entity/general-information/fzp-calculator/${route.params.id}?${query}`
    );
  }
  beforeLeave.value.changes = changes;
};
const unWatchOptions = watch(
  options,
  () => {
    beforeLeave.value.changes = true;
  },
  { deep: true }
);
const unWatchFinancialType = watch(financial_type, (newVal) => {
  if (options.value.financial_type !== newVal) {
    beforeLeave.value.changes = true;
  }
});
onBeforeRouteLeave(async (to) => {
  if (beforeLeave.value.changes) {
    beforeLeave.value.to = await to;
    await changeAlertModal(true);
    return false;
  }
  return true;
});
const unWatchRouteQuery = watch(
  () => route.query,
  (newVal) => {
    if (newVal.open_non_qfzp) {
      setModal(true);
    }
  },
  { deep: true }
);
onMounted(() => {
  if (route.query.open_non_qfzp) {
    setModal(true);
  }
  financial_type.value = options.value.financial_type;
});
watch(
  () => options.value.financial_type,
  (newVal) => {
    financial_type.value = newVal;
  }
);
onUnmounted(() => {
  unWatchRouteQuery();
  unWatchOptions();
  unWatchFinancialType();
});
</script>

<style lang="scss" scoped>
.choose-title {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.88px;
}
.choose-name {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.88px;
}
.wrapper {
  background: #deeded;
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.save-button {
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.save-button:disabled {
  opacity: 0.5;
}
.f-20 {
  font-size: 30px;
}
</style>

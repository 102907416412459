<template>
  <div class="d-flex flex-wrap justify-content-end align-items-center">
    <select
      v-model="financial_period"
      disabled
      class="date-select pl-2 mt-2 mr-3"
    >
      <option
        v-for="(year, index) in financialPeriod.data"
        :key="year.id"
        class="option"
        :value="year.id"
        :selected="!index"
      >
        {{ year.name }}
      </option>
    </select>
    <select
      id=""
      v-model="financial_year"
      name=""
      class="date-select pl-2 mt-2 mr-3"
    >
      <option
        v-for="(year, index) in financialYear.data"
        :key="year.id"
        class="option"
        :value="year.id"
        :selected="!index"
      >
        {{ year.name }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { onMounted } from "vue";
import { useEntityStore } from "../../../store/entity";
import {
  getEntityItem,
  getEntityItems,
} from "../../../store/entityActions/get";
import { useProfitAndLossStore } from "../../../store/ProfitAdLoss";
import { watch } from "vue";
import { useRoute } from "vue-router";
import { useExpenditureStore } from "../../../store/expenditure";
import { useBusinessStore } from "../../../store/buisness.store";
import { useFinancialDataStore } from "../../../store/financial.store";
import { useLiabilityDataStore } from "../../../store/liabities.store";
import { useComputeIncomeTaxStore } from "../../../store/incometax.store";
import { useIFRSStore } from "../../../store/NonIFRS/IFRS.store";
import { useQfzpCoiStore } from "../../../store/QFZPCOI";

const entityStore = useEntityStore();
const route = useRoute();
const {
  financialYear: financialPeriod,
  financialPeriod: financialYear,
  entity,
} = storeToRefs(entityStore);
const ifrsStore = useIFRSStore();
const profitAndLossStore = useProfitAndLossStore();
const expenditureStore = useExpenditureStore();
const financialDataStore = useFinancialDataStore();
const liabilityDataStore = useLiabilityDataStore();
const computeIncomeTaxStore = useComputeIncomeTaxStore();
const { loading } = storeToRefs(computeIncomeTaxStore);
const { financial_year, financial_period } = storeToRefs(profitAndLossStore);
const businessStore = useBusinessStore();
const qfzCoiStore = useQfzpCoiStore();
const { loading: qfzCoiStoreLoading } = storeToRefs(qfzCoiStore);
const { current_business } = storeToRefs(businessStore);

onMounted(async () => {
  if (!financialPeriod.value.data.length) {
    await getEntityItems("financialYear", "/financialYear");
  }
  financial_period.value = "";
  financial_year.value = "";
  const Financial_period = entity.value.data?.entity[0]?.financial_period?.id;
  financial_period.value = Financial_period
    ? Financial_period
    : financial_period.value;
  await getEntityItem(
    "financialPeriod",
    "/financialYear",
    financial_period.value
  );
  const fy = await localStorage.getItem("financial_year");
  if (fy) {
    financial_year.value = fy;
  } else {
    financial_year.value = financial_year.value
      ? financial_year.value
      : financialYear.value.data[0]?.id;
    localStorage.setItem("financial_year", financial_year.value);
  }
});
const getData = async (financial_year, financial_period) => {
  loading.value = true;
  qfzCoiStoreLoading.value = true;

  await ifrsStore.getIFRSCheck(
    route.params.id,
    financial_year,
    financial_period,
    async ({ qualified_for_freezone, license_authority_type }) => {
      await businessStore.getBusiness(
        route.params.id,
        financial_year,
        financial_period
      );
      if (qualified_for_freezone === "Y" && license_authority_type == 2) {
        await qfzCoiStore.getQfzpcoiData(
          route.params.id,
          financial_year,
          financial_period
        );
      } else {
        await computeIncomeTaxStore.getComputeIncomeTax(route.params.id);
        await profitAndLossStore.getIncomeRevenue(
          route.params.id,
          financial_year,
          financial_period,
          current_business.value
        );
        await expenditureStore.getExpenditure(
          route.params.id,
          financial_year,
          financial_period,
          current_business.value
        );

        await financialDataStore.getAsset(route.params.id);
        await liabilityDataStore.getLiabilities(route.params.id);
      }
    }
  );
};

watch(financial_year, async (newValue, oldValue) => {
  if (newValue == oldValue) return;
  if (!newValue) return;
  await localStorage.setItem("financial_year", newValue);
  await getData(newValue, financial_period.value, "financial_year");
});
</script>

<style scoped>
.date-select {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  width: 140px;
  height: 40px;
  margin-bottom: 10px;
}
.date-select:focus {
  outline: none;
}
.option {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

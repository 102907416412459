export const chapter7DataStructure = {
  previousValue: {
    dividends_and_other_profit_distributions: 0,
  },
  addition_item: null,
  addition_description: null,
  deduction_item: null,
  deduction_description: null,
  total_addtion: 0,
  total_diduction: 0,
  total: 0,
  articles: [],
  changesMadeIn12: false,
  additional_data: {
    addition: {
      data: [],
      total: "",
    },
    deduction: {
      data: [],
      total: "",
    },
    total: "",
  },
};

<template>
  <form
    :id="`form-${modelName}`"
    :class="`w-100 table-body py-4  ${
      sub ? 'sub' : ''
    } ${bgColor} d-flex align-items-center justify-content-between`"
  >
    <CustomModalContainer
      :open="state.showPopup"
      :set-open="(val) => openClose('popup', val)"
      :on-close="handlePopUpClose"
      persistent
    >
      <button class="save-button p-3 mr-3" @click="addNew">
        <img src="@/assets/svg/plus-square-light.svg" alt="" />
        Add New
      </button>
      <div class="w-100 table-responsive mt-4 mb-0">
        <table class="table custom-table mb-0 table-bordered table-striped">
          <thead>
            <tr>
              <th class="table-header">Sl No.</th>
              <th class="table-header">Particulars</th>
              <th class="table-header">Amount</th>
              <th class="table-header">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in state.popupData" :key="item.id">
              <td class="table-rows">
                <p class="data-text mb-0">{{ index + 1 }}</p>
              </td>
              <td class="table-rows">
                <input
                  v-model="state.popupData[index].particular"
                  type="text"
                  class="input-custom"
                  @input="changedPopUpValues"
                />
              </td>
              <td class="table-rows">
                <input
                  v-model="state.popupData[index].amount"
                  type="number"
                  class="input-custom"
                  step="0.01"
                  @keypress="validateAmount"
                  @input="changedPopUpValues"
                />
              </td>
              <button class="delete-button w-100" @click="deleteItem(item.id)">
                <img src="@/assets/svg/trash.svg" alt="" />
              </button>
            </tr>
            <tr>
              <td class="align-middle" :colspan="4">
                <h5 class="w-100 d-flex justify-content-end table-header">
                  {{ NumberFormatter(total) }}
                </h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CustomModalContainer>
    <h5 v-if="makeTextBold" class="mb-0 w-75 body-title bold">
      <b>{{ name }}</b>
    </h5>
    <p v-if="!makeTextBold" class="mb-0 w-75 body-title">
      {{ name }}
    </p>
    <s></s>
    <h5
      v-if="!state.showInput && makeTextBold"
      :class="`body-title ${isDisabled ? '' : 'cursor-pointer'}  mb-0 bold`"
      :style="{ color: amountColor }"
      @dblclick="handleDoubleClick"
      @click="handleClick"
    >
      <b> {{ NumberFormatter(getLastUsed()) }}</b>
    </h5>
    <p
      v-if="!state.showInput && !makeTextBold"
      :class="`body-title ${isDisabled ? '' : 'cursor-pointer'} mb-0`"
      :style="{ color: amountColor }"
      @dblclick="handleDoubleClick"
      @click="handleClick"
    >
      {{ NumberFormatter(getLastUsed()) }}
    </p>
    <input
      :id="'input-' + modelName"
      :value="data[modelName].value"
      :hidden="!state.showInput"
      type="number"
      class="clickable-text"
      v-bind="inputProps"
      @focus="setFirstValue(data[modelName].value)"
      @input="setInputValue"
      @blur="handleBlur(false)"
      @keydown.tab.enter="(e) => handleTabEnter(e)"
      @keypress="validateAmount"
    />
  </form>
</template>

<script setup>
import { watch, onMounted, reactive, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { generate } from "randomized-string";
import { useNoneIFRSBalanceSheet } from "../../../../../store/NonIFRS/balancesheet.store";
import { CustomModalContainer } from "../../../../common";
import { useStatusStore } from "../../../../../store/statusStore";
import { validateAmount } from "../../../../../helpers/validation";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";
import { autoSaveNoneIFRSBS } from "../common/autoSave";
const props = defineProps({
  modelName: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
  showInput: {
    type: Boolean,
    default: false,
  },
  sub: {
    type: Boolean,
    default: false,
  },
  bgColor: {
    type: String,
    default: "white",
  },
  onTabAndEnter: {
    type: Function,
    default: () => {},
  },
  makeTextBold: {
    type: Boolean,
    default: false,
  },
  inputProps: {
    type: Object,
    default: {},
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  onClick: {
    type: [Function, Boolean],
    default: false,
  },
  amountColor: {
    type: String,
    default: "",
  },
  showPopup: {
    type: Boolean,
    default: false,
  },
  setSavingStatus: {
    type: Function,
    default: () => {},
  },
  tab: {
    type: String,
    default: "Liabilities",
  },
});

const nonIfrsBsStore = useNoneIFRSBalanceSheet();
const { data, groupData } = storeToRefs(nonIfrsBsStore);
const state = reactive({
  showInput: props.showInput,
  showPopup: props.showPopup,
  popupData: [],
  firstInputValue: "",
  changedPopUp: false,
});
const route = useRoute();
const router = useRouter();

const total = computed(() => {
  if (!state.popupData.length) return 0;
  return state.popupData.reduce((acc, curr) => acc + Number(curr.amount), 0);
});
const statusStore = useStatusStore();
const { status } = storeToRefs(statusStore);
const changedPopUpValues = () => {
  state.changedPopUp = true;
};
const setStoreState = (value, inputType = "value") => {
  let obj = {
    ...data.value,
    [props.modelName]: { ...data.value[props.modelName], [inputType]: value },
  };
  data.value = obj;
};
const setInputValue = async (e) => {
  let value = e.target.value;
  setStoreState(value);
};

const openCloseInput = async (value) => {
  state.showInput = await value;
  const inputField = await document.getElementById(`input-${props.modelName}`);
  if (value && inputField) {
    state.changedPopUp = false;
    inputField.hidden = await false;
    await inputField.focus();
  }
};
const openClosePopUp = async (value) => {
  state.showPopup = value;
};
const setLastUsed = async (value) => {
  const prevLastInput = data.value[props.modelName].last_input;
  await setStoreState(value, "last_input");
  if (prevLastInput !== value && props.tab === "Assets") {
    await autoSaveNoneIFRSBS(props.setSavingStatus, route, router);
  }
};
const handleDoubleClick = async (value) => {
  state.doubleClick = true;
  await openClose("popup", value);
  setTimeout(() => {
    state.doubleClick = false;
  }, 250);
};
const handleClick = (value) => {
  setTimeout(async () => {
    if (!state.doubleClick) {
      await openClose("input", value);
    }
  }, 250);
};
const isDisabled = computed(() => {
  if (props.disabled) {
    return true;
  }
  if (status.value || route.query.business === "master") {
    return true;
  }
  if (window.location.pathname.includes("view")) {
    return true;
  }
  return false;
});
const openClose = async (type, value) => {
  if (props.onClick) {
    props.onClick();
    return;
  }
  if (props.disabled) {
    return;
  }
  if (status.value || route.query.business === "master") {
    return;
  }
  if (window.location.pathname.includes("view")) {
    return;
  }
  if (type === "input") {
    await openCloseInput(value);
    if (value) setLastUsed(type);
  } else if (type === "popup") {
    await openClosePopUp(value);
    if (value) setLastUsed(type);
  }
};
const getLastUsed = () => {
  if (route.query.business === "master") {
    return groupData.value[props.modelName];
  }
  if (data.value[props.modelName].last_input === "input") {
    return data.value[props.modelName].value;
  } else if (data.value[props.modelName].last_input === "popup") {
    return total.value;
  }
};
const handlePopUpClose = async () => {
  await setStoreState(state.popupData, "data");
  await setStoreState(total.value, "total");
  await autoSavePopup(state.popupData);
  await openClose("popup", false);
};
const handleTabEnter = async (e) => {
  e.preventDefault();
  await openCloseInput(false);
  await props.onTabAndEnter(props.modelName, state.showInput);
};

const setData = async (Data) => {
  let cleanedData = [];
  if (typeof Data === "object") {
    if (Data.length < 5) {
      for (let i = 0; i < 5; i++) {
        let currentPropValue = Data[i];
        if (currentPropValue) {
          let amount = currentPropValue.amount;
          if (amount == 0) {
            currentPropValue.amount = "";
          }
          cleanedData[i] = currentPropValue;
        } else {
          cleanedData[i] = {
            id: `${props.modelName}-${generate({
              charset: "number",
              length: 10,
            })}`,
            particular: "",
            amount: "",
          };
        }
      }
    } else {
      for (let i = 0; i < 5; i++) {
        let currentPropValue = Data[i];
        let amount = currentPropValue.amount;
        if (amount == 0) {
          currentPropValue.amount = "";
        }
        cleanedData[i] = currentPropValue;
      }
      cleanedData = Data;
    }
  } else {
    cleanedData = [];
    for (let i = 0; i < 5; i++) {
      cleanedData[i] = {
        id: `${props.modelName}-${generate({
          charset: "number",
          length: 10,
        })}`,
        particular: "",
        amount: "",
      };
    }
  }
  state.popupData = cleanedData;
};
onMounted(() => {
  setData(data.value[props.modelName].data);
});

const handleBlur = async (value) => {
  await openCloseInput(value);
  await autoSaveInput();
  if (props.inputProps.onblur) {
    await props.inputProps.onblur(props.modelName, value);
  }
};
const autoSavePopup = async () => {
  if (props.tab === "Assets") {
    if (state.changedPopUp) {
      await autoSaveNoneIFRSBS(props.setSavingStatus, route, router);
      state.changedPopUp = false;
    }
  }
};
const autoSaveInput = async () => {
  if (props.tab === "Assets") {
    if (state.firstInputValue !== data.value[props.modelName].value) {
      await autoSaveNoneIFRSBS(props.setSavingStatus, route, router);
    }
  }
};
const deleteItem = async (id) => {
  const deleteData = state.popupData.filter((d) => d.id !== id);
  state.popupData = deleteData;
};
const addNew = async () => {
  const newData = [
    ...state.popupData,
    {
      id: `${props.modelName}-${generate({
        charset: "number",
        length: 10,
      })}`,
      particular: "",
      amount: "",
    },
  ];
  state.popupData = newData;
};
const setFirstValue = (value) => {
  state.firstInputValue = value;
  if (props.inputProps.onfocus) {
    props.inputProps.onfocus(props.modelName, value);
  }
};
watch(
  () => data.value[props.modelName].data,
  async (newVal) => {
    await setData(newVal);
  },
  { deep: true }
);

watch(
  () => props.showInput,
  async (newVal) => {
    await openClose("input", newVal);
  },
  { deep: true }
);
watch(
  () => props.showPopup,
  async (newVal) => {
    await openClose("popup", newVal);
  },
  { deep: true }
);
</script>

<style scoped>
.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
  border-bottom: 1px solid rgba(116, 113, 113, 0.3);
}
.table-body.sub {
  padding-left: 46px !important;
}
.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}
.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}
@media screen and (max-width: 600px) {
  .table-body.sub {
    padding-left: 16px !important;
  }
}
.input-custom:focus {
  outline: none;
}
.save-button {
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
</style>

<template>
  <div class="w-100 mt-4">
    <FormCheckList article="article_26_checklist" />
    <div v-if="showForm" class="mt-4 w-100">
      <div class="w-100 d-flex align-items-center justify-content-between">
        <h4 class="title bold">Name of Assets</h4>
        <div class="d-flex align-items-center">
          <button class="save-button mr-3" @click="saveData()">Save</button>
          <button
            v-if="!status"
            class="save-button mr-3"
            @click="addNewForm('article_26_assets')"
          >
            <img src="@/assets/svg/plus-square-light.svg" alt="" />
            Add New
          </button>
        </div>
      </div>
      <FormHeader article="assets">
        <Form
          v-for="item in data.article_26_assets"
          :id="item.id"
          :key="item.id"
          article="article_26_assets"
          :assetsOrLiabilities="article26Assets"
        />
      </FormHeader>
      <div class="w-100 mt-4 d-flex align-items-center justify-content-between">
        <h4 class="title bold">Name of Liability</h4>
        <div class="d-flex align-items-center">
          <button class="save-button mr-3" @click="saveData()">Save</button>
          <button
            v-if="!status"
            class="save-button mr-3"
            @click="addNewForm('article_26_liabilities')"
          >
            <img src="@/assets/svg/plus-square-light.svg" alt="" />
            Add New
          </button>
        </div>
      </div>
      <FormHeader article="liabilities">
        <Form
          v-for="item in data.article_26_liabilities"
          :id="item.id"
          :key="item.id"
          article="article_26_liabilities"
        />
      </FormHeader>
    </div>
    <TaxableCheckList />
    <div
      v-if="
        data.article_26_incident_occurs
          .the_taxable_persons_cease_to_be_member_of_the_same_qualifying_group &&
        data.article_26_incident_occurs
          .there_is_a_subsequent_transfer_of_the_asset_or_liability
      "
      class="w-100 mt-4"
    >
      <div class="w-100 my-3 d-flex align-items-center justify-content-between">
        <h4 class="title bold">Assets</h4>
        <button
          v-if="!status"
          class="save-button mr-3"
          @click="addNewTaxableForm('assets')"
        >
          <img src="@/assets/svg/plus-square-light.svg" alt="" />
          Add New
        </button>
      </div>
      <TaxableLineItemHeader type="assets">
        <TaxableLineItemForm
          v-for="item in data.article_26_taxable_lineitem?.assets"
          :id="item.id"
          :key="item.id"
          article="article_26_taxable_lineitem"
        />
      </TaxableLineItemHeader>
      <div class="col-lg-12">
        <div
          class="table-body py-3 pr-3 gray d-flex align-items-center w-100 justify-content-end"
        >
          <p class="sub-title bold mb-0 mr-10">Total Asset Taxable Amount</p>
          <p class="total-amount bold mb-0">
            {{ NumberFormatter(article26AssetsTotalTaxable) }}
          </p>
        </div>
      </div>
      <div class="w-100 my-3 d-flex align-items-center justify-content-between">
        <h4 class="title bold">Liabilities</h4>
        <button
          v-if="!status"
          class="save-button mr-3"
          @click="addNewTaxableForm('liabilities')"
        >
          <img src="@/assets/svg/plus-square-light.svg" alt="" />
          Add New
        </button>
      </div>
      <TaxableLineItemHeader type="liabilities">
        <TaxableLineItemForm
          v-for="item in data.article_26_taxable_lineitem?.liabilities"
          :id="item.id"
          :key="item.id"
          type="liabilities"
          article="article_26_taxable_lineitem"
        />
      </TaxableLineItemHeader>
      <div class="col-lg-12">
        <div
          class="table-body py-3 gray pr-3 d-flex align-items-center w-100 justify-content-end"
        >
          <p class="sub-title bold mb-0 mr-10">
            Total Liabilities Taxable Amount
          </p>
          <p class="total-amount bold mb-0">
            {{ NumberFormatter(article26LiabilitiesTotalTaxable) }}
          </p>
        </div>
      </div>
      <div class="col-lg-12">
        <div
          class="table-body py-3 pr-3 border-bottom d-flex align-items-center w-100 justify-content-end"
        >
          <p class="sub-title bold mb-0 mr-10">Total Taxable Amount</p>
          <p class="total-amount bold mb-0">
            {{ NumberFormatter(article26TotalTaxableAmount) }}
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 my-4">
        <h4 class="title bold">Reference for Clause Below-</h4>
        <p class="article"></p>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-title
              collapse-icon="mdi-minus"
              expand-icon="mdi-plus"
            >
              <div
                class="px-3 py-3 w-100 d-flex align-items-center justify-content-between"
              >
                <h6 class="article-title mb-0">
                  {{ article26.name }}
                </h6>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div v-html="article26.description"></div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script setup>
import Form from "./Form.vue";
import { useChapter8Store } from "../../../../store/chapter8";
import { storeToRefs } from "pinia";
import FormCheckList from "./FormCheckList.vue";
import TaxableCheckList from "./TaxableCheckList26.vue";
import TaxableLineItemForm from "./TaxableLineItemForm.vue";

import { generate } from "randomized-string";
import { useRoute } from "vue-router";
import { onMounted, ref, watch } from "vue";
import { NumberFormatter } from "../../../../helpers/numberFormatter";
import FormHeader from "./FormHeader.vue";
import TaxableLineItemHeader from "./TaxableLineItemHeader.vue";
import { useStatusStore } from "../../../../store/statusStore";

const chapter8Store = useChapter8Store();
const {
  data,
  article26AssetsTotalTaxable,
  article26LiabilitiesTotalTaxable,
  article26TotalTaxableAmount,
} = storeToRefs(chapter8Store);
const route = useRoute();
const statusStore = useStatusStore();
const { status } = storeToRefs(statusStore);
const showForm = ref(false);
const takenAssets = ref([]);
const addToTakenAssets = (asset) => {
  takenAssets.value.push(asset);
};

const takenLiabilities = ref([]);
const addToTakenLiabilities = (asset) => {
  takenLiabilities.value.push(asset);
};
const article26 = ref({
  name: "",
  description: "",
});
const addNewForm = (type) => {
  data.value[type].push({
    id: generate(),
    name_of_asset: "",
    sale_value: 0,
    sale_date: "",
    net_book_value_as_on_sale_date: 0,
    purchase_value: 0,
    purchase_date: "",
    net_book_value_as_on_purchase_date: 0,
  });
};
const addNewTaxableForm = (type) => {
  data.value.article_26_taxable_lineitem[type].push({
    id: generate(),
    date_of_initial_transfer: null,
    net_book_value: 0,
    market_value: 0,
    subsequent_transfer_date: null,
    taxable_amount: 0,
  });
};
const saveData = async () => {
  await chapter8Store.saveChapter8(route);
};
watch(
  data,
  async (newValue) => {
    const values = [];

    for (let newValueX in newValue.article_26_checklist) {
      const newValueData = newValue.article_26_checklist[newValueX];
      values.push(newValueData);
    }
    article26.value = newValue.articles.find(
      (article) => article.name == "Article 26"
    );
    showForm.value = values.reduce((a, b) => {
      return a && b;
    });
  },
  { deep: true }
);
onMounted(() => {
  const values = [];
  for (let x in data.value.article_26_checklist) {
    values.push(data.value.article_26_checklist[x]);
  }
  showForm.value = values.reduce((a, b) => {
    return a && b;
  });
  article26.value = data.value.articles?.find(
    (article) => article.name == "Article 26"
  );
});
</script>

<style scoped>
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.btn-add {
  width: 124.33px;
  height: 47px;
  background: #0f75bc;
  border-radius: 5px;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.header {
  padding: 6px 9px;
  height: 47px;
  background: #0f75bc;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
.custom-input {
  height: 44px;
  padding: 11px 13px;
  margin-top: 10px;
  border: 1px solid rgba(116, 113, 113, 0.3);
}
.custom-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
  text-align: start;
}
.article {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
.article-desc {
  white-space: pre-wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px; /* or
185% */
  letter-spacing: 0.055em;
  color: #747171;
}
.gray {
  background: #deeded;
}
.article-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px; /*
identical to box height, or 185% */
  letter-spacing: 0.055em;
  color: #000000;
}
.border-bottom {
  border: 1px solid #ede4e4;
}
</style>

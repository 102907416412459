<template>
  <div class="w-100">
    <p :class="`mb-0 label ${disabled ? 'disabled' : ''}`">{{ label }}</p>
    <div class="d-flex align-items-center">
      <input
        v-bind="$props"
        :type="
          type
            ? type === 'password'
              ? showpassword
                ? 'text'
                : 'password'
              : type
            : 'text'
        "
        v-model="value"
        :placeholder="placeholder"
        :maxlength="MaxLength"
        :class="`account-input ${addSuggestion ? 'border-' + color : ''} ${
          errorMessage ? 'error' : ''
        } w-100`"
        :max="max"
        :autocomplete="autocomplete ? autocomplete : 'on'"
        :style="style"
      />
      <img
        v-if="type === 'password' && !showpassword"
        src="@/assets/svg/eye-off.svg"
        :class="`eye-off cursor-pointer ${disabled ? 'disabled' : ''}`"
        @click="showPassword"
      />
      <img
        v-else-if="type === 'password' && showPassword"
        src="@/assets/svg/eye.svg"
        :class="`eye-off eye cursor-pointer ${disabled ? 'disabled' : ''}`"
        @click="showPassword"
      />
      <p
        v-if="type === 'password' && addSuggestion"
        :class="`p-2 suggestion text-${color}`"
      >
        {{ suggestion }}
      </p>
    </div>
    <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
  </div>
</template>

<script>
import { checkPasswordStrength } from "../../helpers/password";

export default {
  name: "AccountInput",
  props: [
    "modelValue",
    "placeholder",
    "label",
    "type",
    "errorMessage",
    "MaxLength",
    "disabled",
    "max",
    "autocomplete",
    "checkValidity",
    "addSuggestion",
    "style",
  ],
  emits: ["update:modelValue"],
  data() {
    return {
      showpassword: false,
      suggestion: "",
      color: "",
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
        if (this.type === "password" && value && this.addSuggestion) {
          let { valid, suggestion, color } = checkPasswordStrength(value);
          this.suggestion = suggestion;
          this.color = color;
          if (this.checkValidity) {
            this.checkValidity(valid);
          }
        }
      },
    },
  },
  methods: {
    showPassword() {
      this.showpassword = !this.showpassword;
    },
  },
};
</script>

<style scoped>
.account-input {
  border: none;
  border-bottom: 1px solid #747171;
  padding-bottom: 15px;
}
.account-input.border-danger {
  border-bottom: --bs-danger;
}
.account-input.border-warning {
  border-bottom: --bs-warning;
}
.account-input.border-success {
  border-bottom: --bs-success;
}
.account-input::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #747171;
  text-align: left !important;
}
.account-input:focus {
  outline: 0;
  border-bottom: 1px solid #0f75bc;
}
span {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.055em;

  color: #000000;
}
.eye-off {
  margin-left: -30px;
  margin-bottom: 15px;
}
.label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
  z-index: 10;
}
.error {
  border-bottom: 1px solid red;
}
.eye {
  height: 30px;
  width: 25px;
}
.suggestion {
  margin-left: -200px;
  font-size: 14px;
  margin-bottom: 15px;
}
.account-input:disabled {
  opacity: 0.5;
}
.label.disabled {
  opacity: 0.5;
}
.eye-off.disabled {
  opacity: 0.5;
}
</style>

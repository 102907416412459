<template>
  <div
    v-for="(mainItem, mainIndex) in data"
    :key="mainItem.id"
    class="w-100 mt-1"
  >
    <div class="header d-flex align-items-center w-100 justify-content-between">
      <p class="header-title mb-0">
        {{ mainItem.name }}
      </p>
      <p class="header-title mb-0">
        {{ NumberFormatter(mainItem.sum()) }}
      </p>
    </div>
    <form
      v-for="(item, index) in mainItem.sub"
      :id="item.model"
      :key="item.id"
      :class="`w-100 table-body ${!index || !(index % 2) ? 'white' : 'gray'} ${
        item.sub ? 'sub' : ''
      } d-flex align-items-center justify-content-between`"
      @submit="($e) => handleSubmit($e, mainItem.id)"
    >
      <p class="mb-0 body-title">
        {{ item.name }}
      </p>
      <p
        v-if="!item.showInput"
        :class="`body-title ${isHeader(item.model) ? 'bold' : ''} mb-0`"
        @click="openCloseInput(item.model, mainItem.id, true)"
      >
        {{ NumberFormatter(currentLiability[item.model]) }}
      </p>

      <input
        :id="'input-current-liabilities-' + item.model"
        v-model="currentLiability[item.model]"
        :hidden="!item.showInput"
        type="number"
        class="clickable-text"
        @blur="openCloseInput(item.model, mainItem.id, false)"
        @keydown.tab="(e) => handleTab(e, mainItem.id, item.model)"
      />

      <!-- <p class="body-title mb-0">
        {{ item.amount }}
      </p> -->
    </form>
  </div>
</template>

<script setup>
import { generate } from "randomized-string";
import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useLiabilityDataStore } from "../../../../../../store/liabities.store";
import { NumberFormatter } from "../../../../../../helpers/numberFormatter";
import { useRoute } from "vue-router";

const props = defineProps({
  view: {
    type: Boolean,
    default: false,
  },
});
const liabilityDataStore = useLiabilityDataStore();
const { currentLiability, totalCurrentLiability } =
  storeToRefs(liabilityDataStore); //get initialvalue from store
const route = useRoute();
const data = ref([
  {
    name: "Trade and Other payables",
    id: generate(),
    sub: [
      {
        id: generate(),
        name: "Trade payables to:-",
        sub: false,
        showInput: false,
        amount: "0000.00",
        model: "trade_and_othrs_pybls_trades_payable_to",
      },
      {
        id: generate(),
        name: "   Non-related parties",
        sub: true,
        showInput: false,
        amount: "0000.00",
        model: "trade_and_othrs_pybls_trades_payable_to_non_related_parties",
      },
      {
        id: generate(),
        name: "   Associates",
        sub: true,
        showInput: false,
        amount: "0000.00",
        model: "trade_and_othrs_pybls_trades_payable_to_associates",
      },
      {
        id: generate(),
        name: " Other related parties",
        sub: true,
        showInput: false,
        amount: "0000.00",
        model: "trade_and_othrs_pybls_trades_payable_to_other_related_parties",
      },
      {
        id: generate(),
        name: "Refund liabilities",
        sub: false,
        showInput: false,
        amount: "0000.00",
        model: "trade_and_othrs_pybls_refund_liabilities",
      },
      {
        id: generate(),
        name: "Financial discounts",
        sub: false,
        showInput: false,
        amount: "0000.00",
        model: "trade_and_othrs_pybls_financial_discount",
      },
      {
        id: generate(),
        name: "Deferred grant income",
        sub: false,
        showInput: false,
        amount: "0000.00",
        model: "trade_and_othrs_pybls_deferred_grant_income",
      },
      {
        id: generate(),
        name: "Accrual of operating expenses",
        sub: false,
        showInput: false,
        amount: "0000.00",
        model: "trade_and_othrs_pybls_accural_of_operating_expenses",
      },
      {
        id: generate(),
        name: "Accruals of volume discounts",
        sub: false,
        showInput: false,
        amount: "0000.00",
        model: "trade_and_othrs_pybls_accural_of_volume_discounts",
      },
    ],
    sum() {
      return total(this.id);
    },
  },
  {
    name: "Current corporate tax liabilities",
    id: generate(),
    sub: [
      {
        id: generate(),
        name: "Beginning of financial year",
        sub: false,
        showInput: false,
        amount: "0000.00",
        model: "current_income_tax_liabilities_begining_of_the_financial_year",
      },
      {
        id: generate(),
        name: "Income tax paid",
        sub: false,
        showInput: false,
        amount: "0000.00",
        model: "current_income_tax_liabilities_income_tax_paid",
      },
      {
        id: generate(),
        name: "Tax expense",
        sub: false,
        showInput: false,
        amount: "0000.00",
        model: "current_income_tax_liabilities_tax_expense",
      },
      {
        id: generate(),
        name: "Under-provision in prior financial years",
        sub: false,
        showInput: false,
        amount: "0000.00",
        model:
          "current_income_tax_liabilities_under_provision_in_prior_fccal_yr",
      },
    ],
    sum() {
      return total(this.id);
    },
  },
  {
    name: "Borrowings",
    id: generate(),
    sub: [
      {
        id: generate(),
        name: "Bank overdrafts",
        sub: false,
        showInput: false,
        amount: "0000.00",
        model: "borrowings_bank_overdrafts",
      },
      {
        id: generate(),
        name: "Bank borrowings",
        sub: false,
        showInput: false,
        amount: "0000.00",
        model: "borrowings_bank_borrowings",
      },
      {
        id: generate(),
        name: "Lease liabilities",
        sub: false,
        showInput: false,
        amount: "0000.00",
        model: "borrowings_lease_liabilities",
      },
    ],
    sum() {
      return total(this.id);
    },
  },
  {
    name: "Provision",
    id: generate(),
    sub: [
      {
        id: generate(),
        name: "Warranty",
        sub: false,
        showInput: false,
        amount: "0000.00",
        model: "provisions_warranty",
      },
      {
        id: generate(),
        name: "Restructuring",
        sub: false,
        showInput: false,
        amount: "0000.00",
        model: "provisions_restructuring",
      },
    ],
    sum() {
      return total(this.id);
    },
  },
]);
const isHeader = (key) => {
  return key === "trade_and_othrs_pybls_trades_payable_to";
};

currentLiability.value.trade_and_othrs_pybls_trades_payable_to = computed(
  () => {
    const keys = [
      "trade_and_othrs_pybls_trades_payable_to_non_related_parties",
      "trade_and_othrs_pybls_trades_payable_to_associates",
      "trade_and_othrs_pybls_trades_payable_to_other_related_parties",
    ];
    return keys.reduce((a, b) => a + Number(currentLiability.value[b] || 0), 0);
  }
);

const openCloseInput = async (model, mainItemId, value) => {
  if (props.view || route.query.business === "master" || isHeader(model)) {
    return;
  }
  data.value = await data.value.map((item) => {
    if (item.id === mainItemId) {
      let newItem = item.sub.map((subItem) => {
        if (subItem.model === model) {
          return { ...subItem, showInput: value };
        }
        return subItem;
      });
      return { ...item, sub: newItem };
    }
    return item;
  });

  if (value) {
    const input = await document.getElementById(
      `input-current-liabilities-${model}`
    );
    input.focus();
  }
};
const total = (id) => {
  let sum = 0;
  data.value.forEach((item) => {
    if (item.id === id) {
      sum = item.sub.reduce((a, b) => {
        if (b.model === "trade_and_othrs_pybls_trades_payable_to") return a;
        if (b.model === "current_income_tax_liabilities_income_tax_paid") {
          return a - Number(currentLiability.value[b.model]);
        }
        return a + Number(currentLiability.value[b.model] || 0);
      }, 0);
    }
  });
  return sum;
};
data.value.forEach((item) => {
  if (item.name === "Trade and Other payables") {
    totalCurrentLiability.value.trade_and_others_payables = item.sub.reduce(
      (a, b) => {
        if (b.model === "trade_and_othrs_pybls_trades_payable_to") return a;
        return a + Number(currentLiability.value[b.model]);
      },
      0
    );
  } else if (item.name === "Current corporate tax liabilities") {
    totalCurrentLiability.value.current_income_tax_liabilities =
      item.sub.reduce((a, b) => {
        if (b.model === "current_income_tax_liabilities_income_tax_paid") {
          return a - Number(currentLiability.value[b.model]);
        }
        return a + Number(currentLiability.value[b.model]);
      }, 0);
  } else if (item.name === "Borrowings") {
    totalCurrentLiability.value.borrowings = item.sub.reduce(
      (a, b) => a + Number(currentLiability.value[b.model]),
      0
    );
  } else if (item.name === "Provision") {
    totalCurrentLiability.value.provisions = item.sub.reduce(
      (a, b) => a + Number(currentLiability.value[b.model]),
      0
    );
  }
});
watch(
  currentLiability,
  (newValue) => {
    data.value = data.value.map((item) => ({
      ...item,
      sum() {
        return total(item.id);
      },
    }));
    data.value.forEach((item) => {
      if (item.name === "Trade and Other payables") {
        totalCurrentLiability.value.trade_and_others_payables = item.sub.reduce(
          (a, b) => {
            if (b.model === "trade_and_othrs_pybls_trades_payable_to") return a;
            return a + Number(newValue[b.model]);
          },
          0
        );
      } else if (item.name === "Current corporate tax liabilities") {
        totalCurrentLiability.value.current_income_tax_liabilities =
          item.sub.reduce((a, b) => {
            if (b.model === "current_income_tax_liabilities_income_tax_paid") {
              return a - Number(newValue[b.model]);
            }
            return a + Number(newValue[b.model]);
          }, 0);
      } else if (item.name === "Borrowings") {
        totalCurrentLiability.value.borrowings = item.sub.reduce(
          (a, b) => a + Number(newValue[b.model]),
          0
        );
      } else if (item.name === "Provision") {
        totalCurrentLiability.value.provisions = item.sub.reduce(
          (a, b) => a + Number(newValue[b.model]),
          0
        );
      }
    });
  },
  { deep: true }
);
const handleSubmit = async (e, id) => {
  e.preventDefault();
  let current_model = e.target.id;
  const currentData = data.value.find((item) => item.id === id);
  if (!currentData) {
    return;
  }
  const next_model =
    currentData.sub[
      currentData.sub.map((e) => e.model).indexOf(current_model) + 1
    ];
  // close current  input
  openCloseInput(current_model, id, false);
  if (next_model) {
    await openCloseInput(next_model.model, id, true);
  }
};
const handleTab = async (e, id, current_model) => {
  e.preventDefault();
  const currentData = data.value.find((item) => item.id === id);
  if (!currentData) {
    return;
  }
  const next_model =
    currentData.sub[
      currentData.sub.map((e) => e.model).indexOf(current_model) + 1
    ];
  // close current  input
  openCloseInput(current_model, id, false);
  if (next_model) {
    await openCloseInput(next_model.model, id, true);
  }
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}
</style>

<template>
  <ProfitAndLossContainer tab="expenditure">
    <LoaderContainer
      :loading="statusLoading || financialTypeLoading"
      :error="errorBusiness || statusError"
    >
      <LoaderContainer
        v-if="data === 'IFRS'"
        :loading="expenditureLoading || incomeLoading"
      >
        <PBTCalculations :view="!!status" />
        <DistributionExpenses :view="!!status" />
        <MarketingExpenses :view="!!status" />
        <OccupancyExpenses :view="!!status" />
        <AdministrationExpenses :view="!!status" />
        <OtherExpenses :view="!!status" />
        <Ebitda />
        <FinanceCosts />
        <ReclassifiedPL :view="!!status" />
        <LossesOrGains :view="!!status" />
        <TotalExpenditure :view="!!status" />
      </LoaderContainer>
      <NonIfrsExpenditure v-if="data === 'Non-IFRS'" />
    </LoaderContainer>
  </ProfitAndLossContainer>
</template>

<script setup>
import ProfitAndLossContainer from "../Container";
import PBTCalculations from "./PBTcalculations.vue";
import DistributionExpenses from "./DistributionExpenses.vue";
import MarketingExpenses from "./MarketingExpenses.vue";
import OccupancyExpenses from "./OccupancyExpenses.vue";
import AdministrationExpenses from "./AdministrationExpenses.vue";
import FinanceCosts from "./FinanceCosts.vue";
import ReclassifiedPL from "./ReclassifiedPL.vue";
import ReclassifiedPL2 from "./ReclassifiedPL2.vue";
import LossesOrGains from "./LossesOrGains.vue";
import FinanceIncome from "./FinanceIncome.vue";
import FinanceCosts2 from "./FinanceCosts2.vue";
import TotalExpenditure from "./TotalExpenditure.vue";
import OtherExpenses from "./OtherExpenses.vue";
import { storeToRefs } from "pinia";
import { useExpenditureStore } from "../../../../../store/expenditure";
import { useProfitAndLossStore } from "../../../../../store/ProfitAdLoss";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStatusStore } from "../../../../../store/statusStore";
import { useBusinessStore } from "../../../../../store/buisness.store";
import NonIfrsExpenditure from "../../NON-IFRS/ProfitAndLoss/ExpenditureContainer.vue";
import { useIFRSStore } from "../../../../../store/NonIFRS/IFRS.store";
import { LoaderContainer } from "../../../../common";
import Ebitda from "./Ebitda.vue";

const expenditureStore = useExpenditureStore();
const profileAndLossStore = useProfitAndLossStore();
const { financial_period, financial_year } = storeToRefs(profileAndLossStore);
const statusStore = useStatusStore();
const profitLossStore = useProfitAndLossStore();

const {
  status,
  loading: statusLoading,
  error: statusError,
} = storeToRefs(statusStore);
const route = useRoute();
const businesStore = useBusinessStore();
const ifrStore = useIFRSStore();
const { data, loading: financialTypeLoading } = storeToRefs(ifrStore);
const { error: errorBusiness } = storeToRefs(businesStore);
const { loading: expenditureLoading } = storeToRefs(expenditureStore);
const { loading: incomeLoading } = storeToRefs(profitLossStore);
onMounted(async () => {
  expenditureLoading.value = true;
  incomeLoading.value = true;
});
</script>

<style scoped></style>

<template>
  <div class="w-100">
    <ShowStatus :modal="editStatus" :set-modal="setStatus" />
    <CustomTable :Data="data" :Headers="headers" :actions="actions" />
  </div>
</template>

<script setup>
import CustomTable from "@/components/common/CustomTable.vue";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import { useEntityStore } from "../../../../store/entity";
import { getEntityItems } from "../../../../store/entityActions/get";
import tableEdit from "../../../../assets/svg/table-edit.svg";
import { generate } from "randomized-string";
import { useRouter, useRoute } from "vue-router";
import ShowStatus from "./ShowStatus.vue";

const headers = ref(["Entity has branch"]);
const entityStore = useEntityStore();
const { entity, businessActivity, entityBranch } = storeToRefs(entityStore);
const router = useRouter();
const data = ref([]);
const route = useRoute();
const editStatus = ref(false);
const setStatus = (status) => {
  editStatus.value = status;
};
const actions = ref([
  {
    id: generate(),
    icon: tableEdit,
    onClick: (subId) => {
      if (entity.value?.data?.entity[0]?.file_return_status === "Filed") {
        setStatus(true);
        return;
      }
      router.push(`/entity/add-entity/branch-details/${route.params.id}`);
    },
  },
]);
onMounted(async () => {
  if (!businessActivity.value.data.length) {
    await getEntityItems("businessActivity", "/businessActivity");
  }
  const item = {
    id: "",
    EntityHasBranch: "No",
  };

  data.value = [item];
});
</script>

<style lang="scss" scoped></style>

<template>
  <LoaderContainer :loading="loading" :error="error">
    <div class="w-100 mt-3">
      <div
        class="header d-flex align-items-center w-100 justify-content-between"
      >
        <p class="header-title mb-0">Corporate Tax Return</p>
        <p class="header-title mb-0">Income</p>
      </div>
      <div
        class="w-100 table-body white cursor-pointer d-flex align-items-center justify-content-between"
        @click="$router.push(`/entity/general-information/${$route.params.id}`)"
      >
        <p class="mb-0 body-title">General Information</p>
      </div>
      <div
        v-for="(item, index) in data"
        :key="item.id"
        :class="`w-100 table-body ${
          !index || !(index % 2) ? 'gray' : 'white'
        } ${item?.sub ? 'sub' : ''} ${
          item.onClick ? 'cursor-pointer' : ''
        } d-flex align-items-center justify-content-between`"
        @click="item.onClick ? item.onClick() : () => {}"
      >
        <p class="mb-0 body-title w-75">{{ item.name }}</p>
        <p
          v-if="!item.showInput"
          class="body-title mb-0"
          v-bind:style="{ color: computationTaxColor[item.model] }"
        >
          {{ NumberFormatter(computeIncomeTax[item.model]) }}
        </p>
      </div>
      <div
        class="total-container d-flex align-items-center justify-content-between"
      >
        <p class="mb-0 total-amount">Total Income Current Year</p>
        <p
          class="mb-0 total-amount"
          v-bind:style="{
            color: computationTaxColor.total_income_current_year,
          }"
        >
          {{ NumberFormatter(computeIncomeTax.total_income_current_year) }}
        </p>
      </div>
      <div class="total-container d-flex align-items-center">
        <div
          class="w-100 d-flex align-items-center justify-content-between cursor-pointer"
          @click="pushToChapter('chapter-11')"
        >
          <p class="body-title total-desc mb-0 p-10px w-50">
            Brought Forward & Carried Forward Loss Chapter-11 (Loss- Sett-off in
            Current Year)
          </p>
          <p
            class="mb-0 total-amount"
            v-bind:style="{
              color: computationTaxColor?.chapter_eleven,
            }"
          >
            {{ NumberFormatter(computeIncomeTax?.chapter_eleven) }}
          </p>
        </div>
      </div>
      <div
        class="total-container d-flex align-items-center justify-content-between"
      >
        <p class="mb-0 total-amount">Total Income</p>
        <p
          class="mb-0 total-amount"
          v-bind:style="{ color: computationTaxColor?.total_income }"
        >
          {{ NumberFormatter(computeIncomeTax?.total_income) }}
        </p>
      </div>
      <div class="total-container d-flex flex-column justify-content-center">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <p class="mb-0 total-amount">Tax on Total Income @9%</p>
          <TaxonTotalIncome9Percent />
        </div>
      </div>
      <div
        class="total-container d-flex align-items-center cursor-pointer justify-content-between"
        @click="pushToChapter('chapter-13')"
      >
        <p class="mb-0 total-desc body-title py-3 px-1 w-50">
          Withholding and Foreign Tax Credit as per Chapter-13(WTC / FTC)
        </p>

        <p
          class="mb-0 total-amount"
          v-bind:style="{ color: computationTaxColor?.chapter_thirteen }"
        >
          {{ NumberFormatter(computeIncomeTax?.chapter_thirteen) }}
        </p>
      </div>

      <div
        class="grand-total d-flex align-items-center justify-content-between mb-10"
      >
        <p class="mb-0 grand-total-amount">Tax Payable / Refundable</p>
        <p class="mb-0 grand-total-amount">
          {{ NumberFormatter(computeIncomeTax?.tax_payable_refundable) }}
        </p>
      </div>
    </div>
  </LoaderContainer>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useComputeIncomeTaxStore } from "../../../store/incometax.store";
import { useProfitAndLossStore } from "../../../store/ProfitAdLoss";
import { storeToRefs } from "pinia";
import { NumberFormatter } from "../../../helpers/numberFormatter";
import LoaderContainer from "../../common/LoaderContainer.vue";
import { useBusinessStore } from "../../../store/buisness.store";
import TaxonTotalIncome9Percent from "./TaxonTotalIncome9Percent.vue";
const router = useRouter();
const route = useRoute();
const computeIncomeTaxStore = useComputeIncomeTaxStore();
const businessStore = useBusinessStore();
const profitAndLossStore = useProfitAndLossStore();
const { computeIncomeTax, loading, error, computationTaxColor } = storeToRefs(
  computeIncomeTaxStore
);
const { business } = storeToRefs(businessStore);
const { financial_year, financial_period } = storeToRefs(profitAndLossStore);
const current_business = computed(() => {
  if (!business.value.data.length) {
    return "";
  } else if (business.value.data.length === 1) {
    return business.value.data[0]?.id;
  }
  return "master";
});
const data = ref([
  {
    model: "business_income",
    showInput: false,
    name: "Business Income - Financial Info",
    sub: false,
    amount: "0000.00",
  },

  {
    model: "chapter_seven",
    showInput: false,
    onClick: () => {
      if (!current_business.value) {
        return;
      }
      router.push({
        path: `/entity/computation-tax/chapter-7/${route.params.id}`,
        query: {
          financial_year: `${financial_year.value}`,
          financial_period: `${financial_period.value}`,
          current_business: `${current_business.value}`,
        },
      });
    },
    name: "Exempt Income Chapter-7",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "chapter_eight",
    showInput: false,
    name: "Taxability as per Chapter - 8",
    onClick: () => {
      if (!current_business.value) {
        return;
      }
      router.push({
        path: `/entity/computation-tax/chapter-8/${route.params.id}`,
        query: {
          financial_year: `${financial_year.value}`,
          financial_period: `${financial_period.value}`,
          current_business: `${current_business.value}`,
        },
      });
    },
    sub: false,
    amount: "0000.00",
  },
  {
    model: "chapter_nine",
    showInput: false,
    name: "Deductions under Chapter-9",
    onClick: () => {
      if (!current_business.value) {
        return;
      }
      router.push({
        path: `/entity/computation-tax/chapter-9/${route.params.id}`,
        query: {
          financial_year: `${financial_year.value}`,
          financial_period: `${financial_period.value}`,
          current_business: `${current_business.value}`,
        },
      });
    },
    sub: false,
    amount: "0000.00",
  },
  {
    model: "chapter_ten",
    showInput: false,
    name: "Related Party Transactions under Chapter-10",
    sub: false,
    amount: "0000.00",
    onClick: () => {
      if (!current_business.value) {
        return;
      }
      router.push({
        path: `/entity/computation-tax/chapter-10/${route.params.id}`,
        query: {
          financial_year: `${financial_year.value}`,
          financial_period: `${financial_period.value}`,
          current_business: `${current_business.value}`,
        },
      });
    },
  },
  {
    model: "chapter_six",
    showInput: false,
    name: "Other Adjustments",
    onClick: () => {
      if (!current_business.value) {
        return;
      }
      router.push({
        path: `/entity/computation-tax/other-adjustments/${route.params.id}`,
        query: {
          financial_year: `${financial_year.value}`,
          financial_period: `${financial_period.value}`,
          current_business: `${current_business.value}`,
        },
      });
    },
    sub: false,
    amount: "0000.00",
  },
]);
const pushToChapter = (chapter) => {
  if (!current_business.value) {
    return;
  }
  router.push({
    path: `/entity/computation-tax/${chapter}/${route.params.id}`,
    query: {
      financial_year: `${financial_year.value}`,
      financial_period: `${financial_period.value}`,
      current_business: `${current_business.value}`,
    },
  });
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}
.table-body.hover:hover {
  background: linear-gradient(90deg, #101432 0%, #00a7a7 100%);
  color: #fafbff !important;
}
.sub-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}

.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}

.total-container.h {
  min-height: 150px;
}

.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}

.clickable-text {
  text-align: right !important;
  border: none;
  border-bottom: 1px solid#000000;
  padding-bottom: 1px;
  padding-left: 4px;
  height: 100%;
}

.clickable-text:focus {
  outline: 0;
  border-bottom: 1px solid#0f75bc;
}

.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}

.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}

.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}

.total-desc {
  background: #deeded;
}
.p-10px {
  padding: 6px !important;
}
</style>

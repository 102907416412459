import { defineStore } from "pinia";
import fetchWrapper from "../helpers/fetchWrapper";
import { generate } from "randomized-string";

export const useChapter8Store = defineStore("chapter8", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      loading: false,
      error: false,
      savingMessage: {
        message: "",
        type: "info"
      },
      selectedAssets: [],
      masterData: {},
      data: {
        account_transfer_within_qualifying_group: true,
        article_26_checklist: {
          the_taxable_person_are_judicial_person: true,
          either_taxable_person_has_a_direct_or_indirect_ownership: true,
          none_of_the_person_are_an_exempt_person: true,
          none_of_the_person_are_a_qualifying_freezone_person: true,
          the_financial_year_of_the_each_of_the_taxable_person_ends_on_same_date: true,
          both_taxable_persons_prepare_their_financial_statements_using_the_same_accounting_standards: true
        },
        article_26_assets: [
          {
            id: generate(),
            name_of_assets: "",
            name_of_transferee_entity: "",
            net_book_value_as_transfer_date: 0,
            transfer_date: ""
          }
        ],
        article_26_liabilities: [
          {
            id: generate(),
            name_of_liabilities: "",
            name_of_transferee_entity: "",
            net_book_value_as_transfer_date: 0,
            transfer_date: ""
          }
        ],
        article_26_incident_occurs: {
          there_is_a_subsequent_transfer_of_the_asset_or_liability: true,
          the_taxable_persons_cease_to_be_member_of_the_same_qualifying_group: true
        },
        article_26_taxable_lineitem: {
          assets: [
            {
              id: generate(),
              date_of_initial_transfer: "",
              name_of_transferee_entity: null,
              net_book_value_on_transfer_date: 0,
              market_value: 0,
              subsequent_transfer_date: null,
              taxable_amount: 0
            }
          ],
          liabilities: [
            {
              id: generate(),
              date_of_initial_transfer: "",
              name_of_transferee_entity: null,
              net_book_value_on_transfer_date: 0,
              market_value: 0,
              subsequent_transfer_date: null,
              taxable_amount: 0
            }
          ],
          totalAssetsAmount: 0,
          totalLiabilitiesAmount: 0,
          totalTaxableAmount: 0
        },
        account_transfer_as_business_restructuring: false,
        article_27_checklist: {
          the_taxable_person_are_judicial_person: false,
          either_taxable_person_has_a_direct_or_indirect_ownership: false,
          none_of_the_person_are_an_exempt_person: false,
          none_of_the_person_are_a_qualifying_freezone_person: false,
          the_financial_year_of_the_each_of_the_taxable_person_ends_on_same_date: false,
          both_taxable_persons_prepare_their_financial_statements_using_the_same_accounting_standards: false
        },
        article_27_assets: [
          {
            id: "71917LADEvDl93Ou",
            name_of_assets: "",
            name_of_transferee_entity: "",
            net_book_value_as_transfer_date: 0,
            transfer_date: ""
          }
        ],
        article_27_liabilities: [
          {
            id: "71917LADEvDl93Ou",
            name_of_liabilities: "",
            name_of_transferee_entity: "",
            net_book_value_as_transfer_date: 0,
            transfer_date: ""
          }
        ],
        article_27_incident_occurs: {
          the_shares_or_other_ownership_interests_in_the_taxable_person: false,
          there_is_a_subsequent_transfer_or_disposal_of_the_business: false
        },
        article_27_taxable_lineitem: {
          assets: [
            {
              id: generate(),
              date_of_initial_transfer: "",
              name_of_transferee_entity: null,
              net_book_value_on_transfer_date: 0,
              market_value: 0,
              subsequent_transfer_date: null,
              taxable_amount: 0
            }
          ],
          liabilities: [
            {
              id: generate(),
              date_of_initial_transfer: "",
              name_of_transferee_entity: null,
              net_book_value_on_transfer_date: 0,
              market_value: 0,
              subsequent_transfer_date: null,
              taxable_amount: 0
            }
          ],
          totalAssetsAmount: 0,
          totalLiabilitiesAmount: 0,
          totalTaxableAmount: 0
        }
      }
    };
  },
  getters: {
    article26AssetsTotalTaxable(state) {
      if (!state.data?.article_26_taxable_lineitem?.assets) {
        return 0;
      }
      const sum = state.data?.article_26_taxable_lineitem?.assets.reduce(
        (a, asset) => a + Number(asset.taxable_amount),
        0
      );
      return sum;
    },
    article26LiabilitiesTotalTaxable(state) {
      if (!state.data?.article_26_taxable_lineitem?.liabilities) {
        return 0;
      }
      const sum = state.data?.article_26_taxable_lineitem?.liabilities?.reduce(
        (a, asset) => a + Number(asset.taxable_amount),
        0
      );
      return sum;
    },
    article26TotalTaxableAmount() {
      return (
        this.article26AssetsTotalTaxable + this.article26LiabilitiesTotalTaxable
      );
    },
    article27AssetsTotalTaxable(state) {
      if (!state.data?.article_27_taxable_lineitem?.assets) {
        return 0;
      }
      const sum = state.data?.article_27_taxable_lineitem?.assets.reduce(
        (a, asset) => a + Number(asset.taxable_amount),
        0
      );
      return sum;
    },
    article27LiabilitiesTotalTaxable(state) {
      if (!state.data?.article_27_taxable_lineitem?.liabilities) {
        return 0;
      }
      const sum = state.data?.article_27_taxable_lineitem?.liabilities?.reduce(
        (a, asset) => a + Number(asset.taxable_amount),
        0
      );
      return sum;
    },
    article27TotalTaxableAmount() {
      return (
        this.article27AssetsTotalTaxable + this.article27LiabilitiesTotalTaxable
      );
    }
  },
  actions: {
    async getChapter8(route) {
      try {
        this.loading = true;
        const entity_id = route.params.id;
        const { financial_year, financial_period, current_business } =
          route.query;
        const res = await fetchWrapper.get(
          `/chapter8?entity_id=${entity_id}&financial_year=${financial_year}&financial_period=${financial_period}${
            current_business !== "master"
              ? "&entity_business_id=" + current_business
              : ""
          }`
        );
        if (res.data?.result === 1) {
          if (route.query.current_business === "master") {
            this.masterData = res.data.data;
            this.error = false;
            this.loading = false;
            return;
          }
          this.data = {
            ...res.data.data,
            article_26_assets: res.data?.data?.article_26_assets?.map((i) => ({
              id: generate(),
              ...i
            })),
            article_26_liabilities: res.data?.data?.article_26_liabilities?.map(
              (i) => ({
                id: generate(),
                ...i
              })
            ),
            article_27_assets: res.data?.data?.article_27_assets?.map((i) => ({
              id: generate(),
              ...i
            })),
            article_27_liabilities: res.data?.data?.article_27_liabilities?.map(
              (i) => ({
                id: generate(),
                ...i
              })
            ),
            article_26_taxable_lineitem: {
              assets: res.data?.data?.article_26_taxable_lineitem?.assets
                ? res.data?.data?.article_26_taxable_lineitem?.assets?.map(
                    (i) => ({
                      id: generate(),
                      ...i
                    })
                  )
                : [
                    {
                      id: generate(),
                      date_of_initial_transfer: "",
                      name_of_transferee_entity: null,
                      net_book_value_on_transfer_date: 0,
                      market_value: 0,
                      subsequent_transfer_date: null,
                      taxable_amount: 0
                    }
                  ],
              liabilities: res.data?.data?.article_26_taxable_lineitem
                ?.liabilities
                ? res.data?.data?.article_26_taxable_lineitem?.liabilities?.map(
                    (i) => ({
                      id: generate(),
                      ...i
                    })
                  )
                : [
                    {
                      id: generate(),
                      date_of_initial_transfer: "",
                      name_of_transferee_entity: null,
                      net_book_value_on_transfer_date: 0,
                      market_value: 0,
                      subsequent_transfer_date: null,
                      taxable_amount: 0
                    }
                  ]
            },
            article_27_taxable_lineitem: {
              assets: res.data?.data?.article_27_taxable_lineitem?.assets
                ? res.data?.data?.article_27_taxable_lineitem?.assets?.map(
                    (i) => ({
                      id: generate(),
                      ...i
                    })
                  )
                : [
                    {
                      id: generate(),
                      date_of_initial_transfer: "",
                      name_of_transferee_entity: null,
                      net_book_value_on_transfer_date: 0,
                      market_value: 0,
                      subsequent_transfer_date: null,
                      taxable_amount: 0
                    }
                  ],
              liabilities: res.data?.data?.article_27_taxable_lineitem
                ?.liabilities
                ? res.data?.data?.article_27_taxable_lineitem?.liabilities?.map(
                    (i) => ({
                      id: generate(),
                      ...i
                    })
                  )
                : [
                    {
                      id: generate(),
                      name_of_transferee_entity: null,
                      net_book_value_on_transfer_date: 0,
                      market_value: 0,
                      subsequent_transfer_date: null,
                      taxable_amount: 0
                    }
                  ]
            }
          };
          this.error = false;
          this.loading = false;
        } else {
          this.error = res?.data.message;
          this.loading = false;
        }
      } catch (err) {
        console.log(err);
        this.error = err?.response?.data?.message || "";
        this.loading = false;
      }
    },
    async saveChapter8(route, callBack = () => {}) {
      try {
        const { financial_year, financial_period, current_business } =
          route.query;
        if (current_business === "master") {
          return;
        }
        this.savingMessage.type = "info";
        this.savingMessage.message = "Saving...";
        let payload = {};
        for (let x in this.data) {
          if (x !== "articles") {
            payload[x] = this.data[x];
          }
        }
        const entity_id = route.params.id;

        payload["entity_id"] = entity_id;
        payload["financial_year"] = financial_year;
        payload["financial_period"] = financial_period;
        payload["entity_business_id"] = current_business;
        payload.article_26_taxable_lineitem["totalAssetsAmount"] =
          this.article26AssetsTotalTaxable;
        payload.article_26_taxable_lineitem["totalLiabilitiesAmount"] =
          this.article26LiabilitiesTotalTaxable;
        payload.article_26_taxable_lineitem["totalTaxableAmount"] =
          this.article26TotalTaxableAmount;
        payload.article_27_taxable_lineitem["totalAssetsAmount"] =
          this.article27AssetsTotalTaxable;
        payload.article_27_taxable_lineitem["totalLiabilitiesAmount"] =
          this.article27LiabilitiesTotalTaxable;
        payload.article_27_taxable_lineitem["totalTaxableAmount"] =
          this.article27TotalTaxableAmount;

        const res = await fetchWrapper.post(`chapter8`, payload);
        if (res.data.result === 1) {
          this.savingMessage.type = "success";
          this.savingMessage.message = "Saved Successfully";
          setTimeout(() => {
            this.savingMessage.type = "success";
            this.savingMessage.message = "";
          }, 3000);
          callBack();
        } else {
          this.savingMessage.type = "warning";
          this.savingMessage.message = res.data.message;
        }
      } catch (err) {
        console.log(err);
        this.savingMessage.type = "error";
        this.savingMessage.message = err?.response?.data?.message || "";
      }
    }
  }
});

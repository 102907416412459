<script setup>
import { storeToRefs } from "pinia";
import { useChapter10Store } from "../../../../store/chapter10";
import { useBusinessStore } from "../../../../store/buisness.store";
import { NumberFormatter } from "../../../../helpers/numberFormatter";
import { onMounted } from "vue";
import { getEntityItem } from "../../../../store/entityActions/get";
import { useRoute } from "vue-router";
import { useEntityStore } from "../../../../store/entity";
const route = useRoute();
const Chapter10Store = useChapter10Store();
const { masterData } = storeToRefs(Chapter10Store);
const businessStore = useBusinessStore();
const { business } = storeToRefs(businessStore);
const entityStore = useEntityStore();
const { entity, businessSubActivity } = storeToRefs(entityStore);
const getBusinessName = (id) => {
  const Business = business.value.data.find((b) => b.id == id);
  if (!Business) {
    return "";
  }
  return Business?.name;
};
const getJurisdictionName = (id) => {
  const business = businessSubActivity.value.data.find((bus) => bus?.id == id);
  if (!business) {
    return "";
  }
  return business?.name;
};
onMounted(async () => {
  if (!entity?.value.data?.entity[0]) {
    await getEntityItem("entity", "/entity", route.params.id);
  }
  const Entity = entity?.value.data?.entity[0];
  const business_activity = Entity?.business_activity;
  const place_of_incorporation = Entity?.place_of_establishment?.id;
  await getEntityItem(
    "businessSubActivity",
    "/businessActivity/" + business_activity,
    place_of_incorporation
  );
});
</script>

<template>
  <div class="w-100">
    <div
      v-for="(item, index) in masterData"
      class="row bottom-border"
      :key="index"
    >
      <div class="col-lg-12 mt-3">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <h4 class="title">{{ getBusinessName(item?.entity_business_id) }}</h4>
        </div>
        <div class="d-flex justify-content-between mt-4">
          <h4 class="title bold">Separate Annexure for article 35</h4>
        </div>
        <!-- table -->
        <div class="w-100 table-responsive mt-2">
          <table class="table table-bordered table-striped mt-2">
            <thead>
              <th class="table-header">Name of Connected Person</th>
              <th class="table-header">Connection with Person</th>
              <th class="table-header">Nature</th>
              <th class="table-header">Type of Transaction</th>
              <th class="table-header">Transaction Value</th>
              <th class="table-header">Market Price</th>
              <th class="table-header">Variation/Disallowance</th>
              <th class="table-header">Basis for Market Value</th>
              <th class="table-header">Adjusted in TI required</th>
            </thead>
            <tbody>
              <tr
                v-for="(data, index) in item.article_35_annexure"
                :key="data.id"
              >
                <td class="align-middle table-rows pl-2">
                  {{ data.name_of_connected_person }}
                </td>
                <td class="align-middle table-rows pl-2">
                  {{ data.connection_with_person }}
                </td>
                <td class="align-middle table-rows pl-2">
                  {{ data.nature }}
                </td>
                <td class="align-middle table-rows pl-2">
                  {{ data.type_of_transaction }}
                </td>
                <td class="align-middle table-rows pl-2">
                  {{ data.transaction_value }}
                </td>
                <td class="align-middle table-rows pl-2">
                  {{ data.market_price }}
                </td>
                <td class="align-middle table-rows pl-2">
                  {{ data.variation }}
                </td>
                <td class="align-middle table-rows pl-2">
                  {{ data.basis_of_market_value }}
                </td>
                <td class="align-middle table-rows pl-2">
                  <div
                    class="d-flex pl-4 w-100 h-100 align-items-center justify-content-center"
                  >
                    <v-switch
                      v-model="data.adjusted_in_ti_required"
                      color="#0F75BC"
                      inset
                      hide-details
                      :disabled="true"
                    ></v-switch>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-100 d-flex my-3 align-items-center justify-content-end">
          <p class="title bold">
            Total Variation:
            {{ NumberFormatter(item.article_35_total_variation) }}
          </p>
        </div>
        <div class="d-flex justify-content-between mt-4">
          <h4 class="title bold">Separate Annexure for article 36</h4>
        </div>
        <!-- table -->
        <div class="w-100 table-responsive mt-2">
          <table class="table table-bordered table-striped mt-2">
            <thead>
              <th class="table-header">Name of Connected Person</th>
              <th class="table-header">Connection with Person</th>
              <th class="table-header">Nature</th>
              <th class="table-header">Type of Transaction</th>
              <th class="table-header">Transaction Value</th>
              <th class="table-header">Market Price</th>
              <th class="table-header">Variation/Disallowance</th>
              <th class="table-header">Basis for Market Value</th>
              <th class="table-header">Adjusted in TI required</th>
            </thead>
            <tbody>
              <tr
                v-for="(data, index) in item.article_36_annexure"
                :key="data.id"
              >
                <td class="align-middle table-rows pl-2">
                  {{ data.name_of_connected_person }}
                </td>
                <td class="align-middle table-rows pl-2">
                  {{ data.connection_with_person }}
                </td>
                <td class="align-middle table-rows pl-2">
                  {{ data.nature }}
                </td>
                <td class="align-middle table-rows pl-2">
                  {{ data.type_of_transaction }}
                </td>
                <td class="align-middle table-rows pl-2">
                  {{ data.transaction_value }}
                </td>
                <td class="align-middle table-rows pl-2">
                  {{ data.market_price }}
                </td>
                <td class="align-middle table-rows pl-2">
                  {{ data.variation }}
                </td>
                <td class="align-middle table-rows pl-2">
                  {{ data.basis_of_market_value }}
                </td>
                <td class="align-middle table-rows pl-2">
                  <div
                    class="d-flex pl-4 w-100 h-100 align-items-center justify-content-center"
                  >
                    <v-switch
                      v-model="data.adjusted_in_ti_required"
                      color="#0F75BC"
                      inset
                      hide-details
                      :disabled="true"
                    ></v-switch>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-100 d-flex my-2 align-items-center justify-content-end">
          <p class="title bold">
            Total Variation:
            {{ NumberFormatter(item.article_36_total_variation) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.btn-add {
  width: 124.33px;
  height: 47px;
  background: #0f75bc;
  border-radius: 5px;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.header {
  padding: 6px 9px;
  min-height: 47px;
  background: #0f75bc;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
.custom-input {
  height: 44px;
  padding: 11px 13px;
  margin-top: 10px;
  border: 1px solid rgba(116, 113, 113, 0.3);
}
.custom-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
  text-align: start;
}
.article {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
.article-desc {
  white-space: pre-wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px; /* or
185% */
  letter-spacing: 0.055em;
  color: #747171;
}
.gray {
  background: #deeded;
}
.article-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px; /*
identical to box height, or 185% */
  letter-spacing: 0.055em;
  color: #000000;
}
.border-bottom {
  border-bottom: 1px solid #ede4e4;
}
.table-header {
  height: 64px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-left: 10px;
  vertical-align: middle;
  border: 3px solid #fff;
}
</style>

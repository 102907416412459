/* eslint-disable no-prototype-builtins */
import fetchWrapper from "@/helpers/fetchWrapper";
import { defineStore } from "pinia";
const initialAmount = "";

const totalKeys = [
  "provisions_legal_claim",
  "trade_and_othrs_pybls_trades_payable_to",
  "othr_rsrvs_shr_optn_rsrv",
  "othr_rsrvs_cptl_rsrv",
  "othr_rsrvs_fair_value_reserve",
  "othr_rsrvs_hedging_reserve",
  "othr_rsrvs_currency_translation_reserve",
  "othr_rsrvs_assets_revaluation_reserve",
];

export const useLiabilityDataStore = defineStore("liability", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      entity_id: "",
      financial_year: 1,
      financial_period: 1,
      loading: true,
      error: false,
      shareHolderLiability: {
        share_capital_begining_of_financial_year: initialAmount,
        share_capital_shares_issued: initialAmount,
        share_capital_shares_issue_expenses: initialAmount,
        trasuary_shares_trasuary_shares_purchased: initialAmount,
        trasuary_shares_trasuary_shares_reissued: initialAmount,
        othr_rsrvs_shr_optn_rsrv: initialAmount,
        othr_rsrvs_shr_optn_rsrv_begining_of_the_financial_year: initialAmount,
        othr_rsrvs_shr_optn_rsrv_employee_share_option_scheme: initialAmount,
        othr_rsrvs_cptl_rsrv: initialAmount,
        othr_rsrvs_cptl_rsrv_begining_of_the_financial_year: initialAmount,
        othr_rsrvs_cptl_rsrv_gain_on_reissue: initialAmount,
        othr_rsrvs_cptl_rsrv_excess_tax_on_emply_shr_optn_scheme: initialAmount,
        othr_rsrvs_fair_value_reserve: initialAmount,
        othr_rsrvs_fair_value_reserve_begining_of_the_fncal_yr: initialAmount,
        othr_rsrvs_fair_value_reserve_fair_value_gain_losses: initialAmount,
        othr_rsrvs_hedging_reserve: initialAmount,
        othr_rsrvs_hedging_reserve_begining_of_the_financial_year:
          initialAmount,
        othr_rsrvs_hedging_reserve_fair_value_gain_losses: initialAmount,
        othr_rsrvs_hedging_reserve_tax_on_rclassfctn_adjustments: initialAmount,
        othr_rsrvs_currency_translation_reserve: initialAmount,
        othr_rsrvs_currency_translation_reserve_bgng_of_the_fncal_yr:
          initialAmount,
        othr_rsrvs_currency_translation_reserve_net_crncy_tnsln_dffrnc:
          initialAmount,
        othr_rsrvs_equity_component_of_convertible_bonds: initialAmount,
        othr_rsrvs_assets_revaluation_reserve: initialAmount,
        othr_rsrvs_assets_revaluation_reserve_begining_of_the_fncal_yr:
          initialAmount,
        othr_rsrvs_assets_revaluation_reserve_revaluation_gains: initialAmount,
        othr_rsrvs_assets_revaluation_reserve_tax_on_revaluation_gains:
          initialAmount,
        retained_profits_begining_of_the_financial_year: initialAmount,
        retained_profits_net_profit_for_the_financial_year: initialAmount,
        retained_profits_dividend_paid_during_financial_year: initialAmount,
      },
      // all these properties will have their type inferred automatically
      currentLiability: {
        trade_and_othrs_pybls_trades_payable_to: initialAmount,
        trade_and_othrs_pybls_trades_payable_to_non_related_parties:
          initialAmount,
        trade_and_othrs_pybls_trades_payable_to_associates: initialAmount,
        trade_and_othrs_pybls_trades_payable_to_other_related_parties:
          initialAmount,
        trade_and_othrs_pybls_refund_liabilities: initialAmount,
        trade_and_othrs_pybls_financial_discount: initialAmount,
        trade_and_othrs_pybls_deferred_grant_income: initialAmount,
        trade_and_othrs_pybls_accural_of_operating_expenses: initialAmount,
        trade_and_othrs_pybls_accural_of_volume_discounts: initialAmount,
        current_income_tax_liabilities_begining_of_the_financial_year:
          initialAmount,
        current_income_tax_liabilities_income_tax_paid: initialAmount,
        current_income_tax_liabilities_tax_expense: initialAmount,
        current_income_tax_liabilities_under_provision_in_prior_fccal_yr:
          initialAmount,
        borrowings_bank_overdrafts: initialAmount,
        borrowings_bank_borrowings: initialAmount,
        borrowings_lease_liabilities: initialAmount,
        provisions_warranty: initialAmount,
        provisions_restructuring: initialAmount,
      },

      nonCurrentLiability: {
        borrowings: initialAmount,
        borrowings_bank_borrowings: initialAmount,
        borrowings_convertible_bonds: initialAmount,
        borrowings_redeemable_preference_share: initialAmount,
        borrowings_lease_liabilities: initialAmount,
        borrowings_security_granted: initialAmount,
        provisions: initialAmount,
        provisions_legal_claim: initialAmount,
        provisions_legal_claim_begining_of_the_financial_year: initialAmount,
        provisions_legal_claim_provision_made: initialAmount,
        provisions_legal_claim_provision_utilised: initialAmount,
        provisions_legal_claim_amortisation_of_discount: initialAmount,
        deferred_tax_liabilities: initialAmount,
        deferred_tax_liabilities_deferred_tax_assets: initialAmount,
        deferred_tax_liabilities_deferred_tax_liabilities: initialAmount,
      },
      totalShareHolderEquity: {
        share_capital: "",
        trasuary_shares: "",
        other_reserves: "",
        retained_profits: "",
        total_shareholders_equvity: "",
      },
      totalNonCurrentLiability: {
        borrowings: "",
        provisions: "",
        deferred_tax_liabilities: "",
      },
      totalCurrentLiability: {
        trade_and_others_payables: "",
        current_income_tax_liabilities: "",
        borrowings: "",
        provisions: "",
      },
    };
  },
  actions: {
    async getLiabilities(
      entity_id,
      financial_year,
      financial_period,
      current_business
    ) {
      try {
        this.loading = true;
        if (financial_year && financial_period) {
          const res = await fetchWrapper.get(
            `/nonCurrentLiability?entity_id=${entity_id}&financial_period=${financial_period}&financial_year=${financial_year}${
              current_business === "master"
                ? ""
                : "&entity_business_id=" + current_business
            }`
          );
          if (res.data.result == 1) {
            let responseData = res.data?.data;
            //this.nonCurrentLiability = responseData;
            for (let x in responseData) {
              let liability = this.nonCurrentLiability.hasOwnProperty(x);
              if (liability) {
                if (!totalKeys.includes(x)) {
                  this.nonCurrentLiability[x] = responseData[x]
                    ? responseData[x]
                    : "";
                }
              }
            }
          } else {
            for (let x in this.nonCurrentLiability) {
              if (!totalKeys.includes(x)) {
                this.nonCurrentLiability[x] = "";
              }
            }
          }
          await this.getCurrentLiability(
            entity_id,
            financial_period,
            financial_year,
            current_business
          );
          this.loading = await false;
          this.error = await false;
        } else {
          for (let x in this.nonCurrentLiability) {
            this.nonCurrentLiability[x] = "";
          }
        }
      } catch (err) {
        console.log(err);
        this.error = err?.response?.data?.message || "";
      }
    },
    async getCurrentLiability(
      entity_id,
      financial_period,
      financial_year,
      current_business
    ) {
      try {
        const res = await fetchWrapper.get(
          `/currentLiability?entity_id=${entity_id}&financial_period=${financial_period}&financial_year=${financial_year}${
            current_business === "master"
              ? ""
              : "&entity_business_id=" + current_business
          }
         `
        );
        if (res.data.result == 1) {
          let responseData = res.data?.data;
          //this.currentLiability = responseData;
          for (let x in responseData) {
            let currentLiability = this.currentLiability.hasOwnProperty(x);
            if (currentLiability) {
              if (!totalKeys.includes(x)) {
                this.currentLiability[x] = responseData[x]
                  ? responseData[x]
                  : "";
              }
            }
          }
        } else {
          for (let x in this.currentLiability) {
            if (!totalKeys.includes(x)) {
              this.currentLiability[x] = "";
            }
          }
        }
        await this.getShareHolderLiability(
          entity_id,
          financial_period,
          financial_year,
          current_business
        );
      } catch (err) {
        console.log(err);
      }
    },
    async getShareHolderLiability(
      entity_id,
      financial_period,
      financial_year,
      current_business
    ) {
      try {
        const res = await fetchWrapper.get(
          `/shareholdersLiability?entity_id=${entity_id}&financial_period=${financial_period}&financial_year=${financial_year}${
            current_business === "master"
              ? ""
              : "&entity_business_id=" + current_business
          }`
        );
        if (res.data.result == 1) {
          let responseData = res.data?.data;
          //this.shareHolderLiability = responseData;
          for (let x in responseData) {
            let shareHolderLiability =
              this.shareHolderLiability.hasOwnProperty(x);
            if (shareHolderLiability) {
              if (!totalKeys.includes(x)) {
                this.shareHolderLiability[x] = responseData[x]
                  ? responseData[x]
                  : "";
              }
            }
          }
        } else {
          for (let x in this.shareHolderLiability) {
            if (!totalKeys.includes(x)) {
              this.shareHolderLiability[x] = "";
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async save(route, toast, callBack = () => {}) {
      toast.makeInfoToast("Saving...");
      try {
        const entity_id = route.params.id;
        const { financial_year, financial_period, business } = route.query;
        let payload = {
          entity_id: entity_id,
          financial_year: financial_year,
          financial_period: financial_period,
          entity_business_id: business ? business : 0,
          ...this.currentLiability,
          ...this.totalCurrentLiability,
          total_current_liabilities: this.total_current_liability,
        };

        let res = await fetchWrapper.post("/currentLiability", payload);

        if (res.data.result == 1) {
          let response = await this.saveNonCurrent(
            entity_id,
            financial_period,
            financial_year,
            res.data.entity_business_id
          );

          //console.log("response2", response.data.result);
          if (response.data.result == 1) {
            await this.saveShareHolder(
              entity_id,
              financial_period,
              financial_year,
              res.data.entity_business_id
            );
            toast.makeSuccessToast("success", "saved successfully...");
            callBack(res.data.entity_business_id);
          } else {
            toast.makeWarningToast("error", response.data.message);
          }
        } else {
          toast.makeErrorToast("error", res.data.message);
        }
        return res;
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    },
    async saveNonCurrent(
      entity_id,
      financial_period,
      financial_year,
      entity_business_id
    ) {
      try {
        let payload = {
          entity_id,
          financial_year,
          financial_period,
          entity_business_id,
          ...this.nonCurrentLiability,
          ...this.totalNonCurrentLiability,
          total_non_current_liabilities: Number(
            this.total_non_current_liability
          ),
        };
        const res = await fetchWrapper.post("/nonCurrentLiability", payload);
        return res;
      } catch (err) {
        console.log(err);
      }
    },
    async saveShareHolder(
      entity_id,
      financial_period,
      financial_year,
      entity_business_id
    ) {
      try {
        let payload = {
          entity_id,
          financial_year,
          financial_period,
          entity_business_id,
          ...this.shareHolderLiability,
          ...this.totalShareHolderEquity,
          total_shareholders_equvity: this.total_shareholder_liability,
        };
        const res = await fetchWrapper.post("/shareholdersLiability", payload);
        return res;
      } catch (err) {
        console.log(err);
      }
    },
  },

  getters: {
    total_non_current_liability(state) {
      var sum = 0;
      var obj = state.totalNonCurrentLiability;
      for (var el in obj) {
        sum += Number(obj[el]);
      }
      return sum;
    },
    total_current_liability(state) {
      let sum = Object.keys(state.totalCurrentLiability).reduce(
        (a, b) => a + Number(state.totalCurrentLiability[b] || 0),
        0
      );
      return sum;
    },
    total_shareholder_liability(state) {
      var obj = state.totalShareHolderEquity;
      const keys = [
        "share_capital",
        "trasuary_shares",
        "other_reserves",
        "retained_profits",
      ];
      let sum = keys.reduce((acc, key) => {
        return acc + Number(obj[key]);
      }, 0);
      return sum;
    },
    total_liability() {
      let sum =
        Number(this.total_non_current_liability || 0) +
        Number(this.total_current_liability || 0);
      return sum;
    },
    totalShEquityAndLiabilities() {
      let sum =
        Number(this.total_liability) + Number(this.total_shareholder_liability);
      return sum;
    },
  },
});

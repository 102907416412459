import fetchWrapper from "@/helpers/fetchWrapper";
import {
  basicDataStructures,
  basicDataStructure
} from "../helpers/storeStructure";

import { defineStore } from "pinia";

export const useSubscriptionStore = defineStore("subscription", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      subscriptions: {
        vat: 0,
        is_vat_applicable: "",
        ...basicDataStructures
      },
      subscription: {
        ...basicDataStructure
      },
      mySubscription: {
        ...basicDataStructure
      }
    };
  },
  actions: {
    async getSubscriptions() {
      try {
        this.subscriptions.loading = false;
        const res = await fetchWrapper.get("/subscription");
        if (res.data.result == 1) {
          this.subscriptions.data = res.data?.data;
          this.subscriptions.vat = res.data?.vat;
          this.subscriptions.is_vat_applicable = res.data?.is_vat_applicable;
          this.subscriptions.error = false;
          this.subscriptions.loading = false;
        } else {
          this.subscriptions.error = "Failed to fetch subscriptions";
          this.subscriptions.loading = false;
        }
      } catch (err) {
        console.log(err);
        this.subscriptions.error = err?.response?.data?.message || "";
        this.subscriptions.loading = false;
      }
    },
    async getSubscription(id) {
      this.subscription.loading = true;
      const subscription = this.subscriptions?.data.find(
        (sub) => String(sub?.id) === String(id)
      );
      this.subscription.data = subscription;
      this.subscription.error = false;
      this.subscription.loading = false;
    },
    async paySubscription(data, setMessage, callBack = () => {}) {
      try {
        setMessage("Saving subscription please wait...");
        const res = await fetchWrapper.post("/purchaseSubscription", data);
        if (res.data.result === 1) {
          callBack();
        } else {
          setMessage(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message
          );
        }
      } catch (err) {
        console.log(err);
        setMessage(
          "Unable to create account" +
            err?.message +
            ", " +
            err?.response?.data?.message || ""
        );
      }
    },
    async getMyPlan(callBack = () => {}) {
      try {
        this.mySubscription.loading = false;
        const res = await fetchWrapper.get("/myPlan");
        if (res.data.result == 1) {
          this.mySubscription.data = res.data?.data;
          this.mySubscription.error = false;
          this.mySubscription.loading = false;
          callBack(res?.data);
        } else {
          this.mySubscription.error =
            "Failed to fetch subscriptions " + res.data?.error
              ? res.data?.error?.join(",")
              : res.data?.message;
          this.mySubscription.loading = false;
        }
      } catch (err) {
        console.log(err);
        this.mySubscription.error = err?.response?.data?.message || "";
        this.mySubscription.loading = false;
      }
    },
    unsubscribeStore() {
      this.subscription = basicDataStructure;
      this.subscriptions = basicDataStructures;
      this.mySubscription = basicDataStructure;
    }
  }
});

<template>
  <SideNavContainer side-nav-type="financialData" :subtitle="name">
    <EntityChecker :check-mandatory-fields="true">
      <div
        class="title-container d-flex align-items-center justify-content-between"
      >
        <h3 class="title">
          Financial Data
          {{
            ifrs === "IFRS"
              ? `(Prepared as Per IFRS)`
              : ifrs === "Non-IFRS"
              ? "(not prepared as Per IFRS)"
              : ""
          }}
          <br />
          <p class="subtitle mb-0">
            {{ name }}
          </p>
        </h3>
        <FinancialDataDateFilter />
      </div>
      <FinancialDataBreadCrumb :entity-name="name" />

      <Business />
      <IFRSCheckerContainer :have-loader="false" :check-status="true">
        <LoaderContainer
          :loading="financialTypeLoading || statusLoading"
          :error="
            business.error ? business.error : statusError ? statusError : ''
          "
        >
          <div
            class="d-flex item-container border-bottom align-items-center justify-content-between"
          >
            <p class="mb-0 balance-sheet">Balance Sheet</p>
            <div
              class="d-flex flex-wrap align-items-center justify-content-end"
            >
              <button
                class="btn-action view-balance-sheet d-flex align-items-center mt-1 mr-2 justify-content-center"
                @click="goToBalanceSheetView()"
              >
                View
              </button>
              <button
                :class="`btn-action download-balance-sheet d-flex align-items-center mt-1 mr-2 justify-content-center ${
                  !$route.query.business ? 'disabled' : ''
                }`"
                :disabled="!$route.query.business"
                @click="downloadBalanceSheet"
              >
                <img src="@/assets/svg/download.svg" class="mr-1" alt="" />
                Download
              </button>
              <button
                :disabled="
                  $route.query.business === 'master' ||
                  status ||
                  expirationDuration < 0
                "
                :class="`btn-action ${
                  $route.query.business === 'master'
                    ? 'disabled'
                    : status
                    ? 'disabled'
                    : expirationDuration > 0
                    ? ''
                    : 'disabled'
                } d-flex align-items-center mt-1 mr-2 justify-content-center`"
                @click="downloadSample('balanceSheet')"
              >
                <img src="@/assets/svg/download.svg" class="mr-1" alt="" />
                Download Sample
              </button>
              <button
                :disabled="
                  $route.query.business === 'master' ||
                  status ||
                  expirationDuration < 0
                "
                :class="`btn-action ${
                  $route.query.business === 'master'
                    ? 'disabled'
                    : status
                    ? 'disabled'
                    : expirationDuration > 0
                    ? ''
                    : 'disabled'
                } d-flex align-items-center mt-1 mr-2 justify-content-center`"
                @click="openUpload('balanceSheet')"
              >
                <img src="@/assets/svg/upload.svg" class="mr-1" alt="" />
                Upload
              </button>
              <button
                :disabled="
                  expirationDuration < 0 ||
                  status ||
                  !route.query.financial_year
                "
                :class="`btn-action d-flex align-items-center mt-1 mr-2 justify-content-center ${
                  status ? 'disabled' : expirationDuration > 0 ? '' : 'disabled'
                } `"
                @click="goToBalanceSheet()"
              >
                Click Here
              </button>
            </div>
          </div>
          <div
            class="d-flex item-container align-items-center justify-content-between"
          >
            <p class="mb-0 balance-sheet">Profit & Loss</p>
            <div
              class="d-flex flex-wrap align-items-center justify-content-end"
            >
              <button
                class="btn-action d-flex align-items-center mt-1 mr-2 justify-content-center"
                @click="gotoProfitAndLossView()"
              >
                View
              </button>
              <button
                :class="`btn-action d-flex align-items-center mt-1 mr-2 justify-content-center ${
                  !$route.query.business ? 'disabled' : ''
                }`"
                :disabled="!$route.query.business"
                @click="downloadProfitLoss"
              >
                <img src="@/assets/svg/download.svg" class="mr-1" alt="" />
                Download
              </button>
              <button
                :disabled="
                  $route.query.business === 'master' ||
                  status ||
                  expirationDuration < 0
                "
                :class="`btn-action ${
                  $route.query.business === 'master'
                    ? 'disabled'
                    : status
                    ? 'disabled'
                    : expirationDuration > 0
                    ? ''
                    : 'disabled'
                } d-flex align-items-center mt-1 mr-2 justify-content-center`"
                @click="downloadSample('profitAndLoss')"
              >
                <img src="@/assets/svg/download.svg" class="mr-1" alt="" />
                Download Sample
              </button>
              <button
                :disabled="
                  $route.query.business === 'master' ||
                  status ||
                  expirationDuration < 0
                "
                :class="`btn-action ${
                  $route.query.business === 'master'
                    ? 'disabled'
                    : status
                    ? 'disabled'
                    : expirationDuration > 0
                    ? ''
                    : 'disabled'
                } d-flex align-items-center mt-1 mr-2 justify-content-center`"
                @click="openUpload('profitAndLoss')"
              >
                <img src="@/assets/svg/upload.svg" class="mr-1" alt="" />
                Upload
              </button>
              <button
                :disabled="expirationDuration < 0 || status"
                :class="`btn-action d-flex align-items-center mt-1 mr-2 justify-content-center ${
                  status ? 'disabled' : expirationDuration > 0 ? '' : 'disabled'
                }`"
                @click="gotoProfit()"
              >
                Click Here
              </button>
            </div>
          </div>
          <UploadContainer
            v-if="!!type"
            :open="open"
            :type="type"
            :set-open="setOpen"
          />
        </LoaderContainer>
      </IFRSCheckerContainer>
    </EntityChecker>
  </SideNavContainer>
</template>
<script setup>
import SideNavContainer from "../../layout/SideNavContainer.vue";
import FinancialDataDateFilter from "./DateHandler/ContainerDateHandler.vue";
import { getEntityItem } from "../../../store/entityActions/get";
import EntityChecker from "../AddEntity/EntityChecker.vue";
import { useEntityStore } from "../../../store/entity";
import Business from "./BusinessHandler/ContainerBusiness.vue";
import { useRoute, useRouter } from "vue-router";
import { useProfitAndLossStore } from "../../../store/ProfitAdLoss";
import { useBusinessStore } from "../../../store/buisness.store";
import { storeToRefs } from "pinia";
import LoaderContainer from "../../common/LoaderContainer.vue";
import UploadContainer from "./upload/UploadContainer.vue";
import { computed, onMounted, ref } from "vue";
import { useFinancialDataStore } from "../../../store/financial.store";
import { useStatusStore } from "../../../store/statusStore";
import { FinancialDataBreadCrumb } from "../../BreadCrumbs";
import { getDurationInDays } from "../../../helpers/date";
import IFRSCheckerContainer from "./IFRSCheckerContainer.vue";
import { useIFRSStore } from "../../../store/NonIFRS/IFRS.store";
import { downloadURI } from "../../../helpers/download";

const financialDataStore = useFinancialDataStore();
const profitAndLossStore = useProfitAndLossStore();
const businessStore = useBusinessStore();
const { business } = storeToRefs(businessStore);
const entityStore = useEntityStore();
const statusStore = useStatusStore();
const {
  status,
  loading: statusLoading,
  error: statusError,
} = storeToRefs(statusStore);
const ifrsStore = useIFRSStore();
const { data: ifrs, loading: financialTypeLoading } = storeToRefs(ifrsStore);
const open = ref(false);
const setOpen = (value) => {
  open.value = value;
};
const type = ref("");
const route = useRoute();
const { entity } = storeToRefs(entityStore);
const router = useRouter();
const downloadBalanceSheet = async () => {
  let financial_year = route.query.financial_year;
  let financial_period = route.query.financial_period;
  let business_id = route.query.business ? route.query.business : "";
  await financialDataStore.saveBalanceSheetFile(
    route.params.id,
    financial_year,
    financial_period,
    business_id
  );
};
const downloadProfitLoss = async () => {
  let financial_year = route.query.financial_year;
  let financial_period = route.query.financial_period;
  let business_id = route.query.business ? route.query.business : "";
  await profitAndLossStore.saveProfitAndLossFile(
    route.params.id,
    financial_year,
    financial_period,
    business_id
  );
};
const goToBalanceSheet = () => {
  if (status.value || expirationDuration.value < 0) {
    return;
  }
  router.push(
    `/entity/financial-data/balance-sheet/assets/${
      route.params.id
    }?financial_period=${route.query.financial_period}&financial_year=${
      route.query.financial_year
    }${route.query.business ? "&business=" + route.query.business : ""}`
  );
};
const gotoProfit = () => {
  if (status.value || expirationDuration.value < 0) {
    return;
  }
  router.push(
    `/entity/financial-data/profit-loss/income/${
      route.params.id
    }?financial_period=${route.query.financial_period}&financial_year=${
      route.query.financial_year
    }${route.query.business ? "&business=" + route.query.business : ""}`
  );
};

const openUpload = (Type) => {
  if (status.value || expirationDuration.value < 0) {
    return;
  }
  type.value = Type;
  open.value = true;
};
const downloadSample = (type) => {
  if (ifrs.value === "Non-IFRS") {
    if (type === "balanceSheet") {
      downloadURI(
        "https://admin.snaptax.me/public/sample/non-ifrs-balancesheet.xlsx",
        "non-ifrs-balancesheet"
      );
    } else if (type === "profitAndLoss") {
      downloadURI(
        "https://admin.snaptax.me/public/sample/non-ifrs-profitloss.xlsx",
        "non-ifrs-profitloss"
      );
    }
    return;
  }
  if (type === "balanceSheet") {
    downloadURI(
      "https://admin.snaptax.me/public/sample/balancesheet.xlsx",
      "sample-balancesheet"
    );
  } else if (type === "profitAndLoss") {
    downloadURI(
      "https://admin.snaptax.me/public/sample/profitloss.xlsx",
      "sample-profit-and-loss"
    );
  }
};
const name = computed(() => {
  if (!entity.value.data) return "";
  const Entity = entity.value.data?.entity[0];
  if (!Entity) return "";
  if (Entity?.entity_type == 2) {
    return `${Entity?.entity_first_name} ${Entity?.entity_last_name}`;
  }
  return Entity?.entity_name;
});
const expirationDuration = computed(() => {
  if (!entity.value.data) return 0;
  const Entity = entity.value.data?.entity[0];
  const duration = getDurationInDays(Entity?.subscription_end_date || 0);
  return duration;
});

onMounted(async () => {
  ifrs.value = "";
  if (!entity.value.data) {
    const id = route.params.id;
    await getEntityItem("entity", "/entity", id);
  }

  statusLoading.value = true;
});
const goToBalanceSheetView = () => {
  router.push(
    `/entity/financial-data/balance-sheet/view/${
      route.params.id
    }?financial_period=${route.query.financial_period}&financial_year=${
      route.query.financial_year
    }${route.query.business ? "&business=" + route.query.business : ""}`
  );
};
const gotoProfitAndLossView = () => {
  router.push(
    `/entity/financial-data/profit-loss/view/${
      route.params.id
    }?financial_period=${route.query.financial_period}&financial_year=${
      route.query.financial_year
    }${route.query.business ? "&business=" + route.query.business : ""}`
  );
};
</script>

<style scoped>
.balance-sheet {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.055em;
  color: #000000;
}

.btn-action {
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
  padding: 12px 12px;
}

.item-container {
  padding-top: 21px;
  padding-bottom: 41px;
}
.title-container {
  padding: 25px 10px 16px 10px;
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.055em;
  color: #000000;
}
.border-bottom {
  border-bottom: 1px solid rgba(116, 113, 113, 0.3);
}
.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.btn-action.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>

import fetchWrapper from "../helpers/fetchWrapper";
import { defineStore } from "pinia";
import { basicDataStructures } from "../helpers/storeStructure";
import { Toast } from "../helpers/toast";

export const useUsersStore = defineStore("usersStore", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      users: {
        ...basicDataStructures
      }
    };
  },
  actions: {
    async getUsersList() {
      try {
        this.users.loading = true;
        const res = await fetchWrapper.get(`/subUser`);
        if (res.data?.result == 1) {
          this.users.error = false;
          this.users.data = res.data?.data;
          this.users.loading = false;
        } else {
          this.users.error = res.data.message;
          this.users.loading = false;
        }
      } catch (err) {
        console.log(err);
        this.users.error = err?.response?.data?.message || "";
        this.users.loading = false;
      }
    },
    async addSubUSer(payload, callBack = () => {}) {
      const toast = new Toast("Saving....");
      try {
        const res = await fetchWrapper.post(`/subUser`, payload);
        if (res.data.result == 1) {
          toast.makeSuccessToast(res.data?.message);
          callBack(res.data);
        } else {
          toast.makeErrorToast(res.data?.error);
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    },
    async assignEntity(
      { user_id, entities = [] },
      setMessage = () => {},
      callBack = () => {}
    ) {
      const toast = new Toast("Saving....");
      try {
        const res = await fetchWrapper.post(`/assignEntity`, {
          user_id,
          entities
        });
        if (res.data.result == 1) {
          toast.makeSuccessToast("Saved successfully", "success");
          callBack(res.data);
        } else {
          toast.makeErrorToast(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message
          );
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    },
    async editSubUSer(payload, callBack = () => {}) {
      const toast = new Toast("Updating....");
      try {
        const res = await fetchWrapper.put(`/subUser`, payload);
        if (res.data.result == 1) {
          toast.makeSuccessToast("Updated successfully");
          callBack(res.data);
        } else {
          toast.makeErrorToast(res.data?.error, "danger");
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    },
    async changeSubUserPassword(payload, callBack = () => {}) {
      const toast = new Toast("Changing....");
      try {
        const res = await fetchWrapper.post(`/updateSubuserPassword`, payload);
        if (res.data.result == 1) {
          toast.makeSuccessToast("Changed successfully");
          callBack(res.data);
        } else {
          toast.makeErrorToast(res.data?.error);
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    }, // 0910479181
    async deleteUser(userId, callBack = () => {}) {
      const toast = new Toast("Deleting user....");
      try {
        const res = await fetchWrapper.delete(`/subUser/${userId}`);
        if (res.data.result == 1) {
          toast.makeSuccessToast("Deleted Successfully");
          callBack(res.data);
        } else {
          toast.makeErrorToast(res.data?.message);
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    }
  }
});

<template>
  <div class="w-100">
    <div class="header d-flex align-items-center w-100 justify-content-between">
      <p class="header-title mb-0">Other Investments at amortised cost</p>
      <p class="header-title mb-0">
        {{
          NumberFormatter(
            totalNonCurrentAsset.other_investment_at_amortised_cost
          )
        }}
      </p>
    </div>
    <form
      v-for="(item, index) in data"
      @submit="handleSubmit"
      :key="item.id"
      :class="`w-100 table-body ${!index || !(index % 2) ? 'white' : 'gray'} ${
        item?.sub ? 'sub' : ''
      } d-flex align-items-center justify-content-between`"
    >
      <p class="mb-0 body-title">
        {{ item.name }}
      </p>
      <p
        v-if="!item.showInput"
        class="body-title mb-0"
        @click="openCloseInput(item.model, true)"
      >
        {{ NumberFormatter(NonCurrentAssetData[item.model]) }}
      </p>

      <input
        :id="'input-' + item.model"
        v-model="NonCurrentAssetData[item.model]"
        type="number"
        class="clickable-text"
        :hidden="!item.showInput"
        @focus="setOriginalValue(item.model, NonCurrentAssetData[item.model])"
        @blur="handleBlur(item.model, false)"
        @keydown.tab.enter="(e) => handleTab(e, item.model)"
      />
    </form>
  </div>
</template>

<script setup>
import { generate } from "randomized-string";
import { ref, watch } from "vue";

import { storeToRefs } from "pinia";

import { useFinancialDataStore } from "../../../../../store/financial.store";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";
import { useRoute, useRouter } from "vue-router";
import { autoSaveNonCurrentAssets } from "./helper";

const props = defineProps({
  view: {
    type: Boolean,
    default: false,
  },
  setSavingStatus: {
    type: Function,
    default: () => {},
  },
});
const route = useRoute();
const router = useRouter();
const financialDataStore = useFinancialDataStore();
const { NonCurrentAssetData, totalNonCurrentAsset } =
  storeToRefs(financialDataStore); //get initialvalue from store

const data = ref([
  {
    id: generate(),
    name: "1. Beginning of Financial Year",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "other_investment_at_amortised_cost_begining_of_financial_year",
  },
  {
    id: generate(),
    name: "2. Additions (+)",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "other_investment_at_amortised_cost_additions",
  },
  {
    id: generate(),
    name: "3. Fair value Gains (+)",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "other_investment_at_amortised_cost_fair_value_gains",
  },
  {
    id: generate(),
    name: "4. Disposals (-)",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "other_investment_at_amortised_cost_disposals",
  },
]);
const openCloseInput = async (model, value) => {
  if (props.view || route.query.business === "master") {
    return;
  }
  data.value = data.value.map((item) => {
    if (item.model === model) {
      return { ...item, showInput: value };
    }
    return item;
  });
  if (value) {
    const input = await document.getElementById(`input-${model}`);
    input.focus();
  }
};

totalNonCurrentAsset.value.other_investment_at_amortised_cost =
  data.value.reduce((a, b) => {
    if (b.model === "other_investment_at_amortised_cost_disposals") {
      return a - Number(NonCurrentAssetData.value[b.model]);
    }
    return a + Number(NonCurrentAssetData.value[b.model]);
  }, 0);

watch(
  NonCurrentAssetData,
  (newValue) => {
    totalNonCurrentAsset.value.other_investment_at_amortised_cost =
      data.value?.reduce((a, b) => {
        if (b.model === "other_investment_at_amortised_cost_disposals") {
          return a - Number(newValue[b.model]);
        }
        return a + Number(newValue[b.model]);
      }, 0);
  },
  { deep: true }
);

const handleSubmit = async (e) => {
  e.preventDefault();
  let current_model = e.target.id;
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
const handleTab = async (e, current_model) => {
  e.preventDefault();
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
const autoSave = async (model) => {
  const previousValue = data.value.find((item) => item.model === model).value;
  const currentValue = NonCurrentAssetData.value[model];
  await autoSaveNonCurrentAssets(
    previousValue,
    currentValue,
    props.setSavingStatus,
    route,
    router
  );
};
const handleBlur = async (model, openClose) => {
  await openCloseInput(model, openClose);
  await autoSave(model);
};
const setOriginalValue = (key, value) => {
  const index = data.value.findIndex((item) => item.model === key);
  data.value[index].value = value;
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}
</style>

<script setup>
import { ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useBusinessStore } from "../../../../store/buisness.store";
import { storeToRefs } from "pinia";
import { useChapter11Store } from "../../../../store/chapter-11";
import { NumberFormatter } from "../../../../helpers/numberFormatter";

const name = ref("");
const route = useRoute();
const businessStore = useBusinessStore();
const { business } = storeToRefs(businessStore);
const chapter11Store = useChapter11Store();
const { masterData } = storeToRefs(chapter11Store);
const setBusinessName = (newValue, current_business) => {
  const findBusiness = newValue?.data?.find((b) => b.id == current_business);
  name.value = findBusiness?.name ? findBusiness?.name : "";
};
watch(
  business,
  (newValue) => {
    if (newValue?.data.length)
      setBusinessName(newValue, route.query.current_business);
  },
  { deep: true }
);
watch(
  () => route.query,
  (newValue) => {
    setBusinessName(business.value, newValue.current_business);
  }
);
const getBusinessName = (id) => {
  const Business = business.value.data.find((b) => b.id == id);
  if (!Business) {
    return "";
  }
  return Business?.name;
};
</script>

<template>
  <div class="w-100">
    <div
      v-for="(item, index) in masterData"
      :key="item.unique_id"
      class="w-100 mt-4"
    >
      <div class="0 d-flex align-items-center justify-content-between">
        <h4 class="title">{{ getBusinessName(item.entity_business_id) }}</h4>
      </div>
      <div class="w-100 table-responsive mt-2">
        <table class="table table-bordered table-striped mt-2">
          <thead>
            <th class="table-header w-100px">Financial Year</th>
            <th class="table-header">Brought Forward Loss</th>
            <th class="table-header">
              Details of Loss (Current year Loss-Transfer from Related Party)
            </th>
            <th class="table-header">Details of Loss (Current year Loss)</th>
            <th class="table-header">Sett off of Loss</th>
            <th class="table-header">C/f Loss</th>
            <th class="table-header">Transfer of Loss to Related Company</th>
            <th class="table-header">Net Carried Forward of Loss</th>
            <th class="table-header">
              Whether conditions specified as per Article 39 agreed with
            </th>
          </thead>
          <tbody>
            <tr v-for="(listItem, Index) in item.list" :key="Index">
              <td class="table-rows">{{ listItem?.financial_year_name }}</td>
              <td class="table-rows">
                {{ NumberFormatter(listItem?.brought_forward_loss) }}
              </td>
              <td class="table-rows">
                {{ NumberFormatter(listItem?.details_of_loss_related_party) }}
              </td>
              <td class="table-rows">
                {{ NumberFormatter(listItem?.details_of_loss) }}
              </td>
              <td class="table-rows">
                {{ NumberFormatter(listItem?.sett_off_of_loss) }}
              </td>
              <td class="table-rows">
                {{ NumberFormatter(listItem?.carrie_forward_loss) }}
              </td>
              <td class="table-rows">
                <input
                  v-model="masterData[index].list[Index].transfer_of_loss"
                  type="text"
                  disabled
                  class="table-input"
                  placeholder="Other Settoff of Loss"
                />
              </td>
              <td class="table-rows">
                {{ NumberFormatter(listItem?.net_carried_forward_loss) }}
              </td>
              <td class="table-rows">
                <div
                  class="d-flex w-100 h-100 align-items-center justify-content-center"
                >
                  <v-switch
                    v-model="masterData[index].list[Index].article_39_agreed"
                    color="#0F75BC"
                    inset
                    disabled
                    hide-details
                  ></v-switch>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
.table-header {
  min-height: 83px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-left: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  vertical-align: middle;
  border: 3px solid #fff;
}
.table-rows {
  background: #ffffff;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
  border: 3px solid #fff;
  vertical-align: middle;
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.text-note {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
.table-input {
  padding: 10px;
  width: 100% !important;
}
.table-input:focus {
  outline: 0;
}
.table-input::placeholder {
  text-align: left;
}
.w-100px {
  min-width: 100px;
}
</style>

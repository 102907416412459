import fetchWrapper from "@/helpers/fetchWrapper";
import { Toast } from "../../helpers/toast";

export const deleteEntity = async (
  id,
  endPoint,
  setMessage,
  callBack = () => {}
) => {
  let toast = new Toast("Updating....");
  try {
    setMessage("Deleting please wait...", "info");
    const res = await fetchWrapper.delete(endPoint + "/" + id);
    if (res.data.result === 1) {
      toast.makeSuccessToast(res.data.message);
      callBack(res?.data);
    } else {
      toast.makeErrorToast(
        res.data?.error ? res.data?.error?.join(",") : res.data?.message
      );
    }
  } catch (err) {
    console.log(err);
    toast.makeErrorToast(err?.response?.data?.message || "");
  }
};

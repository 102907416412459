<template>
  <LoaderContainer :loading="entity.loading">
    <ShowStatus :modal="editStatus" :set-modal="setStatus" />
    <slot />
  </LoaderContainer>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useEntityStore } from "../../../store/entity";
import { LoaderContainer } from "../../common";
import { onMounted, ref, watch } from "vue";
import ShowStatus from "../MasterData/List/ShowStatus.vue";
import { useRouter, useRoute } from "vue-router";

const entityStore = useEntityStore();
const { entity } = storeToRefs(entityStore);
const editStatus = ref(false);
const setStatus = (status) => {
  editStatus.value = status;
};
const router = useRouter();
const route = useRoute();
onMounted(async () => {
  if (route.params?.id) {
    if (entity.value?.data?.entity[0]?.file_return_status === "Filed") {
      setStatus(true);
      setTimeout(() => {
        router.push(`/entity`);
      }, 3000);
      return;
    }
  }
});
watch(
  () => entity.value.data,
  (newVal) => {
    if (route.params.id) {
      if (newVal.data?.entity[0]?.file_return_status === "Filed") {
        setStatus(true);
        setTimeout(() => {
          router.push(`/entity`);
        }, 3000);
        return;
      }
    }
  },
  { deep: true }
);
</script>

<style lang="scss" scoped></style>


export const checkRequired = (obj) => {
  const errorFields = []
  for (const item in obj) {
    if (!obj[item]) {
      errorFields.push(item)
    }
  }
  return errorFields
}

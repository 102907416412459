<template>
  <div class="d-flex align-items-center">
    <DeleteBusiness
      :id="modal.business_id"
      :open="modal.modalWarning"
      :set-open="(value) => setModal('modalWarning', value)"
      :call-back-after-delete="callBackAfterDelete"
    />
    <CustomModalContainer
      :open="modal.toggle"
      title="Choose business to remove"
      :set-open="(value) => setModal('toggle', value)"
      width="500"
    >
      <div
        v-for="(busines, index) in business.data"
        :key="busines.id"
        :class="`business-box px-3 d-flex justify-content-between align-items-center ${
          !index || !(index % 2) ? 'white' : 'gray'
        } `"
      >
        <p class="mb-0">{{ busines.name }}</p>
        <img
          src="@/assets/svg/trash.svg"
          class="trash-icon cursor-pointer"
          alt=""
          @click="
            () => {
              setModal('business_id', busines.id);
              setModal('modalWarning', true);
            }
          "
        />
      </div>
    </CustomModalContainer>
    <button
      v-if="business.data.length"
      class="delete-btn mb-1 d-flex align-items-center mt-1 mr-3 justify-content-center"
      @click="() => setModal('toggle', true)"
    >
      <img src="@/assets/svg/trash-white.svg" class="cursor-pointer" />
      Remove
    </button>
    <slot />
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useBusinessStore } from "../../../../../store/buisness.store";
import { ref } from "vue";
import { CustomModalContainer } from "../../../../common";
import DeleteBusiness from "./DeleteBusiness.vue";
import { useRoute, useRouter } from "vue-router";

const businessStore = useBusinessStore();
const { business } = storeToRefs(businessStore);
const modal = ref({
  toggle: false,
  business_id: "",
  modalWarning: false,
});
const setModal = (key, value) => {
  modal.value[key] = value;
};
const route = useRoute();
const router = useRouter();
const changeBusiness = (newBusiness) => {
  let business_id = "";
  if (newBusiness?.data?.length) {
    if (newBusiness?.data?.length > 1) {
      business_id = "master";
    } else if (newBusiness?.data?.length === 1) {
      business_id = newBusiness.data[0]?.id;
    }
  }
  const { pathname } = window.location;
  router.replace(
    `${pathname}?financial_year=${
      route.query.financial_year
    }&financial_period=${route.query.financial_period}${
      business_id ? "&business=" + business_id : ""
    }`
  );
};
const callBackAfterDelete = async () => {
  await changeBusiness(business.value);
  await setModal("toggle", false);
};
</script>

<style scoped>
.delete-btn {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.business-box {
  width: 100% !important;
  height: 60px;
  border-top: 1px solid rgba(142, 140, 140, 0.5);
}
.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}
</style>

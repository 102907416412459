<template>
  <div class="row">
    <form class="col-lg-12 mt-4" @submit="handleSubmit">
      <div
        v-for="item in formData"
        :key="item.id"
        class="row w-100 wrapper p-3"
      >
        <p class="col-lg-8 title my-auto">{{ item.title }}</p>
        <div class="col-lg-4">
          <CustomTextInput
            v-model="auditor_details[item.id]"
            v-bind="item.input"
            :error-message="errors[item.id]"
          />
        </div>
      </div>
      <div class="row w-100 wrapper p-3">
        <p class="col-lg-8 title my-auto">
          Are you Liable to furnish a report under Transfer Pricing Audit
        </p>
        <div class="col-lg-4">
          <CustomRadioButton
            v-model="
              auditor_details.liable_to_furnish_report_under_tansfer_pricing_audit
            "
            :options="[
              { name: 'Yes', value: 'Y' },
              { name: 'No', value: 'N' },
            ]"
          />
        </div>
      </div>
      <div class="col-lg-12 mt-5 mb-5">
        <div class="w-100 d-flex align-items-center">
          <button type="submit" class="save-button mr-3">Save</button>
          <button
            class="save-button bg-light text-dark"
            type="button"
            @click="goToGeneralInformationPage()"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import CustomTextInput from "../../common/CustomTextInput.vue";
import { useGeneralInfoStore } from "../../../store/generalInformation";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import CustomRadioButton from "../../common/CustomRadioButton.vue";
import { auditorDetailsFormData } from "./data";

const generalInfoStore = useGeneralInfoStore();
const { auditor_details } = storeToRefs(generalInfoStore);
const router = useRouter();
const route = useRoute();
const errors = ref({
  mobile: "",
});
const goToGeneralInformationPage = () => {
  router.push({
    path: `/entity/general-information/${route.params.id}`,
    query: {
      ...route.query,
      liable_to_audit: "Y",
    },
  });
};
const formData = ref(auditorDetailsFormData);
const handleSubmit = async (e) => {
  e.preventDefault();
  if (auditor_details.value.mobile && errors.value.mobile) {
    return;
  }
  await generalInfoStore.saveAuditorDetails(
    route.params.id,
    route.query.financial_year,
    async () => {
      setTimeout(async () => {
        await generalInfoStore.getGeneralInformation();
        await goToGeneralInformationPage();
      }, 2000);
    }
  );
};
watch(
  () => auditor_details.value.mobile,
  (newValue) => {
    if (newValue) {
      if (String(newValue).length >= 8 && String(newValue).length <= 13) {
        errors.value.mobile = "";
      } else {
        errors.value.mobile = "Mobile number should be between 8 to 13 digits";
      }
    } else {
      errors.value.mobile = "";
    }
  }
);
</script>

<style lang="scss" scoped>
.title {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.88px;
}
.wrapper {
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.radio-container {
  height: 56px;
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  gap: 11px;
}
.yes {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.w-400 {
  width: 400px;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
</style>

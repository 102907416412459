<template>
  <SideNavContainer title="Entity">
    <EntityChecker>
      <AddEntityTab active="ownershipDetails" />
      <UploadContainer
        name="ownership"
        :open="open"
        type="ownership_details"
        :set-open="setModal"
        :call-back="reload"
      />
      <div class="row w-100">
        <div class="col-lg-12 mt-4">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <h4 class="title">Ownership Details</h4>
            <div
              class="mr-2 gray-button cursor-pointer edit-text"
              @click="() => setModal(true)"
            >
              &#128206; Upload Ownership Details
            </div>
          </div>
        </div>
        <div class="col-lg-12 mt-2">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <h4 class="title">Manager Details</h4>
            <div
              class="edit-text d-flex align-items-center"
              @click="
                $router.push(
                  `/entity/add-entity/ownership-details/${$route.params.id}`
                )
              "
            >
              <img src="@/assets/svg/add-new.svg" alt="" class="mb-0 mr-2" />
              Add Manager Details
            </div>
          </div>
        </div>
        <OwnerShipDetails />
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <h4 class="title"></h4>
            <div
              class="edit-text d-flex align-items-center"
              @click="handleTab('director-details')"
            >
              <img src="@/assets/svg/add-new.svg" alt="" class="mb-0 mr-2" />
              Add Director
            </div>
          </div>
          <ListOfDirectors />
        </div>
        <div class="col-lg-12 mt-4">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <h4 class="title">List of Shareholders</h4>
            <div
              class="edit-text d-flex align-items-center"
              @click="handleTab('shareholder-details')"
            >
              <img src="@/assets/svg/add-new.svg" alt="" class="mb-0 mr-2" />
              Add Shareholder
            </div>
          </div>
          <ListShareHolder />
        </div>
      </div>
    </EntityChecker>
  </SideNavContainer>
</template>

<script setup>
import ListOfDirectors from "@/components/entity/MasterData/List/ListOfDirectors.vue";
import ListShareHolder from "@/components/entity/MasterData/List/LIstShareHolder.vue";
import OwnerShipDetails from "../../../MasterData/List/OwnerShipDetails.vue";
import { SideNavContainer } from "../../../../layout";
import { AddEntityTab } from "../../../../common";
import EntityChecker from "../../EntityChecker.vue";
import { useRoute, useRouter } from "vue-router";
import { getEntityItem } from "../../../../../store/entityActions/get";
import { useEntityStore } from "../../../../../store/entity";
import { storeToRefs } from "pinia";
import UploadContainer from "../../uploadEntity/UploadContainer.vue";
import { ref } from "vue";

const route = useRoute();
const router = useRouter();
const entityStore = useEntityStore();
const { ownershipDetails } = storeToRefs(entityStore);
const open = ref(false);

const setModal = (value) => {
  open.value = value;
};
const handleTab = async (tab) => {
  const id = route.params.id;
  await getEntityItem("ownershipDetails", "/entityOwnerDetails", id);
  let owner_id = ownershipDetails.value.data[0]?.id;
  router.push(`/entity/add-entity/${tab}/${id}?owner_id=${owner_id}`);
};
const reload = () => {
  window.location.reload();
};
</script>

<style scoped>
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.edit-text {
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #000000;
}
.gray-button {
  background-color: #deeded;
  padding: 10px;
}
</style>

<template>
  <BreadCrumb :links="breadCrumbLinks">
    <slot />
  </BreadCrumb>
</template>

<script setup>
import { ref } from "vue";
import { generate } from "randomized-string";
import BreadCrumb from "../common/BreadCrumb.vue";

const breadCrumbLinks = ref([
  {
    id: generate(),
    name: "Home",
    link: "/",
    disabled: false,
    active: false
  },
  {
    id: generate(),
    name: "Entity",
    link: "/entity",
    disabled: false,
    active: true
  }
]);
</script>

<style scoped></style>

<template>
  <div class="w-100">
    <div
      class="w-100 header d-flex align-items-center w-100 justify-content-between"
    >
      <p class="header-title mb-0">Shareholder’s Equity</p>
      <p class="header-title mb-0">Amount</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShareHolderAmount"
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.body-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.table-body {
  height: 44px;
  background: #deeded;
  padding-right: 100px;
  padding-left: 10px;
}
</style>

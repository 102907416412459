<template>
  <div class="w-100">
    <div class="header d-flex align-items-center w-100 justify-content-between">
      <p class="header-title mb-0">Distribution Expenses</p>
      <p class="header-title mb-0">
        {{ NumberFormatter(totalExpenditure.distribution_expenses) }}
      </p>
    </div>
    <form
      :id="item.model"
      @submit="handleSubmit"
      v-for="(item, index) in data"
      :key="item.id"
      :class="`w-100 table-body ${!index || !(index % 2) ? 'white' : 'gray'} ${
        item?.sub ? 'sub' : ''
      } d-flex align-items-center justify-content-between`"
    >
      <p class="mb-0 body-title">{{ item.name }}</p>
      <p
        v-if="!item.showInput"
        class="body-title mb-0"
        @click="openCloseInput(item.model, true)"
      >
        {{ NumberFormatter(indirect_expenses[item.model]) }}
      </p>
      <input
        :id="'input-' + item.model"
        :hidden="!item.showInput"
        v-model="indirect_expenses[item.model]"
        type="number"
        class="clickable-text"
        @blur="openCloseInput(item.model, false)"
      />
    </form>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useExpenditureStore } from "../../../../../store/expenditure";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";
import { useRoute } from "vue-router";
const props = defineProps({
  view: {
    type: Boolean,
    default: false
  }
});
const route = useRoute();
const data = ref([
  {
    model: "indrct_expens_distribution_expense_frieght_outward",
    showInput: false,
    name: "1. Freight Outward",
    sub: false
  },
  {
    model: "indrct_expens_distribution_expense_driver_expense",
    showInput: false,
    name: "2. Driver Expenses",
    sub: false
  },
  {
    model: "indrct_expns_distribution_expense_petrol_expense",
    showInput: false,
    name: "3. Petrol Expenses",
    sub: false
  },
  {
    model: "indrct_expns_distribution_expense_vehicle_repairs",
    showInput: false,

    name: "4. Vehicle Repairs",
    sub: false,
    amount: "0000.00"
  },
  {
    model: "indrct_expns_distribution_expense_others",
    showInput: false,
    name: "5. Others",
    sub: false,
    amount: "0000.00"
  }
]);
const openCloseInput = async (model, value) => {
  if (props.view || route.query.business === "master") {
    return;
  }
  const newValues = data.value.map((item) => {
    if (item.model === model) {
      return { ...item, showInput: value };
    }
    return item;
  });
  data.value = newValues;
  if (value) {
    const input = await document.getElementById(`input-${model}`);
    input.focus();
  }
};
const expenditureStore = useExpenditureStore();
const { indirect_expenses, totalExpenditure } = storeToRefs(expenditureStore);
totalExpenditure.value.distribution_expenses = data.value.reduce(
  (a, b) => a + Number(indirect_expenses.value[b.model]),
  0
);

watch(
  indirect_expenses,
  (newValue) => {
    totalExpenditure.value.distribution_expenses = data.value.reduce(
      (a, b) => a + Number(newValue[b.model]),
      0
    );
  },
  { deep: true }
);
const handleSubmit = async (e) => {
  e.preventDefault();
  let current_model = e.target.id;
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}

.sub-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}

.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}

.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}

.clickable-text {
  text-align: right !important;
  border: none;
  border-bottom: 1px solid#000000;
  padding-bottom: 1px;
  padding-left: 4px;
  height: 100%;
}

.clickable-text:focus {
  outline: 0;
  border-bottom: 1px solid#0f75bc;
}
</style>

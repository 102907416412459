<!-- eslint-disable vue/no-v-html -->
<template>
  <SideNavContainer
    side-nav-type="financialData"
    title="Exempt Income as per Chapter-7"
    :subtitle="name"
    :TitleRightSide="FinancialYear"
  >
    <ChaptersBreadCrumb
      :entity-name="name"
      chapter="chapter-7"
      chapter-name="Chapter-7"
    />
    <Business Tab="chapter-7" />
    <LoaderContainer
      :loading="chapter7.loading || statusLoading"
      :error="chapter7.error || statusError"
    >
      <div class="row mt-10">
        <div class="col-lg-12"></div>
        <Addition />
        <Deduction />
        <div class="col-lg-12">
          <div
            class="grand-total d-flex align-items-center justify-content-between mb-10"
          >
            <p class="mb-0 grand-total-amount">Net ADDITION/(deduction)</p>
            <div class="d-flex align-items-center">
              <p class="mb-0 grand-total-amount mr-3">
                {{ NumberFormatter(chapter7Total($route)) }}
              </p>
              <v-tooltip
                v-if="chapter7Color.total === '#e60000'"
                :text="`Computed value ${NumberFormatter(
                  chapter7.total_computed || 0
                )}`"
                location="bottom"
              >
                <template #activator="{ props }">
                  <p class="bold mb-0" v-bind="props">
                    <b> ?</b>
                  </p>
                </template>
              </v-tooltip>
            </div>
          </div>
        </div>
        <p class="text-info">{{ message }}</p>
        <div
          v-if="$route.query.current_business !== 'master'"
          class="w-100 mt-0 d-flex align-items-center justify-content-end"
        >
          <button class="btn-submit" @click="saveData()">Submit</button>
        </div>
        <div class="col-lg-12 mt-10">
          <h5 class="reference">Reference for Clause Below-</h5>
        </div>
        <v-expansion-panels v-if="chapter7.articles.length">
          <v-expansion-panel v-for="(article, i) in chapter7.articles" :key="i">
            <v-expansion-panel-title
              collapse-icon="mdi-minus"
              expand-icon="mdi-plus"
            >
              <div
                class="article px-3 py-3 w-100 d-flex align-items-center justify-content-between"
              >
                <h6 class="article-tile mb-0">{{ article.name }}</h6>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              ><div v-html="article.description"></div
            ></v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </LoaderContainer>
  </SideNavContainer>
</template>

<script setup>
import SideNavContainer from "../../layout/SideNavContainer.vue";
import { useRoute } from "vue-router";
import { useComputeIncomeTaxStore } from "../../../store/incometax.store";
import { useBusinessStore } from "@/store/buisness.store";
import { ChaptersBreadCrumb } from "../../BreadCrumbs";
import { storeToRefs } from "pinia";
import { ref, watch, onMounted } from "vue";
import Business from "../Business/Business.vue";
import LoaderContainer from "../../common/LoaderContainer.vue";
import { NumberFormatter } from "../../../helpers/numberFormatter";
import Addition from "./chapter7/Addition.vue";
import { useStatusStore } from "../../../store/statusStore";
import Deduction from "./chapter7/Deduction.vue";
import FinancialYear from "../common/FinancialPeriodYear.vue";

const route = useRoute();
const statusStore = useStatusStore();
const { loading: statusLoading, error: statusError } = storeToRefs(statusStore);
const name = ref("");
const entity_id = route.params.id;
const message = ref("");

const computeIncomeTaxStore = useComputeIncomeTaxStore();
const businessStore = useBusinessStore();
const { chapter7, chapter7Total, chapter7Color } = storeToRefs(
  computeIncomeTaxStore
);
const { business } = storeToRefs(businessStore);

const setBusinessName = (newValue, current_business) => {
  const findBusiness = newValue?.data?.find((b) => b.id == current_business);
  name.value = findBusiness?.name ? findBusiness?.name : "";
};
watch(
  business,
  (newValue) => {
    if (newValue?.data.length)
      setBusinessName(newValue, route.query.current_business);
  },
  { deep: true }
);
watch(
  () => route.query,
  (newValue) => {
    setBusinessName(business.value, newValue.current_business);
  }
);
onMounted(async () => {
  await computeIncomeTaxStore.getchapter7(
    entity_id,
    route.query.financial_year,
    route.query.financial_period,
    route.query.current_business
  );

  if (route.query.financial_year && route.query.financial_period) {
    await statusStore.getStatus(
      route.params.id,
      route.query.financial_year,
      route.query.financial_period
    );
  }
});

const saveData = async () => {
  await computeIncomeTaxStore.saveChapter7(
    entity_id,
    route.query.financial_period,
    route.query.financial_year,
    route.query.current_business,
    (mess) => (message.value = mess),
    async () => {
      await computeIncomeTaxStore.getchapter7(
        entity_id,
        route.query.financial_year,
        route.query.financial_period,
        route.query.current_business
      );
    }
  );
};
</script>

<style scoped>
.header {
  height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: white;
}
.table-body {
  min-height: 80px;
  padding-right: 100px;
  padding-left: 26px;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 30px;
}
.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}
.total-container {
  height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}
.total-desc {
  background: #deeded;
}
.reference {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.article-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px; /*
identical to box height, or 185% */
  letter-spacing: 0.055em;
  color: #000000;
}

.white {
  background: #fafbff;
}
.gray {
  background: #deeded;
}
.btn-submit {
  width: 82px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
.body-title.disabled {
  color: #747171 !important;
}
.total-amount.disabled {
  color: #747171 !important;
}
</style>

import { defineStore } from "pinia";
import { basicDataStructures } from "../helpers/storeStructure";
import { Toast } from "../helpers/toast";
import { getErrorMessage, getWarningMessage } from "../helpers/errorHandler";
import fetchWrapper from "../helpers/fetchWrapper";

export const useBusinessStore = defineStore("business", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      business: {
        entity_id: "",
        financial_year: "",
        ...basicDataStructures
      },
      current_business: null
    };
  },
  getters: {
    getCurrentBusiness(state) {
      return state.current_business;
    }
  },
  actions: {
    async getBusiness(entity_id, financial_year, financial_period) {
      if (financial_year && financial_period) {
        try {
          this.business.loading = true;
          const res = await fetchWrapper.get(
            `/entityBusiness?entity_id=${entity_id}&financial_year=${financial_year}&financial_period=${financial_period}`
          );
          if (res.data?.result === 1) {
            this.entity_id = entity_id;
            this.financial_year = financial_year;
          }
          this.business.data = res.data?.data || [];
          this.business.error = false;
          this.business.loading = false;
        } catch (err) {
          console.log(err);
          this.business.error = err?.response?.data?.message || "";
          this.business.loading = false;
        }
      }
    },
    async addBusiness(payload, setMessage, callBack = () => {}) {
      let toast = new Toast("Saving Business....");
      try {
        const res = await fetchWrapper.post("/entityBusiness", payload);
        if (res.data?.result === 1) {
          toast.makeSuccessToast("New business created successfully");
          setTimeout(() => setMessage(""), 3000);
          callBack(res?.data?.data, toast);
        } else {
          toast.makeErrorToast(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message
          );
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    },
    async updateBusiness(payload, setMessage, callBack = () => {}) {
      let toast = new Toast("Updating please wait....");
      try {
        const res = await fetchWrapper.put("/entityBusiness", payload);
        if (res.data?.result === 1) {
          toast.makeSuccessToast("Business Update successfully...");
          callBack(res?.data?.data);
        } else {
          toast.makeErrorToast(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message
          );
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    },
    async deleteBusiness(id, callBack = () => {}) {
      let toast = new Toast("Removing business please wait....");
      try {
        const res = await fetchWrapper.delete(`entityBusiness/${id}`);
        if (res.data?.result === 1) {
          toast.makeSuccessToast("Business Deleted successfully...");
          callBack(res?.data?.data);
        } else {
          toast.makeErrorToast(getWarningMessage(res));
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(getErrorMessage(err));
      }
    },
    async getOldBusiness(entity_id, financial_year, financial_period) {
      if (financial_year && financial_period) {
        try {
          const res = await fetchWrapper.get(
            `/entityBusiness?entity_id=${entity_id}&financial_year=${financial_year}&financial_period=${financial_period}`
          );
          return res.data?.data || [];
        } catch (err) {
          console.log(err);
        }
      }
    },
    async importBusiness(
      { entity_id, financial_year, financial_period },
      callBack = () => {}
    ) {
      let toast = new Toast("Importing please wait....");
      try {
        const res = await fetchWrapper.post("/createCopy", {
          entity_id,
          financial_year,
          financial_period
        });
        if (res.data?.result === 1) {
          toast.makeSuccessToast("Business imported successfully...");
          callBack(res?.data?.data);
        } else {
          toast.makeErrorToast(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message
          );
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    }
  }
});

<template>
  <div class="w-100 d-flex align-items-center justify-content-end">
    <div
      v-for="option in options"
      :key="option.value"
      class="d-flex align-items-center"
    >
      <input
        v-bind="option.input"
        v-model="value"
        type="radio"
        :value="option.value"
        class="mr-2"
        @click="
          () => {
            if (!option.onClick || option.disabled) return;
            option.onClick();
          }
        "
      />
      <p :class="`mb-0 choose-name mr-2 ${option.text}`">{{ option.name }}</p>
    </div>
    <slot />
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  options: {
    type: Array,
    default: [],
  },
});
const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>

<style lang="scss" scoped>
.choose-name {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.88px;
}
</style>

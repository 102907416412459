<template>
  <div class="w-100 mt-1">
    <div class="header d-flex align-items-center w-100 justify-content-between">
      <p class="header-title mb-0">Share capital</p>
      <p class="header-title mb-0">
        {{ NumberFormatter(totalShareHolderEquity.share_capital) }}
      </p>
    </div>
    <form
      :id="item.model"
      @submit="handleSubmit"
      v-for="(item, index) in data"
      :key="item.id"
      :class="`w-100 table-body ${!index || !(index % 2) ? 'white' : 'gray'} ${
        item?.sub ? 'sub' : ''
      } d-flex align-items-center justify-content-between`"
    >
      <p class="mb-0 body-title">
        {{ item.name }}
      </p>
      <p
        v-if="!item.showInput"
        class="body-title mb-0"
        @click="openCloseInput(item.model, true)"
      >
        {{ NumberFormatter(shareHolderLiability[item.model]) }}
      </p>

      <input
        :id="'input-' + item.model"
        v-model="shareHolderLiability[item.model]"
        :hidden="!item.showInput"
        type="number"
        class="clickable-text"
        @blur="openCloseInput(item.model, false)"
        @keydown.tab="(e) => handleTab(e, item.model)"
      />

      <!-- <p class="body-title mb-0">
        {{ item.amount }}
      </p> -->
    </form>
  </div>
</template>

<script setup>
import { generate } from "randomized-string";
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";

import { useLiabilityDataStore } from "../../../../../store/liabities.store";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";
import { useRoute } from "vue-router";
const props = defineProps({
  view: {
    type: Boolean,
    default: false
  }
});
const route = useRoute();
const liabilityDataStore = useLiabilityDataStore();
const { shareHolderLiability, totalShareHolderEquity } =
  storeToRefs(liabilityDataStore); //get initialvalue from store

const data = ref([
  {
    id: generate(),
    name: "1. Beginning of Financial Year",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "share_capital_begining_of_financial_year"
  },
  {
    id: generate(),
    name: "2. Shares issued (+)",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "share_capital_shares_issued"
  },
  {
    id: generate(),
    name: "3. Shares issue expenses (-)",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "share_capital_shares_issue_expenses"
  }
]);

totalShareHolderEquity.value.share_capital = data.value.reduce((a, b) => {
  if (b.model === "share_capital_shares_issue_expenses") {
    return a - Number(shareHolderLiability.value[b.model]);
  }
  return a + Number(shareHolderLiability.value[b.model]);
}, 0);

watch(
  shareHolderLiability,
  (newValue) => {
    totalShareHolderEquity.value.share_capital = data.value?.reduce((a, b) => {
      if (b.model === "share_capital_shares_issue_expenses") {
        return a - Number(newValue[b.model]);
      }
      return a + Number(newValue[b.model]);
    }, 0);
  },
  { deep: true }
);

const openCloseInput = async (model, value) => {
  if (props.view || route.query.business === "master") {
    return;
  }
  data.value = data.value.map((item) => {
    if (item.model === model) {
      return { ...item, showInput: value };
    }
    return item;
  });
  if (value) {
    const input = await document.getElementById(`input-${model}`);
    input.focus();
  }
};
const handleSubmit = async (e) => {
  e.preventDefault();
  let current_model = e.target.id;
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
const handleTab = async (e, current_model) => {
  e.preventDefault();
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}
</style>

<template>
  <LoaderContainer
    status-message="Transaction is processing, Please Don't leave this page"
    :loading="paymentOrder.loading"
    :error="paymentOrder.error"
  >
    <div class="row mt-4">
      <div class="col-lg-6">
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center check-wrapper"
        >
          <h1 class="check-mark text-success">
            <b> &#x2713; </b>
          </h1>
          <h2 class="success-message text-success mt-4">
            <b> Done </b>
          </h2>
          <h2 class="text-success mt-4">
            <b>Your payment was successful </b>
          </h2>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="w-100 cont table-responsive mt-4 mb-0">
          <table class="table custom-table mb-0 table-bordered table-striped">
            <thead>
              <tr>
                <th class="table-header">Name</th>
                <th class="table-header">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="table-rows align-middle">
                  <p class="data-text mb-0">Payment Method</p>
                </td>
                <td class="table-rows align-middle">
                  <p class="data-text mb-0">
                    {{ paymentOrder.data.paymethod }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="table-rows align-middle">
                  <p class="data-text mb-0">Payment Subscription</p>
                </td>
                <td class="table-rows align-middle">
                  <p class="data-text mb-0">
                    {{ paymentOrder.data.description }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="table-rows align-middle">
                  <p class="data-text mb-0">Card type</p>
                </td>
                <td class="table-rows align-middle">
                  <p class="data-text mb-0">
                    {{ paymentOrder.data.card?.type }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="table-rows align-middle">
                  <p class="data-text mb-0">Currency</p>
                </td>
                <td class="table-rows align-middle">
                  <p class="data-text mb-0">{{ paymentOrder.data.currency }}</p>
                </td>
              </tr>
              <tr>
                <td class="table-rows align-middle">
                  <p class="data-text mb-0">Status</p>
                </td>
                <td class="table-rows align-middle">
                  <p class="data-text mb-0">
                    {{ paymentOrder.data.status.text }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="table-rows align-middle">
                  <p class="data-text mb-0">Payment Done at</p>
                </td>
                <td class="table-rows align-middle">
                  <p class="data-text mb-0">
                    {{ paymentOrder.data.transaction.date }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="table-rows align-middle">
                  <p class="data-text mb-0">Amount</p>
                </td>
                <td class="table-rows align-middle">
                  <p class="data-text text-success mb-0">
                    <b> {{ paymentOrder.data.amount }} </b>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <button
          class="btn btn-success w-100 p-3"
          @click="$router.push(`/subscription`)"
        >
          Continue
        </button>
      </div>
    </div>
  </LoaderContainer>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { usePaymentStore } from "../../../store/payment.store";
import { onMounted } from "vue";
import LoaderContainer from "../../common/LoaderContainer.vue";
import { useProfileStore } from "../../../store/profile";

const paymentStore = usePaymentStore();
const { paymentOrder } = storeToRefs(paymentStore);
const profileStore = useProfileStore();

onMounted(async () => {
  setTimeout(async () => {
    await paymentStore.getOrderDetails();
    await profileStore.getUserProfileSilently();
  }, 15 * 1000);
});
</script>

<style scoped>
.check-wrapper {
  min-height: 50vh;
}
.check-mark {
  font-size: 200px;
}
.success-message {
  font-size: 40px;
}

.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
}
.table-header {
  min-height: 39px;
  background: rgb(76, 175, 80);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2.5px solid #fff;
}
.table-rows {
  background: #ffffff;
  border: 2.5px solid #fff;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.cont {
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <div class="w-100">
    <h4
      v-if="label && !label?.toString().includes('*')"
      :class="`label  ${disabled ? 'disabled' : ''}`"
    >
      {{ label }}
    </h4>
    <h4
      v-if="label && label?.toString().includes('*')"
      :class="`label  ${disabled ? 'disabled' : ''}`"
    >
      {{ label.toString().replace("*", "") }} <span class="text-danger">*</span>
    </h4>
    <v-select
      v-model="value"
      :label="placeholder"
      :items="options"
      :item-title="itemTitle"
      :item-value="itemValue"
      bg-color="#DEEDED"
      v-bind="otherProps"
    ></v-select>
    <p
      v-if="errorMessage"
      :style="{ 'margin-top': '-20px' }"
      class="text-danger"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
export default {
  name: "CustomSelectInput",
  props: [
    "modelValue",
    "placeholder",
    "label",
    "type",
    "options",
    "itemTitle",
    "itemValue",
    "otherProps",
    "errorMessage",
  ],
  emits: ["update:modelValue"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style>
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
/* .v-input__control {
  height: 48px;
  margin-top: 3px;
} */
/* .v-input--density-default .v-field--variant-solo .v-label.v-field-label--floating, .v-input--density-default .v-field--variant-filled .v-label.v-field-label--floating {
    top: 3px !important;
} */
</style>

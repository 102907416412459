export const incomeAttributalCoi = [
  {
    model: "a_income_attr_domestic",
    name: "A) Income Attributable for Domestic PE out of PL",
    sub: false,
    disabled: true,
  },
  {
    model: "a_i_revenue_attr_domestic",
    name: "i) Revenue Attributable for Domestic PE out of PL",
    sub: true,
    disabled: false,
  },
  {
    model: "a_ii_expense_attr_domestic",
    name: "ii) Expense Attributable for Domestic PE out of PL",
    sub: true,
    disabled: false,
  },
  {
    model: "b_income_attr_foreign",
    name: "B) Income Attributable for Foreign PE out of (1)",
    sub: false,
    disabled: true,
  },
  {
    model: "b_i_income_attr_foreign",
    name: "i) Income Attributable for Foreign PE out of (1)",
    sub: true,
    disabled: false,
  },
  {
    model: "b_ii_expense_attr_foreign",
    name: "ii) Expense Attributable for Foreign PE out of (1)",
    sub: true,
    disabled: false,
  },
  {
    model: "c_income_from_txn_nfzp_immovable_commercial_fz",
    name: "C) Income derived from transactions with Non Free Zone Persons related to Immovable Commercial Property located in a Free Zone",
    sub: false,
    disabled: true,
  },
  {
    model: "c_i_revenue_from_txn_nfzp_immovable_commercial_fz",
    name: "i) Revenue derived from transactions with Non Free Zone Persons related to Immovable Commercial Property located in a Free Zone",
    sub: true,
    disabled: true,
  },
  {
    model: "c_ii_expense_to_txn_nfzp_immovable_commercial_fz",
    name: "ii) Expenses related to transactions with Non Free Zone Persons related to Immovable Commercial Property located in a Free Zone",
    sub: true,
    disabled: true,
  },
  {
    model: "d_revenue_from_txn_any_person_fz_not_commercial",
    name: "D) Revenue derived from transactions with any Person related to property in a Free Zone not being a Commercial Property",
    sub: false,
    disabled: true,
  },
  {
    model: "d_i_revenue_from_txn_any_person_fz_not_commercial",
    name: "i) Revenue derived from transactions with any Person related to property in a Free Zone not being a Commercial Property",
    sub: true,
    disabled: true,
  },
  {
    model: "d_ii_expense_to_txn_any_person_fz_not_commercial",
    name: "ii) Expenses related to transactions with any Person related to property in a Free Zone not being a Commercial Property",
    sub: true,
    disabled: true,
  },
  {
    model: "e_income_from_txn_any_person_non_commercial_fz",
    name: "E) Income derived from transactions with any Person related to Non Commercial Property in a Free Zone.",
    sub: false,
    disabled: true,
  },
  {
    model: "e_i_revenue_from_txn_any_person_non_commercial_fz",
    name: "i) Revenue derived from transactions with any Person related to Non Commercial Property in a Free Zone.",
    sub: true,
    disabled: true,
  },
  {
    model: "e_ii_expense_to_txn_any_person_non_commercial_fz",
    name: "ii) Expenses related to transactions with any Person related to Non Commercial Property in a Free Zone.",
    sub: true,
    disabled: true,
  },
  {
    model: "f_income_from_non_qualifying_intl_prpty",
    name: "F) Income derived from Non Qualifying Intellectual Property",
    sub: false,
    disabled: true,
  },
  {
    model: "f_i_revenue_from_non_qualifying_intl_prpty",
    name: "i. Revenue derived from Non Qualifying Intellectual Property",
    sub: true,
    disabled: true,
  },
  {
    model: "f_ii_expense_to_and_earning_from_non_qualifying_intl_prpty",
    name: "ii. Expenses related to and for earning Income from Non Qualifying Intellectual Property",
    sub: true,
    disabled: true,
  },
  {
    model: "g_excess_income_from_qualifying_intl_prpty",
    name: "G) Excess Income derived from Qualifying Intellectual Property",
    sub: false,
    disabled: true,
  },
];

export const chaptersData = [
  {
    model: "unrealized_gain_loss_chapter_6",
    name: "Unrealized Gain or Loss Chapter-6",
  },
  {
    model: "exempt_income_chapter_7",
    name: "Exempt Income Chapter-7",
  },
  {
    model: "taxability_as_per_chapter_8",
    name: "Taxability as per Chapter - 8",
  },
  {
    model: "deduction_under_chapter_9",
    name: "Deductions under Chapter-9",
  },
  {
    model: "related_party_txn_under_chapter_10",
    name: "Related Party Transactions under Chapter-10",
  },
  {
    model: "deduction_under_chapter_9",
    name: "Deductions under Chapter-9",
  },
];

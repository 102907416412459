<template>
  <BreadCrumb :links="breadCrumbLinks" />
</template>

<script setup>
import { ref, watch, defineProps } from "vue";
import { generate } from "randomized-string";
import BreadCrumb from "../common/BreadCrumb.vue";
import { useRoute } from "vue-router";

const props = defineProps({
  entityName: {
    type: String,
    default: "",
  },
});
const route = useRoute();

const breadCrumbLinks = ref([
  {
    id: generate(),
    name: "Home",
    link: "/",
    disabled: false,
    active: false,
  },
  {
    id: generate(),
    name: "Entity",
    link: "/entity",
    disabled: false,
    active: false,
  },
  {
    id: "master-data",
    name: props.entityName,
    link: "/entity/master-data/" + route.params.id,
    disabled: false,
    active: false,
  },

  {
    id: "COI",
    name: "COI",
    link: `/entity/computation-tax/${route.params.id}`,
    disabled: false,
    active: true,
  },
]);

watch(
  () => props.entityName,
  (newValue) => {
    breadCrumbLinks.value = breadCrumbLinks.value.map((item) => {
      if (item.id === "master-data") {
        return { ...item, name: newValue };
      }
      return item;
    });
  },
  { deep: true }
);
</script>

<style scoped></style>

<template>
  <div class="w-100">
    <div class="header d-flex align-items-center w-100 justify-content-between">
      <p class="header-title mb-0">Cost of Sales</p>
      <p class="header-title mb-0">
        {{ NumberFormatter(CostOfSales) }}
      </p>
    </div>
    <form
      v-for="(item, index) in data"
      :id="item.model"
      :key="item.id"
      :class="`w-100 table-body ${!index || !(index % 2) ? 'white' : 'gray'} ${
        item.sub ? 'sub' : ''
      } d-flex align-items-center justify-content-between`"
      @submit="handleSubmit"
    >
      <p class="mb-0 body-title">{{ item.name }}</p>
      <p
        v-if="
          !item.showInput && item.model !== 'cost_of_sale_change_in_inventory'
        "
        class="body-title mb-0"
        @click="openCloseInput(item.model, true)"
      >
        {{ NumberFormatter(costOfSales[item.model]) }}
      </p>
      <p
        v-else-if="item.model === 'cost_of_sale_change_in_inventory'"
        class="total-amount mb-0"
      >
        {{ NumberFormatter(changeInInventory) }}
      </p>
      <input
        :id="`input-${item.model}`"
        v-model="costOfSales[item.model]"
        :hidden="!item.showInput"
        type="number"
        class="clickable-text"
        @focus="setOriginalValue(item.model, costOfSales[item.model])"
        @blur="handleBlur(item.model, false)"
      />
    </form>

    <div
      class="grand-total d-flex align-items-center justify-content-between mb-10"
    >
      <p class="mb-0 grand-total-amount">GROSS PROFIT</p>
      <p class="mb-0 grand-total-amount">
        {{ NumberFormatter(grossProfit($route)) }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useProfitAndLossStore } from "../../../../../store/ProfitAdLoss";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";
import { useRoute, useRouter } from "vue-router";
import { autoSaveIncome } from "./helper";
const props = defineProps({
  view: {
    type: Boolean,
    default: false,
  },
  setSavingStatus: {
    type: Function,
    default: () => {},
  },
});

const route = useRoute();
const router = useRouter();
const data = ref([
  {
    model: "cost_of_sale_change_in_inventory",
    showInput: false,
    name: "1. Change in Inventory",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "cost_of_sale_change_in_inventory_opening_stock",
    showInput: false,
    name: "1.1 Opening Stock ",
    sub: true,
    amount: "0000.00",
  },
  {
    model: "cost_of_sale_change_in_inventory_purchase",
    showInput: false,
    name: "1.2 Purchases (+)",
    sub: true,
    amount: "0000.00",
  },
  {
    model: "cost_of_sale_change_in_inventory_closing_stock",
    showInput: false,
    name: "1.3 Closing Stock (-)",
    sub: true,
    amount: "0000.00",
  },
  {
    model: "cost_of_sale_power_and_fuel",
    showInput: false,
    name: "2. Power and Fuel",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "cost_of_sale_jobwork_expenses",
    showInput: false,
    name: "3. Jobwork Expenses",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "cost_of_sale_carriege_inward",
    showInput: false,
    name: "4. Carriage Inward",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "cost_of_sale_labour_wages",
    showInput: false,
    name: "5. Labour Wages",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "cost_of_sale_other_direct_expneses",
    showInput: false,
    name: "6. Other Direct Expenses (Specify nature & amount)",
    sub: false,
    amount: "0000.00",
  },
]);

const profileAndLossStore = useProfitAndLossStore();
const { costOfSales, changeInInventory, CostOfSales, grossProfit } =
  storeToRefs(profileAndLossStore);

const openCloseInput = async (model, value) => {
  if (props.view || route.query.business === "master") {
    return;
  }
  const newValues = data.value.map((item) => {
    if (item.model === model) {
      return { ...item, showInput: value };
    }
    return item;
  });
  data.value = newValues;
  if (value) {
    const input = await document.getElementById(`input-${model}`);
    input.focus();
  }
};

const autoSave = async (model) => {
  const previousValue = data.value.find((item) => item.model === model).value;
  const currentValue = costOfSales.value[model];
  await autoSaveIncome(
    previousValue,
    currentValue,
    props.setSavingStatus,
    route,
    router
  );
};
const handleBlur = async (model, openClose) => {
  await openCloseInput(model, openClose);
  await autoSave(model);
};
const setOriginalValue = (key, value) => {
  const index = data.value.findIndex((item) => item.model === key);
  data.value[index].value = value;
};
const handleSubmit = async (e) => {
  e.preventDefault();
  let current_model = e.target.id;
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  await openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}

.sub-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}

.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}

.clickable-text {
  text-align: right !important;
  border: none;
  border-bottom: 1px solid#000000;
  padding-bottom: 1px;
  padding-left: 4px;
  height: 100%;
}

.clickable-text:focus {
  outline: 0;
  border-bottom: 1px solid#0f75bc;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.total-container {
  height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}
</style>

<template>
  <v-dialog width="auto" v-model="toggle" content-class="my-custom-dialog">
    <div class="card-container">
      <div class="d-flex w-100 justify-content-end">
        <img
          src="@/assets/svg/close-circle.svg"
          class="cursor-pointer"
          alt=""
          @click="closeModal()"
        />
      </div>
      <h4 class="bold title mt-4">
        Tax Liability at Other Income of Freezone person
      </h4>
      <div class="w-100 table-responsive mt-4 mb-0">
        <table class="table custom-table mb-0 table-bordered table-striped">
          <thead>
            <tr>
              <th class="table-header">Particulars</th>
              <th class="table-header">Condition</th>
              <th class="table-header amount">Amount</th>
              <th class="table-header">Amount to be added</th>
            </tr>
          </thead>
          <tbody>
            <RevenueExpense
              revenue_description="Revenue Attributable for Domestic Permanent Establishment out of (1)"
              :revenue_amount="revenue.revenue_attributable_for_domestic"
              :expense_amount="expense.expenses_attributable_for_domestic"
              expense_description="Expenses Attributable for Domestic Permanent Establishment out of (2)"
            />
            <RevenueExpense
              revenue_description="Revenue Attributable for Foreign Permanent Establishment out of (1)"
              :revenue_amount="revenue.revenue_attributable_for_foreign"
              :expense_amount="expense.expenses_attributable_for_foreign_PE"
              expense_description="Expenses Attributable for Foreign Permanent Establishment out of (2)"
            />
            <RevenueExpense
              revenue_description="Revenue from Commercial Property Located in Freezone Person, from Natural Person &  Non-Freezone Person out of (1)"
              :revenue_amount="revenue.revenue_commercial_property_located"
              :expense_amount="
                expense.expenses_commercial_property_located_in_FZP
              "
              expense_description="Expenses Attributable for Domestic Permanent Establishment out of (2)"
            />
            <RevenueExpense
              revenue_description="Revenue from Non Commercial Property Located in Freezone Person, from any person out of (1)"
              :revenue_amount="revenue.revenue_non_commercial_property_located"
              :expense_amount="
                expense.expenses_from_non_commercial_property_located_in_FZP
              "
              expense_description="Expenses from Non Commercial Property Located in Freezone Person, from any person out (2)"
            />
            <tr>
              <td class="table-rows">
                <p class="total-amount mb-0">
                  Tax Liability at Other Income of Freezone Person
                </p>
              </td>
              <td class="table-rows">
                <p class="data-text mb-0">
                  <b> Tax Liability at Other Income of Freezone Person </b>
                  is the addition of all amounts to be added
                </p>
              </td>
              <td class="table-rows">
                <p class="data-text">
                  <LastItem
                    :data="[
                      {
                        id: 'revenue_attributable_for_domestic',
                        revenue_amount:
                          revenue.revenue_attributable_for_domestic,
                        expense_amount:
                          expense.expenses_attributable_for_domestic,
                      },
                      {
                        id: 'revenue_attributable_for_foreign',
                        revenue_amount:
                          revenue.revenue_attributable_for_foreign,
                        expense_amount:
                          expense.expenses_attributable_for_foreign_PE,
                      },
                      {
                        id: 'revenue_commercial_property_located',
                        revenue_amount:
                          revenue.revenue_commercial_property_located,
                        expense_amount:
                          expense.expenses_commercial_property_located_in_FZP,
                      },
                      {
                        id: 'revenue_non_commercial_property_located',
                        revenue_amount:
                          revenue.revenue_non_commercial_property_located,
                        expense_amount:
                          expense.expenses_from_non_commercial_property_located_in_FZP,
                      },
                    ]"
                    :total="taxLiabilityOtherIncomeQFZP"
                  />
                </p>
              </td>
              <td class="table-rows">
                <p class="mb-0 total-amount">
                  {{ NumberFormatter(taxLiabilityOtherIncomeQFZP) }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import { ref, watch } from "vue";
import { NumberFormatter } from "../../../helpers/numberFormatter";
import { useQFZCalculatorStore } from "../../../store/QFZcalculator";
import { storeToRefs } from "pinia";
import RevenueExpense from "./TaxLiablityOtherIncomeFZP/RevenueExpense.vue";
import LastItem from "./TaxLiablityOtherIncomeFZP/LastItem.vue";

const props = defineProps({
  open: Boolean,
  setOpen: Function,
  taxableIncome: {
    type: Number,
    default: 0,
  },
  taxLiabilityOtherIncomeQFZP: {
    type: Number,
    default: 0,
  },
});
const QFZCalculator = useQFZCalculatorStore();
const { revenue, expense } = storeToRefs(QFZCalculator);

const toggle = ref(props.open);
watch(
  () => props.open,
  () => {
    toggle.value = props.open;
  }
);

const closeModal = () => {
  toggle.value = false;
  props.setOpen(false);
};
watch(toggle, (newValue) => props.setOpen(newValue));
</script>

<style scoped>
.card-container {
  background: #fafbff;
  border-radius: 5px;
  padding: 33px 27px;
  max-width: 1000px;
  overflow: scroll;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.card-container::-webkit-scrollbar {
  display: none;
}
.card-container::-webkit-scrollbar {
  display: none;
}
.card-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /* identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
}
.table-header {
  min-height: 39px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2.5px solid #fff;
}
.table-header.amount {
  min-width: 300px !important;
}
.table-rows {
  background: #ffffff;
  border: 2.5px solid #fff;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input {
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input:focus {
  outline: none;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.delete-button {
  width: 50px;
  height: 44px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.gray {
  background: #deeded;
}
</style>

<template>
  <SideNavContainer title="Edit Profile">
    <EditProfile />
  </SideNavContainer>
</template>

<script>
import { SideNavContainer } from "../../components/layout";
import EditProfile from "../../components/Profile/EditProfile.vue";

export default {
  name: "EditProfileView",
  components: { SideNavContainer, EditProfile }
};
</script>

<style lang="scss" scoped></style>

<template>
  <SideNavContainer title="Entity">
    <EntityChecker>
      <AddEntityTab active="branchDetails" />
      <div class="row">
        <div class="col-lg-12">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <h4 class="title mb-0">Branch Details</h4>
            <div class="edit-text d-flex align-items-center">
              <div
                v-if="hasBranch"
                class="d-flex gray-button align-items-center mr-2 cursor-pointer"
                @click="
                  $router.push(
                    `/entity/add-entity/branch-details/${$route.params.id}`
                  )
                "
              >
                <img src="@/assets/svg/add-new.svg" alt="" class="mb-0 mr-1" />
                <p class="edit-text mb-0">Add Branch</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="hasBranch && !isNaturalPerson" class="col-lg-12">
          <BranchDetails />
        </div>
        <div v-else-if="!hasBranch && !isNaturalPerson" class="col-lg-12">
          <BranchNoneDetails />
        </div>
      </div>
      <!-- <div class="col-lg-12">
          <UploadContainer
            name="branch"
            :open="open"
            type="branch_details"
            :set-open="setOpen"
            :call-back="reload"
          />
        </div>
        <div
          v-if="entity.data?.entity[0]?.has_branch === 'Y'"
          class="col-lg-12"
        >
          <div class="w-100 d-flex align-items-center justify-content-between">
            <h4 class="title mb-0">Branch Details</h4>
            <div class="edit-text d-flex align-items-center">
              <div
                class="d-flex gray-button align-items-center mr-2 cursor-pointer"
                @click="
                  $router.push(
                    `/entity/add-entity/branch-details/${$route.params.id}`
                  )
                "
              >
                <img src="@/assets/svg/add-new.svg" alt="" class="mb-0 mr-1" />
                <p class="edit-text mb-0">Add Branch</p>
              </div>
              <div
                class="mr-2 gray-button cursor-pointer edit-text"
                @click="() => setOpen(true)"
              >
                &#128206; Upload Branch Details
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="entity.data?.entity[0]?.has_branch === 'Y'"
          class="col-lg-12"
        >
          <BranchDetails />
        </div>
        <div
          v-if="entity.data?.entity[0]?.has_branch === 'Y'"
          class="col-lg-12"
        >
          <div class="w-100 d-flex align-items-center justify-content-between">
            <h4 class="title">Owners List</h4>
            <div
              class="edit-text d-flex align-items-center"
              @click="
                $router.push(
                  `/entity/add-entity/branch-details/${$route.params.id}`
                )
              "
            >
              <img src="@/assets/svg/add-new.svg" alt="" class="mb-0 mr-2" />
              Add Owner
            </div>
          </div>
        </div>
        <div
          v-if="entity.data?.entity[0]?.has_branch === 'Y'"
          class="col-lg-12"
        >
          <OwnerList />
        </div>
        <div
          v-if="entity.data?.entity[0]?.has_branch === 'N'"
          class="col-lg-12"
        >
          <h4 class="title mb-0">Branch Details</h4>
          <BranchNoneDetails />
        </div>
      </div> -->
    </EntityChecker>
  </SideNavContainer>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useEntityStore } from "../../../../../store/entity";
import OwnerList from "../../../MasterData/List/OwnerList.vue";
import BranchNoneDetails from "../../../MasterData/List/BranchNoneDetails.vue";
import UploadContainer from "../../uploadEntity/UploadContainer.vue";
import { ref, computed } from "vue";
import SideNavContainer from "../../../../layout/SideNavContainer.vue";
import EntityChecker from "../../EntityChecker.vue";
import { AddEntityTab } from "../../../../common";
import BranchDetails from "../../../MasterData/List/BranchDetails.vue";

const entityStore = useEntityStore();
const { entity } = storeToRefs(entityStore);
const open = ref(false);
const setOpen = (value) => {
  open.value = value;
};
const reload = () => {
  window.location.reload();
};
const isNaturalPerson = computed(() => {
  return entity.value.data?.entity[0]?.entity_type == 2;
});
const hasBranch = computed(() => {
  if (!entity.value.data?.entity) {
    return false;
  }
  return entity.value.data?.entity[0]?.has_branch == "Y";
});
</script>

<style scoped>
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.edit-text {
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #000000;
}
.gray-button {
  background-color: #deeded;
  padding: 10px;
  cursor: pointer !important;
}
</style>

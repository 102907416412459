<template>
  <div class="row">
    <div class="col-lg-12 mt-4">
      <h4 class="title">Address</h4>
    </div>
    <div class="col-lg-4 col-md-6 mt-4">
      <CustomTextInput
        v-model="general_information.office_address"
        label="Business Address"
        placeholder="Business Address"
        :disabled="true"
      />
    </div>
    <div class="col-lg-4 col-md-6 mt-4">
      <CustomTextInput
        v-model="general_information.building_no"
        label="Building Address"
        placeholder="Building Address"
      />
    </div>
    <div class="col-lg-4 col-md-6 mt-4">
      <CustomTextInput
        v-model="general_information.street"
        label="Street"
        placeholder="Street"
      />
    </div>
    <div class="col-lg-4 col-md-6 mt-4">
      <CustomTextInput
        v-model="general_information.area"
        label="Area"
        placeholder="Area"
      />
    </div>
    <div class="col-lg-4 col-md-6 mt-4">
      <CustomSelectInput
        v-model="general_information.emirates"
        :options="state.data"
        placeholder="Emirate"
        label="Emirate"
        item-title="name"
        item-value="id"
        :other-props="{ disabled: true }"
      />
    </div>
    <div class="col-lg-4 col-md-6 mt-4">
      <CustomTextInput
        v-model="general_information.po_box_no"
        label="PO BOX"
        placeholder="PO BOX"
        :disabled="true"
      />
    </div>
  </div>
</template>

<script setup>
import { CustomSelectInput, CustomTextInput } from "../../common";
import { useGeneralInfoStore } from "../../../store/generalInformation";
import { storeToRefs } from "pinia";
import { useCountryStore } from "../../../store/country";
import { onMounted } from "vue";
const generalInfoStore = useGeneralInfoStore();
const { general_information } = storeToRefs(generalInfoStore);
const countryStore = useCountryStore();
const { countries, states: state } = storeToRefs(countryStore);
onMounted(async () => {
  if (!countries.value.data?.length) {
    await countryStore.getCountryList();
  }
  const country = countries.value.data.find(
    (c) => c.name === "United Arab Emirates"
  );
  if (country) {
    await countryStore.getStateList(country?.id);
  }
});
</script>

<style scoped>
.title {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.88px;
}
</style>

<template>
  <SideNavContainer title="Entity">
    <EntityChecker>
      <AddEntityTab active="entityDetails" />
      <ShowStatus :modal="editStatus" :set-modal="setStatus" />
      <div class="row mt-4">
        <div class="col-lg-12">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <h4 class="title">Entity Details</h4>
            <div
              class="gray-button d-flex align-items-center"
              @click="editEntity('')"
            >
              <img src="@/assets/svg/table-edit.svg" alt="" class="mb-0 mr-2" />
              <p class="edit-text mb-0">Edit Entity</p>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <EntityDetails />
        </div>
        <div class="col-lg-12">
          <h4 class="title">License Details</h4>
          <LicenseDetails />
        </div>
        <div class="col-lg-12">
          <h4 class="title">Tax Details</h4>
          <TaxDetails />
        </div>
        <div class="col-lg-12">
          <h4 class="title">Business Activity Details</h4>
          <BusinessActivityDetails />
        </div>
      </div>
    </EntityChecker>
  </SideNavContainer>
</template>

<script setup>
import { AddEntityTab } from "@/components/common";
import BranchDetails from "@/components/entity/MasterData/List/BranchDetails.vue";
import EntityDetails from "@/components/entity/MasterData/List/EntityDetails.vue";
import LicenseDetails from "@/components/entity/MasterData/List/LicenseDetails.vue";
import OwnerList from "@/components/entity/MasterData/List/OwnerList.vue";
import TaxDetails from "@/components/entity/MasterData/List/TaxDetails.vue";
import SideNavContainer from "@/components/layout/SideNavContainer.vue";
import EntityChecker from "../../EntityChecker.vue";
import { useEntityStore } from "../../../../../store/entity";
import { storeToRefs } from "pinia";
import UploadContainer from "../../uploadEntity/UploadContainer.vue";
import { ref } from "vue";
import BranchNoneDetails from "../../../MasterData/List/BranchNoneDetails.vue";
import { useRoute, useRouter } from "vue-router";
import ShowStatus from "../../../MasterData/List/ShowStatus.vue";
import BusinessActivityDetails from "../../../MasterData/List/BusinessActivityDetails.vue";
const entityStore = useEntityStore();
const { entity } = storeToRefs(entityStore);
const open = ref(false);
const setOpen = (value) => {
  open.value = value;
};
const reload = () => {
  window.location.reload();
};
const router = useRouter();
const route = useRoute();
const editStatus = ref(false);
const setStatus = (status) => {
  editStatus.value = status;
};
const editEntity = (Push) => {
  if (entity.value?.data?.entity[0]?.file_return_status === "Filed") {
    setStatus(true);
    return;
  }
  if (!Push) {
    Push = `/entity/add-entity/entity-details/entity/${route.params.id}`;
  }
  router.push(Push);
};
</script>

<style scoped>
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.edit-text {
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #000000;
}
.gray-button {
  background-color: #deeded;
  padding: 10px;
  cursor: pointer !important;
}
</style>

<template>
  <div class="col-lg-6">
    <Chapter9Popup
      v-if="$route.query.current_business !== 'master'"
      type="addition_item"
      :model="model"
      :open="modal.modal"
      :on-open="setInitialValue"
      :on-close="autoSave"
      :set-open="setModal"
    />
    <Chapter9Popup5 :open="modal.modal6" :set-open="setModal6" />
    <Chapter9Popup7 :open="modal.modal8" :set-open="setModal8" />

    <div class="header d-flex align-items-center w-100 justify-content-between">
      <p class="header-title mb-0">Addition in Taxable Income</p>
      <p class="header-title mb-0">Amount</p>
    </div>
    <ClickableInput
      v-for="(item, key, index) in chapter9.addition_description"
      :key="key"
      v-model="chapter9.addition_item[key]"
      :title="item"
      :custom-container-class="`${!index || !(index % 2) ? 'gray' : 'white'}`"
      :custom-title-class="titleClass(key)"
      :show-input="addition_itemInput[key]"
      :custom-text-class="textClass(key)"
      :text-props="{
        style: {
          color:
            key === 'interest_expense_disallowed_as_per_article_30' ||
            key === 'entertainment_expense_disallowed_as_per_article_32'
              ? chapter9.addition_item_color[key]
              : '#0000b3',
        },
        onClick: () => handleClick(key),
      }"
      :amount="setAmount(key)"
      :input-props="{
        id: `input-${key}`,
        hidden:
          !addition_itemInput[key] ||
          $route.query.current_business === 'master',
        onkeyup: () => changeColor(key),
        onblur: () => openCloseInput(key, false),
      }"
      :show-tooltip="
        key === 'interest_expense_disallowed_as_per_article_30' ||
        key === 'entertainment_expense_disallowed_as_per_article_32'
          ? chapter9.addition_item_color[key] === '#e60000'
          : false
      "
      :tool-tip-number="chapter9.addition_item_computed[key]"
      :show-amount="showAmount(key)"
      :text="showText(key)"
    />
    <EditableSheet
      v-if="$route.query.current_business !== 'master'"
      v-model="chapter9.additional_data.addition.data"
      v-model:total="chapter9.additional_data.addition.total"
    />
    <div
      v-if="$route.query.current_business === 'master'"
      class="w-100 table-body gray d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 body-title">Additional</p>
      <div class="d-flex align-items-center">
        <p class="mb-0 body-title mr-3 cursor-pointer">
          {{ NumberFormatter(chapter9.additional_data.addition) }}
        </p>
      </div>
    </div>
    <div
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 total-amount">Total Additions</p>
      <div class="d-flex align-items-center">
        <p
          class="mb-0 total-amount mr-3"
          :style="{ color: chapter9Color.total_addtion }"
        >
          {{ NumberFormatter(chapter9TotalAddition) }}
        </p>
        <v-tooltip
          v-if="chapter9Color.total_addtion === '#e60000'"
          :text="`Computed value ${NumberFormatter(
            chapter9.total_addtion_computed || ''
          )}`"
          location="bottom"
        >
          <template #activator="{ props }">
            <p class="bold mb-0" v-bind="props">
              <b> ?</b>
            </p>
          </template>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useStatusStore } from "../../../../store/statusStore";
import { ClickableInput, EditableSheet } from "../../../common";
import { useComputeIncomeTaxStore } from "../../../../store/incometax.store";
import { reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useBusinessStore } from "../../../../store/buisness.store";
import Chapter9Popup from "./Popup.vue";
import Chapter9Popup5 from "./Popup5.vue";
import Chapter9Popup7 from "./Popup7.vue";
import { NumberFormatter } from "../../../../helpers/numberFormatter";
import { autoSaveChp9Line4and7 } from "./helper";

const props = defineProps({
  setSavingStatus: {
    type: Function,
    default: () => {},
  },
});
const businessStore = useBusinessStore();
const computeIncomeTaxStore = useComputeIncomeTaxStore();
const route = useRoute();
const statusStore = useStatusStore();
const addition_itemInput = ref({});
const model = ref("expenses_debited_in_PL");
const { business } = storeToRefs(businessStore);
const initialValue = reactive({
  interest_disallowed_as_per_article_28: false,
  interest_expense_disallowed_as_per_article_31: false,
});
const modal = reactive({
  modal: false,
  modal6: false,
  modal8: false,
});
const { status } = storeToRefs(statusStore);
const {
  chapter9,
  chapter9TotalAddition,
  chapter9DisallowancePerArticle,
  chapter9Color,
  computeIncomeTax,
} = storeToRefs(computeIncomeTaxStore);

const changeColor = (key) => {
  if (
    key === "interest_expense_disallowed_as_per_article_30" ||
    key === "entertainment_expense_disallowed_as_per_article_32"
  ) {
    chapter9.value.addition_item_color[key] = "#e60000";
  }
};
const showAmount = (key) => {
  return business.value.data.length === 1 &&
    key === "interest_expense_disallowed_as_per_article_30"
    ? chapter9.value.addition_item_status[key]
    : chapter9.value.addition_item_status[key] &&
        (route.query.current_business !== "master" &&
        key === "interest_expense_disallowed_as_per_article_30"
          ? false
          : true);
};
const showText = (key) => {
  return business.value.data.length === 1 &&
    key === "interest_expense_disallowed_as_per_article_30"
    ? ""
    : chapter9.value.addition_item_status[key] &&
        (route.query.current_business !== "master" &&
        key === "interest_expense_disallowed_as_per_article_30"
          ? "Calculation is done group data"
          : "");
};

const titleClass = (key) =>
  !chapter9.value.addition_item_status[key]
    ? "disabled"
    : key === "disallowance_as_per_article_33"
    ? "total-amount bold"
    : key === "expenses_related_to_exempt_income" &&
      !computeIncomeTax.value.chapter_seven
    ? "disabled"
    : "";

const textClass = (key) =>
  !chapter9.value.addition_item_status[key]
    ? "disabled"
    : key === "expenses_related_to_exempt_income" &&
      !computeIncomeTax.value.chapter_seven
    ? "disabled"
    : key === "disallowance_as_per_article_33"
    ? "total-amount bold"
    : "cursor-pointer";

const openCloseInput = async (key, value) => {
  if (route.query.current_business === "master" || status.value) {
    return;
  }
  if (
    key === "expenses_related_to_exempt_income" &&
    !computeIncomeTax.value?.chapter_seven
  ) {
    return;
  }
  if (!chapter9.value.addition_item_status[key]) {
    return;
  }

  addition_itemInput.value[key] = await value;
  if (value) {
    const input = await document.getElementById(`input-${key}`);
    input.focus();
  }
};
const setModal = (value, key) => {
  if (route.query.current_business === "master" || status.value) {
    return;
  }
  if (key) {
    if (!chapter9.value.addition_item_status[key]) {
      return;
    }
    model.value = key;
  }

  modal.modal = value;
};

const setModal6 = (value) => {
  if (status.value) {
    return;
  }
  if (
    !chapter9.value.addition_item_status[
      "interest_expense_disallowed_as_per_article_30"
    ]
  ) {
    return;
  }

  modal.modal6 = value;
};
const setModal8 = (value) => {
  if (route.query.current_business === "master" || status.value) {
    return;
  }
  modal.modal8 = value;
};
const handleClick = (key) => {
  if (key === "disallowance_as_per_article_33") {
    return;
  }
  if (
    (business.value.data.length > 1 &&
      route.query.current_business !== "master" &&
      key === "interest_expense_disallowed_as_per_article_30") ||
    (route.query.current_business === "master" &&
      key !== "interest_expense_disallowed_as_per_article_30")
  ) {
    return;
  }

  if (
    key === "expenses_debited_in_PL" || //1
    key === "expenses_wholly_partially_not_related_to_business_activity" || //2
    key === "expenses_related_to_exempt_income" || // 3
    key === "interest_disallowed_as_per_article_28" || //4
    key === "other_expenses_disallowed" || //5
    key === "interest_expense_disallowed_as_per_article_31" || //7
    key === "entertainment_expense_disallowed_as_per_article_32" //8
  ) {
    setModal(true, key);
  } else if (
    key === "interest_expense_disallowed_as_per_article_30" //6
  ) {
    setModal6(true);
  } else {
    openCloseInput(key, true);
  }
};
const setAmount = (key) => {
  if (route.query.current_business === "master") {
    return chapter9.value.addition_item[key];
  }

  if (
    key === "expenses_wholly_partially_not_related_to_business_activity" || //2
    key === "expenses_debited_in_PL" || // 1
    key === "expenses_related_to_exempt_income" || // 3
    key === "interest_disallowed_as_per_article_28" || //4
    key === "other_expenses_disallowed" || // 5
    key === "interest_expense_disallowed_as_per_article_31" || //7
    key === "entertainment_expense_disallowed_as_per_article_32" //8
  ) {
    if (chapter9.value.addition_item[key]) {
      return chapter9.value.addition_item[key]?.total;
    } else return "";
  } else if (key === "disallowance_as_per_article_33") {
    return chapter9DisallowancePerArticle.value;
  }
  return chapter9.value.addition_item[key];
};
const setInitialValue = (key) => {
  if (
    key === "interest_disallowed_as_per_article_28" || // 4
    key === "interest_expense_disallowed_as_per_article_31" // 7
  ) {
    initialValue[key] = true;
  }
};
const autoSave = async (key) => {
  if (
    key === "interest_disallowed_as_per_article_28" || // 4
    key === "interest_expense_disallowed_as_per_article_31" // 7
  ) {
    if (initialValue[key]) {
      await autoSaveChp9Line4and7(route, props.setSavingStatus);
      initialValue[key] = await false;
    }
  }
};
watch(chapter9, (newValue) => {
  if (!newValue.loading && !newValue.error && newValue.addition_description) {
    for (let x in newValue.addition_description) {
      addition_itemInput.value[x] = false;
    }
  }
});
</script>

<style lang="scss" scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: white;
}
.table-body {
  min-height: 80px;
  padding-right: 100px;
  padding-left: 26px;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 30px;
}
.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}
.total-container {
  height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}
.total-desc {
  background: #deeded;
}
.reference {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.article-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px; /*
identical to box height, or 185% */
  letter-spacing: 0.055em;
  color: #000000;
}

.white {
  background: #fafbff;
}
.gray {
  background: #deeded;
}
.btn-submit {
  width: 82px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
.body-title.disabled {
  color: #747171 !important;
}
.total-amount.disabled {
  color: #747171 !important;
}
.cursor-pointer {
  cursor: pointer;
}
</style>

<template>
  <div class="w-100">
    <div
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 total-amount">Profit From Continue Operations</p>
      <p class="mb-0 total-amount">
        {{ NumberFormatter(profitFromContinueOperations(grossProfit($route))) }}
      </p>
    </div>
    <form
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 total-amount">Profit/(Loss) From Discontinue Operations</p>
      <p
        v-if="!showInput.profit_loss"
        class="body-title mb-0"
        @click="openCloseInput('profit_loss', true)"
      >
        {{ NumberFormatter(profit_loss_from_discontinue_operation) }}
      </p>
      <input
        id="input-profit_loss"
        v-model="profit_loss_from_discontinue_operation"
        :hidden="!showInput.profit_loss"
        type="number"
        class="clickable-text"
        @blur="openCloseInput('profit_loss', false)"
        @keydown.enter.tab="(e) => handleTabEnter(e, 'profit_loss')"
      />
    </form>
    <form
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 total-amount">Share of (loss)/proﬁt of associates</p>
      <p
        v-if="!showInput.share_of_loss"
        class="body-title mb-0"
        @click="openCloseInput('share_of_loss', true)"
      >
        {{ NumberFormatter(share_of_loss_profit_of_associates) }}
      </p>
      <input
        id="input-share_of_loss"
        v-model="share_of_loss_profit_of_associates"
        :hidden="!showInput.share_of_loss"
        type="number"
        class="clickable-text"
        @blur="openCloseInput('share_of_loss', false)"
        @keydown.enter.tab="(e) => handleTabEnter(e, 'share_of_loss')"
      />
    </form>
    <div
      class="grand-total d-flex align-items-center justify-content-between mb-4"
    >
      <p class="mb-0 grand-total-amount">Profit before tax</p>
      <p class="mb-0 grand-total-amount">
        {{ NumberFormatter(profitBeforeIncomeTax(grossProfit($route))) }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useExpenditureStore } from "../../../../../store/expenditure";

import { reactive } from "vue";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";
import { useProfitAndLossStore } from "../../../../../store/ProfitAdLoss";

const expenditureStore = useExpenditureStore();
const profitLossStore = useProfitAndLossStore();
const { grossProfit } = storeToRefs(profitLossStore);
const {
  share_of_loss_profit_of_associates,
  profitBeforeIncomeTax,
  profit_loss_from_discontinue_operation,
  profitFromContinueOperations,
} = storeToRefs(expenditureStore);
const showInput = reactive({
  share_of_loss: false,
  profit_loss: false,
});
const openCloseInput = async (model, value) => {
  showInput[model] = await value;
  if (value) {
    const input = await document.getElementById(`input-${model}`);
    if (input) {
      await input.focus();
    }
  }
};

const handleTabEnter = async (e, model) => {
  e.preventDefault();
  await openCloseInput(model, false);
  if (model === "profit_loss") {
    await openCloseInput("share_of_loss", true);
  }
};
</script>

<style scoped>
.total-container {
  height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}
</style>

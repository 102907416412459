<template>
  <SideNavContainer
    title="FreeZone Person Calculator"
    side-nav-type="financialData"
  >
    <LoaderContainer
      :loading="financialTypeLoading || loading || statusLoading"
      :error="statusError"
    >
      <AlertModal
        :open="alertModal"
        message="Warning: You can not leave this page unless you Save & Next"
        sub-message="Please complete the checking process"
        :on-confirm="() => showAlertModal(false)"
        :has-cancel="false"
        button-name="ok"
        :modal-props="{ persistent: true }"
      />
      <GeneralInformationCalculatorBreadCrumb />
      <OptionsToOpenCalculator
        :show-calculator="showCalculator"
        :show-input="showCalculatorInput"
        :set-completed="setCompleted"
      />
      <div class="row">
        <div class="col-lg-12">
          <div class="w-100 wrapper d-flex p-3 align-items-center">
            <h4 class="choose-title w-90 bold mb-0">
              vi. Qualifies De-minimis (Calculate Below)
            </h4>
          </div>
        </div>
      </div>
      <Calculator
        :show-calculator="calculator"
        :disabled="!!status || calculatorInput"
        :set-completed="setCompleted"
      />
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <div class="w-100 px-4 d-flex align-items-center">
            <button class="save-button p-2 mr-3" @click="saveData">
              Save & Next
            </button>
          </div>
        </div>
      </div>
    </LoaderContainer>
  </SideNavContainer>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { SideNavContainer } from "../../layout";
import OptionsToOpenCalculator from "./OptionsToOpenCalculator.vue";
import Calculator from "./Calculator.vue";
import { GeneralInformationCalculatorBreadCrumb } from "../../BreadCrumbs";
import { useGeneralInfoStore } from "../../../store/generalInformation";
import { storeToRefs } from "pinia";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import { AlertModal, LoaderContainer } from "../../common";
import { useQFZCalculatorStore } from "../../../store/QFZcalculator";
import { useStatusStore } from "../../../store/statusStore";
import { useIFRSStore } from "../../../store/NonIFRS/IFRS.store";
const calculator = ref(false);
const router = useRouter();
const route = useRoute();
const calculatorInput = ref(true);
const generalInfoStore = useGeneralInfoStore();
const QFZCalculator = useQFZCalculatorStore();
const statusStore = useStatusStore();
const financialStore = useIFRSStore();
const { options } = storeToRefs(generalInfoStore);
const alertModal = ref(false);
const completed = ref(false);
const {
  status,
  loading: statusLoading,
  error: statusError,
} = storeToRefs(statusStore);
const { loading } = storeToRefs(QFZCalculator);
const { loading: financialTypeLoading } = storeToRefs(financialStore);
const showAlertModal = (value) => {
  alertModal.value = value;
};
const showCalculator = (value) => {
  calculator.value = value;
};
const showCalculatorInput = (value) => {
  calculatorInput.value = value;
};
const setCompleted = (value) => {
  completed.value = value;
};
onBeforeRouteLeave(async () => {
  if (Number(status.value)) {
    return true;
  }
  if (completed.value) {
    completed.value = await false;
    return true;
  }
  showAlertModal(true);
  return false;
});
const goToGeneralInformationPage = async () => {
  completed.value = await true;

  router.push({
    path: `/entity/general-information/${route.params.id}`,
    query: {
      ...route.query,
      qfzp: options.value.qualified_for_freezone,
      open_non_qfzp:
        options.value.qualified_for_freezone === "N" ? true : undefined,
    },
  });
};
const saveData = async () => {
  await QFZCalculator.saveQfzpCalc(
    route.params.id,
    route.query.financial_year,
    route.query.financial_period,
    async () => {
      goToGeneralInformationPage();
    }
  );
};
onMounted(async () => {
  await QFZCalculator.clearData();
  await financialStore.getIFRSCheck(
    route.params.id,
    route.query.financial_year,
    route.query.financial_period,
    async () => {
      await statusStore.getStatus(
        route.params.id,
        route.query.financial_year,
        route.query.financial_period
      );
      await QFZCalculator.getQFZP(
        route.params.id,
        route.query.financial_year,
        route.query.financial_period
      );
    }
  );
});
</script>

<style lang="scss" scoped>
.choose-title {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.88px;
}
.choose-name {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.88px;
}
.wrapper {
  background: #deeded;
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.save-button {
  min-width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.w-90 {
  width: 84%;
}
.choose-title > .active {
  color: #0f75bc;
}
</style>

export const HandleTabAndEnter = (data,modelData,model) => {
    // get the next value
    const nextModel =
      modelData.value[modelData.value.map((e) => e.model).indexOf(model) + 1];
    if (nextModel) {
      if (data.value[model].last_input === "input") {
        const newModelData = modelData.value.map((item) => {
          if (item.model === nextModel.model) {
            return { ...item, showInput: true };
          }
          return { ...item, showInput: false };
        });
        modelData.value = newModelData;
      } else if (data.value[model].last_input === "popup") {
        const newModelData = modelData.value.map((item) => {
          if (item.model === nextModel.model) {
            return { ...item, showPopup: true };
          }
          return { ...item, showPopup: false };
        });
        modelData.value = newModelData;
      }
    }
  };
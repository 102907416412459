<template>
  <tr v-for="(item, index) in expense" :key="item.id">
    <td class="table-rows align-middle">
      <div class="w-100 d-flex align-items-center">
        <p class="mb-0 bold flex-1 data-text">
          <b> {{ index + 3 }}. </b>
        </p>
        <p class="mb-0 flex-2 data-text">
          {{ item.name }}
        </p>
      </div>
    </td>
    <td class="table-rows2 align-middle">
      <p
        v-if="!item.showAmountInput"
        class="mb-0 data-text"
        @click="openCloseInput(item.id, 'amount', true)"
      >
        {{ NumberFormatter(item.amount) }}
      </p>
      <input
        :id="`input-amount-${item.id}`"
        v-model="expense[index].amount"
        class="custom-input"
        :hidden="!item.showAmountInput"
        type="text"
        @blur="openCloseInput(item.id, 'amount', false)"
        @keydown.tab.enter="(e) => handleTab(e, item.id, 'amount')"
        @keypress="(e) => checkAmount(e, item.id)"
      />
    </td>
    <td class="table-rows2 align-middle">
      <p class="mb-0 total-amount">
        {{
          item.id === "total_profits_for_the_year"
            ? NumberFormatter(totalProfitsForTheYear)
            : item.id === "add_back_expenses_disallowed"
            ? NumberFormatter(addBackExpensesDisallowed)
            : item.id === "exempt_income_from_rent_investment"
            ? NumberFormatter(exemptIncomeFromRentInvestment)
            : NumberFormatter("")
        }}
      </p>
    </td>
  </tr>
</template>

<script setup>
import { useCTaxCalculatorIndividualStore } from "../../../store/CTaxCalculatorIndividual";
import { storeToRefs } from "pinia";
import { NumberFormatter } from "../../../helpers/numberFormatter";
import { insertChar } from "../../../helpers/stringUtils";
const props = defineProps({
  setAlert: {
    type: Function,
    default: () => {}
  }
});
const taxCalculator = useCTaxCalculatorIndividualStore();
const {
  expense,
  receipts,
  netReceiptsDuringTheYearAmount,
  totalProfitsForTheYear,
  addBackExpensesDisallowed,
  exemptIncomeFromRentInvestment
} = storeToRefs(taxCalculator);
const openCloseInput = async (id, type, value) => {
  expense.value = expense.value.map((item) => {
    if (item.id === id) {
      if (type === "amount") return { ...item, showAmountInput: value };
      else if (type === "amount_calculated_fields")
        return { ...item, showAmountCalculatedInput: value };
    }
    return item;
  });
  if (value) {
    const input = await document.getElementById(`input-${type}-${id}`);
    input.focus();
  }
};
const handleTab = async (e, id) => {
  e.preventDefault();
  await openCloseInput(id, "amount", false); // close current field
  const nextItem =
    expense.value[expense.value.map((e) => e.id).indexOf(id) + 1]; // find next item

  if (nextItem) {
    await openCloseInput(nextItem?.id, "amount", true); // open next field
  }
};
const checkAmount = (e, id) => {
  let value = "";
  const index = e.target.selectionStart;
  const prevValue = e.target.value;
  const currentKey = String.fromCharCode(e.keyCode);
  value = insertChar(prevValue, currentKey, index);
  if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(value)) {
    e.preventDefault();
    return;
  }
  if (id === "total_profits_for_the_year") {
    if (Number(value) > netReceiptsDuringTheYearAmount.value) {
      e.preventDefault();
      e.target.blur();
      props.setAlert(
        true,
        "Total Profits for the Year amount can not exceed Net Receipts during the year amount"
      );
    }
  } else if (id === "exempt_income_from_rent_investment") {
    const receipt = receipts.value.find(
      (val) => val.id === "revenue_from_rent_investment_salary_wages"
    );
    if (Number(value) > Number(receipt.amount)) {
      e.preventDefault();
      e.target.blur();
      props.setAlert(
        true,
        "Exempt Income-Income from Rent, Investment, Salary & Wages cannot exceed Revenue from Rent, Investment, Salary & Wages"
      );
    }
  }
};
</script>

<style scoped>
.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border-bottom-width: unset !important;
}
.table-header {
  min-height: 39px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.table-rows {
  background: #ffffff;
  padding-top: 19px;
  padding-bottom: 19px;
}
.table-rows.border {
  border-bottom: 1px solid #747171 !important;
  border-top: 1px solid #747171 !important;
}
.yes-text {
  font-style: normal;
  font-weight: 400;
  font-size: 11.5235px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: #000000;
}
.empty {
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.5px solid rgba(149, 146, 146, 0.3);
}
.cursor-pointer {
  cursor: pointer;
}
.data-img {
  width: 110px;
  height: 110px;
  object-fit: cover;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
tbody:last-child {
  border: 2.5px solid #fff;
  border-bottom: 3px solid rgba(149, 146, 146, 0.3);
}
.custom-input {
  text-align: left !important;
  border: none;
  border-bottom: 1px solid#000000;
  padding-bottom: 1px;
  padding-left: 4px;
  height: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input:focus {
  outline: 0;
  border-bottom: 1px solid#0f75bc;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-left: 35px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.table-total {
  background: #0f75bc;
}
.total-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}

.table-rows2 {
  background: #ffffff;
  padding-top: 14px;
  padding-bottom: 14px;
}
.table-rows2:hover {
  background: #2a9ed8;
 
}
.table-rows2.border {
  border-bottom: 1px solid #747171 !important;
  border-top: 1px solid #747171!important;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 30;
}
@media screen and (max-width: 600px) {
  .flex-1 {
    flex: 3;
  }
  .custom-input {
    width: 100%;
  }
}
</style>

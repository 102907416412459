import { onlyAcceptAlphabets } from "../../helpers/validation";

export const addNewUserInputProps = {
  first_name: {
    label: "First Name",
    placeholder: "Enter First  Name",
    onkeypress: (e) => onlyAcceptAlphabets(e)
  },
  last_name: {
    label: "Last Name",
    placeholder: "Enter Last  Name",
    onkeypress: (e) => onlyAcceptAlphabets(e)
  },
  email: {
    label: "Email",
    type: "email",
    placeholder: "Enter Email"
  },
  mobile_code: {
    label: "Mobile code",
    placeholder: "Enter Mobile Code"
  },
  country_code: {
    label: "Country code",
    placeholder: "Enter Country Code"
  },
  mobile_no: {
    label: "Mobile Number",
    placeholder: "Mobile Number"
  },
  password: {
    label: "Password",
    placeholder: "Enter Password",
    type: "password",
    autocomplete: "new-password"
  },
  confirm_password: {
    label: "Confirm password",
    placeholder: "Confirm Your Password",
    type: "password",
    autocomplete: "new-password",
    onpaste: (e) => e.preventDefault()
  }
};

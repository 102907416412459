<template>
  <div class="w-100">
    <div class="header d-flex align-items-center w-100 justify-content-between">
      <p class="header-title mb-0">Derivatve financial instruments</p>
      <p class="header-title mb-0">
        {{
          NumberFormatter(totalCurrentAssets.derivative_financial_instruments)
        }}
      </p>
    </div>
    <form
      v-for="(item, index) in data"
      :id="item.model"
      :key="item.id"
      :class="`w-100 table-body ${!index || !(index % 2) ? 'white' : 'gray'} ${
        item.sub ? 'sub' : ''
      } d-flex align-items-center justify-content-between`"
      @submit="handleSubmit"
    >
      <p class="mb-0 body-title">
        {{ item.name }}
      </p>
      <p
        v-if="!item.showInput"
        :class="`body-title ${isHeader(item.model) ? 'bold' : ''} mb-0`"
        @click="openCloseInput(item.model, true)"
      >
        {{ NumberFormatter(currentAssetData[item.model]) }}
      </p>

      <input
        :id="'input-' + item.model"
        v-model="currentAssetData[item.model]"
        :hidden="!item.showInput"
        type="number"
        class="clickable-text"
        @focus="setOriginalValue(item.model, currentAssetData[item.model])"
        @blur="handleBlur(item.model, false)"
        @keydown.tab="(e) => handleTab(e, item.model)"
      />
    </form>
  </div>
</template>

<script setup>
import { generate } from "randomized-string";
import { ref, computed } from "vue";

import { storeToRefs } from "pinia";

import { useFinancialDataStore } from "../../../../../../store/financial.store";
import { NumberFormatter } from "../../../../../../helpers/numberFormatter";
import { useRoute, useRouter } from "vue-router";
import { autoSaveCurrentAssets } from "../helper";
const props = defineProps({
  view: {
    type: Boolean,
    default: false,
  },
  setSavingStatus: {
    type: Function,
    default: () => {},
  },
});
const route = useRoute();
const router = useRouter();
const financialDataStore = useFinancialDataStore();
const { currentAssetData, totalCurrentAssets } =
  storeToRefs(financialDataStore); //get initialvalue from store

const data = ref([
  {
    id: generate(),
    name: "Derivatives held for hedging",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "drtv_fncl_intmts_drtvs_hld_fr_hdg",
  },
  {
    id: generate(),
    name: "Cash-Flow Hedges",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "drtv_fncl_intmts_drtvs_hld_fr_hdg_csh_flw_hdgs",
  },
  {
    id: generate(),
    name: "Interest Rate Swaps",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "drtv_fncl_intmts_drtvs_hld_fr_hdg_csh_flw_hdgs_intrst_rt_swps",
  },
  {
    id: generate(),
    name: "Currency Forwards",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "drtv_fncl_intmts_drtvs_hld_fr_hdg_csh_flw_hdgs_crncy_fwrd",
  },
  {
    id: generate(),
    name: "Fair Value Hedge",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "drtv_fncl_intmts_drtvs_hld_fr_hdg_fair_value_hedge",
  },
  {
    id: generate(),
    name: "Currency Forwards",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "drtv_fncl_intmts_drtvs_hld_fr_hdg_fair_value_hedge_crncy_fwrd",
  },
  {
    id: generate(),
    name: "Derivatives not held for hedging",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "drvtv_fncl_instmts_drvtvs_nt_hld_fr_hdgg",
  },
  {
    id: generate(),
    name: "Currency Forwards ",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "drvtv_fncl_instmts_drvtvs_nt_hld_fr_hdgg_crncy_fwrd",
  },
]);

const getValue = (model) => {
  return Number(currentAssetData.value[model]) || 0;
};
// Cash-Flow Hedges
currentAssetData.value.drtv_fncl_intmts_drtvs_hld_fr_hdg_csh_flw_hdgs =
  computed(() => {
    const keys = [
      "drtv_fncl_intmts_drtvs_hld_fr_hdg_csh_flw_hdgs_intrst_rt_swps",
      "drtv_fncl_intmts_drtvs_hld_fr_hdg_csh_flw_hdgs_crncy_fwrd",
    ];
    return keys.reduce((a, b) => a + getValue(b), 0);
  });
// Fair Value Hedge
currentAssetData.value.drtv_fncl_intmts_drtvs_hld_fr_hdg_fair_value_hedge =
  computed(() => {
    const keys = [
      "drtv_fncl_intmts_drtvs_hld_fr_hdg_fair_value_hedge_crncy_fwrd",
    ];
    return keys.reduce((a, b) => a + getValue(b), 0);
  });

// Derivatives held for hedging
currentAssetData.value.drtv_fncl_intmts_drtvs_hld_fr_hdg = computed(() => {
  const keys = [
    "drtv_fncl_intmts_drtvs_hld_fr_hdg_csh_flw_hdgs",
    "drtv_fncl_intmts_drtvs_hld_fr_hdg_fair_value_hedge",
  ];
  return keys.reduce((a, b) => a + getValue(b), 0);
});
// Derivatives not held for hedging
currentAssetData.value.drvtv_fncl_instmts_drvtvs_nt_hld_fr_hdgg = computed(
  () => {
    const keys = ["drvtv_fncl_instmts_drvtvs_nt_hld_fr_hdgg_crncy_fwrd"];
    return keys.reduce((a, b) => a + getValue(b), 0);
  }
);

const isHeader = (key) => {
  const keys = [
    "drtv_fncl_intmts_drtvs_hld_fr_hdg",
    "drtv_fncl_intmts_drtvs_hld_fr_hdg_csh_flw_hdgs",
    "drtv_fncl_intmts_drtvs_hld_fr_hdg_fair_value_hedge",
    "drvtv_fncl_instmts_drvtvs_nt_hld_fr_hdgg",
  ];
  return !!keys.find((itemKeys) => itemKeys === key);
};
totalCurrentAssets.value.derivative_financial_instruments = computed(() => {
  return (
    getValue("drtv_fncl_intmts_drtvs_hld_fr_hdg") +
    getValue("drvtv_fncl_instmts_drvtvs_nt_hld_fr_hdgg")
  );
});
const openCloseInput = async (model, value) => {
  if (props.view || route.query.business === "master" || isHeader(model)) {
    return;
  }
  data.value = data.value.map((item) => {
    if (item.model === model) {
      return { ...item, showInput: value };
    }
    return item;
  });
  if (value) {
    const input = await document.getElementById(`input-${model}`);
    input.focus();
  }
};
const handleSubmit = async (e) => {
  e.preventDefault();
  let current_model = e.target.id;
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
const handleTab = async (e, current_model) => {
  e.preventDefault();
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
const autoSave = async (model) => {
  const previousValue = data.value.find((item) => item.model === model).value;
  const currentValue = currentAssetData.value[model];
  await autoSaveCurrentAssets(
    previousValue,
    currentValue,
    props.setSavingStatus,
    route,
    router
  );
};
const handleBlur = async (model, openClose) => {
  await openCloseInput(model, openClose);
  await autoSave(model);
};
const setOriginalValue = (key, value) => {
  const index = data.value.findIndex((item) => item.model === key);
  data.value[index].value = value;
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}
</style>

<template>
  <LoaderContainer
    :loading="mySubscription.loading || subscriptions.loading"
    :error="mySubscription.error || subscriptions.error"
  >
    <div class="row">
      <div class="col-lg-7">
        <h3 class="title mt-3">Active Subscription</h3>
        <ActiveSubscription />
        <PaymentUsed />
        <BillingInfo />
      </div>
      <div class="col-lg-5">
        <h3 class="title mt-3">Modify Plan For You</h3>
        <ModifyPlan />
      </div>
    </div>
  </LoaderContainer>
</template>

<script setup>
import ActiveSubscription from "./ActivePlan/ActiveSubscription.vue";
import BillingInfo from "./ActivePlan/BillingInfo.vue";
import PaymentUsed from "./ActivePlan/PaymentUsed.vue";
import ModifyPlan from "./ActivePlan/ModifyPlan.vue";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";
import { useSubscriptionStore } from "../../store/subscription";
import LoaderContainer from "../common/LoaderContainer.vue";
import { useRouter } from "vue-router";
import { usePaymentStore } from "../../store/payment.store";

const subscriptionStore = useSubscriptionStore();
const { mySubscription, subscriptions } = storeToRefs(subscriptionStore);
const router = useRouter();
const paymentStore = usePaymentStore();
const { paymentOrder } = storeToRefs(paymentStore);
onMounted(async () => {
  if (!mySubscription.value.data) {
    await subscriptionStore.getMyPlan();
  }
  if (!mySubscription.value.data) {
    router.push(
      "/subscription/choose-subscription-type?redirect_tab=subscription"
    );
  }
  await subscriptionStore.getSubscriptions();
});
</script>

<style scoped>
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

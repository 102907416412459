<template>
  <v-dialog v-model="toggle" width="auto" content-class="my-custom-dialog">
    <div class="card-container">
      <div class="d-flex w-100 justify-content-end">
        <img
          src="@/assets/svg/close-circle.svg"
          class="cursor-pointer"
          alt=""
          @click="closeModal()"
        />
      </div>
      <h4 class="bold title mt-4">Plans on {{ day }}</h4>

      <div class="w-100 d-flex align-items-center justify-content-end">
        <button
          v-if="durationDays >= 0"
          class="save-button mr-3"
          @click="addNew"
        >
          <img src="@/assets/svg/plus-square-light.svg" alt="" />
          Add New
        </button>
      </div>
      <div class="w-100 table-responsive mt-4 mb-0">
        <table class="table custom-table mb-0 table-bordered table-striped">
          <thead>
            <tr>
              <th class="table-header">Sl No.</th>
              <th class="table-header">Entity</th>
              <th class="table-header">Filing Type</th>
              <th class="table-header">Notification Date</th>
              <th class="table-header">Remove</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filingPlans" :key="item.entity_id">
              <td class="table-rows align-middle">
                <p class="data-text text-center mb-0">{{ index + 1 }}</p>
              </td>
              <td class="table-rows align-middle">
                <select
                  v-model="filingPlans[index].entity_id"
                  class="date-select pl-2 mt-2 mr-3"
                  @input="
                    (e) => handleEntitySelect(e.target.value, index, true)
                  "
                >
                  <option
                    v-for="entity in Entities"
                    :key="entity.id"
                    class="option"
                    :value="entity.id"
                  >
                    {{ entity.entity_name }}
                  </option>
                </select>
              </td>

              <td class="table-rows align-middle">
                <select
                  v-model="filingPlans[index].filing_type"
                  class="date-select pl-2 mt-2 mr-3"
                >
                  <option
                    v-for="year in filingType.data"
                    :key="year.id"
                    class="option"
                    :value="year.id"
                  >
                    {{ year.name }}
                  </option>
                </select>
              </td>
              <td class="table-rows align-middle">
                <input
                  v-model="filingPlans[index].notification_start_date"
                  type="date"
                  class="custom-input"
                  :max="selectedDate"
                  :min="dateNow()"
                />
              </td>
              <button class="delete-button w-100" @click="deletePlan(item.id)">
                <img src="@/assets/svg/trash.svg" alt="" />
              </button>
            </tr>
            <tr v-if="!filingPlans.length">
              <td class="table-rows align-middle" :colSpan="6">
                <p class="data-text text-center mb-0 w-100">
                  <b>No Plans registered in this date</b>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="durationDays >= 0"
        class="w-100 d-flex gray py-3 px-4 pr-4 align-items-center justify-content-between"
      >
        <div class="">
          <p
            v-if="statusMessage.message"
            :class="`data-text text-center mb-0 w-100 text-${statusMessage.type}`"
          >
            {{ statusMessage.message }}
          </p>
        </div>
        <button class="save-button mr-3" @click="saveData">Save</button>
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import { onMounted } from "vue";
import { ref, watch } from "vue";
import { useFilingPlannerStore } from "../../../store/filingPlanner";
import { storeToRefs } from "pinia";
import { useEntityStore } from "../../../store/entity";
import { generate } from "randomized-string";
import { getNumberOfDaysBetweenDates, dateNow } from "../../../helpers/date";

const props = defineProps({
  selectedDate: {
    type: String,
    required: true,
  },
  open: {
    type: Boolean,
    required: true,
  },
  setOpen: {
    type: Function,
    required: true,
  },
  day: {
    type: String,
    required: true,
  },
});
const fillingPlannerStore = useFilingPlannerStore();
const { filingPlanner, filingType } = storeToRefs(fillingPlannerStore);

const entityStore = useEntityStore();
const { entities } = storeToRefs(entityStore);
const statusMessage = ref({
  message: "",
  type: "info",
});

const Entities = ref([]);
const toggle = ref(props.open);
const filingPlans = ref([]);
// const financialYears = ref({});
const durationDays = ref(0);
const closeModal = () => {
  toggle.value = false;
  props.setOpen(false);
};

const handleEntitySelect = async (
  entity_id,
  index,
  checkPreviousSelection = false
) => {
  try {
    if (checkPreviousSelection) {
      const selectedPlan = filingPlans.value[index];
      const find = filingPlans.value.find(
        (item) => item?.entity_id == entity_id
      );
      if (!!find && selectedPlan?.entity_id != entity_id) {
        alert("Entity Previous  selected");
        setTimeout(() => {
          filingPlans.value[index] = {
            id: generate(),
            entity_id: "",
            financial_period: "",
            financial_year: "",
            filing_date: "",
            filing_type: "",
            notification_start_date: "",
          };
        }, 1000);
        return;
      }
    }
    const financial_period = Entities.value.find(
      (item) => item?.id == entity_id
    )?.financial_period;
    if (financial_period) {
      filingPlans.value[index].financial_period = financial_period;
      //   const res = await fetchWrapper.get(`/financialYear/${financial_period}`);
      //   if (res.data.result == 1) {
      //     financialYears.value[`entity-${entity_id}`] = res.data.data;
      //   }
    } else {
      filingPlans.value[index].financial_period = financial_period;
      filingPlans.value[index].financial_year = "";
    }
  } catch (err) {
    console.log(err);
  }
};
watch(
  () => props.open,
  (newValue) => {
    toggle.value = newValue;
  }
);
const setData = async (value) => {
  const plans = value?.filter(
    (item) => item?.filing_date == props.selectedDate
  );

  filingPlans.value = plans.map((plan) => ({ ...plan, id: generate() }));
  for (let index = 0; index < plans.length; index++) {
    let entity = plans[index];
    await handleEntitySelect(entity?.entity_id, index);
  }
};

const setEntitiesData = (value) => {
  const Data = [];
  for (let i = 0; i < value.length; i++) {
    const entity = value[i]?.entity[0];
    const entity_name =
      entity?.entity_type == 2
        ? `${entity?.entity_first_name} ${entity?.entity_last_name}`
        : entity?.entity_name;
    const item = {
      id: String(entity?.id),
      entity_name,
      financial_period: entity.financial_period?.id,
    };

    Data.push(item);
  }
  Entities.value = Data;
};
onMounted(async () => {
  durationDays.value = getNumberOfDaysBetweenDates(
    dateNow(),
    props.selectedDate
  );
  await setEntitiesData(entities.value.data);
  setData(filingPlanner.value.data);
});
watch(
  () => filingPlanner.value.data,
  (newValue) => {
    setData(newValue);
  },
  { deep: true }
);
watch(
  () => props.selectedDate,
  async (newValue) => {
    durationDays.value = getNumberOfDaysBetweenDates(dateNow(), newValue);
    await setEntitiesData(entities.value.data);
    setData(filingPlanner.value.data);
  },
  { deep: true }
);
watch(
  () => entities.value.data,
  (newValue) => {
    setEntitiesData(newValue);
  },
  { deep: true }
);
const addNew = () => {
  filingPlans.value = [
    ...filingPlans.value,
    {
      id: generate(),
      entity_id: "",
      financial_period: "",
      financial_year: "",
      filing_date: "",
      filing_type: "",
      notification_start_date: "",
    },
  ];
};
const deletePlan = (id) => {
  filingPlans.value = filingPlans.value.filter((item) => item?.id != id);
};
const saveData = async () => {
  await fillingPlannerStore.saveFilePlaned(
    filingPlans.value,
    props.selectedDate,
    async () => {
      toggle.value = false;
      await fillingPlannerStore.getFilingPlanner();
      props.setOpen(false);
    }
  );
};
watch(toggle, (newValue) => {
  props.setOpen(newValue);
});
</script>

<style scoped>
.card-container {
  background: #fafbff;
  border-radius: 5px;
  padding: 33px 27px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.card-container::-webkit-scrollbar {
  display: none;
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /* identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
}
.table-header {
  min-height: 39px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2.5px solid #fff;
}
.table-rows {
  background: #ffffff;
  border: 2.5px solid #fff;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input {
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input:focus {
  outline: none;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.delete-button {
  width: 50px;
  height: 82px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.gray {
  background: #deeded;
}
.date-select {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  width: 212px;
  height: 40px;
}
</style>

<template>
  <v-dialog
    width="auto"
    v-model="toggle"
    content-class="my-custom-dialog"
    persistent
  >
    <div class="card-container">
      <div class="d-flex w-100 justify-content-end">
        <img
          src="@/assets/svg/close-circle.svg"
          class="cursor-pointer"
          alt=""
          @click="closeModal()"
        />
      </div>
      <h4 class="title">
        7.) Entertainment Expense disallowed as per (Article-32)-50% of total
        entertainment expense
      </h4>
      <div
        class="gray my-4 py-3 pl-3 w-100 d-flex align-items-center justify-content-center"
      >
        <h4 class="bold title mt-4 mb-0">
          {{ chapter9.item_7_info }}
        </h4>
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import { ref, watch } from "vue";
import { useComputeIncomeTaxStore } from "../../../../store/incometax.store";
import { storeToRefs } from "pinia";

const props = defineProps({
  open: Boolean,
  setOpen: Function,
});

const toggle = ref(props.open);
watch(
  () => props.open,
  () => {
    toggle.value = props.open;
  }
);
const computeIncomeTaxStore = useComputeIncomeTaxStore();
const { chapter9 } = storeToRefs(computeIncomeTaxStore);

const closeModal = () => {
  toggle.value = false;
  props.setOpen(false);
};
watch(toggle, (newValue) => props.setOpen(newValue));
</script>

<style scoped>
.card-container {
  max-width: 400px;
  background: #fafbff;
  border-radius: 5px;
  padding: 33px 27px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.card-container::-webkit-scrollbar {
  display: none;
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /* identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.gray {
  background: #deeded;
}
</style>

import { useNoneIFRSBalanceSheet } from "../../../../../store/NonIFRS/balancesheet.store";
import { useNoneIFRSProfitLoss } from "../../../../../store/NonIFRS/profitLoss.store";
import { useBusinessStore } from "../../../../../store/buisness.store";

export const autoSaveNoneIFRSBS = async (setSavingStatus, route, router) => {
  const noneIfrsBSStore = useNoneIFRSBalanceSheet();
  const businessStore = useBusinessStore();

  setSavingStatus("loading", true);

  await noneIfrsBSStore.autoSaveBalanceSheet(
    route,
    setSavingStatus,
    async (business) => {
      if (!route.query?.business) {
        await businessStore.getBusiness(
          route.params.id,
          route.query.financial_year,
          route.query.financial_period
        );
        router.push({
          path: location.pathname,
          query: {
            ...route.query,
            business,
          },
        });
      }
      setTimeout(() => {
        setSavingStatus("loading", false);
        setSavingStatus("error", false);
        setSavingStatus("success", false);
      }, 3000);
    }
  );
};

export const autoSaveNoneIFRSPL = async (setSavingStatus, route, router) => {
  const noneIfrsPLStore = useNoneIFRSProfitLoss();
  const businessStore = useBusinessStore();
  setSavingStatus("loading", true);

  await noneIfrsPLStore.autoSaveProfitLoss(
    route,
    setSavingStatus,
    async (business) => {
      if (!route.query?.business) {
        await businessStore.getBusiness(
          route.params.id,
          route.query.financial_year,
          route.query.financial_period
        );
        router.push({
          path: location.pathname,
          query: {
            ...route.query,
            business,
          },
        });
      }
      setTimeout(() => {
        setSavingStatus("loading", false);
        setSavingStatus("error", false);
        setSavingStatus("success", false);
      }, 3000);
    }
  );
};

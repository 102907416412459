<template>
  <div class="col-lg-6">
    <div
      v-if="$route.query.current_business !== 'master'"
      class="header d-flex align-items-center w-100 justify-content-between"
    >
      <p class="header-title mb-0">Deduction from Taxable Income</p>
      <p class="header-title mb-0">Amount</p>
    </div>
    <EditableSheet
      v-if="$route.query.current_business !== 'master'"
      v-model="deduction"
    />
    <div
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 total-amount">Total Additions</p>
      <div class="d-flex align-items-center">
        <p class="mb-0 total-amount mr-3">
          {{ NumberFormatter(total) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useOtherAdjustmentStore } from "../../../store/other-adjustment";
import { storeToRefs } from "pinia";
import { EditableSheet } from "../../common/";
import { NumberFormatter } from "../../../helpers/numberFormatter";
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const otherAdjustmentStore = useOtherAdjustmentStore();
const { deduction, totalDeduction, masterData } =
  storeToRefs(otherAdjustmentStore);
const total = computed(() => {
  if (route.query.current_business === "master") {
    return Number(masterData.value.total_deduction || 0);
  }
  return totalDeduction.value;
});
</script>

<style scoped>
.header {
  height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: white;
}
.table-body {
  min-height: 80px;
  padding-right: 100px;
  padding-left: 26px;
  gap: 30px;
}
.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
  overflow: hidden;
}
.total-container {
  height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}
.total-desc {
  background: #deeded;
}
.reference {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.article-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px; /*
  identical to box height, or 185% */
  letter-spacing: 0.055em;
  color: #000000;
}
.article {
  border-top: 1px solid #747171;
  border-bottom: 1px solid #747171;
}
.btn-submit {
  width: 82px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
.article-desc {
  white-space: pre-wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px; /* or
  185% */
  letter-spacing: 0.055em;
  color: #747171;
}
@media screen and (max-width: 600px) {
  .header {
    padding-right: 33px !important;
  }
}
</style>

<template>
  <SideNavContainer
    side
    title="Entity Master Data"
    sideNavType="financialData"
    :subtitle="name"
  >
    <LoaderContainer :loading="loading">
      <EntityMasterDataBreadCrumb />
      <UploadContainer
        v-if="activeModal.modal"
        :name="activeModal.name"
        :open="activeModal.modal"
        :type="activeModal.model"
        :set-open="activateModal"
        :call-back="reload"
      />
      <div class="row mt-4">
        <ShowStatus :modal="editStatus" :set-modal="setStatus" />

        <div class="col-lg-12">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <h4 class="title">Entity Details</h4>
            <div
              class="gray-button d-flex align-items-center"
              @click="editEntity('')"
            >
              <img src="@/assets/svg/table-edit.svg" alt="" class="mb-0 mr-2" />
              <p class="mb-0 edit-text mb-0">Edit Entity</p>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <EntityDetails />
        </div>
        <div class="col-lg-12">
          <h4 class="title">License Details</h4>
          <LicenseDetails />
        </div>
        <div class="col-lg-12">
          <h4 class="title">Tax Details</h4>
          <TaxDetails />
        </div>

        <div class="col-lg-12">
          <h4 class="title">Business Activity Details</h4>
          <BusinessActivityDetails />
        </div>

        <div class="col-lg-12 mt-4">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <h4 class="title">Contact Details</h4>
            <div class="d-flex align-items-center">
              <div
                class="d-flex gray-button cursor-pointer mr-2"
                @click="
                  $router.push(
                    `/entity/add-entity/contact-details/${$route.params.id}`
                  )
                "
              >
                <img src="@/assets/svg/add-new.svg" alt="" class="mb-0 mr-2" />
                <p class="edit-text mb-0">Add Contact</p>
              </div>
              <div
                class="mr-2 gray-button cursor-pointer edit-text"
                @click="activateModal(true, 'contact_details')"
              >
                &#128206; Upload Contact Details
              </div>
            </div>
          </div>
          <ContactDetails />
        </div>

        <div class="col-lg-12 mt-4">
          <div
            v-if="!isNaturalPerson"
            class="w-100 d-flex align-items-center justify-content-between"
          >
            <h4 class="title">Ownership Details</h4>
            <div
              class="mr-2 gray-button cursor-pointer edit-text"
              @click="activateModal(true, 'ownership_details')"
            >
              &#128206; Upload Ownership Details
            </div>
          </div>
        </div>
        <div v-if="!isNaturalPerson" class="col-lg-12 mt-2">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <h4 class="title">Manager Details</h4>
            <div
              class="gray-button d-flex align-items-center"
              @click="
                editEntity(
                  `/entity/add-entity/ownership-details/${$route.params.id}`
                )
              "
            >
              <img src="@/assets/svg/add-new.svg" alt="" class="mb-0 mr-2" />
              <p class="edit-text mb-0">Add Manager Details</p>
            </div>
          </div>
        </div>
      </div>
      <OwnerShipDetails v-if="!isNaturalPerson" />
      <div class="row">
        <div v-if="!isNaturalPerson" class="col-lg-12">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <h4 class="title">List of Directors</h4>
            <div
              class="gray-button d-flex align-items-center"
              @click="handleTab('director-details')"
            >
              <img src="@/assets/svg/add-new.svg" alt="" class="mb-0 mr-2" />
              <p class="edit-text mb-0">Add Director</p>
            </div>
          </div>
          <ListOfDirectors />
        </div>
        <div v-if="!isNaturalPerson" class="col-lg-12 mt-4">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <h4 class="title">List of Shareholders</h4>
            <div
              class="gray-button d-flex align-items-center"
              @click="handleTab('shareholder-details')"
            >
              <img src="@/assets/svg/add-new.svg" alt="" class="mb-0 mr-2" />
              <p class="mb-0 edit-text">Add Shareholder</p>
            </div>
          </div>
          <ListShareHolder />
        </div>
        <div class="col-lg-12 mt-4">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <h4 class="title">Banking Details</h4>
            <div class="d-flex align-items-center">
              <div
                class="d-flex gray-button align-items-center cursor-pointer mr-2"
                @click="
                  $router.push(
                    `/entity/add-entity/banking-details/${$route.params.id}`
                  )
                "
              >
                <img src="@/assets/svg/plus-square-light.svg" alt="" />
                <p class="mb-0 edit-text">Add bank</p>
              </div>
              <div
                class="mr-2 gray-button cursor-pointer edit-text"
                @click="activateModal(true, 'bank_details')"
              >
                &#128206; Upload Bank Details
              </div>
            </div>
          </div>
          <BankingDetails />
        </div>
        <div v-if="hasBranch && !isNaturalPerson" class="col-lg-12">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <h4 class="title mb-0">Branch Owner List</h4>
            <div class="d-flex align-items-center">
              <div
                class="d-flex gray-button align-items-center mr-2 cursor-pointer"
                @click="
                  $router.push(
                    `/entity/add-entity/branch-details/${$route.params.id}`
                  )
                "
              >
                <img src="@/assets/svg/add-new.svg" alt="" class="mb-0 mr-1" />
                <p class="edit-text mb-0">Add Branch</p>
              </div>
              <div
                class="mr-2 gray-button cursor-pointer edit-text"
                @click="activateModal(true, 'branch_details')"
              >
                &#128206; Upload Branch Details
              </div>
            </div>
          </div>
          <BranchDetails />
        </div>

        <div v-if="!hasBranch && !isNaturalPerson" class="col-lg-12 mt-4">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <h4 class="title">Branch Details</h4>
          </div>
          <BranchNoneDetails />
        </div>

        <div v-if="!hasBranch && !isNaturalPerson" class="col-lg-12">
          <h4 class="title mb-0">Authorized Signatory Details</h4>
          <AuthorizedSignatoryDetails />
        </div>
      </div>
    </LoaderContainer>
  </SideNavContainer>
</template>

<script>
import { SideNavContainer } from "@/components/layout";
import EntityDetails from "./EntityDetails.vue";
import LicenseDetails from "./LicenseDetails.vue";
import TaxDetails from "./TaxDetails.vue";
import BranchDetails from "./BranchDetails.vue";
import OwnerList from "./OwnerList.vue";
import OwnerShipDetails from "./OwnerShipDetails.vue";
import ListOfDirectors from "./ListOfDirectors.vue";
import ListShareHolder from "./LIstShareHolder.vue";
import BankingDetails from "./BankingDetails.vue";
import ContactDetails from "./ContactDetails.vue";
import SubscriptionChecker from "../../../subscription/SubscriptionChecker.vue";
import { getEntityItem } from "../../../../store/entityActions/get";
import { useEntityStore } from "../../../../store/entity";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { ref, watch, computed } from "vue";
import LoaderContainer from "../../../common/LoaderContainer.vue";
import { EntityMasterDataBreadCrumb } from "../../../BreadCrumbs";
import UploadContainer from "../../AddEntity/uploadEntity/UploadContainer.vue";
import BranchNoneDetails from "./BranchNoneDetails.vue";
import ShowStatus from "./ShowStatus.vue";
import BusinessActivityDetails from "./BusinessActivityDetails.vue";
import AuthorizedSignatoryDetails from "./AuthorizedSignatoryDetails.vue";
export default {
  name: "MasterDataList",
  components: {
    SideNavContainer,
    BankingDetails,
    ListShareHolder,
    EntityDetails,
    LicenseDetails,
    TaxDetails,
    BranchDetails,
    OwnerList,
    OwnerShipDetails,
    ListOfDirectors,
    ContactDetails,
    SubscriptionChecker,
    LoaderContainer,
    EntityMasterDataBreadCrumb,
    UploadContainer,
    BranchNoneDetails,
    ShowStatus,
    BusinessActivityDetails,
    AuthorizedSignatoryDetails,
  },
  setup() {
    const entityStore = useEntityStore();
    const { entity, ownershipDetails } = storeToRefs(entityStore);
    const route = useRoute();
    const router = useRouter();

    const loading = ref(true);
    const activeModal = ref({ modal: false, name: "", model: "" });
    const editStatus = ref(false);
    const setStatus = (status) => {
      editStatus.value = status;
    };
    const modalAndName = ref({
      contact_details: {
        modal: false,
        name: "contact",
        model: "contact_details",
      },
      ownership_details: {
        modal: false,
        name: "ownership",
        model: "ownership_details",
      },
      bank_details: { modal: false, name: "bank", model: "bank_details" },
      branch_details: { modal: false, name: "branch", model: "branch_details" },
    });
    const activateModal = (value, model) => {
      if (!model) {
        if (activeModal.value.model) {
          activeModal.value.modal = value;
        }
        return;
      }
      modalAndName.value[model].modal = value;
      activeModal.value = modalAndName.value[model];
    };
    const reload = () => {
      window.location.reload();
    };
    const handleTab = async (tab) => {
      const id = route.params.id;
      await getEntityItem("ownershipDetails", "/entityOwnerDetails", id);
      let owner_id = ownershipDetails.value.data[0]?.id;
      router.push(`/entity/add-entity/${tab}/${id}?owner_id=${owner_id}`);
    };

    const editEntity = (Push) => {
      if (entity.value?.data?.entity[0]?.file_return_status === "Filed") {
        setStatus(true);
        return;
      }
      if (!Push) {
        Push = `/entity/add-entity/entity-details/entity/${route.params.id}`;
      }
      router.push(Push);
    };
    const hasBranch = computed(() => {
      if (!entity.value.data) {
        return false;
      } else if (entity.value.data?.entity[0]?.has_branch === "Y") {
        return true;
      }
      return false;
    });
    const isNaturalPerson = computed(() => {
      if (!entity.value.data) {
        return false;
      } else if (entity.value.data?.entity[0]?.entity_type == 2) {
        return true;
      }
      return false;
    });
    return {
      entity,
      handleTab,
      loading,
      hasBranch,
      activeModal,
      reload,
      activateModal,
      modalAndName,
      editStatus,
      setStatus,
      editEntity,
      isNaturalPerson,
    };
  },
  data() {
    return { name: "" };
  },
  async created() {
    this.loading = true;
    const id = this.$route.params.id;
    await getEntityItem("entity", "/entity", id);
    this.name = this.entity.data
      ? this.entity.data?.entity
        ? this.entity.data?.entity[0].entity_name
        : ""
      : "";
    this.loading = false;
  },
};
</script>

<style scoped>
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.edit-text {
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #000000;
}
.gray-button {
  background-color: #deeded;
  padding: 10px;
}
</style>

<template>
  <SideNavContainer>
    <LoaderContainer
      :loading="mySubscription.loading || subscriptions.loading"
      :error="mySubscription.error || subscriptions.error"
    >
      <div class="row">
        <div class="col-lg-6">
          <div
            class="desc-container d-flex align-items-center justify-content-between"
          >
            <p class="mb-0 subscription-type">
              {{ mySubscription.data?.subscription?.name }}
            </p>
          </div>
          <div class="w-100">
            <div class="customer-info w-100">
              <div
                class="company-name py-2 d-flex align-items-center justify-content-between"
              >
                <h5 class="price-desc mb-0">
                  <b class="bold">
                    Company Name <span class="text-danger"> *</span>
                  </b>
                </h5>
                <div class="w-50 d-flex justify-content-end">
                  <input
                    ref="companyInput"
                    v-model="companyName"
                    type="text"
                    class="clickable-text borde-less w-100"
                    placeholder="Please enter company name"
                  />
                </div>
              </div>
              <p class="name">
                {{ profile.data?.name }}
              </p>
              <p class="phone mb-0">
                {{ profile.data.mobile_no }}
              </p>
              <p class="email mb-0">
                {{ profile.data?.email }}
              </p>
            </div>
            <div
              class="desc-container d-flex align-items-center justify-content-between"
            >
              <p class="mb-0 subscription-type">Order Summary</p>
            </div>
            <div
              class="customer-info w-100 d-flex align-items-center justify-content-between"
            >
              <p class="name mb-0">Price per entity</p>
              <p class="name mb-0">
                {{ mySubscription.data?.price_per_entity }} AED
              </p>
            </div>
            <AddEntityInput :count="entityCount" :set-count="setEntityCount" />
            <div class="w-100 d-flex flex-column prices px-4">
              <div class="d-flex align-items-center justify-content-between">
                <p class="price-desc">SubTotal</p>
                <p class="price-desc">
                  AED
                  {{
                    NumberFormatter(
                      Number(mySubscription.data?.price_per_entity) *
                        Number(entityCount)
                    )
                  }}
                </p>
              </div>
              <VatRegistrationNumber :vat="vat" :set-vat="setVat" />
              <div
                v-if="subscriptions.is_vat_applicable === 'Y'"
                class="d-flex align-items-center justify-content-between"
              >
                <p class="price-desc">Vat</p>
                <p class="price-desc">
                  AED
                  {{
                    NumberFormatter(
                      Number(mySubscription.data?.price_per_entity) *
                        Number(entityCount) *
                        Number(subscriptions.vat)
                    )
                  }}
                </p>
              </div>
            </div>
            <div
              class="total mt-1 w-100 d-flex align-items-center justify-content-between px-4"
            >
              <p class="name">Total</p>
              <p class="name">
                AED
                {{
                  NumberFormatter(
                    Number(mySubscription.data?.price_per_entity) *
                      Number(entityCount) *
                      Number(
                        subscriptions.is_vat_applicable === "Y"
                          ? subscriptions.vat
                          : 0
                      ) +
                      Number(mySubscription.data?.price_per_entity) *
                        Number(entityCount)
                  )
                }}
              </p>
            </div>
            <div class="mt-4 px-4">
              <MakePaymentContainer
                :price="
                  Number(mySubscription.data?.price_per_entity) *
                    Number(entityCount) *
                    Number(
                      subscriptions.is_vat_applicable === 'Y'
                        ? subscriptions.vat
                        : 0
                    ) +
                  Number(mySubscription.data?.price_per_entity) *
                    Number(entityCount)
                "
                :subscription="mySubscription.data?.subscription_id"
                :is-additional="true"
                :entity-count="entityCount"
                :vat-number="vat"
                :company-name="companyName"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6"></div>
      </div>
    </LoaderContainer>
  </SideNavContainer>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useSubscriptionStore } from "../../../store/subscription";
import { LoaderContainer } from "../../common";
import { onMounted, ref, watch } from "vue";
import { useProfileStore } from "../../../store/profile";
import VatRegistrationNumber from "../choosePlan/VatRegistrationNumber.vue";
import AddEntityInput from "./AddEntityInput.vue";
import { NumberFormatter } from "../../../helpers/numberFormatter";
import MakePaymentContainer from "../Payment/MakePaymentContainer.vue";
import { SideNavContainer } from "../../layout";

const subscriptionStore = useSubscriptionStore();
const { mySubscription, subscriptions } = storeToRefs(subscriptionStore);
const entityCount = ref(1);
const vat = ref("");
const setVat = (value) => {
  vat.value = value;
};
const companyInput = ref("");

const companyName = ref("");
const setEntityCount = (value) => {
  entityCount.value = Number(value);
};
const profileStore = useProfileStore();
const { profile } = storeToRefs(profileStore);
onMounted(async () => {
  if (!mySubscription.value.data) {
    await subscriptionStore.getMyPlan();
  }
  if (!subscriptions.value.data.length) {
    await subscriptionStore.getSubscriptions();
  }
  if (companyInput.value) {
    await companyInput.value.focus();
  }
});
watch(companyInput, async (newVal) => {
  if (newVal) {
    await newVal.focus();
  }
});
</script>

<style scoped>
.desc-container {
  padding: 22px 10px 18px 10px;
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.subscription-type {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.customer-info {
  background: #deeded;
  box-shadow: 0px 8px 8px rgba(187, 187, 187, 0.25);
  border-radius: 11px;
  padding: 15px 15px 21px 15px;
  margin-top: 16px;
}
.name {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.phone {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #382628;
  margin-top: 9px;
}
.email {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #382628;
}
.prices {
  margin-top: 25px;
}
.price-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #382628;
}
.total {
  border-top: 1px solid rgba(116, 113, 113, 0.5);
  padding-top: 9px;
}
.btn-checkout {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
  width: 133px;
  height: 40px;
  background: #0f75bc;
  border-radius: 5px;
}
.company-name {
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.clickable-text::placeholder {
  text-align: right !important;
  color: red;
}
.clickable-text.borde-less {
  border: none !important;
}
</style>

<template>
  <div
    class="wrapper p-3 mt-4 d-flex flex-wrap align-items-center justify-content-between"
  >
    <div class="">
      <h4 class="payment">Payment</h4>
      <div class="d-flex align-items-center">
        <div class="success-green mr-2"></div>
        <p class="light-text mb-0">
          {{ mySubscription.data?.transactions?.status_text }}
        </p>
      </div>
    </div>
    <div
      class="d-flex payment-card p-2 align-items-center justify-content-between"
    >
      <p class="card-number mb-0">
        **** **** **** {{ mySubscription.data?.transactions?.last4 }}
      </p>
      <img
        v-if="mySubscription.data?.transactions?.card_type == 'Visa Credit'"
        src="@/assets/svg/visa-card.svg"
        alt=""
      />
      <img
        v-if="
          mySubscription.data?.transactions?.card_type == 'MasterCard Credit'
        "
        src="@/assets/svg/mc_symbol.svg"
        alt=""
      />
    </div>
    <div class="">
      <p class="light-text">Total Amount</p>
      <p class="total-amount">AED {{ mySubscription.data?.paid_amount }}</p>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useSubscriptionStore } from "../../../store/subscription";

const subscriptionStore = useSubscriptionStore();
const { mySubscription } = storeToRefs(subscriptionStore);
</script>

<style scoped>
.wrapper {
  min-height: 103px;
  background: #deeded;
  box-shadow: 0px 8px 8px rgba(187, 187, 187, 0.25);
  border-radius: 10px;
}
.payment {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.light-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #382628;
}
.success-green {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: #17eb45;
}
.payment-card {
  background: #deeded;
  border: 1px solid #747171;
  border-radius: 5px;
}
.card-number {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.055em;
  color: #111827;
}
</style>

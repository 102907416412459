/* eslint-disable camelcase */
import { defineStore } from "pinia";
import fetchWrapper from "../helpers/fetchWrapper";

export const useDahboardStore = defineStore("dashboard", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      loading: true,
      error: false,
      data: {
        entity_id: "",
        revenue_comparison: {
          2023: 0,
          2022: 0,
          comparison_percent: ""
        },
        revenue_profit: {
          current_year_revenue: 0,
          current_year_profit: 0
        },
        taxable_income_chart: {
          2018: 0,
          2019: 0,
          2020: 0,
          2021: 0,
          2022: 0,
          2023: 0
        },
        asset_liabilities_comparison: {
          assets: 0,
          liabilities: 0
        },
        marquee_text: []
      }
    };
  },
  actions: {
    async getDasboardItems(id) {
      try {
        this.loading = true;
        const res = await fetchWrapper.get(`/dashboard${id ? "/" + id : ""}`);
        if (res.data.result == 1) {
          this.data = {
            ...res.data?.data,
            entity_id: String(res.data?.data?.entity_id)
          };
          this.error = false;
          this.loading = false;
        } else {
          this.error = res.data?.error
            ? res.data?.error?.join(",")
            : res.data?.message;
          this.loading = false;
        }
      } catch (err) {
        console.log(err);
        this.error = err?.response?.data?.message || "";
        this.loading = false;
      }
    }
  }
});

<template>
  <div class="w-100">
    <div class="row gx-5 cal-box align-items-center px-4">
      <router-link to="/calculator/qfz-calculator" class="col-lg-4 my-3">
        <div class="box d-flex align-items-center justify-content-start">
          <img src="@/assets/images/calicon11.png" class="img" alt="" />
          <h4 class="title bold flex-3">FREEZONE PERSON CALCULATOR</h4>
        </div>
      </router-link>
      <router-link
        to="/calculator/ctax-calculator-business"
        class="col-lg-4 my-3"
      >
        <div class="box w-100 d-flex align-items-center justify-content-start">
          <img src="@/assets/images/caliicon22.png" class="img" alt="" />
          <h4 class="title bold flex-3">BUSINESS CORPORATE TAX CALCULATOR</h4>
        </div>
      </router-link>
      <router-link
        to="/calculator/ctax-calculator-individual"
        class="col-lg-4 my-3"
      >
        <div class="w-100 box d-flex align-items-center justify-content-start">
          <img src="@/assets/images/calicon32.png" class="img" alt="" />
          <h4 class="title bold flex-3">INDIVIDUAL CORPORATE TAX CALCULATOR</h4>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
.title-container {
  padding: 18px 20px;
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: white;
  margin-bottom: 0px;
}
.link-container {
  padding-left: 16px;
  padding-right: 16px;
}
.box {
  padding-left: 27px;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 5px;
  background: #2a9ed8;
  /*border: 1px solid rgba(116, 113, 113, 0.3);*/
  /*box-shadow: 0px 8px 8px rgba(187, 187, 187, 0.25);*/
  /* box-shadow: 3px 3px 10px rgba(0, 30, 87, 0.751);*/
  border-radius: 57px;
  height: 72px;

  overflow: hidden;
}
.box:hover {
  background-color: white;
  box-shadow: 3px 3px 10px rgba(0, 30, 87, 0.751);
}
.box:hover .title {
  color: #2a9ed8;
}
.flex-1 {
  flex: 1.5;
}
.flex-3 {
  flex: 3;
}
.img {
  height: auto;
  width: auto;
  margin-left: -13px;
}
.cal-box {
  width: 100%;
}
</style>

<template>
  <div class="w-100">
    <div
      v-if="!business.loading && !business.error && business.data?.length"
      class="cont w-100 d-flex align-items-center"
    >
      <DeleteBusiness
        :id="deleteModal.business_id"
        :open="deleteModal.open"
        :set-open="openCloseDeleteModal"
        :call-back-after-delete="callBackAfterDelete"
      />
      <div
        v-if="business.data.length > 1"
        class="wrapper py-3 d-flex mr-3 align-items-center"
        @click="pushToOtherBusiness('master')"
      >
        <h4 :class="`tab-title ${tab === 'master' ? 'active' : ''}  mb-0`">
          Group Data
        </h4>
        <img
          v-if="tab === 'master'"
          src="@/assets/svg/chevron-right-bold.svg"
          alt=""
        />
        <img v-else src="@/assets/svg/chevron-right-light.svg" alt="" />
      </div>
      <form
        v-for="(item, index) in business.data"
        :key="index"
        @submit="(e) => handleSubmit(e, item.id)"
        class="wrapper py-3 d-flex mr-3 align-items-center"
        @click="pushToOtherBusiness(item.id)"
      >
        <h4
          v-if="!item.showInput"
          :class="`tab-title ${item.id == tab ? 'active' : ''}  mb-0`"
          v-on:dblclick="openCloseInput(item.id, true, item.name)"
        >
          {{ item.name }}
        </h4>
        <input
          v-model="name"
          type="text"
          class="bus-input w-75"
          :hidden="!item.showInput"
          @focus="setName(item.name)"
          @blur="openCloseInput(item.id, false)"
        />
        <img
          src="@/assets/svg/trash.svg"
          alt=""
          :hidden="!item.showInput"
          @click="openCloseDeleteModal(true, item.id)"
        />
        <img
          v-if="item.id == tab"
          src="@/assets/svg/chevron-right-bold.svg"
          alt=""
        />
        <img v-else src="@/assets/svg/chevron-right-light.svg" alt="" />
      </form>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { ref, watch, onMounted, onUnmounted } from "vue";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import { useBusinessStore } from "../../../../../store/buisness.store";
import { useFinancialDataStore } from "../../../../../store/financial.store";
import DeleteBusiness from "../common/DeleteBusiness.vue";
import { useNoneIFRSBalanceSheet } from "../../../../../store/NonIFRS/balancesheet.store";
import { useIFRSStore } from "../../../../../store/NonIFRS/IFRS.store";
import { useStatusStore } from "../../../../../store/statusStore";
import { AlertModal } from "../../../../common";
import { useLiabilityDataStore } from "../../../../../store/liabities.store";

const businessStore = useBusinessStore();
const { business } = storeToRefs(businessStore);
const route = useRoute();
const tab = ref(route.query.business ? route.query.business : "");
const financialStore = useFinancialDataStore();
const nonIfrsBalanceSheetStore = useNoneIFRSBalanceSheet();
const liabilityDataStore = useLiabilityDataStore();

const ifrsStore = useIFRSStore();
const statusStore = useStatusStore();
const financial_year = ref(route.query.financial_year || "");

const financial_period = ref(
  route.query.financial_period ? route.query.financial_period : ""
);
const deleteModal = ref({
  open: false,
  business_id: "",
});
const openCloseDeleteModal = (value, id) => {
  if (id) {
    deleteModal.value.business_id = id;
  }
  deleteModal.value.open = value;
};
const callBackAfterDelete = () => {
  router.push(
    `/entity/financial-data/balance-sheet/view/${route.params.id}?financial_period=${route.query.financial_period}&financial_year=${route.query.financial_year}`
  );
};
const getBusiness = async (financial_year, financial_period) => {
  await businessStore.getBusiness(
    route.params.id,
    financial_year,
    financial_period
  );
};
const getData = async (financial_year, financial_period, business_id) => {
  await ifrsStore.getIFRSCheck(
    route.params.id,
    financial_year,
    financial_period,
    async ({ data }) => {
      await statusStore.getStatus(
        route.params.id,
        financial_year,
        financial_period
      );
      if (data === "IFRS") {
        await liabilityDataStore.getLiabilities(
          route.params.id,
          financial_year,
          financial_period,
          business_id
        );
        await financialStore.getAsset(
          route.params.id,
          financial_year,
          financial_period,
          business_id
        );
      } else if (data === "Non-IFRS") {
        await nonIfrsBalanceSheetStore.getNoneIFRSBalanceSheet(
          route.params.id,
          financial_year,
          financial_period,
          business_id
        );
      }
    }
  );
};

onMounted(async () => {
  if (route.query.financial_year && route.query.financial_period) {
    await getBusiness(financial_year.value, financial_period.value);
    changeBusiness(business.value, route.query, async (business_id) => {
      await getData(financial_year.value, financial_period.value, business_id);
    });
  }
});
const changeBusiness = (newBusiness, newValue, callBack = () => {}) => {
  let business_id = "";
  if (newBusiness?.data?.length) {
    if (newValue.business) {
      const find = newBusiness.data.find((b) => b.id == newValue.business);
      if (find) {
        business_id = newValue.business;
      } else {
        if (newBusiness?.data?.length > 1) {
          business_id = "master";
        } else {
          business_id = newBusiness.data[0]?.id;
        }
      }
    } else if (newBusiness?.data?.length > 1) {
      business_id = "master";
    } else {
      business_id = newBusiness.data[0]?.id;
    }
  }
  tab.value = business_id;

  if (newValue.financial_year && newValue.financial_period) {
    const { pathname } = window.location;
    const tab = pathname.split("/")[4];
    if (tab === "view") {
      router.replace(
        `/entity/financial-data/balance-sheet/view/${
          route.params.id
        }?financial_period=${newValue.financial_period}&financial_year=${
          newValue.financial_year
        }${business_id ? "&business=" + business_id : ""}`
      );
    }
  }
  callBack(business_id);
};
const unWatchRouteQuery = watch(
  () => route.query,
  async (newValue, oldValue) => {
    financial_year.value = newValue.financial_year;
    financial_period.value = newValue.financial_period;
    if (newValue.financial_year !== oldValue.financial_year) {
      await getBusiness(newValue.financial_year, newValue.financial_period);
    }
    changeBusiness(business.value, newValue, async (business_id) => {
      await getData(
        newValue.financial_year,
        newValue.financial_period,
        business_id
      );
    });
  },
  { deep: true }
);

const name = ref("");
const message = ref("");
const router = useRouter();

const openCloseInput = async (id, value, Name, update = true) => {
  business.value.data = business.value.data.map((b) => {
    if (b.id === id) {
      return { ...b, showInput: value };
    }
    return b;
  });
  if (value) name.value = Name;
  else if (!value && update) {
    await updateBusiness(id);
  }
};
const pushToOtherBusiness = (id) => {
  router.push(
    `/entity/financial-data/balance-sheet/view/${route.params.id}?financial_period=${financial_period.value}&financial_year=${financial_year.value}&business=${id}`
  );
  tab.value = id;
};
const updateBusiness = async (id) => {
  const findBusiness = business.value.data.find((b) => b.id == id);
  if (findBusiness.name === name.value) return;
  await businessStore.updateBusiness(
    {
      entity_business_id: id,
      entity_id: route.params.id,
      financial_year: financial_year.value,
      financial_period: financial_period.value,
      name: name.value,
    },
    (mess) => {
      message.value = mess;
    },
    async () => {
      await businessStore.getBusiness(
        route.params.id,
        financial_year.value,
        financial_period.value
      );
    }
  );
};
const handleSubmit = async (e, id) => {
  e.preventDefault();
  openCloseInput(id, false, name.value, false);
};
const setName = async (Name) => {
  name.value = Name;
};
onUnmounted(async () => {
  await unWatchRouteQuery();
});
onBeforeRouteLeave(async () => {
  await unWatchRouteQuery();
  return true;
});
</script>

<style scoped>
.wrapper {
  gap: 10px;
  cursor: pointer;
  min-width: 155px !important;
}

.cont {
  overflow-x: auto;
}

.tab-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /*
      identical to box height */
  letter-spacing: 0.055em;
  color: #747171;
}

.tab-title.active {
  font-weight: 600;
  color: #000000;
}

.bus-input {
  border: none;
  border-bottom: 1px solid #000000;
}

.bus-input:focus {
  outline: 0;
  border-bottom: 1px solid #747171;
}
</style>

export const auditorDetailsFormData = [
  {
    id: "date_of_furnishing",
    title: "Date of furnishing of the Audit report",
    input: {
      type: "date"
    }
  },
  {
    id: "auditor_name",
    title: "Name of Auditor signing the tax audit report (whenever applicable)",
    input: {
      placeholder: "Name of Auditor ",
      onkeypress: (e) => {
        const currentKey = String.fromCharCode(e.keyCode);
        if (!/^[a-zA-Z ]*$/.test(currentKey)) {
          e.preventDefault();
        }
      }
    }
  },
  {
    id: "membership_number",
    title: "Membership number of the Auditor",
    input: {
      placeholder: "Membership number",
      onkeypress: (e) => {
        const currentKey = String.fromCharCode(e.keyCode);
        if (!/[a-zA-Z0-9]+/.test(currentKey)) {
          e.preventDefault();
        }
      }
    }
  },
  {
    id: "proprietorship_name",
    title: "Name of the Auditor (proprietorship/ firm)",
    input: {
      placeholder: "Name of Auditor ",
      onkeypress: (e) => {
        const currentKey = String.fromCharCode(e.keyCode);
        if (!/^[a-zA-Z ]*$/.test(currentKey)) {
          e.preventDefault();
        }
      }
    }
  },
  {
    id: "firm_registration_number",
    title: "Firm registration number",
    input: {
      type: "number",
      placeholder: "Firm registration number"
    }
  },
  {
    id: "member_eid",
    title: "EID of the member",
    input: {
      type: "text",
      placeholder: "EID of the member",
      onkeypress: (e) => {
        let reg = /^[0-9 -]+$/;
        let char = String.fromCharCode(e.keyCode); // Get the character
        if (reg.test(char)) return true; // Match with regex
        else e.preventDefault();
      }
    }
  },
  {
    id: "email",
    title: "Email",
    input: {
      type: "email",
      placeholder: "Mail Address"
    }
  },

  {
    id: "mobile",
    title: "Mobile Number",
    input: {
      type: "number",
      placeholder: "Mobile Number",
      onkeypress: (e) => {
        let reg = /^[0-9+]+$/;
        let char = String.fromCharCode(e.keyCode); // Get the character
        if (reg.test(char)) return true; // Match with regex
        else e.preventDefault(); // If not match, don't add to input text
      }
    }
  }
];

<template>
  <div class="w-100 table-responsive mt-4 mb-4">
    <p
      v-if="statusMessage.message"
      :class="`my-2 text-${statusMessage.type} text-center`"
    >
      {{ statusMessage.message }}
    </p>
    <div class="w-100 table-responsive mt-2">
      <table class="table custom-table mb-0 table-bordered table-striped">
        <thead>
          <tr>
            <th class="table-header">Sl No.</th>
            <th class="table-header">Order ID</th>
            <th class="table-header">Transaction ID</th>
            <th class="table-header">Discount Price</th>
            <th class="table-header">Sub Total</th>
            <th class="table-header">vat</th>
            <th class="table-header">total</th>
            <th class="table-header">Transaction date</th>
            <th class="table-header">Status</th>
            <th class="table-header text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data" :key="item?.id">
            <td class="table-rows align-middle">
              <p class="data-text mb-0">{{ index + 1 }}</p>
            </td>
            <td class="table-rows align-middle">
              <p class="data-text mb-0">
                {{ item?.order_id }}
              </p>
            </td>
            <td class="table-rows align-middle">
              <p class="data-text mb-0">
                {{ item?.txn_id }}
              </p>
            </td>
            <td class="table-rows align-middle">
              <p class="data-text mb-0">
                {{ item?.discount_price }}
              </p>
            </td>
            <td class="table-rows align-middle">
              <p class="data-text mb-0">
                {{ item?.sub_total }}
              </p>
            </td>
            <td class="table-rows align-middle">
              <p class="data-text mb-0">
                {{ item?.vat }}
              </p>
            </td>
            <td class="table-rows align-middle">
              <p class="data-text mb-0">
                {{ item?.total }}
              </p>
            </td>
            <td class="table-rows align-middle">
              <p class="data-text mb-0">
                {{ new Date(item?.created_at).toUTCString().slice(0, 16) }}
              </p>
            </td>
            <td class="table-rows align-middle">
              <p class="data-text mb-0">
                {{ item?.status_text }}
              </p>
            </td>

            <td class="table-rows align-middle">
              <div class="w-100 d-flex align-items-center justify-content-end">
                <button
                  v-if="item?.status_text === 'Paid'"
                  class="btn-save p-2 text-right"
                  @click="handleGenerateInvoice(item.order_id)"
                >
                  Generate Invoice
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="!data.length">
            <td class="align-middle" :colspan="10">
              <p class="data-text text-center mb-0">
                <b> You have no transaction history! </b>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const { usePaymentStore } = require("../../store/payment.store");

const props = defineProps({
  data: {
    type: Array,
    default: []
  }
});
const paymentStore = usePaymentStore();
const statusMessage = ref({
  message: "",
  type: "info"
});
const setStatusMessage = (message, type) => {
  statusMessage.value.message = message;
  if (type) {
    statusMessage.value.type = type;
  }
};
const handleGenerateInvoice = async (id) => {
  await paymentStore.generateInvoice(id, setStatusMessage);
};
</script>
<style scoped>
.card-container {
  max-width: 600px;
  background: #fafbff;
  border-radius: 5px;
  padding: 33px 27px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.card-container::-webkit-scrollbar {
  display: none;
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /* identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
}
.table-header {
  min-height: 39px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2.5px solid #fff;
}
.table-rows {
  background: #ffffff;
  border: 2.5px solid #fff;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input {
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input:focus {
  outline: none;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.delete-button {
  width: 50px;
  height: 44px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.gray {
  background: #deeded;
}
.assign-entity-button {
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.btn-save {
  background: #0f75bc;
  border-radius: 5px;
  color: #fafbff;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffff;
}
</style>

<template>
  <tr>
    <td class="table-rows">
      <input
        :value="props.item.date_of_initial_transfer"
        type="date"
        class="custom-input disabled w-100"
        placeholder="Name"
        :disabled="$route.query.current_business === 'master' || !!status"
      />
    </td>
    <td class="table-rows">
      <input
        :value="props.item.name_of_asset"
        type="text"
        class="custom-input w-100"
        :disabled="$route.query.current_business === 'master' || !!status"
        placeholder="Name"
      />
    </td>
    <td class="table-rows">
      <input
        :value="props.item.subsequent_transfer_date"
        type="date"
        class="custom-input w-100"
        :disabled="$route.query.current_business === 'master' || !!status"
        placeholder="dd-mm-yyyy"
      />
    </td>
    <td class="table-rows">
      <input
        :value="props.item.name_of_transferee_entity"
        disabled
        type="text"
        class="custom-input disabled w-100"
        placeholder="Name"
      />
    </td>
    <td class="table-rows">
      <input
        :value="props.item.net_book_value_on_transfer_date"
        type="number"
        class="custom-input w-100"
        :disabled="$route.query.current_business === 'master' || !!status"
        placeholder="Value"
      />
    </td>
    <td class="table-rows">
      <input
        :value="props.item.market_value"
        type="number"
        class="custom-input w-100"
        :disabled="$route.query.current_business === 'master' || !!status"
        placeholder="Value"
      />
    </td>
    <td class="table-rows">
      <input
        :value="props.item.taxable_amount"
        disabled
        type="text"
        class="custom-input total gray text-right w-100"
        placeholder="Name"
      />
    </td>
  </tr>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useStatusStore } from "../../../../store/statusStore";

const props = defineProps({
  item: Object
});
const statusStore = useStatusStore();
const { status } = storeToRefs(statusStore);
</script>

<style scoped>
.header {
  padding: 6px 9px;
  min-height: 60px;
  background: #0f75bc;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; /*
  identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
.custom-input {
  height: 44px;
  padding: 11px 13px;
  margin-top: 10px;
  border: 1px solid rgba(116, 113, 113, 0.3);
}
.custom-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
  text-align: start;
}
.custom-input.total {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.gray {
  background: #deeded;
}
.disabled {
  background-color: #eee;
}
.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
}
.table-header {
  min-height: 39px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2.5px solid #fff;
  width: 100px;
}
.table-header.sl-no {
  width: 10px !important;
}
.table-rows {
  background: #ffffff;
  border: 2.5px solid #fff;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
</style>

/* eslint-disable no-prototype-builtins */
import fetchWrapper from "@/helpers/fetchWrapper";
import { defineStore, storeToRefs } from "pinia";
import { useProfitAndLossStore } from "./ProfitAdLoss";
import { Toast } from "../helpers/toast";
import { itemsThatWillReclassified } from "../components/entity/FinancialData/ProfitAndLoss/Expenditure/data";
import { otherLossesGainsData } from "../components/entity/FinancialData/ProfitAndLoss/Expenditure/data";

export const useExpenditureStore = defineStore("expenditure", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      loading: true,
      error: false,
      finance_cost: {
        indrct_expns_finance_cost_loan_processing_fees: "", //1
        indrct_expns_finance_cost_interest_on_loan: "", // 3
        // indrct_expns_finance_cost_bank_charges : "",
        indrct_expns_finance_cost_lc_charges: "", //2
        indrct_expns_finance_cost_od_interests: "", // 4
        oprtng_prft1_fnce_costs_interest_on_convertible_bonds: "", //5
        oprtng_prft1_fnce_costs_interest_on_lease_liabilities: "", //6
        oprtng_prft1_fnce_costs_dividends_on_redeemable_prfrnce_shares: "", //7
        oprtng_prft1_fnce_costs_other_interest_component: "", // 8
      },
      indirect_expenses: {
        indirect_expenses: "",
        indrct_expens_distribution_expense_frieght_outward: "",
        indrct_expens_distribution_expense_driver_expense: "",
        indrct_expns_distribution_expense_petrol_expense: "",
        indrct_expns_distribution_expense_vehicle_repairs: "",
        indrct_expns_distribution_expense_others: "",
        indrct_expns_market_expenses_advertisement_expenses: "",
        indrct_expns_market_expenses_business_promotion_expenses: "",
        indrct_expns_market_expenses_sampling_expenses: "",
        indrct_expns_market_expenses_entertainment_expenses: "",
        indrct_expns_market_expenses_conference_expenses: "",
        indrct_expns_market_expenses_others: "",
        indrct_expns_occupancy_expenses_rent: "",
        indrct_expns_occupancy_expenses_lease_expenses: "",
        indrct_expns_occupancy_expenses_mentainence_expenses: "",
        indrct_expns_occupancy_expenses_electricity_expenses: "",
        indrct_expns_occupancy_expenses_water_expenses: "",
        indrct_expns_occupancy_expenses_office_supplies: "",
        indrct_expns_occupancy_expenses_telephone_expenses: "",
        indrct_expns_occupancy_expenses_others: "",
        indrct_expns_admnstn_expns_salary_expenses: "",
        indrct_expns_admnstn_expns_lgl_and_prfsnl_charge: "",
        indrct_expns_admnstn_expns_utilities: "",
        indrct_expns_admnstn_expns_bonus: "",
        indrct_expns_admnstn_expns_insrnce_expns_mdcl_insrnc: "",
        indrct_expns_admnstn_expns_insrnce_expns_life_insrnc: "",
        indrct_expns_admnstn_expns_insrnce_expns_keyman_insrnc: "",
        indrct_expns_admnstn_expns_insrnce_expns_other_insrnc: "",
        indrct_expns_admnstn_expns_commission_expenses: "",
        indrct_expns_admnstn_expns_audit_fees: "",
        indrct_expns_admnstn_expns_accounting_charge: "",
        indrct_expns_admnstn_expns_staff_walefare_expenses: "",
        indrct_expns_other_expenses_hotel_boarding_and_loadging: "",
        indrct_expns_other_expenses_tours_and_travel_domestic: "",
        indrct_expns_other_expenses_tours_and_travel_intrntnl: "",
        indrct_expns_other_expenses_conveyance_expenses: "",
        indrct_expns_other_expenses_guest_house_expenses: "",
        indrct_expns_other_expenses_festival_expenses: "",
        indrct_expns_other_expenses_scholarship: "",
        indrct_expns_other_expenses_donations: "",
        indrct_expns_other_expenses_royalty_expenses: "",
        indrct_expns_other_expenses_bad_debts: "",
        indrct_expns_other_expenses_misscellaneouse_expenses: "",
        indrct_expns_other_expenses_others_expenses: "",
      },
      OthersIncome: {
        othr_incm_rclssfd_itms_fncl_asts_at_fvoci: "",
        othr_incm_rclssfd_itms_fncl_asts_at_fvoci_fair_value_gn_ls: "",
        othr_incm_rclssfd_itms_fncl_asts_at_fvoci_reclassification: "",
        othr_incm_rclssfd_itms_cash_flow_hedges: "",
        othr_incm_rclssfd_itms_cash_flow_hedges_fair_value_gn_ls: "",
        othr_incm_rclssfd_itms_cash_flow_hedges_reclassificatins: "",
        othr_incm_rclssfd_itms_changes_in_revaluations_surplus: "",
        othr_incm_rclssfd_itms_changes_in_revaluations_surplus_1: "",
        othr_incm_rclssfd_itms_income_tax_relating_to_clssfd_itms: "",
        othr_incm_rclssfd_itms_remsrmnts_of_the_defined_benifit_plns: "",
        othr_incm_rclssfd_itms_eqty_instruments_through_oci: "",
        othr_incm_rclssfd_itms_others: "",
        othr_incm_othr_ls_gns_net_gns_frm_fncl_asts_msrd_at_fvtpl: "",
        othr_incm_othr_ls_gns_net_derivative_financial_instrmnts: "",
        othr_incm_othr_ls_gns_net_contingent_consideration: "",
      },
      share_of_loss_profit_of_associates: "",
      less_depreciation_amortisation_expense: "",
      profit_loss_from_discontinue_operation: "",
      totalExpenditure: {
        //indirect_expenses: "",
        distribution_expenses: "",
        indrct_expns_market_expenses: "",
        indrct_expns_occupancy_expenses: "",
        indrct_expns_admnstn_expns: "",
        //indrct_expns_finance_cost: "",
        indrct_expns_other_expenses: "",
        //othr_incm_item_reclassified_to_pl: "",
        //othr_incm_othr_ls_gns_net: "",
      },
    };
  },
  getters: {
    financeCost(state) {
      let total = 0;
      for (let x in state.finance_cost) {
        total += Number(state.finance_cost[x] || 0);
      }
      return total;
    },

    totalIndirectExpenses(state) {
      let sum = 0;
      for (let x in state.indirect_expenses) {
        if (x !== "indrct_expns_admnstn_expns_depreciation") {
          //9. Depreciation & Amortization
          sum += Number(state.indirect_expenses[x]);
        }
      }
      return sum;
    },
    Ebidta() {
      return (grossProfit) => grossProfit - this.totalIndirectExpenses;
    },
    ebit(state) {
      return (grossProfit) =>
        this.Ebidta(grossProfit) -
        Number(state.less_depreciation_amortisation_expense || 0);
    },
    totalOtherIncome(state) {
      let sum = 0;
      for (let x in state.OthersIncome) {
        if (
          x === "othr_incm_rclssfd_itms_fncl_asts_at_fvoci" ||
          x === "othr_incm_rclssfd_itms_cash_flow_hedges"
        ) {
          sum += 0;
        } else sum += Number(state.OthersIncome[x]);
      }
      return sum;
    },
    financialAssetsAtFVOCI(state) {
      return (
        Number(
          state.OthersIncome
            .othr_incm_rclssfd_itms_fncl_asts_at_fvoci_fair_value_gn_ls || 0
        ) +
        Number(
          state.OthersIncome
            .othr_incm_rclssfd_itms_fncl_asts_at_fvoci_reclassification || 0
        )
      );
    },
    cashFlowHedges(state) {
      return (
        Number(
          state.OthersIncome
            .othr_incm_rclssfd_itms_cash_flow_hedges_fair_value_gn_ls
        ) +
        Number(
          state.OthersIncome
            .othr_incm_rclssfd_itms_cash_flow_hedges_reclassificatins
        )
      );
    },
    itemsThatReclassified(state) {
      let total = itemsThatWillReclassified.reduce((a, b) => {
        if (
          b.model == "othr_incm_rclssfd_itms_fncl_asts_at_fvoci" ||
          b.model == "othr_incm_rclssfd_itms_cash_flow_hedges"
        ) {
          return a;
        }
        return a + Number(state.OthersIncome[b.model] || 0);
      }, 0);
      return total;
    },
    otherLossesGains(state) {
      let total = otherLossesGainsData.reduce((a, b) => {
        return a + Number(state.OthersIncome[b.model] || 0);
      }, 0);
      return total;
    },
    profitFromContinueOperations() {
      return (grossProfit) => {
        let dif = this.ebit(grossProfit) - this.financeCost;
        return dif + this.itemsThatReclassified + this.otherLossesGains;
      };
    },
    profitBeforeIncomeTax(state) {
      return (grossProfit) =>
        this.profitFromContinueOperations(grossProfit) +
        Number(state.share_of_loss_profit_of_associates || 0) +
        Number(state.profit_loss_from_discontinue_operation || 0);
    },
  },
  actions: {
    async setToEmpty() {
      for (let x in this.indirect_expenses) {
        this.indirect_expenses[x] = "";
      }
      for (let x in this.OthersIncome) {
        this.OthersIncome[x] = "";
      }
      for (let x in this.finance_cost) {
        this.finance_cost[x] = "";
      }
    },
    async getExpenditure(
      id,
      financial_year,
      financial_period,
      entity_business_id
    ) {
      if (financial_period && financial_year) {
        try {
          this.loading = true;
          const res = await fetchWrapper.get(
            `/expenditure?entity_id=${id}&financial_period=${financial_period}&financial_year=${financial_year}${
              entity_business_id
                ? "&entity_business_id=" + entity_business_id
                : ""
            }`
          );
          if (res?.data?.result === 1) {
            let data = res?.data?.data;
            if (data) {
              for (let x in data) {
                let indirectExpense = this.indirect_expenses.hasOwnProperty(x);
                let otherIncome = this.OthersIncome.hasOwnProperty(x);
                let financeCost = this.finance_cost.hasOwnProperty(x);
                if (indirectExpense) {
                  this.indirect_expenses[x] = data[x] || "";
                }
                if (otherIncome) {
                  this.OthersIncome[x] = data[x] || "";
                }
                if (financeCost) {
                  this.finance_cost[x] = data[x] || "";
                }
                this.share_of_loss_profit_of_associates =
                  data?.share_of_loss_profit_of_associates || "";
                this.less_depreciation_amortisation_expense =
                  data.less_depreciation_amortisation_expense || "";
                this.profit_loss_from_discontinue_operation =
                  data["profit_loss_from_discontinue_operation"] || "";
              }
            }
          } else {
            await this.setToEmpty();
            this.error = res.data?.error
              ? res.data?.error?.join(",")
              : res.data?.message;
          }
          this.loading = false;
          this.error = false;
        } catch (err) {
          console.log(err);
          this.error = err?.response?.data?.message || "";
          this.loading = false;
        }
      } else {
        await this.setToEmpty();
      }
    },
    async saveExpenditure(route, callBack = () => {}) {
      const toast = new Toast("Saving...");
      try {
        const profitLossStore = useProfitAndLossStore();
        const { grossProfit } = storeToRefs(profitLossStore);
        const id = route.params.id;
        const {
          financial_year,
          financial_period,
          business: entity_business_id,
        } = route.query;
        const res = await fetchWrapper.post(`/expenditure`, {
          ...this.indirect_expenses,
          ...this.OthersIncome,
          ...this.totalExpenditure,
          ...this.finance_cost,
          othr_incm_rclssfd_itms_fncl_asts_at_fvoci:
            this.financialAssetsAtFVOCI,
          othr_incm_rclssfd_itms_cash_flow_hedges: this.cashFlowHedges,
          ebitda: this.Ebidta(grossProfit.value(route)),
          ebit: this.ebit(grossProfit.value(route)),
          indirect_expenses: this.totalIndirectExpenses,
          finance_costs_net: this.financeCost,
          share_of_loss_profit_of_associates:
            this.share_of_loss_profit_of_associates,
          profit_loss_from_discontinue_operation:
            this.profit_loss_from_discontinue_operation,
          profit_from_continue_operation: this.profitFromContinueOperations(
            grossProfit.value(route)
          ),
          profit_before_income_tax: this.profitBeforeIncomeTax(
            grossProfit.value(route)
          ),
          less_depreciation_amortisation_expense:
            this.less_depreciation_amortisation_expense,
          entity_id: Number(id),
          financial_year,
          financial_period,
          entity_business_id: Number(entity_business_id),
        });
        if (res?.data?.result === 1) {
          toast.makeSuccessToast("Saved successfully");
          callBack(res?.data.entity_business_id);
        } else toast.makeWarningToast(res.data.message);
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    },
  },
});

<template>
  <div class="wrapper w-100">
    <div class="w-100 d-flex align-items-center tab-container">
      <div
        :class="`${active === 'entityDetails' ? 'tab active' : 'tab'}`"
        @click="changeTab('entity')"
      >
        Entity Details
        <hr v-if="active === 'entityDetails'" />
      </div>
      <div
        :class="`${
          active === 'contactDetails'
            ? `tab active ${checkEntity ? '' : 'disabled'}`
            : `tab ${checkEntity ? '' : 'disabled'}`
        }`"
        @click="changeTab('contactDetails')"
      >
        Contact Details
        <hr v-if="active === 'contactDetails'" />
      </div>
      <div
        :class="`${
          active === 'bankingDetails'
            ? `tab active ${checkEntity ? '' : 'disabled'}`
            : `tab ${checkEntity ? '' : 'disabled'}`
        }`"
        @click="changeTab('bankingDetails')"
      >
        Banking Details
        <hr v-if="active === 'bankingDetails'" />
      </div>
      <div
        v-if="(hasBranch && entity_type == 1) || entity_type == 3"
        :class="`${active === 'branchDetails' ? 'tab active' : 'tab'} ${
          checkEntity ? '' : 'disabled'
        }`"
        @click="changeTab('branchDetails')"
      >
        Branch Details
        <hr v-if="active === 'branchDetails'" />
      </div>
      <div
        v-if="entity_type == 1 || entity_type == 3"
        :class="`${
          active === 'ownershipDetails'
            ? `tab active ${checkEntity ? '' : 'disabled'}`
            : `tab ${checkEntity ? '' : 'disabled'}`
        }`"
        @click="changeTab('ownershipDetails')"
      >
        Ownership Details
        <hr v-if="active === 'ownershipDetails'" />
      </div>

      <div
        v-if="entity_type == 1 || entity_type == 3"
        :class="`${
          active === 'authorizedSignatoryDetails'
            ? `tab active ${checkEntity ? '' : 'disabled'}`
            : `tab ${checkEntity ? '' : 'disabled'}`
        }`"
        @click="changeTab('authorizedSignatoryDetails')"
      >
        Authorized Signatory Details
        <hr v-if="active === 'authorizedSignatoryDetails'" />
      </div>

      <div
        :class="`${
          active === 'declaration'
            ? `tab active ${checkEntity ? '' : 'disabled'}`
            : `tab ${checkEntity ? '' : 'disabled'}`
        }`"
        @click="changeTab('declaration')"
      >
        Declaration
        <hr v-if="active === 'declaration'" />
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import { useEntityStore } from "../../store/entity";
import { getEntityItem } from "../../store/entityActions/get";
export default {
  name: "AddEntityTab",
  props: { active: String, entityType: String },
  setup() {
    const entityStore = useEntityStore();
    const {
      entity,
      contactDetails,
      ownershipDetails,
      BankDetails,
      entityBranch,
      OwnerDetails,
      entityAuthorizedSignatory
    } = storeToRefs(entityStore);
    return {
      entity,
      entityStore,
      contactDetails,
      ownershipDetails,
      entityBranch,
      BankDetails,
      OwnerDetails,
      entityAuthorizedSignatory
    };
  },

  data() {
    return {
      hasBranch: false,
      checkEntity: false,
      entity_type: this.entityType,
    };
  },
  async created() {
    await this.getEntity();
    const id = this.$route.params.id;
    const check = this.entity.data?.entity?.find(
      (e) => Number(e.id) === Number(id)
    );
    if (check) {
      this.hasBranch = "Y"; //check?.has_branch === "Y";
      this.checkEntity = !!check;
    } else {
      this.checkEntity = false;
    }
    if (this.entityType?.length > 0) {
      this.entity_type = this.entityType;
    } else {
      const Entity = this.entity?.data?.entity?.find(
        (e) => Number(e.id) === Number(id)
      );
      this.entity_type = Entity?.entity_type;
    }
  },

  watch: {
    entityType(newVal, oldVal) {
      this.entity_type = newVal;
    },
  },
  methods: {
    async getEntity() {
      const id = this.$route.params.id;
      if (this.entity.data?.entity) {
        const check = this.entity.data?.entity?.find(
          (e) => Number(e.id) === Number(id)
        );
        if (!check) {
          await getEntityItem("entity", "/entity", id);
        }
      } else {
        await getEntityItem("entity", "/entity", id);
      }
    },
    async changeTab(tab) {
      const id = this.$route.params.id;
      if (id) {
        if (tab === "contactDetails") {
          await getEntityItem("contactDetails", "/entityContactDetails", id);
          if (!this.contactDetails.data.length) {
            this.$router.push(`/entity/add-entity/contact-details/${id}`);
            return;
          }
          this.$router.push(`/entity/contact-details/${id}`);
        } else if (tab === "entity") {
          this.$router.push(`/entity/add-entity/entity-details/entity/${id}`);
        } else if (tab === "ownershipDetails") {
          await getEntityItem("OwnerDetails", "/entityOwnerDetails", id);
          if (!this.OwnerDetails.data?.length) {
            this.$router.push(`/entity/add-entity/ownership-details/${id}`);
            return;
          }
          this.$router.push(`/entity/ownership-details/${id}`);
        } else if (tab === "bankingDetails") {
          await getEntityItem("BankDetails", "/entityBankDetails", id);
          if (!this.BankDetails.data?.length) {
            this.$router.push(`/entity/add-entity/banking-details/${id}`);
            return;
          }
          this.$router.push(`/entity/banking-details/${id}`);
        } else if (tab === "branchDetails") {
          await getEntityItem("entityBranch", "/entityBranch", id);

          if (!this.entityBranch.data?.length) { 
            this.$router.push(`/entity/add-entity/branch-details/${id}`);
            return;
          }
          this.$router.push(`/entity/branch-details/${id}`);
        } else if (tab === "authorizedSignatoryDetails") {

          await getEntityItem(
            "entityAuthorizedSignatory",
            "/entityAuthorizedSignatory",
            this.$route.params.id
          );
          if (!this.entityAuthorizedSignatory.data?.length) {
            this.$router.push(
              `/entity/add-entity/authorized-signatory-details/${id}`
            );
            return;
          }
          this.$router.push(`/entity/authorized-signatory-details/${id}`);
        } else if (tab === "declaration") {
          this.$router.push(`/entity/add-entity/declaration/${id}`);
        }
      }
      return;
    },
  },
};
</script>

<style scoped>
.wrapper {
  padding: 31px 20px 10px 20px;
}

.tab-container {
  gap: 30px;
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
  padding-bottom: 3px;
}

.tab {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #747171;
  cursor: pointer;
}

.tab.active {
  color: #0f75bc;
}

hr {
  border: 1px solid #0f75bc;
  margin-bottom: -4px;
  margin-top: 5px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

@media screen and (max-width: 600px) {
  .tab-container {
    gap: 18px;
  }
}
</style>

<template>
  <div class="text-center">
    <CustomModalContainer
      :open="dialog"
      :set-open="openDialog"
      width="414"
      height="600"
    >
      <div class="w-100 wrapper">
        <div
          class="w-100 mt-3 d-flex flex-column justify-content-evenly align-items-center"
        >
          <h3 class="verification">Verification</h3>
          <OTP :HandleComplete="onOtpComplete" :HandleOnChange="onOtpChange" />
          <p class="text-info">
            {{ message }}
          </p>
          <vue-countdown
            v-if="counting"
            v-slot="{ seconds }"
            :time="30 * 1000"
            @end="onCountdownEnd"
          >
            <p class="time mb-0 mt-3">
              you can resend after 00:{{ seconds }} seconds
            </p>
          </vue-countdown>
          <p v-if="!counting" class="resend mt-5" @click="sendOtp()">Resend</p>
        </div>
      </div>
    </CustomModalContainer>

    <button v-if="!type" type="button" class="btn-verify" @click="sendOtp()">
      Verify
    </button>
    <button v-else class="btn-log-in" v-bind="props">Send OTP</button>
  </div>
</template>

<script>
import { CustomModalContainer, OTP } from "../common";
import { useProfileStore } from "../../store/profile";

export default {
  name: "VerifyOtp",
  components: { OTP, CustomModalContainer },
  props: {
    mobile_no: String,
    makeVerified: Function,
    type: String,
    mobileCode: String,
  },
  data() {
    return {
      dialog: false,
      message: "",
      otp_id: "",
      counting: false,
    };
  },
  methods: {
    openDialog(value) {
      this.dialog = value;
    },
    onOtpChange() {},
    async onOtpComplete(otp) {
      const profileStore = useProfileStore();
      await profileStore.verifyOtp(
        { otp_id: this.otp_id, otp },
        this.setMessage,
        async (token) => {
          if (this.type === "login") {
            await profileStore.signInWithOTp(
              this.mobile_no,
              this.mobileCode,
              this.setMessage,
              () => {
                sessionStorage.setItem("Token", token);
                this.$router.push("/");
              }
            );
            return;
          }
          setTimeout(() => {
            this.makeVerified(true);
            this.dialog = false;
          }, 2000);
        }
      );
    },
    close() {
      this.dialog = false;
    },
    setMessage(message) {
      this.message = message;
    },
    async sendSignUpOTp() {
      const profileStore = useProfileStore();
      await profileStore.getSignUpOtp(
        this.mobile_no,
        this.mobileCode,
        this.setMessage,
        (otpId) => {
          this.otp_id = otpId;
          this.dialog = true;
          this.startCountdown();
        }
      );
    },
    async sendLoginOTp() {
      const profileStore = useProfileStore();
      await profileStore.getLoginOtp(
        this.mobile_no,
        this.mobileCode,
        this.setMessage,
        (otpId) => {
          this.otp_id = otpId;
          this.startCountdown();
        }
      );
    },
    async sendOtp() {
      if (this.type === "login") {
        this.sendLoginOTp();
        return;
      }
      this.sendSignUpOTp();
    },
    startCountdown() {
      this.counting = true;
    },
    onCountdownEnd() {
      this.counting = false;
    },
  },
};
</script>

<style scoped>
.btn-verify {
  width: 92px;
  height: 40px;
  background: #0f75bc;
  border-radius: 4px;
  padding: 8px 21px;
  color: #fafbff;
  margin-left: -120px;
  position: relative;
  z-index: 3;
}
.wrapper {
  padding: 21px 19px;
  background: #fafbff;
  border-radius: 5px;
}
.verification {
  font-style: normal;
  font-weight: 600;
  font-size: 25.888px;
  line-height: 39px;
  letter-spacing: 0.055em;
  color: #000000;
}
.inputs {
  gap: 10px;
}
.input {
  background: #ffffff;
  border: 1px solid #e1e8f0;
  border-radius: 7px;
  height: 62px;
  width: 63px;
}
.time {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.resend {
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #000000;
}
.btn-send {
  width: 92px;
  height: 40px;
  background: #0f75bc;
  border-radius: 4px;
  padding: 8px 21px;
  color: #fafbff;
}
.btn-log-in {
  width: 196px;
  height: 55px;
  background: #0f75bc;
  border-radius: 4px;
  color: #fafbff;
}
</style>

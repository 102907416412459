<template>
  <EditableSheet
    v-if="$route.query.business !== 'master'"
    data-key="additional_employee_benefits_expneses"
    button-name="Add Employee Benefits Expenses"
  />
  <CustomClickableInput
    v-else
    v-model="groupData['additional_employee_benefits_expneses']"
    :disabled="true"
    name="Additional Employee Benefits Expenses"
  />
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useNoneIFRSProfitLoss } from "../../../../../store/NonIFRS/profitLoss.store";
import { CustomClickableInput } from "../../../../common";
import EditableSheet from "../common/EditableSheet.vue";

const nonIfrsPLStore = useNoneIFRSProfitLoss();
const { groupData } = storeToRefs(nonIfrsPLStore);
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}
.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}
.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}
</style>

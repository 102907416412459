<template>
  <div class="w-100">
    <div
      class="w-100 wrapper px-2 d-flex align-items-center justify-content-between"
    >
      <h3 class="progress-counter active mb-0" @click="handleTab('entity')">
        1
      </h3>
      <h3
        :class="`${
          active >= 2
            ? 'progress-counter active'
            : active === 1 || !active
            ? 'progress-counter next'
            : 'progress-counter'
        }   mb-0`"
        @click="handleTab('license')"
      >
        2
      </h3>
      <h3
        :class="`${
          active >= 3
            ? 'progress-counter active'
            : active === 2
            ? 'progress-counter next'
            : 'progress-counter'
        }   mb-0 mr-4`"
        @click="handleTab('tax')"
      >
        3
      </h3>
      <h3
        :class="`${
          active >= 4
            ? 'progress-counter active'
            : active === 3
            ? 'progress-counter next'
            : 'progress-counter'
        }   mb-0 mr-4`"
        @click="handleTab('business')"
      >
        4
      </h3>
    </div>
    <hr />
    <div
      class="tab-title-container d-flex align-items-center justify-content-between"
    >
      <h3 class="tab-title cursor-pointer">Entity</h3>
      <h3 class="tab-title cursor-pointer">License</h3>
      <h3 class="tab-title cursor-pointer">Tax</h3>
      <h3 class="tab-title cursor-pointer">Business Activity</h3>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useEntityStore } from "../../../../../store/entity";
import { storeToRefs } from "pinia";
export default {
  name: "AddEntityProgress",
  props: { active: String },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const entityStore = useEntityStore();
    const { entity } = storeToRefs(entityStore);
    const handleTab = async (tab) => {
      const item = entity.value.data?.[tab];
      if (!route.params.id) {
        return;
      }
      if (!item.length || tab === "entity") {
        router.push(
          `/entity/add-entity/entity-details/${
            tab == "business" ? "business" : tab === "tax" ? "tax-details" : tab
          }/${route.params.id}`
        );
        return;
      }
      const id = item[0]?.id;
      router.push(
        `/entity/add-entity/entity-details/${
          tab == "business" ? "business" : tab === "tax" ? "tax-details" : tab
        }/${route.params.id}/${id}`
      );
    };
    return { handleTab };
  },
};
</script>

<style scoped>
.wrapper {
  height: 94px;
}
hr {
  margin-top: -45px;
  border: 1px solid #000000;
  margin-left: 30px;
  margin-right: 30px;
}
.progress-counter {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  background-color: #fafbff;
  border-radius: 42px;
  color: #747171;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  cursor: pointer;
}
.progress-counter.active {
  background: #00a7a7;
  color: #fafbff;
}
.progress-counter.next {
  background: #747171;
  color: #fafbff;
}
.tab-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #000000;
}
.tab-title.business-activity {
  margin-left: -75px;
}
.tab-title-container {
  padding-left: 10px;
  padding-right: 0px;
  margin-top: 20px;
  margin-right: -30px;
}
</style>

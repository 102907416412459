<script setup>
import { ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useBusinessStore } from "../../../../store/buisness.store";
import { storeToRefs } from "pinia";
import { useChapter13Store } from "../../../../store/chapter13";
import { useCountryStore } from "../../../../store/country";
import { NumberFormatter } from "../../../../helpers/numberFormatter";

const name = ref("");
const route = useRoute();
const businessStore = useBusinessStore();
const { business } = storeToRefs(businessStore);
const chapter13Store = useChapter13Store();
const { masterData } = storeToRefs(chapter13Store);
const countryStore = useCountryStore();
const { countries } = storeToRefs(countryStore);

const setBusinessName = (newValue, current_business) => {
  const findBusiness = newValue?.data?.find((b) => b.id == current_business);
  name.value = findBusiness?.name ? findBusiness?.name : "";
};

watch(
  business,
  (newValue) => {
    if (newValue?.data.length)
      setBusinessName(newValue, route.query.current_business);
  },
  { deep: true }
);
watch(
  () => route.query,
  (newValue) => {
    setBusinessName(business.value, newValue.current_business);
  }
);

const getBusinessName = (id) => {
  const Business = business.value.data.find((b) => b.id == id);
  if (!Business) {
    return "";
  }
  return Business?.name;
};
</script>

<template>
  <div class="0">
    <div
      class="0 mt-4 border-bottom pb-10"
      v-for="(item, index) in masterData"
      :key="item?.entity_business_id"
    >
      <div class="0 d-flex align-items-center justify-content-between">
        <h4 class="title">{{ getBusinessName(item?.entity_business_id) }}</h4>
      </div>
      <div class="0 mt-4 d-flex align-items-center justify-content-between">
        <h4 class="title bold mb-0">Foreign Tax Credit</h4>
      </div>
      <div class="w-100 table-responsive mt-2">
        <table class="table table-bordered table-striped mt-2 mb-0">
          <thead>
            <th class="table-header w-20">Country Name</th>
            <th class="table-header w-20">Country Code</th>
            <th class="table-header w-20">
              Tax Identification Number of Tax Payer in Respective Country
            </th>
            <th class="table-header w-20">Total Income</th>
            <th class="table-header w-20">Total Tax Credit Claimed</th>
          </thead>
          <tbody>
            <tr v-for="(Value, index) in item.foreign_tax_credit" :key="index">
              <td class="table-rows align-middle">
                <select
                  v-model="Value.country_name"
                  class="border table-input 0"
                  disabled
                >
                  <option
                    v-for="country in countries.data"
                    :value="country.name"
                  >
                    {{ country.name }}
                  </option>
                </select>
              </td>
              <td class="table-rows align-middle">
                <select
                  v-model="Value.country_code"
                  class="border table-input 0"
                  disabled
                >
                  <option
                    v-for="country in countries.data"
                    :value="country.code"
                  >
                    {{ country.code }}
                  </option>
                </select>
              </td>
              <td class="table-rows align-middle">
                <input
                  v-model="Value.tax_identification_no_of_tax_payer"
                  type="text"
                  disabled
                  class="table-input"
                  placeholder="tax indentification number"
                />
              </td>
              <td class="table-rows align-middle">
                <input
                  v-model="Value.total_income"
                  type="number"
                  disabled
                  class="table-input"
                  placeholder="value"
                />
              </td>
              <td class="table-rows align-middle">
                <input
                  v-model="Value.total_tax_credit_claimed"
                  type="number"
                  disabled
                  class="table-input"
                  placeholder="value"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="0 gray pr-3 py-3 d-flex align-items-center justify-content-end"
      >
        <p class="title bold mb-0">
          Total Income:
          {{ NumberFormatter(item.total_foreign_tax_credit) }}
        </p>
      </div>
      <div class="0 mt-4 d-flex align-items-center justify-content-between">
        <h4 class="title bold mb-0">Witholding Tax Credit</h4>
      </div>

      <div class="table-responsive mt-2">
        <table class="table table-bordered table-striped mt-2 mb-0">
          <thead>
            <th class="table-header">S.No</th>
            <th class="table-header">TRN Code Of Deductor</th>
            <th class="table-header">Nature Of Income</th>
            <th class="table-header">Name Of Deductor</th>
            <th class="table-header">Total Income</th>
            <th class="table-header">Total Tax Deducted</th>
            <th class="table-header">% Of Tax Deducted</th>
          </thead>
          <tbody>
            <tr
              v-for="(Value, index) in item.withholding_tax_credit"
              :key="index"
            >
              <td class="table-rows pl-3 align-middle">
                {{ index + 1 }}
              </td>
              <td class="table-rows align-middle">
                <input
                  v-model="Value.trn_code_of_deductor"
                  type="text"
                  class="table-input"
                  placeholder="name"
                  disabled
                />
              </td>
              <td class="table-rows align-middle">
                <input
                  v-model="Value.nature_of_income"
                  type="text"
                  class="table-input"
                  placeholder="name"
                  disabled
                />
              </td>
              <td class="table-rows align-middle">
                <input
                  v-model="Value.name_of_deductor"
                  type="text"
                  class="table-input"
                  placeholder="name"
                  disabled
                />
              </td>
              <td class="table-rows align-middle">
                <input
                  v-model="Value.total_income"
                  type="number"
                  class="table-input"
                  placeholder="value"
                  disabled
                />
              </td>
              <td class="table-rows align-middle">
                <input
                  v-model="Value.total_tax_deducted"
                  type="number"
                  class="table-input"
                  placeholder="value"
                  disabled
                />
              </td>
              <td class="table-rows align-middle">
                <input
                  v-model="Value.percent_of_tax_deducted"
                  type="number"
                  class="table-input"
                  placeholder="value"
                  disabled
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="0 gray py-3 pr-3 d-flex align-items-center justify-content-end"
      >
        <p class="title bold mb-0">
          Total Tax Deducted:
          {{ NumberFormatter(item.total_withholding_tax_credit) }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.table-header {
  height: 70px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-left: 10px;
  vertical-align: middle;
  border: 3px solid #fff;
}
.table-rows {
  background: #ffffff;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
  border: 3px solid #fff;
  padding: 0 !important;
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.table-input {
  padding: 10px;
  width: 100% !important;
}
.table-input:focus {
  outline: 0;
}
.table-input::placeholder {
  text-align: left;
}
.outline-none {
  outline: none !important;
}
.article {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
.flex-2 {
  flex: 7;
}
.flex-1 {
  flex: 1;
}
.gray {
  background: #deeded;
}
.w-20 {
  width: 20%;
}

.border-bottom {
  border-bottom: 1px solid #ede4e4;
}
</style>

<template>
  <div class="w-100">
    <AlertModal
      :open="alertTotalRvenue"
      :message="`Warning: The revenue data entered does not match the Profit and Loss records. The revenue amount is ${NumberFormatter(
        original_revenue_value_from_profitloss
      )} AED.`"
      sub-message="Do you accept this discrepancy?"
      :on-confirm="acceptDiscrepancy"
      :on-cancel="handleCancel"
      :has-cancel="true"
      cancel-button-name="Accept"
      :button-name="`Non Accept`"
      :modal-props="{ persistent: true }"
    />
    <div class="header d-flex align-items-center w-100 justify-content-between">
      <p class="header-title mb-0 w-75">Particulars</p>
      <p class="header-title text-right mb-0">Amount in AED</p>
    </div>
    <form
      v-for="(item, index) in data"
      :id="item.model"
      :key="item.id"
      :class="`w-100 table-body ${!index || !(index % 2) ? '' : ''} ${
        item.sub ? 'sub' : ''
      } d-flex align-items-center justify-content-between py-2`"
    >
      <div class="d-flex w-75">
        <p class="mb-0 body-title count">
          <b>
            {{ renameIndexInRevenue(item.model) }}
          </b>
        </p>
        <p class="mb-0 d-flex body-title item-name">
          {{ item.name }}
        </p>
      </div>
      <p
        v-if="!item.showInput"
        class="body-title mb-00"
        @click="openCloseInput(item.model, true)"
      >
        {{ NumberFormatter(revenue[item.model]) }}
      </p>

      <input
        :id="'input-qfz-calc-' + item.model"
        v-model="revenue[item.model]"
        :hidden="!item.showInput"
        type="text"
        class="clickable-text"
        @blur="handleBlur(item.model, false)"
        @keydown.tab.enter.down.up="(e) => handleTab(e, item.model)"
        @keypress="(e) => handleChange(e, item.model)"
      />
    </form>
    <div
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 total-amount w-70">Total Net Revenue of Freezone Person</p>
      <p class="mb-0 total-amount">
        {{ NumberFormatter(totalRevenue) }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

import { useQFZCalculatorStore } from "../../../store/QFZcalculator";
import { storeToRefs } from "pinia";
import { NumberFormatter } from "../../../helpers/numberFormatter";
import { insertChar } from "../../../helpers/stringUtils";
import { fzpCalcRevenueData } from "./data";
import { renameIndexInRevenue } from "./controller";
import { AlertModal } from "../../common";
import { useRoute, useRouter } from "vue-router";

const QFZCalculator = useQFZCalculatorStore();
const { revenue, totalRevenue, original_revenue_value_from_profitloss } =
  storeToRefs(QFZCalculator);
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  setAlert: {
    type: Function,
    default: () => {},
  },
  compareValue: {
    type: Boolean,
    default: false,
  },
  setComplete: {
    type: [Function || Boolean],
    default: false,
  },
});
const router = useRouter();
const route = useRoute();
const alertTotalRvenue = ref(false);
const data = ref(fzpCalcRevenueData);
const handleChange = (e, model) => {
  let value = "";
  const index = e.target.selectionStart;
  const prevValue = e.target.value;
  const currentKey = String.fromCharCode(e.keyCode);
  value = insertChar(prevValue, currentKey, index);
  if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(value)) {
    e.preventDefault();
    return;
  }
  value = Number(value);
  if (value < 0) {
    e.preventDefault();
    e.target.blur();
    props.setAlert(true, "Can not accept negative values");
    revenue.value[model] = "";
  }
  if (model !== "total_revenue_of_QFZP") {
    const totalRevenueOfQFZP = Number(revenue.value.total_revenue_of_QFZP);
    const total = data.value.reduce((a, b) => {
      if (b.model === "total_revenue_of_QFZP") {
        return a + 0;
      } else if (b.model === model) {
        return a + value;
      }
      return a + Number(revenue.value[b.model]);
    }, 0);
    if (value > totalRevenueOfQFZP) {
      e.preventDefault();
      e.target.blur();
      props.setAlert(
        true,
        "Value should not be greater than Total Revenue of Freezone Person value"
      );
      return;
    }
    if (total > totalRevenueOfQFZP) {
      e.preventDefault();
      e.target.blur();
      props.setAlert(
        true,
        "Sum of values should not be greater than Total Revenue of Freezone Person value"
      );
    }
  }
};
const acceptDiscrepancy = async () => {
  await props.setComplete(true);
  router.push(
    `/entity/financial-data/profit-loss/income/${route.params.id}?financial_period=${route.query.financial_period}&financial_year=${route.query.financial_year}`
  );
};
const handleCancel = () => {
  // revenue.value["total_revenue_of_QFZP"] = Number(
  //   original_revenue_value_from_profitloss.value
  // );
  alertTotalRvenue.value = false;
};
const checkNonNegative = (model) => {
  const value = Number(revenue.value[model]);
  if (value < 0) {
    props.setAlert("Can not accept negative values");
    revenue.value[model] = "";
  }
};
const openCloseInput = async (model, value) => {
  if (props.disabled) {
    return;
  }
  data.value = data.value.map((item) => {
    if (item.model === model) {
      return { ...item, showInput: value };
    }
    return item;
  });
  if (value) {
    const input = await document.getElementById(`input-qfz-calc-${model}`);
    input.focus();
  }
};
const handleBlur = async (model, value) => {
  await openCloseInput(model, value);
  await checkNonNegative(model);
  if (props.compareValue && model == "total_revenue_of_QFZP") {
    if (
      Number(revenue.value[model]) !=
      Number(original_revenue_value_from_profitloss.value)
    ) {
      alertTotalRvenue.value = true;
    }
  }
};
const handleTab = async (e, current_model) => {
  e.preventDefault();
  const pressed = e.key;
  if (pressed === "ArrowUp") {
    return handleUpArrow(e, current_model);
  }
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
const handleUpArrow = async (e, current_model) => {
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) - 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
</script>
<style scoped>
.header {
  min-height: 44px;
  /* background: #0f75bc; */
  padding-right: 100px;
  padding-left: 60px;
}

.header-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #2a9ed8;
}

.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}
.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  border-bottom: 1px solid #747171;
  /*background: #fafbff;*/
  padding-right: 100px;
  padding-left: 26px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}
.count {
  flex: 0.75;
}
.item-name {
  flex: 18;
}
@media screen and (max-width: 600px) {
  .count {
    flex: 2;
  }
}
b,
strong {
  margin-left: -16px;
}
.num {
  background-color: #000000;
}
.head {
  text-align: center;
  margin-bottom: -8px;
  margin-right: -50px;
}
.mb-00:hover {
  background-color: #2a9ed8;
  width: 100px;
  height: 30px;
  text-align: center;
  /*  padding-left: 20px;
  padding-right: 20px;
  margin-left: -200px;*/
}
.clickable-text:focus {
  background-color: #2a9ed8;
  width: 100px;
  height: 30px;
  margin-left: -200px;
}
</style>

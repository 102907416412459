<template>
  <Loader v-if="loading" />
  <div v-else class="w-100">
    <ShowStatus :modal="editStatus" :set-modal="setStatus" />
    <CustomTable
      :Data="data"
      :Headers="headers"
      :haveIndex="true"
      :actions="actions"
    />
  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable.vue";
import { storeToRefs } from "pinia";
import { ref, onMounted } from "vue";
import { useEntityStore } from "../../../../store/entity";
import {
  getEntityItem,
  getEntityAttribute,
} from "../../../../store/entityActions/get";
import { useRoute, useRouter } from "vue-router";
import { useCountryStore } from "../../../../store/country";
import Loader from "@/components/common/Loader.vue";
import { dateMDY } from "../../../../helpers/date";
import { generate } from "randomized-string";
import tableEdit from "@/assets/svg/table-edit.svg";
import tableDelete from "../../../../assets/svg/trash.svg";
import { deleteEntity } from "../../../../store/entityActions/delete";
import ShowStatus from "./ShowStatus.vue";

export default {
  name: "ListOfDirectors",
  components: { CustomTable, Loader, ShowStatus },
  setup() {
    const headers = ref([
      "Name",
      "Address",
      "Emirates ID Number",
      "Contact Number",
      "Email",
      "Gender",
      "Designation",
      "Nationality",
      "Passport Number",
      "Passport Expiry Date",
      "Appoint. Date",
      "Cessation Date",
    ]);
    const data = ref([]);
    const loading = ref(true);
    const route = useRoute();
    const router = useRouter();
    const entityStore = useEntityStore();
    const countryStore = useCountryStore();
    const { DirectorDetails, ownershipDetails, entity } =
      storeToRefs(entityStore);
    const editStatus = ref(false);
    const setStatus = (status) => {
      editStatus.value = status;
    };
    const actions = ref([
      {
        id: generate(),
        icon: tableEdit,
        onClick: async (id) => {
          if (entity.value?.data?.entity[0]?.file_return_status === "Filed") {
            setStatus(true);
            return;
          }
          await getEntityItem(
            "ownershipDetails",
            "/entityOwnerDetails",
            route.params.id
          );
          let directorDetail = DirectorDetails.value.data.find(
            (director) => director.id == id
          );
          let owner_id = directorDetail?.owner_ship_id;
          router.push(
            `/entity/add-entity/director-details/${route.params.id}/${id}?owner_id=${owner_id}`
          );
        },
      },
      {
        id: generate(),
        icon: tableDelete,
        onClick: async (id) => {
          if (
            confirm("Are you sure you want to delete this director detail?")
          ) {
            await deleteEntity(
              id,
              "/entityDirectorDetails",
              (message) => {},
              () => location.reload()
            );
          }
        },
      },
    ]);
    onMounted(async () => {
      loading.value = true;
      await getEntityItem(
        "DirectorDetails",
        "/entityDirectorDetails",
        route.params.id
      );
      const Data = [];
      for (let i = 0; i < DirectorDetails.value.data?.length; i++) {
        const details = DirectorDetails.value.data[i];
        const designationName = await getEntityAttribute(
          "designation",
          "/designation",
          details?.designation
        );
        const countryName = await countryStore.getCountryName(
          details?.nationality
        );
        const item = {
          id: details?.id,
          name: details?.name,
          address: details?.address,
          uaeId: details?.uae_id,
          contact: details?.mobile_no,
          email: details?.email,
          gender: details?.gender,
          designation: designationName,
          nationality: countryName,
          passport_no: details?.passport_no,
          passport_expiry_date: dateMDY(details?.passport_expiry_date),
          appointment_date: dateMDY(details?.appointment_date),
          cessation_date: dateMDY(details?.cessation_date),
        };
        Data.push(item);
      }
      data.value = Data;
      loading.value = false;
    });
    return {
      headers,
      data,
      DirectorDetails,
      loading,
      actions,
      editStatus,
      setStatus,
    };
  },
};
</script>

<style scoped>
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

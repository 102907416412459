<template>
  <LoaderContainer :loading="loading">
    <ParticularsAssets :view="!!status" :set-saving-status="setSavingStatus" />
    <FVTPLAssets :view="!!status" :set-saving-status="setSavingStatus" />
    <DerivativeFinacialInstruments
      :view="!!status"
      :set-saving-status="setSavingStatus"
    />
    <FVOCIAssets :view="!!status" :set-saving-status="setSavingStatus" />
    <AmortisedCostAssets
      :view="!!status"
      :set-saving-status="setSavingStatus"
    />
    <TradeAndReceivablesAssets
      :view="!!status"
      :set-saving-status="setSavingStatus"
    />
    <InvestmentProperties
      :view="!!status"
      :set-saving-status="setSavingStatus"
    />
    <PlantAndEquipment :view="!!status" :set-saving-status="setSavingStatus" />
    <IntangibleAssets :view="!!status" :set-saving-status="setSavingStatus" />
    <DeferredIncomeTax :view="!!status" :set-saving-status="setSavingStatus" />
    <InvestmentJointVenture
      :view="!!status"
      :set-saving-status="setSavingStatus"
    />
    <InvestmentsInAssociate
      :view="!!status"
      :set-saving-status="setSavingStatus"
    />
    <Total :view="!!status" />
    <CurrentAssetParticulars
      :view="!!status"
      :set-saving-status="setSavingStatus"
    />
    <CashEquivalents :view="!!status" :set-saving-status="setSavingStatus" />
    <currentAssetDerivatve
      :view="!!status"
      :set-saving-status="setSavingStatus"
    />
    <currentAssetTrade :view="!!status" :set-saving-status="setSavingStatus" />
    <currentAssetInventories
      :view="!!status"
      :set-saving-status="setSavingStatus"
    />
    <NoncurrentAssetClassifiedSale
      :view="!!status"
      :set-saving-status="setSavingStatus"
    />
    <CurrentAssetTotal :view="!!status" />
  </LoaderContainer>
</template>

<script setup>
import ParticularsAssets from "./Particulars.vue";
import FVTPLAssets from "./FVTPL.vue";
import DerivativeFinacialInstruments from "./DerivativeInstruments.vue";
import FVOCIAssets from "./FVOCI.vue";
import AmortisedCostAssets from "./AmortisedCost.vue";
import TradeAndReceivablesAssets from "./TradeAndReceivables.vue";
import InvestmentProperties from "./InvestmentProperties.vue";
import PlantAndEquipment from "./PlantAndEquipment.vue";
import IntangibleAssets from "./IntangibleAssets.vue";
import DeferredIncomeTax from "./DeferredIncomeTax.vue";
import InvestmentJointVenture from "./InvestmentJointVenture.vue";
import InvestmentsInAssociate from "./InvestmentsInAssociates.vue";
import CurrentAssetParticulars from "./Current/CurrentAssetParticulars.vue";
import CashEquivalents from "./Current/CashEquivalents.vue";
import currentAssetDerivatve from "./Current/currentAssetDerivatve.vue";
import currentAssetTrade from "./Current/currentAssetTrade.vue";
import currentAssetInventories from "./Current/currentAssetInventories.vue";
import NoncurrentAssetClassifiedSale from "./Current/NoncurrentAssetClassifiedSale.vue";
import CurrentAssetTotal from "./Current/CurrentAssetTotal.vue";
import Total from "./Total.vue";
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { getEntityItem } from "../../../../../store/entityActions/get";
import { useRoute } from "vue-router";
import { useEntityStore } from "../../../../../store/entity";
import { useFinancialDataStore } from "../../../../../store/financial.store";
import LoaderContainer from "../../../../common/LoaderContainer.vue";

defineProps({
  view: {
    type: Boolean,
    default: false,
  },
  setSavingStatus: {
    type: Function,
    default: () => {},
  },
});
const entityStore = useEntityStore();
const { entity } = storeToRefs(entityStore);
const financialDataStore = useFinancialDataStore();
const route = useRoute();
const { loading } = storeToRefs(financialDataStore);
onMounted(async () => {
  if (!entity.value.data) {
    const id = route.params.id;
    await getEntityItem("entity", "/entity", id);
  }
  loading.value = true;
});
</script>

<style scoped>
.tab-button {
  width: 140px;
  height: 44px;
  border-radius: 4px;
  background: #eff3f3;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}

.tab-button.active {
  background: linear-gradient(90deg, #101432 0%, #00a7a7 100%);
  color: #fafbff;
}

.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.title-container {
  padding: 25px 10px 16px 10px;
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.055em;
  color: #000000;
}

.subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
  font-style: normal;
}
.save-button:disabled {
  opacity: 0.5;
}
</style>

export const getMax = (...args) => {
  let max = 0;
  for (let i = 0; i < args.length; i++) {
    let value = Number(args[i]);
    if (value > max) {
      max = value;
    }
  }
  return max;
};

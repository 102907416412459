export function insertChar(string, char, position) {
  if (!position) {
    position = 0;
  }

  if (!char) {
    char = "";
  }

  return string.slice(0, position) + char + string.slice(position);
}

export const getThumbnail = (string) => {
  const allArray = string.split(" ");
  const firstWord = allArray[0];
  const secondWord = allArray[1];
  let firstChar = "";
  let secondChar = "";
  if (firstWord) {
    firstChar = firstWord.charAt(0);
  }
  if (secondWord) {
    secondChar = secondWord.charAt(0);
  }
  return (firstChar + secondChar).toUpperCase();
};

export const changeObjectToQueryString = (object) => {
  let string = "";
  Object.keys(object).forEach((key, index) => {
    if (!index) {
      string += `${key}=${object[key]}`;
      return;
    }
    string += `&${key}=${object[key]}`;
  });
  return string;
};

export const getExactValue = (val) => {
  console.log("val", val);
  if (!val) {
    return "";
  } else if (
    val == null ||
    val == undefined ||
    val == "null" ||
    val == "undefined" ||
    val == "NaN" ||
    val == "0"
  ) {
    return "";
  } else if (typeof val === "string") {
    if (!String(val)) {
      return "";
    }
  } else if (typeof val === "number") {
    if (isNaN(val) || val === undefined || !val) {
      return "";
    }
  }
  return val;
};

import fetchWrapper from "@/helpers/fetchWrapper";
import { useEntityStore } from "../entity";
import { useRoute } from "vue-router";

const getQuery=()=>{
  const route=useRoute()
  const {pathname}=window.location
  let query=''
  if(pathname.includes('/entity/master-data'))
  {
    if(route?.query?.financial_period){
      query=`?financial_period=${route.query.financial_period}`
      if(route?.query?.financial_year){
        query+=`&financial_year=${route.query.financial_year}`
      }
    }
    else {
      if(route?.query?.financial_year){
        query=`?financial_year=${route.query.financial_year}`
      }
    }
  }
  return query
}
export const getEntityItems = async (state, endPoint) => {
  const entityStore = useEntityStore();
let query= getQuery()
  try {
    entityStore.makeStateLoading(state);
    const res = await fetchWrapper.get(endPoint+query);
    if (res.data?.result === 1) {
      entityStore.addItemsToState(state, res?.data.data);
    } else {
      await entityStore.addItemsToState(state, []);
      entityStore.makeErrorToState(
        state,
        res.data?.error ? res.data?.error?.join(",") : res.data?.message
      );
    }
  } catch (err) {
    entityStore.makeErrorToState("state", err?.response?.data?.message || "");
  }
};

export const getEntityItem = async (state, endPoint, id) => {
  const entityStore = useEntityStore();
  const query= getQuery()
  try {
    entityStore.makeStateLoading(state);
    const res = await fetchWrapper.get(endPoint + "/" + id+query);
    if (res.data?.result === 1) {
      await entityStore.addItemsToState(state, res?.data.data);
    } else {
      await entityStore.addItemsToState(state, []);
      entityStore.makeErrorToState(
        state,
        res.data?.error ? res.data?.error?.join(",") : res.data?.message
      );
    }
  } catch (err) {
    console.log(err);
    entityStore.makeErrorToState(state, err?.message);
  }
};
/**
 *
 * @param {*} state=> Pinia state name in entity store
 * @param {*} endPoint => Api End point
 * @param {*} id => item to find in the store
 * @param {*} att => attribute name to get from the found item
 * @returns => string of the object attribure
 */
export const getEntityAttribute = async (
  state,
  endPoint,
  id,
  att = "name",
  getNew = false
) => {
  const entityStore = useEntityStore();
  if (!entityStore[state].data?.length || getNew) {
    await getEntityItems(state, endPoint);
  }

  const find = entityStore[state].data?.find(
    (item) => Number(item.id) === Number(id)
  );
  return find ? find[att] : "";
};

export const getEntityAttributes = async (
  state,
  endPoint,
  id,
  att = "name"
) => {
  const entityStore = useEntityStore();
  if (!entityStore[state].data?.length) {
    await getEntityItems(state, endPoint, id);
  }
  const find = entityStore[state].data?.find(
    (item) => Number(item.id) === Number(id)
  );
  console.log(find);
  return find ? find[att] : "";
};

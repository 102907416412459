<template>
  <div v-if="subscriptions.is_vat_applicable === 'Y'" class="w-100">
    <div class="d-flex align-items-center justify-content-between">
      <p class="price-desc flex-2 mb-0">Do you have VAT Registration Number?</p>
      <div class="flex-1 d-flex justify-content-end">
        <v-switch
          v-model="toggle"
          color="#0F75BC"
          inset
          hide-details
        ></v-switch>
      </div>
    </div>
    <div
      v-if="toggle"
      class="d-flex align-items-center justify-content-between my-2"
    >
      <p class="price-desc mb-0 w-50">Enter VAT Registration Number</p>
      <div class="w-50 d-flex justify-content-end">
        <input
          type="number"
          class="clickable-text w-100"
          :value="vat"
          @input="(e) => setVat(e.target.value)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useSubscriptionStore } from "../../../store/subscription";
import { storeToRefs } from "pinia";
const props = defineProps({
  vat: {
    type: String,
    default: "",
  },
  setVat: {
    type: Function,
    default: () => {},
  },
});
const toggle = ref(false);
const subscriptionStore = useSubscriptionStore();
const { subscriptions } = storeToRefs(subscriptionStore);
</script>

<style scoped>
.price-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #382628;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 10;
}
</style>

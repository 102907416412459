<template>
  <div class="d-flex flex-wrap justify-content-end align-items-center">
    <select
      v-model="financial_period"
      disabled
      class="date-select pl-2 mt-2 mr-3"
    >
      <option
        v-for="(year, index) in financialPeriod.data"
        :key="year.id"
        class="option"
        :value="year.id"
        :selected="!index"
      >
        {{ year.name }}
      </option>
    </select>
    <select v-model="financial_year" class="date-select pl-2 mt-2 mr-3">
      <option
        v-for="year in financialYear.data"
        :id="year.id"
        :value="year.id"
        :key="year.id"
        class="option"
      >
        {{ year.name }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import { useEntityStore } from "../../../store/entity";
import {
  getEntityItem,
  getEntityItems,
} from "../../../store/entityActions/get";

const entityStore = useEntityStore();
const route = useRoute();
const router = useRouter();
const {
  financialYear: financialPeriod,
  financialPeriod: financialYear,
  entity,
} = storeToRefs(entityStore);
const financial_year = ref(
  route.query.financial_year ? route.query.financial_year : ""
);
const financial_period = ref(
  route.query.financial_period ? route.query.financial_period : 1
);
const pushTo = (financial_year, financial_period) => {
  const { pathname } = window.location;
  const tab = pathname.split("/")[2];
  if (tab === "general-information") {
    router.replace({
      path: `/entity/general-information/${route.params.id}`,
      query: { ...route.query, financial_year, financial_period },
    });
  }
};
onMounted(async () => {
  if (!financialPeriod.value.data.length) {
    await getEntityItems("financialYear", "/financialYear");
  }
  const Financial_period = entity.value.data?.entity[0]?.financial_period?.id;
  financial_period.value = Financial_period
    ? Financial_period
    : financial_period.value;

  await getEntityItem(
    "financialPeriod",
    "/financialYear",
    financial_period.value
  );
  const fy = await localStorage.getItem("financial_year");
  if (fy) {
    financial_year.value = fy;
  } else {
    financial_year.value = financial_year.value
      ? financial_year.value
      : financialYear.value.data[0]?.id;
    localStorage.setItem("financial_year", financial_year.value);
  }
  pushTo(financial_year.value, financial_period.value);
});

const unWatchFinancialYear = watch(financial_year, async (newValue) => {
  await localStorage.setItem("financial_year", newValue);
  pushTo(newValue, financial_period.value);
});
onBeforeRouteLeave(async () => {
  unWatchFinancialYear();
  return true;
});
onUnmounted(() => {
  unWatchFinancialYear();
});
</script>

<style scoped>
.date-select {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  width: 163px;
  height: 40px;
}
.date-select:focus {
  outline: none;
}
.option {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

import {
  Layout,
  Login,
  Register,
  LogInWithOTp,
  ForgetPassword
} from "@/views/account";

export default {
  path: "/account",
  component: Layout,
  children: [
    { path: "", redirect: "login" },
    { path: "signin", component: Login },
    { path: "signup", component: Register },
    { path: "login-otp", component: LogInWithOTp },
    { path: "forget-password", component: ForgetPassword }
  ]
};

<template>
  <LoaderContainer :loading="loading">
    <div class="row">
      <div class="col-lg-12">
        <div
          class="header d-flex align-items-center w-100 justify-content-between"
        >
          <p class="header-title mb-0">Particulars</p>
          <p class="header-title mb-0">Amount</p>
        </div>
        <Income />
        <OtherIncome />
        <Expenses />
        <EmployeeBenefitsExpense />
        <EmployeeAdditionalBenefits />
        <OtherExpenses />
        <FinanceCosts />
        <LessExpense />
      </div>
    </div>
  </LoaderContainer>
</template>

<script setup>
import LoaderContainer from "../../../../common/LoaderContainer.vue";
import { useNoneIFRSProfitLoss } from "../../../../../store/NonIFRS/profitLoss.store";
import { storeToRefs } from "pinia";
import Income from "./Income.vue";
import OtherIncome from "./OtherIncome.vue";
import Expenses from "./Expenses.vue";
import EmployeeBenefitsExpense from "./EmployeeBenefitsExpense.vue";
import OtherExpenses from "./OtherExpenses.vue";
import FinanceCosts from "./FinanceCosts.vue";
import LessExpense from "./LessExpense.vue";
import EmployeeAdditionalBenefits from "./EmployeeAdditionalBenefits.vue";

const nonIFRSPlStore = useNoneIFRSProfitLoss();
const { loading } = storeToRefs(nonIFRSPlStore);
</script>

<style lang="scss" scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
</style>

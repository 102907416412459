<template>
  <div class="d-flex flex-wrap justify-content-end align-items-center">
    <select
      :value="fp"
      @input="(e) => setFinancialPeriod(e.target.value)"
      class="date-select pl-2 mt-2 mr-3"
    >
      <option :value="0">Financial Period</option>
      <option
        v-for="year in financialPeriod.data"
        :key="year.id"
        class="option"
        :value="year.id"
      >
        {{ year.name }}
      </option>
    </select>
    <select
      id=""
      :value="fy"
      name=""
      class="date-select pl-2 mt-2 mr-3"
      @input="(e) => setFinancialYear(e.target.value)"
    >
      <option :value="0">Financial Year</option>
      <option
        v-for="year in financialYear.data"
        :key="year.id"
        class="option"
        :value="year.id"
      >
        {{ year.name }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { onMounted } from "vue";

import { useEntityStore } from "../../../store/entity";
import {
  getEntityItem,
  getEntityItems,
} from "../../../store/entityActions/get";

const props = defineProps({
  onDateChange: {
    type: Function,
    default: () => {},
  },
  fy: {
    type: Number,
    default: 0,
  },
  fp: {
    type: Number,
    default: 0,
  },
});
const entityStore = useEntityStore();

const { financialYear: financialPeriod, financialPeriod: financialYear } =
  storeToRefs(entityStore);

onMounted(async () => {
  if (!financialPeriod.value.data.length) {
    await getEntityItems("financialYear", "/financialYear");
  }
  if (props.fp) {
    await getEntityItem("financialPeriod", "/financialYear", props.fp);
  }
});

const setFinancialPeriod = async (newValue) => {
  // if (newValue !== oldValue) {
  await props.onDateChange("financial_period", Number(newValue));
  if (Number(newValue)) {
    await getEntityItem("financialPeriod", "/financialYear", newValue);
  } else {
    financialYear.value.data = [];
    await props.onDateChange("financial_year", "");
  }
};
const setFinancialYear = async (newValue) => {
  await props.onDateChange("financial_year", Number(newValue));
};
</script>

<style scoped>
.date-select {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  width: 170px;
  height: 40px;
  margin-bottom: 10px;
}
.date-select:focus {
  outline: none;
}
.option {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

import { defineStore, storeToRefs } from "pinia";
import { basicDataStructure } from "../helpers/storeStructure";
import fetchWrapper from "../helpers/fetchWrapper";
import { useEntityStore } from "./entity";
import { getEntityItem } from "./entityActions/get";
import { getDurationInDays } from "../helpers/date";
import { Toast } from "../helpers/toast";
import { useIFRSStore } from "./NonIFRS/IFRS.store";
export const useStatusStore = defineStore("statusStore", {
  state: () => {
    return {
      ...basicDataStructure,
      status: 0
    };
  },
  actions: {
    async getStatus(entity_id, financial_year, financial_period) {
      try {
        this.loading = true;

        const entityStore = useEntityStore();
        const { entity } = storeToRefs(entityStore);
        const ifrsStore = useIFRSStore();
        const { data, qualified_for_freezone, license_authority_type } =
          storeToRefs(ifrsStore);
        let qfzp =
          license_authority_type?.value == 2 &&
          data.value === "IFRS" &&
          qualified_for_freezone.value === "Y";
        let url = qfzp
          ? `/qfzpComputationStatus?entity_id=${entity_id}&financial_year=${financial_year}&financial_period=${financial_period}`
          : `/computationStatus?entity_id=${entity_id}&financial_year=${financial_year}&financial_period=${financial_period}`;
        const res = await fetchWrapper.get(url);
        if (res.data.result == 1) {
          if (!entity.value.data) {
            await getEntityItem("entity", "/entity", entity_id);
          }
          const Entity = entity.value.data?.entity[0];
          const duration = getDurationInDays(Entity?.subscription_end_date);
          this.status = await (Number(res.data?.status) || !duration);
          this.error = await false;
          this.loading = await false;
        } else {
          this.error = res.data?.error
            ? res.data?.error?.join(",")
            : res.data?.message;
          this.loading = false;
        }
      } catch (err) {
        console.log(err);
        this.error = err?.response?.data?.message || "";
        this.loading = false;
      }
    },
    async updateStatus(payload, toast, callBack = () => {}) {
      toast.makeInfoToast("Changing Status...");
      try {
        const ifrsStore = useIFRSStore();
        const { data, qualified_for_freezone, license_authority_type } =
          storeToRefs(ifrsStore);
        let qfzp =
          license_authority_type.value == 2 &&
          data.value === "IFRS" &&
          qualified_for_freezone.value === "Y";
        let url = qfzp ? `/updateQfzpCOIStatus` : `/updateCOIStatus`;
        const res = await fetchWrapper.post(url, payload);
        if (res.data.result === 1) {
          toast.makeSuccessToast("Saved successfully");
          callBack(res.data, toast);
        } else {
          toast.makeErrorToast(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message,
            "danger"
          );
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    },
    async checkStatus(payload, callBack = () => {}) {
      const toast = new Toast("Checking Status...");
      try {
        const res = await fetchWrapper.post(`/validationBeforeCOI`, payload);
        if (res.data.result === 1) {
          callBack(res?.data, toast);
        } else {
          toast.makeErrorToast(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message
          );
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    }
  }
});

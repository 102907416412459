import { useComputeIncomeTaxStore } from "../../../../store/incometax.store";

export const autoSaveChp9Line4and7 = async (route, setSavingStatus) => {
  const computeTaxStore = useComputeIncomeTaxStore();
  setSavingStatus("loading", true);
  await computeTaxStore.autoSaveChapter9(route, setSavingStatus, async () => {
    await computeTaxStore.getchapter9(
      route.params.id,
      route.query.financial_year,
      route.query.financial_period,
      route.query.current_business
    );
    setTimeout(() => {
      setSavingStatus("loading", false);
      setSavingStatus("error", false);
      setSavingStatus("success", false);
    }, 3000);
  });
};

<template>
  <div class="w-100">
    <div class="header d-flex align-items-center w-100 justify-content-between">
      <p class="header-title mb-0">Intangible Assets</p>
      <p class="header-title mb-0">
        {{ NumberFormatter(totalNonCurrentAsset.intangible_assets) }}
      </p>
    </div>
    <form
      v-for="(item, index) in data"
      :id="item.model"
      :key="item.id"
      :class="`w-100 table-body ${!index || !(index % 2) ? 'white' : 'gray'} ${
        item.sub ? 'sub' : ''
      } d-flex align-items-center justify-content-between`"
      @submit="handleSubmit"
    >
      <p class="mb-0 body-title">
        {{ item.name }}
      </p>
      <p
        v-if="!item.showInput"
        :class="`body-title ${isHeader(item.model) ? 'bold' : ''} mb-0`"
        @click="openCloseInput(item.model, true)"
      >
        {{ NumberFormatter(NonCurrentAssetData[item.model]) }}
      </p>

      <input
        v-if="!isHeader(item.model)"
        :id="'input-' + item.model"
        v-model="NonCurrentAssetData[item.model]"
        :hidden="!item.showInput"
        type="number"
        class="clickable-text"
        @focus="setOriginalValue(item.model, NonCurrentAssetData[item.model])"
        @blur="handleBlur(item.model, false)"
        @keydown.tab="(e) => handleTab(e, item.model)"
      />
    </form>
  </div>
</template>

<script setup>
import { generate } from "randomized-string";
import { ref, watch, computed } from "vue";

import { storeToRefs } from "pinia";

import { useFinancialDataStore } from "../../../../../store/financial.store";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";
import { useRoute, useRouter } from "vue-router";
import { autoSaveNonCurrentAssets } from "./helper";
const props = defineProps({
  view: {
    type: Boolean,
    default: false,
  },
  setSavingStatus: {
    type: Function,
    default: () => {},
  },
});
const route = useRoute();
const router = useRouter();
const financialDataStore = useFinancialDataStore();
const { NonCurrentAssetData, totalNonCurrentAsset } =
  storeToRefs(financialDataStore); //get initialvalue from store

const data = ref([
  {
    id: generate(),
    name: "1.Goodwill",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "intangible_assets_goodwill",
  },
  {
    id: generate(),
    name: "1.1 Beginning of Financial Year",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "intangible_assets_goodwill_begining_of_financial_year",
  },
  {
    id: generate(),
    name: "1.2  Additions (+)",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "intangible_assets_goodwill_additions",
  },
  {
    id: generate(),
    name: "1.3  Impairments (-)",
    sub: true,
    amount: "0000.00",
    showInput: false,
    model: "intangible_assets_goodwill_impairments",
  },
  {
    id: generate(),
    name: "2.Trademarks and licenses",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "intangible_assets_tradmarks_and_license",
  },
  {
    id: generate(),
    name: "2.1  Beginning of Financial Year",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "intangible_assets_tradmarks_and_license_begining_of_financial_yr",
  },
  {
    id: generate(),
    name: "2.2 Additions (+)",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "intangible_assets_tradmarks_and_license_additions",
  },
  {
    id: generate(),
    name: "2.3 Impairments (-)",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "intangible_assets_tradmarks_and_license_impairments",
  },
  {
    id: generate(),
    name: "3.Computer software and licenses",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "intangible_assets_computer_software_and_license",
  },
  {
    id: generate(),
    name: "3.1  Beginning of Financial Year",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "intangible_assets_computer_software_and_license_bgng_of_fncl_yr",
  },
  {
    id: generate(),
    name: "3.2 Additions (+)",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "intangible_assets_computer_software_and_license_additions",
  },
  {
    id: generate(),
    name: "3.3 Impairments (-)",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "intangible_assets_computer_software_and_license_impairments",
  },
]);

const getValue = (value) => {
  return Number(NonCurrentAssetData.value[value]) || 0;
};
// 1. Good will
NonCurrentAssetData.value.intangible_assets_goodwill = computed(() => {
  const keys = [
    "intangible_assets_goodwill_begining_of_financial_year",
    "intangible_assets_goodwill_additions",
    "intangible_assets_goodwill_impairments",
  ];
  return keys.reduce((a, b) => {
    if (b === "intangible_assets_goodwill_impairments") {
      return a - getValue(b);
    }
    return a + getValue(b);
  }, 0);
});
//  2.Trademarks and licenses
NonCurrentAssetData.value.intangible_assets_tradmarks_and_license = computed(
  () => {
    const keys = [
      "intangible_assets_tradmarks_and_license_begining_of_financial_yr",
      "intangible_assets_tradmarks_and_license_additions",
      "intangible_assets_tradmarks_and_license_impairments",
    ];
    return keys.reduce((a, b) => {
      if (b === "intangible_assets_tradmarks_and_license_impairments") {
        return a - getValue(b);
      }
      return a + getValue(b);
    }, 0);
  }
);

// 3.Computer software and licenses
NonCurrentAssetData.value.intangible_assets_computer_software_and_license =
  computed(() => {
    const keys = [
      "intangible_assets_computer_software_and_license_bgng_of_fncl_yr",
      "intangible_assets_computer_software_and_license_additions",
      "intangible_assets_computer_software_and_license_impairments",
    ];
    return keys.reduce((a, b) => {
      if (b === "intangible_assets_computer_software_and_license_impairments") {
        return a - getValue(b);
      }
      return a + getValue(b);
    }, 0);
  });

// Intangible Assets
totalNonCurrentAsset.value.intangible_assets = computed(() => {
  const keys = [
    "intangible_assets_goodwill",
    "intangible_assets_tradmarks_and_license",
    "intangible_assets_computer_software_and_license",
  ];
  return keys.reduce((a, b) => a + getValue(b), 0);
});

const isHeader = (key) => {
  const keys = [
    "intangible_assets_goodwill",
    "intangible_assets_tradmarks_and_license",
    "intangible_assets_computer_software_and_license",
  ];
  return !!keys.find((itemKeys) => itemKeys === key);
};
const openCloseInput = async (model, value) => {
  if (props.view || route.query.business === "master" || isHeader(model)) {
    return;
  }
  data.value = data.value.map((item) => {
    if (item.model === model) {
      return { ...item, showInput: value };
    }
    return item;
  });
  if (value) {
    const input = await document.getElementById(`input-${model}`);
    input.focus();
  }
};
const handleSubmit = async (e) => {
  e.preventDefault();
  let current_model = e.target.id;
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
const handleTab = async (e, current_model) => {
  e.preventDefault();
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
const autoSave = async (model) => {
  const previousValue = data.value.find((item) => item.model === model).value;
  const currentValue = NonCurrentAssetData.value[model];
  await autoSaveNonCurrentAssets(
    previousValue,
    currentValue,
    props.setSavingStatus,
    route,
    router
  );
};
const handleBlur = async (model, openClose) => {
  await openCloseInput(model, openClose);
  await autoSave(model);
};
const setOriginalValue = (key, value) => {
  const index = data.value.findIndex((item) => item.model === key);
  data.value[index].value = value;
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}
</style>

import {
  uae_id_1,
  uae_id_2,
  uae_id_3,
  uae_id_4
} from "../constants/regex-format";

export const checkUaeId = (id) => {
  if (
    uae_id_1.test(id) ||
    uae_id_2.test(id) ||
    uae_id_3.test(id) ||
    uae_id_4.test(id)
  )
    return true;
  else return false;
};

import {
  EntityContainer,
  EntityLayout,
  AddEntityDetailsEntity,
  ContactDetails,
  AddTaxDetails,
  CheckBranch,
  BusinessActivity,
  CheckOutEntity,
  AddEntityContactDetails,
  OwnerShipDetails,
  BankingDetails,
  MasterDataList,
  ContactDetailList,
  OwnerShipDetailsList,
  BankingDetailsList,
  FinancialData,
  BalanceSheetAssets,
  BalanceSheetLiabilities,
  BalanceSheetView,
  ProfitLossIncome,
  ProfitLossExpenditure,
  ProfitLossView,
  EntityList,
  AuthorizedSignatoryDetailsForm,
  AuthorizedSignatoryDetailsList,
  DeclarationForm
} from "@/components/entity";
import { EntityDashboard } from "../components/dashboard";
import {
  ComputationTax,
  ChapterSeven,
  ChapterNine,
  ChapterEight,
  ChapterNineDeduction,
  ChapterEleven,
  ChapterTen,
  ChapterThirteen,
  OtherAdjustments
} from "../components/computation-tax";
import {
  DirectorDetails,
  ShareHolderDetails,
  BranchDetails
} from "../components/entity";
import {
  GeneralInformationContainer,
  CalculatorContainer,
  AuditorDetailsContainer
} from "../components/general-Information";

export default {
  path: "/entity",
  component: EntityLayout,
  children: [
    { path: "", component: EntityContainer },
    { path: "entity-details/:id", component: EntityList },
    {
      path: "add-entity/entity-details/entity",
      component: AddEntityDetailsEntity
    },
    {
      path: "add-entity/entity-details/entity/:id",
      component: AddEntityDetailsEntity
    },
    {
      path: "add-entity/entity-details/license/:id",
      component: ContactDetails
    },
    {
      path: "add-entity/entity-details/license/:id/:sub_id",
      component: ContactDetails
    },
    {
      path: "add-entity/entity-details/tax-details/:id",
      component: AddTaxDetails
    },
    {
      path: "add-entity/entity-details/tax-details/:id/:sub_id",
      component: AddTaxDetails
    },
    {
      path: "add-entity/entity-details/check-branch/:id",
      component: CheckBranch
    },
    {
      path: "add-entity/entity-details/check-branch/:id/:sub_id",
      component: CheckBranch
    },
    {
      path: "add-entity/entity-details/business/:id",
      component: BusinessActivity
    },
    {
      path: "add-entity/entity-details/business/:id/:sub_id",
      component: BusinessActivity
    },
    { path: "add-entity/branch-details/:id", component: CheckOutEntity },
    {
      path: "add-entity/branch-details/:id/:sub_id",
      component: CheckOutEntity
    },
    {
      path: "add-entity/contact-details/:id",
      component: AddEntityContactDetails
    },
    {
      path: "add-entity/contact-details/:id/:sub_id",
      component: AddEntityContactDetails
    },
    {
      path: "add-entity/shareholder-details",
      component: AddEntityContactDetails
    },
    { path: "add-entity/ownership-details/:id", component: OwnerShipDetails },
    {
      path: "add-entity/ownership-details/:id/:sub_id",
      component: OwnerShipDetails
    },
    {
      path: "add-entity/director-details/:id",
      component: DirectorDetails
    },
    {
      path: "add-entity/director-details/:id/:sub_id",
      component: DirectorDetails
    },
    { path: "add-entity/banking-details/:id", component: BankingDetails },
    {
      path: "add-entity/banking-details/:id/:sub_id",
      component: BankingDetails
    },
    {
      path: "add-entity/shareholder-details/:id",
      component: ShareHolderDetails
    },
    {
      path: "add-entity/shareholder-details/:id/:sub_id",
      component: ShareHolderDetails
    }, 
    {
      path: "entity/authorized-signatory-details/:id",
      component: AuthorizedSignatoryDetailsList
    },
    {
      path: "entity/authorized-signatory-details/:id/:sub_id",
      component: AuthorizedSignatoryDetailsList
    },
    {
      path: "add-entity/authorized-signatory-details/:id",
      component: AuthorizedSignatoryDetailsForm
    },
    {
      path: "add-entity/authorized-signatory-details/:id/:sub_id",
      component: AuthorizedSignatoryDetailsForm
    },
    {
      path: "add-entity/declaration/:id",
      component: DeclarationForm
    },
    {
      path: "add-entity/declaration/:id/:sub_id",
      component: DeclarationForm
    },
    { path: "master-data/:id", component: MasterDataList },
    { path: "contact-details/:id", component: ContactDetailList }, 
    { path: "authorized-signatory-details/:id", component: AuthorizedSignatoryDetailsList },
    { path: "ownership-details/:id", component: OwnerShipDetailsList },
    { path: "branch-details/:id", component: BranchDetails },
    { path: "banking-details/:id", component: BankingDetailsList },
    { path: "financial-data/:id", component: FinancialData },
    {
      path: "financial-data/balance-sheet/assets/:id",
      component: BalanceSheetAssets
    },
    {
      path: "financial-data/balance-sheet/liabilities/:id",
      component: BalanceSheetLiabilities
    },
    {
      path: "financial-data/balance-sheet/view/:id",
      component: BalanceSheetView
    },
    {
      path: "financial-data/profit-loss/income/:id",
      component: ProfitLossIncome
    },
    {
      path: "financial-data/profit-loss/expenditure/:id",
      component: ProfitLossExpenditure
    },
    { path: "financial-data/profit-loss/view/:id", component: ProfitLossView },
    { path: "dashboard/:id", component: EntityDashboard },
    { path: "computation-tax/:id", component: ComputationTax },
    { path: "computation-tax/other-adjustments/:id", component: OtherAdjustments  },
    { path: "computation-tax/chapter-7/:id", component: ChapterSeven },
    { path: "computation-tax/chapter-8/:id", component: ChapterEight },
    { path: "computation-tax/chapter-9/:id", component: ChapterNine },
    { path: "computation-tax/chapter-10/:id", component: ChapterTen },
    { path: "computation-tax/chapter-11/:id", component: ChapterEleven },
    { path: "computation-tax/chapter-13/:id", component: ChapterThirteen },
    {
      path: "computation-tax/chapter-9/deduction/:id",
      component: ChapterNineDeduction
    },
    {
      path: "general-information/:id",
      component: GeneralInformationContainer
    },
    {
      path: "general-information/fzp-calculator/:id",
      component: CalculatorContainer
    },
    {
      path: "general-information/auditor-details/:id",
      component: AuditorDetailsContainer
    },
    
    
  ]
};

import { nonIFRSDataStructure } from "./non-ifrs.balance-sheet";

export const nonIFRSProfitLossData = {
  revenue_from_operations: nonIFRSDataStructure,
  sale_of_products: nonIFRSDataStructure,
  sale_of_services: nonIFRSDataStructure,
  other_operating_revenues: nonIFRSDataStructure,
  other_income: nonIFRSDataStructure,
  interest_income: nonIFRSDataStructure,
  dividend_income: {
    dividends_and_other_profit_distributions_received: "", // new
    any_other_income_participating_interest_specified_article_23: "",
    income_foreign_permanent_establishment_that_meet_condition_article_24: "",
  },
  net_gain_loss_on_sale_of_investment: nonIFRSDataStructure,
  other_non_operating_income: nonIFRSDataStructure,
  net_gain_loss_on_foriegn_currency_transaction: nonIFRSDataStructure,
  total_income: nonIFRSDataStructure,
  cost_of_material_consumed: nonIFRSDataStructure,
  purchase_of_stock_in_trade: nonIFRSDataStructure,
  changes_in_inventories_of_finish_goods_wip_sit: nonIFRSDataStructure,
  employee_benefits_expense: nonIFRSDataStructure,
  salary_and_wages: nonIFRSDataStructure,
  contribution_to_provident_and_other_funds: nonIFRSDataStructure,
  esop_espp: nonIFRSDataStructure,
  staff_welfare_expenses: nonIFRSDataStructure,
  additional_employee_benefits_expneses: {
    data: [],
    total: "",
  },
  other_expenses: nonIFRSDataStructure,
  consumption_of_stores_spares: nonIFRSDataStructure,
  power_and_fuel: nonIFRSDataStructure,
  rent: nonIFRSDataStructure,
  entertainment_expenses: nonIFRSDataStructure,
  insurance: nonIFRSDataStructure,
  rates_and_taxes_excluding_income_tax: nonIFRSDataStructure,
  misc_expenses: nonIFRSDataStructure,
  loss_of_foreign_currency_transactions: nonIFRSDataStructure,
  payment_of_auditors: nonIFRSDataStructure,
  net_loss_on_sale_of_investments: nonIFRSDataStructure,
  prior_period_items: nonIFRSDataStructure,
  other_borrowing_cost_except_interest: nonIFRSDataStructure,
  additional_other_expenses: {
    data: [],
    total: "",
  },
  ebitda: nonIFRSDataStructure,
  finance_costs: nonIFRSDataStructure,
  interest_expense: nonIFRSDataStructure,
  additional_finance_cost: {
    data: [],
    total: "",
  },
  earning_before_tax: nonIFRSDataStructure,
  tax_expense: nonIFRSDataStructure,
  corporat_tax: nonIFRSDataStructure,
  deferred_tax: nonIFRSDataStructure,
  earning_after_tax: nonIFRSDataStructure,
};

export const nonIFRSPLGroupData = () => {
  let data = {};
  for (let x in nonIFRSProfitLossData) {
    data[x] = "";
  }
  return data;
};
export const checkPopUpInProfitLoss = (key) => {
  if (key === "dividend_income") {
    return false;
  }
  let show = true;
  Object.keys(nonIFRSProfitLossData).forEach((itemKey) => {
    if (key === itemKey) {
      if (typeof nonIFRSProfitLossData[itemKey] === "string") {
        show = false;
      }
    }
  });
  return show;
};

export const noneIFRSExceptionalKeys = (key) => {
  const keys = [
    "additional_employee_benefits_expneses",
    "additional_other_expenses",
    "additional_finance_cost",
  ];
  return !!keys.find((itemKey) => itemKey === key);
};
export const nonIFRSPLTotalKeys = (key) => {
  const keys = [
    "total_current_assets",
    "total_non_current_liabilities",
    "total_assets",
    "total_current_liabilities",
    "total_liabilities",
    "total_shareholders_equity_and_liabilities",
    "share_holder_funds",
    "share_application_money_pending_allotment",
    "total_shares",
  ];
  return !!keys.find((itemKey) => itemKey === key);
};

import axios from "axios";

export function checkFileSize(file, size = 2) {
  // Get the file size in bytes.
  var fileSize = file?.size;

  // Convert the file size to MB.
  var fileSizeMB = fileSize / 1024 / 1024;

  // Return true if the file size is less than 2MB.
  return fileSizeMB < size;
}

export const createFileObjectFromUrl= async (url,fileName)=> {
  // Detect the type of file from the URL.
  const fileType = url.split('.').pop();

  // Create a new Blob object with the contents of the file.
  const blob = await fetch(url).then((response) => response.blob());

  // Create a new File object from the Blob object.
  const file = new File([blob], fileName, { type: fileType });

  // Return the File object.
  return file;
}
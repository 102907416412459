<template>
  <nav
    id="snaptax-navbar"
    class="snaptax-navbar d-flex align-items-center justify-content-between"
  >
    <AlertModal
      :open="open"
      message="Are you sure you want to logout ?"
      :on-confirm="logOut"
      :on-cancel="() => setModal(false)"
      :icon="logoutIcon"
    />
    <div class="search-toggle d-flex align-items-center mr-4">
      <img
        v-if="toggle"
        src="@/assets/svg/chevron-left.svg"
        alt
        @click="setToggle(false)"
      />
      <img
        v-else
        src="@/assets/svg/chevron-right.svg"
        class="mr-4"
        alt
        @click="setToggle(true)"
      />
      <p v-if="showEntityName" class="profile-name mb-0">{{ entityName }}</p>
    </div>
    <NavBarSavingStatus :loading="loading" :error="error" :success="success" />
    <div class="d-flex align-items-center justify-content-end">
      <div class="profile-container mr-5">
        <h4 class="profile-name mb-0">{{ name }}</h4>
        <p class="profile-access mb-0">
          {{ !Number(profile.data?.parent_id) ? access : "User" }}
        </p>
      </div>
      <NotificationContainer />
      <img
        src="@/assets/svg/power.svg"
        alt
        class="cursor-pointer"
        @click="() => setModal(true)"
      />
    </div>
  </nav>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useProfileStore } from "../../store/profile";
import { useSubscriptionStore } from "../../store/subscription";
import { ref, computed, reactive } from "vue";
import { NotificationContainer } from "../Notification/";
import AlertModal from "../common/AlertModal.vue";
import logoutIcon from "@/assets/svg/warning-error.svg";
import { useEntityStore } from "../../store/entity";
import { generate } from "randomized-string";
import NavBarSavingStatus from "./NavBarSavingStatus.vue";

defineProps({
  setToggle: {
    type: Function,
    default: () => {},
  },
  toggle: {
    type: Boolean,
    default: false,
  },
  showEntityName: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  error: {
    type: [Boolean, String],
    default: false,
  },
  success: {
    type: [Boolean, String],
    default: false,
  },
});

const subScriptionStore = useSubscriptionStore();
const entityStore = useEntityStore();
const { entity } = storeToRefs(entityStore);
const profileStore = useProfileStore();
const { profile } = storeToRefs(profileStore);
const open = ref(false);

const name = computed(() => {
  return `${profile.value.data?.first_name} ${profile.value.data?.last_name}`;
});
const access = computed(() => {
  return profile.value.data?.type;
});
const entityName = computed(() => {
  if (!entity.value.data?.entity) {
    return "";
  }
  const Entity = entity.value.data?.entity[0];
  if (Entity?.entity_type == 2) {
    return `${Entity?.entity_first_name} ${Entity?.entity_last_name}`;
  }
  return Entity?.entity_name;
});
const setModal = (value) => {
  open.value = value;
};

const logOut = async () => {
  subScriptionStore.unsubscribeStore();
  await localStorage.clear();
  await sessionStorage.clear();
  await caches.keys().then(function (names) {
    for (let name of names) {
      caches.delete(name);
    }
  });
  location.href = `/account/signin?r=${generate(24)}`;
};
</script>

<style scoped>
.snaptax-navbar {
  position: fixed;
  background: #ffffff;
  box-shadow: 0px 8px 8px rgba(187, 187, 187, 0.25);
  height: 83px;
  margin-top: 0;
  z-index: 3;
  width: 100% !important;
  padding-right: 200px;
}
.search-input {
  border: none;
  margin-left: -30px;
  padding-left: 40px;
}

.search-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #959292;
  text-align: start;
}
.search-input:focus {
  outline: 0;
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.profile-container {
  min-width: 150px;
}
.profile-name {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.profile-access {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.055em;
  color: #959292;
}
@media screen and (max-width: 600px) {
  .snaptax-navbar {
    padding-right: 30px !important;
  }
}
</style>

<template>
  <tr>
    <td class="table-rows  align-middle py-5">
      <p class="mb-0 total-amount  ms-auto">Taxable Income / (Loss)</p>
    </td>
    <td class="table-rows align-middle py-5"></td>
    <td class="table-rows border align-middle py-5">
      <p class="mb-0 total-amount">{{ NumberFormatter(taxableIncome) }}</p>
    </td>
  </tr>
  <tr>
    <td class="table-total align-middle py-5">
      <p class="mb-0 total-text">Tax Liability</p>
    </td>
    <td class="table-total align-middle py-5">
      <p class="mb-0 total-text"></p>
    </td>
    <td class="table-total border align-middle py-5">
      <Exemption />
    </td>
  </tr>
</template>

<script setup>
import { useCTaxCalculatorIndividualStore } from "../../../store/CTaxCalculatorIndividual";
import { storeToRefs } from "pinia";
import { NumberFormatter } from "../../../helpers/numberFormatter";
import Exemption from "./Exemption.vue";

const taxCalculator = useCTaxCalculatorIndividualStore();
const { taxableIncome } = storeToRefs(taxCalculator);
</script>

<style scoped>
.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border-bottom-width: unset !important;
}

.table-header {
  min-height: 39px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.table-rows {
  background: #ffffff;
  padding-top: 14px;
  padding-bottom: 14px;
}
.table-rows.border {
  border-bottom: 1px solid #747171 !important;
  border-top: 1px solid #747171 !important;
}
.yes-text {
  font-style: normal;
  font-weight: 400;
  font-size: 11.5235px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: #000000;
}
.empty {
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.5px solid rgba(149, 146, 146, 0.3);
}
.cursor-pointer {
  cursor: pointer;
}
.data-img {
  width: 110px;
  height: 110px;
  object-fit: cover;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
tbody:last-child {
  border: 2.5px solid #fff;
  border-bottom: 3px solid rgba(0, 0, 0, 0.651);
}
.custom-input {
  text-align: left !important;
  border: none;
  border-bottom: 1px solid#000000;
  padding-bottom: 1px;
  padding-left: 4px;
  height: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input:focus {
  outline: 0;
  border-bottom: 1px solid#0f75bc;
}
.total-amount {
  font-style: normal;
  margin-left: 35px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
/* .table-total {
  background: #0f75bc !important;
  --bs-table-striped-bg: #0f75bc !important;
} */
.total-text {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #0f75bc; 
}
</style>

<template>
  <form @submit="signup">
    <TermsAndPolicy :modal="modal" :set-modal="setModal" />
    <ModalSuccessMessage
      :open="open"
      message="Your account has been create successfully.Please Login"
      title="Congratulations!"
      :close-modal="closeModal"
    />
    <div
      class="wrapper w-100 d-flex flex-column align-items-center justify-content-evenly"
    >
      <div class="h1">Create your account</div>
      <AccountInput
        v-model="first_name"
        label="First Name"
        placeholder="Enter your first name"
        :error-message="error.first_name"
        @keypress="handleFirstNameLastName"
      />
      <AccountInput
        v-model="last_name"
        label="Last Name"
        placeholder="Enter your last name"
        :error-message="error.last_name"
        @keypress="handleFirstNameLastName"
      />

      <AccountInput
        v-model="email"
        label="Email Address"
        placeholder="Enter your email"
        type="email"
        :error-message="error.email"
      />
      <div class="w-100">
        <p class="type mb-17">Mobile Number</p>
        <div class="d-flex align-items-center">
          <div class="w-40 mt-5">
            <div
              class="w-100 d-flex align-items-center justify-content-between"
            >
              <country-flag
                v-if="country_code"
                :country="country_code"
                class="mr-1"
                size="big"
              />

              <v-autocomplete
                v-model="mobile_code"
                item-title="calling_code"
                item-value="calling_code"
                :items="countries.data"
                custom-class="mt-10px"
                density="compact"
                bg-color="#fafbff"
              />
            </div>
          </div>
          <div class="w-60">
            <div class="w-100 d-flex align-items-center">
              <input
                v-model="mobile_no"
                type="number"
                class="mobile-number w-100"
                autocomplete="off"
              />
              <Verify
                v-if="verify && !verified"
                :mobile_no="mobile_no"
                :make-verified="makeVerified"
                :mobile-code="mobile_code"
              />
              <img
                v-if="verified"
                src="@/assets/svg/check-circle.svg"
                class="check-circle"
                alt=""
              />
            </div>
          </div>
        </div>
        <div
          v-if="error.mobile_no"
          class="w-100 d-flex align-items-center justify-content-center"
          style="margin-top: -18px"
        >
          <p class="text-danger">
            {{ error.mobile_no }}
          </p>
        </div>
      </div>
      <div class="type-container w-100 d-flex align-items-center">
        <p class="mb-0 type">Type</p>
        <input
          type="radio"
          name="type"
          value="Business Owner"
          v-model="type"
          class="input-radio mr-2"
        />
        <p class="radio-description mr-3 mb-0">Business Owner</p>
        <input
          v-model="type"
          type="radio"
          name="type"
          value="Consultant"
          class="input-radio mr-2"
        />
        <p class="radio-description mr-3 mb-0">Consultant</p>
      </div>
      <p class="text-danger" v-if="error.type">please select account type</p>
      <AccountInput
        v-model="password"
        label="Password"
        placeholder="Enter password"
        autocomplete="new-password"
        type="password"
        :error-message="error.password"
        :add-suggestion="true"
        :check-validity="checkPasswordValidity"
      />
      <AccountInput
        v-model="confirm_password"
        label="Confirm Password"
        placeholder="Re-enter your password"
        type="password"
        autocomplete="new-password"
        :error-message="error.confirm_password"
        :disabled="!password_validity"
        @paste="(e) => e.preventDefault()"
      />
      <div class="d-flex align-items-center">
        <input
          v-model="accepted"
          type="checkbox"
          value="accepted"
          class="checkbox mr-3"
          name=""
          id=""
        />
        <p class="mb-0 sign">
          By signing up you agree with our Policy and
          <b class="cursor-pointer" @click="setModal(true)"
            >Terms & Conditions</b
          >
        </p>
      </div>
      <p class="text-info">{{ message }}</p>

      <div class="d-flex align-items-center flex-wrap justify-content-center">
        <button
          :class="`btn-signup mt-2 ${accepted && verified ? '' : 'disabled'}`"
          type="submit"
          :disabled="!accepted || !verified"
        >
          Sign up
        </button>
        <button
          :class="`btn-signin  mt-2`"
          type="button"
          @click="$router.push('/account/signin')"
        >
          Sign in
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import AccountInput from "../common/AccountInput.vue";
import Verify from "./Verify.vue";
import ModalSuccessMessage from "../common/ModalSuccessMessage.vue";
import { basicDataStructures } from "../../helpers/storeStructure";
import { useCountryStore } from "../../store/country";
import { checkRequired } from "@/helpers/formErrorCheckers";
import { useProfileStore } from "@/store/profile";
import { emailRegex } from "../../constants/regex-format";
import TermsAndPolicy from "./TermsAndPolicy.vue";
// import { useRouter } from 'vue-router'
export default {
  name: "CreateAccount",
  components: { AccountInput, Verify, ModalSuccessMessage, TermsAndPolicy },
  data() {
    return {
      modal: false,
      open: false,
      countries: {
        ...basicDataStructures,
      },
      first_name: "",
      country_code: "are",
      last_name: "",
      email: "",
      mobile_code: "+971",
      mobile_no: "",
      password: "",
      verify: false,
      type: "",
      confirm_password: "",
      message: "",
      verified: false,
      accepted: false,
      password_validity: false,
      error: {
        first_name: "",
        last_name: "",
        email: "",
        mobile_code: "",
        mobile_no: "",
        password: "",
        type: "",
        confirm_password: "",
      },
    };
  },
  watch: {
    mobile_no(newValue) {
      if (String(newValue).length === 10 || String(newValue).length === 9) {
        this.verify = true;
        this.error.mobile_no = "";
      } else {
        if (this.mobile_code === "+971") {
          this.error.mobile_no = "Please give 9 characters";
        } else if (this.mobile_code === "+91") {
          this.error.mobile_no = "Please give 10 characters";
        }
        this.verify = false;
        this.verified = false;
      }
    },
    first_name() {
      this.error.first_name = "";
    },
    last_name() {
      this.error.last_name = "";
    },
    email() {
      this.error.email = "";
    },
    mobile_code(newValue) {
      if (!newValue) {
        return;
      }
      const findCountry = this.countries.data.find(
        (country) => country.calling_code === newValue
      );
      const code = findCountry ? findCountry?.code?.toLowerCase() : "";
      this.country_code = code === "uae" ? "are" : code;
      this.error.mobile_code = "";
    },
    password() {
      this.error.password = "";
    },
    type() {
      this.error.type = "";
    },
    confirm_password() {
      this.error.confirm_password = "";
    },
    accepted(newVal) {
      if (newVal) this.modal = true;
    },
  },
  async created() {
    const countryStore = useCountryStore();
    this.countries = countryStore.countries;
    if (!countryStore.countries.data.length) {
      await countryStore.getCountryList();
      this.countries = countryStore.countries;
    }
  },
  methods: {
    closeModal() {
      this.open = false;
    },
    checkPasswordValidity(value) {
      this.password_validity = value;
    },
    async signup(e) {
      e.preventDefault();
      const profileStore = useProfileStore();
      const check = checkRequired({
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        mobile_code: this.mobile_code,
        mobile_no: this.mobile_no,
        password: this.password,
        type: this.type,
        confirm_password: this.confirm_password,
      });

      if (check.length) {
        check.forEach((field) => {
          this.error[field] = "Please enter this field";
        });
        return;
      } else if (this.password !== this.confirm_password) {
        this.error.confirm_password = "Password confirmation error";
        return;
      } else if (!emailRegex.test(this.email)) {
        this.error.email = "Invalid Email";
        return;
      } else if (!this.accepted) {
        this.message = "Please accept terms and policy";
        return;
      } else if (!this.checkName(this.first_name)) {
        this.error.first_name = "Only a space is allowed";
        return;
      } else if (!this.checkName(this.last_name)) {
        this.error.last_name = "Only a space is allowed";
        return;
      }

      await profileStore.createAccount(
        {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          mobile_code: this.mobile_code,
          mobile_no: this.mobile_no,
          password: this.password,
          type: this.type,
        },
        this.setMessage,
        (res) => {
          this.open = true;
          setTimeout(() => {
            this.closeModal();
            this.$router.push("/account/signin");
          }, 3000);
        }
      );
    },
    setType(type) {
      this.type = type;
    },
    setMessage(message) {
      this.message = message;
    },
    makeVerified(verified) {
      this.verified = verified;
    },
    checkName(name) {
      let splitedName = name.split(" ");
      if (splitedName.length) {
        if (!splitedName[0]) {
          splitedName = splitedName.slice(1);
        }
      }
      if (splitedName.length > 2) {
        return false;
      }
      return true;
    },
    handleFirstNameLastName(e) {
      const currentKey = String.fromCharCode(e.keyCode);
      if (!/^[a-zA-Z ]*$/.test(currentKey)) {
        e.preventDefault();
      }
    },
    setModal(value) {
      this.modal = value;
    },
  },
};
</script>

<style scoped>
.wrapper {
  min-height: 125vh;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 100px;
  padding-right: 100px;
}
.v-input--density-compact {
  margin-top: 10px !important;
}
.create {
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 52px;
  letter-spacing: 0.055em;
  color: #000000;
}

.type {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
  margin-right: 39px;
}
.mt-10px {
  margin-top: 10px;
}
.type-container {
  border-bottom: 1px solid #747171;
  padding-bottom: 15px;
}

.radio-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #747171;
}

.mb-17 {
  margin-bottom: -15px;
}

.mt-8 {
  margin-top: 8px;
}

.checkbox {
  background: #ffffff;
  border: 1px solid #747171;
  border-radius: 4px;
}

.sign {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #747171;
}

.btn-signup {
  padding: 8px 21px;
  width: 160px;
  height: 55px;
  background: #0f75bc;
  border-radius: 5px;
  margin-right: 21px;
  color: #fafbff;
}

.btn-signin {
  width: 160px;
  height: 55px;

  background: #ffffff;
  border: 1px solid #747171;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.055em;

  color: #747171;
}

.mobile-number {
  border: none;
  padding-bottom: 6px;
  border-bottom: 1px solid #747171;
  padding-right: 81px;
  margin-top: 17px;
  background: #fafbff;
}

.mobile-number:focus {
  outline: 0;
  border-bottom: 1px solid #000;
}

.check-circle {
  margin-left: -60px;
  position: relative;
}

.btn-signup.disabled {
  opacity: 0.5 !important;
}

@media screen and (max-width: 600px) {
  .wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }

  .btn-signup {
    margin-right: 10px !important;
  }

  .btn-signin {
    margin-right: 10px !important;
  }
}
.w-40 {
  width: 40% !important;
}
.w-60 {
  width: 60% !important;
}
</style>

<template>
  <SideNavContainer title="Choose Your Plan">
    <LoaderContainer
      :loading="
        profile.loading || subscriptions.loading || subscription.loading
      "
      :error="profile.error || subscriptions.error"
    >
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div
            class="desc-container d-flex align-items-center justify-content-between"
          >
            <p class="mb-0 subscription-type">
              {{ subscription.data?.type }}
            </p>
          </div>
          <div class="w-100">
            <div class="customer-info w-100">
              <div
                class="company-name py-2 d-flex align-items-center justify-content-between"
              >
                <h5 class="price-desc mb-0">
                  <b class="bold">
                    Company Name <span class="text-danger"> *</span>
                  </b>
                </h5>
                <div class="w-50 d-flex justify-content-end">
                  <input
                    ref="companyInput"
                    v-model="companyName"
                    type="text"
                    class="clickable-text borde-less w-100"
                    placeholder="Please enter company name"
                  />
                </div>
              </div>
              <p class="phone mb-0">
                {{ profile.data.mobile_no }}
              </p>
              <p class="email mb-0">
                {{ profile.data?.email }}
              </p>
            </div>
            <div
              class="desc-container d-flex align-items-center justify-content-between"
            >
              <p class="mb-0 subscription-type">Order Summary</p>
            </div>
            <div
              class="customer-info w-100 d-flex align-items-center justify-content-between"
            >
              <p class="name mb-0">
                {{ subscription.data?.type }}
              </p>
              <p class="name mb-0">AED {{ subscription.data?.price }}</p>
            </div>
            <div class="w-100 d-flex flex-column prices px-4">
              <div class="d-flex align-items-center justify-content-between">
                <p class="price-desc">SubTotal</p>
                <p class="price-desc">AED {{ subscription.data?.price }}</p>
              </div>

              <VatRegistrationNumber :vat="vat" :set-vat="setVat" />
              <div
                v-if="subscriptions.is_vat_applicable === 'Y'"
                class="d-flex align-items-center justify-content-between"
              >
                <p class="price-desc">VAT</p>
                <p class="price-desc">
                  AED
                  {{
                    Number(subscription.data?.price || 0) *
                    Number(subscriptions.vat)
                  }}
                </p>
              </div>
            </div>
            <div
              class="total mt-1 w-100 d-flex align-items-center justify-content-between px-4"
            >
              <p class="name">Total</p>
              <p class="name">
                AED
                {{
                  Number(subscription.data?.price || 0) *
                    Number(
                      subscriptions.is_vat_applicable === "Y"
                        ? subscriptions.vat
                        : 0
                    ) +
                  Number(subscription.data?.price)
                }}
              </p>
            </div>
            <div class="mt-4 px-4">
              <MakePaymentContainer
                :price="Number(subscription.data.price || 0)"
                :subscription="Number($route.params.plan_type)"
                :vat-number="vat"
                :company-name="companyName"
              />
            </div>
          </div>
        </div>
      </div>
    </LoaderContainer>
  </SideNavContainer>
</template>

<script setup>
import { SideNavContainer } from "@/components/layout";
import { onMounted, ref, watch } from "vue";
import { useSubscriptionStore } from "../../../store/subscription";
import { useProfileStore } from "../../../store/profile";
import { storeToRefs } from "pinia";
import LoaderContainer from "../../common/LoaderContainer.vue";
import MakePaymentContainer from "../Payment/MakePaymentContainer.vue";
import { useRoute } from "vue-router";
import VatRegistrationNumber from "./VatRegistrationNumber.vue";

const subScriptionStore = useSubscriptionStore();
const profileStore = useProfileStore();
const { subscription, subscriptions } = storeToRefs(subScriptionStore);
const { profile } = storeToRefs(profileStore);
const route = useRoute();
const vat = ref("");
const companyName = ref("");
const companyInput = ref("");
const setVat = (value) => {
  vat.value = value;
};
onMounted(async () => {
  if (!subscriptions.value.data.length) {
    await subScriptionStore.getSubscriptions();
  }
  await subScriptionStore.getSubscription(route.params?.plan_type);

  if (companyInput.value) {
    await companyInput.value.focus();
  }
});
watch(companyInput, async (newVal) => {
  if (newVal) {
    await newVal.focus();
  }
});
</script>

<style scoped>
.desc-container {
  padding: 22px 10px 18px 10px;
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.subscription-type {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.customer-info {
  background: #deeded;
  box-shadow: 0px 8px 8px rgba(187, 187, 187, 0.25);
  border-radius: 11px;
  padding: 15px 15px 21px 15px;
  margin-top: 16px;
}
.name {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.phone {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #382628;
  margin-top: 9px;
}
.email {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #382628;
}
.prices {
  margin-top: 25px;
}
.price-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #382628;
}
.total {
  border-top: 1px solid rgba(116, 113, 113, 0.5);
  padding-top: 9px;
}
.btn-checkout {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
  width: 133px;
  height: 40px;
  background: #0f75bc;
  border-radius: 5px;
}
.clickable-text::placeholder {
  text-align: right !important;
  color: red;
}
.company-name {
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.clickable-text.borde-less {
  border: none !important;
}
</style>

<script setup>
import { ref, watch, onMounted, reactive } from "vue";
import { SideNavContainer } from "../../layout";
import { useRoute } from "vue-router";
import { useBusinessStore } from "../../../store/buisness.store";
import { storeToRefs } from "pinia";
import Business from "../Business/Business.vue";
import { useChapter11Store } from "../../../store/chapter-11";
import LoaderContainer from "../../common/LoaderContainer.vue";
import { debounce } from "vue-debounce";
import MasterData from "./chapter11/MasterData.vue";
import { NumberFormatter } from "../../../helpers/numberFormatter";
import { ChaptersBreadCrumb } from "../../BreadCrumbs";
import { useStatusStore } from "../../../store/statusStore";
import { checkAmount } from "../../../helpers/validation";
import { AlertModal, CustomModalContainer } from "../../common";

const name = ref("");
const route = useRoute();
const businessStore = useBusinessStore();
const { business } = storeToRefs(businessStore);
const chapter11Store = useChapter11Store();
const statusStore = useStatusStore();

const {
  status,
  loading: statusLoading,
  error: statusError,
} = storeToRefs(statusStore);
const { data, loading, error, savingMessage } = storeToRefs(chapter11Store);
const setBusinessName = (newValue, current_business) => {
  const findBusiness = newValue?.data?.find((b) => b.id == current_business);
  name.value = findBusiness?.name ? findBusiness?.name : "";
};

onMounted(async () => {
  await chapter11Store.getChapter11(route);
  if (route.query.financial_year && route.query.financial_period) {
    await statusStore.getStatus(
      route.params.id,
      route.query.financial_year,
      route.query.financial_period
    );
  }
});
watch(
  business,
  (newValue) => {
    if (newValue?.data.length)
      setBusinessName(newValue, route.query.current_business);
  },
  { deep: true }
);
watch(
  () => route.query,
  (newValue) => {
    setBusinessName(business.value, newValue.current_business);
  }
);

const saveChapter11 = async () => {
  try {
    const item = data.value[data.value.length - 1];
    const payload = {
      unique_id: item?.unique_id,
      article_39_agreed: item?.article_39_agreed,
      details_of_loss_related_party: item?.details_of_loss_related_party,
      transfer_of_loss: item?.transfer_of_loss,
    };
    await chapter11Store.saveChapter11(route, payload, async () => {
      await chapter11Store.getChapter11(route);
    });
  } catch (err) {
    console.log(err);
  }
};
const intialValues = reactive({
  details_of_loss_related_party: "",
  transfer_of_loss: "",
});
const modal = ref(false);
const setModal = (value) => {
  modal.value = value;
};
const setIntialValue = (key, value) => {
  intialValues[key] = value;
};
const autoSaveChapter = async (prevValue, currentValue) => {
  if (Number(prevValue) !== Number(currentValue)) {
    await saveChapter11();
  }
};
const checkTransferOfLoss = async (e, carrie_forward_loss) => {
  await checkAmount(e, (value) => {
    if (value > Number(carrie_forward_loss)) {
      e.preventDefault();
      setModal(true);
    }
  });
};
const checkTransferOfLossAtBlur = (index) => {
  const previousValue = intialValues.transfer_of_loss;
  const currentValue = data.value[index].transfer_of_loss;
  const carrie_forward_loss = data.value[index].carrie_forward_loss;
  if (currentValue > Number(carrie_forward_loss)) {
    data.value[index].transfer_of_loss = "";
    setModal(true);
  } else {
    autoSaveChapter(previousValue, currentValue);
  }
};
</script>

<template>
  <SideNavContainer
    side-nav-type="financialData"
    title="Brought Forward & Carried Forward Loss-Chapter-11"
    :subtitle="name"
  >
    <AlertModal
      :open="modal"
      :message="`Transfer of Loss to Related Company cannot be greater than C/f Loss `"
      sub-message=""
      :has-cancel="false"
      :on-confirm="() => setModal(false)"
      :on-cancel="() => setModal(false)"
      button-name="ok"
    />
    <ChaptersBreadCrumb
      :entityName="name"
      chapter="chapter-11"
      chapterName="Chapter-11"
    />
    <Business Tab="chapter-11" />
    <LoaderContainer
      :loading="loading || statusLoading"
      :error="error || statusError"
    >
      <MasterData v-if="$route.query.current_business === 'master'" />
      <div v-else class="w-100 mt-4">
        <div class="w-100 d-flex my-2 align-items-center justify-content-end">
          <p :class="`text-${savingMessage.type} mb-0 mr-10`">
            {{ savingMessage.message }}
          </p>
          <button class="save-button mr-3" @click="saveChapter11">Save</button>
        </div>
        <div class="w-100 table-responsive">
          <table class="table table-bordered table-striped mt-2">
            <thead>
              <th class="table-header w-100px">Financial Year</th>
              <th class="table-header">Brought Forward Loss</th>
              <th class="table-header">
                Details of Loss (Current year Loss-Transfer from Related Party)
              </th>
              <th class="table-header">Details of Loss (Current year Loss)</th>
              <th class="table-header">Sett off of Loss</th>
              <th class="table-header">C/f Loss</th>
              <th class="table-header">Transfer of Loss to Related Company</th>
              <th class="table-header">Net Carried Forward of Loss</th>
              <th class="table-header">
                Whether conditions specified as per Article 39 agreed with
              </th>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data" :key="item.unique_id">
                <td class="table-rows">{{ item?.financial_year_name }}</td>
                <td class="table-rows">
                  {{ NumberFormatter(item?.brought_forward_loss) }}
                </td>
                <td class="table-rows">
                  <input
                    v-if="data.length - 1 === index"
                    v-model="data[index].details_of_loss_related_party"
                    type="number"
                    class="table-input"
                    placeholder="Details of loss"
                    :disabled="!!status"
                    @focus="
                      setIntialValue(
                        'details_of_loss_related_party',
                        item.details_of_loss_related_party || ''
                      )
                    "
                    @blur="
                      autoSaveChapter(
                        intialValues.details_of_loss_related_party,
                        item.details_of_loss_related_party
                      )
                    "
                  />
                  <p class="mb-0" v-else>
                    {{ NumberFormatter(item?.details_of_loss_related_party) }}
                  </p>
                </td>
                <td class="table-rows">
                  {{ NumberFormatter(item?.details_of_loss) }}
                </td>
                <td class="table-rows">
                  {{ NumberFormatter(item?.sett_off_of_loss) }}
                </td>
                <td class="table-rows">
                  {{ NumberFormatter(item?.carrie_forward_loss) }}
                </td>
                <td class="table-rows">
                  <input
                    v-if="data.length - 1 === index"
                    v-model="data[index].transfer_of_loss"
                    type="number"
                    class="table-input"
                    placeholder="Transfer of Loss"
                    :disabled="!!status"
                    @focus="
                      setIntialValue(
                        'transfer_of_loss',
                        item.transfer_of_loss || ''
                      )
                    "
                    @blur="checkTransferOfLossAtBlur(index)"
                    @keypress="
                      (e) =>
                        checkTransferOfLoss(e, item.carrie_forward_loss || '')
                    "
                  />
                  <p v-else class="mb-0">
                    {{ NumberFormatter(item?.transfer_of_loss) }}
                  </p>
                </td>
                <td class="table-rows">
                  {{ NumberFormatter(item?.net_carried_forward_loss) }}
                </td>
                <td class="table-rows">
                  <div
                    class="d-flex w-100 h-100 align-items-center justify-content-center"
                  >
                    <v-switch
                      v-model="data[index].article_39_agreed"
                      inset
                      :disabled="data.length - 1 !== index || !!status"
                      hide-details
                      color="#0F75BC"
                    ></v-switch>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </LoaderContainer>
  </SideNavContainer>
</template>

<style scoped>
.table-header {
  min-height: 83px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-left: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  vertical-align: middle;
  border: 3px solid #fff;
}
.table-rows {
  background: #ffffff;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
  border: 3px solid #fff;
  vertical-align: middle;
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.text-note {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
.table-input {
  padding: 10px;
  width: 100% !important;
}
.table-input:focus {
  outline: 0;
}
.table-input::placeholder {
  text-align: left;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.w-100px {
  min-width: 100px;
}
</style>

export const getErrorMessage=err=>{
    if(!err){
        return ""
    }
    if(err?.response?.data?.message){
        return err?.response?.data?.message
    }
  return  err?.message 
}

export const getWarningMessage=(res)=>{
    if( res.data?.error){
      return res.data?.error?.join(",")  
    }
    return  res.data?.message
}
<template>
  <!-- Your Vue template -->
  <slot />
</template>

<script setup>
import { generate } from "randomized-string";
import { ref, onMounted, onUnmounted } from "vue";

const logoutTimer = ref(null);
const publicRoutes = ref([
  "/account/signup",
  "/account/signin",
  "/account/login-otp",
  "/account/forget-password",
]);
const resetLogoutTimer = () => {
  clearTimeout(logoutTimer.value);
  startLogoutTimer();
};

const startLogoutTimer = () => {
  logoutTimer.value = setTimeout(() => {
    logOut();
  }, 30 * 60 * 1000); // 30 minutes
};

const logOut = async () => {
  if (publicRoutes.value.includes(location.pathname)) {
    return;
  }
  await localStorage.clear();
  await sessionStorage.clear();
  await caches.keys().then(function (names) {
    for (let name of names) {
      caches.delete(name);
    }
  });
  location.href = `/account/signin?r=${generate(24)}`;
};

onMounted(() => {
  setupActivityDetection();
});

onUnmounted(() => {
  cleanupActivityDetection();
});

const setupActivityDetection = () => {
  window.addEventListener("mousemove", resetLogoutTimer);
  window.addEventListener("keydown", resetLogoutTimer);
  startLogoutTimer();
};

const cleanupActivityDetection = () => {
  window.removeEventListener("mousemove", resetLogoutTimer);
  window.removeEventListener("keydown", resetLogoutTimer);
};
</script>

<template>
  <div class="row mt-4">
    <div class="col-lg-12">
      <CustomModalContainer
        :open="notQualified"
        :set-open="setOpen"
        transition="dialog-top-transition"
        content-class="my-custom-dialog"
      >
        <h2 class="choose-title w-90 bold mb-0">
          <b class="text-warning mb-0 f-20">&#x26A0;</b>
          Your company is not Qualified Freezone Person
        </h2>
      </CustomModalContainer>
    </div>
    <div class="col-lg-12">
      <div
        class="w-100 wrapper d-flex p-3 align-items-center justify-content-between"
      >
        <h4 class="choose-title w-90 bold mb-0">
          i. Whether Company Maintains Adequate Substance
        </h4>
        <v-switch
          v-model="
            calculatorOptions.wheater_company_maintains_adequate_substance
          "
          color="#0F75BC"
          inset
          hide-details
          :disabled="!!Number(status)"
        />
      </div>
      <div class="col-lg-12">
        <div
          class="w-100 wrapper d-flex p-3 align-items-center justify-content-between"
        >
          <h4 class="choose-title w-90 bold mb-0">
            ii. The Company has Elected to be Subject to Corporate Tax
            (Article-19)
          </h4>
          <v-switch
            v-model="
              calculatorOptions.the_company_has_selected_to_be_subject_to_corporate_tax
            "
            color="#0F75BC"
            inset
            hide-details
            :disabled="!!Number(status)"
          />
        </div>
      </div>
      <div class="col-lg-12">
        <div
          class="w-100 wrapper d-flex p-3 align-items-center justify-content-between"
        >
          <h4 class="choose-title w-90 bold mb-0">
            iii. Complies with Arm Length Price and Transfer Pricing
            Documentation
          </h4>
          <v-switch
            v-model="
              calculatorOptions.complies_with_arm_length_price_and_transfer_pricing_docs
            "
            color="#0F75BC"
            inset
            hide-details
            :disabled="!!Number(status)"
          />
        </div>
      </div>
      <div class="col-lg-12">
        <div
          class="w-100 wrapper d-flex p-3 align-items-center justify-content-between"
        >
          <h4 class="choose-title w-90 bold mb-0">
            iv. Derives Qualifying Income
          </h4>
          <v-switch
            v-model="calculatorOptions.derives_qualifying_income"
            color="#0F75BC"
            inset
            hide-details
            :disabled="!!Number(status)"
          />
        </div>
      </div>
      <div class="col-lg-12">
        <div
          class="w-100 wrapper d-flex p-3 align-items-center justify-content-between"
        >
          <h4 class="choose-title w-90 bold mb-0">
            v. Maintains Audited Financial Statements
          </h4>
          <v-switch
            v-model="calculatorOptions.maintains_audited_financial_statements"
            color="#0F75BC"
            inset
            hide-details
            :disabled="!!Number(status)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { CustomModalContainer } from "../../common";
import { useRouter, useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useQFZCalculatorStore } from "../../../store/QFZcalculator";
import { useStatusStore } from "../../../store/statusStore";

const props = defineProps({
  showCalculator: {
    type: Function,
    default: () => {},
  },
  showInput: {
    type: Function,
    default: () => {},
  },
  setCompleted: {
    type: Function,
    default: () => {},
  },
});
const QFZCalculator = useQFZCalculatorStore();
const { options: calculatorOptions } = storeToRefs(QFZCalculator);
const statusStore = useStatusStore();
const { status } = storeToRefs(statusStore);

const router = useRouter();
const route = useRoute();
const notQualified = ref(false);
const setOpen = (value) => {
  notQualified.value = value;
};
const checkData = async (newVal, checkLineTwo = true) => {
  let showInput = true;
  let showCalc = true;
  if (
    newVal.the_company_has_selected_to_be_subject_to_corporate_tax &&
    checkLineTwo
  ) {
    setOpen(true);
    await props.setCompleted(true);
    await QFZCalculator.saveQfzpCalc(
      route.params.id,
      route.query.financial_year,
      route.query.financial_period,
      async () => {
        setTimeout(() => {
          router.push({
            path: `/entity/general-information/${route.params.id}`,
            query: {
              ...route.query,
              qfzp: "N",
              open_non_qfzp: true,
            },
          });
        }, 3000);
      }
    );

    return;
  }
  for (let x in newVal) {
    if (x === "the_company_has_selected_to_be_subject_to_corporate_tax") {
      showInput = showInput && !newVal[x];
    } else {
      showCalc = showCalc && newVal[x];
      showInput = showInput && newVal[x];
    }
  }
  await props.showInput(!showInput);
  await props.showCalculator(showCalc);
};

watch(
  calculatorOptions,
  async (newVal) => {
    await checkData(newVal);
  },
  { deep: true }
);
onMounted(async () => {
  await checkData(calculatorOptions.value, false);
});
</script>

<style lang="scss" scoped>
.choose-title {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.88px;
}
.choose-name {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.88px;
}
.wrapper {
  background: #deeded;
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.w-90 {
  width: 85%;
}
</style>

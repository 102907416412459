import { defineStore } from "pinia";
import { basicDataStructure } from "../helpers/storeStructure";
import { Toast } from "../helpers/toast";
import fetchWrapper from "../helpers/fetchWrapper";
import { getErrorMessage } from "../helpers/errorHandler";

export const useProfileStore = defineStore("profile", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      profile: {
        ...basicDataStructure,
      },
      remaining_entity_count: 0,
      remaining_user_count: 0,
    };
  },
  actions: {
    async createAccount(data, setMessage, callBack = () => {}) {
      const toast = new Toast("Creating account...");
      try {
        const res = await fetchWrapper.post("/signUp", data);
        if (res.data.result === 1) {
          callBack(res.data);
          this.profile.data = res.data?.data;
          toast.makeSuccessToast("Created Successfully");
        } else {
          toast.makeErrorToast(
            "Unable to create account" + res.data?.error?.join(",")
          );
        }
      } catch (err) {
        toast.makeErrorToast("Unable to create account" + err?.message);
      }
    },
    async getSignUpOtp(
      mobileNumber,
      mobile_code,
      setMessage,
      callBack = () => {}
    ) {
      const toast = new Toast("sending OTP...");
      try {
        const res = await fetchWrapper.post("/getOTP", {
          mobile_no: mobileNumber,
          mobile_code,
        });
        if (res.data.result === 1) {
          toast.closeToast();
          setMessage("OTP Sent. Please check your phone");
          callBack(res?.data.otp_id);
        } else {
          toast.makeErrorToast(res.data?.error?.join(","));
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast("Unable to send OTP " + err?.message);
      }
    },
    async getLoginOtp(
      mobileNumber,
      mobile_code,
      setMessage,
      callBack = () => {}
    ) {
      const toast = new Toast("Sending OTP...");
      try {
        const res = await fetchWrapper.post("/getLoginOTP", {
          mobile_no: mobileNumber,
          mobile_code,
        });
        if (res.data.result === 1) {
          toast.makeSuccessToast("OTP Sent. Please check your phone");
          setMessage("OTP Sent. Please check your phone");
          callBack(res?.data.otp_id);
        } else {
          toast.makeErrorToast(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message
          );
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast("Unable to send otp" + err?.message);
      }
    },
    async verifyOtp({ otp_id, otp }, setMessage, callBack = () => {}) {
      try {
        setMessage("Verifying OTP...");
        const res = await fetchWrapper.post("/verifyOTP", { otp_id, otp });
        if (res.data.result === 1) {
          setMessage("Phone verified");
          callBack();
        } else {
          setMessage(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message
          );
        }
      } catch (err) {
        console.log(err);
        setMessage("Unable to create account" + err?.message);
      }
    },
    async login({ email, password }, setMessage, callBack = () => {}) {
      const toast = new Toast("Logging in...");
      try {
        const res = await fetchWrapper.post("/login", { email, password });
        if (res.data.result === 1) {
          toast.makeSuccessToast("Welcome ✓");
          this.profile.loading = false;
          this.profile.data = res.data?.data;
          this.profile.error = false;
          callBack(res.data);
        } else {
          toast.makeErrorToast(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message
          );
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(
          "Unable to login " +
            ", " +
            (err?.response?.data?.message
              ? err?.response?.data?.message
              : err?.message)
        );
      }
    },
    async getUserProfile(callBackError = () => {}) {
      try {
        this.profile.loading = true;
        const res = await fetchWrapper.get("/user");
        if (res.data.result == 1) {
          this.profile.data = res.data?.data;
          this.remaining_entity_count = res?.data?.remaining_entity_count;
          this.remaining_user_count = res?.data?.remaining_user_count;
          this.profile.error = false;
          this.profile.loading = false;
        } else {
          this.profile.error =
            "Unable to verify your account " + res.data?.error
              ? res.data?.error?.join(",")
              : res.data?.message;
          callBackError(res.status);
        }
      } catch (err) {
        this.profile.error = err?.response?.data?.message
          ? err?.response?.data?.message
          : err?.message;
        callBackError(401);
      }
    },
    async signInWithOTp(mobile_no, mobile_code, setMessage, callBack) {
      try {
        setMessage("Logging in...");
        const res = await fetchWrapper.post("/loginWithOtp", {
          mobile_no,
          mobile_code,
        });
        if (res.data.result === 1) {
          setMessage("Welcome ✓");
          this.profile.loading = false;
          this.profile.data = res.data?.data;
          this.profile.error = false;
          callBack(res.data);
        } else {
          setMessage(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message
          );
        }
      } catch (err) {
        console.log(err);
        setMessage(
          "Unable to create account" +
            ", " +
            (err?.response?.data?.message
              ? err?.response?.data?.message
              : err?.message)
        );
      }
    },
    async changePassword(payload, callBack = () => {}) {
      const toast = new Toast("Changing password...");
      try {
        const res = await fetchWrapper.post("/updatePassword", payload);
        if (res.data.result == 1) {
          toast.makeSuccessToast(res.data.message);
          callBack(res.data);
        } else {
          toast.makeErrorToast(res?.data?.error);
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(getErrorMessage(err));
      }
    },
    async sendResetLink(email, setMessage = () => {}, callBack = () => {}) {
      try {
        setMessage("Sending reset link...", "info");
        const res = await fetchWrapper.post("/forgetPassword", { email });
        if (res.data.result === 1) {
          setMessage(
            res.data?.message + " , please check your email",
            "success"
          );
          callBack(res.data);
        } else {
          setMessage(res.data?.error, "danger");
        }
      } catch (err) {
        console.log(err);
        setMessage(
          "Unable to Send reset link " +
            ", " +
            (err?.response?.data?.message
              ? err?.response?.data?.message
              : err?.message),
          "danger"
        );
      }
    },
    async resetPassword(payload, setMessage = () => {}, callBack = () => {}) {
      try {
        setMessage("Setting new password...", "info");
        const res = await fetchWrapper.post("/resetPassword", payload);
        if (res.data.result === 1) {
          setMessage(res.data?.message, "success");
          callBack(res.data);
        } else {
          setMessage(res.data?.error, "danger");
        }
      } catch (err) {
        console.log(err);
        setMessage(
          "Unable to reset password, " +
            (err?.response?.data?.message
              ? err?.response?.data?.message
              : err?.message),
          "danger"
        );
      }
    },
    async getUserProfileSilently(callBackError = () => {}) {
      try {
        const res = await fetchWrapper.get("/user");
        if (res.data.result == 1) {
          this.profile.data = res.data?.data;
          this.remaining_entity_count = res?.data?.remaining_entity_count;
          this.remaining_user_count = res?.data?.remaining_user_count;
          this.profile.error = false;
          this.profile.loading = false;
        } else {
          this.profile.error =
            "Unable to verify your account " + res.data?.error
              ? res.data?.error?.join(",")
              : res.data?.message;
          this.profile.loading = false;
          callBackError(res.status);
        }
      } catch (err) {
        this.profile.error = err?.response?.data?.message
          ? err?.response?.data?.message
          : err?.message;
        this.profile.loading = false;
        callBackError(401);
      }
    },
  },
});

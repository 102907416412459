<template>
  <LoaderContainer :loading="subscriptions.loading && mySubscription.loading">
    <div
      v-for="plan in otherPlans"
      :key="plan.id"
      class="wrapper mt-4 d-flex flex-column px-4 justify-content-evenly mb-4"
    >
      <h4 class="subscription-title">{{ plan.name }}</h4>
      <div class="">
        <div class="d-flex align-items-start">
          <img
            src="@/assets/svg/check-circle-mini.svg"
            class="check-icon mr-2"
            alt=""
          />
          <p class="subscription-detail mb-0">
            <u>
              <b> Can support up to {{ plan.user_count }} users </b>
            </u>
          </p>
        </div>
        <div class="d-flex align-items-start">
          <img
            src="@/assets/svg/check-circle-mini.svg"
            class="check-icon mr-2"
            alt=""
          />
          <p class="subscription-detail mb-0">
            <u>
              <b> Can support up to {{ plan.entity_count }} entity </b>
            </u>
          </p>
        </div>
        <div class="d-flex align-items-start">
          <img
            src="@/assets/svg/check-circle-mini.svg"
            class="check-icon mr-2"
            alt=""
          />
          <p class="subscription-detail mb-0">
            <u>
              <b
                >Price for additional entity is
                {{ plan.price_per_entity }} AED</b
              >
            </u>
          </p>
        </div>
        <div class="d-flex align-items-start">
          <img
            src="@/assets/svg/check-circle-mini.svg"
            class="check-icon mr-2"
            alt=""
          />
          <p class="subscription-detail mb-0">
            <u
              ><b> <div v-html="plan.description"></div> </b
            ></u>
          </p>
        </div>
      </div>
      <h4 class="subscription-title">
        {{ plan.price }} AED for {{ plan.validity }} Filing
      </h4>
      <button
        class="w-100 btn-choose-plan"
        @click="
          $router.push(
            `/subscription/checkout/${plan.id}?redirect_tab=subscription`
          )
        "
      >
        Choose Plan
      </button>
    </div>
  </LoaderContainer>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useSubscriptionStore } from "../../../store/subscription";
import { onMounted, ref, watch } from "vue";
import { LoaderContainer } from "../../common";

const subscriptionStore = useSubscriptionStore();
const { subscriptions, mySubscription } = storeToRefs(subscriptionStore);
const otherPlans = ref([]);
onMounted(async () => {
  const myplan = mySubscription.value.data;
  otherPlans.value = subscriptions.value.data.filter((sub) => {
    return sub?.id != myplan?.subscription?.id;
  });
});
watch(
  () => subscriptions.value.data,
  (newValue) => {
    const myplan = mySubscription.value.data;
    otherPlans.value = newValue.filter((sub) => {
      return sub?.id != myplan?.subscription?.id;
    });
  },
  { deep: true }
);
</script>

<style scoped>
.wrapper {
  min-height: 357px;
  background: #deeded;
  box-shadow: 0px 8px 8px rgba(187, 187, 187, 0.25);
  border-radius: 10px;
}
.subscription-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #747171;
}
.subscription-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.055em;
  color: #382628;
}
.btn-choose-plan {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #fafbff;
  background: #0f75bc;
  border-radius: 27px;
  height: 54px;
}

.check-icon {
  margin-top: 5px;
}
</style>

<template>
  <SideNavContainer title="Add Entity">
    <EntityChecker>
      <AddEntityTab active="authorizedSignatoryDetails" />
      <LoaderContainer :loading="loading">
        <StatusChecker>
          <form @submit="handleSubmit">
            <div class="row wrapper px-2">
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="first_name"
                  type="text"
                  :error-message="error.first_name"
                  label="First Name *"
                  placeholder="First Name"
                  @keypress="onlyAcceptAlphabets"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="last_name"
                  placeholder="Last Name"
                  type="text"
                  label="Last Name *"
                  :error-message="error.last_name"
                  @keypress="onlyAcceptAlphabets"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="mobile_no"
                  label="Mobile Number with Country Code *"
                  :error-message="error.mobile_no"
                  @keypress="checkMobileNumber($event)"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="email"
                  placeholder="Email Address"
                  type="email"
                  label="Email *"
                  :error-message="error.email"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="Designation"
                  type="text"
                  label="Designation *"
                  :error-message="error.Designation"
                  placeholder="Designation"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <h4 class="label qualifying-benefit">
                  Is the authorised signatory a resident of the UAE?
                  <span class="text-danger">*</span>
                </h4>
                <div
                  class="radio-container w-100 d-flex align-items-center pl-2"
                >
                  <input
                    id=""
                    v-model="is_from_uae"
                    type="radio"
                    value="Y"
                    :error-message="error.is_from_uae"
                    class=""
                  />
                  <p class="mb-0 yes">Yes</p>
                  <input
                    id=""
                    v-model="is_from_uae"
                    type="radio"
                    value="N"
                    name=""
                    :error-message="error.is_from_uae"
                    class=""
                  />
                  <p class="mb-0 yes">No</p>
                </div>
                <p v-if="error.is_from_uae" class="text-danger">
                  {{ error.is_from_uae }}
                </p>
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <div class="w-100">
                  <h4 class="label">
                    Emirates ID Number <span class="text-danger">*</span>
                  </h4>
                  <input
                    v-model="emirates_id_number"
                    type="text"
                    :error-message="error.emirates_id_number"
                    :class="`custom-input ${
                      error.emirates_id_number ? 'error' : ''
                    } w-100`"
                    placeholder="Emirates ID Number"
                    @keypress="checkEmirateId($event, true)"
                  />
                  <p v-if="error.emirates_id_number" class="text-danger">
                    {{ error.emirates_id_number }}
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomFileUploadInput
                  v-model="emirates_id_upload"
                  label="Upload Emirates ID"
                  :file-name="emirates_id_upload"
                  supported-file-text="*Supported file type - .png , .jpeg, .pdf. * Supported file size - 0 - 2 MB"
                  accept="image/png, image/jpeg, application/pdf"
                  :link="
                    emirates_id_upload
                      ? `${imagePath}/${emirates_id_upload}`
                      : ''
                  "
                  :error-message="error.emirates_id_upload"
                  :input-props="{
                    onchange: (e) =>
                      handleFileChange(e, 'registration_certification'),
                    accept: 'image/png, image/jpeg, application/pdf',
                  }"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <h4 class="label">
                  Nationality <span class="text-danger">*</span>
                </h4>
                <v-autocomplete
                  v-model="nationality"
                  :items="countries.data"
                  label="Nationality"
                  item-title="name"
                  item-value="id"
                  :error-message="error.nationality"
                  bg-color="#DEEDED"
                >
                </v-autocomplete>
                <p v-if="error.nationality" class="text-danger">
                  {{ error.nationality }}
                </p>
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="passport_no"
                  label="Passport No. *"
                  placeholder="Passport No."
                  :error-message="error.passport_no"
                  @keypress="onlyAcceptAlphaNumeric"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomFileUploadInput
                  v-model="passport_upload"
                  label="Upload Passport"
                  :file-name="passport_upload"
                  supported-file-text="*Supported file type - .png , .jpeg, .pdf. * Supported file size - 0 - 2 MB"
                  accept="image/png, image/jpeg, application/pdf"
                  :link="
                    passport_upload ? `${imagePath}/${passport_upload}` : ''
                  "
                  :error-message="error.passport_upload"
                  :input-props="{
                    onchange: (e) =>
                      handleFileChange(e, 'registration_certification'),
                    accept: 'image/png, image/jpeg, application/pdf',
                  }"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomDateInput
                  v-model="passport_issue_date"
                  type="number"
                  :error-message="error.passport_issue_date"
                  label="Passport Issue Date *"
                  placeholder="Select Date"
                  :max="dateNow()"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomDateInput
                  v-model="passport_expiry_date"
                  type="number"
                  :error-message="error.passport_expiry_date"
                  label="Passport Expiry Date *"
                  placeholder="Select Date"
                  :min="dateNow()"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="source_of_authorization"
                  placeholder="Source of Authorization"
                  type="text"
                  :error-message="error.source_of_authorization"
                  label="Source of Authorization *"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="place_of_power_excution"
                  placeholder="Court where Power of Attorney was Executed"
                  type="text"
                  :error-message="error.place_of_power_excution"
                  label="Court where Power of Attorney was Executed *"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomDateInput
                  v-model="power_attorney_excution_date"
                  type="number"
                  label="Date of Execution of Power of Attorney *"
                  :error-message="error.power_attorney_excution_date"
                  placeholder="Select Date"
                  :max="dateNow()"
                />
              </div>

              <div class="col-lg-4 col-md-6 mt-4">
                <CustomFileUploadInput
                  v-model="power_of_attorney_file"
                  label="Upload Power of Attorney"
                  :file-name="power_of_attorney_file"
                  supported-file-text="*Supported file type - .png , .jpeg, .pdf. * Supported file size - 0 - 2 MB"
                  accept="image/png, image/jpeg, application/pdf"
                  :link="
                    power_of_attorney_file
                      ? `${imagePath}/${power_of_attorney_file}`
                      : ''
                  "
                  :error-message="error.power_of_attorney_file"
                  :input-props="{
                    onchange: (e) =>
                      handleFileChange(e, 'registration_certification'),
                    accept: 'image/png, image/jpeg, application/pdf',
                  }"
                />
              </div>

              <p class="text-info my-2">{{ message }}</p>
              <div class="col-lg-12">
                <div class="w-100 d-flex align-items-center mt-10 mb-3">
                  <button class="save-next mr-3" type="submit">
                    Save & Next
                  </button>
                  <button
                    class="cancel"
                    type="button"
                    @click="
                      $router.push(`/entity/master-data/${$route.params.id}`)
                    "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </StatusChecker>
      </LoaderContainer>
    </EntityChecker>
  </SideNavContainer>
</template>
<script>
import { SideNavContainer } from "../../../../layout";
import EntityChecker from "../../EntityChecker.vue";
import StatusChecker from "../../StatusChecker.vue";
import { useEntityStore } from "../../../../../store/entity";
import { storeToRefs } from "pinia";
import { getEntityItems, getEntityItem } from "@/store/entityActions/get";
import { useCountryStore } from "@/store/country";
import { checkUaeId } from "../../../../../helpers/checkers";
import {
  onlyAcceptAlphaNumeric,
  onlyAcceptAlphabets,
} from "../../../../../helpers/validation";
import { checkFileSize } from "../../../../../helpers/file";

import {
  AddEntityTab,
  LoaderContainer,
  CustomTextInput,
  CustomSelectInput,
  CustomDateInput,
  CustomFileUploadInput,
} from "../../../../common";
import { dateNow } from "../../../../../helpers/date";
import { addEntity } from "../../../../../store/entityActions/post";
import { emailRegex } from "../../../../../constants/regex-format";

export default {
  name: "AuthorizedSignatoryDetailsForm",
  components: {
    SideNavContainer,
    EntityChecker,
    StatusChecker,
    AddEntityTab,
    LoaderContainer,
    CustomTextInput,
    CustomSelectInput,
    CustomDateInput,
    CustomFileUploadInput,
  },
  setup() {
    const entityStore = useEntityStore();
    const { designation, entityAuthorizedSignatory } = storeToRefs(entityStore);
    const countryStore = useCountryStore();
    const { countries } = storeToRefs(countryStore);
    return {
      entityStore,
      designation,
      countries,
      countryStore,
      onlyAcceptAlphaNumeric,
      onlyAcceptAlphabets,
      dateNow,
      entityAuthorizedSignatory,
    };
  },
  data() {
    return {
      loading: true,
      first_name: "",
      last_name: "",
      mobile_no: "",
      email: "",
      Designation: "",
      is_from_uae: "",
      emirates_id_number: "",
      emirates_id_upload: "",
      nationality: "",
      passport_no: "",
      passport_upload: "",
      passport_issue_date: "",
      passport_expiry_date: "",
      source_of_authorization: "",
      place_of_power_excution: "",
      power_attorney_excution_date: "",
      power_of_attorney_file: "",
      imagePath: "",
      error: {
        emirates_id_number: false,
        passport_no: false,
        email: false,
        mobile_no: false,
        passport_upload: "",
        emirates_id_upload: "",
        passport_issue_date: "",
        passport_expiry_date: "",
      },
      message: "",
      edit: false,
    };
  },

  watch: {
    first_name() {
      this.error.first_name = "";
    },
    last_name() {
      this.error.last_name = "";
    },
    mobile_no() {
      this.error.mobile_no = "";
    },
    email() {
      this.error.email = "";
    },
    Designation() {
      this.error.Designation = "";
    },
    is_from_uae() {
      this.error.is_from_uae = "";
    },
    emirates_id_number() {
      this.error.emirates_id_number = "";
    },
    nationality() {
      this.error.nationality = "";
    },
    passport_no() {
      this.error.passport_no = "";
    },
    passport_upload() {
      this.error.passport_upload = "";
    },
    emirates_id_upload() {
      this.error.emirates_id_upload = "";
    },
    passport_issue_date() {
      this.error.passport_issue_date = "";
    },
    passport_expiry_date() {
      this.error.passport_expiry_date = "";
    },

    source_of_authorization() {
      this.error.source_of_authorization = "";
    },

    place_of_power_excution() {
      this.error.place_of_power_excution = "";
    },
    power_attorney_excution_date() {
      this.error.power_attorney_excution_date = "";
    },
    power_of_attorney_file() {
      this.error.power_of_attorney_file = "";
    },
  },
  async created() {
    const id = this.$route.params.id;
    if (!this.designation.data.length) {
      await getEntityItems("designation", "/designation");
    }
    if (!this.countries.data.length) {
      await this.countryStore.getCountryList();
    }
    if (id) {
      this.getSubId();
    }
    this.loading = false;
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      if (!this.first_name) {
        this.error.first_name = " Field is required";
        return;
      }
      if (!this.last_name) {
        this.error.last_name = " Field is required";
        return;
      }
      if (!this.mobile_no) {
        this.error.mobile_no = " Field is required";
        return;
      }
      if (
        !(
          String(this.mobile_no).length <= 13 &&
          String(this.mobile_no).length >= 8
        ) &&
        !!this.mobile_no
      ) {
        this.error.mobile_no =
          "Invalid Mobile Number, Mobile number should be between 8 to 13 digits ";
        return;
      }
      if (!this.email) {
        this.error.email = " Field is required";
        return;
      }

      if (!this.Designation) {
        this.error.Designation = " Field is required";
        return;
      }
      if (!this.is_from_uae) {
        this.error.is_from_uae = " Field is required";
        return;
      }
      if (!this.emirates_id_number) {
        this.error.emirates_id_number = " Field is required";
        return;
      }
      if (!checkUaeId(this.emirates_id_number) && this.emirates_id_number) {
        this.error.emirates_id_number =
          "Invalid UAE ID please enter the correct format";
        return;
      }
      if (!this.nationality) {
        this.error.nationality = " Field is required";
        return;
      }
      if (!this.passport_no) {
        this.error.passport_no = " Field is required";
        return;
      }
      if (!this.passport_issue_date) {
        this.error.passport_issue_date = " Field is required";
        return;
      }
      if (!this.passport_expiry_date) {
        this.error.passport_expiry_date = " Field is required";
        return;
      }
      if (!this.source_of_authorization) {
        this.error.source_of_authorization = " Field is required";
        return;
      }
      if (!this.place_of_power_excution) {
        this.error.place_of_power_excution = " Field is required";
        return;
      }
      if (!this.power_attorney_excution_date) {
        this.error.power_attorney_excution_date = " Field is required";
        return;
      }
      if (this.email && !emailRegex.test(this.email)) {
        this.error.email = "Invalid Email";
        return;
      }
      const id = this.$route.params.id;
      const formData = new FormData();

      formData.append("entity_id", Number(this.$route.params.id));
      formData.append("first_name", this.first_name);
      formData.append("last_name", this.last_name);
      formData.append("mobile_no", this.mobile_no);
      formData.append("email", this.email);
      formData.append("designation", this.Designation); // mobile_no: '',
      formData.append("is_from_uae", this.is_from_uae); // phone_no: '',
      formData.append("emirates_id_number", this.emirates_id_number); // email: '',
      formData.append("nationality", this.nationality); // registered_address: '',
      formData.append("passport_no", this.passport_no); // business_address: '',
      formData.append("passport_issue_date", this.passport_issue_date); // : '',
      formData.append("passport_expiry_date", this.passport_expiry_date); //   : '',
      formData.append("source_of_authorization", this.source_of_authorization);
      formData.append("place_of_power_excution", this.place_of_power_excution);
      formData.append(
        "power_attorney_excution_date",
        this.power_attorney_excution_date
      );
      if (
        typeof this.power_of_attorney_file !== "string" &&
        this.power_of_attorney_file
      ) {
        formData.append("power_of_attorney_file", this.power_of_attorney_file);
      }
      if (typeof this.power_of_attorney_file === "object") {
        if (!checkFileSize(this.power_of_attorney_file)) {
          this.error.power_of_attorney_file =
            "File size should be less than 2MB";
          return;
        }
      }

      if (typeof this.passport_upload !== "string" && this.passport_upload) {
        formData.append("passport_upload", this.passport_upload);
      }
      if (typeof this.passport_upload === "object") {
        if (!checkFileSize(this.passport_upload)) {
          this.error.passport_upload = "File size should be less than 2MB";
          return;
        }
      }
      if (
        typeof this.emirates_id_upload !== "string" &&
        this.emirates_id_upload
      ) {
        formData.append("emirates_id_upload", this.emirates_id_upload);
      }
      if (typeof this.passpemirates_id_uploadort_upload === "object") {
        if (!checkFileSize(this.emirates_id_upload)) {
          this.error.emirates_id_upload = "File size should be less than 2MB";
          return;
        }
      }

      if (this.edit) {
        formData.append("_method", "PUT");
        formData.append(
          "entity_auth_sign_id",
          Number(this.$route.params.sub_id)
        );
        await addEntity(
          "/entityAuthorizedSignatory",
          formData,
          this.setMessage,
          async () => {
            setTimeout(() => {
              this.$router.push("/entity/authorized-signatory-details/" + id);
            }, 2000);
          },
          true
        );
        return;
      }
      await addEntity(
        "/entityAuthorizedSignatory",
        formData,
        this.setMessage,
        async () => {
          setTimeout(() => {
            this.$router.push("/entity/add-entity/declaration/" + id);
          }, 2000);
        },
        true
      );
    },
    checkEmirateId(e) {
      let reg = /^[0-9 -]+$/;
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (reg.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    checkMobileNumber(e, containMinus = false) {
      let reg = containMinus ? /^[0-9 +-]+$/ : /^[0-9+]+$/;
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (reg.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    handleFileChange() {
      this.error.power_of_attorney_file = "";
    },
    async getSubId() {
      const id = this.$route.params.sub_id;

      await getEntityItem(
        "entityAuthorizedSignatory",
        "/entityAuthorizedSignatory",
        this.$route.params.id
      );

      const entityAuthorizedSignatory =
        this.entityAuthorizedSignatory?.data?.find(
          (e) => Number(e.id) === Number(this.$route.params.sub_id)
        );

      if (entityAuthorizedSignatory) {
        this.edit = true;
        this.first_name = entityAuthorizedSignatory?.first_name;
        this.last_name = entityAuthorizedSignatory?.last_name;
        this.mobile_no = entityAuthorizedSignatory?.mobile_no;
        this.email = entityAuthorizedSignatory?.email;
        this.Designation = entityAuthorizedSignatory?.designation;
        this.is_from_uae = entityAuthorizedSignatory?.is_from_uae;
        this.emirates_id_number = entityAuthorizedSignatory?.emirates_id_number;
        this.nationality = entityAuthorizedSignatory?.nationality;
        this.passport_no = entityAuthorizedSignatory?.passport_no;
        this.passport_issue_date =
          entityAuthorizedSignatory?.passport_issue_date;
        this.passport_expiry_date =
          entityAuthorizedSignatory?.passport_expiry_date;
        this.source_of_authorization =
          entityAuthorizedSignatory?.source_of_authorization;
        this.place_of_power_excution =
          entityAuthorizedSignatory?.place_of_power_excution;
        this.power_attorney_excution_date =
          entityAuthorizedSignatory?.power_attorney_excution_date;
        this.power_of_attorney_file =
          entityAuthorizedSignatory?.power_of_attorney_file;
        this.passport_upload = entityAuthorizedSignatory?.passport_upload;
        this.emirates_id_upload = entityAuthorizedSignatory?.emirates_id_upload;
        this.imagePath = entityAuthorizedSignatory?.imagepath;
      }
    },
    setMessage(message) {
      this.message = message;
    },
  },
};
</script>
<style scoped>
.save-next {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #fafbff;
  width: 196px;
  background: #0f75bc;
  height: 55px;
}

.cancel {
  background: #ffffff;
  border: 1px solid #747171;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #747171;
  height: 55px;
  width: 196px;
}

.custom-input {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  height: 48px;
  padding: 13px 10px;
}

.custom-input.error {
  border: 1px solid red;
}

.custom-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
  text-align: start;
}

.custom-input:focus {
  outline: 0;
}

.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}

.radio-container {
  height: 56px;
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  gap: 11px;
}
.label.qualifying-benefit {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>

<template>
  <div class="w-100">
    <AlertModal
      :open="licenseAuthorityTypeModal"
      message="Warning: You have not selected your License Authority Type"
      sub-message="Please be advised that you have to fill this field in order to proceed to the next step"
      :on-confirm="goToLinceseAuthorityTypePage"
      :has-cancel="false"
      :button-name="`Go Now`"
      :modal-props="{ persistent: true }"
    />
    <AlertModal
      :open="financialPeriodModal"
      message="Warning: You have not selected your Financial Period"
      sub-message="Please be advised that you have to fill this field in order to proceed to the next step"
      :on-confirm="goToFinancialPeriod"
      :has-cancel="false"
      :button-name="`Go Now`"
      :modal-props="{ persistent: true }"
    />

    <LoaderContainer
      :loading="entity.loading"
      :error="
        entity.error || (!checkEntity ? 'Can not get this entity item' : false)
      "
    >
      <slot />
    </LoaderContainer>
  </div>
</template>

<script setup>
import { useEntityStore } from "../../../store/entity";
import { storeToRefs } from "pinia";
import { getEntityItem } from "@/store/entityActions/get";
import LoaderContainer from "../../common/LoaderContainer.vue";
import { onMounted, ref, defineProps } from "vue";
import { useRoute, useRouter } from "vue-router";
import { AlertModal } from "../../common";

const props = defineProps({
  checkMandatoryFields: {
    type: Boolean,
    default: false,
  },
});
const licenseAuthorityTypeModal = ref(false);
const financialPeriodModal = ref(false);
const entityStore = useEntityStore();
const { entity } = storeToRefs(entityStore);
const checkEntity = ref(true);
const route = useRoute();
const id = route.params.id;
const router = useRouter();
const getEntity = async () => {
  await getEntityItem("entity", "/entity", id);
};

const goToLinceseAuthorityTypePage = () => {
  licenseAuthorityTypeModal.value = false;
  const licenseId = entity.value.data?.license[0]?.id;
  if (licenseId) {
    router.push(
      `/entity/add-entity/entity-details/license/${route.params.id}/${licenseId}`
    );
  } else {
    router.push(`/entity/add-entity/entity-details/entity/${route.params.id}`);
  }
};
const goToFinancialPeriod = () => {
  financialPeriodModal.value = false;
  const taxId = entity.value.data?.tax[0]?.id;
  if (taxId) {
    router.push(
      `/entity/add-entity/entity-details/license/${route.params.id}/${taxId}`
    );
  } else {
    router.push(`/entity/add-entity/entity-details/entity/${route.params.id}`);
  }
};
onMounted(async () => {
  if (!entity.value.data) {
    await getEntity();
  }
  const check = entity.value.data?.entity?.find(
    (e) => Number(e.id) === Number(id)
  );
  if (check) {
    checkEntity.value = !!check;
  } else {
    await getEntity();
    const checkSecond = entity.value.data?.entity?.find(
      (e) => Number(e.id) === Number(id)
    );
    if (checkSecond) {
      checkEntity.value = !!check;
    } else checkEntity.value = false;
  }
  if (props.checkMandatoryFields) {
    const licenseAuthorityType =
      entity.value.data?.license[0]?.license_authority_type?.id;
    const financialPeriod = entity.value.data?.tax[0]?.financial_period?.id;
    if (!licenseAuthorityType) {
      entity.value.loading = true;
      licenseAuthorityTypeModal.value = true;
    } else if (!financialPeriod) {
      entity.value.loading = true;
      financialPeriodModal.value = true;
    } else {
      entity.value.error = entity.value.error || "";
    }
  }
});
</script>

<style scoped>
.vh {
  min-height: 40vh;
}
</style>

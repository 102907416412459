<script setup>
import { SideNavContainer } from "../../layout";
import { ref, watch, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
import { useBusinessStore } from "../../../store/buisness.store";
import { storeToRefs } from "pinia";
import Business from "../Business/Business.vue";
import { useChapter13Store } from "../../../store/chapter13";
import { useCountryStore } from "../../../store/country";
import { NumberFormatter } from "../../../helpers/numberFormatter";
import { generate } from "randomized-string";
import MasterData from "./chapter13/MasterData.vue";
import { dateNow } from "../../../helpers/date";
import { ChaptersBreadCrumb } from "../../BreadCrumbs";
import LoaderContainer from "../../common/LoaderContainer.vue";
import { useStatusStore } from "../../../store/statusStore";
import { AlertModal, CustomSelectInput } from "../../common";
import { validateAmount } from "../../../helpers/validation";
import { insertChar } from "../../../helpers/stringUtils";

const name = ref("");
const route = useRoute();
const businessStore = useBusinessStore();
const { business } = storeToRefs(businessStore);
const chapter13Store = useChapter13Store();
const dateOfPayment = ref(null);
const {
  data,
  savingMessage,
  loading,
  totalIncome,
  error,
  totalWithholdingTaxCredit,
} = storeToRefs(chapter13Store);
const statusStore = useStatusStore();
const {
  status,
  loading: statusLoading,
  error: statusError,
} = storeToRefs(statusStore);
const countryStore = useCountryStore();
const { countries } = storeToRefs(countryStore);

const setBusinessName = (newValue, current_business) => {
  const findBusiness = newValue?.data?.find((b) => b.id == current_business);
  name.value = findBusiness?.name ? findBusiness?.name : "";
};
onMounted(async () => {
  if (!countries.value.data.length) {
    await countryStore.getCountryList();
  }
  await chapter13Store.getChapter13(route);

  if (route.query.financial_year && route.query.financial_period) {
    await statusStore.getStatus(
      route.params.id,
      route.query.financial_year,
      route.query.financial_period
    );
  }
});

watch(
  business,
  (newValue) => {
    if (newValue?.data.length)
      setBusinessName(newValue, route.query.current_business);
  },
  { deep: true }
);
watch(
  data,
  (newValue) => {
    newValue.foreign_tax_credit.forEach((tax, index) => {
      let country = countries.value.data.find(
        (country) => country?.name === tax.country_name
      );
      data.value.foreign_tax_credit[index].country_code = country?.code;
    });
  },
  { deep: true }
);
watch(
  () => route.query,
  (newValue) => {
    setBusinessName(business.value, newValue.current_business);
  }
);

const addNewForeignTaxCredit = () => {
  data.value.foreign_tax_credit.push({
    id: generate(),
    country_name: "",
    country_code: "",
    tax_identification_no_of_tax_payer: "",
    total_income: "",
    total_tax_credit_claimed: "",
  });
};

const addNewWithholdingTaxCredit = () => {
  data.value.withholding_tax_credit.push({
    id: generate(),
    trn_code_of_deductor: "",
    nature_of_income: "",
    name_of_deductor: "",
    total_income: "",
    total_tax_deducted: "",
    percent_of_tax_deducted: "",
  });
};
const deleteItem = (index, type) => {
  if (confirm("Are you sure you want to delete this item")) {
    const id = data.value[type][index]?.id;
    data.value[type] = data.value[type].filter((a) => a.id != id);
  }
};
const alertModal = reactive({
  foreignTaxCredit: false,
  holdingTaxCredit: false,
});
const setAlertModal = (value, key) => {
  alertModal[key] = value;
};

const getValue = (e) => {
  let value = "";
  const index = e.target.selectionStart;
  const prevValue = e.target.value;
  const currentKey = String.fromCharCode(e.keyCode);
  value = insertChar(prevValue, currentKey, index);
  return value;
};
const checkTotalTaxCreditClaimed = (
  e,
  dataIndex,
  alertModalkey = "foreignTaxCredit",
  type = "total_income"
) => {
  const value = getValue(e);
  if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(value)) {
    e.preventDefault();
    return;
  }
  if (alertModalkey === "foreignTaxCredit") {
    if (type === "total_income") {
      if (
        Number(value) >
        Number(data.value.foreign_tax_credit[dataIndex].total_income)
      ) {
        e.preventDefault();
        e.target.blur();
        setAlertModal(true, alertModalkey);
      }
    } else if (type === "total_tax_deducted") {
      if (
        Number(value) <
        Number(
          data.value.foreign_tax_credit[dataIndex].total_tax_credit_claimed
        )
      ) {
        e.preventDefault();
        e.target.blur();
        setAlertModal(true, alertModalkey);
      }
    }
  } else if (alertModalkey === "holdingTaxCredit") {
    if (type === "total_income") {
      if (
        Number(value) >
        Number(data.value.withholding_tax_credit[dataIndex].total_income)
      ) {
        e.preventDefault();
        e.target.blur();
        setAlertModal(true, alertModalkey);
      }
    } else if (type === "total_tax_deducted") {
      if (
        Number(value) <
        Number(data.value.withholding_tax_credit[dataIndex].total_tax_deducted)
      ) {
        e.preventDefault();
        e.target.blur();
        setAlertModal(true, alertModalkey);
      }
    }
  }
};
</script>

<template>
  <SideNavContainer
    side-nav-type="financialData"
    title="Withholding and Foreign Tax Credit as per Chapter-13"
    :subtitle="name"
  >
    <ChaptersBreadCrumb
      :entity-name="name"
      chapter="chapter-13"
      chapter-name="Chapter-13"
    />
    <Business Tab="chapter-13" />
    <LoaderContainer
      :loading="loading || statusLoading"
      :error="error || statusError"
    >
      <MasterData v-if="$route.query.current_business === 'master'" />
      <div v-else class="w-100">
        <AlertModal
          :open="alertModal.foreignTaxCredit"
          :message="`Total Tax Credit Claimed value cannot be greater than Total Income `"
          sub-message=""
          :has-cancel="false"
          :on-confirm="() => setAlertModal(false, 'foreignTaxCredit')"
          :on-cancel="() => setAlertModal(false, 'foreignTaxCredit')"
          button-name="ok"
        />
        <AlertModal
          :open="alertModal.holdingTaxCredit"
          :message="`Total Tax Deducted value cannot be greater than Total Income `"
          sub-message=""
          :has-cancel="false"
          :on-confirm="() => setAlertModal(false, 'holdingTaxCredit')"
          :on-cancel="() => setAlertModal(false, 'holdingTaxCredit')"
          button-name="ok"
        />
        <div class="d-flex justify-content-end align-items-center">
          <p :class="`text-${savingMessage.type} mr-10 mb-0`">
            {{ savingMessage.message }}
          </p>
          <button
            class="save-button mr-3"
            @click="chapter13Store.saveChapter13($route)"
          >
            Save
          </button>
        </div>
        <div
          class="w-100 mt-4 d-flex align-items-center justify-content-between"
        >
          <h4 class="title bold mb-0">Foreign Tax Credit</h4>
          <button
            v-if="!status"
            class="save-button mr-3"
            @click="addNewForeignTaxCredit"
          >
            <img src="@/assets/svg/plus-square-light.svg" alt="" />
            Add New
          </button>
        </div>

        <div class="w-100 table-responsive mt-2">
          <table class="table table-bordered table-striped mt-2 mb-0">
            <thead>
              <th class="table-header w-25">Country Name</th>
              <th class="table-header w-20">Country Code</th>
              <th class="table-header w-20">
                Tax Identification Number of Tax Payer in Respective Country
              </th>
              <th class="table-header w-20">Total Income</th>
              <th class="table-header w-20">Total Tax Credit Claimed</th>
              <th class="table-header w-20">Delete</th>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data.foreign_tax_credit" :key="index">
                <td class="table-rows align-middle">
                  <v-autocomplete
                    v-model="data.foreign_tax_credit[index].country_name"
                    label=""
                    :items="countries.data"
                    item-title="name"
                    item-value="name"
                    bg-color="#DEEDED"
                    custom-class="mt-2"
                  >
                  </v-autocomplete>
                </td>
                <td class="table-rows align-middle">
                  <v-autocomplete
                    v-model="data.foreign_tax_credit[index].country_code"
                    label=""
                    :items="countries.data"
                    item-title="code"
                    item-value="code"
                    bg-color="#DEEDED"
                    :disabled="true"
                  >
                  </v-autocomplete>
                </td>
                <td class="table-rows align-middle">
                  <input
                    v-model="
                      data.foreign_tax_credit[index]
                        .tax_identification_no_of_tax_payer
                    "
                    type="text"
                    class="table-input"
                    placeholder="Identification number"
                    :disabled="!!status"
                  />
                </td>
                <td class="table-rows align-middle">
                  <input
                    v-model="data.foreign_tax_credit[index].total_income"
                    type="text"
                    class="table-input"
                    placeholder="value"
                    :disabled="!!status"
                    @keypress="
                      (e) =>
                        checkTotalTaxCreditClaimed(
                          e,
                          index,
                          'foreignTaxCredit',
                          'total_tax_deducted'
                        )
                    "
                  />
                </td>
                <td class="table-rows align-middle">
                  <input
                    v-model="
                      data.foreign_tax_credit[index].total_tax_credit_claimed
                    "
                    type="text"
                    class="table-input"
                    placeholder="value"
                    :disabled="!!status"
                    @keypress="(e) => checkTotalTaxCreditClaimed(e, index)"
                  />
                </td>
                <td class="table-rows align-middle">
                  <button
                    v-if="!status"
                    class="save-button w-100"
                    @click="deleteItem(index, 'foreign_tax_credit')"
                  >
                    <img src="@/assets/svg/trash.svg" alt="" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="w-100 gray pr-3 py-3 d-flex align-items-center justify-content-end"
        >
          <p class="title bold mb-0">
            Total tax:
            {{ NumberFormatter(totalIncome) }}
          </p>
        </div>
        <div
          class="w-100 mt-4 d-flex align-items-center justify-content-between"
        >
          <h4 class="title bold mb-0">With holding Tax Credit</h4>
          <button
            v-if="!status"
            class="save-button mr-3"
            @click="addNewWithholdingTaxCredit"
          >
            <img src="@/assets/svg/plus-square-light.svg" alt="" />
            Add New
          </button>
        </div>

        <div class="table-responsive mt-2">
          <table class="table table-bordered table-striped mt-2 mb-0">
            <thead>
              <th class="table-header">S.No</th>
              <th class="table-header w-25">TRN Code Of Deductor</th>
              <th class="table-header w-25">Nature Of Income</th>
              <th class="table-header w-25">Name Of Deductor</th>
              <th class="table-header">Total Income</th>
              <th class="table-header">Total Tax Deducted</th>
              <th class="table-header">% Of Tax Deducted</th>

              <th class="table-header">Delete</th>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in data.withholding_tax_credit"
                :key="index"
              >
                <td class="table-rows pl-3 align-middle">
                  {{ index + 1 }}
                </td>
                <td class="table-rows w-25 align-middle">
                  <input
                    v-model="
                      data.withholding_tax_credit[index].trn_code_of_deductor
                    "
                    type="text"
                    class="table-input"
                    placeholder="TRN Code"
                    :disabled="!!status"
                  />
                </td>
                <td class="table-rows w-10 align-middle">
                  <input
                    v-model="
                      data.withholding_tax_credit[index].nature_of_income
                    "
                    type="text"
                    class="table-input w-50"
                    placeholder="Nature of Income"
                    :disabled="!!status"
                  />
                </td>
                <td class="table-rows w-10 align-middle">
                  <input
                    v-model="
                      data.withholding_tax_credit[index].name_of_deductor
                    "
                    type="text"
                    class="table-input w-50"
                    placeholder="Name of deductor"
                    :disabled="!!status"
                  />
                </td>
                <td class="table-rows w-10 align-middle">
                  <input
                    v-model="data.withholding_tax_credit[index].total_income"
                    type="text"
                    class="table-input"
                    placeholder="value"
                    :disabled="!!status"
                    @keypress="
                      (e) =>
                        checkTotalTaxCreditClaimed(
                          e,
                          index,
                          'holdingTaxCredit',
                          'total_tax_deducted'
                        )
                    "
                  />
                </td>
                <td class="table-rows w-10 align-middle">
                  <input
                    v-model="
                      data.withholding_tax_credit[index].total_tax_deducted
                    "
                    type="text"
                    class="table-input"
                    placeholder="value"
                    :disabled="!!status"
                    @keypress="
                      (e) =>
                        checkTotalTaxCreditClaimed(e, index, 'holdingTaxCredit')
                    "
                  />
                </td>
                <td class="table-rows w-10 align-middle">
                  <input
                    v-model="
                      data.withholding_tax_credit[index].percent_of_tax_deducted
                    "
                    type="number"
                    class="table-input"
                    placeholder="value"
                    :disabled="!!status"
                  />
                </td>
                <td class="table-rows align-middle">
                  <button
                    v-if="!status"
                    class="save-button w-41"
                    @click="deleteItem(index, 'withholding_tax_credit')"
                  >
                    <img src="@/assets/svg/trash.svg" alt="" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="w-100 gray py-3 pr-3 d-flex align-items-center justify-content-end mb-4"
        >
          <p class="title bold mb-0">
            Total Tax Deducted:
            {{ NumberFormatter(totalWithholdingTaxCredit) }}
          </p>
        </div>
      </div>
    </LoaderContainer>
  </SideNavContainer>
</template>

<style scoped>
.table-header {
  height: 70px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-left: 10px;
  vertical-align: middle;
  border: 3px solid #fff;
}
.table-rows {
  background: #ffffff;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
  border: 3px solid #fff;
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.save-button {
  min-width: 135px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.table-input {
  padding: 10px;
  width: 100% !important;
}
.table-input:focus {
  outline: 0;
}
.table-input::placeholder {
  text-align: left;
}
.outline-none {
  outline: none !important;
}
.article {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
.flex-2 {
  flex: 7;
}
.flex-1 {
  flex: 1;
}
.gray {
  background: #deeded;
}
.w-20 {
  width: 20%;
}
.w-10 {
  width: 10% !important;
}
.save-button.w-41 {
  min-width: 41px;
}
</style>

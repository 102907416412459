<template>
  <SideNavContainer
    title="List of Entities"
    :TitleRightSide="AddNewEntityButton"
  >
    <!-- <ChoosePlan :open="open"  :closeModal="closeModal"/>
    <div class="wrapper h-100 w-100 d-flex align-items-center justify-content-center">
     <button class="button btn-add-entity d-flex justify-content-center align-items-center"
     @click="openModal()">
        <img src="@/assets/svg/plus-square-light.svg" alt="">
          Add Entity
     </button>
    </div> -->
    <EntityList />
  </SideNavContainer>
</template>

<script>
import { defineComponent } from "vue";
import { SideNavContainer } from "../layout";
import AddNewEntityButton from "./AddEntity/AddNewEntityButton.vue";
// import ChoosePlan from './AddEntity/ChoosePlan.vue'
import { useSubscriptionStore } from "../../store/subscription";
import { storeToRefs } from "pinia";
import EntityList from "./List/EntityList.vue";
import { useEntityStore } from "../../store/entity";

export default defineComponent({
  name: "EntityContainer",
  components: { SideNavContainer, EntityList },
  data() {
    return {
      open: false,
      message: "",
      didPurchase: true,
    };
  },
  setup() {
    const subscriptionStore = useSubscriptionStore();
    const { mySubscription } = storeToRefs(subscriptionStore);
    const entityStore = useEntityStore();
    const { entities } = entityStore;
    return { AddNewEntityButton, mySubscription, subscriptionStore, entities };
  },
  methods: {
    closeModal() {
      this.open = false;
    },
    openModal() {
      if (this.didPurchase) {
        this.$router.push("/entity/add-entity/entity-details/entity");
      } else {
        this.open = true;
      }
    },
  },
  async created() {
    if (!this.mySubscription.data) {
      await this.subscriptionStore.getMyPlan();
    }
    this.didPurchase = !!this.mySubscription.data?.length;
  },
});
</script>

<style scoped>
.wrapper {
  min-height: 80vh;
}
.btn-add-entity {
  background: #0f75bc;
  border-radius: 5px;
  width: 177.33px;
  height: 47px;
  gap: 6.6px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
</style>

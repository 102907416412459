<!-- eslint-disable camelcase -->
<template>
  <SideNavContainer title="Add Entity">
    <EntityChecker>
      <AddEntityTab active="entityDetails" />
      <Progress :active="4" />
      <StatusChecker>
        <div class="row wrapper px-2 mt-10">
          <div class="col-lg-4 col-md-6 mt-4">
            <h4 class="label">Industry <span class="text-danger">*</span></h4>
            <v-autocomplete
              v-model="industry"
              label="Industry"
              :items="industries.data"
              item-title="name"
              item-value="id"
              bg-color="#DEEDED"
            >
            </v-autocomplete>
            <p v-if="error.industry" class="text-danger">
              {{ error.industry }}
            </p>
          </div>
          <div class="col-lg-4 col-md-6 mt-4">
            <h4 class="label">
              Main Group
              <span class="text-danger">*</span>
            </h4>
            <v-autocomplete
              v-model="main_group"
              label="Main Group"
              :items="mainGroups.data"
              item-title="main_group"
              item-value="id"
              bg-color="#DEEDED"
              :loading="mainGroups.loading ? 'info' : false"
            >
            </v-autocomplete>
            <p v-if="error.main_group" class="text-danger">
              {{ error.main_group }}
            </p>
          </div>
          <div class="col-lg-4 col-md-6 mt-4">
            <h4 class="label">Sub Group <span class="text-danger">*</span></h4>
            <v-autocomplete
              v-model="sub_group"
              label="Sub Group"
              :items="subGroups.data"
              item-title="sub_group"
              item-value="id"
              bg-color="#DEEDED"
              :loading="subGroups.loading ? 'info' : false"
            >
            </v-autocomplete>
            <p v-if="error.sub_group" class="text-danger">
              {{ error.sub_group }}
            </p>
          </div>
          <div class="col-lg-4 col-md-6 mt-4">
            <h4 class="label">Activity <span class="text-danger">*</span></h4>
            <v-autocomplete
              v-model="activity"
              label="Activity"
              :error-message="error.activity"
              :items="activities.data"
              item-title="activity"
              item-value="id"
              :loading="activities.loading ? 'info' : false"
              bg-color="#DEEDED"
            >
            </v-autocomplete>
            <p v-if="error.activity" class="text-danger">
              {{ error.activity }}
            </p>
          </div>
          <div class="col-lg-4 col-md-6 mt-4">
            <CustomTextInput
              v-model="activity_code"
              placeholder="Activity Code"
              disabled="disabled"
              label="Activity Code"
            />
          </div>
        </div>
        <div class="d-flex align-items-center mt-10">
          <button class="save-next mr-3" @click="saveBusinessActivity">
            Save & Next
          </button>
          <button class="cancel" @click="$router.push(`/entity`)">
            Cancel
          </button>
        </div>
      </StatusChecker>
    </EntityChecker>
  </SideNavContainer>
</template>

<script>
import { SideNavContainer } from "../../../../layout";
import { AddEntityTab } from "../../../../common";
import Progress from "./Progress.vue";
import EntityChecker from "../../EntityChecker.vue";
import { getEntityItem, getEntityItems } from "@/store/entityActions/get";
import { useEntityStore } from "../../../../../store/entity";
import { storeToRefs } from "pinia";
import { updateEntity } from "../../../../../store/entityActions/put";
import StatusChecker from "../../StatusChecker.vue";
import CustomTextInput from "../../../../common/CustomTextInput.vue";
import { addEntity } from "../../../../../store/entityActions/post";

export default {
  name: "BusinessActivity",
  components: {
    SideNavContainer,
    AddEntityTab,
    Progress,
    EntityChecker,
    StatusChecker,
    CustomTextInput,
  },
  setup() {
    const entityStore = useEntityStore();
    const {
      entity,
      businessActivity,
      industries,
      mainGroups,
      subGroups,
      activities,
    } = storeToRefs(entityStore);
    // const industryStore = useIndustryStore();
    // const { industries } = storeToRefs(industryStore);
    return {
      entity,
      businessActivity,
      industries,
      mainGroups,
      subGroups,
      activities,
    };
  },
  data() {
    return {
      industry: "",
      main_group: "",
      sub_group: "",
      activity: "",
      activity_code: "",
      has_branch: "",
      message: "",
      edit: false,
      first_time: {
        main_group: true,
        sub_group: true,
        activity: true,
        activity_code: true,
      },
      error: {
        industry: false,
        main_group: false,
        sub_group: false,
        activity: false,
      },
    };
  },

  watch: {
    async industry(newValue, oldValue) {
      if (newValue !== oldValue) {
        await getEntityItem("mainGroups", "/mainGroups", newValue);
      } else if (!newValue) {
        this.mainGroups.data = [];
      }
      this.error.industry = "";
      if (this.first_time.main_group) {
        this.first_time.main_group = false;
        return;
      }
      const check = this.mainGroups.data.find((c) => c.id == this.main_group);
      if (!check) {
        this.main_group = "";
      }
    },

    async main_group(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        await getEntityItem("subGroups", "/subGroups", newValue);
      } else if (!newValue) {
        this.subGroups.data = [];
      }
      this.error.main_group = "";
      if (this.first_time.sub_group) {
        this.first_time.sub_group = false;
        return;
      }
      const check = this.subGroups.data.find((c) => c.id == this.sub_group);
      if (!check) {
        this.sub_group = "";
      }
    },

    async sub_group(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        await getEntityItem("activities", "/activities", newValue);
      } else if (!newValue) {
        this.activities.data = [];
      }
      this.error.sub_group = "";
      if (this.first_time.activity) {
        this.first_time.activity = false;
        return;
      }
      const check = this.activities.data.find((c) => c.id == this.activity);
      if (!check) {
        this.activity = "";
      }
    },

    async activity(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        const activities = this.activities.data.find((c) => c.id === newValue);
        if (activities) {
          this.activity_code = activities.activity_code;
        } else {
          if (this.first_time.activity_code) {
            this.first_time.activity_code = false;
            return;
          }
          this.activity_code = "";
        }
      } else if (!newValue) {
        this.activity_code = "";
      }
      this.error.activity = "";
    },
  },

  async created() {
    const id = this.$route.params.sub_id;

    if (!this.industries.data.length) {
      await getEntityItems("industries", "/industries");
    }
    //if (!this.activities.data.length) {
    // await getEntityItems("activities", "/activities");
    //}
    this.mainGroups.loading = false;
    this.subGroups.loading = false;
    this.activities.loading = false;
    if (id) {
      this.getSubId();
    }
  },
  methods: {
    async saveBusinessActivity() {
      if (!this.industry) {
        this.error.industry = "Field is required!";
        return;
      }
      if (!this.main_group) {
        this.error.main_group = "Field is required!";
        return;
      }
      if (!this.sub_group) {
        this.error.sub_group = "Field is required!";
        return;
      }
      if (!this.activity) {
        this.error.activity = "Field is required!";
        return;
      }

      const id = Number(this.$route.params?.id);
      const readyData = {
        industry: this.industry,
        main_group: this.main_group,
        sub_group: this.sub_group,
        activity: this.activity,
        activity_code: this.activity_code,
        entity_id: id,
        //entity_business_activity_id: Number(this.$route.params?.sub_id),
      };
      if (this.edit) {
        const id = Number(this.$route.params.sub_id);
        await updateEntity(
          `/entityBusinessActivity`,
          {
            ...readyData,
            entity_business_activity_id: Number(this.$route.params?.sub_id),
          },
          this.setMessage,
          async () => {
            await getEntityItem("entity", "/entity", this.$route.params.id);

            this.$router.push(
              "/entity/entity-details/" + this.$route.params.id
            );
          },
          false
        );
        return;
      }

      await addEntity(
        "/entityBusinessActivity",
        { entity_id: id, ...readyData },
        this.setMessage,
        async () => {
          setTimeout(() => {
            this.$router.push("/entity/add-entity/contact-details/" + id);
          }, 2000);
        }
      );
    },

    async getSubId() {
      const id = this.$route.params.sub_id;
      await getEntityItem("entity", "/entity", this.$route.params.id);
      const Entity = this.entity?.data?.business?.find(
        (e) => Number(e.id) === Number(id)
      );
      if (Entity) {
        this.edit = true;
        this.industry = Number(Entity?.industry?.id);
        this.main_group = Number(Entity?.main_group?.id);
        this.sub_group = Number(Entity?.sub_group?.id);
        this.activity = Number(Entity?.activity?.id);
        this.activity_code = Entity?.activity_code;
      }
    },
    setMessage(message) {
      this.message = message;
    },
  },
};
</script>

<style scoped>
.save-next {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #fafbff;
  width: 196px;
  background: #0f75bc;
  height: 55px;
}

.cancel {
  background: #ffffff;
  border: 1px solid #747171;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #747171;
  height: 55px;
  width: 196px;
}

.radio-container {
  height: 56px;
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  gap: 11px;
}

.yes {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}

.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

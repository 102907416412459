<template>
  <div
    v-if="!business.loading && !business.error && business.data?.length"
    class="cont w-100 d-flex align-items-center"
  >
    <div
      v-if="business.data.length > 1"
      class="wrapper py-3 d-flex mr-3 align-items-center"
      @click="pushToOtherBusiness('master')"
    >
      <h4
        :class="`tab-title ${
          $route.query.current_business === 'master' ? 'active' : ''
        }  mb-0`"
      >
        Group Data
      </h4>
      <img
        v-if="tab === 'master'"
        src="@/assets/svg/chevron-right-bold.svg"
        alt=""
      />
      <img v-else src="@/assets/svg/chevron-right-light.svg" alt="" />
    </div>
    <form
      v-for="(item, index) in business.data"
      :key="index"
      @submit="(e) => handleSubmit(e, item.id)"
      class="wrapper py-3 d-flex mr-3 align-items-center"
      @click="pushToOtherBusiness(item.id)"
    >
      <h4
        v-if="!item.showInput"
        :class="`tab-title ${
          item.id == $route.query.current_business ? 'active' : ''
        }  mb-0`"
        v-on:dblclick="openCloseInput(item.id, true, item.name)"
      >
        {{ item.name }}
      </h4>
      <input
        v-model="name"
        type="text"
        class="bus-input w-75"
        :hidden="!item.showInput"
        @focus="setName(item.name)"
        @blur="openCloseInput(item.id, false)"
      />
      <img
        v-if="item.id == $route.query.current_business"
        src="@/assets/svg/chevron-right-bold.svg"
        alt=""
      />
      <img v-else src="@/assets/svg/chevron-right-light.svg" alt="" />
    </form>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useComputeIncomeTaxStore } from "../../../store/incometax.store";
import { useBusinessStore } from "../../../store/buisness.store";
import { useChapter8Store } from "../../../store/chapter8";
import { useChapter10Store } from "../../../store/chapter10";
import { useChapter13Store } from "../../../store/chapter13";
import { useChapter11Store } from "../../../store/chapter-11";
import { useOtherAdjustmentStore } from "../../../store/other-adjustment";
const props = defineProps({
  Tab: String,
  dontPush: Boolean,
});
const businessStore = useBusinessStore();
const chapter8Store = useChapter8Store();
const chapter10Store = useChapter10Store();
const chapter13Store = useChapter13Store();
const chapter11Store = useChapter11Store();
const computeIncomeTaxStore = useComputeIncomeTaxStore();
const otherAdjustments = useOtherAdjustmentStore();
const { business } = storeToRefs(businessStore);
const route = useRoute();

const name = ref("");
const message = ref("");
const router = useRouter();
watch(
  () => route.query,
  async (newValue, oldValue) => {
    if (newValue.current_business !== oldValue.current_business) {
      await getData(newValue.current_business);
    }
  }
);

const id = route.params.id;
const getData = async (business_id) => {
  if (props.Tab === "chapter-7") {
    await computeIncomeTaxStore.getchapter7(
      route.params.id,
      route.query.financial_year,
      route.query.financial_period,
      business_id
    );
    return;
  }
  if (props.Tab === "chapter-8") {
    await chapter8Store.getChapter8(route);
    return;
  }
  if (props.Tab === "chapter-9") {
    await computeIncomeTaxStore.getComputeIncomeTax(
      route.params.id,
      route.query.financial_year,
      route.query.financial_period,
      route.query.current_business
    );
    await computeIncomeTaxStore.getchapter9(
      route.params.id,
      route.query.financial_year,
      route.query.financial_period,
      business_id
    );
    return;
  }
  if (props.Tab === "chapter-10") {
    await chapter10Store.getChapter10(route);
    return;
  }
  if (props.Tab === "chapter-13") {
    await chapter13Store.getChapter13(route);
    return;
  }
  if (props.Tab === "chapter-11") {
    await chapter11Store.getChapter11(route);
  }
  if (props.Tab === "other-adjustments") {
    await otherAdjustments.getOtherAdjustments(route);
    return;
  }
};

onMounted(async () => {
  await businessStore.getBusiness(
    id,
    route.query.financial_year,
    route.query.financial_period
  );
});

const openCloseInput = async (id, value, Name, update = true) => {
  business.value.data = business.value.data.map((b) => {
    if (b.id === id) {
      return { ...b, showInput: value };
    }
    return b;
  });
  if (value) name.value = Name;
  else if (!value && update) {
    await updateBusiness(id);
  }
};
const pushToOtherBusiness = (id) => {
  if (props.dontPush) {
    return;
  }
  router.push(
    `/entity/computation-tax/${props.Tab}/${route.params.id}?financial_year=${route.query.financial_year}&financial_period=${route.query.financial_period}&current_business=${id}`
  );
};
const updateBusiness = async (id) => {
  const findBusiness = business.value.data.find((b) => b.id == id);
  if (findBusiness.name === name.value) return;
  await businessStore.updateBusiness(
    {
      entity_business_id: id,
      entity_id: route.params.id,
      financial_year: route.query.financial_year,
      financial_period: route.query.financial_period,
      name: name.value,
    },
    (mess) => {
      message.value = mess;
    },
    async () => {
      await businessStore.getBusiness(
        route.params.id,
        route.query.financial_year,
        route.query.financial_period
      );
    }
  );
};
const handleSubmit = async (e, id) => {
  e.preventDefault();
  openCloseInput(id, false, name.value, false);
};
const setName = async (Name) => {
  name.value = Name;
};
</script>

<style scoped>
.wrapper {
  gap: 10px;
  cursor: pointer;
  min-width: 155px !important;
}

.cont {
  overflow-x: auto;
}

.tab-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /*
identical to box height */
  letter-spacing: 0.055em;
  color: #747171;
}

.tab-title.active {
  font-weight: 600;
  color: #000000;
}

.bus-input {
  border: none;
  border-bottom: 1px solid #000000;
}

.bus-input:focus {
  outline: 0;
  border-bottom: 1px solid #747171;
}
</style>

<template>
  <div class="w-100">
    <AlertModal
      :open="deletModal.modal"
      message="Warning: Data will be deleted."
      sub-message="Are you sure you want to proceed?"
      :on-confirm="removeRow"
      :on-cancel="() => openDeleteModal(false)"
      button-name="Delete"
    />
    <div v-if="openInputs.length" class="w-100">
      <div
        v-for="(item, index) in data[dataKey].data"
        :key="item.id"
        :class="`w-100 table-body  py-2 ${sub ? 'sub' : ''} ${
          !index || !(index % 2) ? 'white' : 'gray'
        } d-flex align-items-center justify-content-between`"
      >
        <p
          v-if="!openInputs[index].particular && item.particular"
          class="mb-0 body-title cursor-pointer"
          @click="openCloseInput('particular', true, index, item.id)"
        >
          {{ item.particular }}
        </p>
        <p
          v-if="!openInputs[index].particular && !item.particular"
          class="mb-0 body-title text-danger cursor-pointer"
          @click="openCloseInput('particular', true, index, item.id)"
        >
          Please enter name
        </p>
        <input
          :id="`input-particular-${item.id}`"
          v-model="data[dataKey].data[index].particular"
          :hidden="!openInputs[index].particular"
          type="text"
          class="clickable-text left"
          @blur="openCloseInput('particular', false, index, item.id)"
        />
        <div class="d-flex align-items-center">
          <p
            v-if="!openInputs[index].amount"
            class="body-title cursor-pointer mb-0 mr-3"
            @click="openCloseInput('amount', true, index, item.id)"
          >
            {{ NumberFormatter(item.amount) }}
          </p>
          <input
            :id="`input-amount-${item.id}`"
            v-model="data[dataKey].data[index].amount"
            :hidden="!openInputs[index].amount"
            type="number"
            class="clickable-text w-100"
            @blur="openCloseInput('amount', false, index, item.id)"
            @keypress="validateAmount"
          />
          <button
            v-if="!openInputs[index].amount"
            class="btn btn-outline-primary delete-btn"
            :disabled="status.value || $route.query.business === 'master'"
            @click="() => openDeleteModal(true, item.id)"
          >
            <img src="@/assets/svg/trash.svg" class="cursor-pointer" />
          </button>
        </div>
      </div>
    </div>
    <button
      class="add-btn btn-add mt-2 mb-2 btn btn-outline-primary"
      :disabled="status.value || $route.query.business === 'master'"
      @click="addNewRow"
    >
      {{ buttonName }}
    </button>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";
import { useRoute } from "vue-router";
import { useStatusStore } from "../../../../../store/statusStore";
import { storeToRefs } from "pinia";
import { generate } from "randomized-string";
import { AlertModal } from "../../../../common";
import { useNoneIFRSProfitLoss } from "../../../../../store/NonIFRS/profitLoss.store";
import { validateAmount } from "../../../../../helpers/validation";

const props = defineProps({
  dataKey: {
    type: String,
    default: "",
  },
  buttonName: {
    type: String,
    default: "Add",
  },
});
const nonIFRSPlStore = useNoneIFRSProfitLoss();
const { data } = storeToRefs(nonIFRSPlStore);
const openInputs = ref([]);
const route = useRoute();
const statusStore = useStatusStore();
const { status } = storeToRefs(statusStore);

const deletModal = ref({
  modal: false,
  id: "",
});

const openDeleteModal = (modal, id) => {
  if (id) {
    deletModal.value.id = id;
  }
  deletModal.value.modal = modal;
};
const openCloseInput = async (key, value, index, id) => {
  if (status.value || route.query.business === "master") {
    return;
  }
  if (window.location.pathname.includes("view")) {
    return;
  }
  openInputs.value[index][key] = await value;
  const inputField = await document.getElementById(`input-${key}-${id}`);
  if (inputField) {
    await inputField.focus();
  }
};

watch(
  () => data.value[props.dataKey].data,
  (newVal) => {
    data.value[props.dataKey].total = newVal.reduce((a, b) => {
      return a + Number(b.amount || 0);
    }, 0);
  },
  { deep: true }
);

const addNewRow = async () => {
  if (status.value || route.query.business === "master") {
    return;
  }
  if (window.location.pathname.includes("view")) {
    return;
  }
  const id = generate({ charset: "number", length: 12 });

  data.value[props.dataKey].data = [
    ...data.value[props.dataKey].data,
    {
      id,
      particular: "",
      amount: "",
    },
  ];
  openInputs.value = [
    ...openInputs.value,
    { id, particular: false, amount: false },
  ];
  openCloseInput(
    "particular",
    true,
    data.value[props.dataKey].data.length - 1,
    id
  );
};
const removeRow = async () => {
  if (status.value || route.query.business === "master") {
    return;
  }
  if (window.location.pathname.includes("view")) {
    return;
  }
  openCloseInput.value = await openInputs.value.filter(
    (item) => item.id !== deletModal.value.id
  );
  data.value[props.dataKey].data = await data.value[props.dataKey].data.filter(
    (item) => item.id !== deletModal.value.id
  );
};
onMounted(() => {
  let Data = data.value[props.dataKey].data;
  if (Data?.length) {
    for (let i = 0; i < Data.length; i++) {
      openInputs.value[i] = {
        id: Data[i]?.id,
        particular: false,
        amount: false,
      };
    }
  }
});
</script>

<style scoped>
.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
  border-bottom: 1px solid rgba(116, 113, 113, 0.3);
}
.table-body.sub {
  padding-left: 46px !important;
}
.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}
.body-title.left {
  text-align: left;
}
.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}
.add-btn:disabled {
  opacity: 0.5;
}
.btn-add {
  width: unset;
  min-width: 150px !important;
}
.clickable-text.left {
  text-align: left !important;
}
.delete-btn {
  width: unset !important;
  margin-right: -52px !important;
}

@media screen and (max-width: 600px) {
  .table-body {
    padding-right: 54px !important;
  }
}
</style>

<template>
  <SideNavContainer side-nav-type="financialData">
    <EntityChecker>
      <div
        class="title-container d-flex align-items-center justify-content-between"
      >
        <h3 class="title">
          Auditor Details <br />
          <p class="subtitle mb-0">
            {{ name }}
          </p>
        </h3>
        <FinancialYear />
      </div>
      <LoaderContainer :loading="loading" :error="error">
        <AuditorDetailsBreadCrumb />
        <AuditorDetailsForm />
      </LoaderContainer>
    </EntityChecker>
  </SideNavContainer>
</template>

<script setup>
import { onMounted, watch, computed } from "vue";
import { AuditorDetailsBreadCrumb } from "../../BreadCrumbs";
import { LoaderContainer } from "../../common";
import { SideNavContainer } from "../../layout";
import AuditorDetailsForm from "./AuditorDetailsForm.vue";
import FinancialYear from "./FinancialYear.vue";
import { useGeneralInfoStore } from "../../../store/generalInformation";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import EntityChecker from "../../entity/AddEntity/EntityChecker.vue";
import { useEntityStore } from "../../../store/entity";
const generalInfoStore = useGeneralInfoStore();
const { loading, error, data } = storeToRefs(generalInfoStore);
const route = useRoute();
const name = computed(() => {
  if (!entity.value.data?.entity) {
    return "";
  }
  const Entity = entity.value.data?.entity[0];
  if (Entity?.entity_type == 2) {
    return `${Entity?.entity_first_name} ${Entity?.entity_last_name}`;
  }
  return Entity?.entity_name;
});
const entityStore = useEntityStore();
const { entity } = storeToRefs(entityStore);

watch(
  () => route.query,
  async (newVal) => {
    if (!data.value) {
      await generalInfoStore.getGeneralInformation(
        route.params.id,
        newVal.financial_year
      );
    }
  },
  { deep: true }
);
watch(
  () => route.params.id,
  async (newVal) => {
    if (route.query.financial_year) {
      if (!data.value) {
        await generalInfoStore.getGeneralInformation(
          newVal,
          route.query.financial_year
        );
      }
    }
  },
  { deep: true }
);
onMounted(async () => {
  if (route.query.financial_year) {
    if (!data.value) {
      await generalInfoStore.getGeneralInformation(
        route.params.id,
        route.query.financial_year
      );
    }
  }
});
</script>

<style scoped>
.title-container {
  padding: 25px 10px 16px 10px;
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.055em;
  color: #000000;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

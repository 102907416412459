<template>
  <SideNavContainer title="Entity">
    <EntityChecker>
      <AddEntityTab active="bankingDetails" />
      <UploadContainer
        name="bank"
        :open="open"
        type="bank_details"
        :setOpen="setModal"
        :callBack="reload"
      />
      <div class="col-lg-12 mt-4">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <h4 class="title">Banking Details</h4>
          <div class="d-flex align-items-center">
            <div
              class="d-flex gray-button align-items-center cursor-pointer mr-2"
              @click="
                $router.push(
                  `/entity/add-entity/banking-details/${$route.params.id}`
                )
              "
            >
              <img
                src="@/assets/svg/plus-square-light.svg"
                alt=""
                class="mr-2"
              />
              <p class="edit-text mb-0">Add bank</p>
            </div>
            <div
              class="mr-2 gray-button cursor-pointer edit-text"
              @click="() => setModal(true)"
            >
              &#128206; Upload Bank Details
            </div>
          </div>
        </div>
        <BankingDetails />
      </div>
    </EntityChecker>
  </SideNavContainer>
</template>

<script setup>
import { SideNavContainer } from "../../../../layout";
import { AddEntityTab } from "../../../../common";
import BankingDetails from "../../../MasterData/List/BankingDetails.vue";
import EntityChecker from "../../EntityChecker.vue";
import UploadContainer from "../../uploadEntity/UploadContainer.vue";
import { ref } from "vue";
const reload = () => {
  window.location.reload();
};
const open = ref(false);

const setModal = (value) => {
  open.value = value;
};
</script>

<style scoped>
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.edit-text {
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #000000;
}
.gray-button {
  background-color: #deeded;
  padding: 10px;
}
</style>

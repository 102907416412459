<template>
  <div class="w-100">
    <div
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 total-amount">EBIDTA</p>
      <p class="mb-0 total-amount">
        {{ NumberFormatter(Ebidta(grossProfit($route))) }}
      </p>
    </div>
    <CustomClickableInput
      v-model="less_depreciation_amortisation_expense"
      :make-text-bold="true"
      :on-click="false"
      name="Less- Depreciation & Amortisation Expense"
      model="less_depreciation_amortisation_expense"
      bg-color="gray"
    />
    <div
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 total-amount">EBIT</p>
      <p class="mb-0 total-amount">
        {{ NumberFormatter(ebit(grossProfit($route))) }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useExpenditureStore } from "../../../../../store/expenditure";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";
import { useProfitAndLossStore } from "../../../../../store/ProfitAdLoss";
import { CustomClickableInput } from "../../../../common";

const expenditureStore = useExpenditureStore();
const profitLossStore = useProfitAndLossStore();
const { grossProfit } = storeToRefs(profitLossStore);
const { Ebidta, less_depreciation_amortisation_expense, ebit } =
  storeToRefs(expenditureStore);
</script>

<style scoped>
.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 26px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}
</style>

<template>
  <div class="row">
    <div class="col-lg-4 col-md-6 mt-4">
      <CustomTextInput
        v-model="general_information.entity_name"
        type="text"
        label="Entity Name"
        placeholder="Entity Name"
        disabled
      />
    </div>
    <div class="col-lg-4 col-md-6 mt-4">
      <CustomTextInput
        v-model="general_information.type"
        type="text"
        label="Type"
        placeholder="Type"
        disabled
      />
    </div>
    <div class="col-lg-4 col-md-6 mt-4">
      <CustomTextInput
        v-model="general_information.license_authority_type"
        type="text"
        label="License Authority Type"
        placeholder="License Authority Type"
        disabled
      />
    </div>
    <div class="col-lg-4 col-md-6 mt-4">
      <CustomTextInput
        v-model="general_information.date_of_ct_registration"
        type="date"
        label="Date of CT Registration"
        placeholder="Date of CT Registration"
        disabled
      />
    </div>
    <div class="col-lg-4 col-md-6 mt-4">
      <CustomSelectInput
        v-model="general_information.financial_period"
        :options="financialYear.data"
        item-title="name"
        item-value="id"
        label="Financial Period"
        placeholder="select period"
        :otherProps="{ disabled: true }"
      />
    </div>
    <div class="col-lg-4 col-md-6 mt-4">
      <CustomTextInput
        v-model="general_information.trn_number"
        type="text"
        label="TRN Number"
        placeholder="TRN Number"
        disabled
      />
    </div>
  </div>
</template>

<script setup>
import CustomTextInput from "../../common/CustomTextInput.vue";
import { CustomSelectInput } from "../../common";
import { useGeneralInfoStore } from "../../../store/generalInformation";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";
import { useEntityStore } from "../../../store/entity";
import { useRoute } from "vue-router";
import { getEntityItems } from "../../../store/entityActions/get";
const generalInfoStore = useGeneralInfoStore();
const { general_information } = storeToRefs(generalInfoStore);
const entityStore = useEntityStore();
const { financialYear } = storeToRefs(entityStore);
const route = useRoute();
onMounted(async () => {
  if (!financialYear.value.data.length) {
    await getEntityItems("financialYear", "/financialYear");
  }
});
</script>

<style lang="scss" scoped></style>

<template>
  <SideNavContainer>
    <LoaderContainer :loading="profile.loading" :error="profile.loading">
      <BusinessOwnerDashboard v-if="profile.data?.type === 'Business Owner'" />
      <ConsultantDashboard v-else />
    </LoaderContainer>
  </SideNavContainer>
</template>

<script setup>
import { storeToRefs } from "pinia";
import {
  BusinessOwnerDashboard,
  ConsultantDashboard
} from "../components/dashboard";
import { useProfileStore } from "../store/profile";
import LoaderContainer from "../components/common/LoaderContainer.vue";
import { SideNavContainer } from "../components/layout";

const profileStore = useProfileStore();
const { profile } = storeToRefs(profileStore);
</script>

<style scoped></style>

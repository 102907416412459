<template>
  <SideNavContainer title="Add Entity">
    <Loader :loading="loading">
      <AddEntityTab active="ownershipDetails" />
      <Progress :active="3" />
      <StatusChecker>
        <form @submit="handleSubmit">
          <div class="row">
            <div class="col-lg-4 col-md-6 mt-4">
              <CustomSelectInput
                v-model="entity_type"
                label="Shareholder Type *"
                placeholder="Select Entity Type"
                itemTitle="name"
                itemValue="id"
                :options="type.data"
                :error-message="error.entity_type"
              />
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <CustomTextInput
                v-model="name"
                placeholder="Shareholder Name"
                label="Shareholder Name *"
                :error-message="error.name"
                @keypress="onlyAcceptAlphabets"
              />
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <h4 class="label">Resident of UAE *</h4>
              <div class="radio-container w-100 d-flex align-items-center pl-2">
                <input
                  type="radio"
                  value="Y"
                  id=""
                  :error-message="error.is_from_uae"
                  v-model="is_from_uae"
                  class=""
                />
                <p class="mb-0 yes">Yes</p>
                <input
                  type="radio"
                  value="N"
                  name=""
                  v-model="is_from_uae"
                  id=""
                  class=""
                  :error-message="error.is_from_uae"
                />
                <p class="mb-0 yes">No</p>
              </div>
              <p v-if="error.is_from_uae" class="text-danger">
                {{ error.is_from_uae }}
              </p>
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <div class="w-100">
                <h4 class="label">Emirates ID Number *</h4>
                <input
                  v-model="uae_id"
                  type="text"
                  class="custom-input w-100"
                  placeholder="Emirates ID Number"
                  @keypress="checkEmirateId($event, true)"
                />
                <p v-if="error.uae_id" class="text-danger">
                  {{ error.uae_id }}
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <h4 class="label">
                Nationality <span class="text-danger">*</span>
              </h4>
              <v-autocomplete
                v-model="nationality"
                :items="countries.data"
                label="Nationality"
                item-title="name"
                :error-message="error.nationality"
                item-value="id"
                bg-color="#DEEDED"
              >
              </v-autocomplete>
              <p v-if="error.nationality" class="text-danger">
                {{ error.nationality }}
              </p>
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <CustomTextInput
                v-model="passport_no"
                type="text"
                label="Passport Number *"
                placeholder="Passport Number"
                :error-message="error.passport_no"
                @keypress="onlyAcceptAlphaNumeric"
              />
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <CustomTextInput
                v-model="mobile_no"
                label="Mobile Number with Country Code *"
                :error-message="error.mobile_no"
                @keypress="checkMobileNumber($event)"
              />
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <CustomSelectInput
                v-model="gender"
                label="Gender"
                placeholder="Select Gender"
                item-title="name"
                item-value="name"
                :options="Gender"
              />
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <CustomTextInput
                v-model="email"
                type="email"
                placeholder="Email Address"
                label="Email"
                :error-message="error.email"
              />
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <CustomDateInput
                v-model="passport_issue_date"
                type="number"
                :error-message="error.passport_issue_date"
                label="Passport Issue Date *"
                placeholder="Select Date"
                :max="dateNow()"
              />
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <CustomDateInput
                v-model="passport_expiry_date"
                type="number"
                label="Passport Expiry Date *"
                :error-message="error.passport_expiry_date"
                placeholder="Select Date"
                :min="dateNow()"
              />
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <CustomTextInput
                v-model="share_percent"
                type="number"
                label="Share% *"
                :error-message="error.share_percent"
                placeholder="Share"
              />
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <CustomDateInput
                v-model="registration_date"
                type="number"
                label="Registration Date *"
                :error-message="error.registration_date"
                placeholder="Select Date"
                :max="dateNow()"
              />
            </div>

            <!-- <div class="col-lg-4 col-md-6 mt-4">
              <CustomTextInput
                v-model="address"
                placeholder="Address"
                label="Address"
              />
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <CustomSelectInput
                label="Designation"
                :options="designation.data"
                placeholder="Designation"
                v-model="Designation"
                itemTitle="name"
                itemValue="id"
              />
            </div> -->
            <!-- <div class="col-lg-4 col-md-6 mt-4">
                <CustomSelectInput
                v-model="change_in_holding"
                label="Change in Holding" placeholder="Change Holding" />
            </div> -->
            <p class="text-info my-2">{{ message }}</p>
            <div class="col-lg-12">
              <div class="w-100 d-flex align-items-center mt-10 mb-3">
                <button class="save-next mr-3" type="submit">
                  Save & Next
                </button>
                <button
                  class="cancel"
                  type="button"
                  @click="
                    $router.push(`/entity/master-data/${$route.params.id}`)
                  "
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </StatusChecker>
    </Loader>
  </SideNavContainer>
</template>

<script>
import CustomSelectInput from "@/components/common/CustomSelectInput.vue";
import { useCountryStore } from "@/store/country";
import { storeToRefs } from "pinia";
import { CustomTextInput, CustomDateInput } from "../../../../common";
import { useEntityStore } from "../../../../../store/entity";
import {
  getEntityItems,
  getEntityItem,
} from "../../../../../store/entityActions/get";
import { addEntity } from "@/store/entityActions/post";
import { updateEntity } from "@/store/entityActions/put";
import Loader from "@/components/common/LoaderContainer.vue";
import Progress from "./Progress.vue";
import { AddEntityTab } from "../../../../common";
import { SideNavContainer } from "../../../../layout";
import {
  onlyAcceptAlphaNumeric,
  onlyAcceptAlphabets,
} from "../../../../../helpers/validation";
import StatusChecker from "../../StatusChecker.vue";
import { dateNow } from "../../../../../helpers/date";
import { emailRegex } from "../../../../../constants/regex-format";

export default {
  name: "ShareHolder",
  components: {
    CustomSelectInput,
    CustomTextInput,
    CustomDateInput,
    Loader,
    Progress,
    SideNavContainer,
    AddEntityTab,
    StatusChecker,
  },
  setup() {
    const countryStore = useCountryStore();
    const { countries } = storeToRefs(countryStore);
    const entityStore = useEntityStore();
    const { designation, type, ShareholderDetails, ownershipDetails } =
      storeToRefs(entityStore);
    return {
      countries,
      designation,
      type,
      entityStore,
      ShareholderDetails,
      countryStore,
      ownershipDetails,
      onlyAcceptAlphaNumeric,
      onlyAcceptAlphabets,
      dateNow,
    };
  },
  data() {
    return {
      entity_id: 1,
      entity_type: "",
      owner_ship_id: "",
      Gender: [
        { name: "Male", value: "M" },
        { name: "Female", value: "F" },
        { name: "Others", value: "Others" },
      ],
      name: "",
      is_from_uae: "",
      mobile_no: "",
      email: "",
      gender: "",
      address: "",
      appointment_date: "",
      cessation_date: "",
      nationality: "",
      Designation: "",
      shareholder_passport_no: "",
      passport_issue_date: "",
      passport_expiry_date: "",
      share_percent: "",
      registration_date: "",
      change_in_holding: "",
      message: "",
      edit: false,
      loading: true,
      uae_id: "",
      passport_no: "",
      error: {
        entity_type: "",
        name: "",
        is_from_uae: "",
        nationality: "",
        passport_no: false,
        mobile_no: false,
        passport_issue_date: "",
        passport_expiry_date: "",
        share_percent: "",
        registration_date: "",
        uae_id: "",
        email: "",
      },
    };
  },
  watch: {
    // passport_no(val) {
    //   if (val.length > 12) {
    //     this.error.passport_no =
    //       "Passport number should be less than 12 characters";
    //   } else {
    //     this.error.passport_no = false;
    //   }
    // },

    entity_type() {
      this.error.entity_type = "";
    },
    name() {
      this.error.name = "";
    },
    is_from_uae() {
      this.error.is_from_uae = "";
    },
    nationality() {
      this.error.nationality = "";
    },
    mobile_no() {
      this.error.mobile_no = false;
    },
    passport_no() {
      this.error.passport_no = false;
    },
    share_percent() {
      this.error.share_percent = "";
    },
    registration_date() {
      this.error.registration_date = "";
    },
    passport_issue_date() {
      this.error.passport_issue_date = "";
    },
    passport_expiry_date() {
      this.error.passport_expiry_date = "";
    },
    uae_id() {
      this.error.uae_id = "";
    },
  },
  async created() {
    const id = this.$route.params.sub_id;
    let owner_id = this.$route.query.owner_id;

    if (!this.designation.data.length) {
      await getEntityItems("designation", "/designation");
    }
    if (!this.type.data.length) {
      await getEntityItems("type", "/entityType");
    }
    if (!this.countries.data.length) {
      await this.countryStore.getCountryList();
    }
    if (id) {
      await this.getSubId();
    } else if (owner_id) {
      this.owner_ship_id = owner_id;
    }
    this.loading = false;
  },
  methods: {
    checkEmirateId(e) {
      let reg = /^[0-9 -]+$/;
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (reg.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    checkMobileNumber(e, containMinus = false) {
      let reg = containMinus ? /^[0-9 +-]+$/ : /^[0-9+]+$/;
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (reg.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    async handleSubmit(e) {
      e.preventDefault();
      if (!this.entity_type) {
        this.error.entity_type = "Field is required";
        return;
      }
      if (!this.name) {
        this.error.name = "Field is required";
        return;
      }
      if (!this.is_from_uae) {
        this.error.is_from_uae = "Field is required";
        return;
      }
      if (!this.uae_id) {
        this.error.uae_id = "Field is required";
      }
      if (!this.nationality) {
        this.error.nationality = "Field is required";
        return;
      }
      if (!this.passport_no) {
        this.error.passport_no = "Field is required";
        return;
      }
      if (!this.mobile_no) {
        this.error.mobile_no = "Field is required";
        return;
      }
      if (!this.passport_issue_date) {
        this.error.passport_issue_date = "Field is required";
        return;
      }
      if (!this.passport_expiry_date) {
        this.error.passport_expiry_date = "Field is required";
        return;
      }
      if (!this.share_percent) {
        this.error.share_percent = "Field is required";
        return;
      }
      if (!this.registration_date) {
        this.error.registration_date = "Field is required";
        return;
      }
      if (this.email && !emailRegex.test(this.email)) {
        this.error.email = "Invalid Email";
        return;
      }
      const sub_id = this.$route.params.sub_id;
      const readyData = {
        entity_id: this.$route.params.id,
        entity_type: this.entity_type,
        owner_ship_id: this.owner_ship_id,
        name: this.name,
        mobile_no: this.mobile_no,
        email: this.email,
        gender: this.gender,
        address: this.address,
        uae_id: this.uae_id,
        nationality: this.nationality,
        designation: this.Designation,
        passport_no: this.passport_no,
        passport_expiry_date: this.passport_expiry_date,
        share_percent: this.share_percent,
        registration_date: this.registration_date,
        change_in_holding: "",
        appointment_date: this.appointment_date,
        passport_issue_date: this.passport_issue_date,
        is_from_uae: this.is_from_uae,
      };
      // if (String(this.passport_no).length > 12) {
      //   this.error.passport_no = "Passport must be less than 12 characters";
      //   return;
      // }
      if (
        !(
          String(this.mobile_no).length <= 13 &&
          String(this.mobile_no).length >= 8
        ) &&
        !!this.mobile_no
      ) {
        this.error.mobile_no =
          "Invalid Mobile Number, Mobile number should be between 8 to 13 digits ";
        return;
      }
      if (sub_id) {
        await updateEntity(
          "/entityShareholderDetails",
          { entity_shareholder_id: sub_id, ...readyData },
          this.setMessage,
          () => this.pushToShareHolders()
        );
      } else if (this.$route.query.owner_id) {
        await addEntity(
          "/entityShareholderDetails",
          readyData,
          this.setMessage,
          ({ entity_id: entityId }) => {
            setTimeout(() => {
              this.$router.push(
                "/entity/add-entity/authorized-signatory-details/" + entityId
              );
            }, 2000);
          }
        );
      }
    },
    setMessage(message) {
      this.message = message;
    },
    async getSubId() {
      const id = this.$route.params.sub_id;
      await getEntityItem(
        "ShareholderDetails",
        "/entityShareholderDetails",
        this.$route.params.id
      );
      const ShareholderDetails = this.ShareholderDetails?.data?.find(
        (e) => Number(e.id) === Number(id)
      );
      if (ShareholderDetails) {
        await getEntityItem("ownershipDetails", "/entityOwnerDetails", id);
        this.edit = true;
        this.owner_ship_id = this.$route.query.owner_id
          ? this.$route.query.owner_id
          : this.ownershipDetails?.data[0]?.id
          ? this.ownershipDetails?.data[0]?.id
          : ShareholderDetails?.owner_ship_id;
        this.name = ShareholderDetails?.name;
        this.uae_id = ShareholderDetails?.uae_id;
        this.entity_type = ShareholderDetails?.entity_type;
        this.mobile_no = ShareholderDetails?.mobile_no;
        this.email = ShareholderDetails?.email;
        this.gender = ShareholderDetails?.gender;
        this.address = ShareholderDetails?.address;
        this.appointment_date = ShareholderDetails?.appointment_date;
        this.cessation_date = ShareholderDetails?.cessation_date;
        this.nationality = ShareholderDetails?.nationality;
        this.Designation = ShareholderDetails?.designation;
        this.shareholder_passport_no =
          ShareholderDetails?.shareholder_passport_no;
        this.shareholder_passport_expiry_date =
          ShareholderDetails?.shareholder_passport_expiry_date;
        this.share_percent = ShareholderDetails?.share_percent;
        this.registration_date = ShareholderDetails?.registration_date;
        this.change_in_holding = ShareholderDetails?.change_in_holding;
        this.passport_no = ShareholderDetails?.passport_no;
        this.appointment_date = ShareholderDetails?.appointment_date;
        this.passport_issue_date = ShareholderDetails?.passport_issue_date;
        this.passport_expiry_date = ShareholderDetails?.passport_expiry_date;
        this.is_from_uae = ShareholderDetails?.is_from_uae;
        if (this.owner_ship_id) {
          this.$router.push(
            `/entity/add-entity/shareholder-details/${this.$route.params.id}/${id}?owner_id=${this.owner_ship_id}`
          );
        }
      }
    },
    async pushToShareHolders() {
      await getEntityItem(
        "ShareholderDetails",
        "/entityShareholderDetails",
        this.$route.params.id
      );
      if (this.ShareholderDetails.data.length > 1) {
        this.$router.push(`/entity/ownership-details/${this.$route.params.id}`);
      } else {
        this.$router.push(
          "/entity/add-entity/authorized-signatory-details/" +
            this.$route.params.id
        );
      }
    },
  },
};
</script>

<style scoped>
.save-next {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #fafbff;
  width: 196px;
  background: #0f75bc;
  height: 55px;
}
.cancel {
  background: #ffffff;
  border: 1px solid #747171;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #747171;
  height: 55px;
  width: 196px;
}
.custom-input {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  height: 48px;
  padding: 13px 10px;
}
.custom-input.error {
  border: 1px solid red;
}
.custom-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
  text-align: start;
}
.custom-input:focus {
  outline: 0;
}
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.radio-container {
  height: 56px;
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  gap: 11px;
}
</style>

<template>
  <v-dialog v-model="toggle" width="auto">
    <div class="card-container">
      <div class="d-flex w-100 justify-content-end">
        <img
          src="@/assets/svg/close-circle.svg"
          class="cursor-pointer"
          alt=""
          @click="closeModal()"
        />
      </div>
      <form class="w-100 mt-5" @submit="handleSubmit">
        <AccountInput
          v-model="email"
          label="Please provide your email we will send you a reset link."
          placeholder="Enter your email"
          type="email"
          :error-message="error"
          :style="{
            'padding-bottom': '1px !important',
            'padding-left': '12px',
            'margin-top': '10px',
          }"
        />
        <p
          v-if="statusMessage.message"
          :class="`my-2 text-center text-${statusMessage.type}`"
        >
          {{ statusMessage.message }}
        </p>
        <div class="w-100 d-flex align-items-center justify-content-center">
          <button
            :class="`btn-log-in p-3 mt-10 ${!email ? 'disabled' : ''}`"
            type="submit"
            :disabled="!email"
          >
            Reset password
          </button>
        </div>
      </form>
    </div>
  </v-dialog>
</template>

<script setup>
import { ref, watch } from "vue";
import AccountInput from "../common/AccountInput.vue";
import { useProfileStore } from "../../store/profile";
import { storeToRefs } from "pinia";
const email = ref("");
const error = ref("");
const props = defineProps({
  selectedDate: {
    type: String,
    required: true,
  },
  open: {
    type: Boolean,
    required: true,
  },
  setOpen: {
    type: Function,
    required: true,
  },
});
const profileStore = useProfileStore();
const toggle = ref(props.open);
const statusMessage = ref({
  message: "",
  type: "info",
});
const closeModal = () => {
  toggle.value = false;
  props.setOpen(false, "entityModal");
};
watch(
  () => props.open,
  (newValue) => {
    toggle.value = newValue;
  }
);
watch(toggle, (newValue) => {
  props.setOpen(newValue);
});
const handleSubmit = async (e) => {
  e.preventDefault();
  if (!email.value) {
    return (error.value = "Please provide an email");
  }
  if (!/^[^@]+@\w+(\.\w+)+\w$/.test(email.value)) {
    error.value = "Invalid Email";
    return;
  }
  await profileStore.sendResetLink(email.value, (message, type) => {
    statusMessage.value.message = message;
    statusMessage.value.type = type;
  });
};
watch(
  email,
  () => {
    error.value = "";
  },
  { deep: true }
);
</script>

<style scoped>
.card-container {
  background: #fafbff;
  border-radius: 5px;
  padding: 33px 27px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.btn-log-in {
  height: 55px;
  background: #0f75bc;
  border-radius: 4px;
  color: #fafbff;
}
.btn-log-in.disabled {
  opacity: 0.5;
}
</style>

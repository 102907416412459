import { useRoute } from "vue-router";

export const nonIFRSDataStructure = {
  data: [],
  total: "",
  last_input: "input",
  value: "",
};

export const nonIFRSBalanceSheet = {
  fixed_assets: nonIFRSDataStructure,
  tangible_assets: nonIFRSDataStructure,
  intangible_assets: nonIFRSDataStructure,
  capital_work_in_progress: nonIFRSDataStructure,
  intangible_assets_under_development: nonIFRSDataStructure,
  non_current_investments: nonIFRSDataStructure,
  deferred_tax_assets_net: nonIFRSDataStructure,
  long_term_loan_and_advance: nonIFRSDataStructure,
  other_non_current_assets: nonIFRSDataStructure,
  total_non_current_assets: nonIFRSDataStructure,
  current_investment: nonIFRSDataStructure,
  inventories: nonIFRSDataStructure,
  trade_receivables: nonIFRSDataStructure,
  cash_and_bank_balance: nonIFRSDataStructure,
  short_term_loan_and_advance: nonIFRSDataStructure,
  other_current_assets: nonIFRSDataStructure,
  total_current_assets: nonIFRSDataStructure,
  total_assets: nonIFRSDataStructure,
  share_capital: nonIFRSDataStructure,
  reserves_and_surplus: nonIFRSDataStructure,
  share_application_money_pending_allotment: nonIFRSDataStructure,
  long_term_borrowings: nonIFRSDataStructure,
  deffered_tax_liabilities_net: nonIFRSDataStructure,
  other_long_term_liabilities: nonIFRSDataStructure,
  long_term_provisions: nonIFRSDataStructure,
  total_non_current_liabilities: nonIFRSDataStructure,
  short_term_borrowings: nonIFRSDataStructure,
  trade_payables: nonIFRSDataStructure,
  other_current_liabilities: nonIFRSDataStructure,
  short_term_provisions: nonIFRSDataStructure,
  total_current_liabilities: nonIFRSDataStructure,
  total_liabilities: nonIFRSDataStructure,
  total_shareholders_equity_and_liabilities: nonIFRSDataStructure,
  shareholders_funds: nonIFRSDataStructure,
};
export const nonIFRSBSGroupData = {
  fixed_assets: "",
  tangible_assets: "",
  intangible_assets: "",
  capital_work_in_progress: "",
  intangible_assets_under_development: "",
  non_current_investments: "",
  deferred_tax_assets_net: "",
  long_term_loan_and_advance: "",
  other_non_current_assets: "",
  total_non_current_assets: "",
  current_investment: "",
  inventories: "",
  trade_receivables: "",
  cash_and_bank_balance: "",
  short_term_loan_and_advance: "",
  other_current_assets: "",
  total_current_assets: "",
  total_assets: "",
  share_capital: "",
  reserves_and_surplus: "",
  share_application_money_pending_allotment: "",
  long_term_borrowings: "",
  deffered_tax_liabilities_net: "",
  other_long_term_liabilities: "",
  long_term_provisions: "",
  total_non_current_liabilities: "",
  short_term_borrowings: "",
  trade_payables: "",
  other_current_liabilities: "",
  short_term_provisions: "",
  total_current_liabilities: "",
  total_liabilities: "",
  total_shareholders_equity_and_liabilities: "",
  shareholders_funds: "",
};
export const getValueForCalc = (state, key) => {
  const route = useRoute();
  if (route?.query?.business === "master") {
    return Number(state.groupData[key] || "") || 0;
  }
  if (state.data[key]?.last_input === "input") {
    return Number(state.data[key].value) || 0;
  } else if (state.data[key]?.last_input === "popup") {
    return Number(state.data[key].total) || 0;
  }
  return 0;
};

import {
  Layout,
  PaymentSuccessPage,
  PaymentCanceledPage,
  PaymentDeclinedPage,
  TransactionPage
} from "../views/payment";

export default {
  path: "/payment",
  component: Layout,
  children: [
    { path: "completed", component: PaymentSuccessPage },
    { path: "canceled", component: PaymentCanceledPage },
    { path: "declined", component: PaymentDeclinedPage },
    { path: "transactions", component: TransactionPage }
  ]
};

// import { useProfileStore } from '@/store/profile'
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import accountRoutes from "./account.route";
import entityRoute from "./entity.route";
import profileRoute from "./profile.route";
import subscriptionRoute from "./subscription.route";
import calculatorRoute from "./calculator";
import usersRoutes from "./user.route";
import paymentRoute from "./payment.route";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView
    /*redirect: "/entity"*/
  },
  {
    ...accountRoutes
  },
  {
    ...entityRoute
  },
  {
    ...profileRoute
  },
  {
    ...subscriptionRoute
  },
  {
    ...calculatorRoute
  },
  {
    ...usersRoutes
  },
  {
    ...paymentRoute
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;

<template>
  <div class="w-100">
    <TaxLiablityBusinessIncome
      v-if="!!Number(taxLiabilityBusinessIncomeQFZP)"
      :open="open"
      :set-open="setOpen"
      :taxable-income="netTaxableIncome"
      :taxLiablityBusinessIncomeFZP="taxLiabilityBusinessIncomeQFZP"
    />
    <TaxLiablityOtherIncomeFZP
      v-if="!!Number(taxLiabilityOtherIncomeQFZP)"
      :open="modal"
      :set-open="setModal"
      :taxLiabilityOtherIncomeQFZP="taxLiabilityOtherIncomeQFZP"
    />
    <TotalTaxLiability
      :open="totalTaxLiabilityModal"
      :setOpen="setTotalTaxLiabilityModal"
    />
    <div class="total-container d-flex justify-content-between">
      <p class="mb-0 total-amount w-50">Taxable Income of Freezone Person</p>
      <p class="mb-0 total-amount">
        {{ NumberFormatter(taxableIncomeOfQFZP) }}
      </p>
    </div>

    <div
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 total-amount w-50">
        Tax Liablity at Business Income of Freezone Person
      </p>
      <div class="d-flex align-items-center">
        <span
          v-if="!!Number(taxLiabilityBusinessIncomeQFZP)"
          class="mr-3 mb-0 bold text-info cursor-pointer info-container"
          @click="setOpen(true)"
          >i</span
        >
        <span class="total-amount mb-0">
          {{ NumberFormatter(taxLiabilityBusinessIncomeQFZP) }}
        </span>
      </div>
    </div>
    <div class="total-container d-flex justify-content-between">
      <p class="mb-0 total-amount w-50">
        Tax Liability at Other Income of Freezone Person
      </p>
      <div class="d-flex align-items-center">
        <span class="total-amount mb-0">
          {{ NumberFormatter(taxLiabilityOtherIncomeQFZP) }}
        </span>
      </div>
    </div>
    <div
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 total-amount w-50">Total Tax Liability</p>

      <p class="total-amount mb-0">
        {{ NumberFormatter(totalTaxLiability) }}
      </p>
    </div>
    <!--<div class="w-100 disclaimer-container p-3">
      <h4 class="total-amount">
        <b class="text-warning">&#x26A0;</b>
        Disclaimer
      </h4>
      <p class="mb-0 body-title">
        Our corporate tax calculator provides an estimate of tax liability based
        on our interpretation of current law in force. It is not professional
        tax advice, subject to change, and may not be accurate or applicable.
        Consult a tax professional for personalized guidance and do not rely
        solely on the calculator's results.
      </p>
    </div>-->
  </div>
</template>

<script setup>
import { ref } from "vue";
import { generate } from "randomized-string";
import { useQFZCalculatorStore } from "../../../store/QFZcalculator";
import { storeToRefs } from "pinia";
import { NumberFormatter } from "../../../helpers/numberFormatter";
import TaxLiablityBusinessIncome from "./TaxLiablityBusinessIncome.vue";
import TaxLiablityOtherIncomeFZP from "./TaxLiablityOtherIncomeFZP.vue";

const QFZCalculator = useQFZCalculatorStore();
const {
  exemption,
  taxableIncomeOfQFZP,
  netTaxableIncome,
  taxLiabilityBusinessIncomeQFZP,
  taxLiabilityOtherIncomeQFZP,
  totalTaxLiability,
} = storeToRefs(QFZCalculator);
const open = ref(false);
const modal = ref(false);
const totalTaxLiabilityModal = ref(false);
const setOpen = (value) => {
  open.value = value;
};
const setModal = (value) => {
  modal.value = value;
};
const setTotalTaxLiabilityModal = (value) => {
  totalTaxLiabilityModal.value = value;
};
const data = ref([
  {
    id: generate(),
    name: "Exemption",
    sub: false,
    showInput: false,
    model: "exemption",
  },
  {
    id: generate(),
    name: "Net Taxable Income",
    sub: false,
    showInput: false,
    model: "net_taxable_income",
  },
]);

const openCloseInput = async (model, value) => {
  data.value = data.value.map((item) => {
    if (item.model === model) {
      return { ...item, showInput: value };
    }
    return item;
  });
  if (value) {
    const input = await document.getElementById(`input-qfz-calc-${model}`);
    input.focus();
  }
};
const handleTab = async (e, current_model) => {
  e.preventDefault();
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
const handleSubmit = async (e) => {
  e.preventDefault();
  let current_model = e.target.id;
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 26px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 31px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}
.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  border-bottom: 1px solid #747171;
  /*background: #fafbff;*/
  padding-right: 100px;
  padding-left: 26px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 26px;
}
.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}
.info-container {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #deeded;
}
.disclaimer-container {
  background: #deeded;
}
.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}
</style>

<template>
  <ProfitAndLossContainer v-slot="{ setSavingStatus }" tab="income">
    <LoaderContainer
      :loading="statusLoading || loading"
      :error="statusError || businessError"
    >
      <LoaderContainer v-if="data === 'IFRS'" :loading="IncomeLoading">
        <Sales :view="!!status" :set-saving-status="setSavingStatus" />
        <OtherOperatingRevenue
          :view="!!status"
          :set-saving-status="setSavingStatus"
        />
        <CostOfSales :view="!!status" :set-saving-status="setSavingStatus" />
      </LoaderContainer>
      <NonIfrsIncome
        v-if="data === 'Non-IFRS'"
        :set-saving-status="setSavingStatus"
      />
    </LoaderContainer>
  </ProfitAndLossContainer>
</template>

<script setup>
import ProfitAndLossContainer from "../Container";
import CostOfSales from "./CostOfSales.vue";
import { useProfitAndLossStore } from "../../../../../store/ProfitAdLoss";
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useStatusStore } from "../../../../../store/statusStore";
import NonIfrsIncome from "../../NON-IFRS/ProfitAndLoss/IncomeContainer.vue";
import { useBusinessStore } from "../../../../../store/buisness.store";
import { useIFRSStore } from "../../../../../store/NonIFRS/IFRS.store";
import { LoaderContainer } from "../../../../common";
import { generate } from "randomized-string";
import Sales from "./Sales.vue";
import OtherOperatingRevenue from "./OtherOperatingRevenue.vue";

const ifrStore = useIFRSStore();
const { data, loading } = storeToRefs(ifrStore);
const profitLossStore = useProfitAndLossStore();

const statusStore = useStatusStore();
const toastId = ref(generate());
const {
  status,
  loading: statusLoading,
  error: statusError,
} = storeToRefs(statusStore);
const { loading: IncomeLoading } = storeToRefs(profitLossStore);
const businesStore = useBusinessStore();
const { error: businessError } = storeToRefs(businesStore);

onMounted(async () => {
  IncomeLoading.value = true;
});
</script>

<style scoped></style>

<template>
  <SideNavContainer
    sideNavType="financialData"
    title="Taxability as per chapter - 8"
    :subtitle="name"
  >
    <ChaptersBreadCrumb
      :entityName="name"
      chapter="chapter-8"
      chapterName="Chapter-8"
    />
    <Business Tab="chapter-8" />
    <LoaderContainer
      :loading="business.loading || loading || statusLoading"
      :error="error || statusError"
    >
      <Master v-if="$route.query.current_business === 'master'" />

      <div v-else class="w-100">
        <div class="w-100 d-flex my-2 align-items-center justify-content-end">
          <p :class="`text-${savingMessage.type} mb-0`">
            {{ savingMessage.message }}
          </p>
        </div>
        <div
          class="item-container w-100 px-1 d-flex align-items-center justify-content-between"
        >
          <div class="flex-2">
            <h4 class="title">
              Article 26 – Transfers Within a Qualifying Group
            </h4>
            <p class="sub-title">
              Whether any Income/Loss is included in the Profit/Loss A/c on
              account of transfer within the Qualifying group
            </p>
          </div>
          <div class="d-flex flex-1 justify-content-end">
            <v-switch
              :model-value="data.account_transfer_within_qualifying_group"
              :disabled="!!status"
              color="#0F75BC"
              inset
              hide-details
              readonly
              @click="closeOpenSwitch"
              @input="updateToggle"
            ></v-switch>
          </div>
        </div>
        <div v-if="$route.query !== 'master'" class="w-100">
          <Article26
            v-if="data.account_transfer_within_qualifying_group"
            :disabled="!!status"
          />
        </div>

        <hr class="hr w-100" />
        <div
          class="item-container w-100 px-1 d-flex align-items-center justify-content-between"
        >
          <div class="flex-2">
            <h4 class="title">Article 27 – Business Restructuring Relief</h4>
            <p class="sub-title">
              Whether any Income/Loss is included in the Profit/Loss A/c on
              account of transfer as Business Restructring
            </p>
          </div>
          <div class="d-flex flex-1 justify-content-end">
            <v-switch
              v-model="data.account_transfer_as_business_restructuring"
              color="#0F75BC"
              inset
              :disabled="!!status"
              hide-details
              readonly
              @click="closeOpenSwitchArticle27"
            ></v-switch>
          </div>
        </div>
        <div class="w-100">
          <Article27 v-if="data.account_transfer_as_business_restructuring" />
        </div>
      </div>
    </LoaderContainer>
  </SideNavContainer>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { SideNavContainer } from "../../layout";
import Article26 from "./chapter8/Article26.vue";
import Article27 from "./chapter8/Article27.vue";
import { useBusinessStore } from "../../../store/buisness.store";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import Business from "../Business/Business.vue";
import { useChapter8Store } from "../../../store/chapter8";
import LoaderContainer from "../../common/LoaderContainer.vue";
import Master from "./chapter8/Master.vue";
import { debounce } from "vue-debounce";
import { ChaptersBreadCrumb } from "../../BreadCrumbs";
import { useStatusStore } from "../../../store/statusStore";

const name = ref("");

const businessStore = useBusinessStore();
const route = useRoute();
const { business } = storeToRefs(businessStore);
const chapter8Store = useChapter8Store();
const { data, loading, error, savingMessage } = storeToRefs(chapter8Store);
const statusStore = useStatusStore();
const {
  status,
  loading: statusLoading,
  error: statusError
} = storeToRefs(statusStore);
const closeOpenSwitch = async () => {
  if (data.value.account_transfer_within_qualifying_group) {
    if (confirm("ARE YOU SURE TO DISABLE THIS IT WILL DELETE ALL INPUT DATA")) {
      data.value.account_transfer_within_qualifying_group =
        !data.value.account_transfer_within_qualifying_group;
      await chapter8Store.saveChapter8(route, async () => {
        await chapter8Store.getChapter8(route);
      });
    } else {
      data.value.account_transfer_within_qualifying_group = true;
    }
    return;
  }
  data.value.account_transfer_within_qualifying_group = true;
};
const closeOpenSwitchArticle27 = async () => {
  if (data.value.account_transfer_as_business_restructuring) {
    if (confirm("ARE YOU SURE TO DISABLE THIS IT WILL DELETE ALL INPUT DATA")) {
      data.value.account_transfer_as_business_restructuring =
        !data.value.account_transfer_as_business_restructuring;
      await chapter8Store.saveChapter8(route, async () => {
        await chapter8Store.getChapter8(route);
      });
    }
    return;
  }
  data.value.account_transfer_as_business_restructuring = true;
};
onMounted(async () => {
  await chapter8Store.getChapter8(route);
  if (route.query.financial_year && route.query.financial_period) {
    await statusStore.getStatus(
      route.params.id,
      route.query.financial_year,
      route.query.financial_period
    );
  }
});
const setBusinessName = (newValue, current_business) => {
  const findBusiness = newValue?.data?.find((b) => b.id == current_business);
  name.value = findBusiness?.name ? findBusiness?.name : "";
};
watch(
  business,
  (newValue) => {
    if (newValue?.data.length)
      setBusinessName(newValue, route.query.current_business);
  },
  { deep: true }
);
watch(
  () => route.query,
  (newValue) => {
    setBusinessName(business.value, newValue.current_business);
  }
);
watch(
  data,
  () => {
    debounce(async (route) => {
      await chapter8Store.saveChapter8(route);
    }, 4000)(route);
  },
  { deep: true }
);
</script>

<style scoped>
.item-container {
  min-height: 105px;
  gap: 60px;
}

.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}

.sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}

.hr {
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.flex-2 {
  flex: 10;
}
.flex-1 {
  flex: 1;
}
</style>

<template>
  <SignInSignUpContainer>
    <CreateAccount />
  </SignInSignUpContainer>
</template>

<script>
import { SignInSignUpContainer } from "@/components/account";
import CreateAccount from "@/components/account/CreateAccount.vue";

export default {
  name: "RegisterApp",
  components: { SignInSignUpContainer, CreateAccount }
};
</script>

<style scoped>
.wrapper {
  height: 125vh;
  padding: 110px 132px;
}
.create {
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 52px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

/* eslint-disable no-prototype-builtins */
import fetchWrapper from "@/helpers/fetchWrapper";
import { defineStore } from "pinia";
import { downloadURI } from "../helpers/download";
import fetchFileWrapper from "../helpers/fileApi";
import { getErrorMessage, getWarningMessage } from "../helpers/errorHandler";
import { Toast } from "../helpers/toast";

const totalKeys = [
  "derivative_financial_instrumentsderivatives_held_for_hedging",
  "derivative_financial_instrumentscash_fl_hdg",
  "derivative_financial_instrumentsfair_vl_hdg",
  "derivative_financial_instderivatives_not_held_for_hedging",
  "prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls",
  "prpty_plant_equipment_land",
  "intangible_assets_goodwill",
  "intangible_assets_tradmarks_and_license",
  "intangible_assets_computer_software_and_license",
  "drtv_fncl_intmts_drtvs_hld_fr_hdg",
  "drtv_fncl_intmts_drtvs_hld_fr_hdg_csh_flw_hdgs",
  "drtv_fncl_intmts_drtvs_hld_fr_hdg_fair_value_hedge",
  "drvtv_fncl_instmts_drvtvs_nt_hld_fr_hdgg",
  "trade_and_other_rcvbls_trd_rcvbls",
];
export const useFinancialDataStore = defineStore("financial", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      loading: true,
      error: false,
      // all these properties will have their type inferred automatically
      NonCurrentAssetData: {
        financial_assets_at_fvtpl_begining_of_financial_year: "",
        financial_assets_at_fvtpl_additions: "",
        financial_assets_at_fvtpl_fair_value_gains: "",
        financial_assets_at_fvtpl_disposals: "",
        derivative_financial_instrumentsderivatives_held_for_hedging: "",
        derivative_financial_instrumentscash_fl_hdg: "",
        derivative_financial_instrumentscash_fl_hdg_interest_rate_swaps: "",
        derivative_financial_instrumentscash_fl_hdg_currency_forwds: "",
        derivative_financial_instrumentsfair_vl_hdg: "",
        derivative_financial_instrumentsfair_vl_hdg_crncy_forwards: "",
        derivative_financial_instderivatives_not_held_for_hedging: "",
        derivative_financial_instderivatives_nt_hld_for_hdg_crncy_forwds: "",
        financial_assets_at_fvoci_begining_of_financial_year: "",
        financial_assets_at_fvoci_additions: "",
        financial_assets_at_fvoci_fair_value_gains: "",
        financial_assets_at_fvoci_disposals: "",
        other_investment_at_amortised_cost_begining_of_financial_year: "",
        other_investment_at_amortised_cost_additions: "",
        other_investment_at_amortised_cost_fair_value_gains: "",
        other_investment_at_amortised_cost_disposals: "",
        trad_and_other_receivables_finance_lease_receivables: "",
        trad_and_other_receivables_loan_to_associates: "",
        trad_and_other_receivables_loan_to_subsidiaries: "",
        trad_and_other_receivables_staff_loans: "",
        investment_properties_begining_of_financial_years: "",
        investment_properties_additions: "",
        investment_properties_fair_value_gains: "",
        investment_properties_disposals: "",
        investment_properties_transfer_from_inventories: "",
        investment_properties_transfer_from_property_plant_equipment: "",
        prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls: "",
        prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_bgng_of_fncl_yr: "",
        prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_additions: "",
        prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_disposals: "",
        prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_depreciation: "",
        prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_tnsfr_to_invtmnt_prpts: "",
        prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_revaluation_adjustment: "",
        prpty_plant_equipment_land: "",
        prpty_plant_equipment_land_freehold_land: "",
        prpty_plant_equipment_land_leasehold_land: "",
        intangible_assets_goodwill: "",
        intangible_assets_goodwill_begining_of_financial_year: "",
        intangible_assets_goodwill_additions: "",
        intangible_assets_goodwill_impairments: "",
        intangible_assets_tradmarks_and_license: "",
        intangible_assets_tradmarks_and_license_begining_of_financial_yr: "",
        intangible_assets_tradmarks_and_license_additions: "",
        intangible_assets_tradmarks_and_license_impairments: "",
        intangible_assets_computer_software_and_license: "",
        intangible_assets_computer_software_and_license_bgng_of_fncl_yr: "",
        intangible_assets_computer_software_and_license_additions: "",
        intangible_assets_computer_software_and_license_impairments: "",
        deffered_incom_tax_assets_deffered_tax_assets: "",
        deffered_incom_tax_assets_deffered_tax_liabilities: "",
        investment_in_a_joint_venture_eqty_invstmnt_at_cst_at_bgng_of_yr: "",
        investment_in_a_joint_venture_share_of_profit_in_associates: "",
        investment_in_a_joint_venture_dividend_received: "",
        investment_in_associates_equity_invstmnt_at_cost_at_bgng_of_yr: "",
        investment_in_associates_share_of_profit_in_associates: "",
        investment_in_associates_dividend_received: "",
      },
      financialperiods: [],
      currentAssetData: {
        cash_and_cash_equivalents_cash_at_bank: "",
        cash_and_cash_equivalents_cash_in_hand: "",
        cash_and_cash_equivalents_short_term_bank_deposite: "",
        cash_and_cash_equivalents_bank_deposite_pledged: "",
        cash_and_cash_equivalents_bank_overdrafts: "",
        drtv_fncl_intmts_drtvs_hld_fr_hdg: "",
        drtv_fncl_intmts_drtvs_hld_fr_hdg_csh_flw_hdgs: "",
        drtv_fncl_intmts_drtvs_hld_fr_hdg_csh_flw_hdgs_intrst_rt_swps: "",
        drtv_fncl_intmts_drtvs_hld_fr_hdg_csh_flw_hdgs_crncy_fwrd: "",
        drtv_fncl_intmts_drtvs_hld_fr_hdg_fair_value_hedge: "",
        drtv_fncl_intmts_drtvs_hld_fr_hdg_fair_value_hedge_crncy_fwrd: "",
        drvtv_fncl_instmts_drvtvs_nt_hld_fr_hdgg: "",
        drvtv_fncl_instmts_drvtvs_nt_hld_fr_hdgg_crncy_fwrd: "",
        trade_and_other_rcvbls_trd_rcvbls: "",
        trade_and_other_rcvbls_trd_rcvbls_associates: "",
        trade_and_other_rcvbls_trd_rcvbls_subsidiaries: "",
        trade_and_other_rcvbls_trd_rcvbls_non_related_parties: "",
        trade_and_other_rcvbls_trd_rcvbls_allowances: "",
        trade_and_other_receivables_loan_to_associates: "",
        trade_and_other_receivables_finance_lease_receivables: "",
        trade_and_other_receivables_staff_loans: "",
        trade_and_other_receivables_government_grant_receivables: "",
        trade_and_other_receivables_deposites: "",
        trade_and_other_receivables_prepayments: "",
        trade_and_other_receivables_other_receivables: "",
        inventories_raw_materials: "",
        inventories_work_in_progress: "",
        inventories_finished_goods: "",
        ncrnt_asts_clfd_as_hld_fr_sl_discontinued_operations: "",
        ncrnt_asts_clfd_as_hld_fr_sl_plant_prpty_and_eqpmnt_hld_fr_sale: "",
        ncrnt_asts_clfd_as_hld_fr_sl_discontinued_cash_generating_units: "",
      },
      totalNonCurrentAsset: {
        financial_assets_at_fvtpl: "",
        derivative_financial_instruments: "",
        financial_assets_at_fvoci: "",
        other_investment_at_amortised_cost: "",
        trad_and_other_receivables: "",
        investment_properties: "",
        prpty_plant_equipment: "",
        intangible_assets: "",
        deffered_incom_tax_assets: "",
        investment_in_a_joint_venture: "",
        investment_in_associates: "",
        total_non_current_assets: "",
      },
      totalCurrentAssets: {
        cash_and_cash_equivalents: "",
        derivative_financial_instruments: "",
        trade_and_other_rcvbls: "",
        inventories: "",
        non_current_assets_classified_as_held_for_sale: "",
        total_current_assets: "",
      },
    };
  },
  actions: {
    async getAsset(
      entity_id,
      financial_year,
      financial_period,
      current_business
    ) {
      try {
        this.loading = true;

        if (financial_year && financial_period) {
          const res = await fetchWrapper.get(
            `/nonCurrentAsset?entity_id=${entity_id}&financial_period=${financial_period}&financial_year=${financial_year}${
              current_business === "master"
                ? ""
                : "&entity_business_id=" + current_business
            }`
          );
          if (res.data.result == 1) {
            let responseData = res.data?.data;
            for (let x in responseData) {
              let NonCurrentAssetData =
                this.NonCurrentAssetData.hasOwnProperty(x);

              if (NonCurrentAssetData) {
                if (!totalKeys.includes(x)) {
                  this.NonCurrentAssetData[x] = responseData[x]
                    ? responseData[x]
                    : "";
                }
              }
            }
            this.totalNonCurrentAsset.total_non_current_assets =
              responseData?.total_non_current_assets;
            //this.NonCurrentAssetData = responseData;
            // this.financial_common_data = {
            //   ...this.financial_common_data,
            //   ...responseData,
            // };
          } else {
            for (let x in this.NonCurrentAssetData) {
              if (!totalKeys.includes(x)) {
                this.NonCurrentAssetData[x] = "";
              }
            }
          }
          await this.getCurrentAsset(
            entity_id,
            financial_year,
            financial_period,
            current_business
          );
          this.loading = false;
          this.error = false;
        } else {
          for (let x in this.NonCurrentAssetData) {
            this.NonCurrentAssetData[x] = "";
          }
        }
      } catch (err) {
        console.log(err);
        this.loading = false;
        this.error = err?.response?.data?.message || "";
      }
    },
    async getCurrentAsset(
      entity_id,
      financial_year,
      financial_period,
      current_business
    ) {
      try {
        const res = await fetchWrapper.get(
          `/currentAsset?entity_id=${entity_id}&financial_period=${financial_period}&financial_year=${financial_year}${
            current_business === "master"
              ? ""
              : "&entity_business_id=" + current_business
          }`
        );
        if (res.data.result == 1) {
          let responseData = res.data?.data;
          //this.currentAssetData = responseData;
          for (let x in responseData) {
            let currentAssetData = this.currentAssetData.hasOwnProperty(x);
            if (currentAssetData) {
              if (!totalKeys.includes(x)) {
                this.currentAssetData[x] = responseData[x]
                  ? responseData[x]
                  : "";
              }
            }
          }
          this.totalCurrentAssets.total_current_assets =
            responseData?.total_current_assets;
        } else {
          for (let x in this.currentAssetData) {
            if (!totalKeys.includes(x)) {
              this.currentAssetData[x] = "";
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    async save(route, setMessage = () => {}, callBack = () => {}) {
      const toast = new Toast("Saving non current assets...");
      try {
        const entity_id = route.params.id;
        const { financial_year, financial_period, business } = route.query;
        let payload = {
          entity_id,
          financial_year: financial_year,
          financial_period: financial_period,
          entity_business_id: business ? business : 0,
          ...this.NonCurrentAssetData,
          ...this.totalNonCurrentAsset,
          total_non_current_assets: this.total_non_current_assets,
        };

        let res = await fetchWrapper.post("/nonCurrentAsset", payload);
        if (res.data.result == 1) {
          toast.makeInfoToast("Saving current asssets");
          let response = await this.saveCurrent(
            entity_id,
            financial_year,
            financial_period,
            res.data.entity_business_id
          );

          //console.log("response2", response.data.result);
          if (response.data.result == 1) {
            toast.makeSuccessToast("Data saved successfully");
            callBack(res.data.entity_business_id);
          } else {
            toast.makeWarningToast(
              res.data?.error ? res.data?.error?.join(",") : res.data?.message
            );
          }
        } else {
          toast.makeWarningToast(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message
          );
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    },
    async saveCurrent(
      entity_id,
      financial_year,
      financial_period,
      entity_business_id
    ) {
      try {
        let payload =
          entity_business_id !== "master"
            ? {
                entity_id,
                financial_year: financial_year,
                financial_period: financial_period,
                entity_business_id,
                ...this.currentAssetData,
                ...this.totalCurrentAssets,
                total_current_assets: this.total_current_assets,
              }
            : {
                entity_id,
                financial_year: financial_year,
                financial_period: financial_period,
                ...this.currentAssetData,
                ...this.totalCurrentAssets,
                total_current_assets: this.total_current_assets,
              };
        const res = await fetchWrapper.post("/currentAsset", payload);
        return res;
      } catch (err) {
        console.log(err);
      }
    },
    SetNonCurrentAssetToStore(data) {
      console.log("storeData", data);
      this.NonCurrentAssetData = { ...this.NonCurrentAssetData, ...data };
    },
    async getFinancialPeriod() {
      try {
        const res = await fetchWrapper.get("/financialYear");
        this.financialperiods = res.data?.data;
      } catch (err) {
        console.log(err);
      }
    },
    async saveBalanceSheetFile(
      id,
      financial_year,
      financial_period,
      current_business,
      type = "Excel"
    ) {
      const toast = new Toast("Downloading...");
      try {
        const res = await fetchWrapper.get(
          `/balancesheetExport?entity_id=${id}&financial_year=${financial_year}&financial_period=${financial_period}&type=${type}${
            current_business === "master"
              ? ""
              : "&entity_business_id=" + current_business
          }
          `
        );
        if (res.data?.result === 1) {
          const url = res.data?.url;
          await downloadURI(url, "profitAndLoss");
          toast.makeSuccessToast("Downloaded successfully");
        } else {
          toast.makeWarningToast(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message
          );
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    },
    async uploadBalanceSheetFile(
      entity_id,
      File,
      financial_year,
      financial_period,
      current_business,
      setMessage = () => {},
      callBack = () => {}
    ) {
      const toast = new Toast("Uploading...");
      try {
        if (File && financial_year && financial_period) {
          let data = new FormData();
          data.append("file", File);
          data.append("entity_id", entity_id);
          data.append(
            "entity_business_id",
            current_business ? current_business : 0
          );
          data.append("financial_year", financial_year);
          data.append("financial_period", financial_period);
          const res = await fetchFileWrapper.post("/importBalancesheet", data);
          if (res?.data?.result === 1) {
            toast.makeSuccessToast(res.data.message);
            callBack(res?.data?.entity_business_id);
            return;
          } else toast.makeWarningToast(getWarningMessage(res));
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(getErrorMessage(err));
      }
    },
    async autoSaveNonCurrentAssets(
      route,
      callBack = () => {},
      setSavingStatus = () => {}
    ) {
      try {
        const entity_id = route.params.id;
        const { financial_year, financial_period, business } = route.query;
        let payload = {
          entity_id,
          financial_year: financial_year,
          financial_period: financial_period,
          entity_business_id: business ? business : 0,
          ...this.NonCurrentAssetData,
          ...this.totalNonCurrentAsset,
          total_non_current_assets: this.total_non_current_assets,
        };

        let res = await fetchWrapper.post("/nonCurrentAsset", payload);
        if (res.data.result == 1) {
          setSavingStatus("loading", false);
          setSavingStatus("error", false);
          setSavingStatus("success", true);
          callBack(res.data.entity_business_id);
        } else {
          setSavingStatus("loading", false);
          setSavingStatus("error", getWarningMessage(res));
          setSavingStatus("success", false);
          setTimeout(() => {
            setSavingStatus("loading", false);
            setSavingStatus("error", false);
            setSavingStatus("success", false);
          }, 3000);
        }
      } catch (err) {
        console.log(err);
        setSavingStatus("loading", false);
        setSavingStatus("error", getErrorMessage(err));
        setSavingStatus("success", false);
        setTimeout(() => {
          setSavingStatus("loading", false);
          setSavingStatus("error", false);
          setSavingStatus("success", false);
        }, 3000);
      }
    },
    async autoSaveCurrent(
      route,
      callBack = () => {},
      setSavingStatus = () => {}
    ) {
      try {
        const {
          financial_year,
          financial_period,
          business: entity_business_id,
        } = route.query;
        let payload =
          entity_business_id !== "master"
            ? {
                entity_id: route.params.id,
                financial_year: financial_year,
                financial_period: financial_period,
                entity_business_id,
                ...this.currentAssetData,
                ...this.totalCurrentAssets,
                total_current_assets: this.total_current_assets,
              }
            : {
                entity_id: route.params.id,
                financial_year: financial_year,
                financial_period: financial_period,
                ...this.currentAssetData,
                ...this.totalCurrentAssets,
                total_current_assets: this.total_current_assets,
              };
        const res = await fetchWrapper.post("/currentAsset", payload);
        if (res.data.result == 1) {
          setSavingStatus("loading", false);
          setSavingStatus("error", false);
          setSavingStatus("success", true);
          callBack(res.data.entity_business_id);
        } else {
          setSavingStatus("loading", false);
          setSavingStatus("error", getWarningMessage(res));
          setSavingStatus("success", false);
          setTimeout(() => {
            setSavingStatus("loading", false);
            setSavingStatus("error", false);
            setSavingStatus("success", false);
          }, 3000);
        }
      } catch (err) {
        console.log(err);
        setSavingStatus("loading", false);
        setSavingStatus("error", getErrorMessage(err));
        setSavingStatus("success", false);
        setTimeout(() => {
          setSavingStatus("loading", false);
          setSavingStatus("error", false);
          setSavingStatus("success", false);
        }, 3000);
      }
    },
  },
  getters: {
    total_non_current_assets(state) {
      const obj = state.totalNonCurrentAsset;
      let sum = 0;
      for (let x in obj) {
        if (x !== "total_non_current_assets") {
          sum += Number(obj[x]);
        }
      }
      return sum;
    },
    total_current_assets(state) {
      var obj = state.totalCurrentAssets;
      const keys = [
        "cash_and_cash_equivalents",
        "derivative_financial_instruments",
        "trade_and_other_rcvbls",
        "inventories",
        "non_current_assets_classified_as_held_for_sale",
      ];
      let sum = keys.reduce((a, b) => a + Number(obj[b] || 0), 0);
      return sum;
    },
    total_assets(state) {
      let sum =
        Number(state.total_current_assets) +
        Number(state.total_non_current_assets);

      return sum;
    },
  },
});

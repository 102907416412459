<template>
  <Loader v-if="loading" />
  <div class="w-100" v-else>
    <ShowStatus :modal="editStatus" :set-modal="setStatus" />
    <CustomTable
      :Data="data"
      :Headers="headers"
      :actions="actions"
      :haveIndex="true"
    />
  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable.vue";
import { storeToRefs } from "pinia";
import { generate } from "randomized-string";
import { ref } from "vue";
import tableEdit from "../../../../assets/svg/table-edit.svg";
import tableDelete from "../../../../assets/svg/trash.svg";
import { useEntityStore } from "../../../../store/entity";
import { getEntityItems } from "../../../../store/entityActions/get";
import { useRouter, useRoute } from "vue-router";
import Loader from "@/components/common/Loader.vue";
import { deleteEntity } from "../../../../store/entityActions/delete";
import { dateMDY } from "../../../../helpers/date";
import ShowStatus from "./ShowStatus.vue";

export default {
  name: "LicenseDetails",
  components: { CustomTable, Loader, ShowStatus },
  setup() {
    const headers = ref([
      "Place of Establishment",
      "License Authority Type",
      "License Authority",
      "License Number",
      "License Issue Date",
      "License Expiry Date",
      "License Certification",
      "Registration Certification",
    ]);
    const entityStore = useEntityStore();
    const { entity, businessActivity } = storeToRefs(entityStore);
    const router = useRouter();
    const route = useRoute();
    const editStatus = ref(false);
    const setStatus = (status) => {
      editStatus.value = status;
    };
    const actions = ref([
      {
        id: generate(),
        icon: tableEdit,
        onClick: (id) => {
          if (entity.value?.data?.entity[0]?.file_return_status === "Filed") {
            setStatus(true);
            return;
          }
          router.push(
            `/entity/add-entity/entity-details/license/${route.params.id}/${id}`
          );
        },
      },
      {
        id: generate(),
        icon: tableDelete,
        onClick: async (id) => {
          if (confirm("Are you sure you want to delete this license?")) {
            await deleteEntity(
              id,
              `/entityLicense`,
              () => {},
              () => {
                location.reload();
              }
            );
          }
        },
      },
    ]);
    return {
      headers,
      actions,
      entityStore,
      entity,
      businessActivity,
      editStatus,
      setStatus,
    };
  },
  data() {
    return {
      data: [],
      loading: true,
    };
  },
  async created() {
    if (!this.businessActivity.data.length) {
      await getEntityItems("businessActivity", "/businessActivity");
    }
    this.data = this.entity.data?.license?.map((l) => ({
      id: l.id,
      place_of_establishment: l.place_of_establishment?.name,
      license_authority_type: l.license_authority_type?.name,
      license_authority: l.license_authority?.name,
      license_number: l.license_number,
      license_issue_date: dateMDY(l.license_issue_date),
      license_expiry_date: dateMDY(l.license_expiry_date),
      license_certification: {
        type: "image",
        url: l?.license_certification
          ? `${l?.license_imagepath}/${l?.license_certification}`
          : null,
      },
      registration_certification: {
        type: "image",
        url: l?.registration_certification
          ? `${l?.registration_imagepath}/${l?.registration_certification}`
          : null,
      },
    }));
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped></style>

export const basicDataStructure = {
  loading: true,
  data: null,
  error: false

}
export const basicDataStructures = {
  loading: true,
  data: [],
  error: false

}

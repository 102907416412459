<template>
  <div class="password-container">
    <form @submit="handleSubmit">
      <CustomTextInput
        v-for="(item, index) in data"
        :key="item.key"
        v-model="data[index].model"
        type="password"
        container-class="mt-4"
        v-bind="data[index].props"
        :disabled="
          item.key === 'confirm_password' ? !checkPasswordValidity : false
        "
        :add-suggestion="item.key === 'password'"
        :check-validity="item.key === 'password' ? changeValidity : () => {}"
        :error-message="error[item.key]"
      />
      <button
        class="save-pass-button p-2 data-text mt-4"
        type="submit"
        :disabled="
          !data[0].model || !data[1].model
            ? true
            : data[0].model !== data[1].model
        "
      >
        Change Password
      </button>
    </form>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { CustomTextInput } from "../common";
import { useUsersStore } from "../../store/users.store";

const props = defineProps({
  subUserId: {
    type: String,
    required: true,
  },
  setOpen: {
    type: Function,
    default: () => {},
  },
});
const usersStore = useUsersStore();
const checkPasswordValidity = ref(false);
const changeValidity = (value) => {
  checkPasswordValidity.value = value;
};
const data = ref([
  {
    key: "password",
    model: "",
    props: {
      label: "New password",
      placeholder: "Enter your password",
      required: true,
    },
  },
  {
    key: "confirm_password",
    model: "",
    props: {
      label: "Confirm password",
      placeholder: "Enter your password",
      required: true,
      onpaste: (e) => e.preventDefault(),
    },
  },
]);
const error = ref({
  password: "",
  confirm_password: "",
});
const handleSubmit = async (e) => {
  e.preventDefault();
  await usersStore.changeSubUserPassword(
    {
      sub_user_id: props.subUserId,
      password: data.value[0].model,
    },
    async () => {
      await usersStore.getUsersList();
      await props.setOpen(false);
    }
  );
};
watch(
  () => data.value[1].model,
  (newValue) => {
    if (newValue && data.value[0].model !== newValue) {
      error.value.confirm_password = "Password is not matched";
    } else {
      error.value.confirm_password = "";
    }
  },
  { deep: true }
);
</script>

<style scoped>
.save-pass-button {
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.password-container {
  width: 100% !important;
}
.save-pass-button:disabled {
  opacity: 0.5;
}
</style>

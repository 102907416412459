<template>
  <div class="wrapper w-100 mt-4 d-flex flex-column justify-content-between">
    <p class="billing-info">Billing Info</p>
    <div class="d-flex align-items-center">
      <p class="title mr-2">Order ID :</p>
      <p class="desc">
        {{ mySubscription?.data?.order_no }}
      </p>
    </div>
    <hr />
    <div class="d-flex align-items-center flex-wrap justify-content-between">
      <div class="">
        <p class="title mb-0">Billing Name</p>
        <p class="desc mb-0">{{ mySubscription?.data?.billing_name }}</p>
      </div>
      <div class="">
        <p class="title mb-0">Billing Address</p>
        <p class="desc mb-0">{{ mySubscription?.data?.billing_address }}</p>
      </div>
      <div class="">
        <p class="title mb-0">Billing Mobile Number</p>
        <p class="desc mb-0">{{ mySubscription?.data?.billing_mobile }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useSubscriptionStore } from "@/store/subscription";
import { storeToRefs } from "pinia";

const subscriptionStore = useSubscriptionStore();
const { mySubscription } = storeToRefs(subscriptionStore);
</script>

<style scoped>
.wrapper {
  min-height: 332px;
  background: #deeded;
  box-shadow: 0px 8px 8px rgba(187, 187, 187, 0.25);
  border-radius: 10px;
  padding: 27px 18px 20px 18px;
}
.billing-info {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #382628;
}
.desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #747171;
}
hr {
  border: 1px solid rgba(116, 113, 113, 0.5);
}
</style>

<template>
  <div class="w-100">
    <div
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 total-amount">TOTAL SHAREHOLDERS’ EQUITY</p>
      <p class="mb-0 total-amount">
        {{ NumberFormatter(total_shareholder_liability) }}
      </p>
    </div>
    <div
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 total-amount">Non Current Liabilities</p>
    </div>
  </div>
</template>

<script>
import { useLiabilityDataStore } from "@/store/liabities.store";
import { storeToRefs } from "pinia";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";

export default {
  name: "TotlaShareHoldersEquity",
  setup() {
    const liabilityDataStore = useLiabilityDataStore();
    return { liabilityDataStore, NumberFormatter };
  },
  data() {
    return {};
  },
  computed: {
    total_shareholder_liability: function () {
      return this.liabilityDataStore.total_shareholder_liability;
    }
  }
};
</script>

<style scoped>
.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}
</style>

<template>
  <v-dialog width="auto" v-model="toggle" content-class="my-custom-dialog">
    <div class="card-container">
      <div class="d-flex w-100 justify-content-end">
        <img
          src="@/assets/svg/close-circle.svg"
          class="cursor-pointer"
          alt=""
          @click="closeModal()"
        />
      </div>
      <h4 class="bold title mt-4">
        Filing planner in company {{ entityName }} at {{ day }}
      </h4>

      <div class="w-100 d-flex align-items-center justify-content-end"></div>
      <div class="w-100 table-responsive mt-4 mb-0">
        <table class="table custom-table mb-0 table-bordered table-striped">
          <thead>
            <tr>
              <th class="table-header">Filing Type</th>
              <th class="table-header">Notification Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <select
                  :value="plan?.filing_type"
                  class="date-select pl-2 mt-2 mr-3"
                  disabled
                >
                  <option
                    v-for="year in filingType.data"
                    :key="year.id"
                    class="option"
                    :value="year.id"
                  >
                    {{ year.name }}
                  </option>
                </select>
              </td>
              <td class="table-rows align-middle">
                <p class="data-text text-center mb-0 w-100">
                  <b>{{
                    new Date(plan?.notification_start_date)
                      .toUTCString()
                      .slice(0, 16)
                  }}</b>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import { ref, watch } from "vue";
import { useFilingPlannerStore } from "../../../store/filingPlanner";
import { storeToRefs } from "pinia";

const props = defineProps({
  selectedDate: {
    type: String,
    required: true
  },
  open: {
    type: Boolean,
    required: true
  },
  setOpen: {
    type: Function,
    required: true
  },
  day: {
    type: String,
    required: true
  },
  entityName: {
    type: String,
    default: ""
  },
  entity: {
    type: String,
    default: ""
  }
});
const toggle = ref(props.open);
const fillingPlannerStore = useFilingPlannerStore();
const { filingPlanner, filingType } = storeToRefs(fillingPlannerStore);
const plan = ref({
  filing_type: "",
  notification_date: Date.now()
});
const closeModal = () => {
  toggle.value = false;
  props.setOpen(false, "entityModal");
};
watch(
  () => props.open,
  (newValue) => {
    toggle.value = newValue;
  }
);
watch(toggle, (newValue) => {
  props.setOpen(newValue, "entityModal");
});
watch(
  () => props.entity,
  (newValue) => {
    const data = filingPlanner.value.data.find(
      (item) =>
        item.entity_id == newValue && item?.filing_date == props.selectedDate
    );

    plan.value = data;
  },
  { deep: true }
);
watch(
  () => props.selectedDate,
  (newValue) => {
    const data = filingPlanner.value.data.find(
      (item) => item.entity_id == props.entity && item?.filing_date == newValue
    );
    plan.value = data;
  },
  { deep: true }
);
</script>

<style scoped>
.card-container {
  background: #fafbff;
  border-radius: 5px;
  padding: 33px 27px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.card-container::-webkit-scrollbar {
  display: none;
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /* identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
}
.table-header {
  min-height: 39px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2.5px solid #fff;
}
.table-rows {
  background: #ffffff;
  border: 2.5px solid #fff;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input {
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input:focus {
  outline: none;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.delete-button {
  width: 50px;
  height: 82px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.gray {
  background: #deeded;
}
.date-select {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  width: 212px;
  height: 40px;
}
</style>

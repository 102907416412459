<template>
  <LoaderContainer :loading="profile.loading" :error="profile.error">
    <div class="w-100 mt-4">
      <div class="row">
        <div class="col-lg-6">
          <div class="w-100">
            <div
              class="header d-flex align-items-center w-100 justify-content-between"
            >
              <p class="header-title mb-0">Profile</p>
              <router-link to="/profile/edit-profile">
                <div class="d-flex align-items-center justify-content-end">
                  <img
                    src="@/assets/svg/edit-mini-blue.svg"
                    class="mr-2"
                    alt=""
                  />

                  <p class="mb-0 edit-profile cursor-pointer">Edit Profile</p>
                </div>
              </router-link>
            </div>

            <div
              v-for="(item, index) in data"
              :key="item.key"
              :class="`w-100 table-body ${
                !index || !(index % 2) ? 'gray' : 'white'
              }  d-flex align-items-center justify-content-between`"
            >
              <p class="mb-0 body-title w-25">{{ item.name }}</p>
              <p
                v-if="!item.showInput"
                class="body-title one-line text-right w-75 mb-0"
              >
                {{ profile.data[item.key] }}
              </p>
            </div>
            <div
              class="w-100 d-flex align-items-center my-2 justify-content-end"
            >
              <button
                class="btn-submit px-3"
                @click="$router.push(`/profile/change-password`)"
              >
                Change Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </LoaderContainer>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useProfileStore } from "../../store/profile";
import LoaderContainer from "../common/LoaderContainer.vue";
import { ref } from "vue";
const profileStore = useProfileStore();
const { profile } = storeToRefs(profileStore);
const data = ref([
  {
    key: "first_name",
    name: "First Name",
  },
  {
    key: "last_name",
    name: "Last Name",
  },
  {
    key: "email",
    name: "Email",
  },
  {
    key: "mobile_no",
    name: "Mobile Number",
  },
  {
    key: "type",
    name: "Type",
  },
  {
    key: "address",
    name: "Address",
  },
  {
    key: "zipcode",
    name: "Zip Code",
  },
]);
</script>

<style scoped>
.edit-profile {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #fff;
}

.btn-submit {
  min-width: 82px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 15px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  min-height: 44px;
  padding-right: 15px;
  padding-left: 10px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}
.table-body.hover:hover {
  background: linear-gradient(90deg, #101432 0%, #00a7a7 100%);
  color: #fafbff !important;
}
.sub-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}

.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}

.total-container.h {
  min-height: 150px;
}

.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}

.clickable-text {
  text-align: right !important;
  border: none;
  border-bottom: 1px solid#000000;
  padding-bottom: 1px;
  padding-left: 4px;
  height: 100%;
}

.clickable-text:focus {
  outline: 0;
  border-bottom: 1px solid#0f75bc;
}

.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}

.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}

.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}

.total-desc {
  background: #deeded;
}
.p-10px {
  padding: 6px !important;
}
.one-line {
  white-space: nowrap; /* Prevent wrapping to new lines */
  overflow: hidden; /* Hide any overflow */
  text-overflow: ellipsis; /* Display an ellipsis (...) to indicate overflow */
}
</style>

<template>
  <v-dialog width="auto" v-model="toggle" content-class="my-custom-dialog">
    <div class="card-container">
      <div class="d-flex w-100 justify-content-end">
        <img
          src="@/assets/svg/close-circle.svg"
          class="cursor-pointer"
          alt=""
          @click="closeModal()"
        />
      </div>
      <h4 class="bold title mt-4">
        Tax Liability at Business Income of Freezone person
      </h4>
      <div class="w-100 table-responsive mt-4 mb-0">
        <table class="table custom-table mb-0 table-bordered table-striped">
          <thead>
            <tr>
              <th class="table-header">Particulars</th>
              <th class="table-header amount">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="table-rows">
                <p class="data-text mb-0">Gross Total Income</p>
              </td>
              <td class="table-rows">
                <p class="data-text">
                  {{ NumberFormatter(taxableIncomeOfQFZP) }}
                </p>
              </td>
            </tr>
            <tr>
              <td class="table-rows">
                <p class="data-text mb-0">Exemption(-)</p>
              </td>
              <td class="table-rows">
                <p class="data-text">
                  {{ NumberFormatter(exemption) }}
                </p>
              </td>
            </tr>
            <tr>
              <td class="table-rows">
                <p class="data-text mb-0">Net Taxable Income</p>
              </td>

              <td class="table-rows">
                <p class="data-text">
                  {{ NumberFormatter(netTaxableIncome) }}, [{{
                    NumberFormatter(taxableIncomeOfQFZP)
                  }}
                  - {{ NumberFormatter(exemption) }}]
                </p>
              </td>
            </tr>
            <tr>
              <td class="table-rows">
                <p class="data-text mb-0">
                  Tax Liability @9% on Net Taxable Income
                </p>
              </td>

              <td class="table-rows">
                <p class="data-text">
                  {{ NumberFormatter(netTaxableIncome * 0.09) }}, [{{
                    NumberFormatter(netTaxableIncome)
                  }}
                  * 9%]
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import { ref, watch } from "vue";
import { NumberFormatter } from "../../../helpers/numberFormatter";
import { useQFZCalculatorStore } from "../../../store/QFZcalculator";
import { storeToRefs } from "pinia";

const props = defineProps({
  open: Boolean,
  setOpen: {
    type: Function,
    required: true,
  },
});

const QFZCalculator = useQFZCalculatorStore();
const { exemption, taxableIncomeOfQFZP, netTaxableIncome } =
  storeToRefs(QFZCalculator);
const toggle = ref(props.open);
watch(
  () => props.open,
  () => {
    toggle.value = props.open;
  }
);

const closeModal = () => {
  toggle.value = false;
  props.setOpen(false);
};
watch(toggle, (newValue) => props.setOpen(newValue));
</script>

<style scoped>
.card-container {
  background: #fafbff;
  border-radius: 5px;
  padding: 33px 27px;
  max-width: 1000px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.card-container::-webkit-scrollbar {
  display: none;
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /* identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
}
.table-header {
  min-height: 39px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2.5px solid #fff;
}
.table-header.amount {
  min-width: 300px !important;
}
.table-rows {
  background: #ffffff;
  border: 2.5px solid #fff;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input {
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input:focus {
  outline: none;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.delete-button {
  width: 50px;
  height: 44px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.gray {
  background: #deeded;
}
</style>

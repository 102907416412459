import { defineStore } from "pinia";
import { getErrorMessage, getWarningMessage } from "../../helpers/errorHandler";
import {
  nonIFRSProfitLossData,
  noneIFRSExceptionalKeys,
  nonIFRSPLTotalKeys,
  nonIFRSPLGroupData,
} from "../../helpers/non-ifrs.profit-loss";
import fetchWrapper from "../../helpers/fetchWrapper";
import { Toast } from "../../helpers/toast";
import {
  nonIFRSIncomeData,
  nonIFRSOtherIncomeData,
  nonIFRSDividenIncomeData,
  nonIfrsEmployeeBenefitsExpense,
  nonIfrsOtheExpenseData,
  nonIFRSLessExpenseData,
  nonIFRSExpensesData,
} from "../../components/entity/FinancialData/NON-IFRS/ProfitAndLoss/data";
import {
  getValueForCalc,
  nonIFRSDataStructure,
} from "../../helpers/non-ifrs.balance-sheet";
import fetchFileWrapper from "../../helpers/fileApi";

export const useNoneIFRSProfitLoss = defineStore("non-ifrs-profit-loss", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      loading: true,
      error: false,
      data: nonIFRSProfitLossData,
      groupData: nonIFRSPLGroupData(),
    };
  },
  getters: {
    directExpenses(state) {
      return nonIFRSExpensesData.reduce(
        (a, b) => a + getValueForCalc(state, b.model),
        0
      );
    },
    revenueFromOperation(state) {
      let sum = 0;
      for (let i = 0; i < nonIFRSIncomeData.length; i++) {
        const key = nonIFRSIncomeData[i].model;
        sum += getValueForCalc(state, key);
      }
      return sum;
    },
    dividendOtherIncome(state) {
      const sum = nonIFRSDividenIncomeData.other_income.reduce(
        (a, b) => a + Number(state.data.dividend_income[b.model] || 0),
        0
      );
      return sum;
    },
    dividenIncome(state) {
      return (route) => {
        if (route?.query?.business === "master") {
          return Number(state.groupData.dividend_income) || 0;
        }
        return (
          Number(
            state.data.dividend_income
              .dividends_and_other_profit_distributions_received || 0
          ) + this.dividendOtherIncome
        );
      };
    },
    otherIncome(state) {
      return (route) => {
        let sum = 0;
        for (let i = 0; i < nonIFRSOtherIncomeData.length; i++) {
          const key = nonIFRSOtherIncomeData[i].model;
          if (key === "dividend_income") {
            sum += this.dividenIncome(route);
          } else {
            sum += getValueForCalc(state, key);
          }
        }
        return sum;
      };
    },
    totalIncome() {
      return (route) => this.revenueFromOperation + this.otherIncome(route);
    },
    employeeBenefitExpense(state) {
      return (route) => {
        let sum1 = nonIfrsEmployeeBenefitsExpense.reduce((a, b) => {
          return a + getValueForCalc(state, b.model);
        }, 0);
        let sum2 =
          route?.query?.business === "master"
            ? Number(state.groupData.additional_employee_benefits_expneses)
            : Number(state.data.additional_employee_benefits_expneses.total);
        return sum1 + sum2;
      };
    },
    otherExpenses(state) {
      return (route) => {
        const sum1 = nonIfrsOtheExpenseData.reduce((a, b) => {
          return a + getValueForCalc(state, b.model);
        }, 0);
        const sum2 =
          route?.query?.business === "master"
            ? Number(state.groupData.additional_other_expenses)
            : Number(state.data.additional_other_expenses.total);
        return sum1 + sum2;
      };
    },
    earningBeforeInterest(state) {
      return (route) => {
        let sum =
          this.revenueFromOperation +
          this.otherIncome(route) -
          getValueForCalc(state, "cost_of_material_consumed") -
          getValueForCalc(state, "purchase_of_stock_in_trade") -
          getValueForCalc(
            state,
            "changes_in_inventories_of_finish_goods_wip_sit"
          ) -
          this.employeeBenefitExpense(route) -
          this.otherExpenses(route);
        return sum;
      };
    },
    finance_costs(state) {
      return (route) => {
        let sum1 = getValueForCalc(state, "interest_expense");
        let sum2 =
          route?.query?.business === "master"
            ? Number(state.groupData.additional_finance_cost)
            : Number(state.data.additional_finance_cost.total);
        return sum1 + sum2;
      };
    },
    earningBeforeTax() {
      return (route) =>
        this.earningBeforeInterest(route) - this.finance_costs(route);
    },
    lessExpense(state) {
      let sum = nonIFRSLessExpenseData.reduce((a, b) => {
        return a + getValueForCalc(state, b.model);
      }, 0);
      return sum;
    },
    earningAfterTax() {
      return (route) => this.earningBeforeTax(route) - this.lessExpense;
    },
  },
  actions: {
    getPayload(route) {
      const entity_id = route.params.id;
      const { financial_period, financial_year, business } = route.query;
      const payload = {
        ...this.data,
        revenue_from_operations: this.revenueFromOperation,
        dividend_income: this.data.dividend_income,
        other_income: this.otherIncome(route),
        total_income: this.totalIncome(route),
        direct_expenses: this.directExpenses,
        employee_benefits_expense: this.employeeBenefitExpense(route),
        other_expenses: this.otherExpenses(route),
        ebitda: this.earningBeforeInterest(route),
        finance_costs: this.finance_costs(route),
        earning_before_tax: this.earningBeforeTax(route),
        tax_expense: this.lessExpense,
        earning_after_tax: this.earningAfterTax(route),
        entity_business_id: business,
        entity_id,
        financial_year,
        financial_period,
      };
      return payload;
    },
    handleKeys(response, business) {
      let plsheet = {};
      if (business === "master") {
        for (let x in this.groupData) {
          plsheet[x] = response[x];
        }
      } else {
        Object.keys(response).forEach((key) => {
          if (key === "dividend_income") {
            if (!response[key]) {
              plsheet[key] = nonIFRSProfitLossData.dividend_income;
            } else plsheet[key] = response[key];
          } else if (nonIFRSPLTotalKeys(key)) {
            plsheet[key] = response[key];
          } else if (noneIFRSExceptionalKeys(key)) {
            if (response[key]) {
              plsheet[key] = response[key];
            } else plsheet[key] = { data: [], total: "" };
          } else {
            if (!response[key]) {
              plsheet[key] = nonIFRSDataStructure;
            } else {
              if (typeof response[key] === "object") {
                plsheet[key] = response[key];
                if (!response[key]?.last_input) {
                  plsheet[key]["last_input"] = "input";
                } else if (!response[key]?.value) {
                  plsheet[key]["value"] = "";
                }
              } else {
                plsheet[key] =
                  JSON.parse(response[key]) || nonIFRSDataStructure;
              }
            }
          }
        });
      }
      return plsheet;
    },
    async getNoneIFRProfitLoss(
      entity_id,
      financial_year,
      financial_period,
      current_business
    ) {
      try {
        if (entity_id && financial_year && financial_period) {
          this.loading = true;
          const res = await fetchWrapper.get(
            `/non_ifrs_profit_loss?entity_id=${entity_id}&financial_period=${financial_period}&financial_year=${financial_year}${
              current_business === "master"
                ? ""
                : "&entity_business_id=" + current_business
            }`
          );

          if (res.data.result === 1) {
            const response = await res?.data?.data;
            if (current_business !== "master") {
              this.data = await this.handleKeys(response, current_business);
            } else {
              this.groupData = await this.handleKeys(
                response,
                current_business
              );
            }
            this.error = false;
            this.loading = false;
          } else {
            this.data = nonIFRSProfitLossData;
            this.error = getWarningMessage(res);
            this.loading = false;
          }
        }
      } catch (err) {
        console.log(err);
        this.error = getErrorMessage(err);
        this.loading = false;
      }
    },
    async saveNonIFRSProfitLoss(
      entity_id,
      financial_year,
      financial_period,
      current_business,
      callBack = () => {}
    ) {
      const toast = new Toast("Saving...");
      try {
        if (current_business === "master") {
          toast.makeErrorToast("You can't save in master");
          return;
        }
        if (financial_year && financial_period && entity_id) {
          const payload = this.getPayload({
            params: { id: entity_id },
            query: {
              financial_year,
              financial_period,
              business: current_business,
            },
          });
          const res = await fetchWrapper.post(`/non_ifrs_profit_loss`, payload);
          if (res.data?.result === 1) {
            toast.makeSuccessToast(res.data?.message);
            callBack(res?.data, toast);
          } else {
            toast.makeErrorToast(getWarningMessage(res));
          }
        }
      } catch (err) {
        console.log(err?.message);
        toast.makeErrorToast(getErrorMessage(err));
      }
    },
    async uploadProfitAndLossFile(
      route,
      File,
      callBack = () => {},
      toast = new Toast("Uploading file....")
    ) {
      try {
        const entity_id = route.params.id;
        const { financial_period, financial_year, business } = route.query;
        if (File && financial_year && financial_period) {
          let data = new FormData();
          data.append("file", File);
          data.append("entity_id", entity_id);
          data.append("entity_business_id", business || 0);
          data.append("financial_year", financial_year);
          data.append("financial_period", financial_period);
          const res = await fetchFileWrapper.post(
            "/import_non_ifrs_profit_loss",
            data
          );
          if (res.data.result == 1) {
            toast.makeSuccessToast(res.data.message);
            callBack(res.data.entity_business_id);
            return;
          } else toast.makeWarningToast(getWarningMessage(res));
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(getErrorMessage(err));
      }
    },
    async autoSaveProfitLoss(route, setSavingStatus, callBack = () => {}) {
      try {
        const payload = this.getPayload(route);
        const res = await fetchWrapper.post(`/non_ifrs_profit_loss`, payload);
        if (res.data.result == 1) {
          setSavingStatus("loading", false);
          setSavingStatus("error", false);
          setSavingStatus("success", true);
          callBack(res.data.entity_business_id);
        } else {
          setSavingStatus("loading", false);
          setSavingStatus("error", getWarningMessage(res));
          setSavingStatus("success", false);
          setTimeout(() => {
            setSavingStatus("loading", false);
            setSavingStatus("error", false);
            setSavingStatus("success", false);
          }, 3000);
        }
      } catch (err) {
        console.log(err);
        setSavingStatus("loading", false);
        setSavingStatus("error", getErrorMessage(err));
        setSavingStatus("success", false);
        setTimeout(() => {
          setSavingStatus("loading", false);
          setSavingStatus("error", false);
          setSavingStatus("success", false);
        }, 3000);
      }
    },
  },
});

export const paymentOrder = {
  ref: "0CAC3E89A06D5572C799FC0C07EE13BB0E459ECAD4F3A05F988C1C41A1DE5334",
  cartid: "16901998792",
  test: 1,
  amount: "9000.00",
  currency: "AED",
  description: "Plan A",
  status: {
    code: 3,
    text: "Paid"
  },
  transaction: {
    ref: "030030904138",
    date: "24 Jul 2023 15:59 GST",
    type: "sale",
    class: "ECom",
    status: "A",
    code: "92590628",
    message: "Authorised"
  },
  paymethod: "Card",
  card: {
    type: "Visa Credit",
    last4: "1111",
    country: "AE",
    first6: "411111",
    expiry: {
      month: 7,
      year: 2023
    }
  },
  customer: {
    email: "dagi.zewdu.dz@gmail.com",
    name: {
      forenames: "Dagic Solutions",
      surname: "PLC"
    },
    address: {
      line1: "Test 1",
      city: "Dubai",
      country: "AE",
      areacode: "1000",
      mobile: "1234567966"
    }
  }
};

<template>
  <SideNavContainer title="Change password">
    <form class="row mt-4" @submit="handleSubmit">
      <div class="col-lg-4 mt-4">
        <CustomTextInput
          v-model="data.current_password"
          label="Current Password"
          placeholder="Enter Current Password"
          autocomplete="new-password"
          type="password"
          :error-message="error.current_password"
        />
      </div>
      <div class="col-lg-4 mt-4">
        <CustomTextInput
          v-model="data.new_password"
          label="New Password"
          autocomplete="new-password"
          type="password"
          :error-message="error.new_password"
          :add-suggestion="true"
          :check-validity="changeValidity"
        />
      </div>
      <div class="col-lg-4 mt-4">
        <CustomTextInput
          v-model="data.confirm_password"
          label="Confirm Password"
          placeholder="Enter Confirm Password"
          type="password"
          autocomplete="new-password"
          :error-message="error.confirm_password"
          :disabled="!checkPasswordValidity"
          @paste="(e) => e.preventDefault()"
        />
      </div>
      <div class="col-lg-12 mt-4">
        <div class="w-100 d-flex align-items-center flex-wrap">
          <button
            :class="`btn-log-in mt-2 ${
              data.new_password != data.confirm_password
                ? 'disabled'
                : !data.new_password || !data.confirm_password
                ? 'disabled'
                : ''
            }`"
            type="submit"
            :disabled="
              data.new_password != data.confirm_password ||
              !data.new_password ||
              !data.confirm_password
            "
          >
            Change Password
          </button>
        </div>
      </div>
    </form>
  </SideNavContainer>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useProfileStore } from "../../store/profile";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import SideNavContainer from "../layout/SideNavContainer.vue";
import { CustomTextInput } from "../common";

const name = ref("");
const access = ref("");
const profileStore = useProfileStore();
const { profile } = storeToRefs(profileStore);
const router = useRouter();
const data = ref({
  current_password: "",
  confirm_password: "",
  new_password: "",
});
const error = ref({
  current_password: "",
  confirm_password: "",
  new_password: "",
});
const statusMessage = ref({
  message: "",
  type: "info",
});
const checkPasswordValidity = ref(false);
const changeValidity = (value) => {
  checkPasswordValidity.value = value;
};
const setName = async () => {
  name.value = `${profile.value.data?.first_name} ${profile.value.data?.last_name}`;
  access.value = profile.value.data?.type;
};
onMounted(async () => {
  await setName();
});
const handleSubmit = async (e) => {
  e.preventDefault();
  for (let x in data.value) {
    if (!data.value[x]) {
      error.value[x] = `Please enter ${x}`;
      return;
    }
  }
  await profileStore.changePassword(
    {
      new_password: data.value.new_password,
      current_password: data.value.current_password,
    },
    async () => {
      await profileStore.getUserProfileSilently();
      setTimeout(() => {
        router.push("/profile");
      }, 2000);
    }
  );
};
watch(
  data,
  (newValue) => {
    for (let x in newValue) {
      if (x !== "confirm_password") error.value[x] = ``;
    }
  },
  { deep: true }
);
watch(
  () => data.value.confirm_password,
  (newValue) => {
    if (newValue !== data.value.new_password && newValue) {
      error.value.confirm_password = "Password is not matched";
    } else {
      error.value.confirm_password = "";
    }
  }
);
</script>

<style scoped>
.cont {
  min-height: 80vh;
}
.wrapper {
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;
  min-height: 500px;
  background: #fafbff;
  border-radius: 5px;
}
.welcome {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.full-vh {
  height: 125vh;
}
.log {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  letter-spacing: 0.055em;
  color: #000000;
}
.btn-log-in {
  height: 55px;
  background: #0f75bc;
  border-radius: 4px;
  padding: 15px 75px 16px;
  color: #fafbff;
}
.btn-log-in.disabled {
  opacity: 0.5;
}
.or {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #382628;
}
.btn-log-otp {
  width: 196px;
  height: 55px;
  background: #ffffff;
  border-radius: 4px;
  color: #747171;
  border: 1px solid #747171;
  border-radius: 4px;
}
.gap-100 {
  gap: 70px;
}
.new-snaptax {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #747171;
}
.create-account {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-decoration: none;
  color: #000000;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>

<template>
  <CustomModalContainer
    :open="open"
    :set-open="setOpen"
    min-width="400px"
    :on-close="setCloseMessage"
    persistent
  >
    <iframe
      v-if="iFrameUrl"
      class="payment-iframe"
      :src="iFrameUrl"
      title="description"
    ></iframe>
  </CustomModalContainer>
</template>

<script setup>
import { CustomModalContainer } from "../../common";

const props = defineProps({
  open: Boolean,
  setOpen: {
    type: Function,
    default: () => {},
  },
  iFrameUrl: {
    type: String,
    default: "",
  },
  setStatusMessage: {
    type: Function,
    default: () => {},
  },
  toast: {
    type: Object,
    default: () => {},
  },
});
const setCloseMessage = async () => {
  console.log("toast", props.toast);
  await props.toast.makeWarningToast("Payment popup closed");
  await localStorage.removeItem("order_id");
};
</script>

<style scoped>
.payment-iframe {
  height: 80vh;
  width: 720px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.payment-iframe::-webkit-scrollbar {
  display: none;
}
</style>

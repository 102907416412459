<template>
  <CustomModalContainer :open="modal" :set-open="setModal" :is-square="true">
    <h4 class="text-center bold">Privacy Policy</h4>
    <p class="intro">{{ policy.privacyPolicyTitleDesc }}</p>
    <div
      v-for="(item, index) in policy.policyData"
      :key="item.id"
      class="mt-2 px-10"
    >
      <p class="description-text">
        <span class="bold">{{ `${index + 1}. ${item.title}` }}</span>
        {{ item.description }}
      </p>
    </div>
    <h4 class="text-center bold">Terms and Conditions</h4>
    <p class="intro">
      {{ policy.termsConditionsDesc }}
    </p>
    <h4 class="intro bold text-info">LIMITED LICENSE</h4>
    <p class="intro">{{ policy.limitedLicenseDesc }}</p>
    <div
      v-for="(item, index) in policy.limitedLicense"
      :key="item.id"
      class="mt-2 px-10"
    >
      <p class="description-text">
        <span class="bold">{{ `${index + 1}. ${item.title}` }}</span>
        {{ item.description }}
      </p>
      <div v-for="subItem in item.sub" :key="subItem.id" class="mt-2 px-10">
        <p class="description-text">
          <span class="bold">{{ `* ${subItem.title}` }}</span>
          {{ subItem.description }}
        </p>
      </div>
    </div>
    <div
      v-for="item in policy.otherPrivacyPolicy"
      :key="item.id"
      class="mt-2 mb-5"
    >
      <p class="description-text">
        <span class="bold">{{ `${item.title}` }}</span>
        {{ item.description }}
      </p>
    </div>
    <div
      class="check-box-wrapper mt-4 px-2 py-4 d-flex align-items-center justify-content-center"
    >
      <input
        id="item-afdas"
        type="checkbox"
        class="terms-check-box"
        checked
        @click="handleClick"
      />
      <p class="mb-0 ml-4 check-box-text">
        I have read and agree to the
        <span class="bold">Terms and Conditions</span> and
        <span class="bold">Privacy Policy</span>
      </p>
    </div>
  </CustomModalContainer>
</template>

<script setup>
import { ref } from "vue";
import { CustomModalContainer } from "../common";
import {
  policyData,
  privacyPolicyTitleDesc,
  termsConditionsDesc,
  limitedLicenseDesc,
  limitedLicense,
  otherPrivacyPolicy,
} from "./termsAndPolicyData";

const props = defineProps({
  modal: {
    type: Boolean,
    default: false,
  },
  setModal: {
    type: Function,
    default: () => {},
  },
});
const policy = ref({
  policyData,
  privacyPolicyTitleDesc,
  termsConditionsDesc,
  limitedLicenseDesc,
  limitedLicense,
  otherPrivacyPolicy,
});
const handleClick = async (e) => {
  e.preventDefault();
};
</script>

<style scoped>
.terms-wrapper {
  height: 100vh;
}
.check-box-wrapper {
  min-height: 60px;
  width: 100%;
  position: sticky;
  bottom: -35px;
  background: #deeded;
}
.intro {
  color: #747171;
  text-align: justify;
}
.description-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  text-align: justify;
  color: #747171;
}

</style>

<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <div class="wrapper d-flex mr-10 cursor-pointer" v-bind="props">
        <p class="bell-icon mb-0">&#x1F514;</p>
        <div
          v-if="!!notification.data.length"
          class="counter-container bg-danger d-flex align-items-center justify-content-center"
        >
          <p class="counter text-white mb-0">
            <b> {{ notification.data.length }} </b>
          </p>
        </div>
      </div>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in notification.data"
        :key="item.entity_id"
        :value="index"
      >
        <v-list-item-title>{{ item.alert_message }}</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="!notification.data.length">
        <v-list-item-title> <b> No notifications yet </b></v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup>
import { onMounted, ref, onUnmounted, computed, watch } from "vue";
import { useFilingPlannerStore } from "../../store/filingPlanner";
import { storeToRefs } from "pinia";
import { useProfileStore } from "../../store/profile";

const fillingPlannerStore = useFilingPlannerStore();
const { notification } = storeToRefs(fillingPlannerStore);
const interval = ref("");
const profileStore = useProfileStore();
const { profile } = storeToRefs(profileStore);
const isConsultant = computed(() => {
  if (!profile.value.data) {
    return false;
  }
  return profile.value.data?.type === "Consultant";
});
const getNotification = async () => {
  await fillingPlannerStore.getNotification();

  interval.value = setInterval(async () => {
    await fillingPlannerStore.getNotification();
  }, 60 * 1000 * 5);
};
onMounted(async () => {
  if (isConsultant.value) {
    await getNotification();
  }
});
watch(isConsultant, async (value) => {
  if (value) {
    await getNotification();
  } else {
    if (interval.value) clearInterval(interval.value);
  }
});
onUnmounted(() => {
  clearInterval(interval.value);
});
</script>

<style scoped>
.bell-icon {
  font-size: 24px;
}
.counter-container {
  margin-top: -8px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
}
.counter {
  font-size: 12px;
}
</style>

<template>
  <div class="row wrapper px-2 mt-4">
    <div class="col-lg-12 mt-4">
      <h4 class="title">Banking Details</h4>
    </div>
    <div class="col-lg-4 col-md-6">
      <CustomTextInput
        v-model="general_information.bank_name"
        label="Name of Bank"
        placeholder="Name of Bank"
        disabled
      />
    </div>
    <div class="col-lg-4 col-md-6">
      <CustomTextInput
        v-model="general_information.branch_name"
        label="Name of Branch"
        placeholder="Name of Branch"
        disabled
      />
    </div>
    <div class="col-lg-4 col-md-6">
      <CustomTextInput
        v-model="general_information.swift_code"
        placeholder="Swift Code"
        label="Swift Code"
        disabled
      />
    </div>
    <div class="col-lg-4 col-md-6 mt-4">
      <CustomTextInput
        v-model="general_information.branch_address"
        placeholder="Branch Address"
        label="Branch Address"
        disabled
      />
    </div>
    <div class="col-lg-4 col-md-6 mt-4">
      <CustomSelectInput
        v-model="general_information.account_type"
        label="Type of Account"
        placeholder="Account Type"
        disabled
        :options="['Current', 'Saving']"
        :other-props="{ disabled: true }"
      />
    </div>
    <div class="col-lg-4 col-md-6 mt-4">
      <CustomTextInput
        v-model="general_information.account_holder_name"
        label="Name of Account"
        placeholder="Name of Account"
        disabled
      />
    </div>
    <div class="col-lg-4 col-md-6">
      <CustomTextInput
        v-model="general_information.account_no"
        label="Account Number "
        placeholder="Account Number"
        disabled
      />
    </div>
    <div class="col-lg-4 col-md-6">
      <CustomTextInput
        v-model="general_information.iban_no"
        type="text"
        label="IBAN Number"
        placeholder="IBAN Number"
        disabled
      />
    </div>
  </div>
</template>

<script setup>
import { CustomSelectInput, CustomTextInput } from "../../common";
import { useGeneralInfoStore } from "../../../store/generalInformation";
import { storeToRefs } from "pinia";
const generalInfoStore = useGeneralInfoStore();
const { general_information } = storeToRefs(generalInfoStore);
</script>

<style lang="scss" scoped>
.title {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.88px;
}
</style>

<template>
  <div class="w-100">
    <div class="header d-flex align-items-center w-100 justify-content-between">
      <p class="header-title mb-0">Other Income</p>
      <p class="header-title mb-0">
        {{ NumberFormatter(otherIncome($route)) }}
      </p>
    </div>
    <div v-for="(item, index) in modelData" :key="item.model" class="w-100">
      <ClickableInputOrPopUp
        v-if="item.model !== 'dividend_income'"
        :model-name="item.model"
        :name="item.name"
        :sub="item.sub"
        :show-input="item.showInput"
        :show-popup="item.showPopup"
        :on-tab-and-enter="handleTabAndEnter"
        :bg-color="!index || !(index % 2) ? 'white' : 'gray'"
        :set-saving-status="setSavingStatus"
        tab="Income"
      />
      <DividenIncome
        v-else-if="item.model === 'dividend_income'"
        :set-saving-status="setSavingStatus"
      />
    </div>
    <div
      class="header d-flex align-items-center w-100 justify-content-between mb-4"
    >
      <p class="header-title mb-0">TOTAL INCOME</p>
      <p class="header-title mb-0">
        {{ NumberFormatter(totalIncome($route)) }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
import { nonIFRSOtherIncomeData } from "./data";
import { storeToRefs } from "pinia";
import { useNoneIFRSProfitLoss } from "../../../../../store/NonIFRS/profitLoss.store";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";
import ClickableInputOrPopUp from "./InputWithPop.vue";
import DividenIncome from "./DividenIncome.vue";
defineProps({
  setSavingStatus: {
    type: Function,
    default: () => {},
  },
});
const modelData = ref(nonIFRSOtherIncomeData);
const nonIfrsPlStore = useNoneIFRSProfitLoss();
const { data, otherIncome, totalIncome } = storeToRefs(nonIfrsPlStore);
const handleTabAndEnter = (model) => {
  // get the next value
  const nextModel =
    modelData.value[modelData.value.map((e) => e.model).indexOf(model) + 1];
  if (nextModel && nextModel !== "dividend_income") {
    if (data.value[model].last_input === "input") {
      const newModelData = modelData.value.map((item) => {
        if (item.model === nextModel.model) {
          return { ...item, showInput: true };
        }
        return { ...item, showInput: false };
      });
      modelData.value = newModelData;
    } else if (data.value[model].last_input === "popup") {
      const newModelData = modelData.value.map((item) => {
        if (item.model === nextModel.model) {
          return { ...item, showPopup: true };
        }
        return { ...item, showPopup: false };
      });
      modelData.value = newModelData;
    }
  }
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}
.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}
.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}
.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}
</style>

<template>
  <div class="w-100">
    <ShowStatus :modal="editStatus" :set-modal="setStatus" />
    <CustomTable
      :Data="data"
      :Headers="headers"
      :actions="actions"
      :haveIndex="true"
      :active="active"
    />
  </div>
</template>

<script setup>
import CustomTable from "@/components/common/CustomTable.vue";
import { storeToRefs } from "pinia";
import { generate } from "randomized-string";
import { ref, onMounted } from "vue";
import tableEdit from "../../../../assets/svg/table-edit.svg";
import tableDelete from "../../../../assets/svg/trash.svg";
import { useCountryStore } from "../../../../store/country";
import { useEntityStore } from "../../../../store/entity";
import { useRouter, useRoute } from "vue-router";
import { dateMDY } from "../../../../helpers/date";
import { deleteEntity } from "../../../../store/entityActions/delete";
import ShowStatus from "./ShowStatus.vue";

const headers = ref([
  "Owner Name",
  "Address",
  "Emirates ID",
  "Contact Number",
  "Email",
  "Gender",
  // "Designation",
  "Nationality",
  "Passport Number",
  "Passport Expiry Date",
  "Source of Authorization",
  "Cessation Date",
  "Active",
]);
const countryStore = useCountryStore();
const { countries } = storeToRefs(countryStore);
const entityStore = useEntityStore();
const { entity } = storeToRefs(entityStore);
const router = useRouter();
const route = useRoute();
const editStatus = ref(false);
const setStatus = (status) => {
  editStatus.value = status;
};
const actions = ref([
  {
    id: generate(),
    icon: tableEdit,
    onClick: (subId) => {
      if (entity.value?.data?.entity[0]?.file_return_status === "Filed") {
        setStatus(true);
        return;
      }
      router.push(
        `/entity/add-entity/branch-details/${route.params.id}/${subId}`
      );
    },
  },
  {
    id: generate(),
    icon: tableDelete,
    onClick: async (id) => {
      if (confirm("Are you sure you want to delete this branch detail?")) {
        await deleteEntity(
          id,
          "/entityBranch",
          (message) => {},
          () => location.reload()
        );
      }
    },
  },
]);

const data = ref([]);

onMounted(async () => {
  if (countries.value.data.length) {
    await countryStore.getCountryList();
  }
  const ownerList = [];
  entity.value.data?.branch?.forEach((branch) => {
    branch?.entity_branch_owner.forEach((branchOwner) => {
      ownerList.push({ ...branchOwner, branch_id: branch?.id });
    });
  });

  data.value = ownerList.map((owner) => ({
    id: owner?.branch_id,
    director_name: owner?.director_name,
    address: owner?.address,
    emirates_id: owner?.emirates_id,
    contact_no: owner?.contact_no,
    email: owner?.email,
    gender: owner?.gender,
    // designation: "",
    nationality: countries.value.data.find(
      (country) => country?.id === owner?.nationality
    )?.name,
    passport_no: owner?.passport_no,
    passport_expiry_date: dateMDY(owner?.passport_expiry_date),
    source_of_authorization: owner?.source_of_authorization,
    cessation_date: dateMDY(owner?.cessation_date),
    active: owner?.active,
  }));
});
</script>

<style scoped></style>

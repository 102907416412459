<template>
  <AlertModal
    :open="open"
    message="Warning: All entries on the balance sheet and Profit/Loss of this business will be permanently deleted."
    sub-message="Are you sure you want to proceed?"
    :on-confirm="DeleteBusiness"
    :on-cancel="onCancel"
    button-name="Delete"
  />
</template>

<script setup>
import { useBusinessStore } from "../../../../../store/buisness.store";
import AlertModal from "../../../../common/AlertModal.vue";
import { useRoute } from "vue-router";
// import { useRoute } from "vue-router";
const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  open: {
    type: Boolean,
    default: false,
  },
  setOpen: {
    type: Function,
    default: () => {},
  },
  callBackAfterDelete: {
    type: Function,
    default: () => {},
  },
});
const onCancel = () => props.setOpen(false);
const businessStore = useBusinessStore();
const route = useRoute();
// const route = useRoute();
const DeleteBusiness = async () => {
  await businessStore.deleteBusiness(props.id, async () => {
    await businessStore.getBusiness(
      route.params.id,
      route.query.financial_year,
      route.query.financial_period
    );
    await props.callBackAfterDelete();
  });
  props.setOpen(false);
};
</script>

<style scoped></style>

import { defineStore } from "pinia";
import {
  basicDataStructure,
  basicDataStructures
} from "../helpers/storeStructure";
import fetchWrapper from "../helpers/fetchWrapper";
import { Toast } from "../helpers/toast";

export const useEntityStore = defineStore("entity", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      entities: {
        ...basicDataStructures
      },
      entity: {
        ...basicDataStructure
      },
      type: {
        ...basicDataStructures
      },
      subType: {
        ...basicDataStructures
      },
      businessActivity: {
        ...basicDataStructures
      },
      businessSubActivity: {
        ...basicDataStructures
      },
      financialYear: {
        ...basicDataStructures
      },
      documentType: {
        ...basicDataStructures
      },
      designation: {
        ...basicDataStructures
      },
      ownershipDetails: {
        ...basicDataStructures
      },
      contactDetails: {
        ...basicDataStructures
      },
      OwnerDetails: {
        ...basicDataStructures
      },
      DirectorDetails: {
        ...basicDataStructures
      },
      ShareholderDetails: {
        ...basicDataStructures
      },
      BankDetails: {
        ...basicDataStructures
      },
      financialPeriod: {
        ...basicDataStructures
      },
      licenseAuthorityType: {
        ...basicDataStructures
      },
      licenseAuthority: {
        ...basicDataStructures
      },
      industries: {
        ...basicDataStructures
      },
      mainGroups: {
        ...basicDataStructures
      },
      subGroups: {
        ...basicDataStructures
      },
      activities: {
        ...basicDataStructures
      },
      entityAuthorizedSignatory: {
        ...basicDataStructures
      },
      entityBranch: {
        ...basicDataStructures
      },
      entityDeclaration: {
        ...basicDataStructure
      }
    };
  },
  actions: {
    makeStateLoading(s) {
      this[s].loading = true;
    },
    addItemsToState(state, payload) {
      this[state].data = payload;
      this[state].error = false;
      this[state].loading = false;
    },
    makeErrorToState(state, payload) {
      this[state].error = payload;
      this[state].loading = false;
    },
    async makeBankAccountPrimary(id, callBack = () => {}) {
      let toast = new Toast("Saving....");
      try {
        let res = await fetchWrapper.get(`/makePrimary?bank_id=${id}`);
        if (res.data?.result === 1) {
          callBack(res?.data, toast);
        } else {
          toast.makeErrorToast(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message
          );
        }
      } catch (err) {
        console.log(err?.response?.data?.message || "");
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    },
    async makeContactPrimary(id, callBack = () => {}) {
      let toast = new Toast("Saving....");
      try {
        let res = await fetchWrapper.get(
          `/makeRegistered?entity_contact_details_id=${id}`
        );
        if (res.data?.result === 1) {
          toast.makeSuccessToast("Successfully updated...");
          callBack(res.data, toast);
        } else {
          toast.makeErrorToast(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message
          );
        }
      } catch (err) {
        console.log(err?.message);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    }
  }
});

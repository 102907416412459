import { generate } from "randomized-string";

export const fzpCalcRevenueData = [
  {
    id: generate(), //
    name: "Total Revenue of Freezone Person (1)",
    sub: false,
    showInput: false,
    model: "total_revenue_of_QFZP"
  },
  {
    id: generate(), // 1
    name: "Revenue Attributable for Domestic Permanent Establishment out of (1)",
    sub: false,
    showInput: false,
    model: "revenue_attributable_for_domestic"
  },
  {
    id: generate(), // 2
    name: "Revenue Attributable for Foreign Permanent Establishment out of (1)",
    sub: false,
    showInput: false,
    model: "revenue_attributable_for_foreign"
  },
  {
    id: generate(), // 3
    name: "Revenue derived from transactions with Non Free Zone Persons related to Immovable Commercial Property located in a Free Zone",
    sub: false,
    showInput: false,
    model: "revenue_commercial_property_located"
  },
  {
    id: generate(), //4
    name: "Revenue derived from transactions with any Person related to property in a Free Zone not being a Commercial Property",
    sub: false,
    showInput: false,
    model: "revenue_derived_transactions_with_person_related_fz_ncp"
  },
  {
    id: generate(), //5
    name: "Revenue derived from transactions with any Person related to Non Commercial Property in a Free Zone",
    sub: false,
    showInput: false,
    model: "revenue_derived_transactions_with_person_related_ncp_fz"
  },
  {
    id: generate(), //6
    name: "Revenue derived from Non Qualifying Intellectual Property",
    sub: false,
    showInput: false,
    model: "revenue_derived_non_qualifying_intellectual_property"
  },
  {
    id: generate(), //7
    name: "Excess Income derived from Qualifying Intellectual Property",
    sub: false,
    showInput: false,
    model: "excess_income_derived_from_qualifying_intellectual_property"
  }
];

export const fzpCalcExpenseData = [
  {
    id: generate(), // Total
    name: "Total Expenses of Freezone Person  (2)",
    sub: false,
    showInput: false,
    model: "total_expenses_of_qfzp"
  },
  {
    id: generate(), // 1
    name: "Expenses Attributable for Domestic Permanent Establishment out of (2)",
    sub: false,
    showInput: false,
    model: "expenses_attributable_for_domestic"
  },
  {
    id: generate(), // 2
    name: "Expenses Attributable for Foreign Permanent Establishment out of (2)",
    sub: false,
    showInput: false,
    model: "expenses_attributable_for_foreign_PE"
  },
  {
    id: generate(), // 3
    name: "Expenses from Commercial Property located In Freezone, from Natural Person & Non-Freezone Person out of (2)",
    sub: false,
    showInput: false,
    model: "expenses_commercial_property_located_in_FZP"
  },
  {
    id: generate(), // 4
    name: "Expenses from Non-Commercial Property located in Freezone, from any person out of (2)",
    sub: false,
    showInput: false,
    model: "expenses_from_non_commercial_property_located_in_FZP"
  },
  {
    id: generate(), // 5
    name: "Expenses related to transactions with any Person related to Non Commercial Property in a Free Zone.",
    sub: false,
    showInput: false,
    model: "expenses_in_transactions_person_related_ncp_fz"
  },
  {
    id: generate(), // 6
    name: "Expenses related to and for earning Income from Non Qualifying Intellectual Property",
    sub: false,
    showInput: false,
    model: "expenses_related_earning_income_NQIP"
  }
];

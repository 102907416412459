<template>
  <LoaderContainer :loading="loading" :error="error">
    <div class="w-100 vh">
      <div class="row g-2">
        <div class="col-lg-12">
          <TitleAndDate title="Welcome to SnapTax" />
          <div class="row mt-2">
            <div class="col-lg-12">
              <CalculatorContainer />
            </div>
            <div class="col-lg-12 mt-2">
              <RevenueComparison
                :revenue-comparison="revenueComparison"
                :labels="labels"
                :current-year-profit="data.revenue_profit.current_year_profit"
                :current-year-revenue="data.revenue_profit.current_year_revenue"
                :comparison-percent="data.revenue_comparison.comparison_percent"
              />
            </div>
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-7 mt-2">
                  <div class="w-100 chart-container p-2">
                    <h4 class="title my-2">Last 5 years income</h4>
                    <Last5YearsChart
                      v-if="data.taxable_income_chart"
                      :taxable-income-chart="data.taxable_income_chart"
                    />
                  </div>
                </div>
                <div class="col-lg-5 mt-2">
                  <div class="w-100 chart-container p-2">
                    <h4 class="title my-2">
                      Assets and Liabilities Comparison
                    </h4>
                    <AssetsAndLiabilitiesComparison
                      :asset-liabilities-comparison="
                        data.asset_liabilities_comparison
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="`${
                profile.data.has_subscription === 'Y'
                  ? 'col-lg-12'
                  : 'col-lg-12'
              }`"
            ></div>
          </div>
        </div>
        <!-- <div class="col-lg-3">
          <ChatBox />
        </div> -->
        <div class="col-lg-12 mt-2">
          <FederalTax />
        </div>
      </div>
    </div>
    <Marquee :texts="data.marquee_text" />
  </LoaderContainer>
</template>

<script setup>
import TitleAndDate from "../common/TitleAndDate.vue";
import RevenueComparison from "../common/RevenueComparison.vue";
import { useProfileStore } from "../../../store/profile";
import { useDahboardStore } from "../../../store/dashboard";
import { storeToRefs } from "pinia";
import LoaderContainer from "../../common/LoaderContainer.vue";
import { CalculatorContainer } from "../../calculator/";
import { onMounted, ref, watch } from "vue";
import Last5YearsChart from "../common/Last5YearsChart.vue";
import AssetsAndLiabilitiesComparison from "../common/AssetsAndLiabilitiesComparison.vue";
import Marquee from "../../common/Marquee.vue";
import FederalTax from "../consultant/FederalTax.vue";

const profileStore = useProfileStore();
const { profile } = storeToRefs(profileStore);
const dashboardStore = useDahboardStore();
const { data, loading, error } = storeToRefs(dashboardStore);
const revenueComparison = ref([]);
const labels = ref([]);
watch(
  () => data.value.revenue_comparison,
  (newValue) => {
    const Data = [];
    for (let x in newValue) {
      if (x !== "comparison_percent") {
        labels.value.push(x);
        Data.push(newValue[x]);
      }
    }
    revenueComparison.value = Data;
  },
  { deep: true }
);
onMounted(async () => {
  await dashboardStore.getDasboardItems();
  for (let x in data.value.revenue_comparison) {
    if (x !== "comparison_percent") {
      labels.value.push(x);
      revenueComparison.value.push(data.value.revenue_comparison[x]);
    }
  }
});
</script>

<style scoped>
.vh {
  min-height: 125vh;
}
.company-list {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.add-new {
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #000000;
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 33px;
  letter-spacing: 0.055em;
  color: #000000;
}
.chart-container {
  border: 1px solid rgba(116, 113, 113, 0.2);
  box-shadow: 4px 4px 10px 2px rgba(187, 187, 187, 0.25);
  border-radius: 5px;
  background: #deeded;
}
</style>

<template>
  <Loader v-if="loading" />
  <div class="w-100" v-else>
    <ShowStatus :modal="editStatus" :set-modal="setStatus" />

    <CustomTable
      :Data="data"
      :Headers="headers"
      :actions="actions"
      :have-index="true"
    />
  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable.vue";
import { storeToRefs } from "pinia";
import { ref, onMounted } from "vue";
import { useEntityStore } from "../../../../store/entity";
import {
  getEntityItem,
  getEntityAttribute,
} from "../../../../store/entityActions/get";
import { useRoute, useRouter } from "vue-router";
import { useCountryStore } from "../../../../store/country";
import Loader from "@/components/common/Loader.vue";
import { dateMDY } from "../../../../helpers/date";
import { generate } from "randomized-string";
import tableEdit from "@/assets/svg/table-edit.svg";
import tableDelete from "../../../../assets/svg/trash.svg";
import { deleteEntity } from "../../../../store/entityActions/delete";
import ShowStatus from "./ShowStatus.vue";

export default {
  name: "AuthorizedSignatoryDetails",
  components: { CustomTable, Loader, ShowStatus },
  setup() {
    const headers = ref([
      "First Name",
      "Last Name",
      "Mobile Number",
      "Email",
      "Designation",
      "Resident of the UAE?",
      "Emirates ID Number",
      "Nationality",
      "Passport Number",
      "Passport Issue Date",
      "Passport Expiry Date",
      "Source of Authorization",
      "Court where Power of Attorney was Executed",
      "Date of Execution of Power of Attorney",
      "Upload Power of Attorney",
    ]);
    const data = ref([]);
    const loading = ref(true);
    const route = useRoute();
    const router = useRouter();
    const entityStore = useEntityStore();
    const countryStore = useCountryStore();
    const { entityAuthorizedSignatory, entity } = storeToRefs(entityStore);
    const editStatus = ref(false);
    const setStatus = (status) => {
      editStatus.value = status;
    };
    const actions = ref([
      {
        id: generate(),
        icon: tableEdit,
        onClick: async (id) => {
          if (entity.value?.data?.entity[0]?.file_return_status === "Filed") {
            setStatus(true);
            return;
          }

          router.push(
            `/entity/add-entity/authorized-signatory-details/${route.params.id}/${id}`
          );
        },
      },
      {
        id: generate(),
        icon: tableDelete,
        onClick: async (id) => {
          if (
            confirm("Are you sure you want to delete this director detail?")
          ) {
            await deleteEntity(
              id,
              "/entityAuthorizedSignatory",
              (message) => {},
              () => location.reload()
            );
          }
        },
      },
    ]);
    onMounted(async () => {
      loading.value = true;
      await getEntityItem(
        "entityAuthorizedSignatory",
        "/entityAuthorizedSignatory",
        route.params.id
      );
      const Data = [];
      for (let i = 0; i < entityAuthorizedSignatory.value.data?.length; i++) {
        const details = entityAuthorizedSignatory.value.data[i];
        const countryName = await countryStore.getCountryName(
          details?.nationality
        );
        const item = {
          first_name: details?.first_name,
          id: details?.id,
          last_name: details?.last_name,
          mobile_no: details?.mobile_no,
          email: details?.email,
          Designation: details?.designation,
          is_from_uae: details?.is_from_uae == "Y" ? "Yes" : "No",
          emirates_id_number: details?.emirates_id_number.toString(),
          nationality: countryName,
          passport_no: details?.passport_no,
          passport_issue_date: details?.passport_issue_date,
          passport_expiry_date: details?.passport_expiry_date,
          source_of_authorization: details?.source_of_authorization,
          place_of_power_excution: details?.place_of_power_excution,
          power_attorney_excution_date: details?.power_attorney_excution_date,
          //imagePath: details?.imagePath,
          power_of_attorney_file: {
            type: "image",
            url: details?.power_of_attorney_file
              ? `${details?.imagepath}/${details?.power_of_attorney_file}`
              : null,
          },
        };
        Data.push(item);
      }
      data.value = Data;
      loading.value = false;
    });
    return {
      headers,
      data,
      entityAuthorizedSignatory,
      loading,
      actions,
      editStatus,
      setStatus,
    };
  },
};
</script>

<template>
  <div class="cont d-flex align-items-center">
    <form
      class="wrapper w-100 d-flex flex-column justify-content-evenly align-items-center"
      @submit="handleSubmit"
    >
      <h2 class="log">Enter new Password</h2>
      <AccountInput
        v-model="data.new_password"
        label="New Password"
        placeholder="Enter your new password"
        autocomplete="new-password"
        type="password"
        :error-message="error.new_password"
      />
      <AccountInput
        v-model="data.confirm_password"
        label="Confirm Password"
        placeholder="Enter confirm password"
        type="password"
        autocomplete="new-password"
        :error-message="error.confirm_password"
        @paste="(e) => e.preventDefault()"
      />
      <p
        v-if="statusMessage.message"
        :class="`my-2 text-${statusMessage.type}`"
      >
        {{ statusMessage.message }}
      </p>
      <div class="d-flex align-items-center flex-wrap justify-content-center">
        <button
          :class="`btn-log-in mt-2 ${
            data.new_password != data.confirm_password
              ? 'disabled'
              : !data.new_password || !data.confirm_password
              ? 'disabled'
              : ''
          }`"
          type="submit"
          :disabled="
            data.new_password != data.confirm_password ||
            !data.new_password ||
            !data.confirm_password
          "
        >
          Update password
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import AccountInput from "../common/AccountInput.vue";
import { useProfileStore } from "../../store/profile";
import { useRoute, useRouter } from "vue-router";
const route = useRoute();
const router = useRouter();
const profileStore = useProfileStore();
const data = ref({
  confirm_password: "",
  new_password: "",
});
const error = ref({
  confirm_password: "",
  new_password: "",
});
const statusMessage = ref({
  message: "",
  type: "info",
});

const handleSubmit = async (e) => {
  e.preventDefault();
  for (let x in data.value) {
    if (!data.value[x]) {
      error.value[x] = `Please enter ${x}`;
      return;
    }
  }
  await profileStore.resetPassword(
    {
      token: route.query.token,
      password: data.value.new_password,
      password_confirmation: data.value.confirm_password,
    },
    (message, type) => {
      statusMessage.value.message = message;
      statusMessage.value.type = type;
    },
    async () => {
      setTimeout(() => {
        router.push(`/account/signin`);
      }, 2000);
    }
  );
};
watch(
  data,
  (newValue) => {
    for (let x in newValue) {
      error.value[x] = ``;
    }
  },
  { deep: true }
);
</script>

<style scoped>
.cont {
  min-height: 125vh;
}
.wrapper {
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;
  min-height: 500px;
  background: #fafbff;
  border-radius: 5px;
}
.welcome {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.full-vh {
  height: 125vh;
}
.log {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  letter-spacing: 0.055em;
  color: #000000;
}
.btn-log-in {
  height: 55px;
  background: #0f75bc;
  border-radius: 4px;
  padding: 15px 75px 16px;
  color: #fafbff;
}
.btn-log-in.disabled {
  opacity: 0.5;
}
.or {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #382628;
}
.btn-log-otp {
  width: 196px;
  height: 55px;
  background: #ffffff;
  border-radius: 4px;
  color: #747171;
  border: 1px solid #747171;
  border-radius: 4px;
}
.gap-100 {
  gap: 70px;
}
.new-snaptax {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #747171;
}
.create-account {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-decoration: none;
  color: #000000;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>

<template>
  <div class="w-100">
    <PaymentPopup
      :open="open"
      :set-open="setOpen"
      :i-frame-url="paymentIntegration.url"
      :set-status-message="setStatusMessage"
      :toast="toast"
    />
    <p
      v-if="statusMessage.message"
      :class="`my-2 text-${statusMessage.type} text-center`"
    >
      {{ statusMessage.message }}
    </p>
    <button
      class="btn-checkout"
      :disabled="!companyName"
      @click="proceedPayment"
    >
      Check Out
    </button>
  </div>
</template>

<script setup>
// import { useRoute } from "vue-router";
// import { usePaymentStore } from "../../../store/payment.store";
import { ref, watch } from "vue";
import PaymentPopup from "./PaymentPopup.vue";
import { useSubscriptionStore } from "../../../store/subscription";
import { storeToRefs } from "pinia";
import { usePaymentStore } from "../../../store/payment.store";
import { useRoute } from "vue-router";
import { Toast } from "../../../helpers/toast";

const props = defineProps({
  subscription: {
    type: Object,
    required: true,
  },
  price: {
    type: Number,
    default: 0,
  },
  vatNumber: {
    type: String,
    default: "",
  },
  isAdditional: {
    type: Boolean,
    default: false,
  },
  entityCount: {
    type: Number,
    default: 1,
  },
  companyName: {
    type: String,
    default: "",
  },
});
const subScriptionStore = useSubscriptionStore();
const paymentStore = usePaymentStore();
const toast = ref();
const { subscriptions } = storeToRefs(subScriptionStore);
const setStatusMessage = (message, type) => {
  statusMessage.value.message = message;
  statusMessage.value.type = type;
};
const paymentIntegration = ref({
  ref_id: "",
  url: "",
});
const statusMessage = ref({
  message: "",
  type: "info",
});
const open = ref(false);
const setOpen = (value) => {
  open.value = value;
};

// const paymentStore = usePaymentStore();
// const route = useRoute();
const proceedPayment = async () => {
  toast.value = await new Toast("Initiating Payment...");
  const vat =
    subscriptions.value.is_vat_applicable === "Y" ? subscriptions.value.vat : 0;
  const sub_total = Number(vat) * props.price + props.price;
  const host = window.location.origin;
  const payload = {
    company_name: props.companyName,
    subscription_id: props?.subscription,
    vat_number: props.vatNumber,
    coupon_code: "",
    discount_price: 0,
    sub_total: props.price,
    vat: subscriptions.value.vat || 0,
    total: sub_total,
    return_auth: `${host}/payment/completed`,
    return_decl: `${host}/payment/declined`,
    return_can: `${host}/payment/canceled`,
  };
  if (props.isAdditional) {
    await paymentStore.makeAnOrderForAdditionalEntity(
      { ...payload, entity_count: props.entityCount },
      (data) => {
        paymentIntegration.value.ref_id = data?.order_id;
        localStorage.setItem("order_id", data?.order_id);
        paymentIntegration.value.url = data?.url;
        setOpen(true);
      },
      toast.value
    );
    return;
  }
  await paymentStore.makeAnOrder(
    payload,
    (data) => {
      paymentIntegration.value.ref_id = data?.order_id;
      localStorage.setItem("order_id", data?.order_id);
      paymentIntegration.value.url = data?.url;
      setOpen(true);
    },
    toast.value
  );
};
</script>

<style scoped>
.btn-checkout {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
  width: 133px;
  height: 40px;
  background: #0f75bc;
  border-radius: 5px;
}
.btn-checkout:disabled {
  opacity: 0.5;
}
</style>

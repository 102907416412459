<template>
  <div class="w-100">
    <DesktopSideNav
      v-if="toggle"
      :side-nav-type="sideNavType"
      :set-toggle="setToggle"
    />
    <TabletSideNav
      v-else-if="width >= 600 && !toggle"
      :side-nav-type="sideNavType"
    />
    <div id="main">
      <Navbar
        :show-entity-name="sideNavType === 'financialData'"
        :set-toggle="setToggle"
        :toggle="toggle"
        :loading="savingStatus.loading"
        :error="savingStatus.error"
        :success="savingStatus.success"
      />
      <div class="main-container w-100">
        <div
          v-if="!!title"
          class="title-container d-flex align-items-center justify-content-between"
        >
          <h3 class="title">
            {{ title ? title : "" }}
            <br />
            <p v-if="subtitle" class="subtitle mb-0">{{ subtitle }}</p>
          </h3>
          <component :is="TitleRightSide" v-if="TitleRightSide" />
        </div>
        <div class="slot w-100">
          <slot
            :toggle="toggle"
            :set-toggle="setToggle"
            :set-saving-status="setSavingStatus"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onUnmounted, onMounted, reactive } from "vue";
import DesktopSideNav from "./DesktopSideNav.vue";
import Navbar from "./Navbar.vue";
import TabletSideNav from "./TabletSideNav.vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useProfileStore } from "../../store/profile";

const props = defineProps([
  "title",
  "TitleRightSide",
  "subtitle",
  "sideNavType",
]);
const width = ref(window.innerWidth);
const toggle = ref(false);
const Main = ref();
const profileStore = useProfileStore();
const { profile } = storeToRefs(profileStore);
const router = useRouter();
const savingStatus = reactive({
  loading: false,
  error: false,
  success: false,
});
function setToggle(open) {
  const main = document.getElementById("main");
  const navbar = document.getElementById("snaptax-navbar");

  if (window.innerWidth > 600 && main) {
    if (open) {
      navbar.style.paddingRight = "370px";
      document.getElementById("main").style.marginLeft = "321px";
    } else {
      navbar.style.paddingRight = "200px";
      document.getElementById("main").style.marginLeft = "150px";
    }
  }
  toggle.value = open;
}
const handleResize = () => {
  const main = document.getElementById("main");
  const navbar = document.getElementById("snaptax-navbar");
  width.value = window.innerWidth;
  if (window.innerWidth > 990 && main) {
    toggle.value = true;
    navbar.style.paddingRight = "370px";
    main.style.marginLeft = "321px";
  } else if (window.innerWidth <= 990 && window.innerWidth > 600) {
    toggle.value = false;
    navbar.style.paddingRight = "200px";
    main.style.marginLeft = "150px";
  } else {
    toggle.value = false;
    main.style.marginLeft = "0px";
  }
};
const setSavingStatus = (type, value) => {
  savingStatus[type] = value;
};

const checkUser = (status) => {
  if (status === 401) {
    setTimeout(() => {
      router.push("/account/signin");
    }, 3000);
    return;
  }
  router.push("/account/signin");
};
onMounted(async () => {
  window.addEventListener("resize", handleResize);
  if (window.innerWidth <= 992) {
    toggle.value = false;
  }
  if (profile.value.data) {
    await profileStore.getUserProfileSilently(async (status) => {
      checkUser(status);
    });
  }
});
onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});
</script>

<style scoped>
#main {
  transition: margin-left 0.5s;
  min-height: 125vh;
  margin-left: 150px;
  animation: fadeIn 5s;
}
@media screen and (max-width: 992px) {
  #main {
    margin-left: 150px;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  #main {
    margin-left: 0;
  }
}
.main-container {
  padding: 83px 41px 0 17px;
  min-height: 125vh;
}
.title-container {
  padding: 25px 10px 16px 10px;
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.055em;
  color: #000000;
}
.slot {
  min-height: 83vh;
}
.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
@media screen and (max-width: 600px) {
  .main-container {
    padding: 83px 10px 5px 10px;
  }
}
</style>

<template>
  <Loader v-if="loading" />
  <div v-else class="w-100">
    <ShowStatus :modal="editStatus" :set-modal="setStatus" />
    <p
      v-if="statusMessage.message"
      :class="`my-2 text-${statusMessage.type} text-center`"
    >
      {{ statusMessage.message }}
    </p>
    <CustomTable
      :Data="data"
      :Headers="headers"
      :actions="actions"
      :haveIndex="true"
      v-slot="slotProps"
    >
      <td class="table-rows align-middle">
        <v-switch
          :model-value="
            contactDetails.data[slotProps.index].is_registered === 'Y'
          "
          color="#0F75BC"
          inset
          hide-details
          readonly
          @click="
            (e) =>
              setPrimaryAccount(
                contactDetails.data[slotProps.index].id,
                contactDetails.data[slotProps.index].is_registered
              )
          "
        ></v-switch>
      </td>
    </CustomTable>
  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable.vue";
import { ref } from "vue";
import { generate } from "randomized-string";
import tableEdit from "../../../../assets/svg/table-edit.svg";
import tableDelete from "../../../../assets/svg/trash.svg";
import { useEntityStore } from "../../../../store/entity";
import { getEntityItem } from "@/store/entityActions/get";
import { useCountryStore } from "../../../../store/country";
import { storeToRefs } from "pinia";
import Loader from "@/components/common/Loader.vue";
import { useRouter, useRoute } from "vue-router";
import { deleteEntity } from "../../../../store/entityActions/delete";
import { getEntityItems } from "../../../../store/entityActions/get";
import { useGeneralInfoStore } from "../../../../store/generalInformation";
import ShowStatus from "./ShowStatus.vue";

export default {
  name: "ContactDetails",
  components: { CustomTable, Loader, ShowStatus },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const entityStore = useEntityStore();
    const countryStore = useCountryStore();
    const { contactDetails, documentType, entity } = storeToRefs(entityStore);
    const { countries } = storeToRefs(countryStore);
    const generalInfoStore = useGeneralInfoStore();
    const editStatus = ref(false);
    const setStatus = (status) => {
      editStatus.value = status;
    };
    const headers = ref([
      "Place of establishment",
      "Nationality",
      "ID Document",
      "ID Document File",
      "ID Document Number",
      "Mobile Number",
      "Landline Number",
      "Email",
      "Registered Address",
      "Business Address in UAE",
      "Is primary contact",
    ]);
    const actions = ref([
      {
        id: generate(),
        icon: tableEdit,
        onClick: (id) => {
          if (entity.value?.data?.entity[0]?.file_return_status === "Filed") {
            setStatus(true);
            return;
          }
          router.push(
            `/entity/add-entity/contact-details/${route.params.id}/${id}`
          );
        },
      },
      {
        id: generate(),
        icon: tableDelete,
        onClick: async (id) => {
          if (confirm("Are you sure you want to delete this contact?")) {
            await deleteEntity(
              id,
              "/entityContactDetails",
              (message) => {},
              () => location.reload()
            );
          }
        },
      },
    ]);

    return {
      headers,
      actions,
      contactDetails,
      countries,
      countryStore,
      documentType,
      entityStore,
      generalInfoStore,
      editStatus,
      setStatus,
    };
  },
  data() {
    return {
      data: [],
      loading: true,
      statusMessage: {
        message: "",
        type: "info",
      },
    };
  },
  async created() {
    const id = this.$route.params.id;
    await getEntityItem("contactDetails", "/entityContactDetails", id);
    if (!this.countries.data.length) {
      await this.countryStore.getCountryList();
    }
    if (!this.documentType.data?.length) {
      await getEntityItems("documentType", "/documentType");
    }
    const getDocType = (id) => {
      if (!id) {
        return "";
      }
      const doc = this.documentType.data?.find((d) => d.id == id);
      if (doc) {
        return doc?.name;
      }
      return "";
    };

    this.data = this.contactDetails.data?.map((contact) => {
      let docType = getDocType(contact?.id_doc_type);
      return {
        id: contact?.id,
        name: contact?.place_of_establishment?.name || "",
        nationality:
          this.countries.data.find(
            (country) => country?.id === contact?.nationality
          )?.name || "",
        id_doc_type: docType || "",
        id_doc_file: {
          type: "image",
          url: contact?.id_doc_file
            ? `${contact?.imagepath}/${contact?.id_doc_file}`
            : null,
        },
        id_doc_no: contact?.id_doc_no || "",
        mobile_no: contact?.mobile_no || "",
        phone_no: contact?.phone_no || "",
        email: contact?.email || "",
        registered_address: contact?.registered_address || "",
        business_address:
          `${contact?.business_address ?? ""} ${
            contact?.state_id?.name ?? ""
          } ${contact?.po_box_no ?? ""}` || "",
      };
    });
    this.loading = false;
  },
  methods: {
    async setPrimaryAccount(id, value) {
      const newVal = value === "Y" ? "N" : value === "N" ? "Y" : "";
      if (newVal === "Y") {
        const financial_year = localStorage.getItem("financial_year");
        await this.entityStore.makeContactPrimary(id, async (data, toast) => {
          toast.makeInfoToast("Getting the updated value");
          await getEntityItem(
            "contactDetails",
            "/entityContactDetails",
            this.$route.params.id
          );
          await this.generalInfoStore.getGeneralInformation(
            this.$route.params.id,
            financial_year
          );
          toast.makeSuccessToast("Update success");
        });
      }
    },
    setStatusMessage(message, type) {
      this.statusMessage.message = message;
      if (type) {
        this.statusMessage.type = type;
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <LoaderContainer :loading="profile.loading" :error="error">
    <slot />
  </LoaderContainer>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useProfileStore } from "../../store/profile";
import LoaderContainer from "../common/LoaderContainer.vue";
import { computed, onMounted, ref } from "vue";

const profileStore = useProfileStore();
const { profile } = storeToRefs(profileStore);
const nonPrivateRoutes = ref([
  "/account/signup",
  "/account/signin",
  "/account/login-otp",
  "/account/forget-password",
]);
const error = computed(() => {
  const isPublicRoute = nonPrivateRoutes.value.includes(location.pathname);
  if (isPublicRoute) {
    return false;
  }
  return profile.error;
});
const checkUser = (status) => {
  const isPublicRoute = nonPrivateRoutes.value.includes(location.pathname);
  if (isPublicRoute) {
    profile.value.loading = false;
    return;
  }
  if (status === 401) {
    setTimeout(() => {
      location.href = "/account/signin";
    }, 3000);
    return;
  }
  location.href = "/account/signin";
};
onMounted(async () => {
  profile.value.loading = true;
  const token = await sessionStorage.getItem("Token");
  if (!token) {
    await checkUser(400);
    return;
  }
  await profileStore.getUserProfile(async (status) => {
    checkUser(status);
  });
});
</script>

<style scoped></style>

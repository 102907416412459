<template>
  <div class="w-100">
    <div class="header d-flex align-items-center w-100 justify-content-between">
      <p class="header-title mb-0">Trade and other receivables</p>
      <p class="header-title mb-0">
        {{ NumberFormatter(totalCurrentAssets.trade_and_other_rcvbls) }}
      </p>
    </div>
    <form
      v-for="(item, index) in data"
      :id="item.model"
      :key="item.id"
      :class="`w-100 table-body ${!index || !(index % 2) ? 'white' : 'gray'} ${
        item.sub ? 'sub' : ''
      } d-flex align-items-center justify-content-between`"
      @submit="handleSubmit"
    >
      <p class="mb-0 body-title">
        {{ item.name }}
      </p>
      <p
        v-if="!item.showInput"
        :class="`body-title ${isHeader(item.model) ? 'bold' : ''} mb-0`"
        @click="openCloseInput(item.model, true)"
      >
        {{ NumberFormatter(currentAssetData[item.model]) }}
      </p>

      <input
        :id="'input-' + item.model"
        v-model="currentAssetData[item.model]"
        :hidden="!item.showInput"
        type="number"
        class="clickable-text"
        @focus="setOriginalValue(item.model, currentAssetData[item.model])"
        @blur="handleBlur(item.model, false)"
        @keydown.tab="(e) => handleTab(e, item.model)"
      />
    </form>
  </div>
</template>

<script setup>
import { generate } from "randomized-string";
import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useFinancialDataStore } from "../../../../../../store/financial.store";
import { NumberFormatter } from "../../../../../../helpers/numberFormatter";
import { useRoute, useRouter } from "vue-router";
import { autoSaveCurrentAssets } from "../helper";
const props = defineProps({
  view: {
    type: Boolean,
    default: false,
  },
  setSavingStatus: {
    type: Function,
    default: () => {},
  },
});
const route = useRoute();
const router = useRouter();
const financialDataStore = useFinancialDataStore();
const { currentAssetData, totalCurrentAssets } =
  storeToRefs(financialDataStore); //get initialvalue from store
const data = ref([
  {
    id: generate(),
    name: "Trade Receivables",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "trade_and_other_rcvbls_trd_rcvbls",
  },
  {
    id: generate(),
    name: "Associates",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "trade_and_other_rcvbls_trd_rcvbls_associates",
  },
  {
    id: generate(),
    name: "Subsidiaries",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "trade_and_other_rcvbls_trd_rcvbls_subsidiaries",
  },
  {
    id: generate(),
    name: "Non-related parties",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "trade_and_other_rcvbls_trd_rcvbls_non_related_parties",
  },
  {
    id: generate(),
    name: "Allowances (-)",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "trade_and_other_rcvbls_trd_rcvbls_allowances",
  },
  {
    id: generate(),
    name: "Loan to associates",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "trade_and_other_receivables_loan_to_associates",
  },
  {
    id: generate(),
    name: "Finance lease receivables",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "trade_and_other_receivables_finance_lease_receivables",
  },

  {
    id: generate(),
    name: "Staff loans",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "trade_and_other_receivables_staff_loans",
  },
  {
    id: generate(),
    name: "Government grant receivables",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "trade_and_other_receivables_government_grant_receivables",
  },

  {
    id: generate(),
    name: "Deposits",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "trade_and_other_receivables_deposites",
  },
  {
    id: generate(),
    name: "Prepayments",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "trade_and_other_receivables_prepayments",
  },

  {
    id: generate(),
    name: "Other receivables",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "trade_and_other_receivables_other_receivables",
  },
]);
const isHeader = (key) => {
  return key === "trade_and_other_rcvbls_trd_rcvbls";
};
const getValue = (key) => {
  return Number(currentAssetData.value[key]) || 0;
};
currentAssetData.value.trade_and_other_rcvbls_trd_rcvbls = computed(() => {
  const keys = [
    "trade_and_other_rcvbls_trd_rcvbls_associates",
    "trade_and_other_rcvbls_trd_rcvbls_subsidiaries",
    "trade_and_other_rcvbls_trd_rcvbls_non_related_parties",
    "trade_and_other_rcvbls_trd_rcvbls_allowances",
  ];
  return keys.reduce((a, b) => {
    if (b === "trade_and_other_rcvbls_trd_rcvbls_allowances") {
      return a - getValue(b);
    }
    return a + getValue(b);
  }, 0);
});
totalCurrentAssets.value.trade_and_other_rcvbls = data.value.reduce((a, b) => {
  if (b.model === "trade_and_other_rcvbls_trd_rcvbls") {
    return a;
  } else if (b.model === "trade_and_other_rcvbls_trd_rcvbls_allowances") {
    return a - getValue(b.model);
  }
  return a + getValue(b.model);
}, 0);

watch(
  currentAssetData,
  (newValue) => {
    totalCurrentAssets.value.trade_and_other_rcvbls = data.value?.reduce(
      (a, b) => {
        if (b.model === "trade_and_other_rcvbls_trd_rcvbls") {
          return a;
        } else if (b.model === "trade_and_other_rcvbls_trd_rcvbls_allowances") {
          return a - Number(newValue[b.model] || 0);
        }
        return a + Number(newValue[b.model] || 0);
      },
      0
    );
  },
  { deep: true }
);

const openCloseInput = async (model, value) => {
  if (props.view || route.query.business === "master" || isHeader(model)) {
    return;
  }
  data.value = data.value.map((item) => {
    if (item.model === model) {
      return { ...item, showInput: value };
    }
    return item;
  });
  if (value) {
    const input = await document.getElementById(`input-${model}`);
    input.focus();
  }
};
const handleSubmit = async (e) => {
  e.preventDefault();
  let current_model = e.target.id;
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
const handleTab = async (e, current_model) => {
  e.preventDefault();
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
const autoSave = async (model) => {
  const previousValue = data.value.find((item) => item.model === model).value;
  const currentValue = currentAssetData.value[model];
  await autoSaveCurrentAssets(
    previousValue,
    currentValue,
    props.setSavingStatus,
    route,
    router
  );
};
const handleBlur = async (model, openClose) => {
  await openCloseInput(model, openClose);
  await autoSave(model);
};
const setOriginalValue = (key, value) => {
  const index = data.value.findIndex((item) => item.model === key);
  data.value[index].value = value;
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}
</style>

import { defineStore } from "pinia";
import { basicDataStructure } from "../helpers/storeStructure";
import { QFZCOIdata } from "../constants/QFZCOI.data";
import fetchWrapper from "../helpers/fetchWrapper";
import { getErrorMessage, getWarningMessage } from "../helpers/errorHandler";

export const useQfzpCoiStore = defineStore("qfz-coi", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      ...basicDataStructure,
      data: QFZCOIdata,
      color: QFZCOIdata,
      status: {
        status: "",
        button: ""
      }
    };
  },
  getters: {},
  actions: {
    async reseteData() {
      this.loading = await true;
      this.error = await false;
      this.data = await QFZCOIdata;
      this.color = await QFZCOIdata;
      this.status = await {
        status: "",
        button: ""
      };
    },
    async getQfzpcoiData(entity_id, financial_year, financial_period) {
      try {
        if (!entity_id || !financial_year || !financial_period) {
          return;
        }
        const res = await fetchWrapper.get(
          `/qfzpCOI?entity_id=${entity_id}&financial_year=${financial_year}&financial_period=${financial_period}`
        );
        if (res?.data?.result == 1) {
          this.data = await res.data.data;
          this.color = await res.data?.color;
          for (let x in res.data.status) {
            this.status[x] = await Number(res.data.status[x]);
          }
          this.error = await false;
          this.loading = await false;
        } else {
          await this.reseteData();
          this.error = getWarningMessage(res);
          this.loading = false;
        }
      } catch (err) {
        console.log(err);
        await this.reseteData();
        this.error = getErrorMessage(err);
        this.loading = false;
      }
    }
  }
});

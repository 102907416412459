export const naturalPersonHeaders = [
  { key: "type", label: "Type" },
  { key: "sub_type", label: "Sub Type" },
  { key: "entity_first_name", label: "Entity First Name" },
  { key: "entity_last_name", label: "Entity Last Name" },
  { key: "DOCOB", label: "Date of Commencement of Business" },
  { key: "trade_name", label: "Trade Name" }
];

export const legalPersonHeader = [
  { key: "type", label: "Type" },
  { key: "sub_type", label: "Sub Type" },
  { key: "name", label: "Entity Name" },
  { key: "DOI", label: "Date of Incorporation" },
  { key: "trade_name", label: "Trade Name" },
  { key: "place_of_incorporation", label: "Country of Incorporation" },
  {
    key: "qualifying_benefit_entity",
    label: "Qualifying Benefit Entity?"
  },
  {
    key: "decree_law_number",
    label: "Decree Law Number"
  },
  {
    key: "decree_law_number_file",
    label: "Decree Law"
  }
];
export const partnerShipHeaders = [
  { key: "type", label: "Type" },
  { key: "sub_type", label: "Sub Type" },
  { key: "name", label: "Entity Name" },
  { key: "DOI", label: "Date of Incorporation" },
  { key: "trade_name", label: "Trade Name" },
  { key: "place_of_incorporation", label: "Country of Incorporation" }
];

<template>
  <LoaderContainer :loading="loading">
    <div class="w-100 mt-3">
      <div
        class="header d-flex align-items-center w-100 justify-content-between"
      >
        <p class="header-title mb-0">Corporate Tax Return</p>
        <p class="header-title mb-0">Income</p>
      </div>
      <div
        class="w-100 table-body white cursor-pointer d-flex align-items-center justify-content-between"
        @click="$router.push(`/entity/general-information/${$route.params.id}`)"
      >
        <p class="mb-0 body-title">General Information</p>
      </div>
      <div
        class="w-100 table-body gray cursor-pointer d-flex align-items-center justify-content-between"
      >
        <p class="mb-0 body-title">Business Income - Financial Info</p>
        <p class="body-title mb-0" :style="{ color: color.business_income }">
          {{ NumberFormatter(data.business_income) }}
        </p>
      </div>
      <div
        class="w-100 table-body d-flex align-items-center justify-content-between"
      >
        <p class="mb-0 body-title w-75 bold">
          <b> Other Direct Taxable Income of QFZP </b>
        </p>
        <p
          class="body-title mb-0 bold"
          :style="{ color: color.other_direct_taxable_income_qfzp }"
        >
          {{ NumberFormatter(data.other_direct_taxable_income_qfzp) }}
        </p>
      </div>
    </div>
    <OtherDirectTaxable />
    <!-- <Chapters /> -->
    <div
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 total-amount">
        Total Net Qualifying Income for Current Year
      </p>
      <p
        class="mb-0 total-amount"
        :style="{ color: color.total_net_qualifying_income_current_year }"
      >
        {{ NumberFormatter(data["total_net_qualifying_income_current_year"]) }}
      </p>
    </div>

    <!-- <div
      class="w-100 table-body gray py-4 d-flex align-items-center justify-content-between"
    >
      <h5 class="mb-0 w-75 body-title">
        Brought Forward & carried Forward Loss Chapter-11
      </h5>
      <p
        class="body-title mb-0"
        :style="{ color: color.brought_frwd_carried_frwd_loss_chapter_11 }"
      >
        {{ NumberFormatter(data.brought_frwd_carried_frwd_loss_chapter_11) }}
      </p>
    </div>
     -->

    <div
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 total-amount">Total Income</p>
      <p class="mb-0 total-amount" :style="{ color: color.total_income }">
        {{ NumberFormatter(data.total_income) }}
      </p>
    </div>
    <div
      class="grand-total d-flex align-items-center justify-content-between mb-10"
    >
      <p class="mb-0 grand-total-amount">Tax Payable / Refundable</p>
      <p class="mb-0 grand-total-amount">
        {{ NumberFormatter(data.taxable_refundable) }}
      </p>
    </div>
  </LoaderContainer>
</template>

<script setup>
import { NumberFormatter } from "../../../helpers/numberFormatter";
import { LoaderContainer } from "../../common";
import Chapters from "./QFZPCOI/Chapters.vue";
import { useQfzpCoiStore } from "../../../store/QFZPCOI";
import { storeToRefs } from "pinia";
import OtherDirectTaxable from "./QFZPCOI/OtherDirectTaxable.vue";

const qfzpCoi = useQfzpCoiStore();
const { data, color, loading } = storeToRefs(qfzpCoi);
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}
.table-body.hover:hover {
  background: linear-gradient(90deg, #101432 0%, #00a7a7 100%);
  color: #fafbff !important;
}
.sub-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}

.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}

.total-container.h {
  min-height: 150px;
}

.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}

.clickable-text {
  text-align: right !important;
  border: none;
  border-bottom: 1px solid#000000;
  padding-bottom: 1px;
  padding-left: 4px;
  height: 100%;
}

.clickable-text:focus {
  outline: 0;
  border-bottom: 1px solid#0f75bc;
}

.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  background: #fafbff;
  padding-right: 100px;
  padding-left: 10px;
}

.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}

.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}

.total-desc {
  background: #deeded;
}
.p-10px {
  padding: 6px !important;
}
</style>

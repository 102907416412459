<template>
  <LoaderContainer :loading="loading">
    <div class="w-100">
      <ShowStatus :modal="editStatus" :set-modal="setStatus" />
      <p
        v-if="statusMessage.message"
        :class="`my-2 text-${statusMessage.type} text-center`"
      >
        {{ statusMessage.message }}
      </p>
      <CustomTable
        :Data="data"
        :Headers="headers"
        :actions="actions"
        :have-index="true"
        v-slot="slotProps"
      >
        <td class="table-rows align-middle">
          <v-switch
            :model-value="
              BankDetails.data[slotProps.index].primary_account === 'Y'
            "
            color="#0F75BC"
            inset
            hide-details
            readonly
            @click="
              (e) =>
                setPrimaryAccount(
                  BankDetails.data[slotProps.index].id,
                  BankDetails.data[slotProps.index].primary_account
                )
            "
          ></v-switch>
        </td>
      </CustomTable>
    </div>
  </LoaderContainer>
</template>

<script setup>
import CustomTable from "@/components/common/CustomTable.vue";
import { storeToRefs } from "pinia";
import { generate } from "randomized-string";
import tableEdit from "../../../../assets/svg/table-edit.svg";
import tableDelete from "../../../../assets/svg/trash.svg";
import { useEntityStore } from "../../../../store/entity";
import { getEntityItem } from "../../../../store/entityActions/get";
import { deleteEntity } from "../../../../store/entityActions/delete";
import { useGeneralInfoStore } from "../../../../store/generalInformation";
import LoaderContainer from "../../../common/LoaderContainer.vue";
import ShowStatus from "./ShowStatus.vue";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
const entityStore = useEntityStore();
const { BankDetails, entity } = storeToRefs(entityStore);
const route = useRoute();
const generalInfoStore = useGeneralInfoStore();
const headers = ref([
  "Name",
  "Branch",
  "Swift Code",
  "Branch Address",
  "Type",
  "Name",
  "Account No.",
  "IBAN No.",
  "Is primary account",
]);
const data = ref([]);
const router = useRouter();
const editStatus = ref(false);
const setStatus = (status) => {
  editStatus.value = status;
};
const actions = ref([
  {
    id: generate(),
    icon: tableEdit,
    onClick: (subId) => {
      if (entity.value?.data?.entity[0]?.file_return_status === "Filed") {
        setStatus(true);
        return;
      }
      router.push(
        `/entity/add-entity/banking-details/${route.params.id}/${subId}`
      );
    },
  },
  {
    id: generate(),
    icon: tableDelete,
    onClick: async (id) => {
      if (confirm("Are you sure you want to delete this bank detail?")) {
        await deleteEntity(
          id,
          "/entityBankDetails",
          (message) => {},
          () => location.reload()
        );
      }
    },
  },
]);
const loading = ref(false);
const statusMessage = ref({
  message: "",
  type: "info",
});
onMounted(async () => {
  const id = route.params.id;
  await getEntityItem("BankDetails", "/entityBankDetails", id);

  data.value = BankDetails.value.data.map((details) => ({
    id: details?.id,
    bank_name: details?.bank_name,
    branch_name: details?.branch_name,
    swift_code: details?.swift_code,
    branch_address: details?.branch_address,
    account_type: details?.account_type,
    account_holder_name: details?.account_holder_name,
    account_no: details?.account_no,
    iban_no: details?.iban_no,
  }));
  loading.value = false;
});
const setPrimaryAccount = async (id, value) => {
  const newVal = value === "Y" ? "N" : value === "N" ? "Y" : "";
  if (newVal === "Y") {
    const financial_year = localStorage.getItem("financial_year");

    await entityStore.makeBankAccountPrimary(id, async (data, toast) => {
      toast.makeInfoToast("Getting the updated value", "info");
      await getEntityItem("BankDetails", "/entityBankDetails", route.params.id);
      await generalInfoStore.getGeneralInformation(
        route.params.id,
        financial_year
      );
      toast.makeSuccessToast("Successfully updated...");
    });
  }
};
</script>

<style lang="scss" scoped></style>

import { defineStore } from "pinia";

export const useCTaxCalculatorIndividualStore = defineStore(
  "CTaxCalculatorIndividual",
  {
    // arrow function recommended for full type inference
    state: () => {
      return {
        receipts: [
          {
            id: "total_receipts_for_the_year",
            name: "Total Receipts for the Year",
            amount: ""
          },
          {
            id: "revenue_from_rent_investment_salary_wages",
            name: "Revenue from Rent, Investment, Salary & Wages",
            amount: ""
          }
        ],
        expense: [
          {
            id: "total_profits_for_the_year",
            name: "Total Profits for the Year",
            amount: ""
          },
          {
            id: "add_back_expenses_disallowed",
            name: "Add back expenses disallowed",
            amount: ""
          },
          {
            id: "exempt_income_from_rent_investment",
            name: "Exempt Income-Income from Rent, Investment, Salary & Wages",
            amount: ""
          }
        ]
      };
    },
    getters: {
      netReceiptsDuringTheYearAmount(state) {
        return (
          Number(state.receipts[0].amount) - Number(state.receipts[1].amount)
        );
      },
      netReceiptsDuringCalculatedFields() {
        if (this.netReceiptsDuringTheYearAmount <= 1000000) {
          return "Revenue less than 1MN AED, hence Exempt";
        }
        return this.netReceiptsDuringTheYearAmount;
      },
      totalProfitsForTheYear(state) {
        if (
          this.netReceiptsDuringCalculatedFields ===
          "Revenue less than 1MN AED, hence Exempt"
        ) {
          return 0;
        }
        return Number(state.expense[0].amount);
      },
      addBackExpensesDisallowed(state) {
        if (
          this.netReceiptsDuringCalculatedFields ===
          "Revenue less than 1MN AED, hence Exempt"
        ) {
          return 0;
        }
        return Number(state.expense[1].amount);
      },
      exemptIncomeFromRentInvestment(state) {
        if (
          this.netReceiptsDuringCalculatedFields ===
          "Revenue less than 1MN AED, hence Exempt"
        ) {
          return 0;
        }
        return Number(state.expense[2].amount);
      },
      taxableIncome() {
        const total =
          this.totalProfitsForTheYear +
          this.addBackExpensesDisallowed -
          this.exemptIncomeFromRentInvestment;
        return total;
      },
      exemption() {
        if (this.taxableIncome > 0) {
          return 375000;
        }
        return 0;
      },
      taxLiablity() {
        const total = (this.taxableIncome - this.exemption) * 0.09;
        if (total < 0) {
          return 0;
        }
        return total;
      }
    }
  }
);

<template>
  <div
    class="d-flex align-items-center"
    @click="$router.push('/payment/transactions')"
  >
    <h4 class="add-new mb-0">&#x293E; Transaction History</h4>
  </div>
</template>

<script setup></script>

<style scoped>
.add-new {
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

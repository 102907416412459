import fetchWrapper from "@/helpers/fetchWrapper";
import { defineStore } from "pinia";
import { basicDataStructures } from "../helpers/storeStructure";

export const useCountryStore = defineStore("country", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      countries: {
        ...basicDataStructures
      },
      states: {
        ...basicDataStructures
      }
    };
  },
  actions: {
    async getCountryList() {
      try {
        this.countries.loading = true;
        const res = await fetchWrapper.get("/countryList?to");
        this.countries.data = res.data?.data;
        this.countries.error = false;
        this.countries.loading = false;
      } catch (err) {
        console.log(err);
        this.countries.error = err?.response?.data?.message || "";
        this.countries.loading = false;
      }
    },
    async getStateList(id) {
      try {
        this.states.loading = true;
        const res = await fetchWrapper.get("/countryList/" + id);
        this.states.data = res.data?.data;
        this.states.error = false;
        this.states.loading = false;
      } catch (err) {
        console.log(err);
        this.states.error = err?.response?.data?.message || "";
        this.states.loading = false;
      }
    },
    async getCountryName(id) {
      if (!this.countries.data.length) {
        await this.getCountryList();
      }
      const country = this.countries.data.find(
        (c) => Number(c?.id) === Number(id)
      );
      return country ? country?.name : "";
    }
  }
});

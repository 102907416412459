<template>
  <Loader v-if="loading" />
  <div class="w-100" v-else>
    <ShowStatus :modal="editStatus" :set-modal="setStatus" />

    <CustomTable
      :Data="data"
      :Headers="headers"
      :actions="actions"
      :have-index="true"
    />
  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable.vue";
import { storeToRefs } from "pinia";
import { generate } from "randomized-string";
import { ref } from "vue";
import tableEdit from "../../../../assets/svg/table-edit.svg";
import tableDelete from "../../../../assets/svg/trash.svg";
import { useRouter, useRoute } from "vue-router";
import { deleteEntity } from "../../../../store/entityActions/delete";
import { dateMDY } from "../../../../helpers/date";
import ShowStatus from "./ShowStatus.vue";
import { useEntityStore } from "../../../../store/entity";
import { removeKeyFromObject } from "../../../../helpers/removeDuplicates";
export default {
  name: "TaxDetails",
  components: { CustomTable, ShowStatus },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const headers = ref([
      "Registered Tax Group",
      "Financial Period",
      "VAT TRN Details",
      "Corporate Tax TRN Details",
      "VAT Certificate",
      "Corporate Tax Certificate",
      "Issue Date",
    ]);
    const editStatus = ref(false);
    const setStatus = (status) => {
      editStatus.value = status;
    };
    const actions = ref([
      {
        id: generate(),
        icon: tableEdit,
        onClick: (id) => {
          if (entity.value?.data?.entity[0]?.file_return_status === "Filed") {
            setStatus(true);
            return;
          }
          router.push(
            `/entity/add-entity/entity-details/tax-details/${route.params.id}/${id}`
          );
        },
      },
      {
        id: generate(),
        icon: tableDelete,
        onClick: async (id) => {
          if (confirm("Are you sure you want to delete this tax details ?")) {
            await deleteEntity(
              id,
              "/entityTax",
              () => {},
              () => {
                location.reload();
              }
            );
          }
        },
      },
    ]);
    const entityStore = useEntityStore();
    const { entity, businessActivity } = storeToRefs(entityStore);
    return {
      headers,
      actions,
      entity,
      businessActivity,
      editStatus,
      setStatus,
    };
  },
  data() {
    return {
      data: [],
      loading: true,
    };
  },
  created() {
    const id = this.$route.params.id;
    const findEntity = this.entity.data?.entity?.find(
      (e) => Number(e.id) === Number(id)
    );
    this.data = this.entity?.data?.tax?.map((e) => {
      const item = {
        id: e?.id,
        reg_tax_group: e?.reg_tax_group,
        financial_period: e?.financial_period?.name,
        var_trn_details: e?.var_trn_details,
        corp_tax_trn_details: e?.corp_tax_trn_details,
        vat_certificate: {
          type: "image",
          url: e?.vat_certificate
            ? `${e?.imagepath}/${e?.vat_certificate}`
            : null,
        },
        corporate_tax: {
          type: "image",
          url: e?.corporate_tax ? `${e?.imagepath}/${e?.corporate_tax}` : null,
        },
        issue_date: dateMDY(e.issue_date),
      };
      if (findEntity?.entity_type == 2) {
        //natural person
        item.fy_period_under_vat = e?.fy_period_under_vat?.name;
      }
      return item;
    });
    if (findEntity?.entity_type == 2) {
      //natural person
      this.headers = [...this.headers, "FY Period under VAT"];
    }

    this.loading = false;
  },
};
</script>

<style scoped></style>

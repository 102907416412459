<template>
  <div class="w-100 mt-4">
    <div class="payment-info">
      <div class="d-flex align-items-center justify-content-between">
        <div class="">
          <h3 class="subscription-title">
            {{ mySubscription?.data?.subscription?.name }}
          </h3>
          <h4 class="subscription-sub-title">
            {{
              new Date(mySubscription.data?.start_date)
                .toUTCString()
                .slice(0, 16)
            }}
            to
            {{
              new Date(mySubscription.data?.end_date).toUTCString().slice(0, 16)
            }}
          </h4>
        </div>
        <div class="subscription-amount">
          AED
          {{ mySubscription.data?.paid_amount }}
        </div>
      </div>
      <div class="description-container mt-3">
        <div class="d-flex align-items-start">
          <img
            src="@/assets/svg/check-circle-mini.svg"
            class="check-icon mr-2"
            alt=""
          />
          <p class="subscription-detail mb-0">
            <u>
              <b>
                Can support up to {{ mySubscription.data?.user_count }} users
              </b>
            </u>
          </p>
        </div>
        <div class="d-flex align-items-start">
          <img
            src="@/assets/svg/check-circle-mini.svg"
            class="check-icon mr-2"
            alt=""
          />
          <p class="subscription-detail mb-0">
            <u>
              <b>
                Can support up to {{ mySubscription.data?.entity_count }} entity
              </b>
            </u>
          </p>
        </div>
        <div class="d-flex align-items-start">
          <img
            src="@/assets/svg/check-circle-mini.svg"
            class="check-icon mr-2"
            alt=""
          />
          <p class="subscription-detail mb-0">
            <u>
              <b>
                Price for additional entity is
                {{ mySubscription.data?.price_per_entity }} AED
              </b>
            </u>
          </p>
        </div>
        <div class="d-flex align-items-start">
          <img
            class="last-icon mr-2"
            src="@/assets/svg/check-circle-mini.svg"
            alt=""
          />
          <p class="subscription-detail mb-0">
            <b>
              <p v-html="mySubscription.data?.subscription?.description" />
            </b>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useSubscriptionStore } from "../../../store/subscription";

const subscriptionStore = useSubscriptionStore();
const { mySubscription, subscription } = storeToRefs(subscriptionStore);
</script>

<style scoped>
.payment-info {
  width: 100%;
  min-height: 214px;
  display: flex;
  flex-direction: column;
  background: #deeded;
  box-shadow: 0px 8px 8px rgba(187, 187, 187, 0.25);
  border-radius: 10px;
  padding: 21px 17px 15px 18px;
}
.subscription-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.055em;
  color: #382628;
}
.subscription-sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #382628;
}
.subscription-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.055em;
  color: #111827;
}
.subscription-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #747171;
}
.check-icon {
  margin-top: 5px;
}
.last-icon {
  margin-top: 4px;
}
</style>

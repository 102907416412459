
<template>
<SideNavContainer>
<ProfileContainer />
</SideNavContainer>
</template>

<script>
import { SideNavContainer } from '@/components/layout'
import { ProfileContainer } from '@/components/Profile'

export default {
  name: 'ProfileView',
  components: { ProfileContainer, SideNavContainer }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <SideNavContainer side-nav-type="financialData">
    <EntityChecker :check-mandatory-fields="true">
      <div
        class="title-container d-flex align-items-center justify-content-between"
      >
        <h3 class="title">
          General Information <br />
          <p class="subtitle mb-0">
            {{ name }}
          </p>
        </h3>
        <FinancialYear />
      </div>
      <LoaderContainer :loading="loading || statusLoading" :error="error">
        <GeneralInformationBreadCrumb />
        <Entity />
        <Address />
        <BankingDetails />
        <Choose />
      </LoaderContainer>
    </EntityChecker>
  </SideNavContainer>
</template>

<script setup>
import { computed, onUnmounted, ref } from "vue";
import LoaderContainer from "../../common/LoaderContainer.vue";
import { SideNavContainer } from "../../layout";
import Entity from "./Entity.vue";
import Address from "./Address.vue";
import BankingDetails from "./BankingDetails.vue";
import Choose from "./Choose.vue";
import { GeneralInformationBreadCrumb } from "../../BreadCrumbs";
import { onMounted, watch } from "vue";
import { useGeneralInfoStore } from "../../../store/generalInformation";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import FinancialYear from "./FinancialYear.vue";
import { useStatusStore } from "../../../store/statusStore";
import { useIFRSStore } from "../../../store/NonIFRS/IFRS.store";
import EntityChecker from "../../entity/AddEntity/EntityChecker.vue";
import { useEntityStore } from "../../../store/entity";

const generalInfoStore = useGeneralInfoStore();
const { loading, error, options } = storeToRefs(generalInfoStore);
const financialStore = useIFRSStore();
const statusStore = useStatusStore();
const route = useRoute();
const entityStore = useEntityStore();
const { loading: financialTypeLoading } = storeToRefs(financialStore);
const { loading: statusLoading } = storeToRefs(statusStore);
const { entity } = storeToRefs(entityStore);

const name = computed(() => {
  if (!entity.value.data?.entity) {
    return "";
  }
  const Entity = entity.value.data?.entity[0];
  if (Entity?.entity_type == 2) {
    return `${Entity?.entity_first_name} ${Entity?.entity_last_name}`;
  }
  return Entity?.entity_name;
});

const unWatchQuery = watch(
  () => route.query,
  async (newVal) => {
    if (newVal.financial_year) {
      await financialStore.getIFRSCheck(
        route.params.id,
        newVal.financial_year,
        newVal.financial_period,
        async () => {
          await statusStore.getStatus(
            route.params.id,
            newVal.financial_year,
            newVal.financial_period
          );
        }
      );
    }
    await generalInfoStore.getGeneralInformation(
      route.params.id,
      newVal.financial_year
    );
    if (newVal.liable_to_audit) {
      options.value.liable_to_audit = newVal.liable_to_audit;
    }
    if (newVal.qfzp) {
      options.value.qualified_for_freezone = newVal.qfzp;
    }
    if (newVal.method_of_accounting) {
      options.value.method_of_accounting = newVal.method_of_accounting;
    }
    if (newVal.liable_to_maintain_tp_documentation) {
      options.value.liable_to_maintain_tp_documentation =
        newVal.liable_to_maintain_tp_documentation;
    }
  },
  { deep: true }
);
const unWatchParamsId = watch(
  () => route.params.id,
  async (newVal) => {
    if (route.query.financial_year) {
      await generalInfoStore.getGeneralInformation(
        newVal,
        route.query.financial_year
      );
      if (route.query.liable_to_audit) {
        options.value.liable_to_audit = route.query.liable_to_audit;
      }
      if (route.query.qfzp) {
        options.value.qualified_for_freezone = route.query.qfzp;
      }
    }
  },
  { deep: true }
);
onMounted(async () => {
  if (route.query.financial_year) {
    await financialStore.getIFRSCheck(
      route.params.id,
      route.query.financial_year,
      route.query.financial_period,
      async () => {
        await statusStore.getStatus(
          route.params.id,
          route.query.financial_year,
          route.query.financial_period
        );
      }
    );
    await generalInfoStore.getGeneralInformation(
      route.params.id,
      route.query.financial_year
    );
  }
  if (route.query.liable_to_audit) {
    options.value.liable_to_audit = route.query.liable_to_audit;
  }
  if (route.query.qfzp) {
    options.value.qualified_for_freezone = route.query.qfzp;
  }
  if (route.query.method_of_accounting) {
    options.value.method_of_accounting = route.query.method_of_accounting;
  }
  if (route.query.liable_to_maintain_tp_documentation) {
    options.value.liable_to_maintain_tp_documentation =
      route.query.liable_to_maintain_tp_documentation;
  }
});

onUnmounted(() => {
  unWatchQuery();
  unWatchParamsId();
});
</script>

<style scoped>
.title-container {
  padding: 25px 10px 16px 10px;
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.055em;
  color: #000000;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

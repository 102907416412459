<template>
  <SideNavContainer title="List of Users" :TitleRightSide="AddNewUserButton">
    <LoaderContainer :loading="users.loading" :error="users.error">
      <SearchUser :search-text="searchText" :handle-search="handleSearch" />
      <UserList :data="data" />
    </LoaderContainer>
  </SideNavContainer>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { SideNavContainer } from "../../components/layout";
import { AddNewUserButton, UserList } from "../../components/user";
import SearchUser from "../../components/user/SearchUser.vue";
import { useUsersStore } from "../../store/users.store";
import { storeToRefs } from "pinia";
import LoaderContainer from "../../components/common/LoaderContainer.vue";
import { searchObject } from "../../helpers/searchObject";

const searchText = ref("");
const data = ref([]);
const usersStore = useUsersStore();
const { users } = storeToRefs(usersStore);
const handleSearch = (value) => {
  searchText.value = value;
  if (!value) {
    data.value = users.value.data;
    return;
  }
  data.value = searchObject(
    value,
    ["email", "first_name", "last_name", "mobile_no"],
    users.value.data,
    "id",
    true
  );
};
onMounted(async () => {
  await usersStore.getUsersList();
  data.value = users.value.data;
});
watch(
  () => users.value.data,
  (newValue) => {
    data.value = newValue;
  },
  { deep: true }
);
</script>

<style scoped></style>

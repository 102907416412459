<template>
  <div class="row">
    <div class="col-lg-12">
      <h4 class="title bold">
        Whether, within (2) two years from the date of the transfer, any of the
        below incident occurs:
      </h4>
      <div
        class="d-flex align-items-center justify-content-between w-100 content"
      >
        <p class="article flex-2 mb-0">
          i) There is a subsequent transfer of the asset or liability outside of
          the Qualifying Group.
        </p>
        <div class="d-flex flex-1 align-items center justify-content-end">
          <v-switch
            v-model="
              data.article_26_incident_occurs
                .there_is_a_subsequent_transfer_of_the_asset_or_liability
            "
            color="#0F75BC"
            inset
            :disabled="!!status"
            hide-details
          />
        </div>
      </div>
      <div
        class="d-flex align-items-center justify-content-between w-100 content"
      >
        <p class="article flex-2 mb-0">
          ii) The Taxable Persons cease to be members of the same Qualifying
          Group.
        </p>
        <div class="d-flex flex-1 align-items center justify-content-end">
          <v-switch
            v-model="
              data.article_26_incident_occurs
                .the_taxable_persons_cease_to_be_member_of_the_same_qualifying_group
            "
            color="#0F75BC"
            :disabled="!!status"
            inset
            hide-details
          />
        </div>
      </div>
      <div class="col-lg-12 my-4">
        <p class="text-danger">
          ***Difference Between Market Value and Transfer Value to be taxed and
          disallowed under Article-26
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useChapter8Store } from "../../../../store/chapter8";
import { useStatusStore } from "../../../../store/statusStore";

const chapter8Store = useChapter8Store();
const { data } = storeToRefs(chapter8Store);
const statusStore = useStatusStore();
const { status } = storeToRefs(statusStore);
</script>

<style scoped>
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.btn-add {
  width: 124.33px;
  height: 47px;
  background: #0f75bc;
  border-radius: 5px;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.header {
  padding: 6px 9px;
  min-height: 47px;
  background: #0f75bc;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
.custom-input {
  height: 44px;
  padding: 11px 13px;
  margin-top: 10px;
  border: 1px solid rgba(116, 113, 113, 0.3);
}
.custom-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
  text-align: start;
}
.article {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
.content {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ede4e4;
  gap: 60px;
}
.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}
.sub-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.flex-2 {
  flex: 10;
}
.flex-1 {
  flex: 1;
}
</style>

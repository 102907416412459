import { defineStore } from "pinia";
import { generate } from "randomized-string";
import { getMax } from "../helpers/number";

export const useCTaxCalculatorBusinessStore = defineStore(
  "CTaxCalculatorBusiness",
  {
    // arrow function recommended for full type inference
    state: () => {
      return {
        data: [
          {
            id: "total_revenue_for_the_year",
            name: "Total Revenue for the Year",
            amount: "",
            amount_calculated_fields: "totalRevenueForTheYear"
          },
          {
            id: "ebidta_fincial",
            name: "EBITDA as per Finacials",
            amount: "",
            amount_calculated_fields:"ebidta_fincial"
          },
          
          {
            id: "less_exempt",
            name: "Less-Exempt Income  as per Article-22",
            amount: "",
            amount_calculated_fields: "less_exempt"
          },
          {
            id: "ebitda",
            name: "Adjusted EBITDA(i-ii)",
            amount: "",
            amount_calculated_fields: "EBITDA"
          },
         
          {
            id: "adjustments_per_ct_law",
            name: "Adjustments as per CT Law",
            amount: "",
            amount_calculated_fields: ""
          },
          {
            id: "total_profits_for_the_year",
            name: "Total Profits for the Year",
            amount: "",
            amount_calculated_fields: "totalProfitsForTheYear"
          },
          {
            id: "add_back_expenses_disallowed",
            name: "Add back expenses disallowed (Other than mentioned in (iii) &(iv) ) (+)",
            amount: "",
            amount_calculated_fields: "addBackExpensesDisallowed"
          },
          {
            id: "exempt_income_to_be_deducted",
            name: "Exempt Income-Incomes to be deducted (-)",
            amount: "",
            amount_calculated_fields: "exemptIncomeIncomesToBeDeducted"
          },
          {
            id: "entertainment_expenses_to_be_added_back",
            name: "Entertainment Expenses to be added back (+)",
            amount: "",
            amount_calculated_fields: "entertainmentExpensesAddedBack"
          },
          {
            id: "interest_expenditure",
            name: "Net Interest Expenditure (+)",
            amount: "",
            amount_calculated_fields: "interestExpenditure"
          }
        ]
      };
    },
    getters: {
      totalRevenueForTheYear(state) {
        const item = state.data.find(
          (item) => item?.id === "total_revenue_for_the_year"
        );
        let sum = Number(item.amount);
        if (sum <= 3000000) {
          return "Apply SBR";
        }
        return sum;
      }, 
      EBITDA(state) {
        if (this.totalRevenueForTheYear === "Apply SBR") {
          return 0;
        }
        const lessExempt = state.data.find((item) => item?.id === "less_exempt");
        const ebidtaFincial = state.data.find((item) => item?.id === "ebidta_fincial");
        let ebidta = 0;
        if (ebidtaFincial) {
          ebidta = ebidtaFincial.amount;
          if (lessExempt) {
            ebidta = ebidta - lessExempt.amount;
          }
        }

        // let sum = Number(item.amount);
        return ebidta;
      },
      totalProfitsForTheYear(state) {
        if (this.totalRevenueForTheYear === "Apply SBR") {
          return 0;
        }
        const item = state.data.find(
          (item) => item?.id === "total_profits_for_the_year"
        );
        let sum = Number(item.amount);
        return sum;
      },
      addBackExpensesDisallowed(state) {
        if (this.totalRevenueForTheYear === "Apply SBR") {
          return 0;
        }
        const item = state.data.find(
          (item) => item?.id === "add_back_expenses_disallowed"
        );
        let sum = Number(item.amount);
        return sum;
      },
      exemptIncomeIncomesToBeDeducted(state) {
        if (this.totalRevenueForTheYear === "Apply SBR") {
          return 0;
        }
        const item = state.data.find(
          (item) => item?.id === "exempt_income_to_be_deducted"
        );
        let sum = Number(item.amount);
        return sum;
      },
      entertainmentExpensesAddedBack(state) {
        if (this.totalRevenueForTheYear === "Apply SBR") {
          return 0;
        }
        const item = state.data.find(
          (item) => item?.id === "entertainment_expenses_to_be_added_back"
        );
        let sum = Number(item.amount) * 0.5;
        return sum;
      },
      interestExpenditure(state) {
        if (this.totalRevenueForTheYear === "Apply SBR") {
          return 0;
        }
        const item = state.data.find(
          (item) => item?.id === "interest_expenditure"
        );
        if (Number(item.amount) <= 12000000) {
          return 0;
        }
        const ebitda = state.data.find((item) => item.id === "ebitda");
        const ebitdaThreePercent = Number(ebitda.amount) * 0.3;
        if (Number(ebitda.amount) >= 40000000) {
          let value = Number(item.amount) - ebitdaThreePercent;
          return getMax(0, value);
        } else if (
          Number(ebitda.amount) <= 40000000 &&
          Number(item.amount) >= 12000000
        ) {
          let value = Number(item.amount) - 12000000;
          return getMax(0, value);
        }
        return 0;
      },
      taxableIncome() {
        const sum =
          this.totalProfitsForTheYear +
          this.addBackExpensesDisallowed +
          this.entertainmentExpensesAddedBack +
          this.interestExpenditure -
          this.exemptIncomeIncomesToBeDeducted;
        if (sum < 0) {
          return 0;
        }
        return sum;
      },
      exemption() {
        if (this.taxableIncome > 0) {
          return 375000;
        }
        return 0;
      },
      taxLiability() {
        const tot = (this.taxableIncome - this.exemption) * 0.09;
        if (tot < 0) {
          return 0;
        }
        return tot;
      }
    }
  }
);

<template>
  <LoaderContainer
    :loading="countries.loading || profile.loading"
    :error="countries.error || profile.errors"
  >
    <AlertModal
      :open="leaveAlert.modal"
      message="Do you really want to leave? you have unsaved changes"
      :on-confirm="leaveSite"
      :on-cancel="cancelLeaveAlert"
      button-name="Leave"
    />
    <form @submit="handleSubmit">
      <div class="row">
        <div class="col-lg-4 mt-4">
          <CustomTextInput
            v-model="first_name"
            label="First Name"
            placeholder="First Name"
            @keypress="handleFirstNameLastName"
          />
        </div>
        <div class="col-lg-4 mt-4">
          <CustomTextInput
            v-model="last_name"
            label="Last Name"
            placeholder="Last Name"
            @keypress="handleFirstNameLastName"
          />
        </div>
        <div class="col-lg-4 mt-4">
          <CustomTextInput
            v-model="email"
            type="email"
            label="Email"
            placeholder="Email"
            :error-message="error.email"
          />
        </div>
        <div class="col-lg-4 mt-4">
          <h4 class="label">Mobile Number</h4>
          <div class="d-flex w-100 mt-4 align-items-center">
            <country-flag
              v-if="country_code"
              :country="country_code"
              class="mr-1"
              size="big"
            />
            <v-autocomplete
              v-model="mobile_code"
              :items="countries.data"
              item-title="calling_code"
              item-value="calling_code"
              bg-color="#DEEDED"
            >
            </v-autocomplete>
            <input
              v-model="mobile_no"
              type="number"
              class="custom-input mt-31"
              :style="{ width: '67% !important' }"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-lg-4 mt-4">
          <CustomTextInput
            v-model="address"
            type="text"
            label="Address"
            placeholder="Enter Address"
            :error-message="error.email"
          />
        </div>
        <div class="col-lg-4 mt-4">
          <CustomTextInput
            v-model="postal_code"
            type="number"
            label="Zip Code"
            placeholder="Enter zip code"
            :error-message="error.email"
            @keypress="onlyAcceptDigits"
          />
        </div>
        <div class="col-lg-12 mt-4">
          <p class="text-info my-2">{{ message }}</p>
          <div class="d-flex align-items-center flex-wrap">
            <button class="btn-signup mt-2" type="submit">Save</button>
            <button
              class="btn-signin mt-2"
              type="button"
              @click="$router.push('/profile')"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </LoaderContainer>
</template>

<script setup>
import { AlertModal, CustomTextInput } from "../common";
import { ref, watch, onMounted } from "vue";
import { useCountryStore } from "../../store/country";
import { storeToRefs } from "pinia";
import { useProfileStore } from "../../store/profile";
import { addEntity } from "../../store/entityActions/post";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import LoaderContainer from "../common/LoaderContainer.vue";
import { onlyAcceptDigits } from "../../helpers/validation";
import { emailRegex } from "../../constants/regex-format";
const countryStore = useCountryStore();
const { countries } = storeToRefs(countryStore);
const router = useRouter();
const first_name = ref("");
const last_name = ref("");
const email = ref("");
const mobile_code = ref("");
const mobile_no = ref("");
const type = ref("");
const verify = ref("");
const message = ref("");
const country_code = ref("");
const postal_code = ref("");
const address = ref("");
const leaveAlert = ref({
  modal: false,
  to: "",
});

const error = ref({
  mobile_no: "",
  email: "",
});
const profileStore = useProfileStore();
const { profile } = storeToRefs(profileStore);
onMounted(async () => {
  if (!countries.value.data.length) {
    await countryStore.getCountryList();
  }
  if (!profile.value.data) {
    await profileStore.getProfile();
  }
  first_name.value = profile?.value?.data?.first_name;
  last_name.value = profile?.value?.data?.last_name;
  email.value = profile?.value?.data?.email;
  mobile_code.value = profile?.value?.data?.mobile_code;
  mobile_no.value = profile?.value?.data?.mobile_no;
  type.value = profile?.value?.data?.type;
  address.value = profile?.value?.data?.address;
  postal_code.value = profile?.value?.data?.zipcode;
});

onBeforeRouteLeave(async (to) => {
  if (
    (first_name.value !== profile?.value?.data?.first_name ||
      last_name.value !== profile?.value?.data?.last_name ||
      email.value !== profile?.value?.data?.email ||
      mobile_code.value !== profile?.value?.data?.mobile_code ||
      mobile_no.value !== profile?.value?.data?.mobile_no ||
      type.value !== profile?.value?.data?.type ||
      address.value !== profile?.value?.data?.address ||
      postal_code.value !== profile?.value?.data?.zipcode) &&
    !leaveAlert.value.modal
  ) {
    leaveAlert.value.modal = true;
    leaveAlert.value.to = to.path;
    return false;
  }
  return true;
});
const cancelLeaveAlert = () => {
  leaveAlert.value.modal = false;
  leaveAlert.value.to = "";
};
const leaveSite = () => {
  router.push(leaveAlert.value.to);
};
watch(mobile_no, (newValue) => {
  if (String(newValue).length === 10) {
    verify.value = true;
    error.value.mobile_no = "";
  } else {
    verify.value = false;
    error.value.mobile_no = "Please give 10 characters";
  }
});
watch(mobile_code, (newValue) => {
  if (!newValue) {
    return;
  }
  const findCountry = countries.value.data.find(
    (country) => country.calling_code === newValue
  );
  const code = findCountry ? findCountry?.code?.toLowerCase() : "";
  country_code.value = code === "uae" ? "are" : code;
});
const handleSubmit = async (e) => {
  e.preventDefault();
  if (!emailRegex.test(email.value)) {
    error.value.email = "Please enter valid email";
    return;
  }
  await addEntity(
    "/updateProfile",
    {
      first_name: first_name.value,
      last_name: last_name.value,
      email: email.value,
      mobile_code: mobile_code?.value,
      mobile_no: mobile_no.value,
      zipcode: postal_code?.value,
      address: address.value,
      type: type.value,
    },
    (mess) => {
      message.value = mess;
    },
    async () => {
      await profileStore.getUserProfile();
      setTimeout(() => {
        router.push("/profile");
      }, 2000);
    }
  );
};
const handleFirstNameLastName = (e) => {
  const currentKey = String.fromCharCode(e.keyCode);
  if (!/^[a-zA-Z ]*$/.test(currentKey)) {
    e.preventDefault();
  }
};
</script>

<style scoped>
.type-container {
  border-bottom: 1px solid #747171;
  padding-bottom: 15px;
}
.radio-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #747171;
}
.wrapper {
  min-height: 70vh;
}
.btn-signup {
  padding: 8px 21px;
  width: 150px;
  height: 55px;
  background: #0f75bc;
  border-radius: 5px;
  margin-right: 21px;
  color: #fafbff;
}
.btn-signin {
  width: 150px;
  height: 55px;
  background: #ffffff;
  border: 1px solid #747171;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; /* identical to box height */
  text-align: center;
  letter-spacing: 0.055em;
  color: #747171;
}
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
  margin-bottom: -9px;
}
.mt-31 {
  margin-top: -31px !important;
}
.custom-input {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  height: 56px;
  padding: 13px 10px;
  margin-top: 8px;
}
.mt-31 {
  margin-top: -22px !important;
}
</style>

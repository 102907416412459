<template>
  <div class="w-100">
    <div class="header d-flex align-items-center w-100 justify-content-between">
      <p class="header-title mb-0">Property, Plant and Equipment</p>
      <p class="header-title mb-0">
        {{ NumberFormatter(totalNonCurrentAsset.prpty_plant_equipment) }}
      </p>
    </div>
    <form
      v-for="(item, index) in data"
      :id="item.model"
      :key="item.id"
      :class="`w-100 table-body ${!index || !(index % 2) ? 'white' : 'gray'} ${
        item.sub ? 'sub' : ''
      } d-flex align-items-center justify-content-between`"
      @submit="handleSubmit"
    >
      <p class="mb-0 body-title">
        {{ item.name }}
      </p>
      <p
        v-if="!item.showInput"
        :class="`body-title ${isHeader(item.model) ? 'bold' : ''} mb-0`"
        @click="openCloseInput(item.model, true)"
      >
        {{ NumberFormatter(NonCurrentAssetData[item.model]) }}
      </p>

      <input
        :id="'input-' + item.model"
        v-model="NonCurrentAssetData[item.model]"
        type="number"
        :hidden="!item.showInput"
        class="clickable-text"
        @focus="setOriginalValue(item.model, NonCurrentAssetData[item.model])"
        @blur="handleBlur(item.model, false)"
        @keydown.tab="(e) => handleTab(e, item.model)"
      />
    </form>
  </div>
</template>

<script setup>
import { generate } from "randomized-string";
import { ref, computed } from "vue";

import { storeToRefs } from "pinia";

import { useFinancialDataStore } from "../../../../../store/financial.store";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";
import { useRoute, useRouter } from "vue-router";
import { autoSaveNonCurrentAssets } from "./helper";
const props = defineProps({
  view: {
    type: Boolean,
    default: false,
  },
  setSavingStatus: {
    type: Function,
    default: () => {},
  },
});
const route = useRoute();
const router = useRouter();
const financialDataStore = useFinancialDataStore();
const { NonCurrentAssetData, totalNonCurrentAsset } =
  storeToRefs(financialDataStore); //get initialvalue from store

const data = ref([
  {
    id: generate(),
    name: "1.Building, Plant and Equipment, Motor Vehicles",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls",
  },
  {
    id: generate(),
    name: "1.1 Beginning of Financial Year",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_bgng_of_fncl_yr",
  },
  {
    id: generate(),
    name: "1.2  Additions (+)",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_additions",
  },
  {
    id: generate(),
    name: "1.3  Disposals (-)",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_disposals",
  },
  {
    id: generate(),
    name: "1.4  Depreciation (-)",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_depreciation",
  },
  {
    id: generate(),
    name: "1.5 Transfer to investment properties (-)",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_tnsfr_to_invtmnt_prpts",
  },
  {
    id: generate(),
    name: "1.6  Revaluation Adjustments",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_revaluation_adjustment",
  },
  {
    id: generate(),
    name: "2.Land",
    sub: false,
    showInput: false,
    amount: "0000.00",
    model: "prpty_plant_equipment_land",
  },
  {
    id: generate(),
    name: "2.1 Freehold Land",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "prpty_plant_equipment_land_freehold_land",
  },
  {
    id: generate(),
    name: "2.2 Leasehold Land",
    sub: true,
    showInput: false,
    amount: "0000.00",
    model: "prpty_plant_equipment_land_leasehold_land",
  },
]);
const isHeader = (key) => {
  const keys = [
    "prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls",
    "prpty_plant_equipment_land",
  ];
  return !!keys.find((itemKeys) => itemKeys === key);
};
const getValue = (model) => {
  return Number(NonCurrentAssetData.value[model]) || 0;
};
// 1.Building, Plant and Equipment, Motor Vehicles
NonCurrentAssetData.value.prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls = computed(
  () => {
    const keys = [
      "prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_bgng_of_fncl_yr",
      "prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_additions",
      "prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_disposals",
      "prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_depreciation",
      "prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_tnsfr_to_invtmnt_prpts",
      "prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_revaluation_adjustment",
    ];
    return keys.reduce((a, b) => {
      if (
        b === "prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_disposals" ||
        b === "prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_depreciation" ||
        b === "prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls_tnsfr_to_invtmnt_prpts"
      ) {
        return a - getValue(b);
      }
      return a + getValue(b);
    }, 0);
  }
);

// 2.Land
NonCurrentAssetData.value.prpty_plant_equipment_land = computed(() => {
  const keys = [
    "prpty_plant_equipment_land_freehold_land",
    "prpty_plant_equipment_land_leasehold_land",
  ];
  return keys.reduce((a, b) => a + getValue(b), 0);
});

totalNonCurrentAsset.value.prpty_plant_equipment = computed(() => {
  const keys = [
    "prpty_plt_eqpt_bldng_plt_eqpt_mtr_vchls",
    "prpty_plant_equipment_land",
  ];
  return keys.reduce((a, b) => a + getValue(b), 0);
});

const openCloseInput = async (model, value) => {
  if (props.view || route.query.business === "master" || isHeader(model)) {
    return;
  }
  data.value = data.value.map((item) => {
    if (item.model === model) {
      return { ...item, showInput: value };
    }
    return item;
  });
  if (value) {
    const input = await document.getElementById(`input-${model}`);
    input.focus();
  }
};
const handleSubmit = async (e) => {
  e.preventDefault();
  let current_model = e.target.id;
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
const handleTab = async (e, current_model) => {
  e.preventDefault();
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
const autoSave = async (model) => {
  const previousValue = data.value.find((item) => item.model === model).value;
  const currentValue = NonCurrentAssetData.value[model];
  await autoSaveNonCurrentAssets(
    previousValue,
    currentValue,
    props.setSavingStatus,
    route,
    router
  );
};
const handleBlur = async (model, openClose) => {
  await openCloseInput(model, openClose);
  await autoSave(model);
};
const setOriginalValue = (key, value) => {
  const index = data.value.findIndex((item) => item.model === key);
  data.value[index].value = value;
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 10px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}
</style>

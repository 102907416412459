<template>
  <SideNavContainer title="Choose Your Plan">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="desc-container d-flex align-items-center justify-content-between">
          <p class="mb-0 subscription-type">
            {{ subscription.data?.type }}
          </p>
        </div>
        <div class="w-100">
          <div class="customer-info w-100">
            <p class="name">
              {{ profile.data.name }}
            </p>
            <p class="phone mb-0">
              {{ profile.data.mobile_no }}
            </p>
            <p class="email mb-0">
              {{ profile.data.email }}
            </p>
          </div>
          <div class="desc-container d-flex align-items-center justify-content-between">
            <p class="mb-0 subscription-type">
              Order Summary
            </p>
          </div>
          <div class="customer-info w-100 d-flex align-items-center justify-content-between">
            <p class="name mb-0">
              {{ subscription.data?.type }}
            </p>
            <p class="name mb-0">
              AED {{ subscription.data?.price }}
            </p>
          </div>
          <div class="w-100 d-flex flex-column prices px-4">
            <div class="d-flex align-items-center justify-content-between">
              <p class="price-desc">
                SubTotal
              </p>
              <p class="price-desc">
                AED {{ subscription.data?.price }}
              </p>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <p class="price-desc">
                Taxes
              </p>
              <p class="price-desc">
                AED {{ subscription.data?.price }}
              </p>
            </div>
          </div>
          <div class="total mt-1 w-100 d-flex align-items-center justify-content-between px-4">
            <p class="name">
              Total
            </p>
            <p class="name">
              AED {{ subscription.data?.price }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="desc-container d-flex align-items-center justify-content-between">
          <p class="mb-0 subscription-type">
            Make Payment
          </p>
          <div class="d-flex align-items-center">
            <button class="credit">
              Credit/Debit
            </button>
            <button class="net-banking">
              Net Banking
            </button>
          </div>
        </div>
        <div class="visa-container w-100 d-flex align-items-center justify-content-between">
          <div class="">
            <div class="d-flex align-items-center">
              <input
                id=""
                type="radio"
                name=""
                class="mb-1 mr-3"
              >
              <p class="name mb-0">
                **** 4857
              </p>
            </div>
            <div class="d-flex align-items-center pl-5">
              <p class="view-card-btn edit-card-btn px-3 mb-0">
                Visa
              </p>
              <p class="edit-card-btn pl-3 mb-0">
                Edit
              </p>
            </div>
          </div>
          <img
            src="@/assets/svg/visa-logo.svg"
            alt=""
          >
        </div>
        <div class="visa-container w-100 d-flex align-items-center justify-content-between">
          <div class="">
            <div class="d-flex align-items-center">
              <input
                id=""
                type="radio"
                name=""
                class="mb-1 mr-3"
              >
              <p class="name mb-0">
                **** 4857
              </p>
            </div>
            <div class="d-flex align-items-center pl-5">
              <p class="view-card-btn edit-card-btn px-3 mb-0">
                Mastercard
              </p>
              <p class="edit-card-btn pl-3 mb-0">
                Edit
              </p>
            </div>
          </div>
          <img
            src="@/assets/svg/Mastercard.svg"
            alt=""
          >
        </div>
        <button class="btn-add-card">
          <img
            src="@/assets/svg/plus.svg"
            class="mr-2"
            alt=""
          >
          Add New Card
        </button>
        <div class="total-amount d-flex align-items-center justify-content-between">
          <p class="name mb-0">
            Total Amount
          </p>
          <p class="name mb-0 my-3">
            {{ subscription.data?.price }}
          </p>
        </div>
        <p class="text-info">
          {{ message }}
        </p>
        <div class="w-100 d-flex mt-4 align-items-center justify-content-center mb-3">
          <button
            class="btn-proceed-payment"
            @click="proceedPayment()"
          >
            Proceed Payment
          </button>
        </div>
      </div>
    </div>
  </SideNavContainer>
</template>

<script>
import { SideNavContainer } from '@/components/layout'
import { defineComponent } from 'vue'
import { useSubscriptionStore } from '../../../store/subscription'
import { useProfileStore } from '../../../store/profile'
import { storeToRefs } from 'pinia'
export default defineComponent({
  name: 'CheckoutMakePayment',
  components: { SideNavContainer },
  setup (props) {
    const subScriptionStore = useSubscriptionStore()
    const profileStore = useProfileStore()
    const { subscription } = storeToRefs(subScriptionStore)
    const { profile } = storeToRefs(profileStore)
    return { subScriptionStore, profileStore, subscription, profile }
  },
  data () {
    return ({
      message: ''
    })
  },
  async created () {
    await this.subScriptionStore.getSubscription(this.$route.params?.plan_type)
  },
  methods: {
    async proceedPayment () {
      await this.subScriptionStore.paySubscription({
        subscription_id: this.subscription.data.id,
        order_no: 'ASDF8787',
        coupon_code: '',
        discount_price: 0,
        paid_amount: this.subscription.data.price,
        billing_name: this.profile.data.first_name + ' ' + this.profile.data.last_name,
        billing_address: 'CIC building, park street',
        billing_mobile: '8789952789',
        payment_type: 'online',
        payment_status: 'success'
      }, this.setMessage, () => {
        setTimeout(() => {
          this.$route.query.redirect_tab === 'subscription'
            ? this.$router.push('/subscription')
            : this.$router.push('/entity/add-entity/entity-details/entity')
        }, 2000)
      })
    },
    setMessage (message) {
      this.message = message
    }
  }
})
</script>

<style  scoped>
.desc-container {
    height: 64px;
    padding: 22px 10px 18px 10px;
    border-bottom: 1px solid rgba(116, 113, 113, 0.5);;
}
.subscription-type {
  font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.055em;
color: #000000;
}
.customer-info {
 background: #DEEDED;
box-shadow: 0px 8px 8px rgba(187, 187, 187, 0.25);
border-radius: 11px;
padding: 15px 15px 21px 15px;
margin-top:16px;
}
.name {
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.055em;
color: #000000;
}
.phone {
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.055em;
color: #382628;
margin-top:9px;
}
.email {
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 26px;
letter-spacing: 0.055em;
color: #382628;
}
.prices {
  margin-top: 25px;
}
.price-desc {
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.055em;
color: #382628
}
.total {
  border-top: 1px solid rgba(116, 113, 113, 0.5);
  padding-top:9px;
}
.btn-checkout {
  font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.055em;
color: #FAFBFF;
width: 133px;
height: 40px;
background: #0F75BC;
border-radius: 5px;
}
.credit {
    background: #0F75BC;
border-radius: 4px;
width: 128px;
height: 30px;
margin-right:6px;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.055em;
color: #FAFBFF;
}
.net-banking {
width: 125px;
height: 30px;
background: #EFF3F3;
border-radius: 4px;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.055em;
color: #000000;
}
.visa-container {
 background: #DEEDED;
box-shadow: 0px 8px 8px rgba(187, 187, 187, 0.25);
border-radius: 11px;
height: 105px;
margin-top:16px;
padding: 20px;
}
.view-card-btn {
border-right: 1px solid #000000;
cursor: pointer;
}
.edit-card-btn {
    font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 26px;
letter-spacing: 0.055em;
color: #747171;
cursor: pointer;
}
.btn-add-card {
    height: 53px;
    width: 100%;
background: #DEEDED;
border-radius: 4px;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
letter-spacing: 0.055em;
color: #000000;
margin-top:16px;
}
.total-amount {
    padding: 18px 10px 27px 10px;
    border-top: 1px solid rgba(116, 113, 113, 0.5);
    border-bottom: 1px solid rgba(116, 113, 113, 0.5);
    margin-top:19px;
}
.btn-proceed-payment {
    width: 196px;
background: #0F75BC;
border-radius: 4px;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
height: 55px;
text-align: center;
letter-spacing: 0.055em;
color: #FAFBFF;
}
</style>

<template>
  <LoaderContainer :loading="loading">
    <ShowStatus :modal="editStatus" :set-modal="setStatus" />
    <CustomTable :Data="data" :Headers="headers" :actions="actions" />
  </LoaderContainer>
</template>

<script setup>
import CustomTable from "@/components/common/CustomTable.vue";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import { useEntityStore } from "../../../../store/entity";
import {
  getEntityItems,
  getEntityAttribute,
  getEntityItem,
} from "../../../../store/entityActions/get";
import tableEdit from "../../../../assets/svg/table-edit.svg";
import tableDelete from "../../../../assets/svg/trash.svg";
import { generate } from "randomized-string";
import { deleteEntity } from "../../../../store/entityActions/delete";
import { useRouter, useRoute } from "vue-router";
import ShowStatus from "./ShowStatus.vue";
import { LoaderContainer } from "../../../common";

const headers = ref([
  "Place of Establishment",
  "License Authority Type.",
  "License Authority",
  "License Number",
  "Issue Date",
  "Expiry Date",
  "License Certification",
  "Registration Certification",
]);
const entityStore = useEntityStore();
const { entity, businessActivity, entityBranch } = storeToRefs(entityStore);
const router = useRouter();
const data = ref([]);
const route = useRoute();
const loading = ref(true);
const editStatus = ref(false);
const setStatus = (status) => {
  editStatus.value = status;
};
const actions = ref([
  {
    id: generate(),
    icon: tableEdit,
    onClick: (subId) => {
      if (entity.value?.data?.entity[0]?.file_return_status === "Filed") {
        setStatus(true);
        return;
      }
      router.push(
        `/entity/add-entity/branch-details/${route.params.id}/${subId}`
      );
    },
  },
  {
    id: generate(),
    icon: tableDelete,
    onClick: async (id) => {
      if (confirm("Are you sure you want to delete this branch detail?")) {
        await deleteEntity(
          id,
          "/entityBranch",
          (message) => {},
          () => location.reload()
        );
      }
    },
  },
]);
onMounted(async () => {
  if (!businessActivity.value.data.length) {
    await getEntityItems("businessActivity", "/businessActivity");
  }
  const Data = [];
  await getEntityItem("entityBranch", "/entityBranch", route.params.id);

  for (let i = 0; i < entityBranch?.value.data.length; i++) {
    const details = entityBranch.value.data[i];
    const item = {
      id: details?.id,
      place_of_establishment: details?.place_of_establishment?.name,
      license_authority_type: details?.license_authority_type?.name,
      license_authority: details?.license_authority?.name,
      license_number: details?.license_number,
      license_issue_date: details?.license_issue_date,
      license_expiry_date: details?.license_expiry_date,
      license_certification: {
        type: "image",
        url: details?.license_certification
          ? `${details?.license_imagepath}/${details?.license_certification}`
          : null,
      },
      registration_certification: {
        type: "image",
        url: details?.registration_certification
          ? `${details?.registration_imagepath}/${details?.registration_certification}`
          : null,
      },
    };
    Data.push(item);
  }
  // for (let i = 0; i < entity?.value.data.branch.length; i++) {
  //   const branch = entity.value.data?.branch[i];
  //   const businessActivity = await getEntityAttribute(
  //     "businessActivity",
  //     "/businessActivity",
  //     branch?.business_activity
  //   );
  //   const item = {
  //     id: branch.id,
  //     EntityHasBranch: "YES",
  //     tarde_license_no: branch.tarde_license_no,
  //     business_activity:
  //       branch?.business_activity === 1 ? "Legal Person" : "Natural Person"
  //   };
  //   Data.push(item);
  // }
  data.value = Data;
  loading.value = false;
});
</script>

<style lang="scss" scoped></style>

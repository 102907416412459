import { defineStore } from "pinia";
import fetchWrapper from "../helpers/fetchWrapper";
import { getErrorMessage, getWarningMessage } from "../helpers/errorHandler";
import { Toast } from "../helpers/toast";

export const useQFZCalculatorStore = defineStore("QFZcalculator", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      loading: true,
      error: false,
      entity_id: "",
      financial_year: "",
      financial_period: "",
      original_revenue_value_from_profitloss: 0,
      options: {
        wheater_company_maintains_adequate_substance: false,
        the_company_has_selected_to_be_subject_to_corporate_tax: false,
        complies_with_arm_length_price_and_transfer_pricing_docs: false,
        derives_qualifying_income: false,
        maintains_audited_financial_statements: false
      },
      revenue: {
        total_revenue_of_QFZP: "",
        revenue_attributable_for_domestic: "",
        revenue_attributable_for_foreign: "",
        revenue_commercial_property_located: "",
        revenue_derived_transactions_with_person_related_fz_ncp: "",
        revenue_derived_transactions_with_person_related_ncp_fz: "",
        revenue_derived_non_qualifying_intellectual_property: "",
        excess_income_derived_from_qualifying_intellectual_property: ""
      },
      expense: {
        total_expenses_of_qfzp: "",
        expenses_attributable_for_domestic: "",
        expenses_attributable_for_foreign_PE: "",
        expenses_commercial_property_located_in_FZP: "",
        expenses_from_non_commercial_property_located_in_FZP: "",
        expenses_in_transactions_person_related_ncp_fz: "",
        expenses_related_earning_income_NQIP: ""
      },
      revenueQfzp: {
        revenue_from_transaction_with_NFZP: "",
        revenue_from_Excluded_activities: ""
      }
    };
  },
  getters: {
    revenueTot(state) {
      let sum = 0;
      for (let x in state.revenue) {
        if (x !== "total_revenue_of_QFZP") {
          sum += Number(state.revenue[x] || "");
        }
      }
      return sum;
    },
    totalRevenue(state) {
      return Number(state.revenue.total_revenue_of_QFZP) - this.revenueTot;
    },
    expenseTot(state) {
      let sum = 0;
      for (let x in state.expense) {
        if (x !== "total_expenses_of_qfzp") {
          sum += Number(state.expense[x] || "");
        }
      }
      return sum;
    },
    totalExpense(state) {
      return Number(state.expense.total_expenses_of_qfzp) - this.expenseTot;
    },
    totalIncomeOfQFZP() {
      return this.totalRevenue - this.totalExpense;
    },
    totalRevenueNonQualifyingActivities(state) {
      let sum = 0;
      for (let x in state.revenueQfzp) {
        sum += Number(state.revenueQfzp[x]);
      }
      return sum;
    },
    taxableIncomeOfQFZP() {
      const fivePercent = 0.05 * this.totalRevenue;
      if (
        this.totalRevenueNonQualifyingActivities > fivePercent ||
        this.totalRevenueNonQualifyingActivities > 5000000
      ) {
        return this.totalIncomeOfQFZP;
      }
      return 0;
    },
    exemption() {
      if (this.taxableIncomeOfQFZP > 0) {
        return 375000;
      }
      return 0;
    },
    netTaxableIncome() {
      if (this.taxableIncomeOfQFZP - this.exemption > 0) {
        return this.taxableIncomeOfQFZP - this.exemption;
      }
      return 0;
    },
    taxLiabilityBusinessIncomeQFZP() {
      const ninePercent = this.netTaxableIncome * 0.09;
      if (ninePercent > 0) {
        return ninePercent;
      }
      return 0;
    },
    taxLiabilityOtherIncomeQFZP(state) {
      let sum = 0;
      const revenueKey = Object.keys(state.revenue).map((key) => key);
      const expenseKey = Object.keys(state.expense).map((key) => key);
      revenueKey.forEach((key, index) => {
        if (
          key === "excess_income_derived_from_qualifying_intellectual_property"
        ) {
          const diff = Number(state.revenue[key]);
          if (diff > 0) {
            sum += diff * 0.09;
          }
        } else if (
          key !== "total_revenue_of_QFZP" &&
          key !== "total_expenses_of_qfzp"
        ) {
          const diff =
            Number(state.revenue[key]) -
            Number(state.expense[expenseKey[index]]);
          if (diff > 0) {
            sum += diff * 0.09;
          }
        }
      });

      return sum;
    },
    totalTaxLiability() {
      return (
        this.taxLiabilityBusinessIncomeQFZP + this.taxLiabilityOtherIncomeQFZP
      );
    }
  },
  actions: {
    clearData() {
      (this.loading = true),
        (this.error = false),
        (this.entity_id = ""),
        (this.financial_year = ""),
        (this.financial_period = ""),
        Object.keys(this.revenue).forEach((key) => {
          this.revenue[key] = "";
        });
      Object.keys(this.expense).forEach((key) => {
        this.expense[key] = "";
      });
      this.options = {
        wheater_company_maintains_adequate_substance: false,
        the_company_has_selected_to_be_subject_to_corporate_tax: false,
        complies_with_arm_length_price_and_transfer_pricing_docs: false,
        derives_qualifying_income: false,
        maintains_audited_financial_statements: false
      };
      this.revenueQfzp = {
        revenue_from_transaction_with_NFZP: "",
        revenue_from_Excluded_activities: ""
      };
    },
    async getQFZP(entity_id, financial_year, financial_period) {
      if (!entity_id || !financial_year || !financial_period) return;
      try {
        const res = await fetchWrapper.get(
          `/qfzpCalculator?entity_id=${entity_id}&financial_year=${financial_year}&financial_period=${financial_period}`
        );
        if (res.data?.result == 1) {
          Object.keys(this.revenue).forEach((key) => {
            this.revenue[key] = res.data.data[key];
          });
          Object.keys(this.expense).forEach((key) => {
            this.expense[key] = res.data.data[key];
          });
          Object.keys(this.revenueQfzp).forEach((key) => {
            this.revenueQfzp[key] = res.data.data[key];
          });
          Object.keys(this.options).forEach((key) => {
            this.options[key] = res.data.data[key] === "Y";
          });
          this.original_revenue_value_from_profitloss = res.data.data['original_revenue_value_from_profitloss'] ||  "";
          this.entity_id = entity_id;
          this.financial_year = financial_year;
          this.financial_period = financial_period;
          this.loading = false;
        } else {
          this.clearData();
          this.error = getWarningMessage(res);
          this.loading = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async saveQfzpCalc(
      entity_id,
      financial_year,
      financial_period,
      callBack = () => {},
      toast = new Toast("Saving please wait...")
    ) {
      try {
        toast.makeInfoToast("Saving please wait...");
        if (!entity_id || !financial_year || !financial_period) return;
        const payloadOptions = {};
        Object.keys(this.options).forEach((item) => {
          payloadOptions[item] = this.options[item] ? "Y" : "N";
        });
        const res = await fetchWrapper.post("/qfzpCalculator", {
          entity_id,
          financial_year,
          financial_period,
          ...payloadOptions,
          ...this.revenue,
          ...this.expense,
          ...this.revenueQfzp,
          qualifies_de_minimis: "N",
          income_from_non_qualifying_activities: "0",
          total_net_revenue_of_fzp: this.totalRevenue,
          total_net_expense_of_fzp: this.totalExpense,
          total_income_of_fzp: this.totalIncomeOfQFZP,
          total_revenue_of_non_qualifying_activities:
            this.totalRevenueNonQualifyingActivities,
          total_revenue_of_non_qualifying_activities_percent:
            (this.totalRevenueNonQualifyingActivities / this.totalRevenue) * 100
        });
        if (res.data.result === 1) {
          callBack(res?.data.data);
        } else {
          toast.makeErrorToast(getWarningMessage(res));
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(getErrorMessage(err));
      }
    }
  }
});

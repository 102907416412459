<template>
  <SideNavContainer title="Add Entity">
    <EntityChecker>
      <LoaderContainer :loading="loading">
        <AddEntityTab active="ownershipDetails" />
        <Progress :active="1" />
        <StatusChecker>
          <form @submit="handleSubmit">
            <div class="row wrapper px-2">
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="manager_name"
                  label="Name *"
                  :error-message="error.manager_name"
                  placeholder="Name"
                  @keypress="onlyAcceptAlphabets"
                />
              </div>

              <div class="col-lg-4 col-md-6 mt-4">
                <h4 class="label">
                  Resident of UAE <span class="text-danger">*</span>
                </h4>
                <div
                  class="radio-container w-100 d-flex align-items-center pl-2"
                >
                  <input
                    type="radio"
                    value="Y"
                    id=""
                    :error-message="error.is_from_uae"
                    v-model="is_from_uae"
                    class=""
                  />
                  <p class="mb-0 yes">Yes</p>
                  <input
                    type="radio"
                    value="N"
                    name=""
                    v-model="is_from_uae"
                    id=""
                    :error-message="error.is_from_uae"
                    class=""
                  />
                  <p class="mb-0 yes">No</p>
                </div>
                <p v-if="error.is_from_uae" class="text-danger">
                  {{ error.is_from_uae }}
                </p>
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <div class="w-100">
                  <h4 class="label">
                    Emirates ID Number of Manager
                    <span class="text-danger">*</span>
                  </h4>
                  <input
                    v-model="emirates_id_number"
                    type="text"
                    :class="`custom-input ${
                      error.emirates_id_number ? 'error' : ''
                    } w-100`"
                    placeholder="Emirates ID Number of Manager"
                    @keypress="checkEmirateId($event, true)"
                  />
                  <p v-if="error.emirates_id_number" class="text-danger">
                    {{ error.emirates_id_number }}
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="passport_no"
                  placeholder="Passport number of Manager"
                  :error-message="error.passport_no"
                  label="Passport number of Manager *"
                  @keypress="onlyAcceptAlphaNumeric"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <h4 class="label">
                  Nationality <span class="text-danger">*</span>
                </h4>
                <v-autocomplete
                  v-model="nationality"
                  :items="countries.data"
                  label="Nationality"
                  item-title="name"
                  item-value="id"
                  bg-color="#DEEDED"
                >
                </v-autocomplete>
                <p v-if="error.nationality" class="text-danger">
                  {{ error.nationality }}
                </p>
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <div class="w-100">
                  <h4 class="label">
                    Mobile Number with Country Code
                    <span class="text-danger">*</span>
                  </h4>
                  <input
                    v-model="mobile_no"
                    type="text"
                    :error-message="error.mobile_no"
                    :class="`custom-input ${
                      error?.mobile_no ? 'error' : ''
                    } w-100`"
                    placeholder="Mobile Number"
                    @keypress="checkMobileNumber($event)"
                  />
                  <p v-if="error.mobile_no" class="text-danger">
                    {{ error.mobile_no }}
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomSelectInput
                  v-model="gender"
                  label="Gender"
                  placeholder="Select Gender"
                  item-title="name"
                  item-value="value"
                  :options="[
                    { name: 'Male', value: 'Male' },
                    { name: 'Female', value: 'Female' },
                    { name: 'Others', value: 'Others' },
                  ]"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="email"
                  type="email"
                  label="Email Address"
                  placeholder="Email Address"
                  :error-message="error.email"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomDateInput
                  v-model="passport_issue_date"
                  type="number"
                  label="Passport Issue Date *"
                  placeholder="Select Date"
                  :max="dateNow()"
                  :error-message="error.passport_issue_date"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomDateInput
                  v-model="passport_expiry_date"
                  type="number"
                  label="Passport Expiry Date *"
                  placeholder="Select Date"
                  :min="dateNow()"
                  :error-message="error.passport_expiry_date"
                />
              </div>
              <div class="col-lg-4 col-md-6 mt-4">
                <CustomTextInput
                  v-model="address"
                  label="Address"
                  placeholder="Address"
                />
              </div>
              <div class="col-lg-12">
                <div class="w-100 d-flex align-items-center mt-10 mb-3">
                  <button class="save-next mr-3" type="submit">
                    Save & Next
                  </button>
                  <button
                    class="cancel"
                    type="button"
                    @click="
                      $router.push(`/entity/master-data/${$route.params.id}`)
                    "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </StatusChecker>
      </LoaderContainer>
    </EntityChecker>
  </SideNavContainer>
</template>

<script>
import { ref } from "vue";
import { SideNavContainer } from "../../../../layout";
import { AddEntityTab, CustomDateInput } from "../../../../common";
import CustomTextInput from "../../../../common/CustomTextInput.vue";
import { useRoute } from "vue-router";
import CustomSelectInput from "@/components/common/CustomSelectInput.vue";
import { useCountryStore } from "../../../../../store/country";
import { storeToRefs } from "pinia";
import EntityChecker from "../../EntityChecker.vue";
import { useEntityStore } from "../../../../../store/entity";
import { getEntityItem } from "../../../../../store/entityActions/get";
import Progress from "./Progress.vue";
import LoaderContainer from "@/components/common/LoaderContainer.vue";
import { addEntity } from "../../../../../store/entityActions/post";
import { updateEntity } from "../../../../../store/entityActions/put";
import { checkUaeId } from "../../../../../helpers/checkers";
import {
  onlyAcceptAlphaNumeric,
  onlyAcceptAlphabets,
} from "../../../../../helpers/validation";
import { dateNow } from "../../../../../helpers/date";
import StatusChecker from "../../StatusChecker.vue";
import { emailRegex } from "../../../../../constants/regex-format";

export default {
  name: "OwnerShipDetail",
  components: {
    StatusChecker,
    SideNavContainer,
    AddEntityTab,
    CustomTextInput,
    CustomSelectInput,
    EntityChecker,
    Progress,
    LoaderContainer,
    CustomDateInput,
  },
  setup() {
    const router = useRoute();
    const countryStore = useCountryStore();
    const { countries } = storeToRefs(countryStore);
    const entityStore = useEntityStore();
    const { ownershipDetails, DirectorDetails } = storeToRefs(entityStore);
    const tab = ref(router.query?.tab ? router.query?.tab : "directorDetails");
    const onChangeTab = (Tab) => {
      tab.value = Tab;
    };
    return {
      tab,
      onChangeTab,
      router,
      countryStore,
      countries,
      entityStore,
      ownershipDetails,
      DirectorDetails,
      onlyAcceptAlphaNumeric,
      onlyAcceptAlphabets,
      dateNow,
    };
  },
  data() {
    return {
      manager_name: "",
      is_from_uae: "",
      emirates_id_number: "",
      nationality: "",
      mobile_no: "",
      gender: "",
      email: "",
      passport_no: "",
      passport_issue_date: "",
      passport_expiry_date: "",
      address: "",
      saved: false,
      owner_id: "",
      loading: true,
      edit: false,
      message: "",
      error: {
        manager_name: "",
        nationality: "",
        emirates_id_number: "",
        is_from_uae: "",
        mobile_no: "",
        passport_no: "",
        passport_issue_date: "",
        passport_expiry_date: "",
        email: "",
      },
    };
  },
  watch: {
    passport_no() {
      this.error.passport_no = false;
    },
    manager_name() {
      this.error.manager_name = false;
    },
    nationality() {
      this.error.nationality = false;
    },
    emirates_id_number() {
      this.error.emirates_id_number = "";
    },
    is_from_uae() {
      this.error.is_from_uae = false;
    },

    mobile_no() {
      this.error.mobile_no = "";
    },
    passport_issue_date() {
      this.error.passport_issue_date = "";
    },
    passport_expiry_date() {
      this.error.passport_expiry_date = "";
    },
  },
  async created() {
    const id = this.$route.params.id;
    if (!this.countries.data.length) {
      await this.countryStore.getCountryList();
    }

    await getEntityItem("ownershipDetails", "/entityOwnerDetails", id);

    this.owner_id = this.$route.query?.owner_id || "";

    if (this.owner_id) {
      const owner = this.ownershipDetails.data.find(
        (owner) => owner?.id == this.owner_id
      );

      //console.log(owner);
      this.edit = true;
      (this.manager_name = owner?.manager_name),
        (this.is_from_uae = owner?.is_from_uae),
        (this.emirates_id_number = owner?.emirates_id_number),
        (this.nationality = owner?.nationality),
        (this.passport_no = owner?.passport_no),
        (this.mobile_no = owner?.mobile_no),
        (this.gender = owner?.gender),
        (this.email = owner?.email),
        (this.passport_issue_date = owner?.passport_issue_date),
        (this.passport_expiry_date = owner?.passport_expiry_date),
        (this.address = owner?.address);
    }
    this.loading = false;
  },
  methods: {
    checkMobileNumber(e, containMinus = false) {
      let reg = containMinus ? /^[0-9 +-]+$/ : /^[0-9+]+$/;
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (reg.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    checkEmirateId(e) {
      let reg = /^[0-9 -]+$/;
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (reg.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    setSaved(value) {
      this.saved = value;
    },
    setOwnerId(value) {
      this.owner_id = value;
    },
    setMessage(message) {
      this.message = message;
    },
    gotoDirectors() {},
    async handleSubmit(e) {
      e.preventDefault();
      if (!this.manager_name) {
        this.error.manager_name = "Field is required!";
        return;
      }
      if (!this.is_from_uae) {
        this.error.is_from_uae = "Field is required!";
        return;
      }

      if (!this.nationality) {
        this.error.nationality = "Field is required!";
        return;
      }
      if (!this.passport_no) {
        this.error.passport_no = "Field is required!";
        return;
      }
      if (!this.mobile_no) {
        this.error.mobile_no = "Field is required!";
        return;
      }
      if (!this.passport_issue_date) {
        this.error.passport_issue_date = "Field is required!";
        return;
      }
      if (!this.passport_expiry_date) {
        this.error.passport_expiry_date = "Field is required!";
        return;
      }
      if (!this.emirates_id_number) {
        this.error.emirates_id_number = "Field is required!";
        return;
      }
      if (!checkUaeId(this.emirates_id_number)) {
        this.error.emirates_id_number =
          "Invalid UAE ID please enter the correct format";
        return;
      }
      // if (String(this.passport_no).length > 12) {
      //   this.error.passport_no =
      //     "Passport number should not be more than 12 characters";
      //   return;
      // }

      if (String(this.mobile_no).length < 8 && !!this.mobile_no) {
        if (String(this.mobile_no).length > 13) {
          this.error.mobile_no =
            "Invalid Mobile Number, Mobile number should be 8 or 13 digits including country code";
          return;
        }
      }
      if (this.email && !emailRegex.test(this.email)) {
        this.error.email = "Invalid Email";
        return;
      }
      if (this.edit) {
        await updateEntity(
          "/entityOwnerDetails",
          {
            entity_id: this.$route.params.id,
            entity_owner_id: this.owner_id,
            manager_name: this.manager_name,
            is_from_uae: this.is_from_uae,
            emirates_id_number: this.emirates_id_number,
            nationality: this.nationality,
            passport_no: this.passport_no,
            mobile_no: this.mobile_no,
            gender: this.gender,
            email: this.email,
            passport_issue_date: this.passport_issue_date,
            passport_expiry_date: this.passport_expiry_date,
            address: this.address,
          },
          this.setMessage,
          async () => {
            await getEntityItem(
              "DirectorDetails",
              "/entityDirectorDetails",
              this.$route.params.id
            );
            if (this.DirectorDetails.data.length > 1) {
              this.$router.push(
                `/entity/ownership-details/${this.$route.params.id}`
              );
            } else if (this.DirectorDetails.data.length === 1) {
              this.router.push(
                `/entity/add-entity/director-details/${this.$route.params.id}/${this.DirectorDetails.data[0]?.id}`
              );
            } else if (this.$route.query.owner_id) {
              this.$router.push(
                `/entity/add-entity/director-details/${this.$route.params.id}?owner_id=${this.$route.query?.owner_id}`
              );
            }
          }
        );
      } else {
        await addEntity(
          "/entityOwnerDetails",
          {
            entity_id: this.$route.params.id,
            manager_name: this.manager_name,
            is_from_uae: this.is_from_uae,
            emirates_id_number: this.emirates_id_number,
            nationality: this.nationality,
            passport_no: this.passport_no,
            mobile_no: this.mobile_no,
            gender: this.gender,
            email: this.email,
            passport_issue_date: this.passport_issue_date,
            passport_expiry_date: this.passport_expiry_date,
            address: this.address,
          },
          this.setMessage,
          async ({ entity_owner_id }) => {
            this.$router.push(
              `/entity/add-entity/director-details/${this.$route.params.id}?owner_id=${entity_owner_id}`
            );
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.save-next {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #fafbff;
  width: 196px;
  background: #0f75bc;
  height: 55px;
}
.cancel {
  background: #ffffff;
  border: 1px solid #747171;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #747171;
  height: 55px;
  width: 196px;
}
.owner-list {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.radio-container {
  height: 56px;
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  gap: 11px;
}
.yes {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.custom-input {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  height: 48px;
  padding: 13px 10px;
}
.custom-input.error {
  border: 1px solid red;
}
.custom-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
  text-align: start;
}
.custom-input:focus {
  outline: 0;
}
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

<template>
  <div class="chart">
    <Line v-if="chartData" :data="chartData" :options="options" />
  </div>
</template>

<script setup>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Line } from "vue-chartjs";
import { onMounted, ref, watch } from "vue";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const props = defineProps({
  taxPaidAnalysis: {
    type: Object,
    default: null
  }
});
const chartData = ref(null);
const options = ref({
  responsive: true
});
const makeAppropriateLabel = (word) => {
  const split = word.toString().split("_");
  const firstName = split[0];
  const firstLetter = firstName.charAt(0).toUpperCase();
  const fistNameLastLetters = firstName.slice(1);
  let newWord = firstLetter + fistNameLastLetters + " ";
  for (let i = 1; i < split.length; i++) {
    newWord += split[i] + " ";
  }
  return newWord;
};
watch(
  () => props.taxPaidAnalysis,
  (newValue) => {
    let labels = [];
    let dataNumber = [];
    for (let x in newValue) {
      labels.push(makeAppropriateLabel(x));
      dataNumber.push(newValue[x]);
    }
    chartData.value = {
      labels: labels,
      datasets: [
        {
          label: "Tax paid",
          data: dataNumber,
          backgroundColor: "#00a7a7",
          borderColor: "#0f75bc"
        }
      ]
    };
  },
  { deep: true }
);

onMounted(() => {
  let labels = [];
  let dataNumber = [];
  for (let x in props.taxPaidAnalysis) {
    labels.push(makeAppropriateLabel(x));
    dataNumber.push(props.taxPaidAnalysis[x]);
  }
  chartData.value = {
    labels: labels,
    datasets: [
      {
        label: "Tax paid",
        data: dataNumber,
        backgroundColor: "#00a7a7",
        borderColor: "#4d82a8"
      }
    ]
  };
});
</script>

<style scoped>
.chart {
  height: 250px;
}
</style>

<template>
  <CustomModalContainer
    :open="open"
    :set-open="setOpen"
    content-class="my-custom-dialog"
    title=" 5.) Interest Expense disallowed as per (Article-30) (exceeding 30%
        EBITDA for)"
    persistent
  >
    <div class="w-100 table-responsive mt-4 mb-0">
      <table
        v-for="(mainItem, tableIndex) in tableRows"
        :key="mainItem.id"
        class="w-100 table custom-table mb-0 table-bordered table-striped"
      >
        <thead class="w-100">
          <tr class="w-100">
            <th class="table-header name">{{ mainItem.name }}</th>
            <th
              v-for="item in chapter9.item_5_info"
              :key="item.financial_year"
              class="table-header"
              :style="{ width: 55 / chapter9.item_5_info.length + '%' }"
            >
              <div v-if="!tableIndex" class="w-100 d-flex justify-content-end">
                {{ item.financial_year || "" }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="w-100">
          <tr
            v-for="rows in mainItem.sub"
            :id="rows.id"
            :key="rows.id"
            class="w-100"
          >
            <td class="table-rows name">
              <p class="data-text mb-0">
                {{ rows.name }}
              </p>
            </td>
            <td
              v-for="(item5, Index) in chapter9.item_5_info"
              :key="item5.financial_year"
              class="table-rows"
              :style="{ width: 55 / chapter9.item_5_info.length + '%' }"
            >
              <div class="w-100 d-flex justify-content-end">
                {{ NumberFormatter(chapter9.item_5_info[Index][rows.key]) }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </CustomModalContainer>
</template>
<script setup>
import { ref } from "vue";
import { useComputeIncomeTaxStore } from "../../../../store/incometax.store";
import { storeToRefs } from "pinia";
import { generate } from "randomized-string";
import { NumberFormatter } from "../../../../helpers/numberFormatter";
import { CustomModalContainer } from "../../../common";
//
defineProps({
  open: Boolean,
  setOpen: {
    type: Function,
    default: () => {},
  },
});

const computeIncomeTaxStore = useComputeIncomeTaxStore();
const { chapter9 } = storeToRefs(computeIncomeTaxStore);
const tableRows = ref([
  {
    id: generate() + 1,
    name: "1. Calculation for Adjusted EBITDA",
    sub: [
      {
        id: generate() + "i",
        key: "ebitda",
        name: "i. EBITDA as per Financials",
      },
      {
        id: generate() + "ii",
        key: "exempt_income_as_per_chapter_7",
        name: "ii. Less- Exempt Income as per Chapter-7",
      },
      {
        id: generate() + "iii",
        key: "adjusted_ebitda",
        name: "iii. Adjusted EBITDA",
      },
    ],
  },
  {
    id: generate() + 2,
    name: "2. Calculation for Net Interest Expenditure",
    sub: [
      {
        id: generate() + "-i",
        key: "previous_year_allowance_available",
        name: "i. Previous Year Brought Forward of Net Interest Expenditure",
      },

      {
        id: generate() + "-ii",
        key: "interest_expenditure_for_the_period",
        name: "ii. Interest Expenditure for the period (after netting of Interest Disallowed under any other article of the decree ex-article 28 & 31)",
      },
      {
        id: generate() + "-iii",
        key: "taxable_interest_income",
        name: "iii. Taxable Interest Income for the period",
      },
      {
        id: generate() + "-iv",
        key: "net_interest_expenditure",
        name: "iv. Net Interest Expenditure",
      },
    ],
  },
  {
    id: generate() + "-" + 3,
    name: "3. Calculation for Disallowance and Allowance",
    sub: [
      {
        id: generate() + "-i",
        key: "disallowed_interest_expense",
        name: "i. Calculation for Disallowance",
      },
      {
        id: generate() + "-ii",
        key: "net_interest_expenditure_allowable_final",
        name: "ii. Net Interest Expenditure Allowable-Final",
      },
    ],
  },
  {
    id: generate() + "-" + 4,
    name: "4. Carried Forward Details-",
    sub: [
      {
        id: generate() + "-i",
        key: "interest_for_the_year",
        name: "i. Disallowed Interest to carried forward",
      },
    ],
  },
  // {
  //   id: 11,
  //   key: "net_interest_expenditure_allowable_final",
  //   name: "Net Interest Expenditure Allowable-Final",
  // },
  // {
  //   id: 12,
  //   key: "opening_before_interest",
  //   name: "Opening b/f Interest (disallowed in previous year)",
  // },
  // {
  //   id: 13,
  //   key: "utilised_out_of_year",
  //   name: "Utilised out of year",
  // },
  // {
  //   id: 14,
  //   key: "balance_to_cf",
  //   name: "Balance to c/f",
  // },
]);
</script>

<style scoped>
.card-container {
  background: #fafbff;
  border-radius: 5px;
  padding: 33px 27px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.card-container::-webkit-scrollbar {
  display: none;
}
.table-header.name {
  width: 45%;
}
.table-rows.name {
  width: 45%;
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /* identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
}
.table-header {
  min-height: 39px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2.5px solid #fff;
  width: 100px;
}
.table-header.sl-no {
  width: 10px !important;
}
.table-rows {
  background: #ffffff;
  border: 2.5px solid #fff;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input {
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input:focus {
  outline: none;
}
.save-button {
  width: 110px;
  min-height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.delete-button {
  width: 50px;
  height: 44px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.gray {
  background: #deeded;
}
</style>

<template>
  <tr v-if="!!index || index === 0">
    <td class="table-rows">
      <input
        v-model="initialDate"
        type="date"
        class="custom-input disabled w-100"
        placeholder="Name"
        :disabled="$route.query.current_business === 'master' || !!status"
      />
    </td>
    <td v-if="assets.length" class="table-rows">
      <select
        @change="handleAsset"
        :value="asset"
        class="custom-input w-100"
        :disabled="$route.query.current_business === 'master' || !!status"
      >
        <option v-for="(asset, index) in assets" :value="asset.id">
          {{ asset.name_of_asset }}
        </option>
      </select>
    </td>
    <td v-else class="table-rows">
      <input
        v-model="data[props.article][props.type][index].name_of_asset"
        type="text"
        class="custom-input w-100"
        :disabled="$route.query.current_business === 'master' || !!status"
        placeholder="Name"
      />
    </td>
    <td class="table-rows">
      <input
        v-model="
          data[props.article][props.type][index].subsequent_transfer_date
        "
        type="date"
        class="custom-input w-100"
        :disabled="
          !data[props.article][props.type][index].date_of_initial_transfer ||
          $route.query.current_business === 'master' ||
          !!status
        "
        placeholder="dd-mm-yyyy"
        :min="data[props.article][props.type][index].date_of_initial_transfer"
      />
    </td>
    <td class="table-rows">
      <input
        v-model="
          data[props.article][props.type][index].name_of_transferee_entity
        "
        :disabled="assets.length || !!status"
        type="text"
        class="custom-input disabled w-100"
        placeholder="Name"
      />
    </td>
    <td class="table-rows">
      <input
        v-model="
          data[props.article][props.type][index].net_book_value_on_transfer_date
        "
        type="number"
        class="custom-input w-100"
        :disabled="$route.query.current_business === 'master' || !!status"
        placeholder="Value"
      />
    </td>
    <td class="table-rows">
      <input
        v-model="data[props.article][props.type][index].market_value"
        type="number"
        class="custom-input w-100"
        :disabled="$route.query.current_business === 'master' || !!status"
        placeholder="Value"
      />
    </td>
    <td class="table-rows">
      <p class="button-container gray bold px-2">
        {{
          NumberFormatter(data[props.article][props.type][index].taxable_amount)
        }}
      </p>
    </td>
    <td class="table-rows">
      <div
        class="w-100 button-container d-flex align-items-center justify-content-between"
      >
        <button class="save-button w-100" @click="deleteItem">
          <img src="@/assets/svg/trash.svg" alt="" />
        </button>
      </div>
    </td>
  </tr>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useChapter8Store } from "../../../../store/chapter8";
import { onMounted, ref, watch } from "vue";
import { NumberFormatter } from "../../../../helpers/numberFormatter";
import { useStatusStore } from "../../../../store/statusStore";

const props = defineProps({
  id: String,
  article: String,
  type: {
    type: String,
    default: "assets"
  }
});
const statusStore = useStatusStore();
const { status } = storeToRefs(statusStore);
const chapter8Store = useChapter8Store();
const { data, selectedAssets } = storeToRefs(chapter8Store);

const initialDate = ref("");
const asset = ref(null);
const assets = ref([]);

const index = ref(null);
const doCalculation = (dateOfTransfer, subsequentDate) => {
  if (dateOfTransfer && subsequentDate) {
    const subDate = new Date(subsequentDate);
    const initialDate = new Date(dateOfTransfer);
    const difference = subDate.getFullYear() - initialDate.getFullYear();
    if (Math.abs(difference) < 2) {
      if (props.type === "assets") {
        data.value[props.article][props.type][index.value].taxable_amount =
          Number(
            data.value[props.article][props.type][index.value].market_value
          ) -
          Number(
            data.value[props.article][props.type][index.value]
              .net_book_value_on_transfer_date
          );
      } else if (props.type === "liabilities") {
        data.value[props.article][props.type][index.value].taxable_amount =
          Number(
            data.value[props.article][props.type][index.value]
              .net_book_value_on_transfer_date
          ) -
          Number(
            data.value[props.article][props.type][index.value].market_value
          );
      }
    } else {
      data.value[props.article][props.type][index.value].taxable_amount = 0;
    }
  }
};

watch(
  initialDate,
  (newValue) => {
    const assetType = props.article.includes("26")
      ? props.type === "assets"
        ? data.value.article_26_assets
        : props.type === "liabilities"
        ? data.value.article_26_liabilities
        : []
      : props.article.includes("27")
      ? props.type === "assets"
        ? data.value.article_27_assets
        : props.type === "liabilities"
        ? data.value.article_27_liabilities
        : [{ id: "First mistake" }]
      : [];

    data.value[props.article][props.type][
      index.value
    ].date_of_initial_transfer = newValue;
    doCalculation(
      newValue,
      data.value[props.article][props.type][index.value]
        .subsequent_transfer_date
    );
    assets.value = assetType.filter((asset) => asset.transfer_date == newValue);
  },
  { deep: true }
);
watch(
  data,
  (newValue) => {
    const NewValue = newValue[props.article][props.type][index.value];
    const assetType = props.article.includes("26")
      ? props.type === "assets"
        ? data.value.article_26_assets
        : props.type === "liabilities"
        ? data.value.article_26_liabilities
        : []
      : props.article.includes("27")
      ? props.type === "assets"
        ? data.value.article_27_assets
        : props.type === "liabilities"
        ? data.value.article_27_liabilities
        : []
      : [];
    const newAsset = assetType.find((Asset) => Asset.id === asset.value);

    if (newAsset) {
      data.value[props.article][props.type][
        index.value
      ].name_of_transferee_entity = newAsset?.name_of_transferee_entity;
      data.value[props.article][props.type][
        index.value
      ].net_book_value_on_transfer_date =
        newAsset?.net_book_value_as_transfer_date;
      data.value[props.article][props.type][index.value]["name_of_asset"] =
        newAsset?.name_of_asset;
    }
    doCalculation(initialDate.value, NewValue?.subsequent_transfer_date);
  },
  { deep: true }
);
const handleAsset = (e) => {
  const value = e.target.value;
  const assetType = props.article.includes("26")
    ? props.type === "assets"
      ? data.value.article_26_assets
      : props.type === "liabilities"
      ? data.value.article_26_liabilities
      : []
    : props.article.includes("27")
    ? props.type === "assets"
      ? data.value.article_27_assets
      : props.type === "liabilities"
      ? data.value.article_27_liabilities
      : []
    : [];
  const newValue = assetType.find((asset) => asset.id === value);
  const alreadyAddedCheck = selectedAssets.value.find(
    (asset) => asset?.id === value
  );

  if (alreadyAddedCheck) {
    if (confirm("Item Already added should you type it manually")) {
      assets.value = [];
    }
    asset.value = "";
    return;
  }
  data.value[props.article][props.type][index.value].name_of_transferee_entity =
    newValue?.name_of_transferee_entity;
  data.value[props.article][props.type][
    index.value
  ].net_book_value_on_transfer_date = newValue?.net_book_value_as_transfer_date;
  data.value[props.article][props.type][index.value]["name_of_asset"] =
    newValue?.name_of_asset;
  selectedAssets.value = selectedAssets.value.filter(
    (a) => a.id !== asset.value
  );
  selectedAssets.value.push(newValue);
  asset.value = value;
};
const deleteItem = () => {
  if (confirm("Are sure you want to delete this item")) {
    const id = data.value[props.article][props.type][index.value]?.id;
    data.value[props.article][props.type] = data.value[props.article][
      props.type
    ].filter((d) => d?.id != id);
  }
};
onMounted(async () => {
  data.value[props.article][props.type].forEach((value, Index) => {
    if (value.id === props.id) {
      index.value = Index;
    }
    const initial_transfer_date =
      data.value[props.article][props.type][index.value]
        ?.date_of_initial_transfer;

    if (initial_transfer_date) {
      initialDate.value = initial_transfer_date;
      const assetType = props.article.includes("26")
        ? props.type === "assets"
          ? data.value.article_26_assets
          : props.type === "liabilities"
          ? data.value.article_26_liabilities
          : []
        : props.article.includes("27")
        ? props.type === "assets"
          ? data.value.article_27_assets
          : props.type === "liabilities"
          ? data.value.article_27_liabilities
          : []
        : [];

      assets.value = assetType.filter(
        (asset) => asset.transfer_date == initial_transfer_date
      );
      const nameOfAsset =
        data.value[props.article][props.type][index.value]?.name_of_asset;
      const newValue = assetType.find(
        (asset) => asset?.name_of_asset == nameOfAsset
      );
      if (newValue) {
        asset.value = newValue?.id;
        selectedAssets.value.push(newValue);
      }
    }
  });
});
</script>

<style scoped>
.header {
  padding: 6px 9px;
  min-height: 60px;
  background: #0f75bc;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #fafbff;
}
.custom-input {
  height: 44px;
  padding: 11px 13px;
  margin-top: 10px;
  border: 1px solid rgba(116, 113, 113, 0.3);
}
.custom-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
  text-align: start;
}
.custom-input.total {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.gray {
  background: #deeded;
}
.disabled {
  background-color: #eee;
}
.save-button {
  width: 110px;
  height: 44px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.button-container {
  height: 44px;
  padding: 11px 13px;
  border: 1px solid rgba(116, 113, 113, 0.3);
  margin-top: 10px;
}
.wrapper {
  overflow-x: auto;
}
.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border: 0.5px solid rgba(149, 146, 146, 0.3);
}
.table-header {
  min-height: 39px;
  background: #0f75bc;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2.5px solid #fff;
  width: 100px;
}
.table-header.sl-no {
  width: 10px !important;
}
.table-rows {
  background: #ffffff;
  border: 2.5px solid #fff;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
</style>

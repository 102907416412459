<template>
  <div class="w-100">
    <div
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 total-amount">Total Income of Freezone Person</p>
      <p class="mb-0 total-amount">
        {{ NumberFormatter(totalIncomeOfQFZP) }}
      </p>
    </div>
    <div class="header d-flex align-items-center w-100 justify-content-between">
      <div class="d-flex align-items-center w-75">
        <p class="mb-0 body-title count">
          <b> C. </b>
        </p>
        <p class="mb-0 d-flex body-title item-name">
          Income from Non- Qualifying activities (included above)
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { NumberFormatter } from "../../../helpers/numberFormatter";
import { useQFZCalculatorStore } from "../../../store/QFZcalculator";

const QFZCalculator = useQFZCalculatorStore();
const { totalIncomeOfQFZP } = storeToRefs(QFZCalculator);
</script>

<style scoped>
.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  /*background: #fafbff;*/
  padding-right: 100px;
  padding-left: 26px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 26px;
}
.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}
.header {
  min-height: 44px;
  /*background: #0f75bc;*/
  padding-right: 100px;
  padding-left: 26px;
}
.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 31px;
  letter-spacing: 0.055em;
  color: #382628;
}
.count {
  flex: 0.75;
}
.item-name {
  flex: 18;
  margin-top: -11px;
}
@media screen and (max-width: 600px) {
  .header {
    padding-left: 8px;
  }
  .count {
    flex: 2;
  }
}
b,
strong {
  margin-left: -16px;
}
.w-75 {
  margin-top: 15px;
}
</style>

<template>
  <div class="w-100 d-flex align-items-center justify-content-between">
    <CustomModalContainer :open="open" :set-open="setOpen">
      <CustomClickableInput
        v-model="
          dividenIncome.dividends_and_other_profit_distributions_received
        "
        name="1.) Dividends and other profit distributions received from, a) Resident Juridical person & b) Participating Interest in a foreign juridical person"
        model="dividends_and_other_profit_distributions_received"
        bg-color="gray"
        :on-click="false"
        :on-tab-and-enter="handleTabEnter"
        :input-props="{
          onfocus: () => {
            setOriginalValue(
              'dividends_and_other_profit_distributions_received',
              dividenIncome.dividends_and_other_profit_distributions_received
            );
          },
          onblur: () => {
            autoSave('dividends_and_other_profit_distributions_received');
          },
        }"
      />
      <div
        class="w-100 table-body d-flex align-items-center justify-content-between"
      >
        <h5 class="mb-0 body-title">2.) Other Income</h5>
      </div>
      <CustomClickableInput
        v-for="(item, index) in dividenOtherIncome"
        :key="item.model"
        v-model="dividenIncome[item.model]"
        :model="item.model"
        :name="item.name"
        :sub="item.sub"
        :show-input="item.showInput"
        :bg-color="!index || !(index % 2) ? 'gray' : 'white'"
        :on-click="false"
        :on-tab-and-enter="handleTabEnter"
        :input-props="{
          onfocus: () =>
            setOriginalValue(item.model, dividenIncome[item.model]),
          onblur: () => autoSave(item.model),
        }"
      />
    </CustomModalContainer>
    <h5 class="mb-0 body-title">4 Dividend Income</h5>
    <h5 class="body-title bold mb-0 cursor-pointer" @click="setOpen(true)">
      <b>{{ NumberFormatter(dividendIncome) }}</b>
    </h5>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { CustomClickableInput, CustomModalContainer } from "../../../../common";
import { NumberFormatter } from "../../../../../helpers/numberFormatter";
import { useProfitAndLossStore } from "../../../../../store/ProfitAdLoss";
import { storeToRefs } from "pinia";
import { autoSaveIncome } from "./helper";
import { useRoute, useRouter } from "vue-router";
import { useStatusStore } from "../../../../../store/statusStore";

const props = defineProps({
  setSavingStatus: {
    type: Function,
    default: () => {},
  },
});
const open = ref(false);
const statusStore = useStatusStore();
const { status } = storeToRefs(statusStore);
const setOpen = (value) => {
  if (status.value || route.query.business === "master") {
    return;
  }
  if (window.location.pathname.includes("view")) {
    return;
  }
  open.value = value;
};
const route = useRoute();
const router = useRouter();
const profileAndLossStore = useProfitAndLossStore();
const dividenIncomeOriginalValue = ref("");
const { dividenIncome, DividenIncome, groupDatadividenIncome } =
  storeToRefs(profileAndLossStore);
const dividendIncome = computed(() => {
  if (route.query.business === "master") {
    return groupDatadividenIncome.value;
  }
  return DividenIncome.value(route);
});
const dividenOtherIncome = ref([
  {
    model: "any_other_income_participating_interest_specified_article_23",
    name: "2.i Any other income from a Participating Interest as specified in Article-23 ",
    havePopup: false,
    sub: true,
    value: "",
  },
  {
    model:
      "income_foreign_permanent_establishment_that_meet_condition_article_24",
    name: "2.ii Income of a Foreign Permanent Establishment that meet condition of Article 24",
    havePopup: false,
    sub: true,
    value: "",
  },
]);
const setShowInput = async (model, value) => {
  const newData = dividenOtherIncome.value.map((item) => {
    if (item.model === model) {
      return { ...item, showInput: value };
    }
    return { ...item, showInput: false };
  });
  dividenOtherIncome.value = newData;
};
const handleTabEnter = async (model) => {
  if (model === "dividends_and_other_profit_distributions_received") {
    await setShowInput(
      "any_other_income_participating_interest_specified_article_23",
      true
    );
  } else {
    const nextModel =
      dividenOtherIncome.value[
        dividenOtherIncome.value.map((e) => e.model).indexOf(model) + 1
      ];
    if (nextModel) {
      await setShowInput(nextModel.model, true);
    } else {
      await setShowInput(model, false);
    }
  }
};
const autoSave = async (model) => {
  let previousValue = dividenOtherIncome.value.find(
    (item) => item.model === model
  )?.value;
  let currentValue = dividenIncome.value[model];
  if (model === "dividends_and_other_profit_distributions_received") {
    previousValue = dividenIncomeOriginalValue.value;
    currentValue =
      dividenIncome.value.dividends_and_other_profit_distributions_received;
  }
  await autoSaveIncome(
    previousValue,
    currentValue,
    props.setSavingStatus,
    route,
    router
  );
};
const setOriginalValue = (key, value) => {
  if (key === "dividends_and_other_profit_distributions_received") {
    dividenIncomeOriginalValue.value = value;
    return;
  }
  const index = dividenOtherIncome.value.findIndex(
    (item) => item.model === key
  );
  dividenOtherIncome.value[index].value = value;
};
</script>

<style scoped>
.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
}
.table-body.sub {
  padding-left: 46px !important;
}
.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}
.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}
.input-custom {
  border: none;
}
.input-custom:focus {
  outline: none;
}
.save-button {
  width: 110px;
  height: 44px;
  background: #0f75bc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}
</style>

<template>
  <SideNavContainer side-nav-type="financialData">
    <EntityChecker>
      <LoaderContainer :loading="loading" :error="error">
        <div class="row">
          <div class="col-lg-12">
            <TitleAndDate :title="name" :show-entity="false" />
            <RevenueComparison
              :revenue-comparison="revenueComparison"
              :labels="labels"
              :comparison-percent="data.revenue_comparison.comparison_percent"
              :current-year-profit="data.revenue_profit.current_year_profit"
              :current-year-revenue="data.revenue_profit.current_year_revenue"
            />
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-7 mt-4">
                  <div class="w-100 chart-container p-2">
                    <h4 class="title my-2">Last 5 years income</h4>
                    <Last5YearsChart
                      v-if="data.taxable_income_chart"
                      :taxable-income-chart="data.taxable_income_chart"
                    />
                  </div>
                </div>
                <div class="col-lg-5 mt-4">
                  <div class="w-100 chart-container p-2">
                    <h4 class="title my-2">
                      Assets and Liabilities Comparison
                    </h4>
                    <AssetsAndLiabilitiesComparison
                      :asset-liabilities-comparison="
                        data.asset_liabilities_comparison
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-7 mt-4 my-auto">
                  <div class="w-100 chart-container p-2">
                    <h4 class="title my-2">Tax paid analysis</h4>
                    <TaxPaidAnalysis
                      :tax-paid-analysis="data.tax_paid_analysis"
                    />
                  </div>
                </div>
                <div class="col-lg-5 mt-4">
                  <div class="w-100 chart-container p-2">
                    <h4 class="title my-2">NP Ratio</h4>
                    <Last5YearsChart
                      v-if="data.np_ratio"
                      :taxable-income-chart="data.np_ratio"
                      labels="Np ratio"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Marquee :texts="data.marquee_text" />
      </LoaderContainer>
    </EntityChecker>
  </SideNavContainer>
</template>

<script setup>
import SideNavContainer from "../../layout/SideNavContainer.vue";
import RevenueComparison from "../common/RevenueComparison.vue";
import TitleAndDate from "../common/TitleAndDate.vue";
import { useEntityStore } from "../../../store/entity";
import { onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { getEntityItem } from "../../../store/entityActions/get";
import EntityChecker from "../../entity/AddEntity/EntityChecker.vue";
import { useEntityDashboardStore } from "../../../store/entityDashboard";
import Last5YearsChart from "../common/Last5YearsChart.vue";
import AssetsAndLiabilitiesComparison from "../common/AssetsAndLiabilitiesComparison.vue";
import TaxPaidAnalysis from "../common/TaxPaidAnalysis.vue";
import Marquee from "../../common/Marquee.vue";

const entityStore = useEntityStore();
const { entity } = storeToRefs(entityStore);
const entityDashboard = useEntityDashboardStore();
const { data, loading, error } = storeToRefs(entityDashboard);
const route = useRoute();
const name = ref("");
const revenueComparison = ref([]);
const labels = ref([]);

watch(
  () => data.value.revenue_comparison,
  (newValue) => {
    const Data = [];
    for (let x in newValue) {
      if (x !== "comparison_percent") {
        labels.value.push(x);
        Data.push(newValue[x]);
      }
    }
    revenueComparison.value = Data;
  },
  { deep: true }
);
const setName = async () => {
  if (!entity.value.data) {
    const id = route.params.id;
    await getEntityItem("entity", "/entity", id);
  }
  name.value = entity.value.data?.entity[0].entity_name;
};
onMounted(async () => {
  await setName();
  await entityDashboard.getDasboardItems(route.params.id);
  for (let x in data.value.revenue_comparison) {
    if (x !== "comparison_percent") {
      labels.value.push(x);
      revenueComparison.value.push(data.value.revenue_comparison[x]);
    }
  }
});
</script>

<style scoped>
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 33px;
  letter-spacing: 0.055em;
  color: #000000;
}
.chart-container {
  border: 1px solid rgba(116, 113, 113, 0.2);
  box-shadow: 4px 4px 10px 2px rgba(187, 187, 187, 0.25);
  border-radius: 5px;
  background: #deeded;
}
</style>

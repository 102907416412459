<template>
  <AlertModal
    :open="userModal"
    message="It seems your subscription per user is up"
    sub-message="Please buy subscription to continue !!"
    :on-confirm="
      () => {
        $router.push(`/subscription`);
      }
    "
    :on-cancel="closeUsersModal"
    button-name="Go to subscription"
  />
  <ChoosePlan :open="open" :closeModal="closeModal" />
  <div class="add-new d-flex align-items-center" @click="checkAndPush">
    <img src="@/assets/svg/add-new.svg" alt="" class="mb-0 mr-2" />
    Add New
  </div>
</template>

<script setup>
import { ref } from "vue";
import ChoosePlan from "../entity/AddEntity/ChoosePlan.vue";
import { useProfileStore } from "../../store/profile";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { AlertModal } from "../common";
const open = ref(false);
const closeModal = () => {
  open.value = false;
};
const userModal = ref(false);
const closeOpenModal = (value) => {
  userModal.value = value;
};
const closeUsersModal = () => {
  closeOpenModal(false);
};
const profileStore = useProfileStore();
const { profile, remaining_user_count } = storeToRefs(profileStore);
const router = useRouter();
const checkAndPush = () => {
  if (profile.value.data?.has_subscription === "Y") {
    if (remaining_user_count.value) {
      router.push("/users/add-user");
    } else {
      userModal.value = open;
    }
  } else {
    open.value = true;
  }
};
</script>

<style scoped>
.add-new {
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

<template>
  <BreadCrumb :links="breadCrumbLinks" />
</template>

<script setup>
import { ref, watch, defineProps } from "vue";
import { generate } from "randomized-string";
import BreadCrumb from "../common/BreadCrumb.vue";
import { useRoute } from "vue-router";

const props = defineProps({
  entityName: {
    type: String,
    default: "",
  },
});
const route = useRoute();
const name = ref(props.entityName);

const breadCrumbLinks = ref([
  {
    id: generate(),
    name: "Home",
    link: "/",
    disabled: false,
    active: false,
  },
  {
    id: generate(),
    name: "Entity",
    link: "/entity",
    disabled: false,
    active: false,
  },
  {
    id: "master-data",
    name: name,
    link: "/entity/master-data/" + route.params.id,
    disabled: false,
    active: false,
  },
  {
    id: "financial-data",
    name: "Financial Data",
    link: `/entity/financial-data/${route.params.id}?financial_year=${
      route.query.financial_year
    }&financial_period=${route.query.financial_period}${
      route.query.business ? "&business=" + route.query.business : ""
    }`,
    disabled: false,
    active: true,
  },
]);
watch(
  () => route.query,
  (newValue) => {
    breadCrumbLinks.value = breadCrumbLinks.value.map((item) => {
      if (item.id === "financial-data") {
        return {
          ...item,
          link: `/entity/${route.params.id}?financial_year=${
            newValue.financial_year
          }&financial_period=${newValue.financial_period}${
            newValue.business ? "&business=" + newValue.business : ""
          }`,
        };
      }
      return item;
    });
  },
  { deep: true }
);
watch(
  () => props.entityName,
  (newValue) => {
    breadCrumbLinks.value = breadCrumbLinks.value.map((item) => {
      if (item.id === "master-data") {
        return { ...item, name: newValue };
      }
      return item;
    });
  },
  { deep: true }
);
</script>

<style scoped></style>

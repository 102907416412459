<template>
  <v-dialog v-bind="$props" v-model="toggle" width="auto">
    <div
      :class="`card-container ${isSquare ? 'square-box' : ''}`"
      :style="{
        'min-width': props.minWidth,
        'max-width': props.maxWidth,
      }"
    >
      <div v-if="showCloseIcon" class="d-flex w-100 justify-content-end">
        <img
          src="@/assets/svg/close-circle.svg"
          class="cursor-pointer"
          alt
          @click="closeModal()"
        />
      </div>
      <h4 v-if="title" class="bold title mt-4">{{ title }}</h4>
      <div class="w-100 mt-4">
        <slot />
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  open: Boolean,
  setOpen: {
    type: Function,
    default: () => {},
  },
  minWidth: {
    type: String,
    default: "",
  },
  maxWidth: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
  onClose: {
    type: Function,
    default: () => {},
  },
  showCloseIcon: {
    type: Boolean,
    default: true,
  },
  onOpen: {
    type: Function,
    default: () => {},
  },
  isSquare: {
    type: Boolean,
    default: false,
  },
});
const toggle = ref(props.open);
watch(toggle, (newValue) => props.setOpen(newValue));
watch(
  () => props.open,
  (newValue) => {
    toggle.value = newValue;
  },
  { deep: true }
);
const closeModal = () => {
  props.onClose();
  toggle.value = false;
  props.setOpen(false);
};
watch(toggle, (newValue) => {
  if (newValue) {
    props.onOpen();
  }
});
</script>

<style scoped>
.card-container {
  background: #fafbff;
  border-radius: 5px;
  padding: 33px 27px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.card-container::-webkit-scrollbar {
  display: none;
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /* identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}

@media screen and (max-width: 600px) {
  .square-box {
    height: 70vh;
  }
}
</style>

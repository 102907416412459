
export const nonCurrentAssetData = [
  {
    model: "tangible_assets",
    name: "(i) Tangible assets",
    sub: true
  },
  {
    model: "intangible_assets",
    name: "(ii) Intangible assets",
    sub: true
  },
  {
    model: "capital_work_in_progress",
    name: "(iii) Capital work-in-progress",
    sub: true
  },
  {
    model: "intangible_assets_under_development",
    name: "(iv) Intangible assets under development",
    sub: true
  },
  {
    model: "non_current_investments",
    name: "(b) Non-current investments",
    sub: false
  },
  {
    model: "deferred_tax_assets_net",
    name: "(c) Deferred tax assets (net)",
    sub: false
  },
  {
    model: "long_term_loan_and_advance",
    name: "(d) Long term loans and advances",
    sub: false
  },
  {
    model: "other_non_current_assets",
    name: "(e) Other non-current assets",
    sub: false
  }
];
export const currentAssetData = [
  {
    model: "current_investment",
    name: "(a) Current investments",
    sub: false
  },
  {
    model: "inventories",
    name: "(b) Inventories",
    sub: false
  },
  {
    model: "trade_receivables",
    name: "(c) Trade receivables",
    sub: false
  },
  {
    model: "cash_and_bank_balance",
    name: "(d) Cash and Bank balances",
    sub: false
  },
  {
    model: "short_term_loan_and_advance",
    name: "(e) Short-term loans and advances",
    sub: false
  },
  {
    model: "other_current_assets",
    name: "(f) Other current assets",
    sub: false
  }
];

import fetchWrapper from "@/helpers/fetchWrapper";
import fetchFileWrapper from "@/helpers/fileApi";
import { Toast } from "../../helpers/toast";
import { getWarningMessage } from "../../helpers/errorHandler";

export const updateEntity = async (
  endPoint,
  payload,
  setMessage,
  callBack = () => {},
  file = false
) => {
  let toast = new Toast("Updating....");
  try {
    console.log(payload);
    let res;
    if (file) {
      res = await fetchFileWrapper.put(endPoint, payload);
    } else {
      res = await fetchWrapper.put(endPoint, payload);
    }
    if (res.data.result === 1) {
      toast.makeSuccessToast(res.data.message);
      callBack(res?.data);
    } else {
      toast.makeErrorToast(getWarningMessage(res));
    }
  } catch (err) {
    console.log(err);
    toast.makeErrorToast(err?.response?.data?.message || "");
  }
};

<template>
  <SideNavContainer title="Business corporate tax calculator">
    <LoaderContainer :loading="loading" :error="error">
      <CustomModalContainer :open="open.value" :set-open="setOpen">
        <div class="w-100 disclaimer-container p-3">
          <h4 class="note-text mb-0">
            <b class="text-warning warning-icon mr-3 mb-0">&#x26A0;</b>
            <span class="mb-0">Note</span>
          </h4>
          <p class="mb-0 body-title">{{ open.message }}</p>
        </div>
      </CustomModalContainer>
      <div class="container-fluid wrapper">
        <div class="row align-items-center">
          <div class="col-md-3">
            <Button />
          </div>
          <div class="col-md-9">
            <div class="card border border-white shadow bg-body rounded-4 my-4">
              <div class="card-body">
                <div class="w-100 table-responsive mt-4 mb-4">
                  <table class="table custom-table">
                    <thead>
                      <tr>
                        <th class="table-header w-50">Particulars</th>
                        <div class="head">
                          <th class="table-header w-20">Amount in AED</th>
                        </div>
                        <th class="table-header w-20">Calculated Fields</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in data" :key="item.id">
                        <td class="table-rows align-middle">
                          <div class="w-100 d-flex align-items-center">
                            <p class="mb-0 bold flex-1 data-text">
                              <b>{{
                                getSerialNumber(
                                  item.id === "adjustments_per_ct_law"
                                    ? "empty"
                                    : index + 1
                                )
                              }}</b>
                            </p>
                            <p
                              v-if="item.id === 'adjustments_per_ct_law'"
                              class="mb-0 bold flex-2 data-text"
                            >
                              <b>
                                {{ item.name }}
                              </b>
                            </p>
                            <p v-else class="mb-0 flex-2 data-text">
                              {{ item.name }}
                            </p>
                          </div>
                        </td>
                        <td class="table-rows2 align-middle">
                          <p
                            v-if="
                              !item.showAmountInput &&
                              item.id !== 'adjustments_per_ct_law' &&
                              item.id !== 'ebitda'
                            "
                            class="mb-0 data-text"
                            @click="openCloseInput(item.id, 'amount', true)"
                          >
                            {{ NumberFormatter(item.amount) }}
                          </p>
                          <input
                            v-if="
                              !['adjustments_per_ct_law', 'ebitda'].includes(
                                item.id
                              )
                            "
                            :id="`input-amount-${item.id}`"
                            v-model="data[index].amount"
                            class="custom-input w-100"
                            :hidden="!item.showAmountInput"
                            type="text"
                            @blur="
                              (e) =>
                                openCloseInput(
                                  item.id,
                                  'amount',
                                  false,
                                  e.target.value,
                                  index
                                )
                            "
                            @keydown.tab.enter="
                              (e) => handleTab(e, item.id, 'amount')
                            "
                            @keypress="(e) => checkAmount(e, item.id)"
                          />
                          <p
                            v-if="['ebitda'].includes(item.id)"
                            class="mb-0 ml-0 align-items-center total-amount"
                          >
                            {{
                              taxCalculator[item.amount_calculated_fields] ===
                              "Apply SBR"
                                ? taxCalculator[item.amount_calculated_fields]
                                : NumberFormatter(
                                    taxCalculator[item.amount_calculated_fields]
                                  )
                            }}
                          </p>
                        </td>
                        <td class="table-rows align-middle">
                          <p
                            v-if="
                              item.id !== 'adjustments_per_ct_law' &&
                              item.id !== 'ebidta_fincial' &&
                              item.id !== 'less_exempt'
                            "
                            class="mb-0 total-amount"
                          >
                            {{
                              taxCalculator[item.amount_calculated_fields] ===
                              "Apply SBR"
                                ? taxCalculator[item.amount_calculated_fields]
                                : NumberFormatter(
                                    taxCalculator[item.amount_calculated_fields]
                                  )
                            }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="table-rows align-middle py-5">
                          <p class="mb-0 total-amount ms-auto">
                            Taxable Income
                          </p>
                        </td>
                        <td class="table-rows align-middle py-5"></td>
                        <td class="table-rows border align-middle py-5">
                          <p class="mb-0 total-amount">
                            {{ NumberFormatter(taxableIncome) }}
                          </p>
                        </td>
                      </tr>

                      <tr>
                        <td class="table-total align-middle py-5">
                          <p class="mb-0 total-text">Tax Liability</p>
                        </td>
                        <td class="table-total align-middle py-5"></td>
                        <td class="table-total border align-middle py-5">
                          <Exemption />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 disclaimer-container p-3">
        <h4 class="total-amount">
          <b class="text-warning">&#x26A0;</b>
          Disclaimer
        </h4>
        <p class="mb-0 body-title">
          Our corporate tax calculator provides an estimate of tax liability
          based on our interpretation of current law in force. It is not
          professional tax advice, subject to change, and may not be accurate or
          applicable. Consult a tax professional for personalized guidance and
          do not rely solely on the calculator's results.
        </p>
      </div>
      <Marquee :texts="dashboardData.marquee_text || []" />
    </LoaderContainer>
  </SideNavContainer>
</template>

<script setup>
import SideNavContainer from "../layout/SideNavContainer.vue";
import { useCTaxCalculatorBusinessStore } from "../../store/CTaxCalculatorBusiness";
import { storeToRefs } from "pinia";
import { NumberFormatter } from "../../helpers/numberFormatter";
import Marquee from "../common/Marquee.vue";
import { insertChar } from "../../helpers/stringUtils";
import { onMounted, ref } from "vue";
import { useDahboardStore } from "../../store/dashboard";
import { CustomModalContainer, LoaderContainer } from "../common";
import Exemption from "./BusinessCalculator/Exemption.vue";
import Button from "./Button.vue";
const taxCalculator = useCTaxCalculatorBusinessStore();
const { data, taxableIncome } = storeToRefs(taxCalculator);
const dashboardStore = useDahboardStore();
const { data: dashboardData, loading, error } = storeToRefs(dashboardStore);

const open = ref({
  value: false,
  message: "",
});
const setOpen = (value, message = "") => {
  if (message) {
    open.value.message = message;
  }
  open.value.value = value;
};
const openCloseInput = async (id, type, value, amount, index) => {
  data.value = data.value.map((item) => {
    if (item.id === id) {
      if (type === "amount") return { ...item, showAmountInput: value };
      else if (
        type === "amount_calculated_fields" &&
        (id === "ebitda" ||
          id === "add_back_expenses_disallowed" ||
          id === "exempt_income_to_be_deducted")
      )
        return { ...item, showAmountCalculatedInput: value };
    }
    return item;
  });
  if (value) {
    const input = await document.getElementById(`input-${type}-${id}`);
    input.focus();
  }
  if (
    amount &&
    (id === "ebitda" ||
      id === "total_revenue_for_the_year" ||
      id === "interest_expenditure")
  ) {
    const value = Number(data.value[index].amount);
    if (value < 0) {
      data.value[index].amount = "";
      return;
    }
    if (id === "ebitda") {
      const ebitda = data.value.find(
        (item) => item.id === "total_revenue_for_the_year"
      );
      if (Number(amount) > Number(ebitda.amount)) {
        setOpen(true, "EBTDA can not exceed Total Revenue for the Year amount");
        data.value[index].amount = "";
      }
    }
  }
  if (id === "total_profits_for_the_year") {
    const totalRevenueYearAmount = data.value.find(
      (item) => item.id === "total_revenue_for_the_year"
    );
    if (Number(amount) > Number(totalRevenueYearAmount.amount)) {
      setOpen(
        true,
        "Total Profits for the Year can not exceed Total Revenue for the Year amount"
      );
      data.value[index].amount = "";
    }
  }
};
const checkAmount = (e, id) => {
  let value = "";
  const index = e.target.selectionStart;
  const prevValue = e.target.value;
  const currentKey = String.fromCharCode(e.keyCode);
  value = insertChar(prevValue, currentKey, index);
  if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(value)) {
    e.preventDefault();
    return;
  }
  if (id === "total_profits_for_the_year") {
    const totalRevenueYearAmount = data.value.find(
      (item) => item.id === "total_revenue_for_the_year"
    );

    if (value > Number(totalRevenueYearAmount.amount)) {
      e.preventDefault();
      setOpen(
        true,
        "Total Profits for the Year can not exceed Total Revenue for the Year amount"
      );
    }
  } else if (id === "ebidta_fincial") {
    const ebidtaFincial = data.value.find(
      (item) => item.id === "total_revenue_for_the_year"
    );
    if (value > Number(ebidtaFincial.amount)) {
      e.preventDefault();
      setOpen(true, "EBTDA can not exceed Total Revenue for the Year amount");
    }
  } else if (id === "less_exempt") {
    const lessExempt = data.value.find((item) => item.id === "ebidta_fincial");
    if (value > Number(lessExempt.amount)) {
      e.preventDefault();
      setOpen(true, "EBTDA can not exceed Total Revenue for the Year amount");
    }
  }
};

//const checkAmountCalculatedField = (e, id) => {};
const handleTab = async (e, id) => {
  e.preventDefault();
  const nextItem = data.value[data.value.map((e) => e.id).indexOf(id) + 1]; // find next item
  await openCloseInput(id, "amount", false); // close current field
  await openCloseInput(nextItem?.id, "amount", true); // open next field
};
const getSerialNumber = (index) => {
  switch (index) {
    case 1:
      return "A.";
    case 2:
      return "B i.";
    case 3:
      return "ii.";
    case 6:
      return "C.";
    case 7:
      return "i.";
    case 8:
      return "ii.";
    case 9:
      return "iii.";
    case 10:
      return "iv.";
    default:
      return "";
  }
};
onMounted(async () => {
  await dashboardStore.getDasboardItems();
});
</script>

<style scoped>
.table.custom-table {
  --bs-table-striped-bg: #deeded;
  border-bottom-width: unset !important;
}

/* .table.custom-table > tbody > tr:last-child > * {
 --bs-table-accent-bg: #0f75bc !important;
 color: #ffffff;
} */
.table-header {
  min-height: 39px;
  /*background: #0f75bc;*/
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #2a9ed8;
  padding-top: 10px;
  padding-bottom: 10px;
}
.table-rows {
  background: #ffffff;
  padding-top: 14px;
  padding-bottom: 14px;
}
.table-rows.border {
  border-bottom: 1px solid #747171 !important;
  border-top: 1px solid #747171 !important;
}
.yes-text {
  font-style: normal;
  font-weight: 400;
  font-size: 11.5235px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: #000000;
}
.empty {
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.5px solid rgba(149, 146, 146, 0.3);
}
.cursor-pointer {
  cursor: pointer;
}
.data-img {
  width: 110px;
  height: 110px;
  object-fit: cover;
}
.data-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
tbody:last-child {
  border: 2.5px solid #fff;
  border-bottom: 6px solid rgb(0, 0, 0);
  /* background: #0f75bc !important; */
}
.custom-input {
  text-align: left !important;
  border: none;
  border-bottom: 1px solid#000000;
  padding-bottom: 1px;
  padding-left: 4px;
  height: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #111827;
}
.custom-input:focus {
  outline: 0;
  border-bottom: 1px solid#0f75bc;
}
.total-amount {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-left: 35px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.total-amount .table-rows {
  border-bottom: 6px solid rgb(0, 0, 0);
}
/* .table-total {
  background: #0f75bc; 
} */
.total-text {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #0f75bc;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 20;
}

.disclaimer-container {
  background: #deeded;
}
.note-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.warning-icon {
  font-size: 20px;
}
.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #382628;
}
.table-rows2 {
  background: #ffffff;
  padding-top: 14px;
  padding-bottom: 14px;
}
.table-rows2:hover {
  background: #2a9ed8;
}
.table-rows2.border {
  border-bottom: 1px solid #747171 !important;
  border-top: 1px solid #747171 !important;
}
.bold {
  font-weight: bold;
}
.head {
  text-align: center;
  margin-left: -25px;
  margin-bottom: -10px;
}

@media screen and (max-width: 600px) {
  .flex-1 {
    flex: 3;
  }
}
/*tbody td:hover {
     background-color: #2a9ed8;
color:white !important;
   }
  .table.custom-table :hover{
   /*  background-color:  #2a9ed8;*/
/* cursor: pointer;*/
/* box-shadow: inset 0px 0px 0px 3px #7ac142;

   }*/
</style>

<template>
  <div class="w-100">
    <h4
      v-if="label && !label?.toString().includes('*')"
      :class="`label mb-0 ${disabled ? 'disabled' : ''}`"
    >
      {{ label }}
    </h4>
    <h4
      v-if="label && label?.toString().includes('*')"
      :class="`label mb-0 ${disabled ? 'disabled' : ''}`"
    >
      {{ label.toString().replace("*", "") }} <span class="text-danger">*</span>
    </h4>
    <div class="radio-container w-100 d-flex align-items-center pl-1">
      <div
        v-for="option in options"
        :key="option.value"
        class="d-flex align-items-center mr-2"
      >
        <input
          v-model="value"
          class="mr-2"
          v-bind="option.input"
          type="radio"
          :value="option.value"
        />
        <p class="mb-0 choose-name mr-2">{{ option.name }}</p>
      </div>
      <slot />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  options: {
    type: Array,
    default: [],
  },
  label: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>

<style lang="scss" scoped>
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}
.radio-container {
  margin-top: 7px;
  height: 56px;
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  gap: 11px;
}
.choose-name {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.88px;
}
</style>

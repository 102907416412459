<template>
  <SideNavContainer
    title="Deductions under Chapter-9"
    sideNavType="financialData"
    subtitle="Business - B"
  >
    <div class="row my-4">
      <div class="col-lg-12">
        <h4 class="title">
          Interest Expense disallowed as per (Article-30) (exceeding 30% EBITDA
          for)
        </h4>
      </div>
      <div class="col-lg-12">
        <CustomTable
          :Headers="headers"
          :Data="data.slice(0, 2)"
          :haveIndex="true"
        />
      </div>
      <div class="col-lg-12 mt-2">
        <h4 class="sub-title">
          (after netting of Interest Disallowed under any other article of the
          decree)
        </h4>
      </div>
      <div class="col-lg-12">
        <CustomTable
          :haveNoHeader="true"
          :indexStart="3"
          :Data="data.slice(2, 5)"
          :haveIndex="true"
        />
      </div>
      <div class="col-lg-12 mt-2">
        <h4 class="sub-title">Disallowed Interest Expense</h4>
      </div>
      <div class="col-lg-12">
        <CustomTable
          :haveNoHeader="true"
          :indexStart="6"
          :Data="data.slice(5)"
          :haveIndex="true"
        />
      </div>
      <div class="col-lg-12 mt-2">
        <h4 class="sub-title">
          ***To be adjusted and carried forward on FIFO basis
        </h4>
      </div>
    </div>
  </SideNavContainer>
</template>

<script>
import { ref } from "vue";
import SideNavContainer from "../../layout/SideNavContainer.vue";
import { generate } from "randomized-string";
import CustomTable from "../../common/CustomTable.vue";

export default {
  name: "Chapter9Deduction",
  components: { SideNavContainer, CustomTable },
  setup() {
    const headers = ref([
      "Particulars",
      "Year-1",
      "Year-2",
      "Year-3",
      "Year-4",
      "Year-5",
      "Year-6",
      "Year-7",
      "Year-8",
      "Year-9",
      "Year-10"
    ]);
    const data = ref([
      {
        id: generate(),
        name: "Opening b/f Interest (disallowed in previous year)",
        "Year-1": "0000.0",
        "Year-2": "0000.0",
        "Year-3": "0000.0",
        "Year-4": "0000.0",
        "Year-5": "0000.0",
        "Year-6": "0000.0",
        "Year-7": "0000.0",
        "Year-8": "0000.0",
        "Year-9": "0000.0",
        "Year-10": "0000.0"
      },
      {
        id: generate(),
        name: "(+)Interest Expenditure for the period",
        "Year-1": "0000.0",
        "Year-2": "0000.0",
        "Year-3": "0000.0",
        "Year-4": "0000.0",
        "Year-5": "0000.0",
        "Year-6": "0000.0",
        "Year-7": "0000.0",
        "Year-8": "0000.0",
        "Year-9": "0000.0",
        "Year-10": "0000.0"
      },
      {
        id: generate(),
        name: "(-)Taxable Interest Income for the period",
        "Year-1": "0000.0",
        "Year-2": "0000.0",
        "Year-3": "0000.0",
        "Year-4": "0000.0",
        "Year-5": "0000.0",
        "Year-6": "0000.0",
        "Year-7": "0000.0",
        "Year-8": "0000.0",
        "Year-9": "0000.0",
        "Year-10": "0000.0"
      },
      {
        id: generate(),
        name: "Net Interest Expenditure",
        "Year-1": "0000.0",
        "Year-2": "0000.0",
        "Year-3": "0000.0",
        "Year-4": "0000.0",
        "Year-5": "0000.0",
        "Year-6": "0000.0",
        "Year-7": "0000.0",
        "Year-8": "0000.0",
        "Year-9": "0000.0",
        "Year-10": "0000.0"
      },
      {
        id: generate(),
        name: "Allowable upto 30% of EBITDA (Excluding Exempted Income)",
        "Year-1": "0000.0",
        "Year-2": "0000.0",
        "Year-3": "0000.0",
        "Year-4": "0000.0",
        "Year-5": "0000.0",
        "Year-6": "0000.0",
        "Year-7": "0000.0",
        "Year-8": "0000.0",
        "Year-9": "0000.0",
        "Year-10": "0000.0"
      },
      {
        id: generate(),
        name: "Closing Interest to be carried forward",
        "Year-1": "0000.0",
        "Year-2": "0000.0",
        "Year-3": "0000.0",
        "Year-4": "0000.0",
        "Year-5": "0000.0",
        "Year-6": "0000.0",
        "Year-7": "0000.0",
        "Year-8": "0000.0",
        "Year-9": "0000.0",
        "Year-10": "0000.0"
      }
    ]);
    return { data, headers };
  }
};
</script>

<style scoped>
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /*
identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
</style>

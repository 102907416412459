/* eslint-disable camelcase */
import { defineStore } from "pinia";
import fetchWrapper from "../helpers/fetchWrapper";
import { paymentOrder } from "../constants/parmnent-order-details";
import { basicDataStructures } from "../helpers/storeStructure";
import { Toast } from "../helpers/toast";

export const usePaymentStore = defineStore("paymentStore", {
  // arrow function recommended for full type inference
  state: () => {
    return {
      paymentOrder: {
        loading: true,
        error: false,
        data: paymentOrder
      },
      transactions: {
        ...basicDataStructures
      }
    };
  },
  actions: {
    async getOrderDetails(id) {
      try {
        this.paymentOrder.loading = true;
        const order_id = localStorage.getItem("order_id") || id;
        if (!order_id) {
          this.paymentOrder.error = "Payment is not  processed yet";
          this.paymentOrder.loading = false;
          return;
        }
        let res = await fetchWrapper.post(`/orderDetails`, { order_id });
        if (res.data?.result == 1) {
          this.paymentOrder.data = res?.data?.data;
          this.paymentOrder.loading = false;
          localStorage.removeItem("order_id");
        } else {
          this.paymentOrder.error = "Payment is not  processed yet";
          this.paymentOrder.loading = false;
        }
      } catch (err) {
        console.log(err);
        this.paymentOrder.error = err?.response?.data?.message || "";
        this.paymentOrder.loading = false;
      }
    },
    async makeAnOrder(
      payload,
      callBack = () => {},
      toast = new Toast("Processing please wait...")
    ) {
      toast.makeInfoToast("Processing please wait...");
      try {
        let res = await fetchWrapper.post(`/purchaseSubscription`, payload);
        if (res?.data.result == 1) {
          toast.makeInfoToast("Initiating payment..", "info");
          callBack(res.data?.data, toast);
        } else {
          toast.makeErrorToast(
            res.data?.error
              ? res.data?.error?.join(",")
              : res.data?.toast.makeErrorToast
          );
        }
        console.log(res);
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    },
    async getTransactionList() {
      try {
        this.transactions.loading = true;
        let res = await fetchWrapper.get(`/transaction-list`);
        if (res.data?.result == 1) {
          this.transactions.data = res?.data.data;
          this.transactions.error = false;
          this.transactions.loading = false;
        } else {
          this.transactions.error = false;
          this.transactions.loading = false;
        }
      } catch (err) {
        this.transactions.error = `unable to fetch data ${
          err?.response?.data?.message || ""
        }`;
        this.transactions.loading = false;
      }
    },
    async generateInvoice(
      id,
      setMessage = () => {},
      toast = new Toast("Generating...")
    ) {
      try {
        let res = await fetchWrapper.get(`/generate_invoice?order_id=${id}`);
        if (res.data.result == 1) {
          toast.makeSuccessToast("Generated", "success");
          window.open(res.data?.url, "_blank");
        } else {
          toast.makeErrorToast(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message
          );
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(
          "Unable to generate " +
            err?.message +
            ", " +
            err?.response?.data?.message || "",
          "danger"
        );
      }
      setTimeout(() => {
        setMessage("", "info");
      }, 3000);
    },
    async makeAnOrderForAdditionalEntity(
      payload,
      callBack = () => {},
      toast = new Toast("Processing please wait...")
    ) {
      toast.makeInfoToast("Processing please wait...");
      try {
        let res = await fetchWrapper.post(`/purchaseAdditionalCount`, payload);
        if (res?.data.result == 1) {
          toast.makeInfoToast("Initiating payment..", "info");
          callBack(res.data?.data);
        } else {
          toast.makeErrorToast(
            res.data?.error ? res.data?.error?.join(",") : res.data?.message,
            "danger"
          );
        }
      } catch (err) {
        console.log(err);
        toast.makeErrorToast(err?.response?.data?.message || "");
      }
    }
  }
});

export const financeCostData = [
  {
    model: "indrct_expns_finance_cost_loan_processing_fees",
    name: "1. Loan Processing Fees",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "indrct_expns_finance_cost_lc_charges",
    name: "2. LC Charges",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "indrct_expns_finance_cost_interest_on_loan",
    name: "3. Interest on Loan",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "indrct_expns_finance_cost_od_interests",
    name: "4. OD Interest",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "oprtng_prft1_fnce_costs_interest_on_convertible_bonds",
    name: "5. Interest on Convertible Bonds",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "oprtng_prft1_fnce_costs_interest_on_lease_liabilities",
    name: "6. Interest on Lease Liabilities",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "oprtng_prft1_fnce_costs_dividends_on_redeemable_prfrnce_shares",
    name: "7. Dividends on redeemable Preference Shares",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "oprtng_prft1_fnce_costs_other_interest_component",
    name: "8. Other Interest component",
    sub: false,
    amount: "0000.00",
  },
];

export const itemsThatWillReclassified = [
  {
    model: "othr_incm_rclssfd_itms_fncl_asts_at_fvoci",
    showInPut: true,
    name: "1. Financial Assets at FVOCI",
    sub: false,
  },
  {
    model: "othr_incm_rclssfd_itms_fncl_asts_at_fvoci_fair_value_gn_ls",
    showInPut: false,
    name: "1.1 Fair Value Gain/(Losses)",
    sub: true,
  },
  {
    model: "othr_incm_rclssfd_itms_fncl_asts_at_fvoci_reclassification",
    showInPut: false,
    name: "1.2 Reclassification",
    sub: true,
  },
  {
    model: "othr_incm_rclssfd_itms_cash_flow_hedges",
    showInPut: false,
    name: "2. Cash Flow Hedges",
    sub: false,
  },
  {
    model: "othr_incm_rclssfd_itms_cash_flow_hedges_fair_value_gn_ls",
    showInPut: false,
    name: "2.1 Fair Value Gain/(Losses)",
    sub: true,
  },
  {
    model: "othr_incm_rclssfd_itms_cash_flow_hedges_reclassificatins",
    showInPut: false,
    name: "2.2 Reclassification",
    sub: true,
  },
  {
    model: "othr_incm_rclssfd_itms_changes_in_revaluations_surplus",
    showInPut: false,
    name: "3. Changes in Revaluation SurplusGain/(Losses)",
    sub: false,
  },
  {
    model: "othr_incm_rclssfd_itms_income_tax_relating_to_clssfd_itms",
    showInPut: false,
    name: "4. Income Tax Relating to items that will be reclassified to P&L Gain/(Losses)",
    sub: false,
  },
  {
    model: "othr_incm_rclssfd_itms_remsrmnts_of_the_defined_benifit_plns",
    showInPut: false,
    name: "5. Re-measurements of the defined benefit plans Gain/(Losses)",
    sub: false,
  },
  {
    model: "othr_incm_rclssfd_itms_eqty_instruments_through_oci",
    showInPut: false,
    name: "6. Equity Instuments Through OCIGain/(Losses) ",
    sub: false,
  },
  {
    model: "othr_incm_rclssfd_itms_others",
    showInPut: false,
    name: "7. Others (Specify nature & amount) Gain/(Losses)",
    sub: false,
  },
];

export const otherLossesGainsData = [
  {
    model: "othr_incm_othr_ls_gns_net_gns_frm_fncl_asts_msrd_at_fvtpl",
    name: "1. Gains from Financial Assets Measured at FVTPL",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "othr_incm_othr_ls_gns_net_derivative_financial_instrmnts",
    name: "2. Derivative Financial Instruments",
    sub: false,
    amount: "0000.00",
  },
  {
    model: "othr_incm_othr_ls_gns_net_contingent_consideration",
    name: "3. Contingent Consideration",
    sub: false,
    amount: "0000.00",
  },
];

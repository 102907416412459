<template>
  <SideNavContainer title="Add Entity">
    <EntityChecker>
      <AddEntityTab active="declaration" />
      <LoaderContainer :loading="state.loading" />
      <StatusChecker>
        <form @submit="handleSubmit">
          <div class="row wrapper px-2 mt-4">
            <div class="col-lg-12">
              <div class="w-100 d-flex align-items-center mt-10 mb-3">
                <div class="form-check">
                  <input
                    v-model="state.declaration"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label">
                    I declare that all the information provided on this
                    application is true, accurate and complete to the best of my
                    knowledge and belief
                  </label>
                  <p v-if="state.error.declaration" class="text-danger mt-4">
                    <span class="text-warning"> &#9888; </span>
                    {{ state.error.declaration }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="w-100 d-flex align-items-center mt-10 mb-3">
                <button class="save-next mr-3" type="submit">Save</button>
                <button class="cancel" type="button">Cancel</button>
              </div>
            </div>
          </div>
        </form>
      </StatusChecker>
    </EntityChecker>
  </SideNavContainer>
</template>
<script setup>
import { SideNavContainer } from "../../../../layout";
import EntityChecker from "../../EntityChecker.vue";
import StatusChecker from "../../StatusChecker.vue";
import { updateEntity } from "../../../../../store/entityActions/put";

import { AddEntityTab, LoaderContainer } from "../../../../common";
import { onMounted, reactive, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useEntityStore } from "../../../../../store/entity";
import { storeToRefs } from "pinia";

const state = reactive({
  declaration: "",
  message: "",
  loading: false,
  edit: false,
  error: {
    declaration: "",
  },
});
const route = useRoute();
const router = useRouter();
const entityStore = useEntityStore();
const { entity } = storeToRefs(entityStore);
onMounted(() => {
  if (entity.value.data) {
    state.declaration = !!entity.value.data.entity[0]?.declaration;
  }
});

const handleSubmit = async (e) => {
  e.preventDefault();

  if (!state.declaration) {
    state.error.declaration = "Declaration is required";
    return;
  }

  const id = Number(route.params.id);
  const readyData = {
    declaration: state.declaration,
    _method: "PUT",
  };

  await updateEntity(
    "/entityDeclaration",
    { entity_id: id, ...readyData },
    () => {},
    async () => {
      setTimeout(() => {
        router.push("/entity");
      }, 2000);
    }
  );
};
watch(
  () => entity.value.data,
  (newVal) => {
    if (newVal) {
      state.declaration = !!newVal.entity[0]?.declaration;
    }
  },
  { deep: true }
);
</script>
<style scoped>
.save-next {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #fafbff;
  width: 196px;
  background: #0f75bc;
  height: 55px;
}

.cancel {
  background: #ffffff;
  border: 1px solid #747171;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #747171;
  height: 55px;
  width: 196px;
}

.custom-input {
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  height: 48px;
  padding: 13px 10px;
}

.custom-input.error {
  border: 1px solid red;
}

.custom-input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
  text-align: start;
}

.custom-input:focus {
  outline: 0;
}

.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #000000;
}

.radio-container {
  height: 48px;
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  gap: 11px;
}
</style>

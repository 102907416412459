<template>
  <div class="w-100">
    <div
      v-for="item in data"
      :id="item.model"
      :key="item.id"
      :class="`w-100 table-body ${!index || !(index % 2) ? '' : ''} ${
        item?.sub ? 'sub' : ''
      } d-flex align-items-center justify-content-between`"
    >
      <div class="d-flex align-items-center w-75">
        <p class="mb-0 body-title count w-50">
          <b>
            {{
              item.model === "revenue_from_transaction_with_NFZP"
                ? "i."
                : item.model === "revenue_from_Excluded_activities"
                ? "ii."
                : ""
            }}
          </b>
        </p>
        <p class="mb-0 d-flex body-title item-name">{{ item.name }}</p>
      </div>

      <p
        v-if="!item.showInput"
        class="body-title mb-00"
        @click="openCloseInput(item.model, true)"
      >
        {{ NumberFormatter(revenueQfzp[item.model]) }}
      </p>

      <input
        :id="'input-qfz-calc-' + item.model"
        v-model="revenueQfzp[item.model]"
        :hidden="!item.showInput"
        type="number"
        class="clickable-text"
        @blur="openCloseInput(item.model, false)"
        @keydown.tab.enter.down.up="(e) => handleTab(e, item.model)"
        @keypress="(e) => handleChange(e, item.model)"
      />
    </div>
    <div
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 total-amount">
        Total Revenue of Non Qualifying Activities
      </p>
      <Information />
    </div>
    <div
      class="total-container d-flex align-items-center justify-content-between"
    >
      <p class="mb-0 body-title w-70">
        Total Revenue of Non Qualifying Activities in Percentage

        <br />
        <span
          v-if="
            Number((totalRevenueNonQualifyingActivities / totalRevenue) * 100) >
            5
          "
          class="above-five p-2"
        >
          <b class="text-danger">*</b>As the De-minimis requisite limit exceeds
          5% of Total Taxable <br />
          Income of Free Zone Person, hence not qualified to be Qualified<br />
          Free Zone Person, therefore the income will be taxable as Normal<br />
          Business Entity, hence we advise the user to refer Business Tax<br />
          Calculator additionally.</span
        >
      </p>
      <p class="mb-0 total-amount w-60 text-right">
        {{
          NumberFormatter(
            (totalRevenueNonQualifyingActivities / totalRevenue) * 100
          )
        }}
        %
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { generate } from "randomized-string";
import { useQFZCalculatorStore } from "../../../store/QFZcalculator";
import { storeToRefs } from "pinia";
import { NumberFormatter } from "../../../helpers/numberFormatter";
import Information from "./TotalRevenueOfNonQualifying/Information.vue";
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  setAlert: {
    type: Function,
    default: () => {},
  },
});
const QFZCalculator = useQFZCalculatorStore();
const {
  revenueQfzp,
  revenue,
  totalRevenueNonQualifyingActivities,
  totalRevenue,
} = storeToRefs(QFZCalculator);
const data = ref([
  {
    id: generate(),
    name: "Revenue from Transaction with  Non-Freezone Person other than Qualifying Activity",
    sub: false,
    showInput: false,
    model: "revenue_from_transaction_with_NFZP",
  },
  {
    id: generate(),
    name: "Revenue from Excluded Activities",
    sub: false,
    showInput: false,
    model: "revenue_from_Excluded_activities",
  },
]);
const handleChange = (e, model) => {
  const value = Number(e.target.value + String.fromCharCode(e.keyCode));
  const totalRevenueOfQFZP = Number(revenue.value.total_revenue_of_QFZP);
  const total = data.value.reduce((a, b) => {
    if (b.model === model) {
      return a + value;
    }
    return a + Number(revenueQfzp.value[b.model]);
  }, 0);

  if (value > totalRevenueOfQFZP) {
    e.preventDefault();
    e.target.blur();
    props.setAlert(
      true,
      "Value should not be greater than Total Revenue of Freezone Person value"
    );
    return;
  }
  if (total > totalRevenue.value) {
    e.preventDefault();
    e.target.blur();

    props.setAlert(
      true,
      "Sum of values should not be greater than Total Net Revenue of Freezone Person"
    );
  }
};
const openCloseInput = async (model, value) => {
  if (props.disabled) {
    return;
  }
  data.value = data.value.map((item) => {
    if (item.model === model) {
      return { ...item, showInput: value };
    }
    return item;
  });
  if (value) {
    const input = await document.getElementById(`input-qfz-calc-${model}`);
    input.focus();
  }
};
const handleTab = async (e, current_model) => {
  e.preventDefault();
  if (e.key === "ArrowUp") {
    return handleUpArrow(e, current_model);
  }
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) + 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
const handleUpArrow = async (e, current_model) => {
  let next_model =
    data.value[data.value.map((e) => e.model).indexOf(current_model) - 1];
  // close current  input
  openCloseInput(current_model, false);
  if (next_model) {
    await openCloseInput(next_model.model, true);
  }
};
</script>

<style scoped>
.header {
  min-height: 44px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 26px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #fafbff;
}

.table-body {
  min-height: 44px;
  padding-right: 100px;
  padding-left: 26px;
  padding-top: 20px;
}

.body-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 31px;
  letter-spacing: 0.055em;
  color: #382628;
}

.white {
  background: #fafbff;
}

.gray {
  background: #deeded;
}

.table-body.sub {
  padding-left: 46px;
}
.total-container {
  min-height: 76px;
  border-top: 1px solid #747171;
  border-bottom: 1px solid #747171;
  /*background: #fafbff;*/
  padding-right: 100px;
  padding-left: 26px;
}
.total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.grand-total {
  height: 75px;
  background: #0f75bc;
  padding-right: 100px;
  padding-left: 26px;
}
.grand-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #fafbff;
}
.count {
  flex: 0.75;
}
.item-name {
  flex: 18;
}
.mb-00:hover {
  background-color: #2a9ed8;
  width: 100px;
  height: 30px;
  text-align: center;
  /*  padding-left: 20px;
  padding-right: 20px;
  margin-left: -200px;*/
}
.clickable-text:focus {
  background-color: #2a9ed8;
  width: 100px;
  height: 30px;
  margin-left: -200px;
}
@media screen and (max-width: 600px) {
  .count {
    flex: 2;
  }
}
b,
strong {
  margin-left: -16px;
}
</style>
